import React, { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { css, SerializedStyles } from '@emotion/react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import Icomoon from '@src/components/atoms/Icomoon';
import useDirLayout from '@src/libs/hooks/useDirLayout';
import { THEME } from '@src/libs/theme';

interface TooltipProps {
  children?: ReactNode;
  contentStyles?: CSSProperties | SerializedStyles;
  help: string;
  helpInterpol?: { [key: string]: number };
  placement?: 'top' | 'right' | 'bottom' | 'left';
  triggerContainerStyles?: CSSProperties | SerializedStyles;
}

const Tooltip = ({
  children,
  contentStyles,
  help,
  helpInterpol,
  placement = 'left',
  triggerContainerStyles,
}: TooltipProps) => {
  const { dir } = useDirLayout();
  const { t } = useTranslation();

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>
          <div css={[{ alignItems: 'center', display: 'grid' }, triggerContainerStyles as SerializedStyles]}>
            {children || <Icomoon css={styles.icon} icon="help-filled" />}
          </div>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            css={[styles.content, contentStyles as SerializedStyles]}
            side={
              dir === 'rtl' ? (placement === 'left' ? 'right' : placement === 'right' ? 'left' : placement) : placement
            }
          >
            {t(`Tooltip.${help}`, { ...helpInterpol })}
            <RadixTooltip.Arrow></RadixTooltip.Arrow>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

const styles = {
  content: css({
    background: THEME.colors.black.main,
    borderRadius: THEME.box.borderRadius.s,
    color: THEME.font.colors.white,
    fontSize: THEME.font.sizes.subordinate,
    padding: '4px 8px',
  }),
  icon: css({
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.9,
    },
  }),
};

export default Tooltip;
