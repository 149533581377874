import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { css } from '@emotion/react';
import { formatPercent } from '@src/libs/format';
import { ViewportType } from '@src/libs/types';

interface BarChartProps {
  answerOptions: {
    count: number;
    optionTitle: string;
  }[];
  title: string;
}

interface AnswerOptions {
  categories: string[];
  data: number[];
  totalCount: number;
}

const BarChart = ({ answerOptions, title }: BarChartProps) => {
  const { categories, data, totalCount } = answerOptions.reduce<AnswerOptions>(
    (prev, curr) => ({
      categories: [...prev.categories, curr.optionTitle],
      data: [...prev.data, curr.count],
      totalCount: prev.totalCount + curr.count,
    }),
    {
      categories: [],
      data: [],
      totalCount: 0,
    }
  );

  const options = {
    chart: {
      height: 250,
    },
    colors: ['#3892e5'],
    legend: {
      enabled: false,
      layout: 'vertical',
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          formatter(): string {
            // y property contain in this but not declare for type
            const { y } = this as any;

            return `${y} (${formatPercent(y / totalCount, true, 0)})`;
          },
        },
      },
    },
    series: [
      {
        pointWidth: 35,
        data,
        name: title,
        type: 'bar',
      },
    ],
    title: {
      text: '',
    },
    xAxis: {
      categories,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: '',
      },
    },
  };

  return (
    <div css={styles.container}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',

    '& .highcharts-credits': {
      display: 'none',
    },

    '& .highcharts-container': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .highcharts-legend-item': {
      display: 'none',
    },

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      '& .highcharts-root': {
        width: '90vw',
      },
    },

    [`@media (max-width: 375px)`]: {
      '& .highcharts-root': {
        width: '55%',
      },
    },
  }),
};

export default BarChart;
