import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ShopifyOrderPaymentStatus, ShopifyOrderShippingStatus } from '@src/__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@src/libs/format';
import emptyThumbnail from '@src/assets/icon/emptyThumbnail.svg';
import { localizedDateFormatter } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { getPaymentStatusText, getShippingStatusText } from './helper';

const ORDER_TIME_FORMAT = `d MMM yyyy 'at' h:mm aaa`;

interface OrderDetailsProps {
  orderId: string;
  paymentStatus: ShopifyOrderPaymentStatus;
  shippingStatus?: ShopifyOrderShippingStatus | null;
  orderTime: string;
  currency: string;
  items: { productName: string; image?: string | null; variant?: string | null; price: number; quantity: number }[];
  total: number;
}

const OrderDetails = (props: OrderDetailsProps) => {
  const { i18n, t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const { orderId, paymentStatus, shippingStatus, orderTime, total, currency, items } = props;

  const paymentStatusText = useMemo(() => getPaymentStatusText(paymentStatus), [paymentStatus]);
  const shippingStatusText = useMemo(() => getShippingStatusText(shippingStatus), [shippingStatus]);

  return (
    <div css={styles.container}>
      <Row css={styles.paddingTop16}>
        <HighlightedText>#{orderId}</HighlightedText>
        <Badge status={paymentStatus}>{t(paymentStatusText)}</Badge>
        <Badge status={shippingStatus}>{t(shippingStatusText)}</Badge>
        <Time>
          {localizedDateFormatter(new Date(orderTime + 'Z'), ORDER_TIME_FORMAT, i18n.language as AppLanguage)}
        </Time>
      </Row>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Row>
            <FullWidthCol>
              <img css={styles.thumbnail} src={item.image || emptyThumbnail} />
              <div css={styles.bodyWrapper}>
                <p css={styles.contentText}>{item.productName}</p>
                <HighlightedText>
                  {t('Size/Color')} : {item.variant}
                </HighlightedText>
              </div>
            </FullWidthCol>
            {!isMobileView && (
              <>
                <div css={styles.verticalLine} />
                <FixedWidthCol css={[styles.lineHeight48, styles.textAlignRight]}>
                  <HighlightedText>
                    {t(currency)}
                    {formatNumber(item.price)} x {item.quantity}
                  </HighlightedText>
                </FixedWidthCol>
                <div css={styles.verticalLine} />
                <FixedWidthCol css={[styles.lineHeight48, styles.textAlign]}>
                  <TotalCount>
                    {t(currency)}
                    {formatNumber(item.price * item.quantity)}
                  </TotalCount>
                </FixedWidthCol>
              </>
            )}
          </Row>
          {isMobileView && (
            <Row>
              <FullWidthCol />
              <div css={styles.verticalLine} />
              <FixedWidthCol css={[styles.lineHeight48, styles.textAlignRight]}>
                <HighlightedText>
                  {t(currency)}
                  {formatNumber(item.price)} x {item.quantity}
                </HighlightedText>
              </FixedWidthCol>
              <div css={styles.verticalLine} />
              <FixedWidthCol css={[styles.lineHeight48, styles.textAlign]}>
                <TotalCount>
                  {t(currency)}
                  {formatNumber(item.price * item.quantity)}
                </TotalCount>
              </FixedWidthCol>
            </Row>
          )}
        </React.Fragment>
      ))}

      <Row css={[styles.borderTop, styles.footerPadding]}>
        <FullWidthCol />
        <FixedWidthCol css={[styles.lineHeight48, styles.textAlign, styles.clearLeftBorder]}>
          <HighlightedText>{t('Total')}</HighlightedText>
        </FixedWidthCol>
        <FixedWidthCol css={[styles.lineHeight48, styles.textAlign, styles.clearLeftBorder]}>
          <TotalCount>
            {t(currency)}
            {formatNumber(total)}
          </TotalCount>
        </FixedWidthCol>
      </Row>
    </div>
  );
};

const styles = {
  clearLeftBorder: css({
    borderLeft: 0,
  }),
  footerPadding: css({
    padding: '0 16px 0',
  }),
  paddingTop16: css({
    paddingTop: 16,
  }),
  marginRight8: css({
    marginRight: 8,
  }),
  textAlign: css({
    textAlign: 'center',
  }),
  textAlignRight: css({
    textAlign: 'right',
    paddingRight: 16,
  }),
  lineHeight48: css({
    lineHeight: '48px',
  }),
  borderTop: css({
    borderTop: '1px solid #eef3f7',
  }),
  verticalLine: css({
    width: 1,
    background: '#eef3f7',
  }),
  bodyWrapper: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  thumbnail: css({
    width: 48,
    height: 48,
    borderRadius: 5,
    background: '#dee5ec',
  }),
  contentText: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
    marginBottom: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 350,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      maxWidth: '65vw',
    },
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    background: THEME.colors.white,
    border: '1px solid #eef3f7',
    borderRadius: 5,

    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
  }),
};

const HighlightedText = styled.span({
  fontWeight: 600,
  fontSize: THEME.font.sizes.subordinate,
  color: THEME.font.colors.black.main,
});

const TotalCount = styled(HighlightedText)({
  fontSize: 15,
  textAlign: 'center',
});

const Time = styled.span({
  fontSize: THEME.font.sizes.subordinate,
  color: THEME.font.colors.gray.main,
  flex: 1,
  textAlign: 'right',
});

const Badge = styled.span<{ status?: ShopifyOrderPaymentStatus | ShopifyOrderShippingStatus | null }>(({ status }) => ({
  height: 16,
  backgroundColor:
    status === ShopifyOrderPaymentStatus.PAID || status === ShopifyOrderShippingStatus.FULFILLED
      ? '#40b87c'
      : '#FFB63D',
  borderRadius: 40,
  fontWeight: 600,
  fontSize: 11,
  color: THEME.font.colors.white,
  padding: '0 8px',
  lineHeight: '16px',
}));

const Row = styled.div({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: '8px 16px',
  display: 'flex',
  gap: THEME.box.gaps.s,
});

const FullWidthCol = styled.div({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  gap: THEME.box.gaps.s,
});

const FixedWidthCol = styled.div({
  width: 115,
  boxSizing: 'border-box',
});

export default OrderDetails;
