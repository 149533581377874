import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerInstagramInteractionPostsQuery,
  InfluencerInstagramInteractionPostsQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerInstagramInteractionPosts.graphql';

export const useInfluencerInstagramInteractionPostsQuery = (
  options?: QueryHookOptions<
    InfluencerInstagramInteractionPostsQuery,
    InfluencerInstagramInteractionPostsQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerInstagramInteractionPostsQuery, InfluencerInstagramInteractionPostsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerInstagramInteractionPostsLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerInstagramInteractionPostsQuery,
    InfluencerInstagramInteractionPostsQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerInstagramInteractionPostsQuery, InfluencerInstagramInteractionPostsQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
