import { css } from '@emotion/react';
import * as React from 'react';
import { ViewportType } from '@src/libs/types';

interface ButtonProps {
  title: string;
  handleButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
interface SwitcherProps {
  visible: boolean;
  handleSwitcherClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export type ChildProps = ButtonProps & SwitcherProps;

export interface LanguageMenuProps {
  visible: boolean;
  title: string;
  setInvisible: (event: React.MouseEvent<HTMLButtonElement>) => void;
  toggleVisible: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onRef: React.RefObject<HTMLDivElement>;
  children: (props: ChildProps) => React.ReactNode;
  className?: string;
}

const LanguageMenu = (props: LanguageMenuProps) => {
  const { visible, title, onRef, setInvisible, toggleVisible, children, className } = props;

  return (
    <div css={styles.wrapper} ref={onRef} className={className}>
      {children({ title, handleButtonClick: toggleVisible, visible, handleSwitcherClick: setInvisible })}
    </div>
  );
};

const styles = {
  wrapper: css({
    position: 'relative',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      paddingRight: 16,
    },
  }),
};

export default LanguageMenu;
