import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

const SwitchProfessionalAccount = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div css={styles.accountTypeAndToolsContainer}>
        <div>
          <Icomoon color="#000" icon="arrow-down" />
          <div>{t('Account type and tools')}</div>
        </div>
        <div>{t('Account type')}</div>
        <div>
          <div>
            <div>{t('Switch to professional account')}</div>
            <Icomoon color="#a7a7a7" icon="arrow-down" size={12} />
          </div>
        </div>
        <div>
          <div>{t('Add new professional account')}</div>
          <Icomoon icon="arrow-down" size={9} />
        </div>
      </div>

      <div css={styles.areYouACreatorContainer}>
        <div>{t('Title.Are you a creator')}</div>
        <div>{t('Annotation.Based on the category you selected, you may be a creator')}</div>
        <div>
          <div>
            <div>{t('Option.Business')}</div>
          </div>
          <div>{t('Annotation.Best for retailers, local businesses, brands, organizations and service providers')}</div>
        </div>
      </div>

      <div css={styles.arrowNext} />
    </div>
  );
};

const styles = {
  accountTypeAndToolsContainer: css({
    background: '#e5e5e5',
    borderRadius: 32,
    height: 136,
    position: 'relative',
    width: 280,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      borderBottom: '1px solid #c9c9c9',
      color: '#000',
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      justifyContent: 'center',
      padding: 8,
      position: 'relative',

      '& > svg': {
        left: 8,
        position: 'absolute',
        transform: 'rotate(90deg)',

        '[dir="rtl"] &': {
          left: 'unset',
          right: 8,
          transform: 'rotate(270deg)',
        },
      },
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.hint,
      fontWeight: 600,
      padding: 8,
    },

    '& > div:nth-of-type(3)': {
      position: 'relative',

      '& > div': {
        alignItems: 'center',
        background: THEME.colors.white,
        borderRadius: 8,
        boxShadow: THEME.box.shadows.outer,
        display: 'flex',
        gap: THEME.box.gaps.s,
        left: -16,
        padding: '10px 16px',
        position: 'absolute',
        width: '100%',

        '& > div': {
          color: '#000',
          display: 'flex',
          flex: 1,
          fontSize: THEME.font.sizes.normal,
        },

        '& > svg': {
          transform: 'rotate(270deg)',

          '[dir="rtl"] &': {
            transform: 'rotate(90deg)',
          },
        },
      },
    },

    '& > div:nth-of-type(4)': {
      alignItems: 'center',
      bottom: 8,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: 8,
      position: 'absolute',
      width: 'calc(100% - 16px)',

      '& > div': {
        color: '#000',
        display: 'flex',
        flex: 1,
        fontSize: 11,
      },

      '& > svg': {
        transform: 'rotate(270deg)',

        '[dir="rtl"] &': {
          transform: 'rotate(90deg)',
        },
      },
    },
  }),
  areYouACreatorContainer: css({
    background: '#e5e5e5',
    borderRadius: 32,
    height: 136,
    position: 'relative',
    width: 280,

    '& > div:nth-of-type(1)': {
      color: '#000',
      fontSize: 15,
      fontWeight: 600,
      padding: 8,
      textAlign: 'center',
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: 9,
      padding: '0 20px 8px 20px',
      textAlign: 'center',
    },

    '& > div:nth-of-type(3)': {
      background: THEME.colors.white,
      borderRadius: 8,
      bottom: 4,
      boxShadow: THEME.box.shadows.outer,
      display: 'grid',
      gap: THEME.box.gaps.xs,
      left: -16,
      padding: '10px 16px',
      position: 'absolute',
      width: '100%',

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        display: 'flex',
        gap: THEME.box.gaps.s,

        '& > div': {
          color: '#000',
          display: 'flex',
          flex: 1,
          fontSize: THEME.font.sizes.normal,
          fontWeight: 700,
        },

        '&::after': {
          border: '5px solid #0095f6',
          borderRadius: '50%',
          content: '""',
          display: 'block',
          height: 5,
          width: 5,
        },
      },

      '& > div:nth-of-type(2)': {
        color: '#8e8e8e',
        fontSize: THEME.font.sizes.hint,
      },
    },
  }),
  arrowNext: css({
    alignItems: 'center',
    background: '#000',
    borderRadius: '50%',
    bottom: 0,
    content: '""',
    display: 'grid',
    height: 24,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    margin: 'auto',
    top: 0,
    width: 24,

    '&::before': {
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: '8px solid #fff',
      content: '""',
      display: 'block',
    },
  }),
  container: css({
    display: 'grid',
    gap: THEME.box.gaps.s,
    position: 'relative',
  }),
};

export default SwitchProfessionalAccount;
