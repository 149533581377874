import React, { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { InitialLoading } from '@src/components/atoms';
import { DIALOG_REQUIRED_ACTIONS } from '@src/components/shared/Campaign/helpers';
import { useAnyXSocialAuthConnectMutation } from '@src/graphql/hooks';
import { getMessagesFromFetchResult, ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { getCallbackUrl, getConnectSocialAuthState } from '@src/libs/auth';
import { switchText } from '@src/libs/socialMedia';
import { generatePath, ROUTES } from '@src/shared/routes';
import { AnyXAuthSocialAccountType, SocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';

const ConnectComponent = () => {
  const { provider, response } = useAuthProviderResponse(ROUTES.SETTINGS_SOCIAL_CONNECT);
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { connectState, resetConnectState } = getConnectSocialAuthState();

  const [anyXSocialAuthConnect] = useAnyXSocialAuthConnectMutation();

  const socialAuthConnectCall = async () => {
    const { isNewUI, isYoutubeCmsPath, marketplaceId, socialMedia } = connectState;
    const socialConnectPath = isNewUI
      ? isYoutubeCmsPath
        ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT
        : ROUTES.MY_PAGE_SOCIAL_CONNECT
      : ROUTES.SETTINGS_SOCIAL_CONNECT;
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage(
          {
            redirectPath: socialConnectPath,
            errorMsg: UNEXPECTED_ERROR,
          },
          '*'
        );
        window.close();

        return;
      }
      navigate(socialConnectPath);

      return;
    }

    // Redirect with state for connecting FB pages and an IG account.
    let socialMediaProvider = provider;
    const isIgConnectType = socialMedia === SocialAccountType.INSTAGRAM;

    if (isIgConnectType) {
      socialMediaProvider = AnyXAuthSocialAccountType.INSTAGRAM;
    }

    // Connect
    const variables = {
      input: {
        provider: socialMediaProvider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.CONNECT),
      },
    };

    const { ok, errors } = await anyXSocialAuthConnect({ variables })
      .then(result => {
        if (result.data?.anyXSocialAuthConnect?.ok) {
          return { ok: true, errors: [] };
        } else {
          return { ok: false, errors: getMessagesFromFetchResult(result) };
        }
      })
      .catch((e: ApolloError) => ({
        ok: false,
        errors: [e?.graphQLErrors?.[0]?.message],
      }));

    if (ok && errors.length === 0) {
      sendAmplitudeEvent(eventTypes.connectSocial, { socialMedia: switchText(socialMediaProvider) });
      enqueueSnackbar('Success to connect.', { variant: 'success' });

      if (window.opener) {
        window.opener.postMessage(
          {
            redirectPath: marketplaceId
              ? generatePath(ROUTES.FIND_JOBS_MARKETPLACE_ID, { id: marketplaceId })
              : socialConnectPath,
            state: connectState,
          },
          '*'
        );
        window.close();

        return;
      }
      navigate(
        marketplaceId ? generatePath(ROUTES.FIND_JOBS_MARKETPLACE_ID, { id: marketplaceId }) : socialConnectPath
      );
    } else {
      errors.forEach(error => {
        // TODO: add new FB/TikTok grant permission route
        if (
          [ErrorTypes.FB_REQUIRED_PERMISSION_MISSING, ErrorTypes.VIDEO_PERMISSION_MISSING].includes(error as ErrorTypes)
        ) {
          switch (error) {
            case ErrorTypes.FB_REQUIRED_PERMISSION_MISSING:
              navigate(
                marketplaceId
                  ? generatePath(
                      ROUTES.FIND_JOBS_MARKETPLACE_ID,
                      { id: marketplaceId },
                      { [DIALOG_REQUIRED_ACTIONS.FACEBOOK_GRANT_PERMISSION]: true }
                    )
                  : isNewUI
                  ? isYoutubeCmsPath
                    ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION
                    : ROUTES.MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION
                  : ROUTES.SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION
              );
              break;
            default:
              navigate(
                marketplaceId
                  ? generatePath(
                      ROUTES.FIND_JOBS_MARKETPLACE_ID,
                      { id: marketplaceId },
                      { [DIALOG_REQUIRED_ACTIONS.TIKTOK_GRANT_PERMISSION]: true }
                    )
                  : isNewUI
                  ? isYoutubeCmsPath
                    ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION
                    : ROUTES.MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION
                  : ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION
              );
          }

          return;
        } else {
          enqueueSnackbar(t(error), { variant: 'error' });
          if (window.opener) {
            window.opener.postMessage({ redirectPath: ROUTES.SETTINGS_SOCIAL_CONNECT, errorMsg: error }, '*');
            window.close();
          }

          navigate(
            isIgConnectType
              ? isNewUI
                ? isYoutubeCmsPath
                  ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT
                  : ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT
                : ROUTES.SETTINGS_SOCIAL_CONNECT_IG_UNABLE_CONNECT
              : socialConnectPath
          );

          return;
        }
      });
    }
  };

  useEffect(() => {
    socialAuthConnectCall();

    return () => {
      resetConnectState();
    };
  }, []);

  return <InitialLoading />;
};

export default ConnectComponent;
