import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import { THEME } from '@src/libs/theme';

interface BackNavigatorProps {
  actionItem?: React.ReactElement;
  backPath?: string;
  className?: string;
  interpolation?: Record<string, string>;
  skipTranslation?: boolean;
  title?: string;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

const BackNavigator = ({
  actionItem,
  backPath,
  className,
  interpolation,
  skipTranslation,
  title,
  onClick,
}: BackNavigatorProps) => {
  const { t } = useTranslation();

  return (
    <div className={className} css={styles.container}>
      {backPath && (
        <Link to={backPath} onClick={onClick}>
          <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
        </Link>
      )}
      {title && <div css={styles.title}>{skipTranslation ? title : t(`Title.${title}`, { ...interpolation })}</div>}
      <div css={styles.actionItemContainer}>{actionItem}</div>
    </div>
  );
};

const styles = {
  actionItemContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'fill-available',
  }),
  container: css({
    alignItems: 'center',
    background: THEME.colors.white,
    borderRadius: '23px 23px 0 0',
    display: 'flex',
    gap: THEME.box.gaps.s,
    height: 72,
    padding: '0 24px',

    '& > a': {
      alignItems: 'center',
      display: 'flex',
      height: 40,
      justifyContent: 'center',
      minWidth: 40,

      '[dir="rtl"] &': {
        transform: 'scaleX(-1)',
      },
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
    minWidth: 'fit-content',
  }),
};

export default BackNavigator;
