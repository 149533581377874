import React, { MouseEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import anyCreatorBlack from '@src/assets/img/anyCreatorBlack.png';
import { Icomoon } from '@src/components/atoms';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { Select } from '@src/components/shared';
import {
  useCountUnreadNotificationsQuery,
  useGetAvailableInfluencersForStaffQuery,
  useSwitchInfluencerForStaffMutation,
} from '@src/graphql/hooks';
import { removeToken, setToken } from '@src/libs/auth';
import { useAuthData, useGlobalLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import useInfluencerProfile from '@src/pages/Analytics/useInfluencerProfile';
import { InfluencerType, UserRoles } from '@src/__generated__/globalTypes';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { useHelpCenterUrl } from '@src/libs/help';
import {
  checkIsActivePathname,
  menuWidth,
  uuumMenuItems,
  MenuTitle,
  BadgeType,
  DynamicToType,
  HiddenType,
  SmDisconnectStatusType,
  Submenus,
} from './helpers';

interface MenuProps {
  isDesktopView?: boolean;
}

const Menu = ({ isDesktopView }: MenuProps) => {
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);
  const { creatorType, role, userId } = useAuthData();
  const { setUp } = useAuthSetup();
  const { hasContactForm, isUuum } = useGlobalLayout();
  const { enqueueSnackbar, navigate, pathname, t } = useQueryHelper();
  const { hasFBAccount, hasIGAccount, hasTWAccount, hasYTAccount, hasTKAccount } = useInfluencerProfile();
  const { data } = useCountUnreadNotificationsQuery({ skip: !userId });
  const { data: dataAvailableInfluencerForStaff } = useGetAvailableInfluencersForStaffQuery({
    skip: role !== UserRoles.CREATOR_STAFF,
  });
  const [switchInfluencer] = useSwitchInfluencerForStaffMutation({
    refetchQueries: ['GetAvailableInfluencersForStaff'],
    onCompleted: async results => {
      const token = results.switchInfluencerForStaff?.token;
      if (token) {
        enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
        setToken(token);
        try {
          await setUp(token);
        } catch (error) {
          removeToken();
          enqueueSnackbar(t(error.message), { variant: 'error' });
        }
        // reload the page when switch influencer account
        history.go(0);
      }
    },
  });
  const availableInfluencers = dataAvailableInfluencerForStaff?.getAvailableInfluencersForStaff?.influencers || [];
  const { helpCenterUrl } = useHelpCenterUrl();

  const badgesCount = {
    [BadgeType.NOTIFICATION]: data?.countUnreadNotifications?.count || 0,
  };

  const menusTo = {
    [DynamicToType.HELP]: helpCenterUrl,
  } as Record<DynamicToType, string>;

  const isHidden = {
    [HiddenType.CRM]: creatorType === InfluencerType.NORMAL,
    [HiddenType.INTERCOM]: hasContactForm !== true,
  } as Record<HiddenType, boolean>;

  const smDisconnectedAccounts = {
    [SmDisconnectStatusType.FACEBOOK]: !hasFBAccount,
    [SmDisconnectStatusType.INSTAGRAM]: !hasIGAccount,
    [SmDisconnectStatusType.TWITTER]: !hasTWAccount,
    [SmDisconnectStatusType.YOUTUBE]: !hasYTAccount,
    [SmDisconnectStatusType.TIKTOK]: !hasTKAccount,
  } as Record<SmDisconnectStatusType, boolean>;

  useEffect(() => {
    const activeMenu = uuumMenuItems
      .filter(menu => menu.submenus)
      .find(menu => {
        const { submenus } = menu;

        return (
          submenus &&
          submenus.some(submenu => {
            const { to } = submenu;

            return typeof to === 'string' ? checkIsActivePathname(to) : to.some(path => checkIsActivePathname(path));
          })
        );
      });

    if (activeMenu) {
      setExpandedMenus([activeMenu.title || '']);
    }
  }, []);

  const onChangeSwitchInfluencerAccount = (influencerId: number) => {
    switchInfluencer({
      variables: {
        input: { influencerId },
      },
    });
  };

  const onClickMenuEvent = (e: MouseEvent<HTMLAnchorElement>, func: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    func();
  };

  const onClickOpenSubmenus = (
    e: MouseEvent<HTMLAnchorElement>,
    title: MenuTitle | '',
    submenus: Submenus[],
    isActive: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const menus = [...expandedMenus];
    const index = menus.findIndex(menuTitle => menuTitle === title);
    if (index >= 0) {
      menus.splice(index, 1);
    } else {
      const submenuTo = submenus[title === MenuTitle.ANALYTICS && !isUuum ? 1 : 0].to;
      const redirect = typeof submenuTo === 'string' ? submenuTo : submenuTo[0];
      if (!isActive) {
        navigate(redirect);
      }
      menus.push(title);
    }
    setExpandedMenus(menus);
  };

  return (
    <div css={styles.menu}>
      {!isUuum && isDesktopView && (
        <Link css={styles.menuLogoContainer} to={ROUTES.ROOT}>
          <img alt="anyCreatorLogo" height="32" src={anyCreatorBlack} width="150" />
        </Link>
      )}
      {availableInfluencers.length > 1 && (
        <Select
          css={{ marginBottom: 12 }}
          disableClear
          options={availableInfluencers.map(influencer => ({ label: influencer.name, value: influencer.id }))}
          value={availableInfluencers.find(influencer => influencer.selected)?.id || ''}
          onChange={influencerId => onChangeSwitchInfluencerAccount(Number(influencerId))}
        />
      )}
      {uuumMenuItems
        .filter(menu => {
          const { hiddenType } = menu;

          return !(hiddenType && hiddenType.find(type => isHidden[type]));
        })
        .map((menu, index) => {
          const {
            activeIcon,
            badge,
            dynamicTo,
            groupTitle,
            icon,
            isDivider,
            isNewTab,
            rolesToAccess,
            submenus,
            title,
            to,
            onClick,
          } = menu;
          const badgeCount = badge ? badgesCount[badge] : 0;
          const isAccessible = rolesToAccess ? rolesToAccess.includes(role as UserRoles) : true;
          const redirect = to || (dynamicTo && menusTo[dynamicTo]);
          const isActive = !!redirect && `/${pathname.split('/')[1]}`.includes(redirect.split('?')[0]);
          const isMenuExpanded = !!title && expandedMenus.includes(title);

          const menuTo = submenus ? submenus[0].to : redirect;
          const menuRedirect = typeof menuTo === 'string' ? menuTo : menuTo ? menuTo[0] : '';

          return groupTitle ? (
            <div css={styles.menuGroupTitle} key={index}>
              {t(`Title.${groupTitle}`)}
            </div>
          ) : isAccessible ? (
            <div key={index}>
              <MenuItem
                badge={!!badgeCount}
                containItems={!!submenus}
                isActive={isActive}
                target={isNewTab ? '_blank' : ''}
                to={menuRedirect}
                onClick={e =>
                  submenus
                    ? onClickOpenSubmenus(e, title || '', submenus, isActive)
                    : onClick
                    ? onClickMenuEvent(e, onClick)
                    : null
                }
              >
                <Icomoon
                  color={isActive ? '#3992e5' : '#6e7c89'}
                  icon={(isActive ? activeIcon : icon) as string}
                  size={20}
                />
                <span>{t(`MenuTitle.${title}`)}</span>
                {!!badgeCount && (
                  <div>
                    <div css={styles.badge}>{badgeCount}</div>
                  </div>
                )}
                {submenus && (
                  <Icomoon
                    css={[styles.arrow, isMenuExpanded ? 'transform: rotate(180deg);' : '']}
                    icon="arrow-down"
                    size={10}
                  />
                )}
              </MenuItem>
              {submenus && (
                <SubMenus isVisible={isMenuExpanded}>
                  {submenus.map((submenu, subIndex) => {
                    const hasConnectIcon = submenu.disconnectStatusType
                      ? smDisconnectedAccounts[submenu.disconnectStatusType]
                      : null;
                    const {
                      activeIcon: submenuActiveIcon,
                      icon: submenuIcon,
                      title: submenuTitle,
                      to: submenuTo,
                    } = submenu;
                    const isSubmenuActive =
                      typeof submenuTo === 'string'
                        ? checkIsActivePathname(submenuTo)
                        : submenuTo.some(path => checkIsActivePathname(path));
                    // submenu may contain multiple valid pathname
                    const submenuRedirect = typeof submenuTo === 'string' ? submenuTo : submenuTo[0];

                    return (
                      <SubMenuItem
                        isActive={isSubmenuActive}
                        hasConnectIcon={!!hasConnectIcon}
                        key={subIndex}
                        to={submenuRedirect}
                      >
                        <Icomoon
                          color={isSubmenuActive ? '#3992e5' : '#6e7c89'}
                          icon={isSubmenuActive ? submenuActiveIcon : submenuIcon}
                          size={18}
                        />
                        <span>{t(`MenuTitle.${submenuTitle}`)}</span>
                        {hasConnectIcon === true ? (
                          <Icomoon css={{ marginLeft: 'auto' }} icon="link-blue-light" size={16} />
                        ) : null}
                      </SubMenuItem>
                    );
                  })}
                </SubMenus>
              )}
              {isDivider && <div css={styles.menuDivider} />}
            </div>
          ) : null;
        })}
    </div>
  );
};

const MenuItem = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  badge: boolean;
  containItems: boolean;
  isActive: boolean;
}>(({ badge, containItems, isActive }) => ({
  alignItems: 'center',
  backgroundColor: isActive ? '#f6f8fa' : '#fff',
  borderRadius: 3,
  display: 'flex',
  flexWrap: 'wrap',
  filter: 'brightness(1)',
  height: 40,
  margin: '0 8px',
  padding: '0 12px',
  columnGap: 8,

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    margin: '4px 8px',
  },

  '& > span': {
    color: isActive ? '#3892e5' : '#27313b',
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: badge ? '62%' : containItems ? '72%' : '82%',
  },

  '& > div, i': {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  '&:hover': {
    filter: 'brightness(0.9)',
  },
}));

const SubMenuItem = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  isActive: boolean;
  hasConnectIcon: boolean;
}>(({ isActive, hasConnectIcon }) => ({
  alignItems: 'center',
  backgroundColor: isActive ? '#f6f8fa' : '#fff',
  borderRadius: 3,
  display: 'flex',
  flexWrap: 'wrap',
  filter: 'brightness(1)',
  height: 40,
  margin: '0 8px',
  paddingLeft: 36,
  columnGap: 8,

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    margin: '4px 8px',
  },

  '& > span': {
    color: isActive ? '#3892e5' : '#27313b',
    fontSize: THEME.font.sizes.normal,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: hasConnectIcon ? '68%' : '82%',
  },

  '&:hover': {
    filter: 'brightness(0.9)',
  },

  '[dir="rtl"] &': {
    paddingLeft: 0,
    paddingRight: 36,
  },
}));

const SubMenus = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  display: isVisible ? 'block' : 'none',
}));

const styles = {
  badge: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.error,
    borderRadius: '50%',
    color: THEME.font.colors.white,
    display: 'flex',
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    height: 24,
    justifyContent: 'center',
    width: 24,
  }),
  // menuWidth need to reduce 16px, padding left/right already added 8px
  menu: css({
    backgroundColor: THEME.colors.white,
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    padding: '16px 8px',
    position: 'fixed',
    width: menuWidth - 16,
    zIndex: 1,
    boxShadow: THEME.box.shadows.outer,
  }),
  menuDivider: css({
    borderTop: '1px solid #dee5ec',
    margin: 8,
  }),
  menuGroupTitle: css({
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    padding: '12px 16px',
  }),
  menuLogoContainer: css({
    padding: '0 16px 12px 20px',
  }),
  arrow: css({
    marginLeft: 'auto',

    '[dir="rtl"] &': {
      marginRight: 'auto',
    },
  }),
};

export default Menu;
