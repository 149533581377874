import { useSearchParams } from 'react-router-dom';
import { LIMIT } from '@src/libs/constant';

const MAX_LENGTH_OF_PAGER = 5;
export const INITIAL_PAGE_NUMBER = 1;
export const initialPagingParam = {
  p: INITIAL_PAGE_NUMBER,
};

export const getCurrentPage = () => {
  const [searchParams] = useSearchParams();

  return Number(searchParams.get('p')) || INITIAL_PAGE_NUMBER;
};

export const getOffset = (currentPage: number, limit: number | undefined = LIMIT) => limit * (currentPage - 1);

export interface PageInfo {
  firstIndex: number;
  lastIndex: number;
  totalCount: number;
  currentPage: number;
  totalPages: number;
}

export const getPageInfo = (currentPage: number, totalNumber?: number, limit: number | undefined = LIMIT): PageInfo => {
  const totalCount = totalNumber || 0;
  const totalPages = totalCount > 0 ? Math.ceil(totalCount / limit) : 0;

  const firstIndex = getOffset(currentPage, limit) + 1;
  const lastIndex = Math.min(firstIndex + limit - 1, totalCount);

  return {
    firstIndex,
    lastIndex,
    currentPage,
    totalCount,
    totalPages,
  };
};

export const getPageNumbers = (currentPage: number, totalPages: number): number[] => {
  const length = Math.min(totalPages, MAX_LENGTH_OF_PAGER);
  const slide = Math.floor(length / 2);

  let startIndex: number;
  startIndex = Math.max(1, currentPage - slide);
  startIndex = Math.min(startIndex, totalPages - length + 1);

  const AllPageNumbers = Array.from(Array(length), (_, n) => n + startIndex);

  return AllPageNumbers.filter(n => n < totalPages);
};

export const getPagingParams = (page: number) => (page >= INITIAL_PAGE_NUMBER ? { p: page } : {});
