import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { BackNavigatorV2, ThemeButton } from '@src/components/molecules';
import { TextFormV2 } from '@src/components/shared';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { usePageLayout, useRedirectWithinIframe } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { connectSocialAuthState, useRecoil } from '@src/recoilAtoms';
import { useRedirectUrl } from './useRedirectUrl';

interface FormInformation {
  storeName: string;
}

const AddShopify = () => {
  const { setRecoilState } = useRecoil(connectSocialAuthState);
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const { isLoading, getShopifyRedirectUrl } = useRedirectUrl();
  const { startRedirectProcess } = useRedirectWithinIframe();

  const validationSchema = yup.object().shape({
    storeName: yup.string().required('requiredFieldMessage'),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormInformation>({
    defaultValues: {
      storeName: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ storeName }: FormInformation) => {
    const redirectUrl = await getShopifyRedirectUrl(storeName);
    if (redirectUrl) {
      setRecoilState({ shopifyStoreName: storeName });
      startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.SHOPIFY_CONNECT });
    }
  };

  return (
    <form css={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {!isMobileView && <BackNavigatorV2 backPath={ROUTES.MY_PAGE_CHANNEL_CONNECT} title="Shopify Connection" />}
      <div css={styles.contentContainer}>
        <div css={styles.form}>
          <div>
            <div>{t('Select Sales Channel')}</div>
            <Icomoon icon="shopify-color" size={24} />
            <div>Shopify</div>
          </div>

          <div>
            <div>
              <TextFormV2
                isRequired
                placeholder={t('Placeholder.Store name')}
                title="Shopify Store Name"
                {...register('storeName')}
              />
              <ErrorMessage message={errors.storeName?.message} />
            </div>
            <ThemeButton customPalette={mainBlack} disabled={isLoading} text="Save" type="submit" />
          </div>
        </div>
      </div>
    </form>
  );
};

const styles = {
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  contentContainer: css({
    padding: '32px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  form: css({
    background: THEME.colors.white,
    borderRadius: 15,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      borderBottom: '1px solid #eef3f7',
      color: THEME.font.colors.black.main,
      display: 'flex',
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      gap: THEME.box.gaps.s,
      padding: '24px 16px',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        fontSize: THEME.font.sizes.subHeading,
      },

      '& > div:nth-of-type(1)': {
        flex: 1,
      },

      '& > div:nth-of-type(2)': {
        fontSize: THEME.font.sizes.subHeading,
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      gap: THEME.box.gaps.xxl,
      padding: '24px 16px',

      '& > button': {
        borderRadius: 9,
        height: 56,

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          height: 48,
        },

        '& span': {
          fontSize: THEME.font.sizes.subHeading,
          fontWeight: 600,
        },
      },
    },
  }),
};

export default AddShopify;
