import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdateLineBroadcastMessageMutation,
  UpdateLineBroadcastMessageMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdateLineBroadcastMessage.graphql';

export const useUpdateLineBroadcastMessageMutation = (
  options?: MutationHookOptions<UpdateLineBroadcastMessageMutation, UpdateLineBroadcastMessageMutationVariables>
) => useMutation<UpdateLineBroadcastMessageMutation, UpdateLineBroadcastMessageMutationVariables>(MUTATION, options);
