import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  DisconnectPromotionMethodsMutation,
  DisconnectPromotionMethodsMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/DisconnectPromotionMethods.graphql';

export const useDisconnectPromotionMethodsMutation = (
  options?: MutationHookOptions<DisconnectPromotionMethodsMutation, DisconnectPromotionMethodsMutationVariables>
) => useMutation<DisconnectPromotionMethodsMutation, DisconnectPromotionMethodsMutationVariables>(MUTATION, options);
