import { css } from '@emotion/react';
import { THEME } from '@src/libs/theme';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorMessageProps {
  className?: string;
  message?: string;
  withoutTranslation?: boolean;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { className, message, withoutTranslation } = props;
  const { t } = useTranslation();

  return message ? (
    <p className={className} css={styles.message}>
      {withoutTranslation ? message : t(message)}
    </p>
  ) : null;
};

const styles = {
  message: css({
    padding: '2px 0 0 2px',
    color: 'tomato',
    fontSize: THEME.font.sizes.subordinate,
  }),
};

export default ErrorMessage;
