import { useEffect, DependencyList } from 'react';

const useBottomScrollListener = (callback: () => void, deps: DependencyList = []) => {
  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    if (bottom) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, deps);
};

export default useBottomScrollListener;
