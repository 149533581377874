import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getOptions } from '@src/libs/functions';
import { Omit } from '@src/libs/types';
import Select, { ConditionalProps, SelectProps } from '@src/components/shared/Select/Select';
import { useAllRegionsQuery } from '@src/graphql/hooks';

interface RegionSelectorProps extends Omit<SelectProps, 'options'> {
  countryId: string;
}

const RegionSelector = (props: RegionSelectorProps & ConditionalProps) => {
  const { countryId, disabled, ...restProps } = props;
  const { t } = useTranslation();
  const { data, error } = useAllRegionsQuery({
    variables: { countryId },
    skip: !countryId,
    fetchPolicy: 'cache-and-network',
  });
  const options = data ? getOptions(data.allRegions).map(item => ({ ...item, label: t(item.label) })) : [];

  return <Select disabled={!!disabled || !!error} options={options} {...restProps} />;
};

export default RegionSelector;
