import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { DayPicker, SearchForm, Select } from '@src/components/shared';
import { SELECTED_RANGE } from '@src/components/shared/DayPicker/helpers';
import { useGetCustomerTagsQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { CustomerTagOrder, LineBroadcastStatus } from '@src/__generated__/globalTypes';

export interface FilterItems {
  from: string;
  keyword: string;
  status: LineBroadcastStatus;
  tag: string;
  to: string;
}

interface BroadcastListFilterProps {
  filter: FilterItems;
  setFilter: (filter: FilterItems) => void;
}

const BroadcastListFilter = ({ filter, setFilter }: BroadcastListFilterProps) => {
  const [disabledDateRange, setDisabledDateRange] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>(filter.keyword);
  const { t } = useTranslation();
  const { data } = useGetCustomerTagsQuery({
    variables: {
      input: {
        order: CustomerTagOrder.BY_FREQUENTLY_USED,
      },
    },
  });

  const tags = data?.getCustomerTags?.tags || [];
  const tagOptions = tags.map(({ name }) => ({
    label: name,
    value: name,
  }));
  const statusOptions = Object.keys(LineBroadcastStatus).map(status => ({
    label: status,
    value: status,
  }));

  useEffect(() => {
    // filterByDeliveredDate(undefined); // TODO: logic from anyChat, not sure we need to do the same
    setDisabledDateRange(filter.status === 'DRAFT');
  }, [filter.status]);

  return (
    <div css={styles.container}>
      <SearchForm
        css={{ flex: 1 }}
        placeholder={t(`TextForm.Search by Title`)}
        value={keyword}
        onChange={e => setKeyword(e.target.value)}
        onEnterKeyPress={() => setFilter({ ...filter, keyword })}
      />
      <Select
        css={{ width: 150 }}
        options={tagOptions}
        placeholder="Tag"
        value={tagOptions.find(tag => tag.value === filter.tag)?.value || ''}
        onChange={tag => setFilter({ ...filter, tag })}
      />
      <Select
        css={{ width: 150 }}
        options={statusOptions}
        placeholder="Status"
        value={statusOptions.find(status => status.value === filter.status)?.value || ''}
        onChange={status => setFilter({ ...filter, status: status as LineBroadcastStatus })}
      />
      <DayPicker
        disabled={disabledDateRange}
        range
        rangeList={[
          { value: SELECTED_RANGE.TODAY },
          { value: SELECTED_RANGE.LAST_SEVEN_DAYS },
          { value: SELECTED_RANGE.TODAY_TO_MONTH_AGO },
          { value: SELECTED_RANGE.LAST_MONTH },
          { value: SELECTED_RANGE.LAST_YEAR },
          { value: SELECTED_RANGE.WEEK_TO_DATE },
          { value: SELECTED_RANGE.MONTH_TO_DATE },
          { value: SELECTED_RANGE.YEAR_TO_DATE },
        ]}
        value={{ startDate: filter.from || '', endDate: filter.to || '' }}
        onChange={period => setFilter({ ...filter, from: period.startDate, to: period.endDate })}
      />
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    borderBottom: '1px solid #dee5ec',
    borderTop: '1px solid #dee5ec',
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: 16,
    flexWrap: 'wrap',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      display: 'grid',
    },
  }),
};

export default BroadcastListFilter;
