import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  CreateLineBroadcastMessageMutation,
  CreateLineBroadcastMessageMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/CreateLineBroadcastMessage.graphql';

export const useCreateLineBroadcastMessageMutation = (
  options?: MutationHookOptions<CreateLineBroadcastMessageMutation, CreateLineBroadcastMessageMutationVariables>
) => useMutation<CreateLineBroadcastMessageMutation, CreateLineBroadcastMessageMutationVariables>(MUTATION, options);
