import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/molecules';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

const FormSubmitSection = ({ isSubmitting }: { isSubmitting: boolean }) => (
  <div css={styles.formActionSection}>
    <ThemeButton disabled={isSubmitting} text="Save" theme="blue" type="submit" />
  </div>
);

const styles = {
  formActionSection: css({
    backgroundColor: THEME.colors.white,
    borderTop: '1px solid #eef3f7',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 16,

    '& > button': {
      width: 'max-content',

      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        width: '100%',
        height: 40,
        borderRadius: 5,
      },
    },
  }),
};

export default FormSubmitSection;
