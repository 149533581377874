import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { SocialAccountType } from '@src/__generated__/globalTypes';

export interface ConnectSocialAuthState {
  campaignId?: number;
  isNewUI?: boolean;
  isYoutubeCmsPath?: boolean;
  // TODO: to remove fbAccountId after delete old casting/marketplace campaign join flow
  fbAccountId?: number;
  marketplaceId?: number;
  reconnectStatus?: {
    needReconnect: boolean;
    dataForReconnect: {
      analyticsAccountId: number;
    };
  };
  redirectPath?: string;
  redirectType: FE_REDIRECT_MAPPING;
  shopifyStoreName?: string;
  skipAuthVerification?: boolean;
  socialAccountId?: number;
  socialMedia: SocialAccountType;
}

const { persistAtom } = recoilPersist();

export const connectSocialAuthState = atom<Partial<ConnectSocialAuthState>>({
  key: 'connectSocialAuthState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
