import React, { useEffect, FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  // on navigating from "/profile:compare" -> "/profile" no need to scroll to top
  const pathnameWithoutActiveTab = pathname?.split(':')?.[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathnameWithoutActiveTab]);

  return <>{children}</>;
};

export default ScrollToTop;
