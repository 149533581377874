import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdatePaymentInformationMutation,
  UpdatePaymentInformationMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdatePaymentInformation.graphql';

export const useUpdatePaymentInformationMutation = (
  options?: MutationHookOptions<UpdatePaymentInformationMutation, UpdatePaymentInformationMutationVariables>
) => useMutation<UpdatePaymentInformationMutation, UpdatePaymentInformationMutationVariables>(MUTATION, options);
