import { SentryConfigType, sentryConfig } from './sentry';

declare const IS_STG: boolean;
// eslint-disable-next-line no-underscore-dangle,no-undef
const __DEV__ = process.env.NODE_ENV === 'development';

interface ConfigType {
  sentry: SentryConfigType;
}

const staging: ConfigType = {
  sentry: sentryConfig.staging,
};

const production: ConfigType = {
  sentry: sentryConfig.production,
};

export const config: ConfigType = __DEV__ || IS_STG ? staging : production;
