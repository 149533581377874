import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

const Title = styled.h1({
  fontSize: THEME.font.sizes.subTitle,
  color: THEME.font.colors.black.main,
  margin: '32px 0 16px',
  textAlign: 'center',

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    margin: '24px 0 16px',
  },
});

const Annotation = styled.p({
  fontSize: THEME.font.sizes.subordinate,
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: THEME.font.colors.black.main,
});

const AnnotationRed = styled(Annotation)({
  marginTop: 16,
  color: '#ff2b52',
  fontWeight: 600,
});

const AnnotationLink = styled(Link)({
  display: 'inline-flex',
  color: THEME.font.colors.blue.main,
  fontWeight: 600,

  '&:hover': {
    textDecoration: 'underline',
  },
});

const SignInHoverLink = styled.span({
  paddingLeft: 16,
  fontSize: 13,
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.38,
  letterSpacing: 'normal',
  color: THEME.font.colors.blue.main,
});

const SignInBox = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '16px 0 8px 0',
  height: 48,
  borderRadius: 7,
  background: '#eef3f7',

  '&:hover': {
    background: '#eef3f790',

    [`${SignInHoverLink}`]: {
      textDecoration: 'underline',
    },
  },
});

const AnnotationLinkHref = styled.a({
  marginTop: 16,
  display: 'inline-flex',
  fontWeight: 600,
  color: THEME.font.colors.blue.main,
  fontSize: THEME.font.sizes.subordinate,
  lineHeight: '18px',

  '&:hover': {
    textDecoration: 'underline',
  },
});

export default {
  Annotation,
  AnnotationLink,
  AnnotationLinkHref,
  AnnotationRed,
  SignInHoverLink,
  SignInBox,
  Title,
};
