import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage, Textarea } from '@src/components/atoms';
import { EmojiPicker, Label } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { FormInformation } from './helpers';

interface PlainTextInputProps {
  isDisabled?: boolean;
}

const PlainTextInput = ({ isDisabled }: PlainTextInputProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [lineContent, selectedBlockIndex] = watch(['lineContent', 'selectedBlockIndex']);

  const plainTextContent = lineContent[selectedBlockIndex];
  const isError = !!errors.lineContent && !plainTextContent.plainTextMessage?.text;

  const onChangeText = (val: string) => {
    const items = [...lineContent];
    items[selectedBlockIndex] = { ...plainTextContent, plainTextMessage: { text: val } };
    setValue('lineContent', items);
  };

  const onClickEmoji = (emoji: string) => {
    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...plainTextContent,
      plainTextMessage: { text: plainTextContent.plainTextMessage?.text + emoji },
    };
    setValue('lineContent', items);
  };

  return (
    <div css={styles.container}>
      <Label css={styles.label} isRequired title="Input text" />
      <TextAreaContainer disabled={isDisabled} error={isError}>
        <Textarea
          css={styles.textarea}
          disabled={isDisabled}
          placeholder={t('Placeholder.Use this text to share information about your store with your customers')}
          value={plainTextContent?.plainTextMessage?.text || ''}
          onChange={e => onChangeText(e.target.value)}
        />
        <EmojiPicker onEmojiClick={emoji => onClickEmoji(emoji)} />
      </TextAreaContainer>
      {isError && <ErrorMessage message={'requiredFieldMessage'} />}
    </div>
  );
};

const TextAreaContainer = styled.div<{ disabled?: boolean; error: boolean }>(({ disabled, error }) => ({
  backgroundColor: disabled ? '#f2f2f2' : '#fff',
  border: `1px solid ${error ? '#ff6247' : '#dee5ec'}`,
  borderRadius: 2,
  boxShadow: THEME.box.shadows.outer,
  pointerEvents: disabled ? 'none' : 'auto',
  position: 'relative',

  '& > div > div': {
    margin: 8,
  },
}));

const styles = {
  container: css({
    margin: 24,
  }),
  label: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  }),
  textarea: css({
    border: 'none',
    boxShadow: 'none',
  }),
};

export default PlainTextInput;
