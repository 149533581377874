import React, { ReactNode } from 'react';
import {
  closestCenter,
  useSensor,
  useSensors,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import { arrayMove, useSortable, verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export interface DragEndProps {
  destinationIndex: number;
  sourceIndex: number;
}

interface DragAndDropToolkitProps<T> {
  children: ({ items }: { items: T[] }) => ReactNode;
  items: T[];
  primaryKey: keyof T;
  onDragEnd: (props: DragEndProps) => void;
}

const DragAndDropToolkit = <T extends Record<string, any>>({
  children,
  items,
  primaryKey,
  onDragEnd,
}: DragAndDropToolkitProps<T>) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const onDrag = ({ active, over }: DragEndEvent) => {
    const destinationIndex = items.findIndex(i => i[primaryKey] === over?.id);
    const sourceIndex = items.findIndex(i => i[primaryKey] === active.id);

    onDragEnd({ destinationIndex, sourceIndex });
  };

  return (
    <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={onDrag}>
      <SortableContext items={items.map(i => i[primaryKey])} strategy={verticalListSortingStrategy}>
        {children({ items })}
      </SortableContext>
    </DndContext>
  );
};

export { arrayMove, useSortable, CSS };
export default DragAndDropToolkit;
