import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, Icon } from '@src/components/atoms';
import { useSortable, CSS } from '@src/components/atoms/DragAndDropToolkit';
import { THEME } from '@src/libs/theme';
import { LineMessageType } from '@src/__generated__/globalTypes';
import { FormInformation, FormType } from './helpers';

interface BlockCardProps {
  contentType?: LineMessageType;
  error?: boolean;
  id: string;
  isDeletable: boolean;
  onClick?: () => void;
  onDelete: () => void;
}

const BlockCard = ({ contentType, error, id, isDeletable, onClick, onDelete }: BlockCardProps) => {
  const { t } = useTranslation();
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const { setValue, watch } = useFormContext<FormInformation>();
  const formTypeValue = watch('formType');

  const { icon, formType, title } = (() => {
    switch (contentType) {
      case LineMessageType.BUTTON:
        return {
          icon: 'cupboard-grey-light',
          formType: FormType.BUTTON_TYPE,
          title: 'Button Type',
        };
      case LineMessageType.IMAGES:
        return { icon: 'three-cube-horizontal-grey-light', formType: FormType.IMAGE_TYPE, title: 'Image Type' };
      case LineMessageType.IMAGE_VIDEO:
        // anyChat not include this option
        return { icon: 'youtube-grey-light', formType: FormType.IMAGE_VIDEO_TYPE, title: 'Image / Video' };
      case LineMessageType.PLAIN_TEXT:
        return {
          icon: 'edit-box-grey-light',
          formType: FormType.PLAIN_TEXT,
          title: 'Plain Text',
        };
      case LineMessageType.RICH:
        return {
          icon: 'image-grey',
          formType: FormType.RICH_MESSAGE,
          title: 'Rich Message',
        };
      default:
        return {
          icon: null,
          formType: formTypeValue === FormType.BUTTON_TYPE ? FormType.BUTTON_TYPE_CONTENT : FormType.IMAGE_TYPE_CONTENT,
          title: 'Card',
        };
    }
  })();

  const onClickBlock = () => {
    if (onClick) {
      onClick();
    }
    setValue('formType', formType);
  };

  return (
    <div
      css={[
        styles.container,
        {
          transition,
          transform: CSS.Translate.toString(transform),
        },
      ]}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <Block error={error} isDragging={isDragging} onClick={onClickBlock}>
        <Icomoon icon="six-dots-vertical" size={18} />
        <div>
          {icon && <Icomoon icon={icon} size={22} />}
          <div>{t(title)}</div>
        </div>
        <Icon>
          <Icomoon className="arrow-right" css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={10} />
        </Icon>
      </Block>
      <Delete disabled={!isDeletable} onClick={() => isDeletable && onDelete()}>
        <Icomoon icon="trash-outlined" />
      </Delete>
    </div>
  );
};

const Block = styled.div<{ error?: boolean; isDragging: boolean }>(({ error, isDragging }) => ({
  alignItems: 'center',
  backgroundColor: error ? '#fff2f3' : '#fff',
  border: `1px solid ${error ? '#ff6247' : '#dee5ec'}`,
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  gap: 18,
  marginBottom: 8,
  maxHeight: 48,
  opacity: isDragging ? 0.8 : 1,
  padding: '12px 16px',

  '& > svg': {
    cursor: 'grab',
  },

  '& > div': {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: THEME.box.gaps.xs,

    '& > div': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },
  },

  '& > .arrow-right': {
    visibility: 'hidden',
  },

  '&:hover': {
    backgroundColor: error ? '#fff2f3' : '#f6f8fa',

    '& > .arrow-right': {
      visibility: 'visible',
    },
  },
}));

const Delete = styled.div<{ disabled: boolean }>(({ disabled }) => ({
  alignItems: 'center',
  backgroundColor: THEME.colors.white,
  borderRadius: 3,
  cursor: disabled ? 'default' : 'pointer',
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  opacity: disabled ? 0.5 : 1,
  width: 32,
}));

const styles = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,
  }),
};

export default BlockCard;
