import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  YoutubeCmsVideosForInfluencerQuery,
  YoutubeCmsVideosForInfluencerQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/YoutubeCmsVideosForInfluencer.graphql';

export const useYoutubeCmsVideosForInfluencerQuery = (
  options?: QueryHookOptions<YoutubeCmsVideosForInfluencerQuery, YoutubeCmsVideosForInfluencerQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<YoutubeCmsVideosForInfluencerQuery, YoutubeCmsVideosForInfluencerQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useYoutubeCmsVideosForInfluencerLazyQuery = (
  options?: LazyQueryHookOptions<YoutubeCmsVideosForInfluencerQuery, YoutubeCmsVideosForInfluencerQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<YoutubeCmsVideosForInfluencerQuery, YoutubeCmsVideosForInfluencerQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
