import isEqual from 'lodash/isEqual';
import React, { useState, Ref } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import thanksPage from '@src/assets/img/forms/thanksPage.png';
import { ErrorMessage, Textarea } from '@src/components/atoms';
import DragAndDropToolkit, { DragEndProps } from '@src/components/atoms/DragAndDropToolkit';
import { ThemeButton } from '@src/components/molecules';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import { TextForm } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { formState, submitFormState, useRecoil } from '@src/recoilAtoms';
import { generatePath, ROUTES } from '@src/shared/routes';
import { FormStatus, QuestionType } from '@src/__generated__/globalTypes';
import DynamicInput, { Questions } from './DynamicInput';

export interface FormInformation {
  description: string;
  hash?: string;
  id?: any;
  questions: Questions[];
  status: FormStatus;
  title: string;
  thankDescription: string;
  thankTitle: string;
}

export interface Refs {
  backButtonRef: Ref<HTMLButtonElement>;
  previewButtonRef: Ref<HTMLButtonElement>;
  submitButtonRef: Ref<HTMLButtonElement>;
}

interface FormProps {
  isFormAnswerSubmitted: boolean;
  refs: Refs;
}

const Form = ({ isFormAnswerSubmitted, refs }: FormProps) => {
  const navigate = useNavigate();
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState<boolean>(false);
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const { recoilState: formInformation, setRecoilState: setFormState } = useRecoil(formState);
  const { setRecoilState: setSubmitFormState } = useRecoil(submitFormState);
  const {
    formState: { defaultValues, errors },
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<FormInformation>();
  const values = watch();
  const { id, questions } = values;
  const { t } = useTranslation();

  const onChangeInput = (index: number, value: Questions) => {
    const items = [...questions];
    items[index] = value;
    setValue('questions', items);
  };

  const onClickAddInput = () => {
    const nextOrder = questions.length + 1;
    const item = {
      genId: uuidv4(),
      image: null,
      isRequired: false,
      options: [],
      order: nextOrder,
      questionType: QuestionType.SHORT_ANSWER,
      title: '',
    };
    setValue('questions', questions.concat(item));
  };

  const onClickLeave = () => {
    if (formInformation || !isEqual(defaultValues, values)) {
      setIsLeaveDialogOpen(true);
    } else {
      navigate(ROUTES.MY_PAGE_FORMS);
    }
  };

  const onClickPreview = () => {
    trigger().then(isValid => {
      if (isValid) {
        if (!defaultValues?.id || !isEqual(defaultValues, values)) {
          const { description, status, thankDescription, thankTitle, title } = values;
          const previewItems = {
            description,
            hash: '',
            id: '',
            questions: questions.map(question => {
              const { image, isRequired, options, order: questionOrder, questionType, title: questionTitle } = question;

              return {
                id: uuidv4(),
                image: image || '',
                isRequired,
                options: options.map(option => {
                  const { optionTitle, order: optionOrder } = option;

                  return {
                    id: uuidv4(),
                    optionTitle,
                    order: optionOrder,
                  };
                }),
                order: questionOrder,
                questionType,
                title: questionTitle,
              };
            }),
            status,
            thankDescription,
            thankTitle,
            title,
          };

          // check if the initial values is same with current values, if the form is previewing before save
          // save the information into atoms for draft edit/preview
          setFormState(values);
          setSubmitFormState(previewItems);
        }
        navigate(
          id ? generatePath(ROUTES.MY_PAGE_FORMS_EDIT_LIVE_PREVIEW, { id }) : ROUTES.MY_PAGE_FORMS_ADD_LIVE_PREVIEW
        );
      }
    });
  };

  const onCopyInput = (value: Questions) => {
    let items = [...questions];
    items = items.concat({ ...value, genId: uuidv4(), order: questions.length + 1 });
    setValue('questions', items);
  };

  const onDeleteInput = (index: number) => {
    let items = [...questions];
    items.splice(index, 1);
    items = items.map((item, key) => {
      item.order = key + 1;

      return item;
    });
    setValue('questions', items);
  };

  const onDragEnd = ({ destinationIndex, sourceIndex }: DragEndProps) => {
    let items = [...questions];
    const dragItem = items[sourceIndex];
    items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, dragItem);
    items = items.map((item, key) => {
      item.order = key + 1;

      return item;
    });

    setValue('questions', items);
  };

  const isEmailExist = !!questions.find(question => question.questionType === QuestionType.EMAIL);
  const isNameExist = !!questions.find(question => question.questionType === QuestionType.NAME);

  return (
    <div>
      <HiddenButton ref={refs.backButtonRef} type="button" onClick={onClickLeave} />
      <HiddenButton ref={refs.previewButtonRef} type="button" onClick={onClickPreview} />
      <HiddenButton ref={refs.submitButtonRef} type="submit" />
      <Dialog
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: () => setIsLeaveDialogOpen(false),
        }}
        closeButtonProps={{
          styles: blackCircleCloseStyles,
        }}
        contentStyles={{ borderRadius: 3, maxWidth: 648 }}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Leave',
          theme: 'blue',
          onClick: () => navigate(ROUTES.MY_PAGE_FORMS),
        }}
        open={isLeaveDialogOpen}
        onClose={() => setIsLeaveDialogOpen(false)}
      >
        <div css={styles.dialogContent}>
          <span>{t('Dialog.Leave Form')}</span>
          <span>{t('Annotation.Changes you made may not be saved')}</span>
        </div>
      </Dialog>
      <div css={styles.container}>
        <div css={styles.formContainer}>
          <InputContainer marginBottom={16}>
            <StyledTextForm
              isRequired
              placeholder={t('TextForm.Form Title')}
              title="Form Title"
              {...register('title')}
            />
            <ErrorMessage message={errors.title?.message} />
          </InputContainer>

          <InputContainer marginBottom={24}>
            <StyledTextForm
              isRequired
              placeholder={t('TextForm.Description')}
              title="Description"
              {...register('description')}
            />
            <ErrorMessage message={errors.description?.message} />
          </InputContainer>

          <DragAndDropToolkit items={questions} primaryKey="genId" onDragEnd={onDragEnd}>
            {({ items }) =>
              items.map((question, index) => {
                const { genId, questionType } = question;

                return (
                  <DynamicInput
                    error={!!errors.questions}
                    isEmailExist={questionType !== QuestionType.EMAIL && isEmailExist}
                    isFormAnswerSubmitted={isFormAnswerSubmitted}
                    isNameExist={questionType !== QuestionType.NAME && isNameExist}
                    key={genId}
                    value={{ ...question }}
                    onChange={value => onChangeInput(index, value)}
                    onCopy={value => onCopyInput(value)}
                    onDelete={() => onDeleteInput(index)}
                  />
                );
              })
            }
          </DragAndDropToolkit>

          <ThemeButton onClick={onClickAddInput} text="+" skipTranslation width="max-content" css={styles.addBtn} />

          <div css={styles.nextPageDivider}>
            <h2>
              <span>{t('Next Page')}</span>
            </h2>
          </div>

          <div css={styles.thanksPageContainer}>
            <div>{t('Thanks Page')}</div>
            <div>
              <img alt="bgImg" height="120" src={thanksPage} width="180" />
            </div>
            <div>
              <InputContainer marginBottom={16}>
                <StyledTextForm
                  css={styles.thanksPageTitle}
                  placeholder={t('Your response has been recorded')}
                  {...register('thankTitle')}
                />
                <ErrorMessage message={errors.thankTitle?.message} />
              </InputContainer>

              <InputContainer marginBottom={0}>
                <StyledTextArea
                  css={styles.textFormTitle}
                  placeholder={t(
                    'Thank you very much for your response As soon as we confirm the content of your inquiry, our staff will contact you regarding our future actions'
                  )}
                  {...register('thankDescription')}
                />
                <ErrorMessage message={errors.thankDescription?.message} />
              </InputContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputContainer = styled.div<{ marginBottom: number }>(({ marginBottom }) => ({
  marginBottom,
}));

const HiddenButton = styled.button({
  display: 'none',
});

const StyledTextArea = styled(Textarea)({
  borderRadius: 3,
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& input': {
    borderRadius: 3,
    height: 32,
  },
});

const styles = {
  actionContainer: css({
    position: 'absolute',
    right: 24,
    top: 16,
  }),
  container: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  dialogContent: css({
    display: 'grid',

    '& > span:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 24,
    },

    '& > span:nth-of-type(2)': {
      color: THEME.font.colors.error,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 400,
    },
  }),
  formContainer: css({
    backgroundColor: THEME.colors.white,
    width: '100%',
  }),
  nextPageDivider: css({
    marginBottom: 32,

    '& > h2': {
      borderBottom: '1px solid #dee5ec',
      lineHeight: '0.1em',
      textAlign: 'center',
      width: '100%',
    },

    '& > h2 span': {
      backgroundColor: THEME.colors.white,
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      padding: '0 10px',
    },
  }),
  textFormTitle: css({
    '& input': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  }),
  thanksPageContainer: css({
    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      marginBottom: 24,
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 24,
    },

    '& > div:nth-of-type(3)': {
      backgroundColor: '#f6f8fa',
      padding: 24,
    },
  }),
  thanksPageTitle: css({
    '& input': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
    },
  }),
  addBtn: css({
    margin: '0 auto 48px',
    borderRadius: 5,
    fontSize: THEME.font.sizes.title,
    fontWeight: 'normal',

    '.btn-text': {
      position: 'relative',
      bottom: '0.05em',
    },
  }),
};

export default Form;
