import styled from '@emotion/styled';
import * as React from 'react';
import Icomoon from '@src/components/atoms/Icomoon';
import Icon from '@src/components/atoms/Icon';
import { THEME } from '@src/libs/theme';

interface PageArrowProps {
  disabled?: boolean;
  isBack?: boolean;
  onClick?: () => void;
}

const PageArrow = ({ disabled, isBack, onClick }: PageArrowProps) => {
  const Component = disabled ? DisabledPage : Page;

  return (
    <div>
      <Component {...(!disabled && { onClick })}>
        <Icon>
          <Icomoon css={{ transform: isBack ? 'rotate(90deg)' : 'rotate(270deg)' }} icon="arrow-down" size={10} />
        </Icon>
      </Component>
    </div>
  );
};

const DisabledPage = styled.p({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 40,
  height: 32,
  borderRadius: 7,
  border: 'solid 1px #dee5ec',
  backgroundColor: '#dee5ec',
  color: '#8694a2',
  opacity: '0.5',
});

const Page = styled(DisabledPage)({
  transition: 'background-color 0.3s ease-in-out',
  cursor: 'pointer',
  opacity: 1,
  backgroundColor: THEME.colors.white,

  '&:hover': {
    borderColor: '#c5d0da',
    color: '#3b4146',
  },

  '&:active': {
    backgroundColor: '#f6f8fa',
  },
});

export default PageArrow;
