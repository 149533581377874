import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import { LineBroadcastStatus } from '@src/__generated__/globalTypes';

type BroadcastStatusType = (typeof LineBroadcastStatus)[keyof typeof LineBroadcastStatus];

const BroadcastStatus = ({ status }: { status: BroadcastStatusType }) => {
  const { t } = useTranslation();
  const broadcastStatusColors: Record<LineBroadcastStatus, string> = {
    [LineBroadcastStatus.DRAFT]: '#ffe9c5',
    [LineBroadcastStatus.SCHEDULED]: '#ebc6f8',
    [LineBroadcastStatus.DELIVERED]: '#c6ead8',
    [LineBroadcastStatus.ERROR]: '#ffcfcf',
    [LineBroadcastStatus.SENDING]: THEME.colors.gray.eef3f7,
  };

  return <Status color={broadcastStatusColors[status]}>{t(status)}</Status>;
};

const Status = styled.div<{ color: string }>(({ color }) => ({
  background: color,
  borderRadius: 35,
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subordinate,
  padding: '6px 8px',
}));

export default BroadcastStatus;
