import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ListLoading, NotificationBadge } from '@src/components/molecules';
import EmptyJob from '@src/components/organisms/MyJobs/EmptyJob';
import CampaignCard, { Campaign } from '@src/components/shared/Campaign/Card';
import { useAllCampaignsForYourJobCountsQuery, useAllCampaignsForYourJobQuery } from '@src/graphql/hooks';
import { useAuthData, usePageLayout, useQueryHelper, useUuumInfluencerView } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import Revenue from '@src/components/organisms/MyPage/Payment/Revenue';
import { ROUTES } from '@src/shared/routes';

enum MyJobTabs {
  FINISHED = 'finished',
  IN_PROGRESS = 'in_progress',
}

const MyJobs = () => {
  const [tab, setTab] = useState<string>(location.hash.slice(1) || MyJobTabs.IN_PROGRESS);
  const { isAvailableCountry } = useAuthData();
  const { isMobileView } = usePageLayout();
  const { navigate, t } = useQueryHelper();
  const { isUuumView } = useUuumInfluencerView();
  const isFinished = tab === MyJobTabs.FINISHED;
  const { data, loading } = useAllCampaignsForYourJobQuery({ skip: !isAvailableCountry, variables: { isFinished } });
  const { data: dataCampaignCounts } = useAllCampaignsForYourJobCountsQuery({ skip: !isAvailableCountry });
  const tabs = [
    {
      count: dataCampaignCounts?.allCampaignsForYourJobCounts?.progressCampaignCount || 0,
      title: 'in progress',
      value: MyJobTabs.IN_PROGRESS,
    },
    {
      count: dataCampaignCounts?.allCampaignsForYourJobCounts?.finishedCampaignCount || 0,
      title: 'finished',
      value: MyJobTabs.FINISHED,
    },
  ];

  const onClickTab = (value: MyJobTabs) => {
    setTab(value);
    navigate({ hash: value });
  };

  const campaigns =
    data?.allCampaignsForYourJob?.map(campaign => {
      const { autoManaged, createdDate, engagement, marketplace, type } = campaign;

      return { createdDate, type, ...(autoManaged || engagement || marketplace) };
    }) || [];

  return (
    <div css={styles.container}>
      <div css={styles.contentContainer}>
        {!isMobileView && (
          <div css={styles.headerContainer}>
            <div>{t(`Title.My Jobs`)}</div>
            <NotificationBadge />
          </div>
        )}
        <div css={styles.tabContainer}>
          {tabs.map(({ count, title, value }) => (
            <Tab isActive={tab === value} key={value} onClick={() => onClickTab(value)}>
              <div>{t(title)}</div>
              <div>{count}</div>
            </Tab>
          ))}
        </div>

        <div css={styles.cardContainer}>
          {!isUuumView && <Revenue />}

          {loading ? (
            <ListLoading isTransparentBg />
          ) : !campaigns.length ? (
            <EmptyJob redirect={ROUTES.FIND_JOBS} />
          ) : (
            <div css={{ display: 'grid', gap: THEME.box.gaps.l }}>
              {campaigns.map((campaign, campaignIndex) => (
                <CampaignCard campaign={campaign as Campaign} isFinished={isFinished} key={campaignIndex} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Tab = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  alignItems: 'center',
  backgroundColor: THEME.colors.white,
  borderBottom: `2px solid ${isActive ? '#27313b' : '#fff'}`,
  color: isActive ? '#27313b' : '#6e7c89',
  cursor: 'pointer',
  display: 'flex',
  fontSize: THEME.font.sizes.heading,
  fontWeight: isActive ? 600 : 400,
  gap: THEME.box.gaps.s,
  justifyContent: 'center',
  padding: '16px 0',
  textTransform: 'capitalize',
  width: 'fill-available',

  '& > div:nth-of-type(2)': {
    fontWeight: 600,
  },
}));

const styles = {
  cardContainer: css({
    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: '72px 16px 16px 16px',
    },
  }),
  container: css({
    display: 'flex',
    justifyContent: 'center',
    margin: 24,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: 0,
    },
  }),
  contentContainer: css({
    maxWidth: 648,
    width: '100%',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      maxWidth: 'unset',
    },
  }),
  headerContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: '23px 23px 0 0',
    display: 'flex',
    padding: '25px 16px',
    width: 'fill-available',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      width: 'fill-available',
    },
  }),
  tabContainer: css({
    display: 'flex',
    marginBottom: 24,
    width: 'fill-available',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      left: 0,
      top: 40,
      position: 'fixed',
      zIndex: 1,
    },
  }),
};

export default MyJobs;
