import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { CheckBox, ErrorMessage } from '@src/components/atoms';
import { BackNavigator, ThemeButton } from '@src/components/molecules';
import { SentenceForm, TextForm } from '@src/components/shared';
import { useRequestDeleteAccountMutation } from '@src/graphql/hooks';
import { removeToken } from '@src/libs/auth';
import { useAuthData, useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import yup, { InfluencerEmailSchema } from '@src/libs/validation';
import { ROUTES } from '@src/shared/routes';

interface Information {
  comments: string;
  email: string;
  otherServices: string;
  reasons: string[];
}

const Delete = () => {
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const { userId } = useAuthData();
  const { enqueueSnackbar, navigate, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const [requestDeleteAccount] = useRequestDeleteAccountMutation();
  const validationSchema = yup.object().shape({
    email: InfluencerEmailSchema,
    reasons: yup.array().min(1, 'requiredFieldMessage'),
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setValue,
  } = useForm<Information>({
    defaultValues: {
      comments: '',
      email: '',
      otherServices: '',
      reasons: [],
    },
    resolver: yupResolver(validationSchema),
  });
  const reasons = [
    { label: 'AccountDeletionReason1', value: 'REASON_1' },
    { label: 'AccountDeletionReason2', value: 'REASON_2' },
    { label: 'AccountDeletionReason3', value: 'REASON_3' },
    { label: 'AccountDeletionReason4', value: 'REASON_4' },
  ];

  useDeepCompareEffect(() => {
    setValue(
      'reasons',
      selectedReasons.map(value => {
        const label = reasons.find(reason => reason.value === value)?.label;

        return label ? t(`Label.${label}`) : '';
      })
    );
  }, [selectedReasons]);

  const onClickReason = (checked: boolean, value: string) => {
    const items = [...selectedReasons];
    if (checked) {
      items.push(value);
    } else {
      const index = items.findIndex(item => item === value);
      if (index > -1) {
        items.splice(index, 1);
      }
    }
    setSelectedReasons(items);
  };

  const onSubmit = async (values: Information) => {
    try {
      await requestDeleteAccount({
        variables: {
          input: values,
        },
      });
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      removeToken();
      sendAmplitudeEvent(eventTypes.deleteAccount, { influencerId: userId });
      navigate(ROUTES.SIGN_UP);
    } catch (err) {
      enqueueSnackbar(t(err), { variant: 'error' });
    }
  };

  return (
    <form css={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {!isMobileView && (
        <div css={styles.backNavigatorContainer}>
          <BackNavigator to={ROUTES.SETTINGS_ACCOUNT} title="Delete Account" />
        </div>
      )}

      <div css={styles.contentContainer}>
        <div>
          <div css={styles.hint}>
            {t(
              'Annotation.Please let us know if there are any points you would like us to address in deleting your account'
            )}
          </div>

          <InputContainer>
            <TextForm
              css={styles.textForm}
              error={!!errors.email}
              isRequired
              placeholder=""
              title="Email Address"
              {...register('email')}
            />
            <ErrorMessage message={errors.email?.message} />
          </InputContainer>

          <InputContainer>
            <div css={styles.reasonLabel}>
              <span>{t('Annotation.What is your reason for wanting to delete your account')}</span>
              <span>*</span>
              <span>{`(${t('Multiple selections possible')})`}</span>
            </div>
            <div css={styles.checkboxContainer}>
              {reasons.map(({ label, value }) => (
                <div key={value}>
                  <CheckBox
                    checked={selectedReasons.includes(value)}
                    label={`Label.${label}`}
                    onChange={checked => onClickReason(checked, value)}
                  />
                </div>
              ))}
            </div>
            <ErrorMessage message={errors.reasons?.message} />
          </InputContainer>

          <InputContainer>
            <StyledSentenceForm
              placeholder=""
              title="If you have any other comments about our services, please write them below"
              {...register('comments')}
            />
          </InputContainer>

          <InputContainer>
            <StyledSentenceForm
              placeholder=""
              title="Please let us know if there are any other job search apps/influencer services you use"
            />
          </InputContainer>

          <div css={styles.thanksMessage}>{t('Annotation.DeletionThankYouMessage')}</div>
        </div>

        <div>
          <ThemeButton css={styles.deleteBtn} disabled={isSubmitting} text="Delete" type="submit" />
        </div>
      </div>
    </form>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledSentenceForm = styled(SentenceForm)`
  & label {
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
  }
`;

const styles = {
  backNavigatorContainer: css({
    display: 'flex',
    flexBasis: '100%',
    marginBottom: 24,
  }),
  checkboxContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.l,

    '& > div': {
      '& label': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  container: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '16px 24px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: 16,
    },
  }),
  contentContainer: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    maxWidth: 600,
    width: '100%',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      maxWidth: 'unset',
    },

    '& > div:nth-of-type(1)': {
      padding: 24,

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        padding: '24px 16px',
      },
    },

    '& > div:nth-of-type(2)': {
      borderTop: '1px solid #eef3f7',
      padding: '16px 24px',

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        padding: 16,
      },
    },
  }),
  deleteBtn: css({
    backgroundColor: '#ff5f5f !important',
    color: `${THEME.colors.white} !important`,
    filter: 'brightness(1)',
    height: 40,

    '&:hover': {
      filter: 'brightness(0.9)',
    },
  }),
  hint: css({
    borderBottom: '1px solid #eef3f7',
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
    paddingBottom: 24,
  }),
  reasonLabel: css({
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,

    '& > span:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontWeight: 600,
    },

    '& > span:nth-of-type(2)': {
      color: THEME.font.colors.error,
      fontWeight: 600,
      padding: '0 4px',
    },

    '& > span:nth-of-type(3)': {
      color: THEME.font.colors.gray.main,
    },
  }),
  textForm: css({
    '& label': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },
  }),
  thanksMessage: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  }),
};

export default Delete;
