import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { AnyXSocialAuthSignUpInput } from '@src/__generated__/globalTypes';

interface SignUpStateProps {
  agencyHash?: string;
  agencyType?: string;
  email?: string;
  profile?: Omit<AnyXSocialAuthSignUpInput, 'provider'>;
}

const { persistAtom } = recoilPersist();

export const signUpState = atom<SignUpStateProps>({
  key: 'signUpState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
