import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { JAPANESE_LANG } from '@src/libs/constant';
import { localizedDateFormatter } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { THEME } from '@src/libs/theme';
import { MarketplaceCampaignAppliedStatus } from '@src/__generated__/globalTypes';

interface SelectionMessageProps {
  appliedStatus?: MarketplaceCampaignAppliedStatus | null;
  startDate: any;
}

const SelectionMessage = ({ appliedStatus, startDate }: SelectionMessageProps) => {
  const { i18n, t } = useTranslation();
  const isJPLanguage = i18n.language === JAPANESE_LANG;

  const { background, color, description, icon, title } = (() => {
    if (new Date(startDate) > new Date()) {
      return {
        background: '#e7f2fc',
        color: THEME.font.colors.blue.main,
        description: 'Selection you can make a post after the campaign started',
        icon: 'clock-anti-clockwise',
        title: 'Please wait until this campaign starts',
      };
    }

    switch (appliedStatus) {
      case MarketplaceCampaignAppliedStatus.APPLYING:
        return {
          background: '#e7f2fc',
          color: THEME.font.colors.blue.main,
          description: 'Selection you will be notified if you are approved',
          icon: 'clock-anti-clockwise',
          title: 'Applied',
        };
      case MarketplaceCampaignAppliedStatus.APPROVED:
        return {
          background: '#ecf8f2',
          color: '#40b87c',
          description: 'Selection you are approved to join, please make a post as following detail',
          icon: 'check-circle',
          title: 'You are approved',
        };
      default:
        return {
          background: '',
          color: '',
          description: '',
          icon: '',
          title: '',
        };
    }
  })();

  return title ? (
    <Card background={background} color={color}>
      <div>
        <Icomoon color={color} icon={icon} size={16} />
      </div>
      <div>
        <div>{t(title)}</div>
        <div>
          {t(`Annotation.${description}`, {
            date: localizedDateFormatter(
              new Date(startDate),
              isJPLanguage ? 'MMMdo' : 'dd MMM',
              i18n.language as AppLanguage
            ),
          })}
        </div>
      </div>
    </Card>
  ) : null;
};

const Card = styled.div<{ background: string; color: string }>(({ background, color }) => ({
  background,
  border: `1px solid ${color}`,
  borderRadius: 15,
  display: 'flex',
  gap: THEME.box.gaps.s,
  marginTop: 24,
  padding: 12,

  '& > div:nth-of-type(2)': {
    display: 'grid',
    gap: THEME.box.gaps.xs,

    '& > div:nth-of-type(1)': {
      color,
      fontSize: 13,
      fontWeight: 700,
    },

    '& > div:nth-of-type(2)': {
      color,
      fontSize: 13,
    },
  },
}));

export default SelectionMessage;
