import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { THEME } from '@src/libs/theme';
import Icomoon from '@src/components/atoms/Icomoon';

interface MoreButtonProps {
  className?: string;
  to?: string;
  onClick?: () => void;
}

const MoreButton = ({ className, to, onClick }: MoreButtonProps) => {
  const { t } = useTranslation();

  const onClickAnchor = (e: SyntheticEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <Link className={className} css={styles.container} to={to || ''} onClick={onClickAnchor}>
      {t('Button.More')}
      <Icomoon color="#3992e5" css={styles.icon} icon="arrow-fletching-right" />
    </Link>
  );
};

const styles = {
  container: css({
    display: 'inline-flex',
    cursor: 'pointer',
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: THEME.font.sizes.subordinate,
    color: THEME.font.colors.gray.main,
    gap: THEME.box.gaps.xs,

    '&:hover': {
      opacity: 0.8,
    },
  }),
  icon: css({
    marginLeft: 4,

    '[dir="rtl"] &': {
      transform: 'scaleX(-1)',
    },
  }),
};

export default MoreButton;
