import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { formatNumber } from '@src/libs/format';
import { switchSocialConnectColor, switchImage } from '@src/libs/socialMedia';
import { defaultInfluencerAvatar } from '@src/libs/image';
import Icomoon from '@src/components/atoms/Icomoon';
import { THEME } from '@src/libs/theme';

export enum SocialIconType {
  CIRCULAR,
  DEFAULT,
}
interface SocialAccountProps extends IconWrapperProps {
  className?: string;
  src?: string | null;
  iconType?: SocialIconType;
  username?: string | null;
  followers?: number | null;
  accountLink?: string | null;
  rightElement?: React.ReactNode;
}

const SocialAccount = (props: SocialAccountProps) => {
  const {
    className,
    socialMedia,
    src,
    username,
    followers,
    accountLink,
    rightElement,
    iconType = SocialIconType.CIRCULAR,
  } = props;
  const bgcolor = switchSocialConnectColor(socialMedia);

  return (
    <a
      className={className}
      css={styles.container}
      href={accountLink || undefined}
      rel="noopener noreferrer"
      target="_blank"
    >
      {iconType === SocialIconType.CIRCULAR ? (
        <IconWrapper socialMedia={socialMedia} bgcolor={bgcolor}>
          <Icomoon color="#fff" icon={switchImage({ dynamicColor: true, simplify: true, socialMedia })} size={22} />
        </IconWrapper>
      ) : (
        <Icomoon icon={switchImage({ socialMedia })} size={22} />
      )}
      <Thumbnail
        marginLeft={iconType === SocialIconType.CIRCULAR ? '-8px' : '8px'}
        src={defaultInfluencerAvatar(src)}
      />

      <div css={{ flex: 1, marginLeft: 8 }}>
        <SocialAccountWithAvatar username={username} followers={followers} />
      </div>
      {rightElement}
    </a>
  );
};

export const SocialAccountWithAvatar = ({
  username,
  followers,
  className,
}: {
  username?: string | null;
  followers?: number | null;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={className} css={{ flex: 1 }}>
      <h3 css={styles.username}>{username || ''}</h3>
      <p css={styles.follower}>
        {formatNumber(followers, 0)} {t('Followers')}
      </p>
    </div>
  );
};

interface IconWrapperProps {
  socialMedia: SocialAccountType;
  bgcolor?: string;
}

const IconWrapper = styled.div<IconWrapperProps>(({ bgcolor, socialMedia }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 42,
  height: 42,
  backgroundColor: bgcolor,
  backgroundImage: socialMedia === SocialAccountType.INSTAGRAM ? 'linear-gradient(72deg, #fccc63, #d92f7e)' : 'none',
  borderRadius: '50%',
}));

interface ThumbnailProps {
  marginLeft: string;
}
const Thumbnail = styled.img<ThumbnailProps>(({ marginLeft }) => ({
  boxSizing: 'border-box',
  width: 48,
  height: 48,
  marginLeft,
  border: '3px solid #fff',
  borderRadius: '50%',
}));

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 48,
  }),
  follower: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    fontSize: THEME.font.sizes.subordinate,
    lineHeight: 1.17,
    letterSpacing: 'normal',
    color: THEME.font.colors.gray.main,
  }),
  username: css({
    fontSize: THEME.font.sizes.normal,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: THEME.font.colors.black.main,
  }),
};

export default SocialAccount;
