import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  RemoveAllFormResponsesMutation,
  RemoveAllFormResponsesMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/RemoveAllFormResponses.graphql';

export const useRemoveAllFormResponsesMutation = (
  options?: MutationHookOptions<RemoveAllFormResponsesMutation, RemoveAllFormResponsesMutationVariables>
) => useMutation<RemoveAllFormResponsesMutation, RemoveAllFormResponsesMutationVariables>(MUTATION, options);
