import { useState } from 'react';
import { initialPagingParam } from '@src/components/molecules/Pager/helpers';
import { useUrl } from '@src/libs/hooks';
import { ORDER } from '@src/__generated__/globalTypes';
import { getOrderType } from '../constant';

interface SortParams<T extends string> {
  f: T | ''; // field
  o: string; // order
}

export const getInitialSort = <T extends string>(
  params: Record<string, T>,
  getInitialField: (field?: T | '') => T | null
) => {
  const field = params.f;
  const order = params.o;

  const initialSort: SortType<T> = {
    field: getInitialField(field),
    order: getOrderType(order),
  };

  return initialSort;
};

export const getSortParams = <T extends string>(params: SortType<T>): SortParams<T> => ({
  f: params.field || '',
  o: params.order || '',
});

export interface SortType<T extends string> {
  field: T | null;
  order: ORDER | null;
}

export interface SortActionType<T extends string> {
  sort: SortType<T>;
  setSort: (sort: SortType<T>) => void;
}

const useSort = <T extends string>(getInitialField: (field: T | '' | undefined) => T | null): SortActionType<T> => {
  const { searchParams, updateSearchParams } = useUrl();

  const initialSort = getInitialSort(Object.fromEntries(searchParams) as Record<string, T>, getInitialField);
  const [sort, baseSetSort] = useState<SortType<T>>(initialSort);

  const setSort = (props: SortType<T>) => {
    const shouldReset = props.field === sort.field && props.order === sort.order;
    const newSort = shouldReset ? { field: null, order: null } : props;
    baseSetSort(newSort);

    updateSearchParams({
      ...initialPagingParam,
      ...getSortParams<T>(newSort),
    });
  };

  return { sort, setSort };
};

export default useSort;
