import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

interface FacebookAuthStateProps {
  reconnectDialogShownDate: string | null;
}

const { persistAtom } = recoilPersist();

export const facebookAuthState = atom<FacebookAuthStateProps>({
  key: 'facebookAuthState',
  default: { reconnectDialogShownDate: null },
  effects_UNSTABLE: [persistAtom],
});
