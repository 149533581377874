import React from 'react';
import { Icomoon, TextCutter } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { css } from '@emotion/react';
import { useQueryHelper } from '@src/libs/hooks';
import { useGetPersonalUserDetailQuery } from '@src/graphql/hooks';
import { SelectedChatStateType } from '@src/recoilAtoms';
import { checkmarkCode } from '@src/assets/htmlCodes';
import {
  useAssignChatPicMutation,
  useResolveChatMutation,
  useUnassignChatPicMutation,
  useUnresolveChatMutation,
} from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';

const ChatActionBar = ({ pic, resolved, className }: SelectedChatStateType & { className?: string }) => {
  const { params, t, enqueueSnackbar } = useQueryHelper();
  const { data } = useGetPersonalUserDetailQuery();

  const [assignChatPic, assignPicCtx] = useAssignChatPicMutation({
    refetchQueries: ['GetLineChats'],
  });
  const [unassignChatPic, unassignPicCtx] = useUnassignChatPicMutation({
    refetchQueries: ['GetLineChats'],
  });
  const [resolveChat, resolveChatCtx] = useResolveChatMutation({
    refetchQueries: ['GetLineChats'],
  });
  const [unresolveChat, unresolveChatCtx] = useUnresolveChatMutation({
    refetchQueries: ['GetLineChats'],
  });

  const isAssignedToMe = data?.getPersonalUserDetail?.name && pic === data.getPersonalUserDetail.name;
  const isAssigned = !!pic;
  const isDisabledResolve = (isAssigned && !isAssignedToMe) || resolveChatCtx.loading || unresolveChatCtx.loading;
  const variables = { input: { chatId: params.activeChat as string } };

  const handleAssignUnassignPic = async () => {
    try {
      if (isAssigned) {
        await unassignChatPic({ variables });
      } else {
        await assignChatPic({ variables });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };
  const handleResolveUnresolveChat = async () => {
    try {
      if (resolved) {
        await unresolveChat({ variables });
      } else {
        await resolveChat({ variables });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container} className={className}>
      {!isAssigned ? (
        <ThemeButton
          onClick={handleAssignUnassignPic}
          text="Assign to Me"
          width="max-content"
          css={styles.assignToMe}
          loading={assignPicCtx.loading}
          disabled={assignPicCtx.loading}
          prefixIcon={<span css={styles.addIcon}>+</span>}
        />
      ) : (
        <div>
          <div css={[styles.picContainer, unassignPicCtx.loading ? { opacity: 0.8 } : '']}>
            <TextCutter lines={1} text={`${t('PIC')}: ${pic}`} css={styles.picText} />
            {!unassignPicCtx.loading ? (
              <Icomoon color="#808080" css={styles.close} icon="clear" size={8} onClick={handleAssignUnassignPic} />
            ) : null}
          </div>
        </div>
      )}
      <ThemeButton
        onClick={handleResolveUnresolveChat}
        text={resolved ? 'Resolved' : 'Resolve'}
        width="max-content"
        css={resolved ? styles.resolved : styles.resolve}
        loading={resolveChatCtx.loading || unresolveChatCtx.loading}
        disabled={isDisabledResolve}
        prefixIcon={resolved ? <span css={[styles.addIcon, { fontSize: '18px' }]}>{checkmarkCode}</span> : undefined}
      />
    </div>
  );
};

export default ChatActionBar;

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'end',
    columnGap: 8,
    padding: 8,
    borderBottom: '1px solid #dee5ec',
  }),
  assignToMe: css({
    width: 144,
    fontSize: THEME.font.sizes.subordinate,

    '.pallete_text': {
      paddingLeft: 4,
    },
  }),
  resolve: css({
    minWidth: 96,
    fontSize: THEME.font.sizes.subordinate,
  }),
  resolved: css({
    minWidth: 96,
    fontSize: THEME.font.sizes.subordinate,
    color: THEME.font.colors.blue.main,
  }),
  addIcon: css({
    width: 16,
    fontSize: 22,
    fontWeight: 600,
    marginBottom: 2,
  }),
  close: css({
    width: 18,
    height: 18,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  }),
  picContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    height: 32,
    background: '#eef3f7',
    border: THEME.box.borders.gray.main,
    boxSizing: 'border-box',
    borderRadius: 3,
    minWidth: 144,
  }),
  picText: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    lineHeight: '16px',
    color: THEME.font.colors.gray.main,
  }),
};
