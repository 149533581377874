import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthReconnectTtcmMutation,
  AnyXSocialAuthReconnectTtcmMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthReconnectTtcm.graphql';

export const useAnyXSocialAuthReconnectTtcmMutation = (
  options?: MutationHookOptions<AnyXSocialAuthReconnectTtcmMutation, AnyXSocialAuthReconnectTtcmMutationVariables>
) => useMutation<AnyXSocialAuthReconnectTtcmMutation, AnyXSocialAuthReconnectTtcmMutationVariables>(MUTATION, options);
