import { RefObject, useState, useLayoutEffect, useCallback } from 'react';

export default <T extends HTMLElement = HTMLDivElement>(targetRef: RefObject<T>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleResize = useCallback(() => {
    setDimensions({
      width: targetRef.current?.offsetWidth || 0,
      height: targetRef?.current?.offsetHeight || 0,
    });
  }, [targetRef.current?.offsetHeight, targetRef.current?.offsetWidth]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [targetRef.current?.offsetHeight, targetRef.current?.offsetWidth]);

  return dimensions;
};
