import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import CircularProgress from '@src/components/atoms/CircularProgress';
import { THEME } from '@src/libs/theme';

interface ListLoadingProps {
  className?: string;
  height?: string;
  hideBorder?: boolean;
  isTransparentBg?: boolean;
  spinnerProps?: {
    color?: string;
    size?: string;
    thickness?: string;
  };
  text?: string;
}

const ListLoading = ({ className, height, hideBorder, isTransparentBg, spinnerProps, text }: ListLoadingProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper className={className} height={height} hideBorder={hideBorder} isTransparentBg={isTransparentBg}>
      {text && <span css={{ fontSize: THEME.font.sizes.normal }}>{t(text)}</span>}
      <CircularProgress
        color={spinnerProps?.color || THEME.colors.disabled}
        size={spinnerProps?.size || '64px'}
        thickness={spinnerProps?.thickness || '4px'}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<ListLoadingProps>(({ height, hideBorder, isTransparentBg }) => ({
  alignItems: 'center',
  background: isTransparentBg ? 'transparent' : '#fff',
  display: 'flex',
  flex: 1,
  gap: THEME.box.gaps.l,
  justifyContent: 'center',
  height: height || 360,
  ...(!hideBorder &&
    !isTransparentBg && {
      border: THEME.box.borders.gray.main,
      borderRadius: 2,
    }),
}));

export default ListLoading;
