import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

const EditProfile = () => {
  const { t } = useTranslation();
  const publicBusinessList = [
    { subTitle: 'Option.Real Estate', title: 'Category' },
    { subTitle: 'Address', title: 'Contact options' },
  ];

  return (
    <div css={styles.container}>
      <div css={styles.editProfileContainer}>
        <div>
          <Icomoon color="#c3c3c3" icon="user-circle" size={52} />
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <div key={index} />
            ))}
        </div>
        <div>
          <div>{t('Button.Edit Profile')}</div>
        </div>
      </div>
      <div css={styles.connectOrCreatePageContainer}>
        <div>{t('Public business information')}</div>
        <div>
          <div>{t('Page')}</div>
          <div>{t('Connect or create')}</div>
          <Icomoon color="#8e8e8e" icon="arrow-down" size={8} />
        </div>
        <div>
          {publicBusinessList.map(({ subTitle, title }) => (
            <div key={title}>
              <div>{t(title)}</div>
              <div>{t(subTitle)}</div>
              <Icomoon icon="arrow-down" size={8} />
            </div>
          ))}
        </div>
      </div>

      <div css={styles.arrowNext} />
    </div>
  );
};

const styles = {
  arrowNext: css({
    alignItems: 'center',
    background: '#000',
    borderRadius: '50%',
    bottom: 0,
    content: '""',
    display: 'grid',
    height: 24,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    margin: 'auto',
    top: 0,
    width: 24,

    '&::before': {
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: '8px solid #fff',
      content: '""',
      display: 'block',
    },
  }),
  container: css({
    display: 'grid',
    gap: THEME.box.gaps.s,
    position: 'relative',
  }),
  connectOrCreatePageContainer: css({
    background: '#e5e5e5',
    borderRadius: 32,
    height: 120,
    padding: 8,
    position: 'relative',
    width: 264,

    '& > div:nth-of-type(1)': {
      color: '#000',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      marginBottom: 12,
    },

    '& > div:nth-of-type(2)': {
      alignItems: 'center',
      background: THEME.colors.white,
      borderRadius: 8,
      boxShadow: THEME.box.shadows.outer,
      display: 'flex',
      gap: THEME.box.gaps.l,
      height: 37,
      left: -14,
      padding: '0 16px',
      position: 'absolute',
      width: 274,

      '& > div:nth-of-type(1)': {
        color: '#000',
        display: 'flex',
        flex: 1,
        fontSize: THEME.font.sizes.normal,
      },

      '& > div:nth-of-type(2)': {
        color: '#8e8e8e',
        fontSize: THEME.font.sizes.hint,
      },

      '& > svg': {
        transform: 'rotate(270deg)',

        '[dir="rtl"] &': {
          transform: 'rotate(90deg)',
        },
      },
    },

    '& > div:nth-of-type(3)': {
      marginTop: 50,

      '& > div': {
        display: 'flex',
        gap: THEME.box.gaps.m,
        padding: '6px 0',

        '& > div:nth-of-type(1)': {
          color: '#000',
          display: 'flex',
          flex: 1,
          fontSize: 11,
          fontWeight: 500,
        },

        '& > div:nth-of-type(2)': {
          color: THEME.font.colors.gray.main,
          fontSize: 9,
          fontWeight: 500,
        },

        '& > svg': {
          transform: 'rotate(270deg)',

          '[dir="rtl"] &': {
            transform: 'rotate(90deg)',
          },
        },
      },
    },
  }),
  editProfileContainer: css({
    alignContent: 'center',
    background: '#e5e5e5',
    borderRadius: 32,
    display: 'grid',
    gap: THEME.box.gaps.m,
    height: 96,
    padding: 20,
    width: 240,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      gap: 28,

      '& > div': {
        background: '#dedede',
        borderRadius: 5,
        height: 32,
        minWidth: 32,
      },
    },

    '& > div:nth-of-type(2)': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.s,

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        background: THEME.colors.white,
        borderRadius: 5,
        color: '#000',
        display: 'flex',
        flex: 1,
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 500,
        height: 32,
        justifyContent: 'center',
      },

      '&::after': {
        background: '#d7d7d7',
        borderRadius: 5,
        content: '""',
        display: 'flex',
        flex: 1,
        height: 32,
      },
    },
  }),
};

export default EditProfile;
