import React from 'react';
import { css } from '@emotion/react';
import { CrowdinButton, Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

declare const IS_DISABLED_ON_PROD: boolean;

const CrowdinWidget = () =>
  !IS_DISABLED_ON_PROD ? (
    <div css={styles.container}>
      <Icomoon color="#000" icon="crowdin" size={24} />
      <CrowdinButton />
    </div>
  ) : null;

const styles = {
  container: css({
    alignItems: 'center',
    background: '#e6e6e6',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '5px 5px 0 0',
    bottom: 0,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    gap: THEME.box.gaps.s,
    height: 40,
    left: 10,
    justifyContent: 'center',
    padding: '0 16px',
    position: 'fixed',
    zIndex: 5,

    '& > button': {
      fontSize: THEME.font.sizes.subHeading,
      margin: 0,
    },
  }),
};

export default CrowdinWidget;
