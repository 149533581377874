import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage, Icomoon, Icon, Textarea } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { Select, TextForm, YearMonthDayPicker } from '@src/components/shared';
import { useGetFanCountriesQuery, useGetFanProvincesQuery } from '@src/graphql/hooks';
import { getOptions } from '@src/libs/functions';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { usePageLayout, useQueryHelper, useTranslateOptions } from '@src/libs/hooks';
import { FanGender } from '@src/__generated__/globalTypes';
import { generatePath, ROUTES } from '@src/shared/routes';
import { closeIcon } from '@src/assets/htmlCodes';

export interface FanInformation {
  address1: string;
  address2: string;
  birthday: string;
  city: string;
  contactNumber: string;
  countryId: string;
  email: string;
  firstName: string;
  gender: FanGender;
  lastName: string;
  postalCode: string;
  province: string;
  tags: string[];
}

const FanDetail = () => {
  const [tag, setTag] = useState<string>('');
  const { params, t } = useQueryHelper();
  const { activeChat, fanId } = params;
  const { data } = useGetFanCountriesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { isMobileView } = usePageLayout();
  const fanCountries = data?.getFanCountries?.countries || [];
  const pathOptions = activeChat && fanId ? { activeChat, fanId } : undefined;

  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<FanInformation>();
  const [birthday, countryId, gender, province, tags] = watch(['birthday', 'countryId', 'gender', 'province', 'tags']);

  const fanCountryOptions = useMemo(() => getOptions(fanCountries), [fanCountries.length]);
  const countryOptions = useTranslateOptions(fanCountryOptions);
  const genderOptions = useTranslateOptions([
    { value: FanGender.FEMALE, label: 'Female' },
    { value: FanGender.MALE, label: 'Male' },
    { value: FanGender.OTHER, label: 'Other' },
  ]);

  const { data: dataFanProvinces, refetch } = useGetFanProvincesQuery({
    variables: {
      input: {
        countryId,
      },
    },
  });
  const provinceOptions =
    dataFanProvinces?.getFanProvinces?.provinces.map(item => ({ label: item.name, value: item.name })) || [];

  useEffect(() => {
    refetch({
      input: {
        countryId,
      },
    });
  }, [countryId]);

  const onClickAddTag = () => {
    if (tag) {
      const items = [...tags];
      items.push(tag);
      setValue('tags', items);
      setTag('');
    }
  };

  const onClickRemoveTag = (index: number) => {
    const items = [...tags];
    items.splice(index, 1);
    setValue('tags', items);
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <Link to={generatePath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID, pathOptions)}>
            <Icon>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Icon>
          </Link>
          <Icomoon icon="address-book-tiffany-blue" size={28} />
          <div>{t('MenuTitle.Fan Detail')}</div>
        </div>
      )}

      <ContentContainer isMobileView={isMobileView}>
        <InputContainer>
          <div>{t('TextForm.Name')}:</div>
          <div css={styles.nameFieldsContainer}>
            <div>
              <TextForm error={!!errors.firstName} placeholder="Ryuji" {...register('firstName')} />
              <ErrorMessage message={errors.firstName?.message} />
            </div>
            <div>
              <TextForm error={!!errors.lastName} placeholder="Takemoto" {...register('lastName')} />
              <ErrorMessage message={errors.lastName?.message} />
            </div>
          </div>
        </InputContainer>

        <InputContainer>
          <div>{t('TextForm.Email')}:</div>
          <div>
            <TextForm error={!!errors.email} placeholder="anychat@anymindgroup.com" {...register('email')} />
            <ErrorMessage message={errors.email?.message} />
          </div>
        </InputContainer>

        <InputContainer>
          <div>{t('TextForm.Phone Number')}:</div>
          <TextForm
            error={!!errors.contactNumber}
            placeholder="anychat@anymindgroup.com"
            {...register('contactNumber')}
          />
        </InputContainer>

        <InputContainer>
          <div>{t('Birth day')}:</div>
          <div>
            <YearMonthDayPicker
              format="yyyy-MM-dd"
              maxDate={new Date()}
              value={birthday}
              onChange={value => setValue('birthday', value as string)}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <div>{t('Selector.Gender')}:</div>
          <Select
            options={genderOptions}
            placeholder="Select Gender"
            value={gender}
            onChange={value => setValue('gender', value as FanGender)}
          />
        </InputContainer>

        <InputContainer>
          <div>{t('Selector.Country')}:</div>
          <Select
            options={countryOptions}
            placeholder="Select Country"
            value={countryId}
            onChange={value => setValue('countryId', value)}
          />
        </InputContainer>

        <InputContainer>
          <div>{t('Selector.Province')}:</div>
          <Select
            options={provinceOptions}
            placeholder="Select Province"
            value={province}
            onChange={value => setValue('province', value)}
          />
        </InputContainer>

        <InputContainer>
          <div>{t('TextForm.City')}:</div>
          <TextForm error={!!errors.city} placeholder={t('TextForm.City')} {...register('city')} />
        </InputContainer>

        <InputContainer>
          <div>{t('TextForm.Postal Code')}:</div>
          <TextForm error={!!errors.postalCode} placeholder="〒106-6131" {...register('postalCode')} />
        </InputContainer>

        <InputContainer>
          <FlexStartLabel>
            <div>{t('Sentence.Address 1')}:</div>
          </FlexStartLabel>
          <div>
            <StyledTextarea
              error={!!errors.address1}
              placeholder="Roppongi Roppongihiruzumoritawa(31-, Minato Ku"
              {...register('address1')}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <FlexStartLabel>
            <div>{t('Sentence.Address 2')}:</div>
          </FlexStartLabel>
          <div>
            <StyledTextarea
              error={!!errors.address2}
              placeholder="Roppongi Roppongihiruzumoritawa(31-, Minato Ku"
              {...register('address2')}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <FlexStartLabel>{t('Label.Tags')}:</FlexStartLabel>
          <div css={styles.tagsFieldContainer}>
            <div>
              <TextForm placeholder="VIP,etc." value={tag} onChange={e => setTag(e.target.value)} />
              <ThemeButton disabled={!tag} text="Add Tag" theme="black" width="max-content" onClick={onClickAddTag} />
            </div>
            <div css={{ display: 'flex', gap: '4px 8px' }}>
              {tags.map((item: string, index: number) => (
                <div css={styles.tag} key={item}>
                  <div>{item}</div>
                  <span css={styles.close} onClick={() => onClickRemoveTag(index)}>
                    {closeIcon}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </InputContainer>

        <div css={styles.actionContainer}>
          <ThemeButton
            theme="blue"
            disabled={isSubmitting}
            text="Save"
            width="max-content"
            type="submit"
            css={styles.saveButton}
          />
        </div>
      </ContentContainer>
    </div>
  );
};

const ContentContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  display: 'grid',
  height: isMobileView ? '90vh' : '65vh',
  overflowY: 'auto',
}));

const FlexStartLabel = styled.div({
  alignItems: 'flex-start',
  display: 'grid',
  height: '100%',

  '& > div': {
    marginTop: 8,
  },
});

const InputContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 16,

  '& > div:nth-of-type(1)': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    width: '20%',
  },

  '& > div:nth-of-type(2)': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '80%',

    '& > div': {
      width: 'fill-available',
    },
  },
});

const StyledTextarea = styled(Textarea)({
  height: 56,
});

const styles = {
  actionContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  container: css({
    display: 'grid',
  }),
  nameFieldsContainer: css({
    display: 'flex;',
    gap: THEME.box.gaps.s,

    '& > div': {
      width: '48% !important',
    },
  }),
  navigatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,
    gap: THEME.box.gaps.s,

    '& > a': {
      alignItems: 'center',
      backgroundColor: THEME.colors.white,
      borderRadius: 5,
      boxShadow: THEME.box.shadows.outer,
      display: 'grid',
      height: 32,
      justifyContent: 'center',
      width: 32,

      '& > i': {
        margin: 0,
      },
    },

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      marginLeft: 8,
    },
  }),
  saveButton: css({
    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 40,
      width: '100%',
    },
  }),
  tag: css({
    alignItems: 'center',
    backgroundColor: '#eef3f7',
    borderRadius: 3,
    display: 'flex',
    flexWrap: 'wrap',
    height: 24,
    padding: '0 8px',
    width: 'fit-content',
    gap: THEME.box.gaps.s,

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subordinate,
    },
  }),
  tagsFieldContainer: css({
    display: 'flex',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 12,
      gap: THEME.box.gaps.s,

      '& > div': {
        display: 'flex',
        flex: 1,

        '& > div': {
          width: '100%',
        },
      },

      '& > button': {
        borderRadius: 3,
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
        height: 32,
        width: 82,
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexWrap: 'wrap',
    },

    '.btn-text': {
      padding: 0,
    },
  }),
  close: css({
    fontSize: THEME.font.sizes.subTitle,
    lineHeight: '20px',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.7,
    },
  }),
};

export default FanDetail;
