import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  AutoManagedJoinedMethodsV2Query,
  AutoManagedJoinedMethodsV2QueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/AutoManagedJoinedMethodsV2.graphql';

export const useAutoManagedJoinedMethodsV2Query = (
  options?: QueryHookOptions<AutoManagedJoinedMethodsV2Query, AutoManagedJoinedMethodsV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<AutoManagedJoinedMethodsV2Query, AutoManagedJoinedMethodsV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useAutoManagedJoinedMethodsV2LazyQuery = (
  options?: LazyQueryHookOptions<AutoManagedJoinedMethodsV2Query, AutoManagedJoinedMethodsV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<AutoManagedJoinedMethodsV2Query, AutoManagedJoinedMethodsV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
