import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDirLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';

const Footer = () => {
  const { t, i18n } = useQueryHelper();
  const { dir } = useDirLayout();

  return (
    <div css={styles.container} dir={dir}>
      <div css={styles.copyrightContainer}>
        <span css={styles.copyright}>Copyright © AnyMind Group. All right reserved.</span>
      </div>

      <div css={styles.termsAndConditionContainer}>
        <StyledLink target="_blank" to={ROUTES.PRIVACY}>
          {t('Privacy Policy')}
        </StyledLink>
        <VerticalLine />
        <StyledLink target="_blank" to={ROUTES.TERMS}>
          {t('Terms of Service')}
        </StyledLink>
        <VerticalLine />
        <Anchor target="_blank" href={`https://any-creator.com/${i18n.language}/contact/`} rel="noopener noreferrer">
          {t('Contact')}
        </Anchor>
      </div>
    </div>
  );
};

const StyledLink = styled(Link)({
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.subordinate,
});

const Anchor = styled.a({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.subordinate,
  lineHeight: '16px',

  '&:hover': {
    textDecoration: 'underline',
  },
});

const VerticalLine = styled.span({
  width: 1,
  height: 16,
  background: '#dee5ec',
});

const styles = {
  container: css({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 16,

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      flexDirection: 'column',
      gap: THEME.box.gaps.s,
      flexWrap: 'nowrap',
    },
  }),
  copyright: css({
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.subordinate,
    textAlign: 'center',
  }),
  copyrightContainer: css({
    display: 'flex',
    flexBasis: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  termsAndConditionContainer: css({
    display: 'flex',
    flexBasis: '50%',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: THEME.box.gaps.s,

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      flexBasis: '100%',
      justifyContent: 'center',
    },
  }),
};

export default Footer;
