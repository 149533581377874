import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetFanQuery, useUpdateFanMutation } from '@src/graphql/hooks';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import { FanDetailsSchema } from '@src/libs/validation';
import { FanGender } from '@src/__generated__/globalTypes';
import FanDetail, { FanInformation } from './FanDetail';

const Index = () => {
  const { enqueueSnackbar, params, t } = useQueryHelper();
  const { fanId } = params;
  const { data } = useGetFanQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        fanId: Number(fanId),
      },
    },
  });
  const [updateFan] = useUpdateFanMutation();

  const onSubmit = async (values: FanInformation) => {
    const {
      address1,
      address2,
      birthday,
      city,
      contactNumber,
      countryId,
      email,
      firstName,
      gender,
      lastName,
      postalCode,
      province,
      tags,
    } = values;

    try {
      await updateFan({
        variables: {
          input: {
            id: Number(fanId),
            firstName,
            lastName,
            tags,
            address1: address1 || null,
            address2: address2 || null,
            birthday: birthday || null,
            city: city || null,
            contactNumber: contactNumber || null,
            countryId: countryId || null,
            email: email || null,
            gender: gender || FanGender.OTHER,
            postalCode: postalCode || null,
            province: province || null,
          },
        },
      });
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const fanDetails = data?.getFan;
  const defaultValues = {
    address1: fanDetails?.address1 || '',
    address2: fanDetails?.address2 || '',
    birthday: fanDetails?.birthday || '',
    city: fanDetails?.city || '',
    contactNumber: fanDetails?.contactNumber || '',
    countryId: fanDetails?.countryId || '',
    email: fanDetails?.email || '',
    firstName: fanDetails?.firstName || '',
    gender: fanDetails?.gender || FanGender.OTHER,
    lastName: fanDetails?.lastName || '',
    postalCode: fanDetails?.postalCode || '',
    province: fanDetails?.province || '',
    tags: fanDetails?.tags || [],
  };
  const methods = useForm<FanInformation>({
    defaultValues,
    resolver: yupResolver(FanDetailsSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FanDetail />
      </form>
    </FormProvider>
  );
};

export default Index;
