import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { Label, Select, TextForm } from '@src/components/shared';
import { LANGUAGE_OPTIONS } from '@src/libs/i18n';
import { useAuthData, useUuumInfluencerView } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { DisplayLanguage, UserRoles } from '@src/__generated__/globalTypes';
import { CheckBox } from '@src/components/atoms';
import { FormSubmitSection } from '@src/components/shared';

export const LanguageMapping = {
  ch: DisplayLanguage.SIMPLIFIED_CHINESE,
  id: DisplayLanguage.BAHASA,
  en: DisplayLanguage.ENGLISH,
  ja: DisplayLanguage.JAPANESE,
  km: DisplayLanguage.CAMBODIA,
  my: DisplayLanguage.MYANMAR,
  th: DisplayLanguage.THAI,
  tw: DisplayLanguage.TRADITIONAL_CHINESE,
  vi: DisplayLanguage.VIETNAMESE,
  ar: DisplayLanguage.ARABIC,
} as Record<string, DisplayLanguage>;

export interface PersonalInformation {
  displayLanguage: DisplayLanguage;
  notificationSetting: boolean | null;
  email: string;
  name: string;
  password: string;
  passwordConfirm: string;
}

interface YourAccountProps {
  isLoading: boolean;
}

const YourAccount = ({ isLoading }: YourAccountProps) => {
  const { t } = useTranslation();
  const { role } = useAuthData();
  const { isUuumView } = useUuumInfluencerView();
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<PersonalInformation>();
  const [displayLanguageValue, notificationSettingValue] = watch(['displayLanguage', 'notificationSetting']);

  const languageOptions = LANGUAGE_OPTIONS.map(language => ({
    value: LanguageMapping[language.value],
    label: language.label,
  }));

  return (
    <div css={styles.container}>
      <div css={{ maxWidth: 648, width: '100%' }}>
        {isLoading ? (
          <ListLoading />
        ) : (
          <>
            <div css={styles.formInputSection}>
              <div css={styles.formTitle}>{t('Title.Your Account')}</div>
              <div css={styles.formDescription}>
                {t('Annotation.Edit your information and set notifications to receive')}
              </div>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.name}
                  isRequired
                  title="Name"
                  placeholder="Naoya Kurita"
                  {...register('name')}
                />
                <ErrorMessage message={errors.name?.message} />
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.email}
                  isRequired
                  title="Email Address"
                  placeholder="take@anymindgroup.com"
                  {...register('email')}
                />
                <ErrorMessage message={errors.email?.message} />
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.password}
                  title="Password"
                  placeholder=""
                  type="password"
                  {...register('password')}
                />
                <ErrorMessage message={errors.password?.message} />
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.passwordConfirm}
                  title="Confirm Password"
                  placeholder=""
                  type="password"
                  {...register('passwordConfirm')}
                />
                <ErrorMessage message={errors.passwordConfirm?.message} />
              </InputContainer>

              <InputContainer>
                <Select
                  error={!!errors.displayLanguage?.message}
                  isRequired
                  options={languageOptions}
                  title="Display Language"
                  value={displayLanguageValue || ''}
                  onChange={value => setValue('displayLanguage', value as DisplayLanguage)}
                />
                <ErrorMessage message={errors.displayLanguage?.message} />
              </InputContainer>

              {!isUuumView && role === UserRoles.INFLUENCER && (
                <InputContainer>
                  <Label css={styles.inputLabel} title="Notification" />
                  <CheckboxContainer>
                    <StyledCheckbox
                      checked={!!notificationSettingValue}
                      onChange={checked => setValue('notificationSetting', checked)}
                      label="Notify me of campaign information and payment"
                    />
                  </CheckboxContainer>
                </InputContainer>
              )}
            </div>

            <FormSubmitSection isSubmitting={isSubmitting} />

            {role === UserRoles.INFLUENCER && (
              <Link css={styles.deleteBtn} to={ROUTES.SETTINGS_ACCOUNT_DELETE}>
                {t('Button.Delete Account')}
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const InputContainer = styled.div({
  marginBottom: 24,
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& input': {
    borderRadius: 2,
    height: 32,
  },
});

const CheckboxContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  marginBottom: 16,
  gap: THEME.box.gaps.s,
});

const StyledCheckbox = styled(CheckBox)({
  '.checkbox-item-label': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  },
});

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  deleteBtn: css({
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.normal,
    marginTop: 24,

    '&:hover': {
      opacity: 0.7,
    },
  }),
  formDescription: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
  }),
  formInputSection: css({
    backgroundColor: THEME.colors.white,
    padding: 24,
  }),
  formTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 8,
  }),
  inputLabel: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,
  }),
};

export default YourAccount;
