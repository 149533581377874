import { css } from '@emotion/react';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { THEME } from '@src/libs/theme';

interface EmptyColumnProps {
  className?: string;
  colSpan?: number;
  link?: {
    href: LocationDescriptor;
    title: string;
  };
  title?: string;
  linkDescription?: string;
  noLinkDescription?: string;
}

const EmptyColumn = (props: EmptyColumnProps) => {
  const { t } = useTranslation();
  const {
    className,
    link,
    colSpan,
    title = 'General.Nothing has been found',
    linkDescription = 'General.Please try a different query or',
    noLinkDescription = 'General.Please try a different query',
  } = props;

  return (
    <td className={className} colSpan={colSpan} css={styles.wrapper}>
      <h6 css={styles.title}>{t(title)}</h6>
      <p css={styles.tableData}>
        {link ? t(linkDescription) : t(noLinkDescription)}
        {link && (
          <Link css={styles.link} to={link.href}>
            {link.title}
          </Link>
        )}
      </p>
    </td>
  );
};

const styles = {
  link: css({
    display: 'inline',
    color: '#179cd7',
    textDecoration: 'underline',
    transition: 'color 0.3s ease-in-out',
    marginLeft: 8,

    '&:hover': {
      color: '#1686b9',
    },
  }),
  tableData: css({
    marginTop: 8,
    color: '#757575',
    fontSize: THEME.font.sizes.normal,
  }),
  title: css({
    color: '#757575',
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 500,
  }),
  wrapper: css({
    height: 360,
    textAlign: 'center',
  }),
};

export default EmptyColumn;
