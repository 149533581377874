import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import Popover, { MenuItem } from '@src/components/atoms/Popover';
import { ThemeButton } from '@src/components/molecules';
import { SearchForm, Select } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { EmailListSortableField, EmailTemplateStatus, Order } from '@src/__generated__/globalTypes';

export interface FilterItems {
  keyword: string;
  orderByField: EmailListSortableField;
  orderBySequence: Order;
  status: EmailTemplateStatus;
}

interface EmailListFilterProps {
  values: FilterItems;
  onChange: (values: FilterItems) => void;
}

const EmailListFilter = ({ values, onChange }: EmailListFilterProps) => {
  const [filter, setFilter] = useState<FilterItems>(values);
  const { t } = useTranslation();
  const sortOptions = [
    { field: EmailListSortableField.OPEN_RATE, label: 'Highest Open Rate', order: Order.DESC },
    { field: EmailListSortableField.OPEN_RATE, label: 'Lowest Open Rate', order: Order.ASC },
    { field: EmailListSortableField.CLICK_RATE, label: 'Highest Click Rate', order: Order.DESC },
    { field: EmailListSortableField.CLICK_RATE, label: 'Lowest Click Rate', order: Order.ASC },
  ];
  const statusOptions = [
    { label: t('Draft'), value: EmailTemplateStatus.DRAFT },
    { label: t('Published'), value: EmailTemplateStatus.PUBLISHED },
    { label: t('Schedule'), value: EmailTemplateStatus.SCHEDULED },
  ];

  useEffect(() => {
    setFilter(values);
  }, [values.keyword, values.orderByField, values.orderBySequence, values.status]);

  let onCloseMenu: () => void;

  const onClickSortOrder = (orderByField: EmailListSortableField, orderBySequence: Order) => {
    onChange({ ...filter, orderByField, orderBySequence });
    onCloseMenu();
  };

  return (
    <div css={styles.container}>
      <SearchForm
        css={styles.searchForm}
        placeholder={t('TextForm.Search by Email Subject')}
        value={filter.keyword}
        onBlur={() => onChange(filter)}
        onChange={e => setFilter({ ...filter, keyword: e.target.value })}
        onEnterKeyPress={() => onChange(filter)}
      />
      <Select
        css={{ flex: 1 }}
        options={statusOptions}
        placeholder="Status"
        value={filter.status}
        onChange={value => onChange({ ...filter, status: value as EmailTemplateStatus })}
      />

      <Popover
        renderTrigger={<ThemeButton prefixIcon={<Icomoon icon="sort" size={20} />} text="Sort" width="82px" />}
        side="bottom"
        align="end"
        emitEvents={({ onClose }) => {
          onCloseMenu = onClose;
        }}
      >
        <>
          {sortOptions.map((option, index) => {
            const { field, label, order } = option;

            return (
              <MenuItem key={index} onClick={() => onClickSortOrder(field, order)}>
                {t(`MenuTitle.${label}`)}
              </MenuItem>
            );
          })}
        </>
      </Popover>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: '5px 5px 0 0',
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    gap: THEME.box.gaps.s,
  }),
  searchForm: css({
    width: '100%',

    [`@media (min-width: ${ViewportType.MEDIUM}px)`]: {
      flex: 4,
      width: 'auto',
    },

    '& input': {
      height: 32,
    },
  }),
};

export default EmailListFilter;
