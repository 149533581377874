import isAfter from 'date-fns/isAfter';
import * as jwtDecode from 'jwt-decode';
import {
  AnyXAuthSocialAccountType,
  BioSocialMediaType,
  SocialAccountType,
  AuthSocialAccountType,
} from '@src/__generated__/globalTypes';
import { connectSocialAuthState, useRecoil } from '@src/recoilAtoms';
import { FE_REDIRECT_MAPPING } from '../constant';
import { switchSocialMediaName } from '../socialMedia';
import { Token } from './types';

export const setToken = (token: string, refreshToken?: string | null) => {
  if (window.localStorage) {
    localStorage.setItem('accessToken', token);

    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  }
};

export const getToken = () => localStorage.getItem('accessToken') || '';

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const removeToken = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const checkSignedIn = () => {
  const token = getToken();
  if (!token) {
    return false;
  }

  let decoded: Token;

  try {
    decoded = jwtDecode<Token>(token);
  } catch (e) {
    removeToken();

    return false;
  }

  const expire = decoded.exp * 1000;
  const isNotExpired = isAfter(expire, Date.now());

  const isSignedIn = isNotExpired;

  if (!isSignedIn) {
    removeToken();
  }

  return isSignedIn;
};

export const getRedirectTypePath = (redirectType: FE_REDIRECT_MAPPING): string => {
  switch (redirectType) {
    case FE_REDIRECT_MAPPING.SIGNIN:
    case FE_REDIRECT_MAPPING.TALENT_SIGNIN:
      return 'signin';
    case FE_REDIRECT_MAPPING.TALENT_SIGNUP:
    case FE_REDIRECT_MAPPING.SIGNUP:
      return 'signup';
    case FE_REDIRECT_MAPPING.SIGNUP_ENABLED:
      return 'signup-enabled';
    case FE_REDIRECT_MAPPING.CONNECT:
      return 'connect';
    case FE_REDIRECT_MAPPING.SIGNUP_CONNECT:
      return 'signup-connect';
    case FE_REDIRECT_MAPPING.JOIN_CAMPAIGN:
      return 'join_campaign';
    case FE_REDIRECT_MAPPING.ANALYTICS_IG_RECONNECT:
      return 'analytics-reconnect';
    case FE_REDIRECT_MAPPING.LINE_INSTAGRAM_SIGNIN:
      return 'signin_with_line';
    case FE_REDIRECT_MAPPING.TIKTOK_RECONNECT:
      return 'tiktok_reconnect';
    case FE_REDIRECT_MAPPING.LINK_IN_BIO_CONNECT:
      return 'link_in_bio_connect';
    case FE_REDIRECT_MAPPING.MOBILE_SOCIAL_AUTH:
      return 'mobile_social_auth';
    case FE_REDIRECT_MAPPING.MOBILE_TIKTOK_CONNECT:
      return 'mobile_tiktok_connect';
    case FE_REDIRECT_MAPPING.TTCM_RECONNECT:
      return 'ttcm_reconnect';
    default:
      return '';
  }
};

export const getProvider = (
  socialMedia: AnyXAuthSocialAccountType | BioSocialMediaType | SocialAccountType | AuthSocialAccountType
) =>
  (socialMedia === SocialAccountType.INSTAGRAM ? AuthSocialAccountType.FACEBOOK : socialMedia) as AuthSocialAccountType;

export const getRedirectPath = (
  socialMedia: SocialAccountType | AuthSocialAccountType,
  redirectType: FE_REDIRECT_MAPPING
): string => {
  const redirectPath = `redirect/${getRedirectTypePath(redirectType)}`;

  const socialMediaName = switchSocialMediaName(socialMedia);

  return `${redirectPath}/${socialMediaName}`;
};

export const getCallbackUrl = (
  socialMedia: AnyXAuthSocialAccountType | AuthSocialAccountType | BioSocialMediaType | SocialAccountType,
  redirectType: FE_REDIRECT_MAPPING
): string => {
  const origin = location.origin;
  if (socialMedia === AuthSocialAccountType.TWITTER) {
    return `${origin}/redirect/twitter`;
  }

  const provider = getProvider(socialMedia);

  return `${origin}/${getRedirectPath(provider, redirectType)}`;
};

/*
 Connect
 */
export enum ConnectType {
  CONNECT_FB_PAGE = 'CONNECT_FB_PAGE',
  CONNECT_IG_ACCOUNT = 'CONNECT_IG_ACCOUNT',
}

export const getConnectSocialAuthState = () => {
  const { recoilState, resetRecoilState } = useRecoil(connectSocialAuthState);

  return {
    connectState: recoilState,
    resetConnectState: resetRecoilState,
  };
};
