import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

interface ReasonWidgetProps {
  className?: string;
  content: string;
  title: string;
}

const ReasonWidget = ({ className, content, title }: ReasonWidgetProps) => {
  const { t } = useTranslation();

  return (
    <div className={className} css={styles.container}>
      <Icomoon color="#ff5f5f" icon="warning" size={16} />
      <div>
        <div>{t(title)}</div>
        {/* the content is enter by user so it is dynamic and no need for translation */}
        <div>{content}</div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    background: '#ffecec',
    border: '1px solid #ff5f5f',
    borderRadius: 15,
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: 12,

    '& > div': {
      display: 'grid',
      gap: THEME.box.gaps.xs,

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.error,
        fontSize: 13,
        fontWeight: 700,
      },

      '& > div:nth-of-type(2)': {
        color: '#ff7a7a',
        fontSize: 13,
        whiteSpace: 'pre',
      },
    },
  }),
};

export default ReasonWidget;
