import { css } from '@emotion/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

const defaultWrappetText = 'The system is collecting data, please wait until the data is ready';

interface EnrichedChildren {
  type?: {
    displayName: string;
  };
}
interface BlurredBlockProps {
  children: React.ReactNode;
  isAvailableRate?: boolean;
  wrapperText?: string;
}

const BlurredBlock = ({ children, isAvailableRate, wrapperText = defaultWrappetText }: BlurredBlockProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div css={styles.focusedBlock}>
        <div css={styles.focusedContainer}>
          <Icomoon icon="invisible-circle-grey-light" size={48} />
          <p css={styles.offlineText}>{t(wrapperText)}</p>
        </div>
      </div>

      {React.Children.map(children, child => {
        let blurRadius = '5px';
        const elementChild = child as EnrichedChildren;

        if (elementChild?.type?.displayName === 'LegendBar' && isAvailableRate) {
          blurRadius = '0';
        }

        return <div css={{ filter: `blur(${blurRadius})` }}>{child}</div>;
      })}
    </>
  );
};

const styles = {
  focusedBlock: css({
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 3,
  }),
  focusedContainer: css({
    zIndex: 5,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  offlineText: css({
    fontSize: 13,
    color: THEME.font.colors.black.main,
    marginTop: 8,
  }),
};

export default BlurredBlock;
