import { css } from '@emotion/react';
import { LocationDescriptor } from 'history';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface MultiTextLinkColumnProps {
  className?: string;
  href: LocationDescriptor;
  data: ReactNode | string;
  children?: ReactElement<any>;
}

const MultiTextLinkColumn = (props: MultiTextLinkColumnProps) => {
  const { className, href, data, children } = props;

  return (
    <td className={className} css={{ padding: '0 8px' }}>
      <div css={{ alignItems: 'center', display: 'flex' }} title={typeof data === 'string' ? data : undefined}>
        <Link css={styles.tableData} to={href}>
          {data}
        </Link>
      </div>
      {children && <div css={{ margin: '10px 0' }}>{children}</div>}
    </td>
  );
};

const styles = {
  tableData: css({
    overflow: 'hidden',
    maxHeight: 32,
    fontSize: 13,
    lineHeight: '16px',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
  }),
};

export default MultiTextLinkColumn;
