import React from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router';
import { Empty } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { useGetFanOrdersQuery } from '@src/graphql/hooks';
import { usePageLayout } from '@src/libs/hooks';
import OrderDetails from './OrderDetails';

const FanOrders = () => {
  const params = useParams<Record<any, string>>();
  const { isMobileView } = usePageLayout();
  const { id } = params as { id: string };
  const { data, loading } = useGetFanOrdersQuery({
    variables: {
      input: {
        fanId: Number(id),
      },
    },
  });

  if (loading) {
    return <ListLoading />;
  }

  if (!data?.getFanOrders?.orders.length) {
    return <Empty />;
  }

  return (
    <div css={!isMobileView && styles.container}>
      {data?.getFanOrders?.orders.map(item => (
        <div css={styles.wrapper} key={item.orderId}>
          <OrderDetails {...item} />
        </div>
      ))}
    </div>
  );
};

const styles = {
  wrapper: css({
    marginBottom: 16,
  }),
  container: css({
    padding: '24px 24px 16px',
  }),
};

export default FanOrders;
