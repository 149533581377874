import { css } from '@emotion/react';
import * as React from 'react';
import { THEME } from '@src/libs/theme';

interface CarouselArrowProps {
  content: React.ReactElement;
}

const CarouselArrow = (props: CarouselArrowProps) => {
  const { content, ...rest } = props;

  return (
    <div css={styles.carouselNav} {...rest}>
      {content}
    </div>
  );
};

const styles = {
  carouselNav: css({
    background: THEME.colors.white,
    color: THEME.font.colors.gray.main,
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: THEME.font.sizes.normal,

    '&::before': {
      display: 'none',
    },

    '&:hover': {
      background: THEME.colors.white,
      color: THEME.font.colors.gray.main,
    },
  }),
};

export default CarouselArrow;
