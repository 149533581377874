import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeComparePostByIdQuery,
  InfluencerYoutubeComparePostByIdQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerYoutubeComparePostById.graphql';

export const useInfluencerYoutubeComparePostByIdQuery = (
  options?: QueryHookOptions<InfluencerYoutubeComparePostByIdQuery, InfluencerYoutubeComparePostByIdQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerYoutubeComparePostByIdQuery, InfluencerYoutubeComparePostByIdQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerYoutubeComparePostByIdLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerYoutubeComparePostByIdQuery, InfluencerYoutubeComparePostByIdQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerYoutubeComparePostByIdQuery, InfluencerYoutubeComparePostByIdQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
