import React from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import { Icomoon, Icon } from '@src/components/atoms';
import { useGetFanHeaderQuery, useGetLineChatQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import Activity from './Activity';
import FanDetail from './FanDetail';
import Empty from './Empty';
import Header from './Header';
import Orders from './Orders';

const ChatCustomerInformation = () => {
  const { matchPath, params, pathname, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { activeChat, fanId } = params;
  const pathOptions = activeChat && fanId ? { activeChat, fanId } : undefined;

  const cards = [
    {
      icon: 'address-book-tiffany-blue',
      title: 'Fan Detail',
      to: generatePath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_FAN_DETAILS, pathOptions),
    },
    {
      icon: 'clock-deep-blue-light',
      title: 'Activity',
      to: generatePath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_ACTIVITY, pathOptions),
    },
    {
      icon: 'clipboard-orange-light',
      title: 'Orders',
      to: generatePath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_ORDERS, pathOptions),
    },
  ];
  const isActivity = matchPath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_ACTIVITY, pathname);
  const isFanDetail = matchPath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_FAN_DETAILS, pathname);
  const isOrders = matchPath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_ORDERS, pathname);

  const { data } = useGetLineChatQuery({
    skip: !activeChat,
    variables: {
      input: {
        chatId: activeChat as string,
      },
    },
  });
  const { data: dataFanHeader } = useGetFanHeaderQuery({
    skip: !fanId,
    variables: {
      input: {
        fanId: Number(fanId),
      },
    },
  });

  const customerInfo = dataFanHeader?.getFanHeader;
  const lineInfo = data?.getLineChat;

  return (
    <div css={styles.container}>
      {activeChat && fanId ? (
        <div>
          {!(isMobileView && (isActivity || isFanDetail || isOrders)) && (
            <Header
              avatar={lineInfo?.avatar || ''}
              currencyId={customerInfo?.currencyId || ''}
              created={customerInfo?.created || ''}
              firstName={customerInfo?.firstName || ''}
              lastName={customerInfo?.lastName || ''}
              lastOrder={customerInfo?.lastOrder || ''}
              lineName={lineInfo?.name || ''}
              totalOrders={customerInfo?.totalOrders || 0}
              totalSpent={customerInfo?.totalSpent || 0}
            />
          )}

          <ContentContainer isMobileView={isMobileView} isNavigatorPage={!isActivity && !isFanDetail && !isOrders}>
            {isFanDetail ? (
              <FanDetail />
            ) : isActivity ? (
              <Activity />
            ) : isOrders ? (
              <Orders />
            ) : (
              <div>
                {cards.map((card, index) => {
                  const { icon, title, to } = card;

                  return (
                    <Card key={index} isMobileView={isMobileView} to={to}>
                      <div>
                        <Icomoon icon={icon} size={28} />
                      </div>
                      <div>{t(`MenuTitle.${title}`)}</div>
                      <Icon>
                        <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={10} />
                      </Icon>
                    </Card>
                  );
                })}
              </div>
            )}
          </ContentContainer>
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

const Card = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isMobileView: boolean }>(
  ({ isMobileView }) => ({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 12,
    gap: THEME.box.gaps.m,
    ...(!isMobileView
      ? { borderRadius: 7, boxShadow: THEME.box.shadows.outer, marginBottom: 16 }
      : { borderBottom: '1px solid #dee5ec' }),

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      display: 'flex',
      flex: 1,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  })
);

const ContentContainer = styled.div<{ isMobileView: boolean; isNavigatorPage: boolean }>(
  ({ isMobileView, isNavigatorPage }) => ({
    padding: isMobileView && isNavigatorPage ? '8px 0' : '16px',
  })
);

const styles = {
  container: css({
    boxShadow: THEME.box.shadows.outer,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      boxShadow: 'none',
    },
  }),
};

export default ChatCustomerInformation;
