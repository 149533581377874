import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerAllInstagramStoryPostsCountQuery,
  InfluencerAllInstagramStoryPostsCountQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerAllInstagramStoryPostsCount.graphql';

export const useInfluencerAllInstagramStoryPostsCountQuery = (
  options?: QueryHookOptions<
    InfluencerAllInstagramStoryPostsCountQuery,
    InfluencerAllInstagramStoryPostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerAllInstagramStoryPostsCountQuery, InfluencerAllInstagramStoryPostsCountQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};

export const useInfluencerAllInstagramStoryPostsCountLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerAllInstagramStoryPostsCountQuery,
    InfluencerAllInstagramStoryPostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerAllInstagramStoryPostsCountQuery, InfluencerAllInstagramStoryPostsCountQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
