import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { useFilter } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { EmailEventStatusForSearch } from '@src/__generated__/globalTypes';
import RecipientList from './RecipientList';
import RecipientListFilter, { FilterItems } from './RecipientListFilter';

interface RecipientProps {
  emailId: string;
}

const Recipient = ({ emailId }: RecipientProps) => {
  const [searchParams] = useSearchParams();
  const { filter, setFilter } = useFilter({
    keyword: searchParams.get('keyword') || '',
    status: (searchParams.get('status') || '') as EmailEventStatusForSearch,
  });

  return (
    <div css={styles.container}>
      <RecipientListFilter values={filter as FilterItems} onChange={setFilter} />
      <RecipientList emailId={emailId} filter={filter as FilterItems} />
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: '0 0 3px 3px',
    boxShadow: THEME.box.shadows.outer,
    padding: 24,
  }),
};

export default Recipient;
