import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetTagsAndFansRecipientsQuery, GetTagsAndFansRecipientsQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetTagsAndFansRecipients.graphql';

export const useGetTagsAndFansRecipientsQuery = (
  options?: QueryHookOptions<GetTagsAndFansRecipientsQuery, GetTagsAndFansRecipientsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetTagsAndFansRecipientsQuery, GetTagsAndFansRecipientsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetTagsAndFansRecipientsLazyQuery = (
  options?: LazyQueryHookOptions<GetTagsAndFansRecipientsQuery, GetTagsAndFansRecipientsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetTagsAndFansRecipientsQuery, GetTagsAndFansRecipientsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
