import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Empty, Icomoon } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { localizedDateFormatter } from '@src/libs/date';
import { useTranslation } from 'react-i18next';
import { useGetFanParticipationFormListQuery } from '@src/graphql/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { THEME } from '@src/libs/theme';

const FanForms = () => {
  const { i18n } = useTranslation();
  const { t, params } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const { id } = params as { id: string };
  const { data, loading } = useGetFanParticipationFormListQuery({
    variables: {
      input: {
        fanId: id,
      },
    },
  });

  if (loading) {
    return <ListLoading />;
  }

  if (!data?.getFanParticipationFormList?.forms.length) {
    return <Empty />;
  }

  return (
    <div css={[styles.container, isMobileView && styles.padding16]}>
      <h1 css={[styles.heading, isMobileView && styles.marginBottom22]}>{t('Participation form list')}</h1>
      {data.getFanParticipationFormList.forms.map(item => (
        <Link
          css={[styles.row, isMobileView && styles.paddingLeft8]}
          to={`/fans/${id}/forms/${item.submissionId}`}
          key={item.submissionId}
        >
          <Icomoon icon="clipboard-grey-dark" size={22} />
          <div css={styles.contentWrapper}>
            <p css={styles.title}>{item.formName}</p>
            <p css={styles.subTitle}>
              {localizedDateFormatter(item.submittedAt, 'dd MMM yyyy', i18n.language as AppLanguage)}
            </p>
          </div>
          <Icomoon css={styles.accordion} icon="arrow-down" size={10} />
        </Link>
      ))}
    </div>
  );
};

const styles = {
  container: css({
    padding: 24,
    backgroundColor: THEME.colors.white,
  }),
  accordion: css({
    transform: 'rotate(-90deg)',
    width: 16,
    height: 16,
  }),
  heading: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.subHeading,
    color: THEME.font.colors.black.main,
    marginBottom: 24,
  }),
  contentWrapper: css({
    flex: 1,
    marginLeft: 16,
  }),
  title: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
    marginBottom: 6,
    lineHeight: '100%',
  }),
  subTitle: css({
    fontSize: THEME.font.sizes.subordinate,
    color: THEME.font.colors.gray.main,
    lineHeight: '100%',
  }),
  row: css({
    border: '1px solid #eef3f7',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    padding: 16,

    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
  }),
  marginBottom22: css({
    marginBottom: 22,
  }),
  paddingLeft8: css({
    paddingLeft: 8,
  }),
  padding16: css({
    padding: 16,
  }),
};

export default FanForms;
