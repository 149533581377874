import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { AuthSocialAccountType, BioSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import * as SOCIAL_AUTH_REDIRECT_URL_FOR_INFLUENCER from '@src/graphql/queries/SocialAuthRedirectUrlForInfluencer.graphql';
import {
  SocialAuthRedirectUrlForInfluencerQuery,
  SocialAuthRedirectUrlForInfluencerQueryVariables,
} from '@src/__generated__/globalTypes';
import { UNEXPECTED_ERROR } from '../error';
import { getCallbackUrl, getProvider } from '../auth';
import { FE_REDIRECT_MAPPING } from '../constant';
import useQueryHelper from './useQueryHelper';

export const useRedirectUrl = (redirectType: FE_REDIRECT_MAPPING) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const getRedirectUrl = async (
    socialMedia: AuthSocialAccountType | BioSocialMediaType | SocialAccountType
  ): Promise<string | null> => {
    const variables = {
      input: {
        provider: getProvider(socialMedia),
        callbackUrl: getCallbackUrl(socialMedia, redirectType),
      },
    };
    let redirectUri = null;
    setLoading(true);

    try {
      const { data } = await client.query<
        SocialAuthRedirectUrlForInfluencerQuery,
        SocialAuthRedirectUrlForInfluencerQueryVariables
      >({
        query: SOCIAL_AUTH_REDIRECT_URL_FOR_INFLUENCER,
        variables,
      });
      if (!data?.socialAuthRedirectUrlForInfluencer) {
        enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });

        return null;
      }
      redirectUri = data.socialAuthRedirectUrlForInfluencer.redirectUri;
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }

    return redirectUri;
  };

  return {
    getRedirectUrl,
    loading,
  };
};
