import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { List } from '@src/components/atoms';
import {
  ListLoading,
  Pager,
  SliderTableComponents,
  SliderTableStyledComponents,
  ThemeButton,
} from '@src/components/molecules';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import { getCurrentPage, getOffset, getPageInfo } from '@src/components/molecules/Pager/helpers';
import StatusIndicator from '@src/components/organisms/MyPage/Emails/StatusIndicator';
import { useGetEmailListCountQuery, useGetEmailListQuery, useRemoveEmailsMutation } from '@src/graphql/hooks';
import { LIMIT } from '@src/libs/constant';
import { formatPercent } from '@src/libs/format';
import { useDirLayout, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import { EmailTemplateStatus } from '@src/__generated__/globalTypes';
import EmptyList from './EmptyList';
import { FilterItems } from './EmailListFilter';

interface EmailListProps {
  filter: FilterItems;
}

const EmailList = ({ filter }: EmailListProps) => {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { isRtl } = useDirLayout();
  const currentPage = getCurrentPage();
  const checkedIdsLength = checkedIds.length;
  const { data, loading } = useGetEmailListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        keyword: filter.keyword,
        limit: LIMIT,
        offset: getOffset(currentPage),
        orderBy: { field: filter.orderByField, order: filter.orderBySequence },
        ...(filter.status && { status: filter.status }),
      },
    },
  });
  const { data: dataEmailListCount } = useGetEmailListCountQuery({
    variables: {
      input: {
        keyword: filter.keyword,
        ...(filter.status && { status: filter.status }),
      },
    },
  });
  const [removeEmails] = useRemoveEmailsMutation({
    refetchQueries: ['GetEmailList'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      setDialogOpen(false);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  useEffect(() => {
    if (isMobileView) {
      setCheckedIds([]);
      setDialogOpen(false);
    }
  }, [isMobileView]);

  const onClickAllChecked = (status: boolean) => {
    const items = [...checkedIds];
    emailList.forEach(email => {
      const index = items.findIndex(checkedId => checkedId === email.id);
      if (status && index >= 0) {
        items.splice(index, 1);
      } else if (!status && index < 0) {
        items.push(email.id);
      }
    });
    setCheckedIds(items);
  };

  const onClickCheck = (id: string) => {
    const items = [...checkedIds];
    const index = items.findIndex(item => item === id);
    if (index > -1) {
      items.splice(index, 1);
    } else {
      items.push(id);
    }
    setCheckedIds(items);
  };

  const onDeleteEmails = () => {
    removeEmails({
      variables: {
        input: {
          emailIds: checkedIds.map(emailId => Number(emailId)),
        },
      },
    });
  };

  const emailList = data?.getEmailList?.emails || [];
  const getCheckedItemStatus = () => {
    let isAllCheckedStatus = checkedIds.length > 0;
    let isItemSelectedStatus = false;

    emailList.every(email => {
      if (!checkedIds.includes(email.id)) {
        isAllCheckedStatus = false;
      } else {
        isItemSelectedStatus = true;
      }

      if (!isAllCheckedStatus && isItemSelectedStatus) {
        return false;
      }

      return true;
    });

    return {
      isAllChecked: isAllCheckedStatus,
      isItemSelected: isItemSelectedStatus,
    };
  };
  const { isAllChecked, isItemSelected } = getCheckedItemStatus();
  const isEmptyEmails = !emailList.length;
  const totalCount = dataEmailListCount?.getEmailListCount?.total || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  if (loading) {
    return <ListLoading />;
  }

  return (
    <>
      <Dialog
        closeButtonProps={{
          styles: blackCircleCloseStyles,
        }}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Delete',
          theme: 'red',
          onClick: onDeleteEmails,
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div css={styles.dialogContent}>
          <div>{t('Title.Delete Email List')}</div>
          <div>{t('Annotation.Delete Email List 1')}</div>
          <div>{t('Annotation.Delete Email List 2')}</div>
        </div>
      </Dialog>

      <SliderTableComponents.Wrapper css={styles.container}>
        <SliderTableComponents.Container>
          <SliderTableComponents.SliderSection>
            <SliderTableComponents.SliderTable>
              <Thead isItemSelected={checkedIdsLength > 0}>
                <tr>
                  {!isMobileView && (
                    <List.HeaderCheckboxColumn
                      checked={isAllChecked || isItemSelected}
                      css={isRtl ? { padding: '0 16px 0 0' } : { padding: '0 0 0 16px' }}
                      indeterminate={!isAllChecked && isItemSelected}
                      onChange={checked => onClickAllChecked(!checked)}
                    />
                  )}
                  {isItemSelected ? (
                    <th colSpan={6} css={{ height: 48, paddingLeft: '8px' }}>
                      <div css={styles.actionContainer}>
                        <div>{t('Selected', { count: checkedIdsLength })}</div>
                        {!isAllChecked && <div onClick={() => onClickAllChecked(false)}>{t('Select all items')}</div>}
                        <div>
                          <ThemeButton text="Delete" theme="red" onClick={() => setDialogOpen(true)} />
                        </div>
                      </div>
                    </th>
                  ) : (
                    <>
                      <List.HeaderColumn title="Subject" css={{ paddingLeft: '8px' }} />
                      <List.HeaderColumn title="Status" />
                      {!isMobileView && (
                        <>
                          <List.HeaderColumn title="Scheduled Date" />
                          <List.HeaderColumn title="Sent Date" />
                          <List.HeaderColumn title="Open Rate" />
                          <List.HeaderColumn title="Click Rate" css={{ paddingRight: '8px' }} />
                        </>
                      )}
                    </>
                  )}
                </tr>
              </Thead>

              <tbody>
                {emailList.map(email => {
                  const { clickRate, id, openRate, scheduleDate, sentAt, status, subject } = email;
                  const isChecked = checkedIds.includes(id);

                  return (
                    <SliderTableStyledComponents.StyledRowNew css={isChecked ? styles.activeTableRow : {}} key={id}>
                      {!isMobileView && (
                        <List.HeaderCheckboxColumn
                          checked={isChecked}
                          css={styles.columnCheckbox}
                          onChange={() => onClickCheck(id)}
                        />
                      )}
                      <List.TextLinkColumn
                        css={styles.linkColumn}
                        data={subject}
                        href={
                          [EmailTemplateStatus.DRAFT, EmailTemplateStatus.SCHEDULED].includes(status)
                            ? generatePath(ROUTES.MY_PAGE_EMAILS_EDIT_COMPOSE, { id })
                            : generatePath(ROUTES.MY_PAGE_EMAILS_EDIT_OVERVIEW, { id })
                        }
                      />
                      <List.TextColumn data={<StatusIndicator status={status} />} />
                      {!isMobileView && (
                        <>
                          {/* Note: API is returning timestamp based on the logged in user's country, we don't need to convert to local timezone */}
                          <List.TextColumn
                            data={scheduleDate ? format(new Date(scheduleDate), 'MMM dd, yyyy hh:mm a') : '-'}
                            css={{ minWidth: '120px' }}
                          />
                          <List.TextColumn data={sentAt ? format(new Date(sentAt), 'MMM dd, yyyy hh:mm a') : '-'} />
                          <List.TextColumn data={formatPercent(openRate)} />
                          <List.TextColumn data={formatPercent(clickRate)} />
                        </>
                      )}
                    </SliderTableStyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderTableComponents.SliderTable>
          </SliderTableComponents.SliderSection>
        </SliderTableComponents.Container>

        {isEmptyEmails && <EmptyList />}
      </SliderTableComponents.Wrapper>

      <Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </>
  );
};

const Thead = styled.thead<{ isItemSelected: boolean }>(({ isItemSelected }) => ({
  backgroundColor: isItemSelected ? '#27313b' : '#f6f8fa',
  borderBottom: '1px solid #dfe7ec',
  borderTop: '1px solid #dfe7ec',
}));

const styles = {
  actionContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.white,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      marginRight: 16,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.white,
      cursor: 'pointer',
      fontSize: THEME.font.sizes.normal,
      textDecoration: 'underline',
    },

    '& > div:last-child': {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',

      '& > button': {
        fontSize: THEME.font.sizes.subordinate,
        marginRight: 16,
        width: 'max-content',

        '.btn-text': {
          padding: '0 16px',
        },
      },
    },
  }),
  activeTableRow: css({
    backgroundColor: '#fffde7',

    '& > td': {
      backgroundColor: '#fffde7',
    },
  }),
  columnCheckbox: css({
    borderTop: '1px solid #efefef',
    padding: '0 0 0 16px',

    '[dir="rtl"] &': {
      padding: '0 16px 0 0',
    },
  }),
  container: css({
    border: 'none',
    boxShadow: THEME.box.shadows.outer,
    margin: 0,
    padding: 0,
  }),
  dialogContent: css({
    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 24,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 16,
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.error,
      fontSize: THEME.font.sizes.normal,
    },
  }),
  linkColumn: css({
    width: 300,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      width: '80%',
    },

    '& > div > a': {
      color: THEME.font.colors.blue.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },
  }),
};

export default EmailList;
