import { useState } from 'react';

interface UseIntervalIncrementProps {
  increment?: number;
  maxCount?: number;
  milliseconds?: number;
  reset?: boolean;
}

const useIntervalIncrement = (props?: UseIntervalIncrementProps) => {
  const { increment = 1, maxCount, milliseconds = 1000, reset = true } = { ...props };
  const [counter, setCounter] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setTimeout> | undefined>(undefined);

  const start = () => {
    setIntervalId(
      setInterval(
        () => setCounter(count => (maxCount ? (count < maxCount ? count + increment : count) : count + increment)),
        milliseconds
      )
    );
  };

  const stop = () => {
    clearInterval(intervalId);
    setIntervalId(undefined);
    if (reset) {
      setCounter(0);
    }
  };

  return {
    counter,
    start,
    stop,
  };
};

export default useIntervalIncrement;
