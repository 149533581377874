import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { AnyXEngagementCampaignStatus } from '@src/__generated__/globalTypes';

interface StatusMessageProps {
  status: AnyXEngagementCampaignStatus;
}

const StatusMessage = ({ status }: StatusMessageProps) => {
  const { t } = useTranslation();

  const { actionColor, description, icon, iconColor, title } = (() => {
    switch (status) {
      case AnyXEngagementCampaignStatus.DRAFTING:
        return {
          actionColor: '#c3def7',
          description: 'Make sure to upload the video with meeting the requrements below',
          icon: 'film-outlined',
          iconColor: '#3992e5',
          title: 'Upload draft video',
        };
      case AnyXEngagementCampaignStatus.POSTING:
        return {
          actionColor: '#c3def7',
          description: 'Make sure to upload the video with meeting the requrements below',
          icon: 'film-outlined',
          iconColor: '#3992e5',
          title: 'Post video',
        };
      case AnyXEngagementCampaignStatus.REVIEWING:
        return {
          actionColor: '',
          description: 'Your video is currently beign reviewed by platform and brand',
          icon: 'clock-anti-clockwise',
          iconColor: '#ffb63d',
          title: 'Awaiting review',
        };
      case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
        return {
          actionColor: '#ffcfcf',
          description: 'See actions available fro your rejected video',
          icon: 'warning',
          iconColor: '#ff5f5f',
          title: `Your video wasn't approved by platform`,
        };
      default:
        return {
          actionColor: '',
          description: 'The collaboration is complete',
          icon: 'check',
          iconColor: '#40b87c',
          title: 'Complete',
        };
    }
  })();

  return (
    <div css={styles.container}>
      {actionColor && <ActionHint color={actionColor}>{t('Action required')}</ActionHint>}
      <div css={styles.titleContainer}>
        <Icomoon color={iconColor} icon={icon} size={16} />
        <div>{t(`Title.${title}`)}</div>
      </div>
      <div css={styles.description}>{t(`Annotation.${description}`)}</div>
    </div>
  );
};

const ActionHint = styled.div<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  borderRadius: 35,
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.hint,
  marginBottom: 12,
  padding: '2px 8px',
  width: 'fit-content',
}));

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 11,
    boxShadow: THEME.box.shadows.outer,
    marginBottom: 24,
    padding: 16,
    width: 'fill-available',
  }),
  description: css({
    color: THEME.font.colors.gray.main,
    fontSize: 13,
  }),
  titleContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginBottom: 8,

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  }),
};

export default StatusMessage;
