import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, Icon } from '@src/components/atoms';
import { useAuthData, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { InfluencerType } from '@src/__generated__/globalTypes';

const Menu = () => {
  const { creatorType } = useAuthData();
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  const menu = [
    {
      description: `View and edit account's information`,
      icon: 'user-grey',
      title: 'Account',
      to: ROUTES.SETTINGS_ACCOUNT,
    },
    {
      description: 'View and edit the address information to ship products',
      icon: 'address-book-grey-light',
      title: 'Address',
      to: ROUTES.SETTINGS_ADDRESS,
    },
    {
      description: 'Connect with your social account to use Analytics data and get sponsorship campaign',
      icon: 'link-grey-light',
      title: 'Social Connect',
      to: ROUTES.SETTINGS_SOCIAL_CONNECT,
    },
    {
      description: 'Edit your information and set notifications to receive',
      icon: 'users-grey-light',
      isHidden: creatorType !== InfluencerType.PRO,
      title: 'Users',
      to: ROUTES.SETTINGS_USERS,
    },
  ];

  return (
    <div css={styles.container}>
      {!isMobileView && <div css={styles.title}>{t(`MenuTitle.Settings`)}</div>}

      <div css={styles.menuContainer}>
        {menu
          .filter(item => !item.isHidden)
          .map(item => (
            <div css={styles.card} key={item.title}>
              {isMobileView ? (
                <Link css={styles.mobileCard} to={item.to}>
                  <div>
                    <Icomoon icon={item.icon} size={20} />
                  </div>
                  <div>
                    <CardTitle>{t(`MenuTitle.${item.title}`)}</CardTitle>
                    <CardDescription>{t(`Annotation.${item.description}`)}</CardDescription>
                  </div>
                  <div>
                    <Icon>
                      <Icomoon color="#6e7c89" css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={10} />
                    </Icon>
                  </div>
                </Link>
              ) : (
                <Link css={styles.desktopCard} to={item.to}>
                  <div>
                    <div>
                      <Icomoon icon={item.icon} size={32} />
                    </div>
                    <CardTitle>{t(`MenuTitle.${item.title}`)}</CardTitle>
                  </div>
                  <div>
                    <CardDescription>{t(`Annotation.${item.description}`)}</CardDescription>
                  </div>
                </Link>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

const CardDescription = styled.div({
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.subordinate,
});

const CardTitle = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
});

const styles = {
  card: css({
    width: '100%',

    [`@media (min-width: ${ViewportType.MEDIUM}px)`]: {
      width: '70%',
    },

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      width: '25%',
    },
  }),
  container: css({
    display: 'grid',
    justifyContent: 'center',
    margin: 24,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: 16,
    },
  }),
  desktopCard: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    cursor: 'pointer',
    flexWrap: 'wrap',
    margin: 8,
    minHeight: 131,
    padding: 16,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      flexBasis: '100%',

      '& > div:nth-of-type(1)': {
        marginRight: 8,
      },
    },
  }),
  menuContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 1080,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      justifyContent: 'center',
    },
  }),
  mobileCard: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
    padding: 16,

    '& > div:nth-of-type(1)': {
      alignItems: 'flex-start',
      display: 'flex',
      flexBasis: '15%',
      justifyContent: 'center',
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      flexBasis: '75%',

      '& > div:nth-of-type(1)': {
        marginBottom: 3,
      },
    },

    '& > div:nth-of-type(3)': {
      alignItems: 'center',
      display: 'flex',
      flexBasis: '10%',
      justifyContent: 'center',
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    margin: 8,
  }),
};

export default Menu;
