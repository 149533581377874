import { CRM_URL } from '@src/libs/requests';
import { downloadFile } from '@src/libs/requests/downloadFile';

const useRecipient = () => {
  const exportEmailRecipientsCsv = async (emailId: string, ids: string[]) => {
    const idsQueryString = ids.join(',');
    const url = new URL(`${CRM_URL}emails/recipients/${emailId}?ids=${idsQueryString}`);

    await downloadFile({ url, filenamePrefix: 'email_recipients', extension: '.csv' });
  };

  return { exportEmailRecipientsCsv };
};

export default useRecipient;
