import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { MarketplacePostQuery, MarketplacePostQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/MarketplacePost.graphql';

export const useMarketplacePostQuery = (
  options?: QueryHookOptions<MarketplacePostQuery, MarketplacePostQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<MarketplacePostQuery, MarketplacePostQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useMarketplacePostLazyQuery = (
  options?: LazyQueryHookOptions<MarketplacePostQuery, MarketplacePostQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<MarketplacePostQuery, MarketplacePostQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
