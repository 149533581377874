import React, { useCallback, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { THEME } from '@src/libs/theme';
import { SelectedChatStateType } from '@src/recoilAtoms';
import ConversationArea from './ConversationArea';
import ChatActionBar from './ChatActionBar';
import { useChatMessages } from './useChatMessages';

const ChatWindow = ({ hasMobileLayout, ...recoilState }: SelectedChatStateType & { hasMobileLayout: boolean }) => {
  const [scrollHeight, setScrollHeight] = useState<number | undefined>();

  const {
    fetchOlder,
    handleSendMessages,
    chatEvents,
    channelUserName,
    textareaValue,
    setTextareaValue,
    activeChatId,
    sendMessageLoading,
  } = useChatMessages();

  const setConversationHeight = useCallback(() => {
    const simulatorHeight = document.getElementsByClassName('chat_body_wrapper')[0].clientHeight; // mobile_body_wrapper in anyChat
    const replyAreaHeight = document.getElementsByClassName('reply_area_wrapper')[0].clientHeight;
    const conversationAreaHeight = simulatorHeight - replyAreaHeight;

    setScrollHeight(conversationAreaHeight);
  }, []);

  useEffect(() => {
    setConversationHeight();
  }, [scrollHeight, hasMobileLayout, activeChatId]);

  useEffect(() => () => window.removeEventListener('keypress', readKey), []);
  const readKey = () => new Promise(resolve => window.addEventListener('keypress', resolve, { once: true }));

  return (
    <div css={styles.chatWindow}>
      <div className="chat_body_wrapper" css={styles.chatBodyWrapper}>
        <ConversationArea
          channelUserName={channelUserName}
          scrollHeight={scrollHeight}
          chatEvents={chatEvents}
          fetchOlder={fetchOlder}
        />

        <div
          className="reply_area_wrapper"
          css={[styles.replyAreaWrapper, hasMobileLayout ? { paddingBottom: 0 } : '']}
        >
          <div css={[styles.replyArea, hasMobileLayout ? { margin: 0 } : '']}>
            {/* TODO: Action Popup after tempalates|emojis being implemented */}

            <div css={[styles.textEditorWrapper, hasMobileLayout ? styles.textEditorWrapperMobile : '']}>
              {hasMobileLayout && recoilState.isChartStateMenuOpen ? (
                <ChatActionBar {...recoilState} css={{ borderBottom: 'none' }} />
              ) : (
                <div css={styles.textEditor}>
                  <TextareaAutosize
                    css={styles.textArea}
                    minRows={2}
                    maxRows={10}
                    value={textareaValue}
                    onChange={e => {
                      setTextareaValue(e.target.value);
                    }}
                    // not in the spec but easy for testing, might remove later
                    onKeyPress={async e => {
                      if (e.key === 'Enter' && e.shiftKey) {
                        e.preventDefault();
                        await readKey().then(handleSendMessages);
                      }
                    }}
                  />
                </div>
              )}

              <div css={styles.actionButtons}>
                <div css={styles.toolsBlock}>
                  {/* TODO: implement tools functions */}
                  {/* <Icomoon icon="smile-grey-light" />
                  <Icomoon icon="blocks" />
                  <Icomoon icon="clipboard-pencil-grey-light" />
                  <Icomoon icon="paperclip" /> */}
                </div>
                <ThemeButton
                  text={hasMobileLayout ? '' : 'Send'}
                  onClick={handleSendMessages}
                  loading={sendMessageLoading}
                  css={[
                    styles.send,
                    {
                      backgroundColor: sendMessageLoading
                        ? '#6e7c89 !important'
                        : hasMobileLayout
                        ? '#3892e5 !important'
                        : '#fff !important',
                    },
                  ]}
                  skipTranslation={hasMobileLayout}
                  postfixIcon={
                    hasMobileLayout ? (
                      <Icomoon css={[{ height: '20px !important', width: '28px !important' }]} icon="send-white" />
                    ) : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;

const styles = {
  chatWindow: css({
    display: 'flex',
    flex: 1,
    flexGrow: 1,
  }),
  chatBodyWrapper: css({
    position: 'relative',
    height: '100%',
    flex: 1,
  }),
  replyAreaWrapper: css({
    position: 'absolute',
    bottom: 0,
    paddingBottom: 12,
    width: '100%',
  }),
  replyArea: css({
    margin: '0 8px',
    backgroundColor: THEME.colors.white,
  }),
  textEditorWrapper: css({
    margin: '8px 0',
    backgroundColor: THEME.colors.white,
    border: THEME.box.borders.gray.main,
    boxSizing: 'border-box',
    borderRadius: 5,
  }),
  textEditorWrapperMobile: css({
    margin: 0,
    borderRight: 'none',
    borderLeft: 'none',
    borderRadius: 0,
  }),
  textEditor: css({
    overflow: 'hidden',
    borderTopRightRadius: 5,
  }),
  textArea: css({
    display: 'block',
    width: 'calc(100% - 24px)',
    resize: 'none',
    padding: 12,
  }),
  actionButtons: css({
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #dee5ec',
    padding: '8px 16px',
  }),
  toolsBlock: css({
    display: 'flex',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    alignItems: 'center',
  }),
  send: css({
    borderRadius: 5,
    color: THEME.font.colors.blue.main,
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    textTransform: 'capitalize',
    border: 'none',
    width: 56,

    '&:hover': {
      opacity: 0.8,
    },

    '&:focus': {
      outline: 'unset',
    },
  }),
};
