import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { AllCampaignsForYourJobCountsQuery } from '@src/__generated__/globalTypes';
import QUERY from '../queries/AllCampaignsForYourJobCounts.graphql';

export const useAllCampaignsForYourJobCountsQuery = (options?: QueryHookOptions<AllCampaignsForYourJobCountsQuery>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<AllCampaignsForYourJobCountsQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useAllCampaignsForYourJobCountsLazyQuery = (
  options?: LazyQueryHookOptions<AllCampaignsForYourJobCountsQuery>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<AllCampaignsForYourJobCountsQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
