import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useAnyXSocialAuthSignInInstagramLineMutation } from '@src/graphql/hooks';
import { useQueryHelper } from '@src/libs/hooks';
import { signInState, useRecoil } from '@src/recoilAtoms';

const useSignInInstagramLine = () => {
  const { recoilState, setRecoilState, resetRecoilState } = useRecoil(signInState);
  const { enqueueSnackbar, t } = useQueryHelper();

  const getLineIdToken = () => recoilState.lineIdToken || '';

  const setLineIdToken = (lineIdToken: string) => {
    setRecoilState({ lineIdToken });
  };

  const removeLineIdToken = () => {
    resetRecoilState();
  };

  const [anyXSocialAuthSignInInstagramLine] = useAnyXSocialAuthSignInInstagramLineMutation({
    onCompleted: () => {
      sendAmplitudeEvent(eventTypes.signInInstagramLine);
      window.location.replace('https://line.me/R/nv/chat');
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    anyXSocialAuthSignInInstagramLine,
    getLineIdToken,
    removeLineIdToken,
    setLineIdToken,
  };
};

export default useSignInInstagramLine;
