import React, { useState, useEffect, useMemo } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { CRMChatTypeFilter } from '@src/__generated__/globalTypes';
import debounce from 'lodash/debounce';
import { LIMIT, POLL_INTERVAL } from '@src/libs/constant';
import { css } from '@emotion/react';
import { useGetLineChatCountQuery, useGetLineChatsQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { selectedChatState, useRecoil } from '@src/recoilAtoms';
import { LineChat } from '@src/__generated__/globalTypes';
import { NoFansConnected, NoLineConnected } from '../ChatView/EmptyChats';
import ChatListFilter from './ChatFilter';
import ChatsList from './ChatsList';
import { ChatFilterFormValues } from './types';

interface ChatListSectionProps {
  hasMobileLayout?: boolean;
  needsToConnectLine?: boolean;
}
const ChatListSection = ({ hasMobileLayout, needsToConnectLine }: ChatListSectionProps) => {
  const { params, navigate, pathname, t } = useQueryHelper();
  const [filter, setFilter] = useState<ChatFilterFormValues>({ type: CRMChatTypeFilter.ACTIVATED, keyword: '' });
  const [currentLimit, setCurrentLimit] = useState(LIMIT);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [allChats, setAllChats] = useState<LineChat[]>([]);
  const { setRecoilState: setSelectedChatState } = useRecoil(selectedChatState);
  const { data, fetchMore, refetch } = useGetLineChatsQuery({
    variables: {
      input: {
        keyword: filter?.keyword,
        typeFilter: filter?.type,
        offset: 0,
        limit: currentLimit,
      },
    },
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getLineChats }) => {
      if (!!getLineChats?.chats) {
        setAllChats(getLineChats.chats);
      }
    },
  });
  const { data: dataLineChatCount, refetch: refetchLineChatCount } = useGetLineChatCountQuery({
    variables: {
      input: {
        keyword: filter.keyword,
      },
    },
  });

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((inputValue: string) => {
        refetch({
          input: {
            keyword: inputValue,
            typeFilter: filter?.type,
            offset: 0,
            limit: LIMIT,
          },
        });
        refetchLineChatCount({
          input: {
            keyword: inputValue,
          },
        });
      }, 300),
    []
  );

  const { activatedCount = 0, assignedToMe = 0 } = dataLineChatCount?.getLineChatCount || {};

  useEffect(() => {
    const allChatsData = data?.getLineChats?.chats;

    if (!!allChatsData?.length && !hasMobileLayout && !params.activeChat) {
      navigate(`${pathname}/${allChatsData[0].chatId}/${allChatsData[0].fanId || ''}`, { replace: true });
    }
  }, [data?.getLineChats?.chats.length]);

  useEffect(() => {
    if (dataLineChatCount?.getLineChatCount?.activatedCount !== undefined) {
      setSelectedChatState(prevState => ({
        ...prevState,
        hasNoChats: !dataLineChatCount?.getLineChatCount?.activatedCount,
      }));
    }
  }, [dataLineChatCount?.getLineChatCount?.activatedCount]);

  const handleFetchMore = async () => {
    setRefetchLoading(true);

    await fetchMore({
      variables: {
        input: {
          keyword: filter?.keyword,
          typeFilter: filter?.type,
          offset: 0,
          limit: currentLimit + LIMIT,
        },
        countInput: {
          keyword: filter?.keyword,
        },
      },
    });

    setCurrentLimit(prevLimit => prevLimit + LIMIT);
    setRefetchLoading(false);
  };

  const renderEmptyListContent = () => {
    if (hasMobileLayout && activatedCount === 0) {
      return needsToConnectLine ? <NoLineConnected /> : <NoFansConnected />;
    }

    return <p css={styles.emptyList}>{t('There are no chats')}</p>;
  };

  const disabledFilter = !!needsToConnectLine || activatedCount === 0;

  return (
    <div css={styles.chatListsSection}>
      <ChatListFilter
        filter={filter}
        setFilter={setFilter}
        assignedToMeNum={assignedToMe}
        totalNum={activatedCount}
        onKeywordSearch={debouncedSearchHandler}
        disabled={disabledFilter}
      />
      {!allChats?.length ? (
        renderEmptyListContent()
      ) : (
        <ChatsList
          chats={allChats}
          loading={refetchLoading}
          fetchMore={handleFetchMore}
          assignedToMeNum={assignedToMe}
          totalNum={activatedCount}
          filter={filter}
          hasMobileLayout={hasMobileLayout}
        />
      )}
    </div>
  );
};

const styles = {
  chatListsSection: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
  emptyList: css({
    margin: 'auto',
    color: THEME.font.colors.black.main,
  }),
};

export default ChatListSection;
