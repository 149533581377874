import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Avatar, Icomoon } from '@src/components/atoms';
import { JAPANESE_LANG } from '@src/libs/constant';
import { THEME } from '@src/libs/theme';

const SelectFacebookPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>{t('Title.Connect your Facebook Page')}</div>
      <div>
        {t(
          `Annotation.You'll use your Facebook Page to connect a product catalog to Instagram If you don't see your Page here, make sure you're an admin of the Page`
        )}
      </div>
      <div>
        <Avatar title="Any_account" size={38} />
        <div>
          <div>Any_account</div>
          <div>{t('Personal Blog likes', { count: 0 })}</div>
        </div>
      </div>
      {i18n.language === JAPANESE_LANG ? (
        <div css={styles.createANewFacebookPage}>
          <div>{t('Create a new Facebook Page')}</div>
          <Icomoon icon="arrow-down" size={10} />
        </div>
      ) : (
        <div css={styles.actionContainer}>
          <div>
            <Avatar title="O" size={32} />
            <div>
              <div>{t(`One More Page I can't stop doing this`)}</div>
              <div>{t('Real Estate likes', { count: 0 })}</div>
            </div>
          </div>

          <div>{t('Button.Next')}</div>
          <div>{t(`Button.Don't Connect to Facebook Now`)}</div>
        </div>
      )}
    </div>
  );
};

const styles = {
  actionContainer: css({
    bottom: 0,
    height: 135,
    left: 0,
    position: 'absolute',
    width: '100%',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      borderBottom: '1px solid #c6c5c7',
      display: 'flex',
      gap: THEME.box.gaps.s,
      marginBottom: 16,
      padding: 8,
      width: 'calc(100% - 16px)',

      '& > div:nth-of-type(2)': {
        display: 'grid',
        flex: 1,
        gap: THEME.box.gaps.xs,

        '& > div:nth-of-type(1)': {
          color: THEME.font.colors.black.main,
          fontSize: 9,
          fontWeight: 600,
        },

        '& > div:nth-of-type(2)': {
          color: THEME.font.colors.gray.main,
          fontSize: 9,
        },
      },

      '&::after': {
        border: '1px solid #6e7c89',
        borderRadius: '50%',
        content: '""',
        display: 'block',
        height: 12,
        width: 12,
      },
    },

    '& > div:nth-of-type(2)': {
      alignItems: 'center',
      background: '#0095f6',
      borderRadius: 3,
      color: THEME.font.colors.white,
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 500,
      height: 32,
      justifyContent: 'center',
      left: -12,
      position: 'absolute',
      width: 306,
    },

    '& > div:nth-of-type(3)': {
      color: '#91cae5',
      fontSize: THEME.font.sizes.hint,
      fontWeight: 600,
      marginTop: 56,
      textAlign: 'center',
    },
  }),
  container: css({
    background: '#e5e5e5',
    borderRadius: 32,
    height: 232,
    padding: '24px 16px',
    position: 'relative',
    width: 248,

    '& > div:nth-of-type(1)': {
      color: '#000',
      fontSize: 14.5,
      fontWeight: 700,
      marginBottom: 4,
      textAlign: 'center',
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: 9,
      marginBottom: 8,
      textAlign: 'center',
    },

    '& > div:nth-of-type(3)': {
      alignItems: 'center',
      background: THEME.colors.white,
      borderRadius: 8,
      boxShadow: THEME.box.shadows.outer,
      display: 'flex',
      gap: THEME.box.gaps.s,
      height: 38,
      left: -12,
      padding: '10px 8px',
      position: 'absolute',
      width: 290,

      '& > div:nth-of-type(2)': {
        display: 'grid',
        flex: 1,
        gap: THEME.box.gaps.s,
        height: 'fit-content',

        '& > div:nth-of-type(1)': {
          color: THEME.font.colors.black.main,
          fontSize: THEME.font.sizes.normal,
          fontWeight: 700,
        },

        '& > div:nth-of-type(2)': {
          color: '#8e8e8e',
          fontSize: THEME.font.sizes.hint,
        },
      },

      '&::after': {
        alignSelf: 'flex-start',
        border: '6px solid #0095f6',
        borderRadius: '50%',
        content: '""',
        display: 'block',
        height: 6,
        width: 6,
      },
    },
  }),
  createANewFacebookPage: css({
    alignItems: 'center',
    borderBottom: '1px solid #cdcdcd',
    bottom: 80,
    display: 'flex',
    gap: THEME.box.gaps.s,
    left: 0,
    padding: '8px 0',
    margin: '0 12px',
    position: 'absolute',
    width: 'calc(100% - 24px)',

    '& > div': {
      color: '#000',
      display: 'flex',
      flex: 1,
      fontSize: 11,
    },

    '& > svg': {
      transform: 'rotate(270deg)',

      '[dir="rtl"] &': {
        transform: 'rotate(90deg)',
      },
    },
  }),
};

export default SelectFacebookPage;
