import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

const SettingsAndPrivacy = () => {
  const { t } = useTranslation();
  const menus = [
    { icon: 'instagram-settings', title: 'Settings and privacy' },
    { icon: 'instagram-scheduled-content', title: 'Scheduled content' },
    { icon: 'instagram-your-activity', title: 'Your activity' },
    { icon: 'instagram-archive', title: 'Archive' },
    { icon: 'instagram-insights', title: 'Insights' },
    { icon: 'qr-code-outlined', title: 'QR code' },
  ];

  return (
    <div css={styles.container}>
      <div css={styles.menuContainer}>
        {menus.map(({ icon, title }, index) => (
          <div key={index}>
            <Icomoon color="#27313b" icon={icon} size={!index ? 24 : 16} />
            <div>{t(title)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: '#4c4c4c',
    borderRadius: 32,
    height: 280,
    position: 'relative',
    width: 280,
  }),
  menuContainer: css({
    background: '#e5e5e5',
    borderRadius: '15px 15px 32px 32px',
    bottom: 0,
    paddingTop: 70,
    position: 'absolute',
    width: '100%',

    '&::before': {
      background: '#c3c3c3',
      borderRadius: 15,
      content: '""',
      display: 'block',
      height: 2,
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 10,
      width: 25,
    },

    '& > div': {
      alignItems: 'center',
      display: 'flex',

      '& > svg': {
        padding: 10,
      },

      '& > div': {
        borderBottom: '1px solid #d5d5d5',
        color: THEME.font.colors.black.main,
        display: 'flex',
        flex: 1,
        fontSize: 11,
        fontWeight: 500,
        padding: '10px 0',
      },
    },

    '& > div:nth-of-type(1)': {
      background: THEME.colors.white,
      borderRadius: 8,
      boxShadow: THEME.box.shadows.outer,
      left: -16,
      position: 'absolute',
      top: 20,
      width: 'calc(100% + 32px)',

      '& > svg': {
        padding: '12px 14px',
      },

      '& > div': {
        borderBottom: 'unset',
        fontSize: THEME.font.sizes.subHeading,
      },
    },

    '& > div:last-child': {
      '& > div': {
        borderBottom: 'unset',
      },
    },
  }),
};

export default SettingsAndPrivacy;
