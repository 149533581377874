import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  GenerateMarketplacePostCaptionMutation,
  GenerateMarketplacePostCaptionMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/GenerateMarketplacePostCaption.graphql';

export const useGenerateMarketplacePostCaptionMutation = (
  options?: MutationHookOptions<GenerateMarketplacePostCaptionMutation, GenerateMarketplacePostCaptionMutationVariables>
) =>
  useMutation<GenerateMarketplacePostCaptionMutation, GenerateMarketplacePostCaptionMutationVariables>(
    MUTATION,
    options
  );
