import { useState } from 'react';
import { useAllInfluencerCategoriesQuery } from '@src/graphql/hooks';
import { Option } from '@src/libs/functions';

const useInfluencerCategories = () => {
  const [influencerCategories, setInfluencerCategories] = useState<Option[]>([]);

  useAllInfluencerCategoriesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: infCategories => {
      setInfluencerCategories(
        infCategories.allInfluencerCategories?.map(item => ({ value: item.id.toString(), label: item.description })) ||
          []
      );
    },
  });

  return {
    influencerCategories,
  };
};

export default useInfluencerCategories;
