import { useEffect, useState } from 'react';

const useScrollToPosition = (customHeight?: number) => {
  const [scrollOnBottom, setScrollOnBottom] = useState<boolean>(false);
  const [scrollOnTop, setScrollOnTop] = useState<boolean>(true);
  const behavior = 'smooth';
  const documentScrollHeight = customHeight || document.documentElement.scrollHeight;

  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= documentScrollHeight;
    const top = window.scrollY === 0;
    if (bottom) {
      setScrollOnBottom(true);
    } else {
      setScrollOnBottom(false);
    }

    if (top) {
      setScrollOnTop(true);
    } else {
      setScrollOnTop(false);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      behavior,
      top: documentScrollHeight,
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      behavior,
      top: 0,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    scrollOnBottom,
    scrollOnTop,
    scrollToBottom,
    scrollToTop,
  };
};

export default useScrollToPosition;
