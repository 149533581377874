import styled from '@emotion/styled';
import React from 'react';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import { THEME } from '@src/libs/theme';

interface NoticeProps {
  className?: string;
  title: string;
  color?: string;
  href?: string;
  content?: string | React.ReactNode;
}

const Notice = (props: NoticeProps) => {
  const { className, color, title, href, content } = props;

  return (
    <Wrapper color={color} className={className}>
      <Icomoon color="#ffb63d" css={{ marginRight: '12px' }} icon="warning" />
      <div>
        <p css={styles.title}>{title}</p>
        <div css={styles.noticeMessage}>
          {content}
          {!!href && (
            <a css={styles.noticeAnchor} href={href} rel="noopener noreferrer" target="_blank">
              {href}
            </a>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ color?: string }>(({ color }) => ({
  display: 'flex',
  margin: 24,
  marginBottom: 0,
  padding: '14px 16px',
  background: THEME.colors.white,
  borderRadius: 3,
  fontSize: 13,
  boxShadow: THEME.box.shadows.outer,
  borderLeft: `3px solid ${color || '#3892e5'}`,
}));

const styles = {
  noticeAnchor: css({
    marginLeft: 4,
    color: THEME.font.colors.blue.main,
    fontWeight: 500,
    textDecoration: 'underline',
    transition: 'opacity 0.3s ease-in-out',

    '&:hover': {
      opacity: 0.7,
    },
  }),
  noticeMessage: css({
    display: 'inline-block',
    alignItems: 'baseline',
    color: THEME.font.colors.gray.main,
    fontWeight: 500,
  }),
  title: css({
    fontWeight: 600,
    marginBottom: 4,
  }),
};

export default Notice;
