import { ApolloLink, Operation } from '@apollo/client';
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql';
import * as Sentry from '@sentry/browser';
import { BrowserOptions, Event, EventHint } from '@sentry/browser';

export interface SentryConfigType extends BrowserOptions {
  environment: 'staging' | 'production';
}

/**
 * Application Config
 */

// Ignore gtm.js's errors
const isInDenyList = (filename?: string): boolean => !!filename?.match(/www.googletagmanager.com/);

// Filtering Sentry's events
const beforeSend: BrowserOptions['beforeSend'] = (event: Event, _hint?: EventHint) => {
  // Ignore errors which stacktrace contains some specific filename.
  const shouldIgnore: boolean = !!event.exception?.values?.some(v =>
    v.stacktrace?.frames?.some(frame => isInDenyList(frame.filename))
  );

  return shouldIgnore ? null : event;
};

export const sentryConfig: { staging: SentryConfigType; production: SentryConfigType } = {
  staging: {
    dsn: 'https://59581a0cf3d04bb89e5a3dd86b3e2d7d@sentry.io/5168497',
    debug: true,
    environment: 'staging',
    beforeSend,
    ignoreErrors: ['ChunkLoadError'],
  },
  production: {
    dsn: 'https://59581a0cf3d04bb89e5a3dd86b3e2d7d@sentry.io/5168497',
    debug: false,
    environment: 'production',
    beforeSend,
    ignoreErrors: ['ChunkLoadError'],
  },
};

/**
 * Apollo Config
 * ref: https://github.com/Haegin/apollo-sentry-link/blob/master/src/index.js
 */
const operationInfo = (operation: Operation) => {
  const operationDefinition = operation.query.definitions.find(def => 'operation' in def) as
    | OperationDefinitionNode
    | undefined;
  const fragmentDefinition = operation.query.definitions.filter(
    def => def.kind === 'FragmentDefinition'
  ) as FragmentDefinitionNode[];

  return {
    type: operationDefinition ? operationDefinition.operation : '',
    name: operation.operationName,
    fragments: fragmentDefinition.map(def => def.name.value).join(', '),
    variables: operation.variables,
  };
};

// Add GraphQL Breadcrumb in stacktrace
export const sentryLink = new ApolloLink((operation, forward) => {
  Sentry.addBreadcrumb({
    category: 'graphql',
    data: operationInfo(operation),
    level: 'fatal',
  });

  return forward ? forward(operation) : null;
});
