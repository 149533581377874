import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  SwitchInfluencerForStaffMutation,
  SwitchInfluencerForStaffMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/SwitchInfluencerForStaff.graphql';

export const useSwitchInfluencerForStaffMutation = (
  options?: MutationHookOptions<SwitchInfluencerForStaffMutation, SwitchInfluencerForStaffMutationVariables>
) => useMutation<SwitchInfluencerForStaffMutation, SwitchInfluencerForStaffMutationVariables>(MUTATION, options);
