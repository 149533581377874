import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { AnyXCheckConnectedSocialAccountsQuery } from '@src/__generated__/globalTypes';
import QUERY from '../queries/AnyXCheckConnectedSocialAccounts.graphql';

export const useAnyXCheckConnectedSocialAccountsQuery = (
  options?: QueryHookOptions<AnyXCheckConnectedSocialAccountsQuery>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<AnyXCheckConnectedSocialAccountsQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useAnyXCheckConnectedSocialAccountsLazyQuery = (
  options?: LazyQueryHookOptions<AnyXCheckConnectedSocialAccountsQuery>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<AnyXCheckConnectedSocialAccountsQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
