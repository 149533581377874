import { CampaignSocialMediaType } from '../__generated__/globalTypes';

/**
 * Copy Text to Clipboard
 * Ref: https://qiita.com/simiraaaa/items/2e7478d72f365aa48356
 */
export const execCopy = (target: string) => {
  // blank div
  const tmp = document.createElement('div');
  // tag for select
  const pre = document.createElement('pre');

  pre.style.webkitUserSelect = 'auto';
  pre.style.userSelect = 'auto';

  tmp.appendChild(pre).textContent = target;

  const s = tmp.style;
  s.position = 'fixed';
  s.right = '200%';

  document.body.appendChild(tmp);
  const selection = document.getSelection();
  if (!selection) {
    return false;
  }
  selection.selectAllChildren(tmp);

  // copy to clipboard
  const result = document.execCommand('copy');

  document.body.removeChild(tmp);

  return result;
};

type EngagementLabel = 'comment' | 'share';
export const getEngagementSectionLabel = (media: CampaignSocialMediaType, label: EngagementLabel) => {
  switch (label) {
    case 'comment':
      return media === CampaignSocialMediaType.TWITTER ? 'Replies' : 'Comment';

    case 'share':
      return media === CampaignSocialMediaType.TWITTER ? 'Retweets' : 'Share';

    default:
      return '';
  }
};

export const getAppRootElement = () => document.getElementById('container');

interface MetaDataProps {
  attribute: string;
  name: string;
  value: string;
}

export const setMetaData = ({ attribute, name, value }: MetaDataProps) => {
  document?.querySelector(`meta[name='${name}']`)?.setAttribute(attribute, value);
};
