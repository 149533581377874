import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { useGetCustomerTagsQuery, useGetLineBroadcastEstimateRecipientsLazyQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { CustomerTagOrder, RecipientType } from '@src/__generated__/globalTypes';
import { ButtonTypeInput, DefaultInput, ImageTypeInput, PlainTextInput, RichMessageInput } from './Inputs';
import { FormInformation, FormType } from './Inputs/helpers';

export type { FormInformation };
export { FormType };

interface FormInputProps {
  isDisabled?: boolean;
  onSubmit: (information: FormInformation) => Promise<void>;
}

const FormInput = ({ isDisabled, onSubmit }: FormInputProps) => {
  const [totalRecipients, setTotalRecipients] = useState<number>(0);
  const { data: dataCustomerTags } = useGetCustomerTagsQuery({
    variables: {
      input: {
        order: CustomerTagOrder.BY_FREQUENTLY_USED,
      },
    },
  });
  const tags = dataCustomerTags?.getCustomerTags?.tags || [];
  const [getLineBroadcastEstimateRecipients] = useGetLineBroadcastEstimateRecipientsLazyQuery();
  const { watch } = useFormContext<FormInformation>();
  const [formType, recipient] = watch(['formType', 'recipient']);

  useEffect(() => {
    if (recipient.type === RecipientType.WITH_CUSTOMER_TAGS && !recipient.tagNames.length) {
      setTotalRecipients(0);
    } else {
      getLineBroadcastEstimateRecipients({
        variables: {
          input: {
            recipientType: recipient.type,
            tagNames: recipient.type === RecipientType.WITH_CUSTOMER_TAGS ? recipient.tagNames : [],
          },
        },
      }).then(({ data }) => {
        setTotalRecipients(data?.getLineBroadcastEstimateRecipients?.total || 0);
      });
    }
  }, [recipient.tagNames, recipient.type]);

  return (
    <div css={styles.container}>
      {[FormType.BUTTON_TYPE, FormType.BUTTON_TYPE_CONTENT].includes(formType) ? (
        <ButtonTypeInput isDisabled={isDisabled} />
      ) : formType === FormType.PLAIN_TEXT ? (
        <PlainTextInput isDisabled={isDisabled} />
      ) : formType === FormType.RICH_MESSAGE ? (
        <RichMessageInput isDisabled={isDisabled} />
      ) : [FormType.IMAGE_TYPE, FormType.IMAGE_TYPE_CONTENT].includes(formType) ? (
        <ImageTypeInput isDisabled={isDisabled} />
      ) : (
        <DefaultInput isDisabled={isDisabled} tags={tags} totalRecipients={totalRecipients} onSubmit={onSubmit} />
      )}
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 3,
    boxShadow: THEME.box.shadows.outer,
    height: 832,
    minWidth: 480,
    overflowY: 'auto',
    width: 480,
  }),
};

export default FormInput;
