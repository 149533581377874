import debounce from 'lodash/debounce';
import React, { useCallback, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { BackNavigatorV2, ThemeButton } from '@src/components/molecules';
import { TextFormV2 } from '@src/components/shared';
import {
  useGetInfluencerRateCardForInfluencerQuery,
  useInfluencerProfileV2Query,
  useUpdateInfluencerRateCardsMutation,
} from '@src/graphql/hooks';
import { CURRENCY_SYMBOLS } from '@src/libs/constant';
import { useAuthData, useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { switchImage, switchSocialBackgroundColor, switchText } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { SocialPostType } from '@src/__generated__/globalTypes';

export interface Information {
  facebookPrice: string;
  instagramPrice: string;
  instagramStoryPrice: string;
  tiktokPrice: string;
  twitterPrice: string;
  youtubePrice: string;
}

const SnsBudget = () => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { userId } = useAuthData();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { data, loading } = useGetInfluencerRateCardForInfluencerQuery({ fetchPolicy: 'no-cache' });
  const { data: dataInfluencerProfile } = useInfluencerProfileV2Query({ variables: { pk: Number(userId) } });
  const [updateInfluencerRateCards] = useUpdateInfluencerRateCardsMutation();

  const influencerRateCard = data?.getInfluencerRateCardForInfluencer;

  if (!loading && !influencerRateCard) {
    // we do not show SNS budget for TA/PA influencer
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const currency = influencerRateCard?.currencyId || '';
  const currencySymbol = CURRENCY_SYMBOLS[currency] || '';
  const facebookPrice = influencerRateCard?.facebook?.price.toString() || '0';
  const instagramPrice = influencerRateCard?.instagram?.price.toString() || '0';
  const instagramStoryPrice = influencerRateCard?.instagramStory?.price.toString() || '0';
  const tiktokPrice = influencerRateCard?.tiktok?.price.toString() || '0';
  const twitterPrice = influencerRateCard?.twitter?.price.toString() || '0';
  const youtubePrice = influencerRateCard?.youtube?.price.toString() || '0';

  const influencerProfile = dataInfluencerProfile?.influencerProfileV2;
  const hasFBAccount = !!influencerProfile?.facebookAccount?.id || !!influencerProfile?.facebookPages?.length;
  const hasIGAccount = !!influencerProfile?.instagramAccounts?.length;
  const hasTTAccount = !!influencerProfile?.tiktokAccounts?.length;
  const hasTWAccount = !!influencerProfile?.twitterAccounts?.length;
  const hasYTAcccount = !!influencerProfile?.youtubeAccounts?.length;

  const prices = [
    { disabled: !hasIGAccount, type: SocialPostType.INSTAGRAM, value: 'instagramPrice' },
    { disabled: !hasIGAccount, type: SocialPostType.INSTAGRAM_STORY, value: 'instagramStoryPrice' },
    { disabled: !hasFBAccount, type: SocialPostType.FACEBOOK, value: 'facebookPrice' },
    { disabled: !hasTWAccount, type: SocialPostType.TWITTER, value: 'twitterPrice' },
    { disabled: !hasTTAccount, type: SocialPostType.TIKTOK, value: 'tiktokPrice' },
    { disabled: !hasYTAcccount, type: SocialPostType.YOUTUBE, value: 'youtubePrice' },
  ] as const;

  const priceValidation = yup.string().matches(/(^\d{1,}\.\d{2}$)|(^\d{1,}$)/, 'invalidPriceMessage');
  const validationSchema = yup.object().shape({
    ...(hasFBAccount && {
      facebookPrice: priceValidation,
    }),
    ...(hasIGAccount && {
      instagramPrice: priceValidation,
      instagramStoryPrice: priceValidation,
    }),
    ...(hasTTAccount && {
      tiktokPrice: priceValidation,
    }),
    ...(hasTWAccount && {
      twitterPrice: priceValidation,
    }),
    ...(hasYTAcccount && {
      youtubePrice: priceValidation,
    }),
  });

  const onKeyUpInput = useCallback(
    debounce(() => {
      buttonRef.current?.click();
    }, 1000),
    []
  );

  const onSubmit = async (values: Information) => {
    try {
      await updateInfluencerRateCards({
        variables: {
          input: {
            ...(hasFBAccount && { facebook: Number(values.facebookPrice) }),
            ...(hasIGAccount && {
              instagram: Number(values.instagramPrice),
              instagramStory: Number(values.instagramStoryPrice),
            }),
            ...(hasTTAccount && { tiktok: Number(values.tiktokPrice) }),
            ...(hasTWAccount && { twitter: Number(values.twitterPrice) }),
            ...(hasYTAcccount && { youtube: Number(values.youtubePrice) }),
          },
        },
      });
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editMyPageSnsBudget);
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const defaultValues = {
    facebookPrice,
    instagramPrice,
    instagramStoryPrice,
    tiktokPrice,
    twitterPrice,
    youtubePrice,
  };
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<Information>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <form css={{ padding: '24px 16px' }} noValidate onSubmit={handleSubmit(onSubmit)}>
      {!isMobileView && <BackNavigatorV2 backPath={ROUTES.MY_PAGE} title="SNS budget" />}
      <div css={styles.contentContainer}>
        <div css={{ color: THEME.font.colors.black.main, fontSize: THEME.font.sizes.normal }}>
          {t('Annotation.Connect with your social account to use Analytics data and get sponsorship campaign')}
        </div>

        <div css={styles.inputContainer}>
          {prices.map(({ disabled, type, value }) => (
            <div key={type}>
              {type === SocialPostType.INSTAGRAM_STORY ? (
                <Icomoon icon={switchImage({ socialMedia: type })} size={24} />
              ) : (
                <SocialMediaIndicator background={switchSocialBackgroundColor(type)}>
                  <Icomoon
                    color="#fff"
                    icon={switchImage({ dynamicColor: true, simplify: true, socialMedia: type })}
                    size={13}
                  />
                </SocialMediaIndicator>
              )}
              <div>
                <Trans
                  components={{ 1: <b /> }}
                  i18nKey="Social media / Post"
                  values={{ socialMedia: switchText(type) }}
                />
              </div>
              <div>
                <TextFormV2
                  css={styles.textForm}
                  disabled={disabled}
                  prependIcon={currencySymbol}
                  type="number"
                  onKeyUp={onKeyUpInput}
                  {...register(value)}
                />
                <ErrorMessage message={errors?.[value]?.message} />
              </div>
            </div>
          ))}
        </div>

        <ThemeButton css={{ display: 'none' }} ref={buttonRef} text="Submit" type="submit" />
      </div>
    </form>
  );
};

const SocialMediaIndicator = styled.div<{ background: string }>(({ background }) => ({
  alignItems: 'center',
  background,
  borderRadius: '50%',
  display: 'flex',
  height: 24,
  justifyContent: 'center',
  minWidth: 24,
}));

const styles = {
  contentContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.l,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  inputContainer: css({
    background: '#eef3f7',
    borderRadius: 15,
    display: 'grid',
    gap: 1,
    overflow: 'hidden',

    '& > div': {
      alignItems: 'center',
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: 16,

      '& > svg': {
        minWidth: 24,
      },

      '& > div:nth-last-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
        minWidth: 'fit-content',

        '& > b': {
          color: THEME.font.colors.black.main,
          fontSize: THEME.font.sizes.normal,
          fontWeight: 600,
        },
      },

      '& > div:last-child': {
        display: 'grid',
        justifyContent: 'flex-end',
        width: 'fill-available',
      },
    },
  }),
  textForm: css({
    width: 138,

    '& input': {
      textAlign: 'right',
    },
  }),
};

export default SnsBudget;
