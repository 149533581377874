import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon, Icon } from '@src/components/atoms';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { getGrowthArrow, getGrowthPrefix } from '@src/components/organisms/AnalyticsShared/TooltipTemplates/utils';
import { bigIntFormatter, formatPercent, intlNumberFormat } from '@src/libs/format';
import { THEME } from '@src/libs/theme';

export interface SummaryInfo {
  count?: number;
  growth?: number | null;
  percentage?: number | null;
}

interface SummaryItemProps extends SummaryInfo {
  icon: string;
  isPercentage?: boolean;
  title: string;
}

const SummaryItem = ({ count, icon, isPercentage, growth, percentage, title }: SummaryItemProps) => {
  const { t } = useTranslation();
  const arrow = getGrowthArrow(growth!);
  const hoverTotal = `${intlNumberFormat(count)}${isPercentage ? '%' : ''}`;
  const hoverGrowth = intlNumberFormat(growth);
  const isInvalid = growth === Infinity || isNaN(growth!);
  const isPositiveGrowth = growth === 0 ? undefined : growth! > 0;
  const growthValue = isPercentage ? formatPercent(growth) : bigIntFormatter(growth, 0);
  const prefix = getGrowthPrefix(growth!) || '';
  const totalValue = isPercentage ? formatPercent(count) : bigIntFormatter(count, 0);

  return (
    <div css={styles.container}>
      <Icon>
        <Icomoon icon={icon} size={28} />
      </Icon>
      <div>
        <div>{t(title)}</div>
        <Styled.TotalNumber title={hoverTotal}>{totalValue}</Styled.TotalNumber>
        {totalValue === '-' ? null : isInvalid ? (
          '-'
        ) : (
          <Styled.Growth
            isPositive={isPositiveGrowth}
            title={hoverGrowth}
          >{`${prefix}${growthValue}  ${arrow}  (${formatPercent(percentage)})`}</Styled.Growth>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    borderRight: '1px solid #eef3f7',
    display: 'flex',
    flex: 1,
    gap: THEME.box.gaps.s,
    padding: '0 16px',

    '& > div': {
      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: 11,
        marginBottom: 4,
      },

      '& > span': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subTitle,
        marginBottom: 4,
      },
    },
  }),
};

export default SummaryItem;
