import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { AnyXEngagementCampaignStatus } from '@src/__generated__/globalTypes';

interface ReasonWidgetProps {
  content?: string | null;
  status: AnyXEngagementCampaignStatus;
  title?: string | null;
}

const ReasonWidget = ({ content, status, title }: ReasonWidgetProps) => {
  const { t } = useTranslation();

  const { color, description, headline, icon, iconColor } = (() => {
    switch (status) {
      case AnyXEngagementCampaignStatus.POSTING:
        return {
          color: '#ffb63d',
          description: 'Checking the change post',
          headline: 'Post reflection time',
          icon: 'clock-anti-clockwise',
          iconColor: '#eccc9c',
        };
      case AnyXEngagementCampaignStatus.REVIEWING:
        return {
          color: '#ffb63d',
          description: 'After posting, it will take around 20 minutes for the report to take',
          headline: 'Checking the post again',
          icon: 'clock-anti-clockwise',
          iconColor: '#eccc9c',
        };
      default:
        return {
          color: THEME.font.colors.error,
          description: content || '',
          headline: title || 'Invalid contents',
          icon: 'warning',
          iconColor: '#ff5f5f',
        };
    }
  })();

  return (
    <Container color={color}>
      <div>
        <Icomoon color={iconColor} icon={icon} size="18" />
      </div>
      <div>
        <div>{t(headline)}</div>
        <div>{t(description)}</div>
      </div>
    </Container>
  );
};

const Container = styled.div<{ color: string }>(({ color }) => ({
  borderLeft: `3px solid ${color}`,
  borderRadius: 3,
  boxShadow: THEME.box.shadows.outer,
  display: 'flex',
  flexWrap: 'wrap',
  padding: 16,
  width: '100%',

  '& > div:nth-of-type(2)': {
    display: 'grid',
    flexBasis: '88%',
    marginLeft: 8,

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      marginBottom: 8,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: 13,
    },
  },
}));

export default ReasonWidget;
