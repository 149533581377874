import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import Tooltip from '@src/components/molecules/Tooltip';
import useDirLayout from '@src/libs/hooks/useDirLayout';
import { THEME } from '@src/libs/theme';

interface LabelProps {
  className?: string;
  help?: string | null;
  isRequired?: boolean;
  title: string;
}

const Label = ({ className, isRequired, help, title }: LabelProps) => {
  const { dir } = useDirLayout();
  const { t } = useTranslation();

  return (
    <div className={className} css={styles.wrapper} dir={dir}>
      <div css={{ minWidth: 'fit-content' }}>{t(title)}</div>
      {isRequired && <span css={{ color: 'tomato' }}>&nbsp;*</span>}
      {help && (
        <div css={styles.iconContainer}>
          <Tooltip help={help} placement="left">
            <Icomoon css={styles.icon} icon="help-filled" size={14} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const styles = {
  icon: css({
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.9,
    },
  }),
  iconContainer: css({
    display: 'grid',
    justifyItems: 'flex-end',
    width: 'fill-available',
  }),
  wrapper: css({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 0 2px 4px',
    fontSize: THEME.font.sizes.normal,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: THEME.font.colors.black.main,
  }),
};

export default Label;
