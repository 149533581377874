import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { css } from '@emotion/react';
import anyCreatorLogo from '@src/assets/img/anyCreatorLogo.png';
import profileImg from '@src/assets/img/avatarProfile.png';
import { Avatar, Icomoon, Icon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { mainRed } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';

export const defaultWidth = 247;

interface EditAccessProps {
  width?: number;
}

const EditAccess = ({ width = defaultWidth }: EditAccessProps) => {
  const { t } = useTranslation();
  const quotient = width / defaultWidth;

  return (
    <div css={styles.container(quotient)}>
      <div css={styles.title(quotient)}>{t('Title.Authorize STGAnyCreator to access your TikTok account')}</div>

      <div css={styles.profileContainer(quotient)}>
        <div css={styles.avatarContainer(quotient)}>
          <div>
            <Avatar src={profileImg} />
          </div>
          <div>{t('TikTokGrantPermissionSampleUserName')}</div>
          <div>{t('Switch account')}</div>
        </div>
        <div css={styles.exchangeIconContainer(quotient)}>
          <Icomoon color="#b9b9b9" icon="exchange" size={10} />
        </div>
        <div css={styles.avatarContainer(quotient)}>
          <div>
            <Avatar src={anyCreatorLogo} />
          </div>
          <div>STGAnyCreator</div>
        </div>
      </div>

      <div css={styles.descriptionContainer(quotient)}>
        <div>{t('STGAnyCreator would like to')}</div>
        <ul>
          <li>{t('Annotation.Read your profile info')}</li>
          <li>{t('Annotation.Read your public videos on TikTok without quote symbol')}</li>
        </ul>
        <div>{t('Annotation.TikTok manage settings')}</div>
        <div>
          <div>
            <div>{t('Button.Edit access')}</div>
            <Icon>
              <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={4} />
            </Icon>
          </div>
        </div>
      </div>

      <div css={styles.agreement(quotient)}>
        <Trans
          components={{ 1: <span /> }}
          i18nKey="Annotation.TikTok authorize to agree privacy and terms of services"
        />
      </div>

      <ThemeButton css={styles.authorizeBtn(quotient)} customPalette={mainRed} text="Authorize" />
      <ThemeButton css={styles.cancelBtn(quotient)} text="Cancel" />
    </div>
  );
};

const styles = {
  agreement: (quotient: number) =>
    css({
      color: '#4f4f4f',
      fontSize: quotient * 5,
      marginBottom: quotient * 12,

      '& > span': {
        color: THEME.font.colors.black.main,
        fontWeight: 600,
      },
    }),
  authorizeBtn: (quotient: number) =>
    css({
      backgroundColor: '#c0364e',
      height: quotient * 24,
      marginBottom: quotient * 2,
      pointerEvents: 'none',

      '& > span': {
        fontSize: quotient * 8,
      },
    }),
  avatarContainer: (quotient: number) =>
    css({
      display: 'grid',
      gap: quotient * 2,
      justifyContent: 'center',
      height: 'fit-content',
      textAlign: 'center',
      width: 'fit-content',

      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',

        '& > img': {
          height: quotient * 35,
          width: quotient * 35,
        },
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: quotient * 6,
        fontWeight: 600,
      },

      '& > div:nth-of-type(3)': {
        color: '#4f4f4f',
        fontSize: quotient * 6,
      },
    }),
  cancelBtn: (quotient: number) =>
    css({
      height: quotient * 24,
      pointerEvents: 'none',

      '& > span': {
        color: THEME.font.colors.black.main,
        fontSize: quotient * 8,
      },
    }),
  container: (quotient: number) =>
    css({
      backgroundImage: 'linear-gradient(#fff 45%, #f5f5f5)',
      border: THEME.box.borders.gray.main,
      borderRadius: 5,
      height: quotient * 288,
      padding: quotient * 16,
      width: quotient * 213,
    }),
  descriptionContainer: (quotient: number) =>
    css({
      borderRadius: 5,
      backgroundColor: '#f8f8f8',
      marginBottom: quotient * 24,
      padding: `${quotient * 8}px ${quotient * 8}px ${quotient * 24}px ${quotient * 8}px`,
      position: 'relative',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: quotient * 6,
        fontWeight: 600,
        marginBottom: quotient * 2,
      },

      '& > ul': {
        padding: `0 ${quotient * 8}px`,

        '& > li': {
          color: '#4f4f4f',
          fontSize: quotient * 6,
          listStyleType: 'disc',
          marginBottom: quotient * 2,
        },
      },

      '& > div:nth-of-type(2)': {
        color: '#6f6f6f',
        fontSize: quotient * 5,
      },

      '& > div:nth-of-type(3)': {
        bottom: quotient * 2,
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',

        '& > div': {
          alignItems: 'center',
          border: `${quotient * 4}px solid #ff5f5f`,
          borderRadius: 5,
          color: THEME.font.colors.black.main,
          display: 'flex',
          fontSize: quotient * 6,
          fontWeight: 600,
          gap: 2,
          justifyContent: 'center',
          padding: `${quotient * 4}px ${quotient * 16}px`,
        },
      },
    }),
  exchangeIconContainer: (quotient: number) =>
    css({
      marginTop: quotient * 12,

      '& > img': {
        height: quotient * 12,
        width: quotient * 12,
      },
    }),
  profileContainer: (quotient: number) =>
    css({
      display: 'flex',
      gap: quotient * 4,
      justifyContent: 'center',
      marginBottom: quotient * 16,
    }),
  title: (quotient: number) =>
    css({
      color: THEME.font.colors.black.main,
      fontSize: quotient * 11,
      fontWeight: 600,
      marginBottom: quotient * 16,
      textAlign: 'center',
    }),
};

export default EditAccess;
