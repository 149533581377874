import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthDisconnectMutation,
  AnyXSocialAuthDisconnectMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthDisconnect.graphql';

export const useAnyXSocialAuthDisconnectMutation = (
  options?: MutationHookOptions<AnyXSocialAuthDisconnectMutation, AnyXSocialAuthDisconnectMutationVariables>
) => useMutation<AnyXSocialAuthDisconnectMutation, AnyXSocialAuthDisconnectMutationVariables>(MUTATION, options);
