import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Icomoon } from '@src/components/atoms';
import { SocialButton } from '@src/components/shared';
import { formatNumberWithCommas } from '@src/libs/format';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { switchImage, switchText } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';

interface SocialAccountConnectorProps {
  accounts: {
    avatar?: string | null;
    followersCount?: number | null;
    id: string;
    isBusiness?: boolean | null;
    name: string;
    needReconnect?: boolean;
    url?: string | null;
  }[];
  socialMedia: SocialAccountType;
  onClickConnect: () => void;
  onClickReconnect?: (socialAccountId: string) => void;
}

const SocialAccountConnector = ({
  accounts,
  socialMedia,
  onClickConnect,
  onClickReconnect,
}: SocialAccountConnectorProps) => {
  const { t } = useTranslation();
  const isIgAccount = socialMedia === SocialAccountType.INSTAGRAM;

  return (
    <div css={styles.container}>
      {accounts.length > 0 ? (
        <>
          {accounts.map(account => {
            const { avatar, id, isBusiness, followersCount, name, needReconnect } = account;

            return (
              <div css={styles.connectedSocialAccountContent} key={`${id}_${account.name}`}>
                <div>
                  <Icomoon icon={switchImage({ socialMedia })} size={24} />
                  <Avatar size={24} src={defaultInfluencerAvatar(avatar)} />
                </div>
                <div>
                  <div>{name}</div>
                  <div>{`${formatNumberWithCommas(followersCount, 0)} ${t('Followers')}`}</div>
                </div>
                {((isIgAccount && !isBusiness) || needReconnect) && (
                  <div>
                    <StyledSocialButton
                      css={styles.reconnectBtn}
                      light
                      socialMedia={socialMedia}
                      title="Reconnect"
                      onClick={() => onClickReconnect?.(id)}
                    />
                  </div>
                )}
              </div>
            );
          })}
          <div css={styles.addAccountContainer}>
            <StyledSocialButton
              iconSize="16"
              light
              socialMedia={socialMedia}
              title="Add Account"
              onClick={onClickConnect}
              css={isIgAccount ? { borderWidth: 0 } : {}}
            />
          </div>
        </>
      ) : (
        <div css={styles.socialAccountContent}>
          <div css={{ gap: '8px' }}>
            <Icomoon icon={switchImage({ socialMedia })} size={24} />
            <span>{switchText(socialMedia)}</span>
          </div>
          <div>
            <StyledSocialButton
              iconSize="16"
              light
              socialMedia={socialMedia}
              title="Connect"
              onClick={onClickConnect}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const StyledSocialButton = styled(SocialButton)({
  borderRadius: 5,
  height: 32,
  justifyContent: 'flex-end',
  marginTop: 0,
  paddingRight: 16,
  width: 'max-content',

  '& > img': {
    height: 16,
    width: 16,
  },

  '.prefix-icon': {
    position: 'relative',
  },

  '& > span': {
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,

    '[dir="rtl"] &': {
      padding: '0 0 0 16px',
    },
  },
});

const styles = {
  addAccountContainer: css({
    padding: 16,
  }),
  connectedSocialAccountContent: css({
    alignItems: 'center',
    borderBottom: '1px solid #eef3f7',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    gap: THEME.box.gaps.l,

    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexWrap: 'wrap',

      '& > svg': {
        '[dir="rtl"] &': {
          marginLeft: -4,
        },
      },

      '& > img': {
        marginLeft: -4,

        '[dir="rtl"] &': {
          marginLeft: 0,
        },
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      width: '75%',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.subordinate,
      },
    },
  }),
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    marginBottom: 16,
  }),
  reconnectBtn: css({
    padding: '0 16px',

    '& > div > i': {
      display: 'none',
    },
  }),
  socialAccountContent: css({
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',

      '& > span': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
        marginLeft: 16,
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  }),
};

export default SocialAccountConnector;
