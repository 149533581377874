import { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import attactStaffTag from '@src/assets/img/broadcast/attachStaffTag.png';
import { Avatar, CircularProgress, Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import { useGetLineChatsQuery } from '@src/graphql/hooks';
import { LIMIT } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { CRMChatTypeFilter, DeliveryTimingType } from '@src/__generated__/globalTypes';
import { FormInformation } from '../helpers';

interface FormActionProps {
  tags: { name: string }[];
  onSubmit: (information: FormInformation) => Promise<void>;
}

enum DialogStatus {
  ATTACH_TAG = 'ATTACH_TAG',
  CLOSE = 'CLOSE',
  SEND_MESSAGE = 'SEND_MESSAGE',
  SEND_TEST_MESSAGE = 'SEND_TEST_MESSAGE',
}

const FormAction = ({ tags, onSubmit }: FormActionProps) => {
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>(DialogStatus.CLOSE);
  const { navigate, t } = useQueryHelper();
  const {
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useFormContext<FormInformation>();
  const [deliveryTiming, isEditPage] = watch(['deliveryTiming', 'id']);
  const isAttachTagDialog = dialogStatus === DialogStatus.ATTACH_TAG;
  const tagName = 'Staff';
  const isTestMemberRegistered = !!tags.find(tag => tag.name === tagName);
  const { data } = useGetLineChatsQuery({
    variables: {
      input: {
        keyword: '',
        limit: LIMIT,
        offset: 0,
        tagName,
        typeFilter: CRMChatTypeFilter.ACTIVATED,
      },
    },
  });

  useEffect(() => {
    if (!isSubmitting && dialogStatus !== DialogStatus.CLOSE) {
      setDialogStatus(DialogStatus.CLOSE);
    }
  }, [isSubmitting]);

  const onExec = () => {
    if (isAttachTagDialog) {
      navigate(ROUTES.MY_PAGE_MESSAGE_CHAT);

      return;
    }

    trigger().then(isValid => {
      if (isValid) {
        switch (dialogStatus) {
          // if is send test message, will set testField & sendField to true
          // @ts-ignore
          case DialogStatus.SEND_TEST_MESSAGE:
            setValue('isTest', true);
          default:
            setValue('isSend', true);
        }
        handleSubmit(onSubmit)();
      } else {
        setDialogStatus(DialogStatus.CLOSE);
      }
    });
  };

  const lineChatList = data?.getLineChats?.chats || [];

  return (
    <div css={styles.container}>
      <Dialog
        closeButtonProps={{
          styles: blackCircleCloseStyles,
        }}
        contentStyles={{ borderRadius: 3, maxWidth: dialogStatus === DialogStatus.SEND_MESSAGE ? 480 : 648 }}
        open={dialogStatus !== DialogStatus.CLOSE}
        onClose={() => setDialogStatus(DialogStatus.CLOSE)}
        {...(isAttachTagDialog
          ? {
              cancelButtonProps: {
                disabled: isSubmitting,
                styles: smallButtonStyles,
                text: 'Move to Chat Page',
                onClick: onExec,
              },
            }
          : {
              nextButtonProps: {
                disabled: isSubmitting,
                styles: smallButtonStyles,
                text: 'Send',
                theme: 'blue',
                onClick: onExec,
              },
            })}
      >
        <div>
          {isSubmitting && (
            <div css={styles.dialogOverlay}>
              <CircularProgress />
            </div>
          )}
          {dialogStatus === DialogStatus.ATTACH_TAG ? (
            <Fragment>
              <DialogTitle>{t('Dialog.Attach Staff Tag')}</DialogTitle>
              <DialogDescription>
                <Trans
                  components={{ 1: <span /> }}
                  i18nKey="Annotation.You need to attach staff tag to the user who you want to send test message from"
                />
              </DialogDescription>
              <DialogDescription>
                <Trans
                  components={{ 1: <span /> }}
                  i18nKey="Annotation.Before you attach the staff tag, you need to be a friend of friend of linked LINE official account"
                />
              </DialogDescription>
              <DialogImageContainer>
                <img height="397" src={attactStaffTag} width="382" />
              </DialogImageContainer>
            </Fragment>
          ) : dialogStatus === DialogStatus.SEND_MESSAGE ? (
            <Fragment>
              <DialogTitle>{t('Dialog.Send message')}</DialogTitle>
              <DialogDescription>{t('Annotation.Are you sure you want to send this message')}</DialogDescription>
            </Fragment>
          ) : (
            <Fragment>
              <DialogTitle>{t('Dialog.Send test message')}</DialogTitle>
              <DialogDescription>{t('Annotation.Are you sure you want to send message')}</DialogDescription>
              <DialogDescription css={{ marginBottom: 4 }}>
                <Trans
                  components={{ 1: <span /> }}
                  i18nKey="Annotation.You can send test message to these users who have staff tag"
                />
              </DialogDescription>
              <div css={styles.dialogHintContainer} onClick={() => setDialogStatus(DialogStatus.ATTACH_TAG)}>
                <Icomoon color="#c5d0da" icon="help-filled" />
                <div>{t('Annotation.How can I attach the Message Sender tag')}</div>
              </div>
              <div css={styles.lineChatListContainer}>
                <div>
                  {lineChatList.map(item => {
                    const { chatId, userAvatar, userName } = item;

                    return (
                      <div key={chatId}>
                        <Avatar title={userName} size={40} src={userAvatar || ''} />
                        <div>{userName}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Dialog>
      <div css={styles.btnContainer}>
        <ThemeButton
          theme="blue"
          text={deliveryTiming.type === DeliveryTimingType.IMMEDIATELY_SEND ? 'Send' : 'Schedule'}
          width="max-content"
          disabled={isSubmitting}
          onClick={() => setDialogStatus(DialogStatus.SEND_MESSAGE)}
        />
        <ThemeButton
          text="Test message"
          width="max-content"
          disabled={isSubmitting}
          onClick={() =>
            setDialogStatus(isTestMemberRegistered ? DialogStatus.SEND_TEST_MESSAGE : DialogStatus.ATTACH_TAG)
          }
        />
        <ThemeButton
          text={isEditPage ? 'Update' : 'Save draft'}
          width="max-content"
          disabled={isSubmitting}
          type="submit"
          css={styles.btnEnd}
        />
      </div>
    </div>
  );
};

const DialogDescription = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  marginBottom: 16,

  '& > span': {
    backgroundColor: '#eef3f7',
    borderRadius: 3,
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subordinate,
    margin: '0 4px',
    padding: '4px 8px',
    width: 'fit-content',
  },
});

const DialogImageContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const DialogTitle = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.heading,
  fontWeight: 600,
  marginBottom: 16,
});

const styles = {
  btnContainer: css({
    display: 'flex',
    flex: 1,
    gap: THEME.box.gaps.s,
  }),
  container: css({
    display: 'flex',
  }),
  dialogHintContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.xs,
    marginBottom: 24,

    '& > div': {
      color: THEME.font.colors.blue.main,
      cursor: 'pointer',
      fontSize: THEME.font.sizes.normal,
      textDecoration: 'underline',
    },
  }),
  dialogOverlay: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    left: 0,
    opacity: 0.7,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 5,

    '& svg': {
      color: '#dfe1e5',
    },
  }),
  lineChatListContainer: css({
    display: 'flex',
    justifyContent: 'center',

    '& > div': {
      backgroundColor: THEME.colors.white,
      borderRadius: 5,
      boxShadow: THEME.box.shadows.outer,
      maxHeight: 256,
      overflow: 'auto',
      width: 416,

      '& > div': {
        alignItems: 'center',
        borderBottom: '1px solid #eef3f7',
        display: 'flex',
        gap: THEME.box.gaps.s,
        padding: '8px 16px',

        '& > div:nth-of-type(2)': {
          color: THEME.font.colors.black.main,
          fontSize: THEME.font.sizes.normal,
        },
      },
    },
  }),
  btnEnd: css({
    marginLeft: 'auto',

    '[dir="rtl"] &': {
      marginLeft: 'unset',
      marginRight: 'auto',
    },
  }),
};

export default FormAction;
