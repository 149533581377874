import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  DeleteEngagementPostForInfluencerMutation,
  DeleteEngagementPostForInfluencerMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/DeleteEngagementPostForInfluencer.graphql';

export const useDeleteEngagementPostForInfluencerMutation = (
  options?: MutationHookOptions<
    DeleteEngagementPostForInfluencerMutation,
    DeleteEngagementPostForInfluencerMutationVariables
  >
) =>
  useMutation<DeleteEngagementPostForInfluencerMutation, DeleteEngagementPostForInfluencerMutationVariables>(
    MUTATION,
    options
  );
