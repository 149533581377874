import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Trans } from 'react-i18next';
import { DragAndDrop } from '@src/components/molecules';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import { THEME } from '@src/libs/theme';

interface ImportFansModalProps {
  open: boolean;
  onClose: () => void;
  onImportFile: (file: File) => void;
}

const ImportFansModal = (props: ImportFansModalProps) => {
  const [file, setFile] = useState<File | null>(null);
  const { open, onClose, onImportFile } = props;
  const { t } = useTranslation();
  // currently only fan csv download access the bucket and both stg and prod using the same url
  const sampleFanCsvPath = `https://storage.googleapis.com/castingasia-public/2022-06-21/manual-upload-template-fans.csv`;

  const onClickImportFans = () => {
    if (file) {
      onImportFile(file);
    }
  };

  const onCloseDialog = () => {
    setFile(null);
    onClose();
  };

  return (
    <Dialog
      cancelButtonProps={{
        styles: smallButtonStyles,
        onClick: onCloseDialog,
      }}
      closeButtonProps={{
        styles: blackCircleCloseStyles,
      }}
      contentStyles={{
        borderRadius: 3,
        maxWidth: 648,
      }}
      nextButtonProps={{
        styles: smallButtonStyles,
        text: 'Import Customers',
        theme: 'blue',
        onClick: onClickImportFans,
      }}
      open={open}
      onClose={onClose}
    >
      <h2 css={styles.heading}>{t('Import customers by CSV')}</h2>
      <div css={{ display: 'flex' }}>
        <Trans
          components={{
            1: (
              <Link
                css={styles.downloadTemplate}
                download
                rel="noopener noreferrer"
                target="_blank"
                to={sampleFanCsvPath}
              />
            ),
          }}
          i18nKey="Fan upload annotation"
          values={{ text: t('sample CSV') }}
        />
      </div>
      <DragAndDrop
        accept=".csv, text/csv"
        contentUploadLabel="General.Add file or drop file upload"
        css={!file && styles.dragAndDropUpload}
        customContent={
          file ? <div css={styles.dragAndDropCustomContent}>{`${file.name} - ${file.size} bytes`}</div> : null
        }
        onDrop={files => setFile(files[0])}
      />
    </Dialog>
  );
};

const styles = {
  downloadTemplate: css({
    cursor: 'pointer',
    color: '#0093ff',
    margin: '0 6px',
    textDecoration: 'underline',
  }),
  dragAndDropUpload: css({
    alignContent: 'center',
    borderWidth: 2,
    marginTop: 24,
    minHeight: 200,
    padding: 16,

    '& > div': {
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 400,
    },
  }),
  dragAndDropCustomContent: css({
    alignItems: 'center',
    border: '2px dashed rgb(222, 229, 236)',
    borderRadius: 3,
    display: 'grid',
    fontSize: THEME.font.sizes.subHeading,
    justifyContent: 'center',
    marginTop: 24,
    minHeight: 200,
    padding: '0 16px',
  }),
  heading: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.heading,
    color: THEME.font.colors.black.main,
    marginBottom: 16,
  }),
};

export default ImportFansModal;
