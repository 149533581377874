import React, { forwardRef, ChangeEvent, KeyboardEvent, ReactNode, Ref } from 'react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import Label from '../Label';

interface TextFormProps {
  appendIcon?: ReactNode;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  help?: string;
  isRequired?: boolean;
  placeholder?: string | null;
  prependIcon?: ReactNode;
  readOnly?: boolean;
  title?: string | null;
  type?: string;
  value?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const TextForm = forwardRef(
  (
    {
      appendIcon,
      className,
      disabled,
      error,
      help,
      isRequired,
      placeholder,
      prependIcon,
      title,
      ...restProps
    }: TextFormProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <div className={className} css={{ width: 'fill-available' }} ref={ref}>
      {title && <Label help={help} isRequired={isRequired} title={title} />}
      <InputContainer disabled={disabled} error={error}>
        {prependIcon}
        <input
          placeholder={placeholder || ''}
          {...(restProps.type === 'number' && {
            onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            },
          })}
          {...restProps}
        />
        {appendIcon}
      </InputContainer>
    </div>
  )
);

const InputContainer = styled.div<{ disabled?: boolean; error?: boolean }>(({ disabled, error }) => ({
  alignItems: 'center',
  border: `1px solid ${error ? '#ff5f5f' : '#c5d0da'}`,
  borderRadius: 9,
  display: 'flex',
  gap: THEME.box.gaps.s,
  height: 46,
  padding: '0 12px',
  width: 'fill-available',
  ...(disabled && {
    background: '#f2f2f2',
    pointerEvents: 'none',
  }),

  '& > input': {
    fontSize: THEME.font.sizes.subHeading,
    width: '100%',
  },
}));

TextForm.displayName = 'TextForm';

export default TextForm;
