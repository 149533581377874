import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

const EditProfile = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div css={styles.tabsContainer}>
        <div>or_account</div>
        <div>
          <div>{t('Edit profile')}</div>
          <div>{t('advertising tools')}</div>
        </div>
        <Icomoon color="#000" icon="instagram-settings" size={18} />
      </div>

      <div css={styles.followerInfo}>
        <div>{t('count followers_', { count: 0 })}</div>
        <div>{t('count following_', { count: 1 })}</div>
      </div>

      <div css={styles.footer}>
        <div>{t('Saved')}</div>
        <div>
          <Icomoon color="#8f8e8f" icon="instagram-people-tagged" size={10} />
          <div>{t('People Tagged')}</div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    height: 234,
    position: 'relative',
    width: 368,
  }),
  followerInfo: css({
    alignItems: 'center',
    color: '#000',
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    fontWeight: 500,
    gap: 32,
    marginTop: 24,
    padding: '0 16px',
  }),
  footer: css({
    alignItems: 'center',
    borderTop: '1px solid #dbdbdc',
    bottom: 0,
    color: '#8f8e8f',
    display: 'flex',
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 700,
    gap: 40,
    padding: '16px 4px 8px 4px',
    position: 'absolute',
    textTransform: 'uppercase',
    width: 'fill-available',

    '& > div:nth-of-type(2)': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.s,
    },
  }),
  tabsContainer: css({
    alignItems: 'center',
    color: '#000',
    display: 'flex',
    gap: 14,
    marginTop: 24,

    '& > div:nth-of-type(1)': {
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 500,
    },

    '& > div:nth-of-type(2)': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.s,

      '& > div': {
        background: '#efefef',
        borderRadius: 9,
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 700,
        padding: '6px 12px',
        textTransform: 'lowercase',
      },

      '& > div:nth-of-type(1)': {
        position: 'relative',

        '&::before': {
          border: '2px dashed #3892e5',
          borderRadius: 3,
          content: '""',
          display: 'block',
          height: 'calc(100% + 12px)',
          left: -10,
          position: 'absolute',
          top: -8,
          width: 'calc(100% + 16px)',

          '[dir="rtl"] &': {
            right: -10,
          },
        },
      },
    },
  }),
};

export default EditProfile;
