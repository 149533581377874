import { css } from '@emotion/react';
import { useQueryHelper } from '@src/libs/hooks';
import { Link } from 'react-router-dom';
import React from 'react';
import { Empty } from '@src/components/atoms';
import { ListLoading, Pager, ThemeButton } from '@src/components/molecules';
import { getOffset, getPageInfo } from '@src/components/molecules/Pager/helpers';
import { LIMIT } from '@src/libs/constant';
import { useGetFormRespondentsListQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES, generatePath } from '@src/shared/routes';
import { FormRespondentItem } from '@src/__generated__/globalTypes';

interface FormRespondentProps {
  currentPage: number;
  total: number;
}

const FormRespondent = (props: FormRespondentProps) => {
  const { currentPage, total } = props;
  const { t, params } = useQueryHelper();
  const { id } = params as { id: string };
  const offset = getOffset(currentPage);

  const { data, loading } = useGetFormRespondentsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: Number(id),
        limit: LIMIT,
        offset,
      },
    },
  });

  if (loading) {
    return <ListLoading />;
  }

  if (!data?.getFormRespondentsList?.respondents.length) {
    return <Empty />;
  }

  const pageInfo = getPageInfo(currentPage, total || 0);

  const renderRespondentTitle = (respondent: FormRespondentItem, index: number) => {
    const title = respondent.firstName
      ? `${respondent.firstName} ${respondent.lastName || ''}`
      : respondent.email || `${t('Respondent')} ${index + 1 + offset}`;

    if (respondent.fanId) {
      return (
        <Link css={styles.link} to={`/fans/${respondent.fanId}/forms`}>
          {title}
        </Link>
      );
    } else {
      return title;
    }
  };

  return (
    <>
      <div css={styles.wrapper}>
        <div css={[styles.row, styles.heading]}>{t('Respondent')}</div>
        {data.getFormRespondentsList.respondents.map((item, index) => (
          <div css={styles.row} key={item.submissionId}>
            {renderRespondentTitle(item, index)}
            <ThemeButton
              css={styles.button}
              text="Answer"
              to={
                item.page
                  ? generatePath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL_PAGE, { id, page: item.page })
                  : '#'
              }
            />
          </div>
        ))}
      </div>

      <Pager
        currentPage={currentPage}
        totalPages={pageInfo.totalPages}
        totalCount={pageInfo.totalCount}
        first={pageInfo.firstIndex}
        last={pageInfo.lastIndex}
        css={{ marginTop: 4 }}
      />
    </>
  );
};

const styles = {
  wrapper: css({
    border: '1px solid #eef3f7',
  }),
  heading: css({
    height: 48,
    backgroundColor: '#f6f8fa',
    borderBottom: '1px solid #dee5ec',
  }),
  row: css({
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',

    '&:not(:last-of-type)': {
      borderBottom: '1px solid #eef3f7',
    },
  }),
  link: css({
    color: THEME.font.colors.blue.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  }),
  button: css({
    width: 'fit-content',
    borderRadius: THEME.box.borderRadius.s,
  }),
};

export default FormRespondent;
