import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { JAPANESE_LANG } from '@src/libs/constant';
import { THEME } from '@src/libs/theme';

const AccountTypeAndTools = () => {
  const { i18n, t } = useTranslation();
  const menus = [
    { icon: 'users-outlined', title: 'Supervision' },
    { header: 'For professional' },
    { icon: 'instagram-account-type-tools', isHighlighted: true, title: 'Account type and tools' },
    { header: 'Your orders and fundraisers' },
    { icon: 'instagram-order-payments', title: 'Orders and payments' },
    { header: 'More info and support' },
    { icon: 'instagram-help', title: 'Option.Help' },
    { icon: 'user-outlined', title: 'Account Status' },
    { icon: 'info-circle-outlined', title: 'About' },
  ];

  return (
    <div css={styles.container}>
      {menus.map(({ icon, isHighlighted, header, title }, index) => (
        <div key={index}>
          {header ? (
            <div css={styles.listHeader}>{t(header)}</div>
          ) : (
            <List isHighlighted={isHighlighted} isJPLanguage={i18n.language === JAPANESE_LANG}>
              <div>
                <Icomoon color="#000" icon={icon || ''} size={isHighlighted ? 20 : 14} />
                <div>{t(title || '')}</div>
                {!isHighlighted && <Icomoon icon="arrow-down" size={10} />}
              </div>
            </List>
          )}
        </div>
      ))}
    </div>
  );
};

const List = styled.div<{ isHighlighted?: boolean; isJPLanguage?: boolean }>(({ isHighlighted, isJPLanguage }) => ({
  position: 'relative',
  ...(isHighlighted && { height: 35 }),

  '& > div': {
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: isHighlighted ? (isJPLanguage ? '7px 16px' : '8px 16px') : isJPLanguage ? '7px' : '8px',
    ...(isHighlighted && {
      background: THEME.colors.white,
      borderRadius: 8,
      boxShadow: THEME.box.shadows.outer,
      left: -24,
      position: 'absolute',
      width: 'calc(100% + 16px)',
    }),

    '& > div': {
      color: '#000',
      display: 'flex',
      flex: 1,
      fontSize: isHighlighted ? '13px' : ' 11px',
      fontWeight: 500,
    },

    '& > svg:nth-of-type(2)': {
      transform: 'rotate(270deg)',

      '[dir="rtl"] &': {
        transform: 'rotate(90deg)',
      },
    },
  },
}));

const styles = {
  container: css({
    background: '#e5e5e5',
    borderRadius: 32,
    height: 280,
    width: 280,
  }),
  listHeader: css({
    borderTop: '4px solid #e1e1e1',
    color: THEME.font.colors.gray.main,
    fontSize: 9,
    fontWeight: 500,
    padding: 8,
  }),
};

export default AccountTypeAndTools;
