import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  CreateEngagementPostForInfluencerV2Mutation,
  CreateEngagementPostForInfluencerV2MutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/CreateEngagementPostForInfluencerV2.graphql';

export const useCreateEngagementPostForInfluencerV2Mutation = (
  options?: MutationHookOptions<
    CreateEngagementPostForInfluencerV2Mutation,
    CreateEngagementPostForInfluencerV2MutationVariables
  >
) =>
  useMutation<CreateEngagementPostForInfluencerV2Mutation, CreateEngagementPostForInfluencerV2MutationVariables>(
    MUTATION,
    options
  );
