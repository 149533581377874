import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { Avatar, ErrorMessage, Icomoon } from '@src/components/atoms';
import { BackNavigatorV2, Dialog, Pager, ThemeButton } from '@src/components/molecules';
import { TextFormV2 } from '@src/components/shared';
import {
  useCreateCreatorStaffMutation,
  useGetCreatorStaffsCountQuery,
  useGetCreatorStaffsQuery,
  useRemoveCreatorStaffMutation,
} from '@src/graphql/hooks';
import { LIMIT } from '@src/libs/constant';
import { getCurrentPage, getOffset, getPageInfo } from '@src/components/molecules/Pager/helpers';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';

interface Staff {
  email: string;
  name: string;
}

const Users = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const currentPage = getCurrentPage();
  const { data } = useGetCreatorStaffsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        limit: LIMIT,
        offset: getOffset(currentPage),
      },
    },
  });
  const { data: dataCreatorStaffsCount } = useGetCreatorStaffsCountQuery();
  const [createCreatorStaff] = useCreateCreatorStaffMutation({
    refetchQueries: ['GetCreatorStaffs'],
  });
  const [removeCreatorStaff] = useRemoveCreatorStaffMutation({
    refetchQueries: ['GetCreatorStaffs'],
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const defaultValues = {
    email: '',
    name: '',
  };
  const validationSchema = yup.object().shape({
    email: yup.string().required().email('invalidEmailMessage'),
    name: yup.string().required(),
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
  } = useForm<Staff>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const creatorStaffs = data?.getCreatorStaffs;
  const creatorAvatar = creatorStaffs?.avatar;
  const creatorName = creatorStaffs?.name;
  const staffs = creatorStaffs?.staffs || [];
  const totalCount = dataCreatorStaffsCount?.getCreatorStaffsCount?.total || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  const onClickCancelDialog = () => {
    reset(defaultValues);
    setDialogOpen(false);
  };

  const onClickDeleteStaff = (creatorStaffId: number) => {
    removeCreatorStaff({
      variables: {
        input: {
          creatorStaffId,
        },
      },
    });
  };

  const onSubmit = async (values: Staff) => {
    try {
      await createCreatorStaff({
        variables: {
          input: values,
        },
      });
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.addUser);
      onClickCancelDialog();
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  return (
    <div css={{ padding: '24px 16px' }}>
      <Dialog
        cancelButtonProps={{
          onClick: onClickCancelDialog,
        }}
        nextButtonProps={{
          disabled: isSubmitting,
          text: 'Send invite',
          onClick: handleSubmit(onSubmit),
        }}
        open={dialogOpen}
        onClose={onClickCancelDialog}
      >
        <div>
          <div css={styles.dialogContent}>
            <div>{t('Add Staff')}</div>
            <div>
              <TextFormV2 isRequired title="Name" {...register('name')} />
              <ErrorMessage message={errors.name?.message} />
            </div>
            <div>
              <TextFormV2 isRequired title="Email address" {...register('email')} />
              <ErrorMessage message={errors.email?.message} />
            </div>
          </div>
        </div>
      </Dialog>

      {!isMobileView && <BackNavigatorV2 backPath={ROUTES.MY_PAGE} title="Users" />}
      <div css={styles.contentContainer}>
        <Title>{t('Creator')}</Title>
        <div css={styles.creatorInfo}>
          <Avatar size={56} src={creatorAvatar} title={creatorName} />
          <div>{creatorName}</div>
        </div>

        <Title>{t('User Count', { staffCount: staffs.length * currentPage, totalCount })}</Title>
        <ThemeButton
          css={styles.addStaffBtn}
          prefixIcon={<Icomoon icon="plus" />}
          text="Add Staff"
          onClick={() => setDialogOpen(true)}
        />
        {!!totalCount && (
          <div css={styles.staffsInfo}>
            {staffs.map(({ email, id, name }) => (
              <div key={id}>
                <div>
                  <div>{name}</div>
                  <div>{email}</div>
                </div>
                <ThemeButton text="Delete" onClick={() => onClickDeleteStaff(id)} />
              </div>
            ))}
          </div>
        )}

        <Pager
          currentPage={currentPage}
          first={firstIndex}
          last={lastIndex}
          totalCount={totalCount}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

const Title = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.heading,
  fontWeight: 600,
  marginBottom: 16,
});

const styles = {
  addStaffBtn: css({
    borderRadius: 9,
    height: 48,
    marginBottom: 16,

    '& span': {
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  }),
  contentContainer: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '24px 16px',
    },
  }),
  creatorInfo: css({
    alignItems: 'center',
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
    gap: THEME.box.gaps.l,
    marginBottom: 32,
    padding: 16,
    width: 'fill-available',
  }),
  dialogContent: css({
    display: 'grid',
    gap: THEME.box.gaps.xxl,

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subTitle,
      fontWeight: 700,
    },
  }),
  staffsInfo: css({
    background: '#eef3f7',
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: 1,
    marginBottom: 16,
    overflow: 'hidden',

    '& > div': {
      alignItems: 'center',
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: 16,

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        display: 'grid',
        fontSize: THEME.font.sizes.subordinate,
        gap: THEME.box.gaps.m,
        width: 'fill-available',

        '& > div:nth-of-type(1)': {
          fontSize: THEME.font.sizes.subHeading,
          fontWeight: 600,
        },
      },

      '& > button': {
        borderRadius: 9,
        height: 40,
        width: 'fit-content',
      },
    },
  }),
};

export default Users;
