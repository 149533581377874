import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import Select from './Select';

const StyledSelect = styled(Select)({
  '.select-input-container': {
    borderRadius: 9,
    height: 46,

    '& > label, input': {
      fontSize: THEME.font.sizes.subHeading,
    },
  },

  '.select-checked-all-container': {
    '& label': {
      fontSize: THEME.font.sizes.subHeading,
    },
  },

  '.select-menu-container': {
    borderRadius: 9,
  },

  '.select-list-container': {
    '& label': {
      fontSize: THEME.font.sizes.subHeading,
    },
  },
});

export default StyledSelect;
