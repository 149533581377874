import { PostProcessorModule } from 'i18next';
import parse from 'html-react-parser';
import mikan from 'mikanjs';

const postProcess: PostProcessorModule = {
  type: 'postProcessor',
  name: 'budoux',
  process(value, _key, _options, translator) {
    if (translator.language === 'ja') {
      return (<div>{parse(mikan(value))}</div>) as never;
    }

    return value;
  },
};

export default postProcess;
