import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetEmailListQuery, GetEmailListQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetEmailList.graphql';

export const useGetEmailListQuery = (options?: QueryHookOptions<GetEmailListQuery, GetEmailListQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetEmailListQuery, GetEmailListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetEmailListLazyQuery = (
  options?: LazyQueryHookOptions<GetEmailListQuery, GetEmailListQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetEmailListQuery, GetEmailListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
