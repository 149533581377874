import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';

export interface CheckBoxProps {
  checked: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  interpolation?: Record<string, string>;
  label?: string;
  onChange?: (checked: boolean) => void;
}

const CheckBox = ({ checked, disabled, indeterminate, interpolation, label, onChange }: CheckBoxProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container} onClick={() => !disabled && onChange?.(!checked)}>
      <StyledCheckBox checked={checked} disabled={disabled}>
        {checked ? (
          indeterminate ? (
            <span css={{ fontSize: THEME.font.sizes.subTitle, marginTop: -3 }}>&#8722;</span>
          ) : (
            <span css={{ fontSize: THEME.font.sizes.normal }}>&#10003;</span>
          )
        ) : null}
      </StyledCheckBox>
      {label && <label css={{ cursor: 'pointer' }}>{t(label, { ...interpolation })}</label>}
    </div>
  );
};

const StyledCheckBox = styled.div<{ checked: boolean; disabled?: boolean }>(({ checked, disabled }) => ({
  alignContent: 'center',
  background: disabled ? THEME.colors.disabled : checked ? THEME.colors.blue.main : THEME.colors.white,
  border: THEME.box.borders.gray.c5d0da,
  borderRadius: THEME.box.borderRadius.xs,
  boxShadow: checked ? 'none' : THEME.box.shadows.inner,
  color: THEME.colors.white,
  cursor: 'pointer',
  display: 'grid',
  fontWeight: 700,
  height: 15,
  justifyContent: 'center',
  minHeight: 15,
  minWidth: 15,
  width: 15,
}));

const styles = {
  container: css({
    alignItems: 'center',
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    gap: THEME.box.gaps.xs,
    width: 'fit-content',
  }),
};

export default CheckBox;
