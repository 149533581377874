interface HamburgerIconProps {
  color?: string;
  width?: string;
  lineHeight?: string;
  lineGap?: string;
}
export const HamburgerIcon = ({
  color = 'black',
  width = '20px',
  lineHeight = '2px',
  lineGap = '4px',
}: HamburgerIconProps) => {
  const line = {
    width,
    height: lineHeight,
    backgroundColor: color,
  };

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: lineGap }}>
      <span css={line} />
      <span css={line} />
      <span css={line} />
    </div>
  );
};
