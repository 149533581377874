import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  GetInfluencerBioAnalyticsQuery,
  GetInfluencerBioAnalyticsQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetInfluencerBioAnalytics.graphql';

export const useGetInfluencerBioAnalyticsQuery = (
  options?: QueryHookOptions<GetInfluencerBioAnalyticsQuery, GetInfluencerBioAnalyticsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetInfluencerBioAnalyticsQuery, GetInfluencerBioAnalyticsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetInfluencerBioAnalyticsLazyQuery = (
  options?: LazyQueryHookOptions<GetInfluencerBioAnalyticsQuery, GetInfluencerBioAnalyticsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetInfluencerBioAnalyticsQuery, GetInfluencerBioAnalyticsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
