import React from 'react';
import { APP_NAME } from '@src/libs/constant';
import * as StyledComponents from './StyledComponents';

const PrivacyContentVN = () => (
  <StyledComponents.Wrapper>
    <StyledComponents.PrivacyTitle title={`Chính sách quyền riêng tư của ${APP_NAME}`} />
    <StyledComponents.SubTitle>Cập nhật ngày: 19 tháng 11 năm 2020</StyledComponents.SubTitle>
    <StyledComponents.PrivacyText>
      {`Công ty AnyMind Group Pte. Ltd. (“AnyMind”, "Chúng tôi", "của Chúng tôi") vận hành "${APP_NAME}" `}
      <StyledComponents.Anchor href="https://app.anytag.jp/" target="_blank">
        https://app.anytag.jp/
      </StyledComponents.Anchor>
      {` Chúng tôi quan tâm đến các quyền lợi của bạn và luôn đồng ý rằng dữ liệu của bạn là của bạn. Vui lòng dành thời
      gian để xem qua chính sách bảo mật của Chúng tôi. Chính sách quyền riêng tư này của ${APP_NAME} sẽ giúp bạn hiểu
      Chúng tôi thu thập dữ liệu cá nhân nào, tại sao Chúng tôi thu thập dữ liệu đó, cách Chúng tôi bảo vệ dữ liệu cá
      nhân của bạn, thời gian Chúng tôi lưu giữ dữ liệu cá nhân của bạn và những quyền lợi có liên quan. Nếu bạn có bất
      kỳ câu hỏi nào, xin vui lòng liên hệ với chúng tôi bằng cách gửi email đến `}
      <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
        info@anymindgroup.com
      </StyledComponents.Anchor>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText>
      {`Trước tiên, vui lòng xem và làm quen với các loại cookie mà chúng tôi sử dụng cho Dịch vụ ${APP_NAME} dưới đây.`}
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="CÁC LOẠI COOKIE ĐƯỢC SỬ DỤNG">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Theo dõi Phân tích Hiệu suất:</b> Loại cookie này cung cấp thông tin tổng hợp về nơi người dùng có thể truy
          cập trên Trang web
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Google Analytics (sử dụng liên tục):</b> AnyMind Group sử dụng Google Analytics để giúp phân tích hành vi
          người dùng sử dụng trang web. Bên cạnh đó, Google Analytics sử dụng cookie để thu thập thông tin về số lượt
          truy cập vào trang web, thông tin những trang đã giới thiệu người dùng truy cập đến trang web, ngôn ngữ, thiết
          bị, trình duyệt và hệ điều hành, các trang mà người dùng truy cập trong trang web và các thông tin tương tự
          khác. Chúng tôi không chia sẻ thông tin này với bên thứ ba. Khi sử dụng theo cách này, cookie Google Analytics
          là cookie được sử dụng liên tục và sẽ vẫn còn trên thiết bị của bạn cho đến khi cookie hết hạn hoặc bạn xóa
          cookie. Các cookie này có thể xuất hiện dưới dạng _utm, _utma, _utmb, _utmc, _utmz, _utmv, _utmt, _gid
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Google Analytics (sử dụng theo kỳ):</b> Loại cookie này có dạng_js. Chúng tôi không giữ lại dữ liệu này vì
          nó sẽ bị xóa khi đóng trình duyệt của bạn và do đó Chúng tôi sẽ không thể chia sẻ dữ liệu đó với bất kỳ bên
          thứ ba nào.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Mạng xã hội:</b> Các cookie này sẽ hỗ trợ chức năng của trang web. Đây là các cookie của bên thứ ba và bạn
          có thể chia sẻ nhận xét, trang, dấu trang và lượt thích đồng thời giúp cung cấp quyền truy cập vào các công cụ
          mạng xã hội một cách dễ dàng.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Chia sẻ trên mạng xã hội:</b> Các tiện ích mạng xã hội được sử dụng trên trang web này bao gồm LinkedIn,
          Twitter và YouTube. Các tiện ích mạng xã hội này thu thập thông tin về việc bạn truy cập trang web và việc bạn
          sử dụng các dịch vụ nền tảng xã hội (ví dụ: các phím hoặc lượt thích) trên trang web. Chúng tôi không chia sẻ
          thông tin với các nền tảng xã hội này, tuy nhiên, xin lưu ý rằng các mạng xã hội thu thập trực tiếp dữ liệu về
          lượt truy cập trang web của bạn và tương tác với tiện ích xã hội
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Dịch vụ Bên thứ ba:</b> Chúng tôi có thể sử dụng cookie và pixel từ các nhà cung cấp là bên thứ ba – chẳng
          hạn như Google, Facebook và các nhà cung cấp khác – trên trang web của Chúng tôi để giúp cung cấp trải nghiệm
          phù hợp hơn cho bạn với tư cách là người dùng. Cookie hoặc pixel này có thể được sử dụng trên trang web của
          công ty AnyMind Group nhằm mục đích phân loại khách vàxác định khách truy cập truy cập vào trang web cũng như
          quảng cáo có thể được gửi cho khách đó thông qua các nền tảng có liên quan.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="1. NHỮNG THÔNG TIN CÁ NH N MÀ CHÚNG TÔI THU THẬP VÀ CÁCH MÀ CHÚNG TÔI THU THẬP NHỮNG THÔNG TIN ĐÓ?">
      Thông tin cá nhân là những thông tin trực tiếp hoặc gián tiếp định danh một cá nhân. Chúng tôi thu thập thông tin
      cá nhân của bạn với mục đích sử dụng thông tin đó cho dịch vụ của Chúng tôi hoặc để tham gia vào Mạnh lưới
      AnyCreator. AnyCreator với chức năng là bên kiểm soát dữ liệu tại đây thông báo đến bạn các thông tin bạn có thể
      cần biết để sử dụng dịch vụ của Chúng tôi hoặc để tham gia vào Mạng lưới AnyCreator của Chúng tôi.
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      Bạn có thể từ chối cung cấp cho Chúng tôi các thông tin cá nhân, nhưng nếu làm như vậy, Chúng tôi sẽ không thể
      cung cấp cho bạn Dịch vụ AnyCreator. Tuy nhiuên, bạn có thể ngừng việc cung cấp dữ liệu cá nhân cho Chúng tôi bất
      cứ lúc nào bằng cách gửi email đến&nbsp;
      <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
        info@anymindgroup.com
      </StyledComponents.Anchor>
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      Chúng tôi có thể thu thập dữ liệu cá nhân thông qua nhiều nguồn khác nhau. Bao gồm:
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Dữ liệu cá nhân được cung cấp trực tiếp từ bạn.</b> Chúng tôi thu thập dữ liệu về cách bạn sử dụng các dịch
          vụ và sản phẩm của Chúng tôi, chẳng hạn như loại nội dung bạn xem hoặc tương tác, hoặc tần suất và thời lượng
          hoạt động của bạn. Chúng tôi cũng thu thập dữ liệu cá nhân mà bạn cung cấp cho chúng tôi khi bạn đăng ký nhận
          các bản tin quảng cáo, hoàn thành khảo sát, đăng ký tài khoản để mua sản phẩm của Chúng tôi hoặc để tham gia
          Mạng lưới AnyCreator của Chúng tôi, và bằng bất kỳ cách thức liên lạc nào giữa bạn và chúng tôi cho dù qua
          e-mail, điện thoại hoặc các kênh liên lạc khác bao gồm cả chatbot của Chúng tôi. Bằng cách này, chúng tôi có
          thể yêu cầu dữ liệu cá nhân, bao gồm tên, giới tính, ngày sinh, địa chỉ, địa chỉ email, số điện thoại, ảnh
          chụp và chi tiết tài khoản ngân hàng của bạn;
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Dữ liệu cá nhân được thu thập tự động.</b> Chúng tôi cũng thu thập và lưu trữ một số loại dữ liệu cá nhân
          khi bạn tương tác trực tuyến với Chúng tôi. Việc thu thập này bao gồm nhưng không giới hạn những cookie mà
          Chúng tôi sử dụng để thu thập dữ liệu của bạn khi trình duyệt web của bạn truy cập vào trang web của Chúng tôi
          hoặc vào những quảng cáo và những nội dung khác được cung cấp bởi hoặc thay mặt Chúng tôi cung cấp trên các
          trang web khác. Chúng tôi cũng thu thập dữ liệu của bạn khi bạn tìm kiếm, đăng tải, hoặc tham gia một cuộc
          thi, trả lời bảng câu hỏi hoặc liên hệ với các nhóm dịch vụ khách hàng của Chúng tôi. Dữ liệu cá nhân của bạn
          mà Chúng tôi tự động thu thập bao gồm địa chỉ IP, mã ID của thiết bị, dữ liệu về vị trí, máy tính và thông tin
          kết nối, chẳng hạn như loại và phiên bản trình duyệt, cài đặt múi giờ, các loại và phiên bản plug-in của trình
          duyệt, hệ điều hành và lịch sử mua hàng. Chúng tôi cũng sử dụng các công cụ phần mềm của mình để đo lường và
          thu thập thông tin bao gồm các công cụ lắng nghe người dùng trên mạng xã hội để phân tích cảm tính về các bình
          luận trên trang mạng xã hội của bạn. Các công cụ phần mềm có thể thu thập thông tin liên quan đến thời gian
          phản hồi của trang, thời lượng truy cập vào các trang nhất định, v.v.; và
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Dữ liệu cá nhân chúng tôi thu thập từ bên thứ ba.</b> Chúng tôi có thể sử dụng cookie và pixel từ các nhà
          cung cấp là các bên thứ ba – ví dụ như Google, Facebook, Instagram, Twitter, YouTube và các nhà cung cấp khác
          thông qua API chính thức của họ – trên trang web của Chúng tôi để giúp cung cấp trải nghiệm phù hợp hơn cho
          bạn với tư cách là người dùng. Cookie hoặc pixel này có thể được sử dụng trên trang web của công ty AnyMind
          Group nhằm mục đích phân loại khách vàxác định khách truy cập truy cập vào trang web cũng như quảng cáo có thể
          được gửi cho khách đó thông qua các nền tảng có liên quan.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="2. TẠI SAO CHÚNG TÔI THU THẬP DỮ LIỆU CỦA BẠN?">
      Chúng tôi thu thập dữ liệu của bạn để phân tích hành vi truy cập mua phương tiện quảng cáo truyền thông của bạn,
      phản hồi yêu cầu của bạn, xử lý mọi khiếu nại; để phát triển và cải thiện các dịch vụ, phương thức liên lạc và
      chức năng trên trang web của Chúng tôi; để cung cấp dịch vụ được cá nhân hóa, truyền thông và quảng cáo được nhắm
      mục tiêu, để cung cấp bạn với các tài liệu tiếp thị, bản tin được yêu cầu và cũng có thể sử dụng nó để nâng cao
      ứng dụng.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="3. CHÚNG TÔI CHIA SẺ DỮ LIỆU CÁ NH N VÀ DỮ LIỆU PHI CÁ NH N CHO NHỮNG AI?">
      Chúng tôi hiểu rằng dữ liệu cá nhân của bạn là bảo mật, vì vậy, như đã nêu tại Chính sách quyền riêng tư của
      AnyCreator, dữ liệu cá nhân của bạn sẽ không bao giờ bị tiết lộ với bên thứ ba mà không có sự đồng ý từ bạn. Chúng
      tôi có thể gửi dữ liệu cá nhân của bạn cho các công ty con và công ty liên kết của Chúng tôi để họ có thể liên hệ
      với bạn liên quan đến yêu cầu của bạn hoặc đề xuất cho bạn về các chiến dịch đang chạy. Khi Chúng tôi gửi dữ liệu
      cá nhân của bạn cho công ty con hoặc công ty liên kết của Chúng tôi ở một quốc gia khác, Chúng tôi đảm bảo rằng họ
      sẽ áp dụng các mức độ bảo vệ thông tin của bạn giống như cách mà Chúng tôi thực hiện. Chúng tôi cũng có thể tiết
      lộ thông tin của bạn cho các bên thứ ba trong trường hợp nghi ngờ gian lận, yêu cầu pháp lý hoặc liên quan đến các
      nhà cung cấp và nhà thầu của Chúng tôi, tất cả các nội đung được quy định như dưới đây.
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      Nói chung, Chúng tôi không thu thập dữ liệu cá nhân của bạn thông qua quảng cáo của khách hàng và do đó, Chúng tôi
      không bán, cho thuê hoặc chia sẻ cho các bên thứ ba không liên kết các dữ liệu cá nhân được thu thập trên các
      trang web của Chúng tôi hoặc các trang web khác.
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      Chúng tôi có thể tiết lộ dữ liệu cá nhân của bạn cho bên thứ ba khi Chúng tôi tin rằng Chúng tôi có nghĩa vụ phải
      làm như vậy theo luật và để điều tra, ngăn chặn hoặc thực hiện hành động liên quan đến các hoạt động bị nghi ngờ
      hoặc bị cấm, bao gồm nhưng không giới hạn ở gian lận và các tình huống liên quan đến các mối đe dọa tiềm ẩn đối
      với sự an toàn về thể chất của bất kỳ ai. Ngoài ra nếu pháp luật hiện hành cho phép, Chúng tôi cũng có thể tiết lộ
      và cung cấp quyền truy cập vào dữ liệu cá nhân và dữ liệu phi cá nhân của bạn cho các nhà cung cấp, các nhà thầu
      đang thay mặt Chúng tôi thực hiện các dịch vụ. Tuy nhiên, các nhà cung cấp và nhà thầu đó không được phép sử dụng
      thông tin cá nhân của người dùng đó cho bất kỳ mục đích nào khác ngoài việc cung cấp dịch vụ cho Chúng tôi.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="4. CHÚNG TÔI BẢO VỆ DỮ LIỆU CÁ NH N CỦA BẠN BẰNG CÁCH NÀO?">
      Chúng tôi tuân theo các tiêu chuẩn ngành được chấp nhận rộng rãi nhằm chống truy cập trái phép, lưu giữ và tiết lộ
      thông tin. Các tiêu chuẩn này bao gồm thực hiện các hoạt động vật lý, điện tử và quản lý cần thiết để bảo vệ tính
      toàn vẹn, truy cập và sử dụng thông tin. Bất kỳ thông tin nào được lưu trữ trên máy chủ của Chúng tôi đều được coi
      là thông tin bí mật. Tuy nhiên, xin lưu ý rằng cho dù Chúng tôi đã nỗ lực thực hiện những biện pháp hợp lý để bảo
      vệ thông tin trên máy chủ, không có phương thức truyền qua Internet nào là đảm bảo an toàn. Do đó, mặc dù Chúng
      tôi cố gắng bảo vệ thông tin của bạn mọi lúc, nhưng Chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của thông
      tin đó.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="5. CHÚNG TÔI LƯU TRỮ THÔNG TIN CÁ NH N CỦA BẠN Ở Đ U?">
      Chúng tôi lưu giữ dữ liệu cá nhân của bạn trong cơ sở dữ liệu của Google Cloud, chỉ những nhà phát triển phần mềm
      được chỉ định là nhân viên của Chúng tôi mới có quyền chỉnh sửa/kiểm tra môi trường production, quyền đó do Google
      Cloud Platform và Amazon Web Services quản lý. Chúng tôi tiến hành kiểm tra hệ thống của mình trong mỗi lần cập
      nhật để đảm bảo an toàn cho việc bảo vệ dữ liệu cá nhân
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="6. CHÚNG TÔI LƯU TRỮ DỮ LIỆU CÁ NH N CỦA BẠN TRONG BAO L U?">
      Chúng tôi có thể sẽ sử dụng những cookie ẩn danh của mình cho mục đích quảng cáo trong tối đa 14 tháng. Đối với dữ
      liệu cá nhân của bạn, chúng tôi sẽ lưu trữ chúng cho đến khi bạn có yêu cầu từ chối lưu trữ hoặc yêu cầu xóa dữ
      liệu cá nhân của mình khỏi trang web của Chúng tôi
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="7. QUYỀN CỦA BẠN LÀ GÌ?">
      Chúng tôi tuân theo các tiêu chuẩn ngành được chấp nhận rộng rãi nhằm chống truy cập trái phép, lưu giữ và tiết lộ
      thông tin. Các tiêu chuẩn này bao gồm thực hiện các hoạt động vật lý, điện tử và quản lý cần thiết để bảo vệ tính
      toàn vẹn, truy cập và sử dụng thông tin. Bất kỳ thông tin nào được lưu trữ trên máy chủ của Chúng tôi đều được coi
      là thông tin bí mật. Tuy nhiên, xin lưu ý rằng cho dù Chúng tôi đã nỗ lực thực hiện những biện pháp hợp lý để bảo
      vệ thông tin trên máy chủ, không có phương thức truyền qua Internet nào là đảm bảo an toàn. Do đó, mặc dù Chúng
      tôi cố gắng bảo vệ thông tin của bạn mọi lúc, nhưng Chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của thông
      tin đó.
      <StyledComponents.PrivacyText>
        Quyền của bạn đối với những dữ liệu cá nhân của mình là như sau:
      </StyledComponents.PrivacyText>
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Quyền được cung cấp thông tin;</b> Bạn có quyền được cung cấp thông tin rõ ràng, minh bạch và dễ hiểu về
          cách Chúng tôi sử dụng dữ liệu cá nhân của bạn cũng như các quyền mà bạn có. Do đó, Chúng tôi cung cấp cho bạn
          các thông tin trong Chính sách quyền riêng tư của AnyCreator. Chúng tôi cũng sẽ thông báo cho bạn nếu Chúng
          tôi thu thập dữ liệu cá nhân của bạn cho các mục đích khác ngoài mục đích Chúng tôi đã mô tả ở trên.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Quyền tiếp cận/ quyền đính chính;</b> Bạn luôn có quyền truy cập, chỉnh sửa hoặc cập nhật dữ liệu cá nhân
          của mình bất cứ lúc nào. Chúng tôi hiểu tầm quan trọng của việc này và nếu bạn muốn thực hiện các quyền của
          mình, vui lòng liên hệ với Chúng tôi tại&nbsp;
          <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
            info@anymindgroup.com
          </StyledComponents.Anchor>
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Quyền chuyển đổi dữ liệu;</b> Dữ liệu cá nhân mà bạn đã cung cấp cho Chúng tôi có thể chuyển giao được.
          Điều này có nghĩa là các dữ liệu đó có thể được chuyển, sao chép hoặc truyền điện tử trong một số trường hợp
          nhất định.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Quyền từ chối;</b> bạn có quyền từ chối một số loại quy trình nhất định, bao gồm cả các quy trình để tiếp
          thị trực tiếp (tức là nhận email thông báo từ Chúng tôi hoặc được liên hệ với các cơ hội tiềm năng khác nhau).
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Quyền xóa/ quyền quên;</b> Bạn có quyền yêu cầu Chúng tôi xóa dữ liệu của bạn. Nếu bạn muốn xóa dữ liệu cá
          nhân mà Chúng tôi lưu giữ về bạn, vui lòng cho Chúng tôi biết và Chúng tôi sẽ căn cứ theo các yêu cầu pháp lý
          để thực hiện các bước hợp lý nhằm đáp ứng yêu cầu của bạn. Nếu dữ liệu cá nhân Chúng tôi thu thập không còn
          cần thiết cho bất kỳ mục đích nào và pháp luật không yêu cầu Chúng tôi lưu giữ dữ liệu đó, Chúng tôi sẽ làm
          những gì có thể để xóa, hủy hoặc hủy nhận dạng vĩnh viễn dữ liệu đó.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Quyền hạn chế xử lý;</b> bạn có quyền hạn chế việc xử lý dữ liệu cá nhân của mình.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Quyền rút lại sự đồng ý;</b> bạn có quyền rút lại sự đồng ý mà bạn đã cho Chúng tôi bất cứ lúc nào bằng
          cách liên hệ với chúng tôi qua&nbsp;
          <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
            info@anymindgroup.com
          </StyledComponents.Anchor>
          . Or&nbsp;
          <StyledComponents.Anchor href="mailto:dpo@anymindgroup.com" target="_blank">
            dpo@anymindgroup.com
          </StyledComponents.Anchor>
          , Chúng tôi sẽ căn cứ theo các yêu cầu pháp lý để thực hiện các bước hợp lý nhằm đáp ứng yêu cầu của bạn.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="8. QUYỀN RIÊNG TƯ CỦA TRẺ EM">
      Chúng tôi rất quan tâm đến vấn đề quyền riêng tư của trẻ em. Do đó, các trang web của Chúng tôi không được phát
      triển cho, cũng không hướng đến trẻ em. Nếu bạn tin rằng con bạn đã cung cấp các dữ liệu cá nhân cho Chúng tôi và
      bạn muốn xóa thông tin đó, vui lòng liên hệ với Chúng tôi theo địa chỉ&nbsp;
      <StyledComponents.Anchor href="mailto:dpo@anymindgroup.com" target="_blank">
        dpo@anymindgroup.com
      </StyledComponents.Anchor>
      .
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="9. THAY ĐỔI CHÍNH SÁCH QUYỀN RIÊNG TƯ NÀY">
      Chúng tôi có thể cập nhật Chính sách quyền riêng tư của mình bất cứ lúc nào. Chúng tôi sẽ thông báo cho bạn về bất
      kỳ thay đổi nào bằng cách đăng Chính sách quyền riêng tư mới trên trang này. Bạn nên xem lại Chính sách quyền
      riêng tư này định kỳ để biết về bất kỳ thay đổi nào. Các thay đổi đối với Chính sách quyền riêng tư này có hiệu
      lực khi chúng được đăng trên trang này.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="10. LÀM THẾ NÀO ĐỂ LIÊN HỆ VỚI CHÚNG TÔI?">
      Nếu bạn có bất kỳ câu hỏi nào liên quan đến Chính sách quyền riêng tư này, vui lòng liên hệ với chúng tôi qua địa
      chỉ 3 Anson Road, #20-03 Springleaf Tower, Singapore 079909 hoặc qua địa chỉ email&nbsp;
      <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
        info@anymindgroup.com
      </StyledComponents.Anchor>
      , or&nbsp;
      <StyledComponents.Anchor href="mailto:dpo@anymindgroup.com" target="_blank">
        dpo@anymindgroup.com
      </StyledComponents.Anchor>
      .
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="THÔNG BÁO COOKIE">
      Bằng cách sử dụng trang web này, bạn đã đồng ý sử dụng các cookie của Chúng tôi được quy định tại Thông báo cookie
      và Chúng sách quyền riêng tư của AnyCreator. Nếu bạn không chấp nhận sử dụng cookie của Chúng tôi theo cách này,
      bạn nên cài đặt lại trình duyệt web của mình, tắt cookie mà chúng tôi đang sử dụng hoặc không sử dụng trang web
      của Chúng tôi. Việc bạn tắt cookie mà Chúng tôi đang sử dụng có thể gây ảnh hưởng đến trải nghiệm người dùng khi
      sử dụng trang web.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="1. COOKIE LÀ GÌ?">
      Cookie là các tệp có lượng dữ liệu nhỏ, có thể bao gồm một mã định danh ẩn danh duy nhất. Cookie được gửi đến
      trình duyệt của bạn từ một trang web và được lưu trữ trên ổ cứng máy tính của bạn.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="2. MỤC ĐÍCH CHÚNG TÔI SỬ DỤNG COOKIE LÀ GÌ?">
      {`Chúng tôi sử dụng “cookie” để thu thập dữ liệu. Bạn có thể cài đặt trình duyệt của mình từ chối tất cả cookie
      hoặc cho biết khi nào cookie được gửi. Tuy nhiên, nếu bạn không chấp nhận cookie, bạn không thể sử dụng một số
      phần của Dịch vụ ${APP_NAME}.`}
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="3. TÔI CÓ THỂ XÓA COOKIE KHÔNG?">
      Có bạn có thể xóa cookie bằng việc từ chối đồng ý, từ chối bằng cách sử dụng cài đặt trình duyệt hoặc sử dụng công
      cụ quản lý cookie để từ chối cookie. Tuy nhiên việc xóa cookie có thể gây ảnh hưởng trải nghiệm người dùng khi sử
      dụng trang web.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="4. CHÚNG TÔI SỬ DỤNG NHỮNG LOẠI COOKIE NÀO?">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Theo dõi Phân tích Hiệu suất:</b> Loại cookie này cung cấp thông tin tổng hợp về nơi người dùng có thể truy
          cập trên Trang web
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Google Analytics (sử dụng liên tục):</b> AnyMind Group sử dụng Google Analytics để giúp phân tích cách
          người dùng truy cập sử dụng trang web. Trong số những thứ khác, Google Analytics sử dụng cookie để thu thập
          thông tin về số lượt truy cập vào trang web, thông tin những trang đã giới thiệu người dùng truy cập đến trang
          web, ngôn ngữ, thiết bị, trình duyệt và hệ điều hành, các trang mà người dùng truy cập trong trang web và các
          thông tin tương tự khác. Chúng tôi không chia sẻ thông tin này với bên thứ ba. Khi sử dụng theo cách này,
          cookie Google Analytics là cookie được sử dụng liên tục và sẽ vẫn còn trên thiết bị của bạn cho đến khi cookie
          hết hạn hoặc bạn xóa cookie. Các cookie này có thể xuất hiện dưới dạng _utm, _utma, _utmb, _utmc, _utmz,
          _utmv, _utmt, _gid
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Google Analytics (sử dụng theo kỳ):</b> Loại cookie này có dạng_js. Chúng tôi không giữ lại dữ liệu này vì
          nó sẽ bị xóa khi đóng trình duyệt của bạn và do đó Chúng tôi sẽ không thể chia sẻ dữ liệu đó với bất kỳ bên
          thứ ba nào.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Mạng xã hội:</b> Các cookie này sẽ hỗ trợ chức năng của trang web. Đây là các cookie của bên thứ ba và bạn
          có thể chia sẻ nhận xét, trang, dấu trang và lượt thích đồng thời giúp cung cấp quyền truy cập vào các công cụ
          mạng xã hội một cách dễ dàng.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Chia sẻ trên mạng xã hội:</b> Các tiện ích mạng xã hội được sử dụng trên trang web này bao gồm LinkedIn,
          Twitter và YouTube. Các tiện ích mạng xã hội này thu thập thông tin về việc bạn truy cập trang web và việc bạn
          sử dụng các dịch vụ nền tảng xã hội (ví dụ: các phím hoặc lượt thích) trên trang web. Chúng tôi không chia sẻ
          thông tin với các nền tảng xã hội này, tuy nhiên, xin lưu ý rằng các mạng xã hội thu thập trực tiếp dữ liệu về
          lượt truy cập trang web của bạn và tương tác với tiện ích xã hội
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Dịch vụ Bên thứ ba:</b> Chúng tôi có thể sử dụng cookie và pixel từ các nhà cung cấp là bên thứ ba – chẳng
          hạn như Google, Facebook và các nhà cung cấp khác – trên trang web của Chúng tôi để giúp cung cấp trải nghiệm
          phù hợp hơn cho bạn với tư cách là người dùng. Cookie hoặc pixel này có thể được sử dụng trên trang web của
          công ty AnyMind Group nhằm mục đích phân loại khách vàxác định khách truy cập truy cập vào trang web cũng như
          quảng cáo có thể được gửi cho khách đó thông qua các nền tảng có liên quan.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>
  </StyledComponents.Wrapper>
);

export default PrivacyContentVN;
