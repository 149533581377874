import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeComparePostsCountQuery,
  InfluencerYoutubeComparePostsCountQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerYoutubeComparePostsCount.graphql';

export const useInfluencerYoutubeComparePostsCountQuery = (
  options?: QueryHookOptions<InfluencerYoutubeComparePostsCountQuery, InfluencerYoutubeComparePostsCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerYoutubeComparePostsCountQuery, InfluencerYoutubeComparePostsCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerYoutubeComparePostsCountLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerYoutubeComparePostsCountQuery,
    InfluencerYoutubeComparePostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerYoutubeComparePostsCountQuery, InfluencerYoutubeComparePostsCountQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
