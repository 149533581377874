import { useAllAvailableCountriesQuery } from '@src/graphql/hooks';
import useTranslateOptions from './useTranslateOptions';

export default () => {
  const { data } = useAllAvailableCountriesQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    allCountries: data?.allAvailableCountries || [],
    allTranslatedCountries: useTranslateOptions(
      data?.allAvailableCountries.map(country => ({
        label: country.name,
        value: country.id,
      })) || []
    ),
  };
};
