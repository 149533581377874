import { css } from '@emotion/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { localizedDateFormatter } from '@src/libs/date';
import { formatDate } from '@src/libs/format';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';

interface DateColumnProps {
  date?: string | null;
  className?: string;
  isTable?: boolean;
  ignoreClientUTC?: boolean;
}

const DateColumn = (props: DateColumnProps) => {
  const { date, className, isTable = true, ignoreClientUTC } = props;
  const { i18n } = useTranslation();
  const Wrapper = isTable ? 'td' : 'div';
  // https://stackoverflow.com/questions/66914706/date-fns-format-function-shows-next-date-instead-of-the-expected-one
  // `ignoreClientUTC` props uses the same UTC from incoming ISO date
  const parsedDate = ignoreClientUTC ? date?.replace('Z', '') || null : date;

  return (
    <Wrapper className={className} css={{ ...(isTable ? styles.tableWrapper : {}) }}>
      <p css={styles.data}>
        {parsedDate ? (
          <>
            {localizedDateFormatter(parsedDate, 'MMM do', i18n.language as AppLanguage)}
            <br />
            <span css={styles.year}>{formatDate(parsedDate, 'yyyy')}</span>
          </>
        ) : (
          '-'
        )}
      </p>
    </Wrapper>
  );
};

const styles = {
  data: css({
    fontSize: 13,
    textAlign: 'left',
    whiteSpace: 'nowrap',
  }),
  tableWrapper: css({
    height: 56,
    padding: '0 8px',
  }),
  year: css({
    color: '#8694a2',
    fontSize: 11,
    textAlign: 'center',
  }),
};

export default DateColumn;
