import { lazy } from 'react';

export const Entry = lazy(() => import('../Entry'));
export const SignIn = lazy(() => import('../SignIn'));
export const SignUp = lazy(() => import('../SignUp'));
export const UuumSignUp = lazy(() => import('../UuumSignUp'));
export const SignUpInstagram = lazy(() => import('../SignUp/Instagram'));
export const SignInInstagramUnableConnect = lazy(() => import('../SignIn/InstagramUnableConnect'));
export const SignInTiktokGrantPermission = lazy(() => import('../SignIn/TiktokGrantPermission'));
export const SignInFacebookGrantPermission = lazy(() => import('../SignIn/FacebookGrantPermission'));
export const SignUpSuccess = lazy(() => import('../SignUp/Success'));
export const SignUpTiktokGrantPermission = lazy(() => import('../SignUp/TiktokGrantPermission'));
export const SignUpFacebook = lazy(() => import('../SignUp/Facebook'));
export const SignUpFacebookGrantPermission = lazy(() => import('../SignUp/FacebookGrantPermission'));
export const SignUpProfile = lazy(() => import('../SignUp/Profile'));
export const CampaignDetailsPublic = lazy(() => import('../CampaignDetailsPublic'));
