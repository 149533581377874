import React, { CSSProperties, PropsWithChildren, ReactElement, RefObject } from 'react';
import Popup from 'reactjs-popup';
import FocusLock from 'react-focus-lock';
import { css, SerializedStyles } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import Portal from '@src/components/atoms/Portal';
import ThemeButton, { ThemeButtonProps } from '@src/components/molecules/ThemeButton';
import useDirLayout from '@src/libs/hooks/useDirLayout';
import { mainBlack, PaletteColorType } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import 'reactjs-popup/dist/index.css';

interface ButtonProps {
  customPalette?: PaletteColorType;
  disabled?: boolean;
  loading?: boolean;
  postfixIcon?: ReactElement;
  prefixIcon?: ReactElement;
  styles?: SerializedStyles | SerializedStyles[];
  text?: string;
  theme?: ThemeButtonProps['theme'];
}

interface DialogProps {
  actionContentStyles?: SerializedStyles;
  cancelButtonProps?: ButtonProps & {
    onClick: () => void;
  };
  closeButtonProps?: {
    styles?: SerializedStyles;
  };
  closeOnDocumentClick?: boolean;
  contentRef?: RefObject<HTMLDivElement>;
  contentStyles?: CSSProperties;
  footerNode?: ReactElement;
  nextButtonProps?: ButtonProps & {
    target?: string;
    to?: string;
    onClick?: () => void;
  };
  open: boolean;
  removeContentPadding?: boolean;
  onClose?: () => void;
}

const Dialog = ({
  actionContentStyles,
  cancelButtonProps,
  closeButtonProps,
  closeOnDocumentClick,
  contentRef,
  contentStyles,
  children,
  footerNode,
  nextButtonProps,
  open,
  removeContentPadding,
  onClose,
}: PropsWithChildren<DialogProps>) => {
  const { dir } = useDirLayout();
  const popupStyles: CSSProperties = {
    border: 'none',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: 'max-content',
    maxHeight: '80%',
    maxWidth: 480,
    padding: '0',
    width: '90%',
  };

  return (
    <Portal portalId="portal-root">
      <Popup
        closeOnDocumentClick={closeOnDocumentClick}
        contentStyle={{ ...popupStyles, ...contentStyles }}
        lockScroll
        modal
        open={open}
        onClose={onClose}
      >
        {
          ((close: () => void) => (
            <FocusLock autoFocus={false} css={{ display: 'contents', height: '100%' }} whiteList={() => false}>
              <div css={[styles.container, removeContentPadding && { padding: 0 }]} dir={dir} ref={contentRef}>
                <div css={[styles.closeBtn, closeButtonProps?.styles]} onClick={close}>
                  <Icomoon color="#6e7c89" icon="clear" size={12} />
                </div>
                {children}
              </div>
              {footerNode ? (
                footerNode
              ) : cancelButtonProps || nextButtonProps ? (
                <div css={[styles.actionContainer, actionContentStyles]}>
                  {cancelButtonProps && (
                    <ThemeButton
                      {...cancelButtonProps}
                      css={cancelButtonProps.styles}
                      text={cancelButtonProps.text || 'Cancel'}
                    />
                  )}
                  {nextButtonProps && (
                    <ThemeButton
                      {...nextButtonProps}
                      css={nextButtonProps.styles}
                      customPalette={nextButtonProps.customPalette || mainBlack}
                      text={nextButtonProps.text || 'Next'}
                    />
                  )}
                </div>
              ) : null}
            </FocusLock>
          )) as any
        }
      </Popup>
    </Portal>
  );
};

const blackCircleCloseStyles = css({
  background: '#000',
  borderRadius: '50%',
  color: THEME.font.colors.white,
  height: 36,
  right: -16,
  top: -16,
  width: 36,

  '& > svg': {
    color: THEME.font.colors.white,
  },
});

const smallButtonStyles = css({
  borderRadius: '5px !important',
  height: '40px !important',

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    height: '32px !important',
    width: 'fit-content',
  },
});

const styles = {
  actionContainer: css({
    borderTop: '1px solid #dee5ec',
    display: 'flex',
    gap: THEME.box.gaps.s,
    justifyContent: 'flex-end',
    padding: 16,

    '& > a, button': {
      borderRadius: 9,
      height: 48,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        height: 40,
      },
    },
  }),
  closeBtn: css({
    alignItems: 'center',
    color: THEME.font.colors.gray.main,
    cursor: 'pointer',
    display: 'grid',
    fontSize: 30,
    justifyContent: 'center',
    position: 'absolute',
    right: 12,
    top: 12,
  }),
  container: css({
    height: '100%',
    padding: '24px 16px',
    overflow: 'auto',
  }),
};

export { blackCircleCloseStyles, smallButtonStyles };
export default Dialog;
