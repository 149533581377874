import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import thankYouForWaitingUsToStart from '@src/assets/img/findJobs/thankYouForWaitingUsToStart.png';
import { Dialog } from '@src/components/molecules';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface DialogJoinedSelectionProps {
  isAutoInfluencerApproval?: boolean | null;
  open: boolean;
  title: string;
  onClose: () => void;
}

const DialogJoinedSelection = ({ isAutoInfluencerApproval, open, title, onClose }: DialogJoinedSelectionProps) => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <Dialog
      cancelButtonProps={{
        text: 'Close',
        onClick: onClose,
      }}
      contentStyles={{
        backgroundColor: THEME.colors.white,
        borderRadius: 9,
        boxShadow: THEME.box.shadows.outer,
        height: isMobileView ? '100%' : 554,
        maxHeight: '90%',
        maxWidth: 648,
        width: '90%',
      }}
      open={open}
      onClose={onClose}
    >
      <div css={styles.dialogContainer}>
        <div css={styles.dialogTitle}>{t('Dialog.Thank you for applying')}</div>
        <div css={styles.dialogImgContainer}>
          <img height="173" src={thankYouForWaitingUsToStart} width="165" />
        </div>
        <Description>
          <Trans i18nKey="Annotation.Successfully applied selection campaign" values={{ title }}>
            description <span css={{ fontWeight: 600 }}>title</span>
          </Trans>
        </Description>
        <Description>
          {t(
            isAutoInfluencerApproval
              ? 'Annotation.Please wait patiently for campaign starts'
              : 'Annotation.You will be notified if you are approved'
          )}
        </Description>
      </div>
    </Dialog>
  );
};

const Description = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  justifySelf: 'center',

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    textAlign: 'center',
    width: 311,
  },
});

const styles = {
  dialogActionContainer: css({
    borderTop: '1px solid #dee5ec',
    padding: 16,

    '& > button': {
      borderRadius: 9,
      height: 48,
    },
  }),
  dialogContainer: css({
    display: 'grid',
    padding: '24px 8px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '32px 8px',
    },
  }),
  dialogImgContainer: css({
    justifySelf: 'center',
    marginBottom: 40,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      marginBottom: 16,
    },
  }),
  dialogTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 700,
    marginBottom: 62,
  }),
};

export default DialogJoinedSelection;
