import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components, MultiValue, MultiValueGenericProps, OptionProps, Props } from 'react-select';
import { css } from '@emotion/react';
import { Label } from '@src/components/shared';
import { useGetTagsAndFansRecipientsQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';

export interface RecipientSelectOption {
  id: number;
  isFan: boolean;
  label: string;
  name: string;
  subtitle: string;
  value: string;
}

interface RecipientSelectProps extends Omit<Props, 'onChange'> {
  error?: boolean;
  fanIds?: number[];
  isRequired?: boolean;
  tagIds?: number[];
  title?: string;
  onChange: (value: MultiValue<RecipientSelectOption>) => void;
}

const RecipientSelect = ({ error, fanIds, isRequired, tagIds, title, onChange }: RecipientSelectProps) => {
  const [options, setOptions] = useState<RecipientSelectOption[]>([]);
  const { t } = useTranslation();
  useGetTagsAndFansRecipientsQuery({
    variables: { input: { keyword: '' } },
    onCompleted: data => {
      let items: RecipientSelectOption[] = [];
      const getTagsAndFansRecipients = data?.getTagsAndFansRecipients;
      if (getTagsAndFansRecipients) {
        items = items.concat(
          getTagsAndFansRecipients.fans.map(item => {
            const { email, id, name } = item;

            return {
              id,
              isFan: true,
              label: `${name} ${email}`,
              name,
              subtitle: email || '',
              value: `fan_${id}`,
            };
          })
        );
        items = items.concat(
          getTagsAndFansRecipients.tags.map(item => {
            const { count, id, tag } = item;

            return {
              id,
              isFan: false,
              label: tag,
              name: tag,
              subtitle: `${count} users`,
              value: `tag_${id}`,
            };
          })
        );
      }
      setOptions(items);
    },
  });

  const MenuOption = (props: OptionProps<RecipientSelectOption, true>) => {
    const { name, subtitle } = props.data;

    return (
      <components.Option {...props}>
        <div css={styles.menuItem}>
          <div>{name}</div>
          <div>{subtitle}</div>
        </div>
      </components.Option>
    );
  };

  const MultiValueLabel = (props: MultiValueGenericProps<RecipientSelectOption>) => {
    const { name } = props.data;

    return (
      <components.MultiValueLabel {...props}>
        <div>{name}</div>
      </components.MultiValueLabel>
    );
  };

  const value = options.filter(option => {
    const { isFan, id } = option;

    return isFan ? fanIds?.includes(id) : tagIds?.includes(id);
  });

  return (
    <div>
      {title && <Label css={styles.label} isRequired={isRequired} title={title} />}
      <Select
        components={{ MultiValueLabel, Option: MenuOption }}
        css={styles.select}
        isClearable={false}
        isMulti
        options={options}
        placeholder={t('Choose recipients from tags or fan name')}
        styles={{
          control: base => ({
            ...base,
            border: `1px solid ${error ? '#ff6247' : '#dee5ec'}`,
            minHeight: 32,
          }),
          indicatorSeparator: base => ({
            ...base,
            display: 'none',
          }),
          multiValueLabel: base => ({
            ...base,
            backgroundColor: '#eef3f7',
            color: THEME.font.colors.black.main,
            fontSize: THEME.font.sizes.subordinate,
          }),
          multiValueRemove: base => ({
            ...base,
            backgroundColor: '#eef3f7',
          }),
          placeholder: base => ({
            ...base,
            color: '#e9e9e9',
          }),
        }}
        value={value}
        onChange={selectedOption => onChange(selectedOption)}
      />
    </div>
  );
};

const styles = {
  label: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  }),
  menuItem: css({
    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subordinate,
      marginBottom: 4,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.hint,
    },
  }),
  select: css({
    '& > div > div:nth-of-type(2)': {
      '& > div': {
        padding: '0 8px',
      },
    },
  }),
};

export default RecipientSelect;
