import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  MadeMarketplaceCampaignPostMutation,
  MadeMarketplaceCampaignPostMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/MadeMarketplaceCampaignPost.graphql';

export const useMadeMarketplaceCampaignPostMutation = (
  options?: MutationHookOptions<MadeMarketplaceCampaignPostMutation, MadeMarketplaceCampaignPostMutationVariables>
) => useMutation<MadeMarketplaceCampaignPostMutation, MadeMarketplaceCampaignPostMutationVariables>(MUTATION, options);
