import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { InfluencerUpdateDetailV2Query, InfluencerUpdateDetailV2QueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerUpdateDetailV2.graphql';

export const useInfluencerUpdateDetailV2Query = (
  options?: QueryHookOptions<InfluencerUpdateDetailV2Query, InfluencerUpdateDetailV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerUpdateDetailV2Query, InfluencerUpdateDetailV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerUpdateDetailV2LazyQuery = (
  options?: LazyQueryHookOptions<InfluencerUpdateDetailV2Query, InfluencerUpdateDetailV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerUpdateDetailV2Query, InfluencerUpdateDetailV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
