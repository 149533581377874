import { FanFormAnswer, QuestionType } from '@src/__generated__/globalTypes';
import { TFunction } from 'i18next';

export const getQuestionWithAnswer = (answer: FanFormAnswer, t: TFunction) => {
  switch (answer.questionType) {
    case QuestionType.NAME:
      return {
        question: t('Name'),
        answer: answer.answerText,
      };
    case QuestionType.EMAIL:
      return {
        question: t('Email'),
        answer: answer.answerText,
      };
    case QuestionType.SHORT_ANSWER:
      return {
        question: answer.questionTitle,
        answer: answer.answerText,
      };
    case QuestionType.CHECKBOX:
    case QuestionType.DROPDOWN:
      return {
        question: answer.questionTitle,
        answer: answer.answerOptionTitles.join(', '),
      };
    default:
      return {
        question: null,
        answer: null,
      };
  }
};
