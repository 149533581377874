import { v4 as uuidv4 } from 'uuid';
import { useGetFormQuery, useGetFormRespondentsCountQuery } from '@src/graphql/hooks';
import { FormStatus } from '@src/__generated__/globalTypes';

interface UseGetFormProps {
  formId: string;
}

const useGetForm = ({ formId }: UseGetFormProps) => {
  const { data, loading } = useGetFormQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: formId,
      },
    },
  });

  const { data: dataRespondentCount } = useGetFormRespondentsCountQuery({
    variables: {
      input: {
        id: Number(formId),
      },
    },
  });

  const description = data?.getForm?.description || '';
  const hash = data?.getForm?.hash || '';
  const id = data?.getForm?.id || '';
  const questions =
    data?.getForm?.questions.map(question => {
      const { id: questionId, image, isRequired, options, order, questionType, title: questionTitle } = question;

      return {
        id: questionId,
        genId: uuidv4(),
        image,
        isRequired,
        options,
        order,
        questionType,
        title: questionTitle,
      };
    }) || [];
  const respondentCount = dataRespondentCount?.getFormRespondentsCount?.total || 0;
  const status = data?.getForm?.status || FormStatus.DRAFT;
  const thankDescription = data?.getForm?.thankDescription || '';
  const thankTitle = data?.getForm?.thankTitle || '';
  const title = data?.getForm?.title || '';

  return {
    description,
    hash,
    id,
    isLoading: loading,
    questions,
    respondentCount,
    status,
    thankDescription,
    thankTitle,
    title,
  };
};

export default useGetForm;
