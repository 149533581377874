import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Empty } from '@src/components/atoms';
import Popover, { MenuItem } from '@src/components/atoms/Popover';
import { ListLoading, ThemeButton } from '@src/components/molecules';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import { useQueryHelper } from '@src/libs/hooks';
import { FanActivityCommentSchema } from '@src/libs/validation';
import { LIMIT } from '@src/libs/constant';
import { css } from '@emotion/react';
import { useGetCreatorStaffsQuery, useGetFanActivitiesQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { getActivityList } from '../helper';
import ActivityRow from './ActivityRow';
import CommentForm, { FormValues } from './CommentForm';
import useStateHandler from './useStateHandler';

const ActivityTimeline = () => {
  const { t, params } = useQueryHelper();
  const [creatorStaffs, setCreatorStaffs] = useState<{ [key: string]: string }>({});
  const { id } = params as { id: string };
  const { data, loading, fetchMore } = useGetFanActivitiesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        fanId: Number(id),
        offset: 0,
        limit: LIMIT,
      },
    },
  });
  useGetCreatorStaffsQuery({
    variables: {
      input: {
        limit: LIMIT,
        offset: 0,
      },
    },
    onCompleted: ({ getCreatorStaffs }) => {
      const res =
        getCreatorStaffs?.staffs.reduce<{ [key: string]: string }>(
          (prev, curr) => ({ ...prev, [curr.id]: curr.name }),
          {
            [getCreatorStaffs.id]: getCreatorStaffs.name,
          }
        ) || {};

      setCreatorStaffs(res);
    },
  });

  const methods = useForm<{ comment: string }>({
    defaultValues: { comment: '' },
    resolver: yupResolver(FanActivityCommentSchema),
  });

  const {
    activity,
    handleAddComment,
    handleUpdateComment,
    handleClickEdit,
    handleClickDelete,
    handleClickMore,
    handleClose,
    handleDeleteConfirm,
  } = useStateHandler(Number(id));

  const handleViewMore = async () => {
    await fetchMore({
      variables: { input: { fanId: Number(id), offset: data?.getFanActivities?.activities.length || 0, limit: LIMIT } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          ...prev,
          getFanActivities: {
            total: fetchMoreResult.getFanActivities?.total || 0,
            __typename: 'GetFanActivitiesPayload',
            activities: [
              ...(prev.getFanActivities?.activities || []),
              ...(fetchMoreResult.getFanActivities?.activities || []),
            ],
          },
        };
      },
    });
  };

  const onSubmit = (payload: FormValues) => {
    handleAddComment(payload);
    methods.reset();
  };

  if (loading) {
    return <ListLoading />;
  }

  if (!data?.getFanActivities?.activities.length) {
    return <Empty />;
  }

  const total = data.getFanActivities.total || 0;
  const activities = getActivityList(data.getFanActivities.activities, t);

  return (
    <div css={{ position: 'relative' }}>
      {/* Delete confirmation dialog */}
      <Dialog
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: handleClose,
        }}
        closeButtonProps={{
          styles: blackCircleCloseStyles,
        }}
        contentStyles={{ borderRadius: 3, maxWidth: 400 }}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Delete',
          theme: 'blue',
          onClick: handleDeleteConfirm,
        }}
        open={activity.showDeleteConfirmation}
        onClose={handleClose}
      >
        <h1 css={styles.heading}>{t('Confirmation required')}</h1>
        <p css={styles.regularText}>{t('Are you sure you want to delete the activity?')}</p>
      </Dialog>
      {/* Add new comment */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CommentForm />
        </form>
      </FormProvider>
      {/* List of activities */}
      <div>
        {activities.map((item, index) => (
          <ActivityRow
            key={(item.activity || '') + index}
            created={item.created}
            activity={item.activity}
            activityType={item.activityType}
            commenter={item.commenterId ? creatorStaffs?.[item.commenterId] || '' : ''}
            popoverNode={
              <Popover
                renderTrigger={
                  <ThemeButton
                    skipTranslation
                    prefixIcon="&middot;&middot;&middot;"
                    width="max-content"
                    onClick={() => handleClickMore(item.id)}
                    css={{
                      alignSelf: 'center',
                      border: 'none !important',
                      fontSize: THEME.font.sizes.subHeading,
                      outline: 'none !important',
                      padding: '0 8px',
                    }}
                  />
                }
              >
                <div css={{ minWidth: '80px' }}>
                  <MenuItem onClick={handleClickEdit}>{t('Edit')}</MenuItem>
                  <MenuItem onClick={handleClickDelete}>{t('Delete')}</MenuItem>
                </div>
              </Popover>
            }
            showCommentInsideForm={activity.showCommentInsideForm && item.id === activity.id}
            onUpdate={handleUpdateComment}
          />
        ))}
      </div>
      {data.getFanActivities.activities.length < total && (
        <div css={styles.buttonWrapper}>
          <ThemeButton
            skipTranslation
            prefixIcon="&middot;&middot;&middot;"
            width="max-content"
            onClick={handleViewMore}
            css={{
              alignSelf: 'center',
              border: 'none',

              '.prefix-icon': {
                fontSize: THEME.font.sizes.title,
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  buttonWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    background: 'linear-gradient(180deg, rgba(246, 248, 250, 0) 0%, #fff 49.4%)',
    height: 92,
    bottom: -40,
    position: 'absolute',
    width: '100%',
    alignItems: 'center',
  }),
  heading: css({
    marginBottom: 24,
    fontWeight: 600,
    fontSize: THEME.font.sizes.heading,
    lineHeight: '22px',
    color: THEME.font.colors.black.main,
  }),
  regularText: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
    marginBottom: 16,
  }),
};

export default ActivityTimeline;
