import { css } from '@emotion/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import NotFoundImg from '@src/assets/img/notfound.png';
import { useDocumentTitle } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

const Template = () => {
  useDocumentTitle({ title: 'pageTitle.Maintenance' });
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper}>
      <div className="error_wrapper" css={styles.shade}>
        <div css={styles.textWrapper}>
          <h1 css={styles.header}>{t('Sentence.System Maintenance')}</h1>
          <h2 css={styles.subHeader}>{t('Sentence.Thank you for using')}</h2>
          <h3 css={styles.text}>{t('Sentence.AnyCreator is temporarily down for maintenance')}</h3>
          <a css={styles.anchor} href="https://casting-asia.com/" rel="noopener noreferrer" target="_blank">
            {t('Sentence.Go to the AnyCreator Site')}
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  anchor: css({
    width: 360,
    margin: '54px auto 0',
    backgroundColor: '#ff4b61',
    borderRadius: 2,
    color: THEME.font.colors.white,
    fontSize: 17,
    textAlign: 'center',
    letterSpacing: '0.05em',
    lineHeight: '58px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',

    '&:hover': {
      backgroundColor: '#e1485e',
    },

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      width: '100%',
      margin: '40px auto 0',
      fontSize: 15,
      lineHeight: '48px',
    },
  }),
  header: css({
    fontSize: 64,
    fontWeight: 600,
    textAlign: 'center',
    textShadow: '1px 1px 6px #f0f0f0',

    [`@media (max-width: 850px)`]: {
      fontSize: 40,
    },
  }),
  shade: css({
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    width: '100%',
  }),
  subHeader: css({
    marginTop: 20,
    padding: '0 24px',
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '1.4em',
    textShadow: '1px 1px 6px #f0f0f0',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.subTitle,
    },
  }),
  text: css({
    marginTop: 8,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    lineHeight: '1.5em',
    textShadow: '1px 1px 6px #f0f0f0',

    [`@media (max-width: 850px)`]: {
      fontSize: 15,
    },
  }),
  textWrapper: css({
    maxWidth: 740,
    margin: '0 auto',
    textAlign: 'center',
    letterSpacing: '0.025em',
    color: '#3b4146',
    padding: '0 5%',
  }),
  wrapper: css({
    background: `url(${NotFoundImg}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
};

export default Template;
