import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthSignInInstagramLineMutation,
  AnyXSocialAuthSignInInstagramLineMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthSignInInstagramLine.graphql';

export const useAnyXSocialAuthSignInInstagramLineMutation = (
  options?: MutationHookOptions<
    AnyXSocialAuthSignInInstagramLineMutation,
    AnyXSocialAuthSignInInstagramLineMutationVariables
  >
) =>
  useMutation<AnyXSocialAuthSignInInstagramLineMutation, AnyXSocialAuthSignInInstagramLineMutationVariables>(
    MUTATION,
    options
  );
