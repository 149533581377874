import styled from '@emotion/styled';
import SentenceForm from './SentenceForm';

const StyledSentenceForm = styled(SentenceForm)({
  '& > textarea': {
    borderRadius: 9,
  },
});

export default StyledSentenceForm;
