import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { SearchForm, Select } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { EmailEventStatusForSearch } from '@src/__generated__/globalTypes';

export interface FilterItems {
  keyword: string;
  status: EmailEventStatusForSearch;
}

interface RecipientListFilterProps {
  values: FilterItems;
  onChange: (values: FilterItems) => void;
}

const RecipientListFilter = ({ values, onChange }: RecipientListFilterProps) => {
  const [filter, setFilter] = useState<FilterItems>(values);
  const { t } = useTranslation();
  const statusOptions = [
    { label: 'Bounced', value: EmailEventStatusForSearch.BOUNCE },
    { label: 'Clicked', value: EmailEventStatusForSearch.CLICK },
    { label: 'Opened', value: EmailEventStatusForSearch.OPEN },
  ];

  useEffect(() => {
    setFilter(values);
  }, [values.keyword, values.status]);

  return (
    <div css={styles.container}>
      <div>
        <SearchForm
          css={styles.searchForm}
          placeholder={t(`TextForm.Search by Fan's Name or Email Address`)}
          value={filter.keyword}
          onBlur={() => onChange(filter)}
          onChange={e => setFilter({ ...filter, keyword: e.target.value })}
          onEnterKeyPress={() => onChange(filter)}
        />
      </div>
      <div>
        <Select
          options={statusOptions}
          placeholder="Status"
          value={filter.status}
          onChange={value => onChange({ ...filter, status: value as EmailEventStatusForSearch })}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,
    gap: THEME.box.gaps.s,

    '& > div:nth-of-type(1)': {
      display: 'flex',
      flex: 1,

      '& > div': {
        width: '100%',
      },
    },

    '& > div:nth-of-type(2)': {
      width: 160,
    },
  }),
  searchForm: css({
    '& input': {
      height: 32,
    },
  }),
};

export default RecipientListFilter;
