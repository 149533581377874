import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { VideoPreview } from '@src/components/atoms';
import { formatNumberWithCommas } from '@src/libs/format';
import { defaultEmptyImage, isVideoThumbnail } from '@src/libs/image';
import { switchText } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignSocialMediaType,
  CampaignType,
  EngagementCampaignPostStatus,
  MarketplaceCampaignPostStatus,
} from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';
import StatusStepper from '../StatusStepper';
import ReasonWidget from './ReasonWidget';

interface PostReport {
  anyXStatus?: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
  click?: number | null;
  comment: number;
  conversion?: number | null;
  id: number;
  inappropriateDetailedReason?: string | null;
  inappropriateReason?: string | null;
  like: number;
  postCaption: string;
  postSocialMedia: CampaignSocialMediaType;
  postUrl: string;
  share: number;
  status: EngagementCampaignPostStatus | MarketplaceCampaignPostStatus;
  thumbNail?: string | null;
  view: number;
}

export interface CampaignPostReportsProps {
  campaignPostReports: {
    currency: string;
    id: number;
    postReport: PostReport[];
    revenue?: number;
    title: string;
  };
  campaignType: CampaignType;
}

const CampaignPostReports = ({ campaignPostReports, campaignType }: CampaignPostReportsProps) => {
  const { currency, postReport, revenue, title } = campaignPostReports;
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.reportContainer}>
      <div css={styles.infoContainer}>
        <div>
          <div css={styles.titleContainer}>
            <div css={styles.title}>{title}</div>

            {revenue && (
              <div css={styles.revenueContainer}>
                <div>{t('Revenue')}</div>
                <div>{`${formatNumberWithCommas(revenue)} ${t(currency)}`}</div>
              </div>
            )}
          </div>

          {postReport.map(post => {
            const {
              anyXStatus,
              click,
              comment,
              conversion,
              like,
              inappropriateDetailedReason,
              inappropriateReason,
              postCaption,
              postSocialMedia,
              postUrl,
              share,
              thumbNail,
              view,
            } = post;
            const engagements = [
              { label: postSocialMedia === CampaignSocialMediaType.FACEBOOK ? 'Reactions' : 'Like', value: like },
              { label: 'Comments', value: comment },
              { label: 'Share', value: share },
              { label: 'Click', value: click !== null ? click : '-' },
              { label: 'Conversion', value: conversion !== null ? conversion : '-' },
              { label: 'View', value: view },
            ];
            const showWarning = inappropriateDetailedReason && inappropriateReason;

            return (
              <div css={styles.postContainer} key={post.id}>
                {!isMobileView && showWarning && (
                  <ReasonWidgetContainer>
                    <ReasonWidget content={inappropriateDetailedReason || ''} title={inappropriateReason || ''} />
                  </ReasonWidgetContainer>
                )}

                <div css={styles.postHeader}>{t('Post Status')}</div>
                {anyXStatus && (
                  <div css={styles.stepper}>
                    <StatusStepper activeColor={THEME.colors.black.main} status={anyXStatus} type={campaignType} />
                  </div>
                )}

                {isMobileView && showWarning && (
                  <ReasonWidgetContainer>
                    <ReasonWidget content={inappropriateDetailedReason || ''} title={inappropriateReason || ''} />
                  </ReasonWidgetContainer>
                )}

                <div css={styles.contentContainer}>
                  <div>
                    <div>
                      {isVideoThumbnail(thumbNail) ? (
                        <VideoPreview height="85" url={thumbNail || ''} width="151" />
                      ) : (
                        <img alt="postImg" height="85" src={defaultEmptyImage(thumbNail)} width="151" />
                      )}
                    </div>
                  </div>
                  <div>
                    <span>{postCaption}</span>
                    <Link to={postUrl} target="_blank">{`View on ${switchText(postSocialMedia)}`}</Link>
                  </div>
                </div>

                <div css={styles.engagementContainer}>
                  {engagements.map(({ label, value }, index) => (
                    <div key={index}>
                      <EngagementTitle>{t(label)}</EngagementTitle>
                      <EngagementValue>{value}</EngagementValue>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const EngagementTitle = styled.div({
  alignItems: 'center',
  backgroundColor: '#f6f8fa',
  color: THEME.font.colors.black.main,
  display: 'flex',
  fontSize: 13,
  fontWeight: 600,
  height: 39,
  justifyContent: 'center',
  width: '100%',
});

const EngagementValue = styled.div({
  alignItems: 'center',
  background: THEME.colors.white,
  color: THEME.font.colors.black.main,
  display: 'flex',
  fontSize: 13,
  height: 39,
  justifyContent: 'center',
  width: '100%',
});

const ReasonWidgetContainer = styled.div({
  display: 'flex',
  marginBottom: 24,
});

const styles = {
  contentContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 8,

    '& > div:nth-of-type(1)': {
      height: 85,
      maxWidth: 167,
      width: '50%',

      '& > div': {
        backgroundColor: '#000',
        marginRight: 16,

        '& > img': {
          objectFit: 'contain',
          width: '100%',
        },
      },
    },

    '& > div:nth-of-type(2)': {
      position: 'relative',
      width: '65%',

      '& > span': {
        color: THEME.font.colors.black.main,
        display: '-webkit-box',
        fontSize: THEME.font.sizes.normal,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
      },

      '& > a': {
        bottom: 5,
        color: THEME.font.colors.blue.main,
        position: 'absolute',
        textDecoration: 'underline',
      },

      [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
        width: '50%',
      },
    },
  }),
  engagementContainer: css({
    background: '#dee5ec',
    border: THEME.box.borders.gray.main,
    display: 'grid',
    gap: 1,
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginTop: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
  }),
  infoContainer: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      maxWidth: 648,
    },
  }),
  postContainer: css({
    borderTop: '1px solid #eef3f7',
    padding: 24,
  }),
  postHeader: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  }),
  reportContainer: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  revenueContainer: css({
    color: THEME.font.colors.black.main,
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: THEME.font.sizes.normal,
    marginTop: 8,

    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexBasis: '50%',
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '50%',
      justifyContent: 'flex-end',
    },
  }),
  stepper: css({
    '& > div': {
      width: '50%',

      [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
        borderBottom: '1px solid #eef3f7',
        marginBottom: 16,
        paddingBottom: 24,
        width: 'auto',
      },
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
  }),
  titleContainer: css({
    padding: 24,
  }),
};

export default CampaignPostReports;
