import { Fragment, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { localizedDateFormatter } from '@src/libs/date';
import { useTranslation } from 'react-i18next';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { FanActivityType } from '@src/__generated__/globalTypes';
import { FanActivityCommentSchema } from '@src/libs/validation';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import CommentForm, { FormValues } from './CommentForm';

const ACTIVITY_TIME_FORMAT = 'MM-dd-yyyy h:mm aaa';

interface ActivityRowProps {
  activity?: string | null;
  created: string;
  activityType: FanActivityType;
  commenter: string;
  showCommentInsideForm: boolean;
  onClickMore?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onUpdate?: (comment: string) => void;
  popoverNode: ReactNode;
}

const ActivityRow = ({
  activity,
  created,
  activityType,
  commenter,
  showCommentInsideForm,
  popoverNode,
  onUpdate,
}: ActivityRowProps) => {
  const { i18n, t } = useTranslation();
  const methods = useForm<{ comment: string }>({
    defaultValues: { comment: activity || '' },
    resolver: yupResolver(FanActivityCommentSchema),
  });

  const onSubmit = (payload: FormValues) => {
    if (onUpdate) {
      onUpdate(payload.comment);
    }
  };

  const activityTime = localizedDateFormatter(
    new Date(created + 'Z'),
    ACTIVITY_TIME_FORMAT,
    i18n.language as AppLanguage
  );

  if (activityType === FanActivityType.COMMENT_CREATE) {
    if (showCommentInsideForm) {
      return (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CommentForm />
          </form>
        </FormProvider>
      );
    } else {
      return (
        <Fragment>
          <Row isComment={true}>
            <SecondaryText css={styles.commentTime}>{activityTime}</SecondaryText>
            <Icomoon css={styles.commentIcon} icon="clipboard-grey-light" size={22} />
            <DetailsWrapper>
              <PrimaryText css={{ marginBottom: 4 }}>{activity}</PrimaryText>
              <SecondaryText css={{ marginBottom: 0 }}>
                {t('Note by', { ...(!!commenter ? { name: commenter } : {}) })}
              </SecondaryText>
            </DetailsWrapper>
            <div css={{ display: 'flex', alignItems: 'center' }}>{popoverNode}</div>
          </Row>
        </Fragment>
      );
    }
  }

  return (
    <Row>
      <CircleWrapper>
        <div css={styles.circle} />
      </CircleWrapper>
      <DetailsWrapper>
        <SecondaryText>{activityTime}</SecondaryText>
        <PrimaryText>{activity}</PrimaryText>
      </DetailsWrapper>
    </Row>
  );
};

const CircleWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  marginLeft: 9,

  '&::before': {
    content: '""',
    width: 2,
    height: '50%',
    position: 'absolute',
    backgroundColor: '#dee5ec',
    left: 'calc(50% - 1px)',
    top: -2,
  },

  '&::after': {
    content: '""',
    width: 2,
    height: '50%',
    position: 'absolute',
    backgroundColor: '#dee5ec',
    left: 'calc(50% - 1px)',
    bottom: -2,
  },
});

const DetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 8,
});

const Row = styled.div<{ isComment?: boolean }>(({ isComment }) => ({
  borderRadius: 5,
  marginBottom: 24,
  position: 'relative',
  display: 'flex',
  alignItems: 'stretch',
  border: `1px solid ${isComment ? '#dee5ec' : 'transparent'}`,
  ...(isComment && { backgroundColor: THEME.colors.white }),

  '&:not(:last-of-type)': {
    '&::after': {
      content: '""',
      backgroundColor: '#dee5ec',
      width: 2,
      height: 24,
      position: 'absolute',
      left: 16,
      bottom: -25,

      '[dir="rtl"] &': {
        left: 'unset',
        right: 7,
      },
    },
  },

  '&:last-of-type': {
    [`${CircleWrapper}`]: {
      '&::after': {
        backgroundColor: 'transparent',
      },
    },
  },

  '&:first-of-type': {
    [`${CircleWrapper}`]: {
      '&::before': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const PrimaryText = styled.p({
  fontSize: THEME.font.sizes.normal,
  color: THEME.font.colors.black.main,
  lineHeight: '14px',
});

const SecondaryText = styled.p({
  fontSize: THEME.font.sizes.subordinate,
  color: THEME.font.colors.gray.main,
  lineHeight: '12px',
  marginBottom: 4,
});

const styles = {
  circle: css({
    width: 16,
    height: 16,
    backgroundColor: THEME.colors.white,
    border: '4px solid #dee5ec',
    borderRadius: 8,
    boxSizing: 'border-box',
    zIndex: 1,
  }),
  commentIcon: css({
    alignSelf: 'center',
    paddingLeft: 12,

    '[dir="rtl"] &': {
      paddingLeft: 0,
      paddingRight: 12,
    },
  }),
  commentTime: css({
    position: 'absolute',
    top: -18,
    left: 30,

    '[dir="rtl"] &': {
      left: 'unset',
      right: 30,
    },
  }),
};

export default ActivityRow;
