import * as React from 'react';
import { CampaignSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { switchImage } from '@src/libs/socialMedia';
import Icomoon from '../Icomoon';

interface SocialMediaColumnProps {
  className?: string;
  socialMedia: CampaignSocialMediaType | SocialAccountType;
  isTable?: boolean;
}

const SocialMediaColumn = (props: SocialMediaColumnProps) => {
  const { className, socialMedia, isTable = true } = props;
  const MediaWrapper = isTable ? 'td' : 'div';
  const icon = switchImage({ socialMedia });

  return (
    <MediaWrapper className={className} css={isTable ? { padding: '0 6px' } : { padding: '0 12px' }}>
      <Icomoon css={{ margin: '0 auto' }} icon={icon} size={17} />
    </MediaWrapper>
  );
};

export default SocialMediaColumn;
