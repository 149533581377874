import React from 'react';
import { css } from '@emotion/react';
import DefaultAvatar from '@src/assets/icon/chat/defaultAvatar.svg';
import { Icomoon, Image } from '@src/components/atoms';
import { ChatType } from './ChatsList/ChatInfoCard';

export interface ChatAvatarProps {
  type?: ChatType;
  avatar?: string | null;
  className?: string;
}

const ChatAvatar = ({ avatar, type, className }: ChatAvatarProps) => (
  <div css={styles.wrapper}>
    <Image
      src={avatar}
      css={styles.avatar}
      className={className}
      fallbackImg={DefaultAvatar}
      width="32px"
      height="32px"
      hideNoImageText
    />
    {type === ChatType.Line && <Icomoon css={styles.badge} icon="line-badge" />}
  </div>
);

const styles = {
  wrapper: css({
    position: 'relative',
    width: 32,
    height: 32,
  }),
  avatar: css({
    borderRadius: '50%',

    img: {
      backgroundColor: 'transparent',
    },
  }),
  badge: css({
    position: 'absolute',
    top: -2,
    right: -4,
  }),
};

export default ChatAvatar;
