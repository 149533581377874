import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Icomoon, Icon } from '@src/components/atoms';
import { CURRENCY_SYMBOLS } from '@src/libs/constant';
import { formatNumberWithCommas } from '@src/libs/format';
import { THEME } from '@src/libs/theme';

interface CardProps {
  currency?: string;
  isTransparentBackground?: boolean;
  revenue: number;
  title?: string;
  to?: string;
}

const Card = ({ currency, isTransparentBackground, revenue, title, to = '' }: CardProps) => {
  const { t } = useTranslation();

  return (
    <Container isTransparentBackground={isTransparentBackground} to={to}>
      <Icomoon icon="coins" size={18} />
      <div>{t(title || 'Revenue')}</div>
      <div>{`${currency ? CURRENCY_SYMBOLS[currency] : ''}${formatNumberWithCommas(
        revenue,
        undefined,
        currency
      )}`}</div>
      {to && (
        <Icon>
          <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={16} />
        </Icon>
      )}
    </Container>
  );
};

const Container = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  isTransparentBackground?: boolean;
  to: string;
}>(({ isTransparentBackground, to }) => ({
  alignItems: 'center',
  borderRadius: 47,
  display: 'flex',
  gap: THEME.box.gaps.s,
  height: 48,
  marginBottom: 16,
  padding: '0 16px',
  width: 'fill-available',
  ...(isTransparentBackground
    ? { background: 'transparent', border: THEME.box.borders.gray.main }
    : {
        background: THEME.colors.white,
        boxShadow: THEME.box.shadows.outer,
      }),
  ...(!to && { pointerEvents: 'none' }),

  '& > div:nth-of-type(1)': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    width: 'fill-available',
  },

  '& > div:nth-of-type(2)': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
  },
}));

export default Card;
