import styled from '@emotion/styled';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { TextForm } from '@src/components/shared';
import { ViewportType } from '@src/libs/types';
import { useQueryHelper } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { FormValues, SubmissionStatus } from './types';

interface ResetPasswordFormProps {
  status: string;
}

const ResetPasswordForm = ({ status }: ResetPasswordFormProps) => {
  const { search, t } = useQueryHelper();
  const queryString = new URLSearchParams(search);
  const user = queryString.get('user');
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();
  const isSuccess = status === SubmissionStatus.OK;

  return (
    <div css={isSuccess ? styles.formSuccessResetPassword : styles.formResetPassword}>
      <div css={styles.container}>
        <p css={styles.heading}>{t('Heading.Reset the password')}</p>
        {isSuccess ? (
          <>
            <Icomoon css={{ margin: '42px auto 0' }} icon="mail-open-blue" size={28} />

            <InfoText>{t('checkInbox')}</InfoText>

            <InfoText css={{ marginTop: 13 }}>{t('resetFollowInstruction')}</InfoText>
          </>
        ) : (
          <>
            <p css={styles.subHeading}>{t('SubHeading.Please reset the password from the email sent')}</p>
            <div css={styles.infoWrapper}>
              <TextForm
                css={styles.textForm}
                title="Email"
                placeholder="your@gmail.com"
                isRequired
                error={!!errors.email}
                {...register('email')}
              />
              <ErrorMessage message={errors.email?.message} />
            </div>
            <div css={styles.buttonWrapper}>
              <ThemeButton text="Back" to={user === 'staff' ? ROUTES.SIGN_IN_STAFF : ROUTES.ROOT} />
              <ThemeButton customPalette={mainBlack} text="Submit" loading={isSubmitting} type="submit" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const InfoText = styled.p({
  fontSize: THEME.font.sizes.normal,
  color: THEME.font.colors.black.main,
  lineHeight: 1.38,
  marginTop: 28,
  textAlign: 'center',
});

const styles = {
  buttonWrapper: css({
    display: 'flex',
    flex: 1,
    gap: THEME.box.gaps.l,
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  }),
  formResetPassword: css({
    maxWidth: 348,
    width: '100%',
  }),
  formSuccessResetPassword: css({
    width: '100%',
  }),
  heading: css({
    fontSize: THEME.font.sizes.subTitle,
    lineHeight: '24px',
    color: THEME.font.colors.black.main,
    marginBottom: 16,
    textAlign: 'center',
  }),
  infoWrapper: css({
    flex: 1,
    marginBottom: 24,
  }),
  subHeading: css({
    fontSize: THEME.font.sizes.normal,
    lineHeight: '18px',
    textAlign: 'center',
    color: THEME.font.colors.black.main,
    marginBottom: 32,
  }),
  textForm: css({
    '& label': {
      fontSize: THEME.font.sizes.normal,
    },

    '& input': {
      borderRadius: 3,
      height: 32,

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        height: 40,
      },
    },
  }),
};

export default ResetPasswordForm;
