import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdateInfluencerRateCardsMutation,
  UpdateInfluencerRateCardsMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdateInfluencerRateCards.graphql';

export const useUpdateInfluencerRateCardsMutation = (
  options?: MutationHookOptions<UpdateInfluencerRateCardsMutation, UpdateInfluencerRateCardsMutationVariables>
) => useMutation<UpdateInfluencerRateCardsMutation, UpdateInfluencerRateCardsMutationVariables>(MUTATION, options);
