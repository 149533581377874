import React from 'react';
import styled from '@emotion/styled';
import Avatar, { AvatarProps, DEFAULT_SIZE } from '@src/components/atoms/Avatar';

interface AvatarsProps {
  items: AvatarProps[];
  max?: number;
  size?: number;
}

const Avatars = ({ items, max = 5, size = DEFAULT_SIZE }: AvatarsProps) => {
  const isExceedMaxValue = items.length - max > 0;
  const marginLeft = (size / DEFAULT_SIZE) * 34;
  const minCount = isExceedMaxValue ? max + 1 : items.length;

  return (
    <Container width={minCount * (minCount > 1 ? size * 0.76 : size)}>
      {items.slice(0, max).map((avatarProps, index) => (
        <StyledAvatar key={index} left={index * marginLeft} {...avatarProps} size={size} />
      ))}
      {isExceedMaxValue && (
        <StyledAvatar left={max * marginLeft} numberOfCharacter={2} size={size} title={`+${items.length - max}`} />
      )}
    </Container>
  );
};

const Container = styled.div<{ width: number }>(({ width }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'flex-end',
  minWidth: `${width}px`,
  position: 'relative',
}));

const StyledAvatar = styled(Avatar)<{ left: number }>(({ left }) => ({
  border: '1px solid #fff',
  left: `${left}px`,
  position: 'absolute',
  top: 0,
}));

export default Avatars;
