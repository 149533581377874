import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { MarketplacePostCommentsQuery, MarketplacePostCommentsQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/MarketplacePostComments.graphql';

export const useMarketplacePostCommentsQuery = (
  options?: QueryHookOptions<MarketplacePostCommentsQuery, MarketplacePostCommentsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<MarketplacePostCommentsQuery, MarketplacePostCommentsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useMarketplacePostCommentsLazyQuery = (
  options?: LazyQueryHookOptions<MarketplacePostCommentsQuery, MarketplacePostCommentsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<MarketplacePostCommentsQuery, MarketplacePostCommentsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
