import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  YoutubeCmsConfirmedRevenueForInfluencerQuery,
  YoutubeCmsConfirmedRevenueForInfluencerQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/YoutubeCmsConfirmedRevenueForInfluencer.graphql';

export const useYoutubeCmsConfirmedRevenueForInfluencerQuery = (
  options?: QueryHookOptions<
    YoutubeCmsConfirmedRevenueForInfluencerQuery,
    YoutubeCmsConfirmedRevenueForInfluencerQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<YoutubeCmsConfirmedRevenueForInfluencerQuery, YoutubeCmsConfirmedRevenueForInfluencerQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};

export const useYoutubeCmsConfirmedRevenueForInfluencerLazyQuery = (
  options?: LazyQueryHookOptions<
    YoutubeCmsConfirmedRevenueForInfluencerQuery,
    YoutubeCmsConfirmedRevenueForInfluencerQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<
    YoutubeCmsConfirmedRevenueForInfluencerQuery,
    YoutubeCmsConfirmedRevenueForInfluencerQueryVariables
  >(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
