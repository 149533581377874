import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  ResendInfluencerSignUpEmailMutation,
  ResendInfluencerSignUpEmailMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/ResendInfluencerSignUpEmail.graphql';

export const useResendInfluencerSignUpEmailMutation = (
  options?: MutationHookOptions<ResendInfluencerSignUpEmailMutation, ResendInfluencerSignUpEmailMutationVariables>
) => useMutation<ResendInfluencerSignUpEmailMutation, ResendInfluencerSignUpEmailMutationVariables>(MUTATION, options);
