import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import anyCreatorLogo from '@src/assets/img/anyCreatorBlack.png';
import { Icomoon, Icon } from '@src/components/atoms';
import { NotificationBadge } from '@src/components/molecules';
import { useQueryHelper, useUuumInfluencerView } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { dynamicTitleState, selectedChatState, toolbarBackState, useRecoil } from '@src/recoilAtoms';
import { TextCutter } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { HamburgerIcon } from '@src/assets/reactIcons/hamburger';

interface MobileToolbarProps {
  backPath?: string;
  toolbarTitle?: string;
  onClickMenu?: () => void;
}

const MobileToolbar = ({ backPath, toolbarTitle, onClickMenu }: MobileToolbarProps) => {
  const { isUuumView } = useUuumInfluencerView();
  const { matchPath, navigate, params, pathname, t, search } = useQueryHelper();
  const redirectPath = backPath ? generatePath(backPath, params as any) + search : '';
  const isActiveChatPath = matchPath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID, pathname);
  const isDynamicTitle = toolbarTitle === '_dynamic'; // if need to pass another _dynamic title - we can make recoil atom for this
  const {
    recoilState: { pic, fanId },
    setRecoilState: setSelectedChartState,
  } = useRecoil(selectedChatState);
  const {
    recoilState: { title, interpolation },
  } = useRecoil(dynamicTitleState);
  const {
    recoilState: { backMethod },
  } = useRecoil(toolbarBackState);

  return (
    <>
      <div css={styles.toolbar}>
        {backPath || backMethod ? (
          <IconContainer onClick={() => (backMethod ? backMethod() : navigate(redirectPath))}>
            <Icon>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={16} />
            </Icon>
          </IconContainer>
        ) : (
          <IconContainer onClick={onClickMenu}>{<HamburgerIcon />}</IconContainer>
        )}
        <div css={styles.logoContainer}>
          {toolbarTitle ? (
            <TextCutter
              css={styles.title}
              text={!isDynamicTitle ? t(`MenuTitle.${toolbarTitle}`) : t(title, { ...interpolation })}
              lines={1}
            />
          ) : !isUuumView ? (
            <Link to={ROUTES.ROOT}>
              <img alt="logo" height="32" src={anyCreatorLogo} />
            </Link>
          ) : null}
        </div>

        {[ROUTES.FIND_JOBS, ROUTES.MY_JOBS].includes(pathname) && <NotificationBadge />}
        {isActiveChatPath ? (
          <div css={styles.chatMenu}>
            <Icomoon
              color={!pic ? '#888888' : '#3992e5'}
              css={styles.chatMenuItem}
              icon="customer-service"
              size={24}
              onClick={() =>
                setSelectedChartState(prevState => ({
                  ...prevState,
                  isChartStateMenuOpen: !prevState.isChartStateMenuOpen,
                }))
              }
            />
            <Link to={fanId ? `${pathname}/${fanId}` : '#'} css={{ display: 'flex', alignItems: 'center' }}>
              <HamburgerIcon color="#6E7C89" />
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
};

const IconContainer = styled.div({
  display: 'flex',
  flexBasis: '10%',
  justifyContent: 'center',

  '& i': {
    fontSize: 25,
  },

  [`@media (max-width: ${ViewportType.SMALL}px)`]: {
    flexBasis: '15%',
  },
});

const styles = {
  logoContainer: css({
    display: 'flex',
    flexBasis: '80%',
    justifyContent: 'center',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      flexBasis: '70%',
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
  }),
  chatMenu: css({
    display: 'flex',
    columnGap: 8,
    padding: '0 16px',
  }),
  chatMenuItem: css({
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  }),
  greyscaleIcon: css({
    filter: 'grayscale(1)',
  }),
  menu: css({
    width: 'calc(100vw - 32px)',
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    top: '58px !important',
    overflow: 'inherit',

    '& > ul': {
      padding: 0,
    },
  }),
  notificationIndicator: css({
    backgroundColor: THEME.colors.error,
    borderRadius: '50%',
    height: 10,
    position: 'absolute',
    right: -4,
    top: -4,
    width: 10,
  }),
  toolbar: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    display: 'flex',
    height: 56,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 5,
  }),
};

export default MobileToolbar;
