import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerProfileAudienceQuery,
  InfluencerProfileAudienceQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerProfileAudience.graphql';

export const useInfluencerProfileAudienceQuery = (
  options?: QueryHookOptions<InfluencerProfileAudienceQuery, InfluencerProfileAudienceQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerProfileAudienceQuery, InfluencerProfileAudienceQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerProfileAudienceLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerProfileAudienceQuery, InfluencerProfileAudienceQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerProfileAudienceQuery, InfluencerProfileAudienceQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
