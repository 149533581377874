import React, { useEffect, useState } from 'react';
import { useLiff } from 'react-liff';
import { Navigate } from 'react-router-dom';
import InstagramLoginForm from '@src/components/organisms/LineIdTokenVerification/InstagramLoginForm';
import { useVerifyLineIDTokenMutation } from '@src/graphql/hooks';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';

interface LineIdTokenVerificationProps {
  isIgSignIn: boolean;
}

const LineIdTokenVerification = ({ isIgSignIn }: LineIdTokenVerificationProps) => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [lineIdToken, setLineIdToken] = useState<string | null>(null);
  const { error, isLoggedIn, liff, ready } = useLiff();
  const { enqueueSnackbar, t } = useQueryHelper();

  const [verifyLineIdToken] = useVerifyLineIDTokenMutation({
    onCompleted: () => {
      if (!isIgSignIn) {
        liff.closeWindow();
      }
    },
  });

  useEffect(() => {
    if (isLoggedIn && ready) {
      getLIffIdToken();
    } else if (!isLoggedIn && ready && !isVerified) {
      liff.login();
    }
  }, [liff]);

  const getLIffIdToken = async () => {
    const idToken = await liff.getIDToken();

    if (!isIgSignIn && idToken) {
      await verifyLineIdToken({
        variables: {
          input: { idToken },
        },
      });

      setIsVerified(true);
      liff.logout();
      window.location.replace('https://line.me/R/nv/chat');
    } else {
      setIsVerified(true);
      liff.logout();
      setLineIdToken(idToken);
    }
  };

  if (error) {
    enqueueSnackbar(t((error as { message: string }).message), { variant: 'error' });

    return <Navigate to={ROUTES.ROOT} />;
  }

  return isIgSignIn && lineIdToken ? <InstagramLoginForm lineIdToken={lineIdToken} /> : null;
};

export default LineIdTokenVerification;
