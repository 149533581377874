import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { AllCampaignsForYourJobQuery, AllCampaignsForYourJobQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/AllCampaignsForYourJob.graphql';

export const useAllCampaignsForYourJobQuery = (
  options?: QueryHookOptions<AllCampaignsForYourJobQuery, AllCampaignsForYourJobQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<AllCampaignsForYourJobQuery, AllCampaignsForYourJobQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useAllCampaignsForYourJobLazyQuery = (
  options?: LazyQueryHookOptions<AllCampaignsForYourJobQuery, AllCampaignsForYourJobQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<AllCampaignsForYourJobQuery, AllCampaignsForYourJobQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
