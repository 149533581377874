import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import { PaymentRequestStatusForInfluencer } from '@src/__generated__/globalTypes';

interface PaymentStatusProps {
  className?: string;
  status: PaymentRequestStatusForInfluencer | null;
}

const PaymentStatus = ({ className, status }: PaymentStatusProps) => {
  const { t } = useTranslation();
  const { color, message } = (() => {
    switch (status) {
      case PaymentRequestStatusForInfluencer.FAILED:
        return { color: '#ffd7d7', message: 'Failed' };
      case PaymentRequestStatusForInfluencer.REJECTED:
        return { color: '#ffd7d7', message: 'Rejected' };
      case PaymentRequestStatusForInfluencer.REVIEWING:
        return { color: '#e5daf2', message: 'Reviewing' };
      case PaymentRequestStatusForInfluencer.TRANSFERRED:
        return { color: '#cfedde', message: 'Paid' };
      default:
        return { color: '#ffd7d7', message: '' };
    }
  })();

  return (
    <Container className={className} color={color}>
      {t(`Payment.${message}`)}
    </Container>
  );
};

const Container = styled.div<{ color: string }>(({ color }) => ({
  background: color,
  borderRadius: 22,
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  padding: '9px 16px',
  width: 'fit-content',
}));

export default PaymentStatus;
