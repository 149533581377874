import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/molecules';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

export const ActionContainer = styled.div({
  background: THEME.colors.white,
  bottom: 0,
  boxShadow: THEME.box.shadows.outer,
  display: 'flex',
  gap: THEME.box.gaps.s,
  left: 0,
  padding: '16px 12px',
  position: 'fixed',
  width: 'fill-available',
  zIndex: 2,
});

export const BackButton = styled(Link)({
  alignItems: 'center',
  background: THEME.colors.white,
  borderRadius: '50%',
  boxShadow: THEME.box.shadows.outer,
  display: 'flex',
  left: 16,
  height: 40,
  justifyContent: 'center',
  position: 'absolute',
  top: 16,
  width: 40,
  zIndex: 1,

  "[dir='rtl'] &": {
    left: 'unset',
    right: 16,
  },
});

export const Container = styled.div<{ hasMobileActions?: boolean; transparent?: boolean }>(
  ({ hasMobileActions, transparent }) => ({
    background: transparent ? 'transparent' : '#fff',
    margin: 0,
    position: 'relative',
    ...(hasMobileActions && { paddingBottom: 96 }),

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 23,
      margin: 24,
      ...(!transparent && { boxShadow: THEME.box.shadows.outer }),
    },
  })
);

export const Header = styled.div({
  alignItems: 'center',
  background: THEME.colors.white,
  borderBottom: '1px solid #f5f6f7',
  borderRadius: '23px 23px 0 0',
  display: 'flex',
  gap: THEME.box.gaps.xxl,
  height: 72,
  padding: '0 32px',

  '& > div:last-child': {
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.l,
    justifyContent: 'flex-end',
    width: 'fill-available',
  },
});

export const StyledButton = styled(ThemeButton)({
  borderRadius: 9,
  height: 40,
  width: 226,

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    height: 56,
    width: 'fill-available',

    '& span': {
      fontSize: THEME.font.sizes.subHeading,
    },
  },
});
