import { useQueryHelper } from '@src/libs/hooks';
import React from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import { Empty, Icomoon } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { useGetFormQuery, useGetFormResponseByPageQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import FormIndividualNavigation from './FormIndividualNavigation';
import useFormResponse from './useFormResponse';
import FormPageResponse from './FormPageResponse';

const SUBMITTED_TIME_FORMATTER = 'M/d/y, h:mm aaa';

interface FormIndividualProps {
  totalResponse: number;
}

const FormIndividual = ({ totalResponse }: FormIndividualProps) => {
  const { t, params } = useQueryHelper();
  const { id, page = '1' } = params as { id: string; page: string };
  const { selectedSubmission, closeDialog, onClickDelete, handleDelete } = useFormResponse();

  const { data, loading } = useGetFormQuery({
    variables: {
      input: {
        id,
      },
    },
  });
  const { data: dataResponseByPage, loading: loadingResponseByPage } = useGetFormResponseByPageQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        formId: id,
        page: Number(page),
      },
    },
  });
  const getForm = data?.getForm;
  const getFormResponseByPage = dataResponseByPage?.getFormResponseByPage;
  const isLoading = loading || loadingResponseByPage;

  if (isLoading) {
    return <ListLoading />;
  }

  if (!getForm || !getFormResponseByPage) {
    return <Empty />;
  }

  return (
    <>
      <div css={{ position: 'relative' }}>
        <div css={styles.topPanel}>
          <FormIndividualNavigation total={totalResponse} />
          <div css={styles.iconWrapper} onClick={() => onClickDelete(getFormResponseByPage?.submissionId)}>
            <Icomoon icon="trash-outlined" />
          </div>
        </div>
        <div css={styles.row}>
          <p css={styles.annotation}>{t('Responses cannot be edited')}</p>
          <p css={styles.formTitle}>{getForm?.title}</p>
        </div>
        {getFormResponseByPage?.answers.length ? (
          <FormPageResponse answers={getFormResponseByPage.answers} formData={getForm} />
        ) : (
          <Empty />
        )}
        <p css={styles.created}>
          {`${t('Submitted')} ${format(
            new Date((getFormResponseByPage?.created as string) + 'Z'),
            SUBMITTED_TIME_FORMATTER
          )}`}
        </p>
      </div>
      <Dialog
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: closeDialog,
        }}
        contentStyles={{
          borderRadius: 3,
          maxWidth: 648,
        }}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Delete',
          theme: 'blue',
          onClick: handleDelete,
        }}
        open={!!selectedSubmission}
        onClose={closeDialog}
      >
        <p css={styles.heading}>{t('Are you sure you want to permanently delete this?')}</p>
        <p css={styles.bodyText}>
          {t('You might lost response data forever Before continuing, export your responses')}
        </p>
        <p css={styles.warning}>{t('Do you want to delete this question and lost responses?')}</p>
      </Dialog>
    </>
  );
};

const styles = {
  iconWrapper: css({
    border: THEME.box.borders.gray.main,
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer',
    },
  }),
  topPanel: css({
    padding: '0 16px 24px',
    borderBottom: '1px solid #dee5ec',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  row: css({
    padding: '24px 16px',

    '&:not(:last-of-type)': {
      borderBottom: '1px solid #dee5ec',
    },
  }),
  annotation: css({
    textAlign: 'center',
    fontWeight: 600,
    fontSize: THEME.font.sizes.hint,
    lineHeight: '100%',
    color: '#c5d0da',
    marginBottom: 16,
  }),
  formTitle: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.subHeading,
    color: THEME.font.colors.black.main,
  }),
  created: css({
    textAlign: 'right',
    position: 'absolute',
    fontSize: THEME.font.sizes.subordinate,
    color: THEME.font.colors.gray.main,
    bottom: -56,
    width: '100%',
    right: -24,
  }),
  heading: css({
    marginBottom: 24,
    fontWeight: 600,
    fontSize: THEME.font.sizes.heading,
    color: THEME.font.colors.black.main,
  }),
  bodyText: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
    marginBottom: 16,
  }),
  warning: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.error,
  }),
};

export default FormIndividual;
