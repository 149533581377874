import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { checkmarkCode } from '@src/assets/htmlCodes';
import { THEME } from '@src/libs/theme';

interface FormStepperProps<T> {
  steps: { value: T }[];
  currentStep: T;
  styleConfig?: {
    activeBgColor?: string;
    completedStepBgColor?: string;
    nextStepBgColor?: string;
    currentTextColor?: string;
    nextStepTextColor?: string;
  };
  className?: string;
}
const FormStepper = <T extends unknown>({ steps, currentStep, styleConfig, className }: FormStepperProps<T>) => {
  const {
    activeBgColor = '#6E7C89',
    completedStepBgColor = '#3892E5',
    nextStepBgColor = '#fff',
    currentTextColor = '#fff',
    nextStepTextColor = '#dee5ec',
  } = styleConfig ?? {};
  const currentStepIndex = steps.findIndex(el => el.value === currentStep);

  return (
    <div className={className} css={styles.stepperContainer}>
      {steps.map((step, i) => {
        const isPrevStep = i < currentStepIndex;
        const isNextStep = i > currentStepIndex;
        const bgColor =
          step.value === currentStep ? activeBgColor : isPrevStep ? completedStepBgColor : nextStepBgColor;
        const textColor = isNextStep ? nextStepTextColor : currentTextColor;
        const borderWidth = isNextStep ? '2px' : '0';
        const labelIndex = isPrevStep ? checkmarkCode : i + 1;
        const delimeterColor = i < currentStepIndex ? completedStepBgColor : nextStepTextColor;

        return (
          <Fragment key={`${step.value}_${i}`}>
            {i > 0 ? <HorizontalLine key={`${i}_delimeter`} delimeterColor={delimeterColor} /> : null}
            <Step key={`${step.value}_${i}`} borderWidth={borderWidth} bgColor={bgColor} textColor={textColor}>
              <span className="step_index" css={styles.stepIndex}>
                {labelIndex}
              </span>
            </Step>
          </Fragment>
        );
      })}
    </div>
  );
};

const Step = styled.div<{ borderWidth: string; bgColor: string; textColor: string }>(
  ({ borderWidth, bgColor, textColor }) => ({
    display: 'flex',
    alignItems: 'center',
    placeContent: 'center',
    width: 38,
    height: 38,
    borderRadius: '50%',
    border: `${borderWidth} solid #dee5ec`,
    boxSizing: 'border-box',
    background: bgColor,
    color: textColor,
  })
);

const HorizontalLine = styled.hr<{ delimeterColor: string }>(({ delimeterColor }) => ({
  flex: 'auto',
  background: delimeterColor,
  height: 2,
}));

const styles = {
  stepperContainer: css({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  stepIndex: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    lineHeight: '140%',
  }),
};

export default FormStepper;
