import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useAuthData, useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import yup, {
  BirthdaySchema,
  // BrandSchema,
  CategoriesSchema,
  CountrySchema,
  GenderSchema,
  NameSchema,
  RegionSchema,
} from '@src/libs/validation';
import { Genders } from '@src/__generated__/globalTypes';
import { useGetAccountInformationQuery, useUpdateAccountInformationMutation } from '@src/graphql/hooks';
import CreatorAccount, { AccountInformation } from './CreatorAccount';

const Index = () => {
  const [isRegionRequired, setIsRegionRequired] = useState<boolean>(false);
  const { isAvailableCountry } = useAuthData();
  const { enqueueSnackbar, t } = useQueryHelper();
  const validationSchema = yup.object().shape({
    // brandName: BrandSchema,
    categoryIds: CategoriesSchema,
    countryId: CountrySchema,
    dateOfBirth: BirthdaySchema,
    gender: GenderSchema,
    name: NameSchema,
    ...(isRegionRequired && { regionId: RegionSchema }),
  });

  const { data, loading } = useGetAccountInformationQuery({
    fetchPolicy: 'no-cache',
  });
  const [updateAccountInformation] = useUpdateAccountInformationMutation();

  const onSubmit = async (values: AccountInformation) => {
    updateAccountInformation({
      variables: {
        // brandName exclude for the first release but is required for API
        input: {
          ...values,
          brandName: '',
          categoryIds: values.categoryIds.map(val => Number(val)),
          regionId: values.regionId ? Number(values.regionId) : null,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.editSettingsAccount);
      })
      .catch(err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      });
  };

  const accountInfo = data?.getAccountInformation;
  const defaultValues = {
    brandName: accountInfo?.brandName || '',
    categoryIds: accountInfo?.categoryIds || [],
    countryId: accountInfo?.countryId || '',
    dateOfBirth: accountInfo?.dateOfBirth || '',
    gender: accountInfo?.gender || Genders.MALE,
    introduce: accountInfo?.introduce || '',
    name: accountInfo?.name || '',
    regionId: accountInfo?.regionId?.toString() || '',
  };
  const methods = useForm<AccountInformation>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setIsRegionRequired(accountInfo?.countryId !== 'US' && isAvailableCountry);
  }, [accountInfo?.countryId, isAvailableCountry]);

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CreatorAccount isLoading={loading} />
      </form>
    </FormProvider>
  );
};

export default Index;
