import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { BackNavigator, ThemeButton } from '@src/components/molecules';
import { ViewportType } from '@src/libs/types';
import { formState, submitFormState, useRecoil } from '@src/recoilAtoms';
import { useFilter, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { FormStatus } from '@src/__generated__/globalTypes';
import FormList from './FormList';
import FormListFilter, { FilterItems } from './FormListFilter';

const Listings = () => {
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const { setRecoilState: setFormState } = useRecoil(formState);
  const { setRecoilState: setSubmitFormState } = useRecoil(submitFormState);
  const [searchParams] = useSearchParams();
  const { filter, setFilter } = useFilter({
    keyword: searchParams.get('keyword') || '',
    status: (searchParams.get('status') || '') as FormStatus,
  });
  const { isMobileView } = usePageLayout();

  useEffect(() => {
    // to reset form draft edit/preview information
    setFormState(null);
    setSubmitFormState(null);
  }, []);

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Form" />
          </div>
          <div>
            <ThemeButton theme="blue" css={styles.addBtn} text="Add Form" to={ROUTES.MY_PAGE_FORMS_ADD} />
          </div>
        </div>
      )}

      <div css={{ marginTop: 16 }}>
        <FormListFilter values={filter as FilterItems} onChange={setFilter} />
        <FormList filter={filter as FilterItems} />
      </div>
    </div>
  );
};

const styles = {
  addBtn: css({
    fontSize: THEME.font.sizes.subordinate,
    width: 'fit-content',

    '.btn-text': {
      padding: '0 16px',
    },
  }),
  container: css({
    padding: 24,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: 16,
    },
  }),
  navigatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexBasis: '50%',
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '50%',
      justifyContent: 'flex-end',
    },
  }),
};

export default Listings;
