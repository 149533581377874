import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { connectSocialAuthState, useRecoil, ConnectSocialAuthState } from '@src/recoilAtoms';
import { AuthSocialAccountType, BioSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import useQueryHelper from './useQueryHelper';
import { useRedirectUrl } from './useRedirectUrl';
import useRedirectWithinIframe from './useRedirectWithinIframe';

const useConnectSocialAuth = (redirectType: FE_REDIRECT_MAPPING) => {
  const { setRecoilState } = useRecoil(connectSocialAuthState);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { getRedirectUrl } = useRedirectUrl(redirectType);
  const { startRedirectProcess } = useRedirectWithinIframe();

  const connectSocialAuth = async (
    socialMedia: AuthSocialAccountType | BioSocialMediaType | SocialAccountType,
    persistStorage?: Omit<ConnectSocialAuthState, 'redirectType' | 'socialMedia'>
  ) => {
    setRecoilState({
      redirectType,
      socialMedia: socialMedia as SocialAccountType,
      ...persistStorage,
    });

    try {
      const redirectUrl = await getRedirectUrl(socialMedia);

      if (redirectUrl) {
        startRedirectProcess({ redirectUrl, redirectType });
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  return {
    connectSocialAuth,
  };
};

export default useConnectSocialAuth;
