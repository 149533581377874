import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { InitialLoading } from '@src/components/atoms';
import { useShopifyValidateAuthMutation } from '@src/graphql/hooks';
import { getConnectSocialAuthState } from '@src/libs/auth';
import { getMessagesFromApolloError, getMessagesFromFetchResult, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';

const ShopifyConnect = () => {
  const { t, enqueueSnackbar, navigate, search } = useQueryHelper();
  const response = search.substr(1);
  const {
    connectState: { shopifyStoreName },
    resetConnectState,
  } = getConnectSocialAuthState();

  const [shopifyValidateAuth] = useShopifyValidateAuthMutation();

  if (!response) {
    enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
    navigate(ROUTES.MY_PAGE_CHANNEL_CONNECT);

    return null;
  }

  const validateAuth = async () => {
    const variables = {
      input: {
        response,
        shopName: shopifyStoreName || '',
      },
    };
    const { ok, errors } = await shopifyValidateAuth({ variables })
      .then(result => {
        if (result?.data?.shopifyValidateAuth?.ok) {
          return { ok: true, errors: [] };
        } else {
          return { ok: false, errors: getMessagesFromFetchResult(result) };
        }
      })
      .catch((e: ApolloError) => ({ ok: false, errors: getMessagesFromApolloError(e) }));

    if (ok) {
      enqueueSnackbar(t('succeededInConnecting'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.connectShopify);
    } else {
      errors.forEach(error => {
        console.error(error);
        enqueueSnackbar(t(error), { variant: 'error' });
      });
    }

    navigate(ROUTES.MY_PAGE_CHANNEL_CONNECT);
  };

  useEffect(() => {
    validateAuth();

    return () => {
      resetConnectState();
    };
  }, []);

  return <InitialLoading />;
};

export default ShopifyConnect;
