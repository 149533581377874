import { css } from '@emotion/react';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface MenuLinkProps {
  className?: string;
  href: LocationDescriptor;
  title: string;
}

const MenuLink = (props: MenuLinkProps) => {
  const { className, href, title } = props;

  return (
    <Link css={styles.link} className={className} to={href} target="_blank">
      {title}
    </Link>
  );
};

const styles = {
  link: css({
    padding: '0 18px 0 12px',
    borderRight: '1px solid #dee5ec',
    color: THEME.font.colors.gray.main,
    fontSize: 11,
    letterSpacing: 'normal',

    '&:hover': {
      textDecoration: 'underline',
    },

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      padding: '0 12px 0',
    },
  }),
};

export default MenuLink;
