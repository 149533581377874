import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ProgressBar } from '@src/components/atoms';
import { formatNumber } from '@src/libs/format';
import { THEME } from '@src/libs/theme';

interface CampaignBudgetProgressBarProps {
  color?: string;
  remainingAmount: number | null;
  totalAmount: number | null;
}

const CampaignBudgetProgressBar = ({
  color = '#3892e5',
  remainingAmount,
  totalAmount,
}: CampaignBudgetProgressBarProps) => {
  const { t } = useTranslation();
  const amountWidth = remainingAmount && totalAmount ? (100 * (totalAmount - remainingAmount)) / totalAmount : 100;

  return (
    <div>
      <div css={styles.headerContainer}>
        <div>{t('Label.Budget')}</div>
        <div>
          {amountWidth < 100 && <span css={{ color }}>{t('Recruiting')}</span>}
          <span>{`${formatNumber(amountWidth, 0)}%`}</span>
        </div>
      </div>
      <ProgressBar completed={amountWidth} completedColor={remainingAmount && totalAmount ? color : '#ff5f5f'} />
    </div>
  );
};

const styles = {
  headerContainer: css({
    display: 'flex',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.gray.main,
      display: 'flex',
      flexBasis: '50%',
      fontSize: 11,
      fontWeight: 600,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      display: 'flex',
      flexBasis: '50%',
      fontSize: 11,
      fontWeight: 600,
      gap: THEME.box.gaps.s,
      justifyContent: 'flex-end',
      marginBottom: 8,
    },
  }),
};

export default CampaignBudgetProgressBar;
