import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { CheckBox } from '@src/components/atoms';
import { Dialog } from '@src/components/molecules';
import AccessPermission from '@src/components/organisms/SignUpFacebook/AccessPermission';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import * as SC from './StyledComponents';

interface DialogFBGrantPermissionProps {
  open: boolean;
  onClose: () => void;
}

const DialogFBGrantPermission = ({ open, onClose }: DialogFBGrantPermissionProps) => {
  const [confirmedNotification, setConfirmedNotification] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <Dialog
      closeButtonProps={{
        styles: css({ display: 'none' }),
      }}
      closeOnDocumentClick={false}
      contentStyles={{
        backgroundColor: THEME.colors.white,
        borderRadius: 9,
        boxShadow: THEME.box.shadows.outer,
        height: isMobileView ? '100%' : 548,
        maxHeight: '90%',
        maxWidth: 648,
        width: '90%',
      }}
      footerNode={
        <div css={styles.dialogActionContainer}>
          <SC.StyledButton
            css={{ width: 'fill-available' }}
            disabled={!confirmedNotification}
            text="Close"
            onClick={onClose}
          />
          <div>{`*${t('Annotation.Enable button with checking a checkbox below')}`}</div>
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <div css={{ padding: '26px 0' }}>
        <div css={styles.dialogTitle}>{t('Grant permissions')}</div>
        <div css={styles.dialogDescription}>
          {t(
            'Annotation.In order to fetch your post to the campaign, AnyCreator needs access to your public information'
          )}
        </div>
        <div css={styles.infoContainer}>
          <div>{t('Annotation.Please make sure that you will turn on all toggles on the access permission page')}</div>
          <div>{t('Annotation.Facebook’s access permission page')}</div>
          <AccessPermission />
        </div>
        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <CheckBox
            checked={confirmedNotification}
            label="I have confirmed this notification"
            onChange={setConfirmedNotification}
          />
        </div>
      </div>
    </Dialog>
  );
};

const styles = {
  dialogActionContainer: css({
    borderTop: '1px solid #dee5ec',
    display: 'grid',
    gap: THEME.box.gaps.xs,
    padding: 16,

    '& > div': {
      color: THEME.font.colors.gray.main,
      fontSize: 11,
    },
  }),
  dialogDescription: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
  }),
  dialogTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 16,
  }),
  infoContainer: css({
    border: THEME.box.borders.gray.main,
    borderRadius: 5,
    display: 'grid',
    justifyContent: 'center',
    marginBottom: 32,
    padding: '24px 16px',

    '& > div:nth-of-type(1)': {
      borderBottom: '1px solid #eef3f7',
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      justifySelf: 'center',
      marginBottom: 24,
      paddingBottom: 16,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      justifySelf: 'center',
      marginBottom: 16,
    },

    '& > div:nth-of-type(3)': {
      justifySelf: 'center',
    },
  }),
};

export default DialogFBGrantPermission;
