import React from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage } from '@src/components/atoms';
import { ViewportType } from '@src/libs/types';
import { BackNavigator, ThemeButton } from '@src/components/molecules';
import { TextForm } from '@src/components/shared';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { UserDetailPayload } from '@src/__generated__/globalTypes';

const UserDetail = () => {
  const { isMobileView } = usePageLayout();
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<UserDetailPayload>();

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="User Detail" to={ROUTES.SETTINGS_USERS} />
        </div>
      )}

      <div css={styles.formContainer}>
        <div css={{ maxWidth: 648, width: '100%' }}>
          <div css={styles.inputContainer}>
            <div>
              <StyledTextForm
                error={!!errors.name}
                isRequired
                placeholder="Display Name"
                title="Display Name"
                {...register('name')}
              />
              <ErrorMessage message={errors.name?.message} />
            </div>

            <div>
              <StyledTextForm
                error={!!errors.email}
                isRequired
                placeholder="Email Address"
                title="Email Address"
                {...register('email')}
              />
              <ErrorMessage message={errors.email?.message} />
            </div>
          </div>

          <div css={styles.actionContainer}>
            <ThemeButton css={styles.submitBtn} disabled={isSubmitting} text="Save" theme="blue" type="submit" />
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledTextForm = styled(TextForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& input': {
    borderRadius: 0,
    height: 32,
  },
});

const styles = {
  actionContainer: css({
    backgroundColor: THEME.colors.white,
    borderTop: '1px solid #eef3f7',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 16,
  }),
  container: css({
    margin: '16px 24px',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      margin: 16,
    },
  }),
  inputContainer: css({
    backgroundColor: THEME.colors.white,
    display: 'grid',
    gap: THEME.box.gaps.l,
    padding: 24,
  }),
  formContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  }),
  navigatorContainer: css({
    marginBottom: 24,
  }),
  submitBtn: css({
    width: 'max-content',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      borderRadius: 5,
      height: 40,
      width: '100%',
    },
  }),
};

export default UserDetail;
