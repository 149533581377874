import {
  AuthSocialAccountType,
  BioSocialMediaType,
  CampaignPromotionMethod,
  CampaignSocialMediaType,
  SocialAccountType,
  SocialPostType,
} from '@src/__generated__/globalTypes';

export const switchSocialConnectColor = (socialMedia: SocialAccountType): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return '#4267b2';
    case SocialAccountType.INSTAGRAM:
      return 'transparent';
    case SocialAccountType.TWITTER:
      return '#38a1f3';
    case SocialAccountType.TIKTOK:
      return '#000';
    default:
      return '#c4302b';
  }
};

export const switchSocialButtonColor = (
  socialMedia?: SocialAccountType | AuthenticationType,
  light?: boolean
): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return light ? '#1877f2' : '#4460a0';
    case SocialAccountType.INSTAGRAM:
      return 'transparent';
    case AuthenticationType.EMAIL:
      return '#000';
    case SocialAccountType.YOUTUBE:
      return light ? '#ff0302' : '#ce1312';

    default:
      return '#000';
  }
};

interface SwitchImageProps {
  dynamicColor?: boolean;
  initial?: boolean;
  simplify?: boolean;
  socialMedia:
    | BioSocialMediaType
    | CampaignPromotionMethod
    | CampaignSocialMediaType
    | SocialAccountType
    | SocialPostType;
  tiktokWhite?: boolean;
}

export const switchImage = ({
  dynamicColor,
  initial,
  simplify,
  socialMedia,
  tiktokWhite,
}: SwitchImageProps): string => {
  switch (socialMedia) {
    case SocialAccountType.LINE:
      return 'line-color';
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return simplify
        ? 'facebook-word'
        : initial
        ? dynamicColor
          ? 'facebook-square'
          : 'facebook-square-color'
        : dynamicColor
        ? 'facebook-round'
        : 'facebook-round-color';
    case SocialAccountType.INSTAGRAM:
    case SocialPostType.INSTAGRAM_STORY:
      return initial ? 'instagram-color' : dynamicColor ? 'instagram' : 'instagram-color-light';
    case SocialAccountType.TIKTOK:
      return tiktokWhite ? 'tiktok-white' : dynamicColor ? 'tiktok' : 'tiktok-color';
    case SocialAccountType.TWITTER:
      return dynamicColor ? 'x-twitter' : 'x-twitter-color';
    case SocialAccountType.YOUTUBE:
      return dynamicColor ? 'youtube' : 'youtube-color';
    default:
      return '';
  }
};

export enum AuthenticationType {
  EMAIL = 'EMAIL',
}

export const switchSocialMediaName = (socialMedia: SocialAccountType | AuthSocialAccountType): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return 'facebook';
    case SocialAccountType.INSTAGRAM:
      return 'instagram';
    case SocialAccountType.TWITTER:
      return 'twitter';
    case SocialAccountType.YOUTUBE:
      return 'youtube';
    case SocialAccountType.TIKTOK:
    case AuthSocialAccountType.TTCM:
      return 'tiktok';
    default:
      return '';
  }
};

export const switchText = (
  socialMedia:
    | SocialAccountType
    | CampaignPromotionMethod
    | CampaignSocialMediaType
    | SocialPostType
    | BioSocialMediaType
    | null
): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return 'Facebook';
    case SocialAccountType.INSTAGRAM:
      return 'Instagram';
    case CampaignSocialMediaType.INSTAGRAM_STORY:
      return 'Instagram Story';
    case SocialAccountType.TWITTER:
      return 'X(Twitter)';
    case SocialAccountType.YOUTUBE:
      return 'YouTube';
    case SocialAccountType.TIKTOK:
      return 'TikTok';
    case SocialAccountType.LINE:
      return 'LINE';
    default:
      return '';
  }
};

export const switchSocialBackgroundColor = (
  socialMedia: CampaignPromotionMethod | SocialAccountType | string
): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return '#1877f2';
    case SocialAccountType.INSTAGRAM:
    case CampaignPromotionMethod.INSTAGRAM_STORY:
      return 'linear-gradient(94deg, #d82e7c 0%, #ff8404 99%);';
    case SocialAccountType.YOUTUBE:
      return '#ce1312';
    case SocialAccountType.TIKTOK:
    case SocialAccountType.TWITTER:
      return '#000';
    default:
      return '#4460a0'; // For Facebook pages
  }
};

export const switchFollowersGrowthLineColor = (socialMedia: SocialAccountType | string): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return '#4e6baa';
    case SocialAccountType.INSTAGRAM:
      return '#ff2b52';
    case SocialAccountType.TWITTER:
      return '#00aaec';
    case SocialAccountType.YOUTUBE:
      return '#ce1312';
    default:
      return '#4e6baa'; // For Facebook pages
  }
};

export type AccountNameType =
  | 'facebookAccount'
  | 'facebookPages'
  | 'instagramAccounts'
  | 'twitterAccounts'
  | 'youtubeAccounts'
  | 'tiktokAccounts'
  | '__typename';

export const getSocialAccountTypeByAccountName = (accountName: AccountNameType) => {
  switch (accountName) {
    case 'facebookAccount':
      return SocialAccountType.FACEBOOK;
    case 'facebookPages':
      return SocialAccountType.FACEBOOK_PAGE;
    case 'instagramAccounts':
      return SocialAccountType.INSTAGRAM;
    case 'twitterAccounts':
      return SocialAccountType.TWITTER;
    case 'youtubeAccounts':
      return SocialAccountType.YOUTUBE;
    case 'tiktokAccounts':
      return SocialAccountType.TIKTOK;

    default:
      return undefined;
  }
};

export const switchCountUnit = (socialMedia: SocialAccountType): string =>
  socialMedia === SocialAccountType.INSTAGRAM ? 'Followers' : 'Subscribers';

export const getSocialMediaUrl = (
  socialMedia: CampaignPromotionMethod | CampaignSocialMediaType | BioSocialMediaType | SocialAccountType
) => {
  switch (socialMedia) {
    case SocialAccountType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM_STORY:
      return 'https://www.instagram.com';

    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return 'https://www.facebook.com';

    case SocialAccountType.TWITTER:
      return 'https://www.twitter.com';

    case SocialAccountType.YOUTUBE:
      return 'https://www.youtube.com';

    case SocialAccountType.TIKTOK:
      return 'https://www.tiktok.com';

    case BioSocialMediaType.LINE:
      return 'https://liff.line.me';

    default:
      return undefined;
  }
};
