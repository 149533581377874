import React, { useEffect } from 'react';
import { InitialLoading } from '@src/components/atoms';
import { useAnyXSocialAuthReconnectTikTokMutation } from '@src/graphql/hooks';
import { getConnectSocialAuthState } from '@src/libs/auth';
import { ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';
import { useAuthProviderResponse } from '../hooks';

const MobileTiktokConnect = () => {
  const { enqueueSnackbar, navigate, t } = useQueryHelper();
  const { provider, response } = useAuthProviderResponse(ROUTES.SIGN_IN);
  const [anyXSocialAuthReconnectTikTok] = useAnyXSocialAuthReconnectTikTokMutation();
  const {
    connectState: { socialAccountId },
    resetConnectState,
  } = getConnectSocialAuthState();

  const connectTiktok = () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      navigate(ROUTES.SIGN_IN);

      return;
    }

    const variables = {
      input: {
        id: String(socialAccountId),
        response,
      },
    };
    anyXSocialAuthReconnectTikTok({ variables })
      .then(result => {
        if (result.data?.anyXSocialAuthReconnectTikTok?.ok) {
          navigate(ROUTES.MOBILE_TIKTOK_GRANT_PERMISSION);
        } else {
          navigate(generatePath(ROUTES.MOBILE_TIKTOK_GRANT_PERMISSION, undefined, { id: socialAccountId }));
        }
      })
      .catch(err => {
        if (err.message !== ErrorTypes.VIDEO_PERMISSION_MISSING) {
          enqueueSnackbar(t(err.message), { variant: 'error' });
        }
        navigate(generatePath(ROUTES.MOBILE_TIKTOK_GRANT_PERMISSION, undefined, { id: socialAccountId }));
      });
  };

  useEffect(() => {
    connectTiktok();

    return () => {
      resetConnectState();
    };
  }, []);

  return <InitialLoading />;
};

export default MobileTiktokConnect;
