import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { Empty } from '@src/components/atoms';
import { BackNavigator, ListLoading } from '@src/components/molecules';
import { useGetFanFormResponseQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { getQuestionWithAnswer } from './helper';

const FormsResponse = () => {
  const { t, params } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { id, submissionId } = params as { id: string; submissionId: string };
  const { data, loading } = useGetFanFormResponseQuery({
    variables: {
      input: {
        fanId: id,
        submissionId,
      },
    },
  });

  if (loading) {
    return <ListLoading />;
  }

  if (!data?.getFanFormResponse?.answers.length) {
    return <Empty />;
  }

  return (
    <div css={isMobileView ? styles.mobileContainer : styles.container}>
      {!isMobileView && (
        <div css={styles.wrapper}>
          <BackNavigator css={styles.navigator} to={`/fans/${id}/forms`} title="" skipTranslation={true} />
          <Link to={`/forms/${data.getFanFormResponse.formId}/entry`} css={styles.link}>
            {data.getFanFormResponse.formTitle}
          </Link>
        </div>
      )}
      {[...data.getFanFormResponse.answers]
        .sort((a, b) => a.order - b.order)
        .map((item, index) => {
          const { question, answer } = getQuestionWithAnswer(item, t);

          return (
            <div css={[styles.row, isMobileView && styles.mobileRow]} key={item.questionId}>
              <p css={styles.question}>{`${t('Question')} ${index + 1}: ${question}`}</p>
              <p css={styles.answer}>{answer}</p>
            </div>
          );
        })}
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  }),
  mobileContainer: css({
    padding: 0,
    backgroundColor: THEME.colors.white,
  }),
  container: css({
    padding: 24,
    backgroundColor: THEME.colors.white,
  }),
  navigator: css({
    '& > a': {
      boxShadow: THEME.box.shadows.outer,
    },
  }),
  row: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 24px 24px 18px',
    border: '1px solid #eef3f7',

    '&:not(:last-of-type)': {
      borderBottomStyle: 'none',
    },

    '& > p': {
      maxWidth: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        maxWidth: '100%',
      },
    },
  }),
  mobileRow: css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 16,
  }),
  question: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.gray.main,
  }),
  answer: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
  }),
  link: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.subHeading,
    color: THEME.font.colors.blue.main,
  }),
};

export default FormsResponse;
