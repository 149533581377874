import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useQueryHelper } from '@src/libs/hooks';
import { InitialLoading } from '@src/components/atoms';
import { useInfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation } from '@src/graphql/hooks';
import { getRedirectPath } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { connectSocialAuthState, useRecoil } from '@src/recoilAtoms';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useProviderResponse } from '../hooks';

const AnalyticsReconnect = () => {
  const {
    recoilState: { reconnectStatus, redirectPath },
    resetRecoilState,
  } = useRecoil(connectSocialAuthState);
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { provider, response } = useProviderResponse('profile');
  const [influencerSocialAuthReconnectInstagramAccountAnalytics] =
    useInfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation();

  useEffect(() => {
    if (reconnectStatus) {
      callReconnectAnalyticsAccount();
    }

    return () => {
      resetRecoilState();
    };
  }, [reconnectStatus]);

  const callReconnectAnalyticsAccount = async () => {
    try {
      await influencerSocialAuthReconnectInstagramAccountAnalytics({
        variables: {
          input: {
            analyticsAccountId: Number(reconnectStatus?.dataForReconnect.analyticsAccountId),
            response,
            // BECAUSE only HERE the callback of instagram is instagream and not facebook (while in the rest of the app,
            // the redirection for instagram is considered facebook)
            callbackUrl: `${location.origin}/${getRedirectPath(
              provider || SocialAccountType.INSTAGRAM,
              FE_REDIRECT_MAPPING.ANALYTICS_IG_RECONNECT
            )}`,
          },
        },
      });
      enqueueSnackbar(t('Your account has been reconnected successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('Fail to reconnect account, please try again later'), { variant: 'error' });
    } finally {
      navigate(redirectPath || '');
    }
  };

  if (!reconnectStatus) {
    enqueueSnackbar(t('Fail to reconnect account, please try again later'), { variant: 'error' });

    return <Navigate to={redirectPath || ''} />;
  }

  return <InitialLoading />;
};

export default AnalyticsReconnect;
