import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckBox, ErrorMessage, Icomoon, Switch } from '@src/components/atoms';
import { useSortable, CSS } from '@src/components/atoms/DragAndDropToolkit';
import { DragAndDrop } from '@src/components/molecules';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { Select, TextForm } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { QuestionType } from '@src/__generated__/globalTypes';
import { closeIcon } from '@src/assets/htmlCodes';

enum DialogStatus {
  CLOSE = 'CLOSE',
  DELETE = 'DELETE',
  UPLOAD = 'UPLOAD',
}

export enum QuestionErrorMessages {
  OPTION_TITLE = 'requiredQuestionOptionTitleMessage',
  TITLE = 'requiredQuestionTitleMessage',
  TYPE = 'requiredQuestionTypeMessage',
}

export interface Questions {
  id?: any;
  genId: any;
  image?: string | null;
  isRequired: boolean;
  options: {
    id?: string | null;
    order: number;
    optionTitle: string;
  }[];
  order: number;
  questionType: QuestionType;
  title: string;
}

interface DynamicInputProps {
  error: boolean;
  isEmailExist: boolean;
  isFormAnswerSubmitted: boolean;
  isNameExist: boolean;
  value: Questions;
  onChange: (value: Questions) => void;
  onCopy?: (value: Questions) => void;
  onDelete: () => void;
}

export const DynamicInput = ({
  error,
  isEmailExist,
  isFormAnswerSubmitted,
  isNameExist,
  value,
  onChange,
  onCopy,
  onDelete,
}: DynamicInputProps) => {
  const { image, isRequired, options, questionType, title } = value;
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>(DialogStatus.CLOSE);
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: value.genId });
  const { t } = useTranslation();

  const dropdownOptions = [
    {
      icon: <Icomoon icon="short-answer" />,
      label: t('Short answer'),
      value: QuestionType.SHORT_ANSWER,
    },
    {
      icon: <Icomoon icon="checkbox" size={12} />,
      label: t('Checkboxes'),
      value: QuestionType.CHECKBOX,
    },
    {
      icon: <Icomoon icon="dropdown" />,
      label: t('Dropdown'),
      value: QuestionType.DROPDOWN,
    },
  ];
  const [dropDownOptions, setDropDownOption] = useState(dropdownOptions);

  useEffect(() => {
    let items = dropdownOptions;
    if (!isNameExist) {
      items = items.concat({
        icon: <Icomoon icon="user-outlined" />,
        label: t('Name'),
        value: QuestionType.NAME,
      });
    }

    if (!isEmailExist) {
      items = items.concat({
        icon: <Icomoon icon="mail-outlined" />,
        label: t('Email Address'),
        value: QuestionType.EMAIL,
      });
    }
    setDropDownOption(items);
  }, [isEmailExist, isNameExist]);

  const onChangeOption = (index: number, val: string) => {
    const items = [...options];
    items[index].optionTitle = val;
    onChange({ ...value, options: items });
  };

  const onChangeSelect = (val: QuestionType) => {
    onChange({
      ...value,
      image: null,
      isRequired: false,
      options: [QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(val) ? [{ optionTitle: '', order: 1 }] : [],
      questionType: val,
      title,
    });
  };

  const onChangeUploadImage = (val: string) => {
    setDialogStatus(DialogStatus.CLOSE);
    onChange({ ...value, image: val });
  };

  const onClickAddOption = () => {
    const nextOrder = options.length + 1;
    const items = options.concat({ optionTitle: '', order: nextOrder });
    onChange({ ...value, options: items });
  };

  const onClickAddOther = () => {
    const nextOrder = options.length + 1;
    const items = options.concat({ optionTitle: 'Other', order: nextOrder });
    onChange({ ...value, options: items });
  };

  const onClickRemoveImage = () => {
    onChange({ ...value, image: '' });
  };

  const onClickRemoveOption = (index: number) => {
    // having readonly properties with es6 clone, so using lodash cloneDeep
    const items = cloneDeep(options);
    items.splice(index, 1).map((item, key) => {
      item.order = key + 1;

      return items;
    });
    onChange({ ...value, options: items });
  };

  return (
    <>
      <Dialog
        open={dialogStatus !== DialogStatus.CLOSE}
        onClose={() => setDialogStatus(DialogStatus.CLOSE)}
        {...(dialogStatus === DialogStatus.DELETE && {
          cancelButtonProps: {
            styles: smallButtonStyles,
            text: 'No, cancel',
            onClick: () => setDialogStatus(DialogStatus.CLOSE),
          },
          nextButtonProps: {
            styles: smallButtonStyles,
            text: 'Yes, delete it',
            theme: 'red',
            onClick: onDelete,
          },
        })}
      >
        {dialogStatus === DialogStatus.DELETE ? (
          <div css={styles.dialogContent}>
            <div>{t('Are you sure you want to permanently delete this?')}</div>
            <div>{t('Annotation.You might lost response data forever Before continuing, export your responses')}</div>
            <div>{t('Annotation.Do you want to delete this question and lost responses')}</div>
          </div>
        ) : (
          <div css={styles.dialogDropArea}>
            <div>{t('Title.Image Upload')}</div>
            <div>{`※${t('Annotation.You can upload one image')}`}</div>
            <DragAndDrop
              accept="image/gif, image/jpeg, image/png"
              contentNotes={[
                t('DragAndDrop.Info'),
                t('DragAndDrop.MaxSize', { maxSize: '10GB' }),
                t('DragAndDrop.FileType', {
                  MIME: 'PNG, GIF, JPEG, AVI, MOV, MP4',
                }),
                t('DragAndDrop.ImageRatio'),
              ]}
              onChange={val => onChangeUploadImage(val[0])}
            />
          </div>
        )}
      </Dialog>
      <InputContainer
        css={{
          transition,
          transform: CSS.Translate.toString(transform),
        }}
        isDragging={isDragging}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <div css={styles.dragIconContainer}>
          <Icomoon color="#c4d0da" icon="ellipsis-outlined" />
        </div>

        <div css={styles.contentContainer}>
          {questionType === QuestionType.NAME ? (
            <div css={styles.nameContentContainer}>
              <div>
                <StyledTextForm
                  disabled
                  isRequired={isRequired}
                  placeholder={t('TextForm.First Name')}
                  title="First Name"
                />
              </div>
              <div>
                <StyledTextForm
                  disabled
                  isRequired={isRequired}
                  placeholder={t('TextForm.Last Name')}
                  title="Last Name"
                />
              </div>
            </div>
          ) : questionType === QuestionType.EMAIL ? (
            <div>
              <StyledTextForm disabled isRequired={isRequired} placeholder="sample@example.com" title="Email Address" />
            </div>
          ) : (
            <div css={styles.multiContentContainer}>
              <div>
                <div>
                  <StyledTextForm
                    isRequired={isRequired}
                    placeholder={t('TextForm.Question Title')}
                    value={title}
                    onChange={e => onChange({ ...value, title: e.target.value })}
                  />
                  {error && !title && <ErrorMessage message={QuestionErrorMessages.TITLE} />}
                </div>
                <div css={{ display: 'flex', gap: '8px' }}>
                  <div>
                    <Select
                      options={dropDownOptions}
                      value={questionType}
                      onChange={val => onChangeSelect(val as QuestionType)}
                    />
                    {error && !questionType && <ErrorMessage message={QuestionErrorMessages.TYPE} />}
                  </div>
                  <ActionIcon disabled={!!image} onClick={() => setDialogStatus(DialogStatus.UPLOAD)}>
                    <Icomoon icon="image-outlined" size={14} />
                  </ActionIcon>
                </div>
              </div>

              <div>
                {image && (
                  <div css={styles.uploadedImageContainer}>
                    <div>
                      <img alt="image" src={image} />
                    </div>
                    <div>
                      <span onClick={onClickRemoveImage} css={styles.close}>
                        {closeIcon}
                      </span>
                    </div>
                  </div>
                )}
                {[QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(questionType) ? (
                  <>
                    {options.map((option, index) => (
                      <div css={styles.optionField} key={index}>
                        {questionType === QuestionType.CHECKBOX ? (
                          <CheckBox label="" checked={false} />
                        ) : (
                          <NumberText>{`${index + 1}.`}</NumberText>
                        )}
                        <div>
                          <StyledTextForm
                            placeholder={`${t('option')} ${index + 1}`}
                            value={option.optionTitle}
                            onChange={e => onChangeOption(index, e.target.value)}
                          />
                          {error && !option.optionTitle && (
                            <ErrorMessage message={QuestionErrorMessages.OPTION_TITLE} />
                          )}
                        </div>
                        <div>
                          {options.length > 1 && (
                            <span onClick={() => onClickRemoveOption(index)} css={styles.close}>
                              {closeIcon}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                    <div css={styles.addOption}>
                      {questionType === QuestionType.CHECKBOX ? (
                        <CheckBox label="" checked={false} />
                      ) : (
                        <NumberText>{`${options.length + 1}.`}</NumberText>
                      )}
                      &nbsp;
                      <span onClick={onClickAddOption}>{t('Add option')}&nbsp;</span>
                      {questionType === QuestionType.CHECKBOX && (
                        <>
                          <span>{t('or')}&nbsp;</span>
                          <span onClick={onClickAddOther}>{t('add other')}</span>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div css={styles.shortAnsContentContainer}>
                    <StyledTextForm disabled placeholder={t('TextForm.Short answer text')} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div css={styles.actionContainer}>
          {![QuestionType.EMAIL, QuestionType.NAME].includes(questionType) && (
            <ActionIcon onClick={() => onCopy?.(value)}>
              <Icomoon icon="copy-outlined" />
            </ActionIcon>
          )}
          <ActionIcon onClick={() => (isFormAnswerSubmitted ? setDialogStatus(DialogStatus.DELETE) : onDelete())}>
            <Icomoon icon="trash-outlined" />
          </ActionIcon>
          <div css={styles.divider} />
          <div css={styles.switchContainer}>
            <span>{t(`Required`)}</span>
            <Switch checked={isRequired} onChange={() => onChange({ ...value, isRequired: !value.isRequired })} />
          </div>
        </div>
      </InputContainer>
    </>
  );
};

const ActionIcon = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  alignItems: 'center',
  backgroundColor: THEME.colors.white,
  border: THEME.box.borders.gray.main,
  borderRadius: 2,
  cursor: 'pointer',
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  width: 32,
  ...(disabled && {
    backgroundColor: '#eef3f7',
    border: THEME.box.borders.gray.main,
    cursor: 'default',
    pointerEvents: 'none',
  }),
}));

const InputContainer = styled.div<{ isDragging: boolean }>(({ isDragging }) => ({
  backgroundColor: '#f6f8fa',
  border: THEME.box.borders.gray.main,
  borderRadius: 3,
  cursor: 'move',
  marginBottom: 24,
  opacity: isDragging ? 0.8 : 1,
  padding: '0 24px',
}));

const NumberText = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  marginRight: 8,
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  },

  '& input': {
    borderRadius: 3,
    height: 32,
  },
});

const styles = {
  actionContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 0',
    gap: THEME.box.gaps.s,
  }),
  addOption: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 24,

    '& > div': {
      padding: 0,
      width: 'fit-content',
    },

    '& > span:nth-of-type(1)': {
      color: THEME.font.colors.gray.main,
      cursor: 'pointer',
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      textDecoration: 'underline',
    },

    '& > span:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },

    '& > span:nth-of-type(3)': {
      color: THEME.font.colors.blue.main,
      cursor: 'pointer',
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },
  }),
  contentContainer: css({
    borderBottom: '1px solid #dee5ec',
    paddingBottom: 16,
  }),
  dialog: {
    borderRadius: '5px',
    boxShadow: THEME.box.shadows.outer,
    maxWidth: 648,
    padding: 0,
    width: '90%',
  },
  dialogCloseBtn: css({
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    position: 'absolute',
    right: -10,
    top: -15,
    width: 40,

    '& > i': {
      margin: 0,
    },
  }),
  dialogContent: css({
    padding: '0 8px 0 8px',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 24,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 24,
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.error,
      fontSize: THEME.font.sizes.normal,
    },
  }),
  dialogDropArea: css({
    padding: '0 8px 0 8px',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 16,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 16,
    },
  }),
  divider: css({
    borderLeft: '1px solid #dee5ec',
  }),
  dragIconContainer: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  fileUploadContainer: css({
    '& label': {
      fontSize: 13,
    },
  }),
  fileUploadDropAreaCss: css({
    '& .drop-area-notes': {
      justifyContent: 'center',
    },
  }),
  nameContentContainer: css({
    display: 'flex',
    flexBasis: '100%',
    gap: THEME.box.gaps.xl,

    '& > div': {
      width: 'fill-available',
    },
  }),
  multiContentContainer: css({
    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      gap: THEME.box.gaps.s,

      '& > div:nth-of-type(1)': {
        display: 'grid',
        flex: 1,

        '& > div': {
          width: 'fill-available',
        },
      },

      '& > div:nth-of-type(2)': {
        display: 'flex',
        flex: 1,

        '& > div:nth-of-type(1)': {
          width: 'fill-available',
        },
      },
    },
  }),
  optionField: css({
    alignItems: 'center',
    display: 'flex',
    marginTop: 24,

    '& > div:nth-of-type(1)': {
      marginRight: 8,
      padding: 0,
      width: 'fit-content',

      '& > label': {
        marginRight: 0,
      },
    },

    '& > div:nth-of-type(2)': {
      width: '350%',
    },

    '& > div:nth-of-type(3)': {
      display: 'flex',
      justifyContent: 'flex-end',
      width: 'fill-available',
    },
  }),
  shortAnsContentContainer: css({
    marginTop: 16,
    width: '40%',
  }),
  switchContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.xs,

    '& > span': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
    },
  }),
  uploadedImageContainer: css({
    alignItems: 'flex-end',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 16,

    '& > div:nth-of-type(1)': {
      backgroundColor: '#000',
      width: 213,

      '& > img': {
        height: 120,
        objectFit: 'contain',
        width: '100%',
      },
    },

    '& > div:nth-of-type(2)': {
      marginLeft: 8,
    },
  }),
  button: css({
    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      borderRadius: 3,
      height: 40,
      width: 'fill-available',
    },
  }),
  close: css({
    fontSize: THEME.font.sizes.title,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  }),
};

export default DynamicInput;
