import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Empty, Icomoon, Icon } from '@src/components/atoms';
import PostReportCard from '@src/components/organisms/MyJobs/Marketplace/PostReport/PostReportCard';
import Card from '@src/components/organisms/MyPage/Payment/Revenue/Card';
import {
  useMarketplaceCampaignPostReportForInfluencerQuery,
  useMarketplaceForInfluencerQuery,
} from '@src/graphql/hooks';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import { CampaignType } from '@src/__generated__/globalTypes';

const PostReport = () => {
  const { isMobileView } = usePageLayout();
  const { params, t } = useQueryHelper();
  const campaignId = Number(params.id);
  const { data: dataMarketplace } = useMarketplaceForInfluencerQuery({ variables: { pk: campaignId } });

  const hasReport = dataMarketplace?.marketplaceForInfluencer?.hasReport;
  const { data: dataReport } = useMarketplaceCampaignPostReportForInfluencerQuery({
    skip: !hasReport,
    variables: { campaignId },
  });
  const reportDetails = dataReport?.marketplaceCampaignPostReportForInfluencer;
  const currency = reportDetails?.currency;
  const posts = reportDetails?.postReports;
  const revenue = reportDetails?.revenue || 0;

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.header}>
          <Link to={generatePath(ROUTES.MY_JOBS_MARKETPLACE_ID, { id: campaignId })}>
            <Icon>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Icon>
          </Link>
          <div>{t('pageTitle.Post Report')}</div>
        </div>
      )}

      <div css={{ padding: '24px 16px' }}>
        <Card currency={currency} isTransparentBackground={isMobileView} revenue={revenue} />

        {posts ? (
          <div css={{ display: 'grid', gap: THEME.box.gaps.xxl }}>
            {posts.map((post, index) => (
              <PostReportCard campaignType={CampaignType.MARKETPLACE} key={index} post={post} />
            ))}
          </div>
        ) : (
          <Empty content="You can track performance on daily basis" title="There are no reports" />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '24px 16px',
    },
  }),
  header: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: '23px 23px 0 0',
    display: 'flex',
    gap: THEME.box.gaps.xxl,
    padding: 32,

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  }),
};

export default PostReport;
