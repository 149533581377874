import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { TiktokProfileAudienceQuery, TiktokProfileAudienceQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/TiktokProfileAudience.graphql';

export const useTiktokProfileAudienceQuery = (
  options?: QueryHookOptions<TiktokProfileAudienceQuery, TiktokProfileAudienceQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<TiktokProfileAudienceQuery, TiktokProfileAudienceQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useTiktokProfileAudienceLazyQuery = (
  options?: LazyQueryHookOptions<TiktokProfileAudienceQuery, TiktokProfileAudienceQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<TiktokProfileAudienceQuery, TiktokProfileAudienceQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
