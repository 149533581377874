import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  YoutubeCmsRevenueForInfluencerQuery,
  YoutubeCmsRevenueForInfluencerQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/YoutubeCmsRevenueForInfluencer.graphql';

export const useYoutubeCmsRevenueForInfluencerQuery = (
  options?: QueryHookOptions<YoutubeCmsRevenueForInfluencerQuery, YoutubeCmsRevenueForInfluencerQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<YoutubeCmsRevenueForInfluencerQuery, YoutubeCmsRevenueForInfluencerQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useYoutubeCmsRevenueForInfluencerLazyQuery = (
  options?: LazyQueryHookOptions<YoutubeCmsRevenueForInfluencerQuery, YoutubeCmsRevenueForInfluencerQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<YoutubeCmsRevenueForInfluencerQuery, YoutubeCmsRevenueForInfluencerQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
