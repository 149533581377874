import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import backgroundImg from '@src/assets/img/notification/emptyList.png';
import { Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import {
  useClickNotificationMutation,
  useGetNotificationsQuery,
  useMarkReadNotificationMutation,
} from '@src/graphql/hooks';
import { getDateLocal } from '@src/libs/date';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ROUTES } from '@src/shared/routes';
import { notificationIcon, notificationRedirectUrl } from './helpers';

const Notification = () => {
  const { enqueueSnackbar, i18n, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { data, fetchMore } = useGetNotificationsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {},
    },
  });
  const [clickNotification] = useClickNotificationMutation({
    refetchQueries: ['Notifications'],
  });
  const [markReadNotification] = useMarkReadNotificationMutation({
    refetchQueries: ['UnreadNotificationsCount'],
  });
  useEffect(() => {
    markReadNotification().catch(err => enqueueSnackbar(t(err.message), { variant: 'error' }));
  }, []);

  const notifications = data?.getNotifications?.notifications || [];
  const hasMore = data?.getNotifications?.hasMore || false;
  const checkpoint = data?.getNotifications?.checkpoint || 0;

  const onClickNotification = (id: string) => {
    clickNotification({
      variables: {
        input: { id },
      },
    }).catch(err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    });
  };

  const onClickViewMore = () => {
    fetchMore({
      variables: {
        input: { checkpoint },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return Object.assign({
          getNotifications: {
            ...fetchMoreResult.getNotifications,
            notifications: [
              ...(prev.getNotifications?.notifications || []),
              ...(fetchMoreResult.getNotifications?.notifications || []),
            ],
          },
        });
      },
    });
  };

  return (
    <div css={styles.container}>
      <div css={styles.notificationsContainer}>
        {!isMobileView && (
          <div css={styles.headerContainer}>
            <Link css={styles.backIcon} to={ROUTES.FIND_JOBS}>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Link>
            <div>{t('pageTitle.Notification')}</div>
          </div>
        )}

        {!notifications.length ? (
          <div css={styles.emptyNotificationContainer}>
            <div>
              <div>
                <img alt="bgImg" height="137" src={backgroundImg} width="128" />
              </div>
              <div>{t('Annotation.Empty Notification')}</div>
            </div>
          </div>
        ) : (
          notifications.map(notification => {
            const { content, created, id, isClicked, notificationId } = notification;
            const campaignId = content?.campaign_id || '';
            const campaignName = content?.campaign_name;
            const message = t(notificationId.toLowerCase(), {
              ...(campaignName ? { campaign_name: campaignName } : {}),
            });
            const url = content?.url || '';
            const { color, icon } = notificationIcon(notificationId);

            return (
              <NotificationContainer
                color={color}
                isClicked={isClicked}
                key={id}
                to={url || notificationRedirectUrl(campaignId, notificationId)}
                onClick={() => (!isClicked ? onClickNotification(id) : null)}
                {...(url && { target: '_blank' })}
              >
                <div />
                <div>
                  <div>{message}</div>
                  <div>
                    {formatDistanceToNow(new Date(created), {
                      addSuffix: true,
                      locale: getDateLocal(i18n.language as AppLanguage),
                    })}
                  </div>
                </div>
                <div>
                  <Icomoon color="#fff" icon={icon} size={16} />
                </div>
              </NotificationContainer>
            );
          })
        )}
      </div>

      {hasMore && (
        <div css={{ display: 'flex', justifyContent: 'center', padding: '0 24px' }}>
          <ThemeButton css={styles.viewMoreBtn} text="View More" onClick={onClickViewMore} />
        </div>
      )}
    </div>
  );
};

const NotificationContainer = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  color: string;
  isClicked: boolean;
}>(({ color, isClicked }) => ({
  alignItems: 'center',
  background: THEME.colors.white,
  borderTop: '1px solid #f4efef',
  display: 'flex',
  gap: THEME.box.gaps.s,
  padding: 16,

  '& > div:nth-of-type(1)': {
    alignSelf: 'flex-start',
    backgroundColor: isClicked ? '#fff' : '#e3436a',
    borderRadius: '50%',
    height: 8,
    marginTop: 5,
    width: 8,
  },

  '& > div:nth-of-type(2)': {
    display: 'grid',
    gap: THEME.box.gaps.s,
    width: 'fill-available',

    '& > div:nth-of-type(1)': {
      color: isClicked ? '#6e7c89' : '#1e1d19',
      fontSize: THEME.font.sizes.normal,
      fontWeight: isClicked ? 400 : 600,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subordinate,
    },
  },

  '& > div:nth-of-type(3)': {
    alignItems: 'center',
    backgroundColor: color,
    borderRadius: '50%',
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    minWidth: 32,
  },
}));

const styles = {
  backIcon: css({
    '[dir="rtl"] &': {
      transform: 'scaleX(-1)',
    },
  }),
  container: css({
    display: 'grid',
    gap: THEME.box.gaps.l,
    justifyContent: 'center',
    padding: 24,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: '0 0 24px 0',
    },
  }),
  emptyNotificationContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    height: '70vh',

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',

      '& > div': {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'center',
        marginBottom: 8,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.normal,
        textAlign: 'center',
        width: 110,
      },
    },
  }),
  headerContainer: css({
    alignItems: 'center',
    background: THEME.colors.white,
    display: 'flex',
    gap: THEME.box.gaps.xxl,
    height: 72,
    padding: '0 32px',

    '& > svg': {
      cursor: 'pointer',
    },

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  }),
  notificationsContainer: css({
    borderRadius: 23,
    maxWidth: 648,
    overflow: 'hidden',
    width: '100vw',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderBottom: '1px solid #f4efef',
      borderRadius: 0,
      maxWidth: 'unset',
    },
  }),
  viewMoreBtn: css({
    borderRadius: 9,
    height: 40,
    width: 228,

    [`@media (max-height: ${ViewportType.TABLET}px)`]: {
      height: 48,
      width: 'fill-available',
    },
  }),
};

export default Notification;
