import { QuestionType } from '@src/__generated__/globalTypes';
import React from 'react';
import { css } from '@emotion/react';
import { Select, TextForm } from '@src/components/shared';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { FormQuestionAnswer, GetFormInputPayload } from '@src/__generated__/globalTypes';

interface FormPageResponseProps {
  formData: GetFormInputPayload | null;
  answers: FormQuestionAnswer[];
}

const FormPageResponse = (props: FormPageResponseProps) => {
  const { t } = useTranslation();
  const { formData, answers } = props;

  const renderAnswer = (answer: FormQuestionAnswer) => {
    switch (answer.questionType) {
      case QuestionType.NAME: {
        const [firstName, lastName] = answer.answerText?.split(' ') || ' ';

        return (
          <div css={[styles.row, { display: 'flex', gap: '16px' }]} key={answer.questionId}>
            <div css={{ flex: 1 }}>
              <p css={styles.questionTitle}>{t('First Name')}</p>
              <TextForm css={styles.textForm} disabled={true} placeholder="" value={firstName} />
            </div>
            <div css={{ flex: 1 }}>
              <p css={styles.questionTitle}>{t('Last Name')}</p>
              <TextForm css={styles.textForm} disabled={true} placeholder="" value={lastName} />
            </div>
          </div>
        );
      }
      case QuestionType.EMAIL:
        return (
          <div css={styles.row} key={answer.questionId}>
            <p css={styles.questionTitle}>{t('Email Address')}</p>
            <TextForm css={styles.textForm} disabled={true} placeholder="" value={answer.answerText} />
          </div>
        );
      case QuestionType.SHORT_ANSWER:
        return (
          <div css={styles.row} key={answer.questionId}>
            <p css={styles.questionTitle}>{answer.questionTitle}</p>
            <TextForm css={styles.textForm} disabled={true} placeholder="" value={answer.answerText} />
          </div>
        );
      case QuestionType.CHECKBOX: {
        const allAnswersHash: { [key: string]: number } = answer.answerOptionIds.reduce(
          (prev, curr) => ({ ...prev, [curr]: 1 }),
          {}
        );

        return (
          <div css={styles.row} key={answer.questionId}>
            <p css={styles.questionTitle}>{answer.questionTitle}</p>
            {formData?.questions
              .find(question => question.id === answer.questionId)
              ?.options.map(option => (
                <div key={option.id} css={styles.checkboxRow}>
                  <CheckBox checked={!!allAnswersHash[option.id]} />
                  <span>{option.optionTitle}</span>
                </div>
              ))}
          </div>
        );
      }
      case QuestionType.DROPDOWN: {
        const dropdownOptions = formData?.questions
          .find(question => question.id === answer.questionId)
          ?.options.map(option => ({ value: option.id.toString(), label: option.optionTitle }));

        return (
          <div css={styles.row} key={answer.questionId}>
            <p css={styles.questionTitle}>{answer.questionTitle}</p>
            <Select
              css={styles.dropdown}
              disabled
              options={dropdownOptions || []}
              value={answer.answerOptionIds?.[0] ? answer.answerOptionIds?.[0].toString() : ''}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  return <>{answers.map(item => renderAnswer(item))}</>;
};

const styles = {
  row: css({
    padding: '24px 16px',

    '&:not(:last-of-type)': {
      borderBottom: '1px solid #dee5ec',
    },
  }),
  questionTitle: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
    marginBottom: 16,
  }),
  textForm: css({
    flex: 1,

    '& input': {
      borderRadius: 3,
      height: 32,
      border: 'none',
    },
  }),
  checkboxRow: css({
    display: 'flex',
    alignItems: 'center',
    gap: THEME.box.gaps.s,

    '.checkbox-root': {
      cursor: 'default',
    },

    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
  }),
  dropdown: css({
    width: 180,

    '& input': {
      fontSize: 13,
    },
  }),
};

export default FormPageResponse;
