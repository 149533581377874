import React from 'react';
import { ActionMeta, CSSObjectWithLabel, MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { THEME } from '@src/libs/theme';
import Label from '../Label';

export interface OptionType {
  label: string;
  value: string;
  __isNew__?: boolean;
}

export interface MultiSearchSelectorProps {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  setFieldValue: (value: OptionType[]) => void;
  placeholder: string;
  isDisabled?: boolean;
  isCreatable?: boolean;
  value: OptionType[];
  loadOptions: (inputValue: string, callback: (options: MultiValue<OptionType>) => void) => Promise<any> | void;
}

const MultiSearchSelector = (props: MultiSearchSelectorProps) => {
  const {
    className,
    title,
    isRequired,
    placeholder,
    value,
    help,
    setFieldValue,
    isDisabled = false,
    isCreatable = true,
    loadOptions,
  } = props;
  const Component = isCreatable ? AsyncCreatableSelect : AsyncSelect;

  const handleChange = (values: MultiValue<OptionType>, { action, removedValue }: ActionMeta<OptionType>) => {
    let listOptions = value;

    switch (action) {
      case 'create-option':
      case 'select-option':
        listOptions = values as OptionType[];
        break;
      case 'remove-value':
      case 'pop-value':
        listOptions = listOptions.filter(v => v.value !== removedValue.value);
        break;
      case 'clear':
        listOptions = [];
        break;
    }

    // @ts-ignore
    setFieldValue(listOptions);
  };

  return (
    <div className={className}>
      {title && <Label title={`Selector.${title}`} isRequired={isRequired} help={help} />}
      <Component
        cacheOptions={true}
        loadOptions={loadOptions}
        isMulti={true}
        onChange={handleChange}
        value={value}
        styles={styles}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
    </div>
  );
};

export const styles = {
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    border: THEME.box.borders.gray.main,
    borderRadius: '2px',
    minHeight: '32px',
    outline: 'none',
    boxShadow: 'none',
    lineHeight: '15px',

    '&:hover': {
      borderColor: '#dee5ec',
    },
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    border: THEME.box.borders.gray.main,
    borderRadius: '3px',
    borderTop: 'none',
    borderTopLeftRadius: 'unset',
    borderTopRightRadius: 'unset',
    marginTop: 0,
    boxShadow: 'unset',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (base: CSSObjectWithLabel) => ({
    ...base,

    div: {
      padding: '0 8px',
    },
  }),
  dropdownIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
    paddingRight: '7px',
    cursor: 'pointer',

    svg: {
      color: THEME.font.colors.gray.main,
      height: '17px',
    },
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    opacity: 0.3,
    marginLeft: '5px',
  }),
};

export default MultiSearchSelector;
