import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  ResendInfluencerForgotPasswordEmailMutation,
  ResendInfluencerForgotPasswordEmailMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/ResendInfluencerForgotPasswordEmail.graphql';

export const useResendInfluencerForgotPasswordEmailMutation = (
  options?: MutationHookOptions<
    ResendInfluencerForgotPasswordEmailMutation,
    ResendInfluencerForgotPasswordEmailMutationVariables
  >
) =>
  useMutation<ResendInfluencerForgotPasswordEmailMutation, ResendInfluencerForgotPasswordEmailMutationVariables>(
    MUTATION,
    options
  );
