import isEqual from 'lodash/isEqual';
import { useEffect, useRef, DependencyList, EffectCallback } from 'react';

const useDeepCompareEffect = (callback: EffectCallback, deps: DependencyList) => {
  const ref = useRef<DependencyList>();

  if (!ref.current || !isEqual(deps, ref.current)) {
    ref.current = deps;
  }

  useEffect(callback, ref.current);
};

export default useDeepCompareEffect;
