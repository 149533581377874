import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Logo } from '@src/components/atoms';
import { ViewportType } from '@src/libs/types';
import { usePageLayout, useUuumInfluencerView } from '@src/libs/hooks';

interface TopPageHeaderProps {
  className?: string;
  href?: LocationDescriptor;
}

const TopPageHeader = (props: TopPageHeaderProps) => {
  const { className, href } = props;
  const { pathname } = useLocation();
  const { isVerticalView } = usePageLayout();
  const { isUuumView } = useUuumInfluencerView();

  const path = pathname.split('/')[1];

  return (
    <header
      css={[
        styles.header,
        !['', 'reconnect', 'signin', 'signup'].includes(path) && isVerticalView && { justifyContent: 'center' },
      ]}
      className={className}
    >
      {!isUuumView ? <Logo css={styles.appLogo} href={href} width="165px" /> : null}
    </header>
  );
};

const styles = {
  appLogo: css({
    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      width: 145,
    },
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '16px 0 19px',
    justifyContent: 'space-between',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 48,
    },
  }),
};

export default TopPageHeader;
