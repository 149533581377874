import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';

export interface SwitchProps {
  checked: boolean;
  className?: string;
  color?: string;
  labelOff?: string;
  labelOn?: string;
  onChange?: (checked: boolean) => void;
}

const Switch = ({
  checked,
  className,
  color = THEME.colors.blue.main,
  labelOff = 'OFF',
  labelOn = 'ON',
  onChange,
}: SwitchProps) => {
  const { t } = useTranslation();

  return (
    <StyledSwitch className={className} color={color} onClick={() => onChange?.(!checked)}>
      <input type="checkbox" checked={checked} />
      <div className="slider" />
      {checked && labelOn && <span className="label-on">{t(labelOn)}</span>}
      {!checked && labelOff && <span className="label-off">{t(labelOff)}</span>}
    </StyledSwitch>
  );
};

const StyledSwitch = styled.div<{ color: string }>(({ color }) => ({
  display: 'grid',
  height: 24,
  position: 'relative',
  width: 54,

  '[dir="rtl"] &': {
    transform: 'scaleX(-1)',
    '-moz-transform': 'scaleX(-1)',
    '-ms-transform': 'scaleX(-1)',
    '-o-transform': 'scaleX(-1)',
    '-webkit-transform': 'scaleX(-1)',
  },

  '& > input': {
    height: 0,
    opacity: 0,
    width: 0,
  },

  '& > .slider': {
    background: THEME.colors.gray.dee5ec,
    borderRadius: 35,
    bottom: 0,
    cursor: 'pointer',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: '.16s',
    '-webkit-transition': '.16s',

    '&:before': {
      background: THEME.colors.white,
      borderRadius: '50%',
      bottom: 3,
      content: '""',
      height: 18,
      left: 3,
      position: 'absolute',
      transition: '.16s',
      width: 18,
      '-webkit-transition': '.16s',
    },
  },

  '& > .label-off': {
    alignSelf: 'center',
    color: THEME.font.colors.white,
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    justifySelf: 'right',
    padding: 6,
    position: 'absolute',

    '[dir="rtl"] &': {
      transform: 'scaleX(-1)',
      '-moz-transform': 'scaleX(-1)',
      '-ms-transform': 'scaleX(-1)',
      '-o-transform': 'scaleX(-1)',
      '-webkit-transform': 'scaleX(-1)',
    },
  },

  '& > .label-on': {
    alignSelf: 'center',
    color: THEME.font.colors.white,
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    justifySelf: 'left',
    padding: 6,
    position: 'absolute',

    '[dir="rtl"] &': {
      transform: 'scaleX(-1)',
      '-moz-transform': 'scaleX(-1)',
      '-ms-transform': 'scaleX(-1)',
      '-o-transform': 'scaleX(-1)',
      '-webkit-transform': 'scaleX(-1)',
    },
  },

  'input:checked + .slider': {
    background: color,
  },

  'input:focus + .slider': {
    boxShadow: THEME.box.shadows.outer,
  },

  'input:checked + .slider:before': {
    transform: 'translateX(30px)',
    '-ms-transform': 'translateX(30px)',
    '-webkit-transform': 'translateX(30px)',
  },
}));

export default Switch;
