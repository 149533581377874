import { SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import React, { FC, PropsWithChildren } from 'react';

interface StatusColumnProps extends StatusProps {
  className?: string;
  isTableElement?: boolean;
}

const StatusColumn: FC<PropsWithChildren<StatusColumnProps>> = props => {
  const { className, statusColor, children, isTableElement = true } = props;
  const StatusWrapper = isTableElement ? 'td' : 'div';

  return (
    <StatusWrapper className={className} css={!isTableElement ? { height: 24, whiteSpace: 'nowrap' } : {}}>
      <TableData statusColor={statusColor}>{children}</TableData>
    </StatusWrapper>
  );
};

interface StatusProps {
  statusColor?: SerializedStyles;
}

const TableData = styled.p<StatusProps>(({ statusColor }) => ({
  borderRadius: 3,
  padding: '5px 14px',
  textAlign: 'center',
  fontSize: THEME.font.sizes.subordinate,
  lineHeight: 1.17,
  letterSpacing: 'normal',
  statusColor,
}));

export default StatusColumn;
