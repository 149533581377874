import {
  DeliveryTimingType,
  FontSize,
  LineBroadcastStatus,
  LineMessageType,
  MediaType,
  RecipientType,
} from '@src/__generated__/globalTypes';

export const dateFormat = 'dd/MM/yyyy HH:mm';

export interface DeliveryTiming {
  scheduleDate: string | null;
  type: DeliveryTimingType;
}

export const fontSizeMapping: Record<FontSize, string> = {
  [FontSize.SM]: '14',
  [FontSize.MD]: '16',
  [FontSize.LG]: '19',
  [FontSize.XL]: '22',
  [FontSize.XXL]: '28',
};

export enum FormType {
  BUTTON_TYPE = 'BUTTON_TYPE',
  BUTTON_TYPE_CONTENT = 'BUTTON_TYPE_CONTENT',
  DEFAULT = 'DEFAULT',
  IMAGE_TYPE = 'IMAGE_TYPE',
  IMAGE_TYPE_CONTENT = 'IMAGE_TYPE_CONTENT',
  IMAGE_VIDEO_TYPE = 'IMAGE_VIDEO_TYPE',
  PLAIN_TEXT = 'PLAIN_TEXT',
  RICH_MESSAGE = 'RICH_MESSAGE',
}

export interface LineContentButtonCard {
  actionLabels: {
    genId: string;
    label: string;
    url: string;
  }[];
  description: {
    fontSize: FontSize;
    text: string;
    weighted: boolean;
  };
  genId: string;
  imageUrl: string;
  title: {
    fontSize: FontSize;
    text: string;
    weighted: boolean;
  };
}

export interface LineContent {
  buttonMessage?: {
    cards: LineContentButtonCard[];
  };
  genId: string;
  imageMessage?: {
    images: {
      genId: string;
      imageUrl: string;
      label?: string;
      url: string;
    }[];
  };
  mediaMessage?: {
    media: {
      image: {
        fileName: string;
        mimeType: string;
        previewUrl: string;
        url: string;
      };
      type: MediaType;
      video: {
        fileName: string;
        mimeType: string;
        previewUrl: string;
        url: string;
      };
    };
  };
  plainTextMessage?: {
    text: string;
  };
  richMessage?: {
    imageUrl: string;
    url?: string;
  };
  type: LineMessageType;
}

export interface Recipient {
  tagNames: string[];
  type: RecipientType;
}

export interface FormInformation {
  deliveryTiming: DeliveryTiming;
  formType: FormType;
  id?: string;
  isSend: boolean;
  isTest: boolean;
  lineContent: LineContent[];
  recipient: Recipient;
  selectedBlockIndex: number;
  status?: LineBroadcastStatus;
  title: string;
}
