import React from 'react';
import CheckBox from '../CheckBox';

interface HeaderCheckboxColumnProps {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  fontSizeCheckbox?: string;
  isTable?: boolean;
  indeterminate?: boolean;
}

const HeaderCheckboxColumn = (props: HeaderCheckboxColumnProps) => {
  const { className, checked, disabled = false, isTable = true, indeterminate, onChange } = props;
  const Wrapper = isTable ? 'th' : 'div';

  return (
    <Wrapper className={className}>
      <CheckBox checked={checked} disabled={disabled} indeterminate={indeterminate} onChange={onChange} />
    </Wrapper>
  );
};

export default HeaderCheckboxColumn;
