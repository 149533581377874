import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

declare const GA_TRACKING_CODE: string;

export enum GACategories {
  'ANALYTICS' = 'ANALYTICS',
  'LINK_IN_BIO' = 'LINK_IN_BIO',
  'CAMPAIGN' = 'CAMPAIGN',
}

// confluence was using CONSTANT_CASE so declare it as enum
export enum GAActions {
  // link in bio
  'COPY_URL_BIO' = 'COPY_URL_BIO',
  'CREATE_BIO' = 'CREATE_BIO',
  'FINISH_BIO' = 'FINISH_BIO',
  'NEXT_FROM_ADD_BIO' = 'NEXT_FROM_ADD_BIO',
  'NEXT_FROM_LINK_BIO' = 'NEXT_FROM_LINK_BIO',
  'NEXT_FROM_PROFILE_BIO' = 'NEXT_FROM_PROFILE_BIO',
  'NEXT_FROM_URL_BIO' = 'NEXT_FROM_URL_BIO',
  'SELECT_TEMPLATE_BIO' = 'SELECT_TEMPLATE_BIO',
  'SHARE_FACEBOOK_BIO' = 'SHARE_FACEBOOK_BIO',
  'SHARE_INSTAGRAM_BIO' = 'SHARE_INSTAGRAM_BIO',
  'SHARE_TIKTOK_BIO' = 'SHARE_TIKTOK_BIO',
  'SHARE_TWITTER_BIO' = 'SHARE_TWITTER_BIO',
  'SHARE_YOUTUBE_BIO' = 'SHARE_YOUTUBE_BIO',
  // analytics
  'FACEBOOK_LOGIN_ANALYTICS' = 'FACEBOOK_LOGIN_ANALYTICS',
  'INSTAGRAM_LOGIN_ANALYTICS' = 'INSTAGRAM_LOGIN_ANALYTICS',
  'TIKTOK_LOGIN_ANALYTICS' = 'TIKTOK_LOGIN_ANALYTICS',
  'TWITTER_LOGIN_ANALYTICS' = 'TWITTER_LOGIN_ANALYTICS',
  'YOUTUBE_LOGIN_ANALYTICS' = 'YOUTUBE_LOGIN_ANALYTICS',
  // campaign
  'JOIN_FROM_DETAIL' = 'JOIN_FROM_DETAIL',
  'NEXT_FROM_ACCOUNT_SELECT' = 'NEXT_FROM_ACCOUNT_SELECT',
  'NEXT_FROM_POLICY' = 'NEXT_FROM_POLICY',
  'POST_FROM_DETAIL' = 'POST_FROM_DETIL',
  'POST_FROM_NEXTSTEP' = 'POST_FROM_NEXTSTEP',
  'REPORT' = 'REPORT',
}

export const useGaTracker = (isSignedIn: boolean) => {
  const { pathname } = useLocation();

  const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.send({ hitType: 'pageview', page });
  };

  useEffect(() => {
    if (GA_TRACKING_CODE && isSignedIn) {
      trackPage(pathname);
    }
  }, [pathname]);
};

export const sendGAEvent = ({ action, category }: { action: GAActions; category: GACategories }) => {
  ReactGA.event({ action, category });
};
