import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { TextForm } from '@src/components/shared';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@src/libs/theme';

export interface FormValues {
  comment: string;
}

const CommentForm = () => {
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();

  return (
    <div css={styles.form}>
      <div css={styles.commentWrapper}>
        <TextForm css={styles.textform} error={!!errors.comment} placeholder={t('Comment')} {...register('comment')} />
        <ThemeButton theme="blue" disabled={isSubmitting} text="Post" css={styles.submitBtn} type="submit" />
      </div>

      <ErrorMessage message={errors.comment?.message} />
    </div>
  );
};

const styles = {
  commentWrapper: css({
    display: 'flex',
    flex: 1,
    gap: THEME.box.gaps.s,
  }),
  form: css({
    display: 'flex',
    border: THEME.box.borders.gray.main,
    padding: 8,
    borderRadius: 5,
    flexDirection: 'column',
    marginBottom: 32,
  }),
  submitBtn: css({
    width: 'fit-content',

    '.btn-text': {
      padding: '0 16px',
    },
  }),
  textform: css({
    flex: 1,

    '& input': {
      borderRadius: 3,
      height: 32,
    },
  }),
};

export default CommentForm;
