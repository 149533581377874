import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useDirLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import BlurredBlock from '../BlurredBlock';

export interface DataWrapperProps {
  className?: string;
  children: ReactNode;
  header?: string | React.ReactNode;
  dataNotAvailable?: boolean;
  isAvailableRate?: boolean;
  wrapperText?: string;
}

const DataOpacityWrapper = (props: DataWrapperProps) => {
  const { children, header, dataNotAvailable = false, isAvailableRate = false, className, wrapperText } = props;
  const { t } = useTranslation();
  const { dir } = useDirLayout();

  return (
    <div className={className} css={styles.relativeContainer} dir={dir}>
      {header && <div css={{ padding: 16 }}>{typeof header === 'string' ? t(header) : header}</div>}
      {dataNotAvailable ? (
        <BlurredBlock isAvailableRate={isAvailableRate} wrapperText={wrapperText}>
          {children}
        </BlurredBlock>
      ) : (
        children
      )}
    </div>
  );
};

export const SectionHeader = styled.h3({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: THEME.font.sizes.subHeading,
  lineHeight: '18px',
  color: THEME.font.colors.black.main,
});

const styles = {
  relativeContainer: css({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  }),
};

export default DataOpacityWrapper;
