import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/browser';
import { setAmplitudeUserId } from '@src/amplitude';
import { useAuthData } from '@src/libs/hooks';
import GET_PERSONAL_INFORMATION from '@src/graphql/queries/GetPersonalUserDetail.graphql';
import { LanguageMapping } from '@src/pages/Settings/Account/YourAccount/YourAccount';
import { GetPersonalUserDetailQuery } from '@src/__generated__/globalTypes';
import { useVerifyToken } from './useVerifyToken';

declare const GA_TRACKING_CODE: string;
export const useAuthSetup = () => {
  const { i18n } = useTranslation();
  const client = useApolloClient();
  const { setAuthData } = useAuthData();
  const { verifyToken } = useVerifyToken();

  const setUp = async (token: string) => {
    // Verify Token
    const { countryId, creatorType, userId, role } = await verifyToken(token);

    const { data } = await client.query<GetPersonalUserDetailQuery>({
      query: GET_PERSONAL_INFORMATION,
    });

    // Set UserID to Sentry
    Sentry.setUser({
      id: String(userId),
    });

    if (GA_TRACKING_CODE) {
      // Set UserID to GoogleAnalytics
      ReactGA.set({ userId });
    }

    if (data?.getPersonalUserDetail?.displayLanguage !== LanguageMapping[i18n.language]) {
      const language = Object.keys(LanguageMapping).find(
        key => LanguageMapping[key] === data?.getPersonalUserDetail?.displayLanguage
      );
      i18n.changeLanguage(language);
    }

    // Set to Context Store
    setAuthData({
      countryId,
      creatorType,
      isAvailableCountry: data.getPersonalUserDetail?.isAvailableCountry,
      role,
      userId,
    });
    setAmplitudeUserId(String(userId));
  };

  return { setUp };
};
