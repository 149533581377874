import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthSignUpTalentMutation,
  AnyXSocialAuthSignUpTalentMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthSignUpTalent.graphql';

export const useAnyXSocialAuthSignUpTalentMutation = (
  options?: MutationHookOptions<AnyXSocialAuthSignUpTalentMutation, AnyXSocialAuthSignUpTalentMutationVariables>
) => useMutation<AnyXSocialAuthSignUpTalentMutation, AnyXSocialAuthSignUpTalentMutationVariables>(MUTATION, options);
