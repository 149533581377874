import { format, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import Select, { components, OptionProps } from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage, Icomoon, RadioIcon } from '@src/components/atoms';
import Popover, { MenuItem } from '@src/components/atoms/Popover';
import { ThemeButton } from '@src/components/molecules';
import DragAndDropToolkit, { DragEndProps } from '@src/components/atoms/DragAndDropToolkit';
import { DayPicker, Label, TextForm } from '@src/components/shared';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import { getDateTimeInPickerFormat } from '@src/libs/date';
import { THEME } from '@src/libs/theme';
import {
  DeliveryTimingType,
  FontSize,
  LineBroadcastStatus,
  LineMessageType,
  MediaType,
  RecipientType,
} from '@src/__generated__/globalTypes';
import BlockCard from '../BlockCard';
import { dateFormat, FormInformation, FormType } from '../helpers';
import { TimePicker } from '../StyledComponents';
import FormAction from './FormAction';

interface DefaultInputProps {
  isDisabled?: boolean;
  tags: { name: string }[];
  totalRecipients: number;
  onSubmit: (information: FormInformation) => Promise<void>;
}

const DefaultInput = ({ isDisabled, tags, totalRecipients, onSubmit }: DefaultInputProps) => {
  const [date, setDate] = useState<string>(format(new Date(), DEFAULT_FNS_DATE_FORMAT));
  const [time, setTime] = useState<string>('');
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [deliveryTiming, lineContent, recipient, status] = watch([
    'deliveryTiming',
    'lineContent',
    'recipient',
    'status',
  ]);

  const blockOptions = [
    { icon: 'edit-box-grey-light', title: 'Plain Text', value: LineMessageType.PLAIN_TEXT },
    { icon: 'image-grey', title: 'Rich Message', value: LineMessageType.RICH },
    { icon: 'cupboard-grey-light', title: 'Button Type', value: LineMessageType.BUTTON },
    { icon: 'three-cube-horizontal-grey-light', title: 'Image Type', value: LineMessageType.IMAGES },
    // anyChat not include this option
    // { icon: 'youtube-grey-light', title: 'Image / Video', value: LineMessageType.IMAGE_VIDEO },
  ];
  const maxCards = 5;
  const lineContentLength = lineContent.length;

  useEffect(() => {
    if (deliveryTiming.scheduleDate) {
      const selectedDate = parse(deliveryTiming.scheduleDate || '', dateFormat, new Date());
      const { date: dateString, time: timeString } = getDateTimeInPickerFormat(selectedDate);

      setDate(dateString);
      setTime(timeString);
    } else {
      setDate('');
      setTime('');
    }
  }, [deliveryTiming.scheduleDate]);

  const onClickAddBlock = (type: LineMessageType) => {
    setValue('lineContent', [
      ...lineContent,
      {
        genId: uuidv4(),
        type,
        ...(type === LineMessageType.BUTTON
          ? {
              buttonMessage: {
                cards: [
                  {
                    actionLabels: [{ genId: uuidv4(), label: '', url: '' }],
                    description: {
                      fontSize: FontSize.SM,
                      text: '',
                      weighted: false,
                    },
                    genId: uuidv4(),
                    imageUrl: '',
                    title: {
                      fontSize: FontSize.LG,
                      text: '',
                      weighted: true,
                    },
                  },
                  {
                    actionLabels: [{ genId: uuidv4(), label: '', url: '' }],
                    description: {
                      fontSize: FontSize.SM,
                      text: '',
                      weighted: false,
                    },
                    genId: uuidv4(),
                    imageUrl: '',
                    title: {
                      fontSize: FontSize.LG,
                      text: '',
                      weighted: true,
                    },
                  },
                ],
              },
            }
          : {}),
        ...(type === LineMessageType.IMAGES
          ? {
              imageMessage: {
                images: [
                  {
                    genId: uuidv4(),
                    imageUrl: '',
                    label: '',
                    url: '',
                  },
                  {
                    genId: uuidv4(),
                    imageUrl: '',
                    label: '',
                    url: '',
                  },
                ],
              },
            }
          : {}),
        ...(type === LineMessageType.IMAGE_VIDEO
          ? {
              media: {
                image: {
                  fileName: '',
                  mimeType: '',
                  previewUrl: '',
                  url: '',
                },
                type: MediaType.IMAGE,
              },
            }
          : {}),
        ...(type === LineMessageType.PLAIN_TEXT
          ? {
              text: '',
            }
          : {}),
        ...(type === LineMessageType.RICH
          ? {
              imageUrl: '',
              url: '',
            }
          : {}),
      },
    ]);
    setValue('selectedBlockIndex', lineContentLength);
    setValue(
      'formType',
      type === LineMessageType.BUTTON
        ? FormType.BUTTON_TYPE
        : type === LineMessageType.IMAGES
        ? FormType.IMAGE_TYPE
        : type === LineMessageType.IMAGE_VIDEO
        ? FormType.IMAGE_VIDEO_TYPE
        : type === LineMessageType.PLAIN_TEXT
        ? FormType.PLAIN_TEXT
        : FormType.RICH_MESSAGE
    );
  };

  const onChangeDeliveryTiming = (dateString: string, timeString: string) => {
    const parseDate = format(new Date(Date.parse(`${dateString} ${timeString}`)), dateFormat);
    setValue('deliveryTiming', { ...deliveryTiming, scheduleDate: parseDate });
  };

  const onDragEnd = ({ destinationIndex, sourceIndex }: DragEndProps) => {
    const items = [...lineContent];
    const dragCard = items[sourceIndex];
    items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, dragCard);
    setValue('lineContent', items);
  };

  const MenuOption = (props: OptionProps<{ label: string; value: string }, true>) => {
    const { label } = props;

    return (
      <components.Option {...props}>
        <div css={styles.tagMenuItem}>
          <div>{label}</div>
        </div>
      </components.Option>
    );
  };

  return (
    <div css={styles.container}>
      <div css={{ padding: 24 }}>
        <InputContainer>
          <TextForm
            disabled={isDisabled}
            error={!!errors.title}
            isRequired
            placeholder={t('Placeholder.Input the title')}
            title="Message Title"
            {...register('title')}
          />
          <ErrorMessage message={errors.title?.message} />
          <div css={styles.titleHint}>
            {t('Annotation.This is displayed as a display message on chat list, and also as a notification')}
          </div>
        </InputContainer>

        <InputContainer>
          <StyledLabel title="Delivery Schedule" />
          <RadioGroup
            disabled={isDisabled}
            onClick={() =>
              setValue('deliveryTiming', {
                scheduleDate: '',
                type: DeliveryTimingType.IMMEDIATELY_SEND,
              })
            }
          >
            <RadioIcon isChecked={deliveryTiming.type === DeliveryTimingType.IMMEDIATELY_SEND} />
            <div>{t('Immediately')}</div>
          </RadioGroup>
          <RadioGroup
            css={{ marginTop: 8 }}
            disabled={isDisabled}
            onClick={() =>
              setValue('deliveryTiming', {
                scheduleDate: format(
                  new Date(new Date().setHours(new Date().getHours() + 1)).setMinutes(0),
                  dateFormat
                ),
                type: DeliveryTimingType.SCHEDULE_SEND,
              })
            }
          >
            <RadioIcon isChecked={deliveryTiming.type === DeliveryTimingType.SCHEDULE_SEND} />
            <div>{t('Specific time')}</div>
          </RadioGroup>

          {deliveryTiming.type === DeliveryTimingType.SCHEDULE_SEND && (
            <div css={styles.dateTimeContainer}>
              <DayPicker
                css={{ width: 296 }}
                disableClear
                disabled={isDisabled}
                inputFormat="yyyy-MM-dd"
                placeholder="2020-01-01"
                value={date}
                onChange={val => onChangeDeliveryTiming(val, time)}
              />
              <TimePicker
                css={{ flex: 1 }}
                disabled={isDisabled}
                disableClear
                value={time}
                onChange={val => onChangeDeliveryTiming(date, val)}
              />
            </div>
          )}
        </InputContainer>

        <InputContainer>
          <div css={styles.recipientsTitleContainer}>
            <StyledLabel title="Recipients" />
            <div>
              <Trans
                components={{ 1: <span /> }}
                i18nKey="Estimated recipients"
                values={{ totalRecipients: totalRecipients || '-' }}
              />
            </div>
          </div>
          <RadioGroup
            disabled={isDisabled}
            onClick={() =>
              setValue('recipient', {
                type: RecipientType.ALL,
                tagNames: [],
              })
            }
          >
            <RadioIcon isChecked={recipient.type === RecipientType.ALL} />
            <div>{t('All Fans who have added your LINE Account as a friend.')}</div>
          </RadioGroup>
          <RadioGroup
            css={{ margin: '8px 0 16px 0' }}
            disabled={isDisabled}
            onClick={() =>
              setValue('recipient', {
                ...recipient,
                type: RecipientType.WITH_CUSTOMER_TAGS,
              })
            }
          >
            <RadioIcon isChecked={recipient.type === RecipientType.WITH_CUSTOMER_TAGS} />
            <div>{t('Fans with specific tag')}</div>
          </RadioGroup>

          {recipient.type === RecipientType.WITH_CUSTOMER_TAGS && (
            <>
              <Select
                components={{ Option: MenuOption }}
                css={styles.select}
                isDisabled={isDisabled}
                isClearable={false}
                isMulti
                options={tags.map(tag => ({ label: tag.name, value: tag.name }))}
                placeholder={t('Select Tags')}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${!!errors.recipient ? '#ff6247' : '#dee5ec'}`,
                    minHeight: 32,
                  }),
                  indicatorSeparator: base => ({
                    ...base,
                    display: 'none',
                  }),
                  multiValueLabel: base => ({
                    ...base,
                    backgroundColor: '#eef3f7',
                    color: THEME.font.colors.black.main,
                    fontSize: THEME.font.sizes.subordinate,
                  }),
                  multiValueRemove: base => ({
                    ...base,
                    backgroundColor: '#eef3f7',
                  }),
                  placeholder: base => ({
                    ...base,
                    color: '#e9e9e9',
                  }),
                }}
                value={recipient.tagNames.map(tag => ({ label: tag, value: tag }))}
                onChange={val =>
                  setValue('recipient', {
                    ...recipient,
                    tagNames: val?.map(item => item.value) || [],
                  })
                }
              />
              {errors.recipient && <ErrorMessage message={'requiredFieldMessage'} />}
            </>
          )}
        </InputContainer>

        <DragAndDropToolkit items={lineContent} primaryKey="genId" onDragEnd={onDragEnd}>
          {({ items }) =>
            items.map((item, index) => {
              const { buttonMessage, imageMessage, plainTextMessage, richMessage } = item;
              const buttonTypeMessageError =
                LineMessageType.BUTTON &&
                buttonMessage?.cards.find(card => card.actionLabels.find(action => !action.label || !action.url));
              const imageMessageError =
                item.type === LineMessageType.IMAGES &&
                imageMessage?.images.find(image => !image.imageUrl || !image.url);
              const plainTextMessageError = item.type === LineMessageType.PLAIN_TEXT && !plainTextMessage?.text;
              const richMessageError = item.type === LineMessageType.RICH && !richMessage?.imageUrl;

              return (
                <BlockCard
                  contentType={item.type}
                  error={
                    !!errors.lineContent &&
                    !!(buttonTypeMessageError || imageMessageError || plainTextMessageError || richMessageError)
                  }
                  id={item.genId}
                  isDeletable={!isDisabled && lineContent.length > 1}
                  key={item.genId}
                  onClick={() => setValue('selectedBlockIndex', index)}
                  onDelete={() =>
                    setValue('lineContent', [...lineContent.slice(0, index), ...lineContent.slice(index + 1)])
                  }
                />
              );
            })
          }
        </DragAndDropToolkit>

        {!isDisabled && (
          <>
            <Popover
              renderTrigger={
                <ThemeButton
                  disabled={lineContentLength >= maxCards}
                  height="40px"
                  text="Add Block"
                  prefixIcon={<Icomoon icon="plus" size={12} />}
                  width="max-content"
                />
              }
              align="start"
            >
              <div css={{ width: '230px', padding: '8px 0' }}>
                {blockOptions.map((option, index) => {
                  const { icon, title, value } = option;

                  return (
                    <MenuItem css={styles.menuItem} key={index} onClick={() => onClickAddBlock(value)}>
                      <Icomoon icon={icon} size={22} />
                      <div>{t(title)}</div>
                    </MenuItem>
                  );
                })}
              </div>
            </Popover>
            <div css={styles.cardCount}>{`${lineContentLength} / ${t('Card Maximum', {
              count: maxCards,
            })}`}</div>
          </>
        )}
      </div>

      {![LineBroadcastStatus.DELIVERED, LineBroadcastStatus.SCHEDULED, LineBroadcastStatus.SENDING].includes(
        status as LineBroadcastStatus
      ) && (
        <div css={styles.actionContainer}>
          <FormAction tags={tags} onSubmit={onSubmit} />
        </div>
      )}
    </div>
  );
};

const InputContainer = styled.div({
  marginBottom: 24,
});

const RadioGroup = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  display: 'flex',
  cursor: 'pointer',
  flexWrap: 'wrap',
  gap: THEME.box.gaps.s,
  pointerEvents: disabled ? 'none' : 'auto',

  '& > div:nth-of-type(2)': {
    color: THEME.font.colors.black.main,
    fontSize: 13,
  },
}));

const StyledLabel = styled(Label)({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
  lineHeight: 'unset',
  marginBottom: 16,
});

const styles = {
  actionContainer: css({
    '--paddingSides': '24px',

    borderTop: '1px solid #dee5ec',
    bottom: 0,
    padding: '16px var(--paddingSides)',
    position: 'absolute',
    width: 'calc(100% - var(--paddingSides) * 2)',
  }),
  cardCount: css({
    color: '#c5d0da',
    fontSize: THEME.font.sizes.subordinate,
    marginTop: 4,
  }),
  container: css({
    height: '100%',
    position: 'relative',
  }),
  dateTimeContainer: css({
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginTop: 16,
  }),
  menu: css({
    '& > .MuiMenu-paper': {
      backgroundColor: THEME.colors.white,
      border: THEME.box.borders.gray.main,
      borderRadius: 3,
      boxShadow: 'none',
      width: 232,
    },
  }),
  menuItem: css({
    display: 'flex',
    gap: 6,
    padding: '8px 16px !important',

    '& > div': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },
  }),
  recipientsTitleContainer: css({
    display: 'flex',
    flexWrap: 'wrap',

    '& > label': {
      display: 'flex',
      flex: 1,
    },

    '& > div': {
      backgroundColor: '#dbdee2',
      borderRadius: 35,
      fontSize: THEME.font.sizes.subordinate,
      height: 'fit-content',
      padding: '2px 8px',

      '& > span': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
      },
    },
  }),
  select: css({
    '& > div > div:nth-of-type(2)': {
      '& > div': {
        padding: '0 8px',
      },
    },
  }),
  tagMenuItem: css({
    '& > div': {
      backgroundColor: '#dee5ec',
      borderRadius: 3,
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subordinate,
      padding: '4px 8px',
      width: 'fit-content',
    },
  }),
  titleHint: css({
    color: THEME.font.colors.gray.main,
    fontSize: 11,
    marginTop: 4,
  }),
};

export default DefaultInput;
