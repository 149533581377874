import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ThemeButton from '@src/components/molecules/ThemeButton';
import { css } from '@emotion/react';
import { THEME } from '@src/libs/theme';

interface PostingHabitsColumnProps {
  className?: string;
  timeslots: string[];
  onClickAdd: () => void;
  isTable?: boolean;
}

const PostingHabitsColumn = (props: PostingHabitsColumnProps) => {
  const { className, timeslots, onClickAdd, isTable = true } = props;

  const { t } = useTranslation();
  const Wrapper = isTable ? TableElement : DivElement;
  const items = [
    { title: 'First time zone', value: timeslots[0] },
    { title: 'Second time zone', value: timeslots[1] },
    { title: 'Third time zone', value: timeslots[2] },
  ];

  return (
    <Wrapper className={className}>
      <div css={{ alignItems: 'center', display: 'flex' }}>
        <div css={styles.tableData}>
          <div css={styles.timeSlotWrapper}>
            {items.map((item, index) => (
              <div css={styles.timeSlot} key={index}>
                <p css={[styles.label, styles.twoLineHeader]}>{t(item.title)}</p>
                <p css={[styles.dateTime, styles.twoLineHeader]}>{item.value}</p>
              </div>
            ))}
            <ThemeButton
              css={{ borderRadius: 0 }}
              prefixIcon={<span css={{ fontSize: '22px' }}>+</span>}
              onClick={onClickAdd}
              width="32px"
              height="32px"
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const DivElement = styled.div({
  padding: 16,

  '&:not(:last-of-type)': {
    borderRight: '1px solid #dee5ec',
  },
});

const TableElement = styled(DivElement.withComponent('td'))``;

const styles = {
  dateTime: css({
    fontSize: THEME.font.sizes.subordinate,
    lineHeight: '15px',
    color: THEME.font.colors.black.main,
  }),
  label: css({
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: THEME.font.sizes.hint,
    lineHeight: '12px',
    color: THEME.font.colors.black.main,
    marginBottom: 12,
  }),
  tableData: css({
    display: 'inline-block',
    fontSize: 13,
    lineHeight: '16px',
    width: '100%',
  }),
  timeSlot: css({
    padding: '0 12px',

    '&:not(:last-of-type)': {
      borderRight: '1px solid #e6ecf0',
    },
  }),
  timeSlotWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  twoLineHeader: css({
    whiteSpace: 'pre-line',
    lineHeight: '1.4em',
    textAlign: 'left',
    /* asian characters might be without whitespace we better keep them inline */
    wordBreak: 'keep-all',

    span: {
      alignSelf: 'center',
    },
  }),
};

export default PostingHabitsColumn;
