import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetAccountInformationQuery } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetAccountInformation.graphql';

export const useGetAccountInformationQuery = (options?: QueryHookOptions<GetAccountInformationQuery>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetAccountInformationQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetAccountInformationLazyQuery = (options?: LazyQueryHookOptions<GetAccountInformationQuery>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetAccountInformationQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
