import { getNow } from '../date';
import { getAuthHeaders } from './utils';

interface DownloadFileProps {
  url: URL;
  filenamePrefix: string;
  method?: 'POST' | 'GET';
  params?: Record<string, any>;
  data?: Record<string, any>;
  hasAuthHeader?: boolean;
  extension?: string;
}
export const downloadFile = async ({
  url,
  filenamePrefix,
  method = 'GET',
  params = {},
  data,
  hasAuthHeader = true,
  extension,
}: DownloadFileProps) => {
  // Auth
  const headers = hasAuthHeader ? getAuthHeaders() : undefined;

  // Filename
  const now = getNow('yyyyMMdd_HHmmss');
  const filename = `${filenamePrefix}_${now}${extension ? extension : ''}`;

  // Params
  if (!!Object.keys(params).length) {
    const filteredParams = removeEmpty(params);
    url.search = new URLSearchParams(filteredParams).toString();
  }

  const { response, error } = await fetch(url.toString(), {
    method,
    mode: 'cors',
    headers,
    ...(!!data && { body: JSON.stringify(data) }),
  })
    .then(res => ({ response: res, error: null }))
    .catch((e: any) => ({ response: null, error: e }));

  if (!response || !response.ok || !!error) {
    const errorMessage = response ? response.statusText : error;

    return { ok: false, error: errorMessage };
  }

  await response.blob().then(blob => {
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = filename;
    anchor.click();

    return 'ok';
  });

  return { ok: true, error: null };
};

// https://github.com/microsoft/TypeScript/issues/31661#issuecomment-497138929
interface ObjectClone {
  [key: string]: any;
}
const removeEmpty = <T extends ObjectClone>(obj: T) =>
  Object.keys(obj).reduce((prev: ObjectClone, key) => {
    const newObj = prev;
    const value = obj[key];
    if (Array.isArray(value) && value.length > 0) {
      newObj[key] = value;
    } else if (!!value) {
      newObj[key] = value;
    }

    return newObj;
  }, {} as T);
