import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import * as React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { TextForm } from '@src/components/shared';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { FormValues } from './types';

interface NewPasswordFormProps {
  isPasswordReset?: boolean;
}

const NewPasswordForm = ({ isPasswordReset }: NewPasswordFormProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();

  return (
    <div css={{ maxWidth: 348, width: '100%' }}>
      <div css={styles.container}>
        {pathname.includes('password_reset') && (
          <>
            <Heading>{t('Heading.Change your password')}</Heading>
            <SubHeading>{t('SubHeading.Please set a new password')}</SubHeading>
          </>
        )}
        {pathname.includes('password_register') && (
          <>
            <Heading>{t('Heading.Set a password and sign in')}</Heading>
            <SubHeading>{t('SubHeading.Email address and password registered')}</SubHeading>
          </>
        )}
        <InputWrapper>
          <TextFormStyled
            autoComplete="new-password"
            title="New Password"
            placeholder="Your Password"
            isRequired={true}
            error={!!errors.password}
            type="password"
            {...register('password')}
          />
          <ErrorMessage message={errors.password?.message} />
        </InputWrapper>
        <InputWrapper>
          <TextFormStyled
            autoComplete="new-password"
            title="Confirm new password"
            placeholder="Your Password"
            isRequired={true}
            error={!!errors.passwordConfirm}
            type="password"
            {...register('passwordConfirm')}
          />
          <ErrorMessage message={errors.passwordConfirm?.message} />
        </InputWrapper>
        <div css={{ display: 'flex' }}>
          <ThemeButton
            customPalette={mainBlack}
            css={styles.submitButton}
            text={isPasswordReset ? 'Update' : 'Sign Up'}
            loading={isSubmitting}
            type="submit"
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  }),
  submitButton: css({
    borderRadius: 3,
    flex: 1,
    transition: '0.2s',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 5,
      height: 40,
    },
  }),
};

const Heading = styled.p({
  fontSize: THEME.font.sizes.subTitle,
  lineHeight: '24px',
  color: THEME.font.colors.black.main,
  textAlign: 'center',
  marginBottom: 16,
});

const SubHeading = styled.p({
  fontSize: THEME.font.sizes.normal,
  lineHeight: '18px',
  textAlign: 'center',
  color: THEME.font.colors.black.main,
  marginBottom: 40,
});

const TextFormStyled = styled(TextForm)({
  '& label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& input': {
    borderRadius: 3,
    height: 32,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 40,
    },
  },
});

const InputWrapper = styled.div({
  flex: 1,
  marginBottom: 24,
});

export default NewPasswordForm;
