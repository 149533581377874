import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthSignUpPartnerMutation,
  AnyXSocialAuthSignUpPartnerMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthSignUpPartner.graphql';

export const useAnyXSocialAuthSignUpPartnerMutation = (
  options?: MutationHookOptions<AnyXSocialAuthSignUpPartnerMutation, AnyXSocialAuthSignUpPartnerMutationVariables>
) => useMutation<AnyXSocialAuthSignUpPartnerMutation, AnyXSocialAuthSignUpPartnerMutationVariables>(MUTATION, options);
