import React from 'react';
import { usePaymentRequestQuery } from '@src/graphql/hooks';
import { useAuthData } from '@src/libs/hooks';
import Card from './Card';

interface RevenueProps {
  isTransparentBackground?: boolean;
}

const Revenue = ({ isTransparentBackground }: RevenueProps) => {
  const { countryId, isAvailableCountry } = useAuthData();
  const { data } = usePaymentRequestQuery({
    skip: countryId === 'US' || !isAvailableCountry,
  });

  const paymentRequest = data?.paymentRequest;
  const currency = paymentRequest?.currency;
  const revenue = paymentRequest?.grossRevenue || 0;

  return <Card currency={currency} isTransparentBackground={isTransparentBackground} revenue={revenue} />;
};

export default Revenue;
