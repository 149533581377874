import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDistanceStrict,
  isSameYear,
  differenceInMinutes,
  differenceInHours,
  format,
  fromUnixTime,
  isValid,
} from 'date-fns';
import { getDateLocal } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { useInterval } from '@src/libs/hooks';

const useFormatChatTime = ({ time, interval }: { time: Date | number; interval: number | null }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const locale = getDateLocal(language as AppLanguage);
  const [formattedChatTime, setFormattedChatTime] = useState('');

  const formatChatTime = useCallback(
    (inputTime: Date | number) => {
      const formatDistance = (inputDate: Date, baseDate: Date) => {
        if (!isValid(inputDate) || !isValid(baseDate)) {
          return '-';
        }

        if (!isSameYear(baseDate, inputDate)) {
          return format(inputDate, 'yyyy/MM/dd');
        }

        if (differenceInHours(baseDate, inputDate) >= 24) {
          return format(inputDate, 'MM/dd');
        }

        if (differenceInMinutes(baseDate, inputDate) < 1) {
          return t('now');
        }

        return formatDistanceStrict(inputDate, baseDate, {
          roundingMethod: 'floor',
          locale,
        });
      };

      const nowDate = new Date();

      if (typeof inputTime === 'number') {
        return formatDistance(fromUnixTime(inputTime), nowDate);
      }

      return formatDistance(inputTime, nowDate);
    },
    [t, locale]
  );

  useEffect(() => {
    setFormattedChatTime(formatChatTime(time));
  }, [time, formatChatTime]);

  useInterval(() => {
    setFormattedChatTime(formatChatTime(time));
  }, interval);

  return { formattedChatTime };
};

export default useFormatChatTime;
