import { ROUTES } from '@src/shared/routes';
import { LanguageDetectorModule } from 'i18next';
import { getLanguageFromLocalStorage, setLanguageToLocalStorage } from './localStorage';
import getLanguageFromNavigator from './navigator';
import { APP_LANGUAGES, convertLanguageCode } from './utils';

export const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => null,
  detect: () => {
    const storageLanguage = getLanguageFromLocalStorage();
    const isWithinIframe = window.location !== window.parent.location; // generic case means it is UUUM iframe
    const isCampaignDetailsPreview = window.location.pathname === ROUTES.CAMPAIGN_DETAILS_PUBLIC;

    if (storageLanguage === APP_LANGUAGES.pseudo) {
      return '';
    }

    if (isWithinIframe && !isCampaignDetailsPreview) {
      return APP_LANGUAGES.ja;
    }

    if (storageLanguage) {
      return storageLanguage;
    }

    const browserLanguage = getLanguageFromNavigator();
    if (browserLanguage) {
      return convertLanguageCode(browserLanguage);
    }

    return '';
  },
  cacheUserLanguage: (lng: string) => {
    setLanguageToLocalStorage(lng);

    return;
  },
};
