import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeAnalyticsAudienceQuery,
  InfluencerYoutubeAnalyticsAudienceQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerYoutubeAnalyticsAudience.graphql';

export const useInfluencerYoutubeAnalyticsAudienceQuery = (
  options?: QueryHookOptions<InfluencerYoutubeAnalyticsAudienceQuery, InfluencerYoutubeAnalyticsAudienceQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerYoutubeAnalyticsAudienceQuery, InfluencerYoutubeAnalyticsAudienceQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerYoutubeAnalyticsAudienceLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerYoutubeAnalyticsAudienceQuery,
    InfluencerYoutubeAnalyticsAudienceQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerYoutubeAnalyticsAudienceQuery, InfluencerYoutubeAnalyticsAudienceQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
