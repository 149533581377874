import React from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon, Icon } from '@src/components/atoms';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';

const FanNavigation = () => {
  const { t, params } = useQueryHelper();
  const { id } = params as { id: string };
  const { isMobileView } = usePageLayout();

  const navOptions = [
    {
      to: generatePath(ROUTES.MY_PAGE_FANS_EDIT_DETAILS, { id }),
      title: t(`MenuTitle.Fan's Details`),
      icon: 'user-grey',
    },
    {
      to: generatePath(ROUTES.MY_PAGE_FANS_EDIT_ACTIVITY, { id }),
      title: t('MenuTitle.Activity'),
      icon: 'address-book-grey-light',
    },
    {
      to: generatePath(ROUTES.MY_PAGE_FANS_EDIT_ORDERS, { id }),
      title: t('MenuTitle.Orders'),
      icon: 'link-grey-light',
    },
    {
      to: generatePath(ROUTES.MY_PAGE_FANS_EDIT_FORMS, { id }),
      title: t('MenuTitle.Forms'),
      icon: 'users-grey-light',
    },
  ];

  return (
    <div css={[styles.wrapper, isMobileView && styles.mobileWrapper]}>
      {navOptions.map(item => (
        <NavLink
          css={[styles.navlink, isMobileView && styles.mobileNavlink]}
          className={({ isActive }) => (isActive ? 'selected' : '')}
          to={item.to}
          key={item.to}
        >
          {isMobileView && <Icomoon css={styles.icon} icon={item.icon} size={24} />}
          <span>{t(item.title)}</span>
          {isMobileView && (
            <Icon css={{ flex: 'unset !important' }}>
              <Icomoon css={styles.accordion} icon="arrow-down" size={10} />
            </Icon>
          )}
        </NavLink>
      ))}
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    borderBottom: '1px solid #eef3f7',
    paddingLeft: 16,

    span: {
      flex: 1,
    },
  }),
  mobileWrapper: css({
    flexDirection: 'column',
    paddingLeft: 0,
    border: 0,
  }),
  accordion: css({
    transform: 'rotate(-90deg)',
    width: 16,
    height: 16,
    margin: '0 2px',
  }),
  icon: css({
    marginRight: 16,
  }),
  navlink: css({
    minWidth: 70,
    color: THEME.font.colors.black.main,
    padding: '14px 8px',
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    textAlign: 'center',
    marginRight: 16,

    '&.selected': {
      borderBottom: '4px solid #3892e5',
    },
  }),
  mobileNavlink: css({
    border: THEME.box.borders.gray.main,
    marginBottom: 16,
    borderRadius: 5,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 16px',
    backgroundColor: THEME.colors.white,
    marginRight: 0,
  }),
};

export default FanNavigation;
