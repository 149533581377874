import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { arrowTopLeft, arrowTopRight } from '@src/assets/htmlCodes';
import { Switch } from '@src/components/atoms';
import { useDirLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { defaultWidth } from './EditAccess';

export { defaultWidth };

interface ManageAccessProps {
  isMobile?: boolean;
  width?: number;
}

const ManageAccess = ({ isMobile = false, width = defaultWidth }: ManageAccessProps) => {
  const { t } = useTranslation();
  const { isRtl } = useDirLayout();
  const quotient = width / defaultWidth;

  return (
    <div css={styles.container(quotient, isMobile)}>
      <div css={styles.title(quotient)}>{t('Title.Manage access')}</div>
      <div css={styles.information(quotient)}>
        <div>{t('Annotation.Read your profile info')}</div>
        <div>{t('Required')}</div>
      </div>
      <div css={[styles.information(quotient), styles.highlighted(quotient)]}>
        <div>{t('Annotation.Read your public videos on TikTok without quote symbol')}</div>
        <Switch checked color="#66dca0" css={styles.switch(quotient)} labelOn="" />
      </div>
      <span css={styles.arrow(quotient)}>{isRtl ? arrowTopLeft : arrowTopRight}</span>
    </div>
  );
};

const styles = {
  arrow: (quotient: number) =>
    css({
      color: THEME.font.colors.error,
      fontFamily: '"Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSize: quotient * 65,
      right: quotient * 35,
      position: 'absolute',
      top: quotient * 70,

      '[dir="rtl"] &': {
        left: quotient * 35,
        right: 'unset',
      },
    }),
  container: (quotient: number, isMobile: boolean) =>
    css({
      backgroundColor: THEME.colors.white,
      border: THEME.box.borders.gray.main,
      borderRadius: 5,
      height: quotient * (isMobile ? 102 : 288),
      padding: quotient * 16,
      position: 'relative',
      width: quotient * 213,
    }),
  highlighted: (quotient: number) =>
    css({
      border: `${quotient * 4}px solid #ff5f5f`,
      borderRadius: 5,
      left: quotient * 5,
      padding: quotient * 8,
      position: 'absolute',
      width: '85%',
    }),
  information: (quotient: number) =>
    css({
      alignItems: 'center',
      display: 'flex',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        display: 'flex',
        flex: 1,
        fontSize: quotient * 6,
      },

      '& > div:nth-of-type(2)': {
        color: '#9d9d9e',
        fontSize: quotient * 6,
      },
    }),
  switch: (quotient: number) =>
    css({
      height: quotient * 9,
      width: quotient * 17,

      '& > .slider:before': {
        height: quotient * 7,
        left: quotient * 8,
        top: quotient * 0.7,
        width: quotient * 7,
      },

      'input:checked + .slider:before': {
        transform: 'unset',
        '-ms-transform': 'unset',
        '-webkit-transform': 'unset',
      },
    }),
  title: (quotient: number) =>
    css({
      color: THEME.font.colors.black.main,
      fontSize: quotient * 12,
      fontWeight: 600,
      marginBottom: quotient * 12,
      textAlign: 'center',
    }),
};

export default ManageAccess;
