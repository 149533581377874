import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { InitialLoading } from '@src/components/atoms';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { setToken } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth, useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const TiktokConnect = () => {
  const { enqueueSnackbar, search } = useQueryHelper();
  const { setUp } = useAuthSetup();
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.MOBILE_TIKTOK_CONNECT);
  const searchParams = new URLSearchParams(search);
  const socialAccountId = searchParams.get('id');
  const token = searchParams.get('token');

  if (!socialAccountId || !token) {
    return <Navigate to={ROUTES.SIGN_IN} />;
  }

  useEffect(() => {
    if (token) {
      setUpToken(token);
    }
  }, [token]);

  const setUpToken = async (accessToken: string) => {
    setToken(accessToken);
    try {
      await setUp(accessToken);
      connectSocialAuth(SocialAccountType.TIKTOK, { socialAccountId: Number(socialAccountId) });
    } catch (err) {
      enqueueSnackbar((err as { message: string }).message, { variant: 'error' });
    }
  };

  return <InitialLoading />;
};

export default TiktokConnect;
