import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languageDetector } from './languageDetector';
import postProcessModule from './postProcess';
import { AppLanguage, APP_LANGUAGES } from './languageDetector/utils';

import EN_US from './languageFiles/en_US.json';
import JA_JP from './languageFiles/ja_JP.json';
import TH_TH from './languageFiles/th_TH.json';
import VI_VN from './languageFiles/vi_VN.json';
import ZH_TW from './languageFiles/zh_TW.json';
import CH_CN from './languageFiles/ch_CN.json';
import KM_KH from './languageFiles/km_KH.json';
import MY_MM from './languageFiles/my_MM.json';
import AR_SA from './languageFiles/ar_SA.json';
import ID_ID from './languageFiles/id_ID.json';
import EN_JA from './languageFiles/en_JA.json';
import KO_KR from './languageFiles/ko_KR.json';

type Resources = Record<
  AppLanguage,
  {
    translation:
      | typeof EN_US
      | typeof JA_JP
      | typeof TH_TH
      | typeof VI_VN
      | typeof ZH_TW
      | typeof CH_CN
      | typeof KM_KH
      | typeof MY_MM
      | typeof ID_ID
      | typeof AR_SA
      | typeof EN_JA
      | typeof KO_KR;
  }
>;
const resources: Resources = {
  [APP_LANGUAGES.en]: {
    translation: EN_US,
  },
  [APP_LANGUAGES.th]: { translation: TH_TH },
  [APP_LANGUAGES.ja]: {
    translation: JA_JP,
  },
  [APP_LANGUAGES.vi]: { translation: VI_VN },
  [APP_LANGUAGES.tw]: { translation: ZH_TW },
  [APP_LANGUAGES.ch]: { translation: CH_CN },
  [APP_LANGUAGES.km]: { translation: KM_KH },
  [APP_LANGUAGES.my]: { translation: MY_MM },
  [APP_LANGUAGES.ar]: { translation: AR_SA },
  [APP_LANGUAGES.id]: { translation: ID_ID },
  [APP_LANGUAGES.ko]: { translation: KO_KR },
  [APP_LANGUAGES.pseudo]: { translation: EN_JA },
};

i18next
  .use(languageDetector)
  .use(postProcessModule)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    supportedLngs: Object.keys(resources),
    fallbackLng: APP_LANGUAGES.en,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export const LANGUAGE_OPTIONS: { value: AppLanguage; label: string }[] = [
  { value: APP_LANGUAGES.en, label: 'English' },
  { value: APP_LANGUAGES.ja, label: '日本語' },
  { value: APP_LANGUAGES.th, label: 'ไทย' },
  { value: APP_LANGUAGES.ch, label: '简体中文' },
  { value: APP_LANGUAGES.id, label: 'Bahasa' },
  { value: APP_LANGUAGES.km, label: 'ភាសាខ្មែរ' },
  { value: APP_LANGUAGES.my, label: 'မြန်မာဘာသာ' },
  { value: APP_LANGUAGES.tw, label: '繁體中文' },
  { value: APP_LANGUAGES.vi, label: 'Tiếng Việt' },
  { value: APP_LANGUAGES.ar, label: 'العربية' },
  { value: APP_LANGUAGES.ko, label: '한국어' },
];

export default i18next;
/**
 * @description name of t() postProcess to render `<buduox-ja/>` webcomponent
 */
export const postProcess = postProcessModule.name;
