import React from 'react';
import { THEME } from '@src/libs/theme';
import ActivityTimeline from './ActivityTimeline';

const FanActivity = () => (
  <div css={{ padding: 24, backgroundColor: THEME.colors.white }}>
    <ActivityTimeline />
  </div>
);

export default FanActivity;
