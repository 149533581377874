import { css } from '@emotion/react';

const maxContent = css({
  div: {
    width: 'max-content',
  },
});

const overflowHidden = css({
  div: {
    overflow: 'hidden',
    maxHeight: 32,
  },
});

const twoLineHeader = css({
  whiteSpace: 'pre-line',
  lineHeight: '1.4em',
  textAlign: 'left',
  wordBreak: 'keep-all',

  span: {
    alignSelf: 'center',
  },
});

const paddingSides = css({
  'td:first-of-type': {
    paddingLeft: 16,
  },

  'td:last-of-type': {
    paddingRight: 16,
  },
});

const preLine = css({
  span: {
    lineHeight: '16px',
  },
});

const flexStart = css({
  div: {
    justifyContent: 'flex-start',
  },
});

const spreadButtonsStyles = css({
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
});

const lastChildPaddingRight = css({
  'th:last-of-type': {
    paddingRight: 16,
  },
});

const displayFlexColumn = css({
  display: 'flex',
  flexDirection: 'column',
});

export default {
  maxContent,
  paddingSides,
  twoLineHeader,
  overflowHidden,
  preLine,
  flexStart,
  spreadButtonsStyles,
  lastChildPaddingRight,
  displayFlexColumn,
};
