import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetEmailTemplateQuery, GetEmailTemplateQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetEmailTemplate.graphql';

export const useGetEmailTemplateQuery = (
  options?: QueryHookOptions<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetEmailTemplateLazyQuery = (
  options?: LazyQueryHookOptions<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
