import { FanListFilterType } from '../types';
import { FormValues } from './types';

export const getInitialFormValues = (filter: FanListFilterType): FormValues => {
  const {
    keyword,
    createdDate,
    lastOrderDate,
    minAmountSpent,
    maxAmountSpent,
    exactAmountSpent,
    minNumberOfOrders,
    maxNumberOfOrders,
    exactNumberOfOrders,
  } = filter;

  return {
    keyword,
    createdDate,
    lastOrderDate,
    minAmountSpent,
    maxAmountSpent,
    exactAmountSpent,
    minNumberOfOrders,
    maxNumberOfOrders,
    exactNumberOfOrders,
    tags: [],
    checkedFields: {
      minAmountSpent: !!minAmountSpent,
      maxAmountSpent: !!maxAmountSpent,
      exactAmountSpent: !!exactAmountSpent,
      minNumberOfOrders: !!minNumberOfOrders,
      maxNumberOfOrders: !!maxNumberOfOrders,
      exactNumberOfOrders: !!exactNumberOfOrders,
    },
  };
};

export const getFilterData = (payload: FormValues): FanListFilterType => {
  const {
    keyword,
    createdDate,
    lastOrderDate,
    checkedFields,
    minAmountSpent,
    maxAmountSpent,
    exactAmountSpent,
    minNumberOfOrders,
    maxNumberOfOrders,
    exactNumberOfOrders,
    tags,
  } = payload;

  return {
    keyword,
    createdDate,
    lastOrderDate,
    minAmountSpent: checkedFields.minAmountSpent ? minAmountSpent : '',
    maxAmountSpent: checkedFields.maxAmountSpent ? maxAmountSpent : '',
    exactAmountSpent: checkedFields.exactAmountSpent ? exactAmountSpent : '',
    minNumberOfOrders: checkedFields.minNumberOfOrders ? minNumberOfOrders : '',
    maxNumberOfOrders: checkedFields.maxNumberOfOrders ? maxNumberOfOrders : '',
    exactNumberOfOrders: checkedFields.exactNumberOfOrders ? exactNumberOfOrders : '',
    tags: tags.map(item => item.value).join(','),
  };
};

export const getTagsIdFromURLString = (tags: string) =>
  tags
    .split(',')
    .filter(item => item)
    .map(item => Number(item));

export const getFanFilterCount = (filter: FanListFilterType) => {
  let count = 0;

  const {
    createdDate,
    lastOrderDate,
    minAmountSpent,
    maxAmountSpent,
    exactAmountSpent,
    minNumberOfOrders,
    maxNumberOfOrders,
    exactNumberOfOrders,
    tags,
  } = filter;

  if (createdDate) {
    count++;
  }

  if (lastOrderDate) {
    count++;
  }

  if (minAmountSpent || maxAmountSpent || exactAmountSpent) {
    count++;
  }

  if (minNumberOfOrders || maxNumberOfOrders || exactNumberOfOrders) {
    count++;
  }

  if (tags) {
    count++;
  }

  return count;
};
