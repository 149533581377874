import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { LanguageSwitcher } from '@src/components/molecules';
import LanguageMenu, { LanguageMenuChildProps } from '@src/components/organisms/LanguageMenu';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';

const Footer = () => {
  const { i18n, t } = useTranslation();
  const { isMobileView } = usePageLayout();

  const languageMenu = (
    <LanguageMenu>
      {({ title, visible, handleButtonClick, handleSwitcherClick }: LanguageMenuChildProps) => (
        <>
          <button css={styles.languageBtn} onClick={handleButtonClick}>
            <span>{title}</span>
            <Icomoon icon="arrow-down" size={10} />
          </button>
          <div css={styles.languageSwitcherContainer}>
            <LanguageSwitcher visible={visible} onClick={handleSwitcherClick} />
          </div>
        </>
      )}
    </LanguageMenu>
  );

  return (
    <div css={styles.container}>
      {isMobileView && (
        <div css={styles.languageMenuContainer}>
          <Icomoon icon="website" size={20} />
          {languageMenu}
        </div>
      )}
      <div css={styles.copyright}>Copyright © AnyMind Group. All right reserved.</div>
      {!isMobileView && (
        <div css={styles.termsAndConditions}>
          <Link rel="noopener noreferrer" target="_blank" to={ROUTES.PRIVACY}>
            {t('Privacy Policy')}
          </Link>
          <Link rel="noopener noreferrer" target="_blank" to={ROUTES.TERMS}>
            {t('Terms Of Service')}
          </Link>
          <Link rel="noopener noreferrer" target="_blank" to={`https://any-creator.com/${i18n.language}/contact/`}>
            {t('Contact')}
          </Link>
          {languageMenu}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    gap: THEME.box.gaps.xl,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      display: 'flex',
      gap: THEME.box.gaps.s,
    },
  }),
  copyright: css({
    color: THEME.font.colors.gray.main,
    display: 'flex',
    flex: 1,
    fontSize: THEME.font.sizes.subordinate,
  }),
  languageBtn: css({
    alignItems: 'center',
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    gap: THEME.box.gaps.s,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subordinate,
      padding: '0 12px',
    },
  }),
  languageMenuContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: 10,
    justifySelf: 'center',
  }),
  languageSwitcherContainer: css({
    '& ul': {
      bottom: 28,
    },
  }),
  termsAndConditions: css({
    display: 'flex',

    '& > a': {
      borderRight: '1px solid #dee5ec',
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subordinate,
      padding: '0 12px',

      '[dir="rtl"] &': {
        borderLeft: '1px solid #dee5ec',
        borderRight: 'unset',
      },
    },
  }),
};

export default Footer;
