import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import emptyChat from '@src/assets/img/chat/emptyChat.png';
import noLineConnected from '@src/assets/img/chat/noLineConnected.png';
import noLineConnected_broadcast from '@src/assets/img/chat/noLineConnected_broadcast.svg';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';

export const NoFansConnected = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.noChats}>
      <div css={styles.addFanWrapper}>
        <img src={emptyChat} css={styles.emptyChat} />
        <div css={styles.addFan}>
          <span className="plus">+</span>
          <span>{` ${t('Button.Add')}`}</span>
        </div>
      </div>

      <div css={styles.addFanParagraph}>
        <h3 className="heading">{t('Add friends to your Chat Media')}</h3>
        <p className="text">{t('As for now, there are no fans to show')}</p>
        <p className="text">{t('Add friends to your Chat account in order to display fans insight')}</p>
      </div>

      <Link to={ROUTES.MY_PAGE_FANS_ADD} css={styles.addFanLink}>
        {t('Add Fan')}
      </Link>
    </div>
  );
};
export const NoLineConnected_Broadcast = () => {
  const { t } = useTranslation();

  return (
    <div css={[styles.noChats, { margin: 'auto', width: 500 }]}>
      <div css={styles.addFanWrapper}>
        <img src={noLineConnected_broadcast} css={styles.emptyChat} />
      </div>

      <div css={styles.addFanParagraph}>
        <h3 className="heading">{t('Connect your LINE Official Account')}</h3>
        <p className="text">{t('To send the test broadcast, you need to connect your LINE Official')}</p>
        <p className="text">{t('Account to AnyCreator')}</p>
      </div>

      <Link to={ROUTES.MY_PAGE_CHANNEL_CONNECT} css={[styles.addFanLink, { height: 32 }]}>
        {t('Button.Connect LINE')}
      </Link>
    </div>
  );
};

export const NoLineConnected = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.noChats}>
      <div css={styles.addFanWrapper}>
        <img src={noLineConnected} css={styles.emptyChat} />
      </div>

      <div css={styles.addFanParagraph}>
        <h3 className="heading">{t('Add friends to your Chat')}</h3>
        <p className="text">{t('Please add Chat and connect account in order to')}</p>
        <p className="text">{t('see your fan’s insight')}</p>
      </div>

      <Link to={ROUTES.MY_PAGE_CHANNEL_CONNECT} css={styles.addFanLink}>
        {t('Button.Add Channel')}
      </Link>
    </div>
  );
};

const styles = {
  addFanWrapper: css({
    position: 'relative',
    alignSelf: 'center',
  }),
  noChats: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  }),
  emptyChat: css({
    margin: 'auto',
    width: 180,
    height: 180,
  }),
  addFan: css({
    position: 'absolute',
    padding: '0 9px',
    borderRadius: 16,
    backgroundColor: THEME.colors.blue.main,
    color: THEME.font.colors.white,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '140%',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, -16px)',

    span: {
      verticalAlign: 'text-bottom',

      '&.plus': {
        fontSize: THEME.font.sizes.heading,
      },
    },
  }),
  addFanParagraph: css({
    color: THEME.font.colors.black.main,
    marginTop: 24,
    padding: '0 16px',

    '.heading': {
      fontSize: THEME.font.sizes.normal,
      lineHeight: '120%',
      textAlign: 'center',
      marginBottom: 16,
    },

    '.text': {
      textAlign: 'center',
      fontSize: THEME.font.sizes.subordinate,
      lineHeight: '120%',
      color: THEME.font.colors.gray.main,
    },
  }),
  addFanLink: css({
    width: 165,
    margin: '24px auto',
    textAlign: 'center',
    border: THEME.box.borders.gray.main,
    backgroundColor: THEME.colors.white,
    height: 32,
    lineHeight: '32px',
    borderRadius: 3,
    fontWeight: 600,
    fontSize: THEME.font.sizes.subordinate,
    color: THEME.font.colors.gray.main,

    '&:hover': {
      borderColor: '#6e7c89',
    },
  }),
};
