import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import { useTranslation } from 'react-i18next';

const MAX_NUMBER_OF_TAGS_TO_SHOW = 3;

interface TagColumnProps {
  className?: string;
  tags: string[];
}

const TagColumn = (props: TagColumnProps) => {
  const { t } = useTranslation();
  const { className, tags } = props;
  const nonEmptyTags = tags.filter(item => item);

  return (
    <td className={className} css={{ padding: '0 8px' }}>
      <div css={{ alignItems: 'center', display: 'flex' }}>
        <div css={styles.tableData}>
          {nonEmptyTags.slice(0, MAX_NUMBER_OF_TAGS_TO_SHOW).map((item, index) => (
            <Tag key={`${item}${index}`}>{item}</Tag>
          ))}
          {nonEmptyTags.length > MAX_NUMBER_OF_TAGS_TO_SHOW && (
            <Tag css={{ backgroundColor: 'transparent' }}>
              +{t('Tags Count', { count: nonEmptyTags.length - MAX_NUMBER_OF_TAGS_TO_SHOW })}
            </Tag>
          )}
        </div>
      </div>
    </td>
  );
};

const Tag = styled.span({
  background: '#eef3f7',
  padding: 4,
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subordinate,
  borderRadius: 3,

  '&:not(:last-of-type)': {
    marginRight: 4,
  },
});

const styles = {
  tableData: css({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    fontSize: 13,
    lineHeight: '16px',
  }),
};

export default TagColumn;
