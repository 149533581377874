import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import readyToSignUpImg from '@src/assets/img/signup/readyToSignUpInstagram.png';
import sunRays from '@src/assets/img/signup/sunRays.png';
import { Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { JAPANESE_LANG } from '@src/libs/constant';
import { usePageLayout } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  AccountTypeAndTools,
  ConnectYourFacebookPage,
  EditProfile as MobileEditProfile,
  SelectFacebookPage,
  SettingsAndPrivacy,
  ShareLogins,
  SwitchProfessionalAccount,
} from './Mobile';
import { Completion, EditProfile, PressBusinessToProceed, SwitchToProAccount } from './Web';

interface InstructionsProps {
  backPath: string;
  failedConnect?: boolean;
  onClickConnect: () => void;
}

const Instructions = ({ backPath, failedConnect, onClickConnect }: InstructionsProps) => {
  const [currIndex, setCurrIndex] = useState<number>(0);
  const [isReadyToSignUp, setIsReadyToSignUp] = useState<boolean>(false);
  const { i18n, t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const isJPLanguage = i18n.language === JAPANESE_LANG;
  const isLinkIgToFbGuide = isMobileView && currIndex > 2;
  const contactUrl = isJPLanguage ? 'https://any-creator.com/ja/contact/' : 'https://any-creator.com/contact/';
  const currNumber = currIndex + 1;
  const instructions = isMobileView
    ? [
        {
          description: (
            <Info>
              1.&nbsp;
              <Trans components={{ 1: <b /> }} i18nKey="Annotation.Go to Settings and privacy" />
            </Info>
          ),
          instruction: <SettingsAndPrivacy />,
        },
        {
          description: (
            <Info>
              2.&nbsp;
              <Trans components={{ 1: <b /> }} i18nKey="Annotation.Go to Account type and tools" />
            </Info>
          ),
          instruction: <AccountTypeAndTools />,
        },
        {
          description: (
            <Info>
              3.&nbsp;
              <Trans
                components={{ 1: <b /> }}
                i18nKey="Annotation.Press Switch to professional account and select Business"
              />
            </Info>
          ),
          instruction: <SwitchProfessionalAccount />,
        },
        {
          description: (
            <Info>
              4.&nbsp;
              <Trans
                components={{ 1: <b /> }}
                i18nKey="Annotation.On the Share logins using Account Center page, tap [Continue] to link you Instagram account to link you Facebook page"
              />
            </Info>
          ),
          instruction: <ShareLogins />,
        },
        {
          description: (
            <>
              <Info>
                5.&nbsp;
                <Trans components={{ 1: <b /> }} i18nKey="Annotation.Create or connect your Facebook page" />
              </Info>
              <Hint>
                *
                <Trans
                  components={{ 1: <b /> }}
                  i18nKey="Annotation.If you already have a Facebook page, go to [Edit profile]"
                />
              </Hint>
            </>
          ),
          instruction: <ConnectYourFacebookPage />,
        },
        {
          description: (
            <Info>
              6.&nbsp;
              <Trans
                components={{ 1: <b /> }}
                i18nKey="Annotation.Follow the flow and select a Facebook page you want to connect to your Instagram account"
              />
            </Info>
          ),
          instruction: <SelectFacebookPage />,
        },
        {
          description: (
            <>
              <Hint>
                *
                <Trans i18nKey="Annotation.Even if you mistakenly skip this flow, you can connect Facebook page following this way" />
              </Hint>
              <div>
                <Info>
                  1.&nbsp;
                  <Trans components={{ 1: <b /> }} i18nKey={'Annotation.Go to Edit Profile'} />
                </Info>
                <Info>2.&nbsp;{t('Annotation.Connect or create Facebook page')}</Info>
              </div>
            </>
          ),
          instruction: <MobileEditProfile />,
        },
      ]
    : [
        {
          description: <Info>{`${currNumber}. ${t('Annotation.Press "edit profile"')}`}</Info>,
          instruction: <EditProfile />,
        },
        {
          description: <Info>{`${currNumber}. ${t('Annotation.Press "switch to pro account”')}`}</Info>,
          instruction: <SwitchToProAccount />,
        },
        {
          description: (
            <Info>{`${currNumber}. ${t('Annotation.follow the steps and press "Business" to proceed')}`}</Info>
          ),
          instruction: <PressBusinessToProceed />,
        },
        {
          description: <Info>{`${currNumber}. ${t('Completed!')}`}</Info>,
          instruction: <Completion />,
        },
        {
          description: (
            <>
              <Info>{`${currNumber}. ${t('Annotation.Now ready to Sign up!')}`}</Info>
              <Hint css={{ marginTop: 12 }}>
                {t('Annotation.Advance preparations are all done, now you can connect your Instagram to AnyCreator')}
              </Hint>
            </>
          ),
          instruction: <img height="210" src={readyToSignUpImg} width="368" />,
        },
      ];
  const { description, instruction } = instructions[currIndex] || { description: '', instruction: '' };

  useEffect(() => {
    setCurrIndex(0);
    setIsReadyToSignUp(false);
  }, [isMobileView]);

  const onClickBack = () => {
    if (isReadyToSignUp) {
      setIsReadyToSignUp(false);
    } else {
      setCurrIndex(index => index - 1);
    }
  };

  const onClickNext = () => {
    if (currIndex < instructions.length - 1) {
      setCurrIndex(index => index + 1);
    } else {
      if (isMobileView && !isReadyToSignUp) {
        setIsReadyToSignUp(true);
      } else {
        onClickConnect();
      }
    }
  };

  const actionButtons = (
    <div css={styles.actionButtonsContainer}>
      {!!currIndex && <ThemeButton text="Back" onClick={onClickBack} />}
      <ThemeButton
        customPalette={mainBlack}
        text={(!isMobileView && currIndex === instructions.length - 1) || isReadyToSignUp ? 'Connect' : 'Next'}
        onClick={onClickNext}
      />
    </div>
  );

  const contactSupport = (
    <div css={styles.contactSupportContainer}>
      <Trans
        components={{ 1: <Link target="_blank" to={contactUrl} /> }}
        i18nKey="If you need support, please contact from here"
      />
    </div>
  );

  return (
    <div css={styles.container}>
      {isMobileView ? (
        isReadyToSignUp ? (
          <div css={styles.readyToSignUpMobileContainer}>
            <div>
              <div>
                <div>
                  <Icomoon color="#fff" icon="star" size={36} />
                </div>
              </div>
              <div>{t('Title.Now ready to Sign up')}</div>
              <div>
                {t('Annotation.Advance preparations are all done, now you can connect your Instagram to AnyCreator')}
              </div>
            </div>
            <div>
              {actionButtons}
              {contactSupport}
            </div>
          </div>
        ) : (
          <>
            <Title css={{ justifyContent: 'center' }}>
              <Icomoon icon="instagram-color-light" size={16} />
              <div>{t(failedConnect ? `Instagram couldn't be found` : 'Title.How to link Instagram account')}</div>
            </Title>
            <Description>
              {t(
                isLinkIgToFbGuide
                  ? 'Annotation.Link you Instagram account to the Facebook page'
                  : `Annotation.Instagram couldn't be found`
              )}
            </Description>
            <div css={styles.instructionContainer}>
              <div>{instruction}</div>
            </div>
            <MobileInfoContainer isLinkIgToFbGuide={isLinkIgToFbGuide}>
              {/* description is dynamic, to put key so that crowdin was able to switch the translation key */}
              <div key={currIndex}>{description}</div>
              <div>
                {instructions.map((_i, index) => (
                  <Dot key={index} isActive={index === currIndex} onClick={() => setCurrIndex(index)} />
                ))}
              </div>
              {actionButtons}
              {contactSupport}
            </MobileInfoContainer>
          </>
        )
      ) : (
        <>
          <div css={styles.titleContainer}>
            <Link to={backPath}>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={12} />
            </Link>
            <Title>
              <Icomoon icon="instagram-color-light" size={16} />
              <div>{t(failedConnect ? `Instagram couldn't be found` : 'Title.How to link Instagram account')}</div>
            </Title>
            <Description>
              {t(
                isLinkIgToFbGuide
                  ? 'Annotation.Link you Instagram account to the Facebook page'
                  : `Annotation.Instagram couldn't be found`
              )}
            </Description>
            {contactSupport}
          </div>
          <div css={styles.webContentContainer}>
            <div>
              <div css={{ marginBottom: 16 }} key={currIndex}>
                {description}
              </div>
              <div>{instruction}</div>
              <div>{actionButtons}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Description = styled.div({
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.normal,
  marginBottom: 24,
});

const Dot = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  background: isActive ? '#27313b' : '#dee5ec',
  borderRadius: '50%',
  cursor: 'pointer',
  height: 8,
  width: 8,
}));

const Hint = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,

  '& b': {
    fontWeight: 600,
  },
});

const Info = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subHeading,

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
  },

  '& b': {
    fontWeight: 600,
  },
});

const MobileInfoContainer = styled.div<{ isLinkIgToFbGuide: boolean }>(({ isLinkIgToFbGuide }) => ({
  '--header': '64px',
  '--title': '22px',
  '--description': isLinkIgToFbGuide ? '17px' : '34px',
  '--instruction': '302px',
  '--paddingBottomTop': '16px',

  background: THEME.colors.white,
  minHeight:
    'calc(100% - var(--header) - var(--title) - var(--description) - var(--instruction) - (var(--paddingBottomTop) * 2))',
  left: 0,
  padding: 'var(--paddingBottomTop) 24px',
  position: 'absolute',
  width: 'fill-available',

  '& > div:nth-of-type(1)': {
    alignItems: 'center',
    display: 'grid',
    height: 125,
    justifyContent: 'center',
  },

  '& > div:nth-of-type(2)': {
    display: 'flex',
    gap: 6,
    justifyContent: 'center',
  },
}));

const Title = styled.div({
  alignItems: 'center',
  color: THEME.font.colors.black.main,
  display: 'flex',
  fontSize: THEME.font.sizes.heading,
  fontWeight: 600,
  gap: THEME.box.gaps.s,
  marginBottom: 16,
});

const styles = {
  actionButtonsContainer: css({
    display: 'flex',
    gap: THEME.box.gaps.xxl,
    margin: '32px 0',

    '& > button': {
      borderRadius: 9,
      height: 48,
    },
  }),
  contactSupportContainer: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,

    '& > a': {
      color: THEME.font.colors.blue.main,
      display: 'inline',
    },
  }),
  container: css({
    '--header': '64px',
    '--paddingTop': '16px',

    height: 'calc(100vh - var(--header) - var(--paddingTop))',
    margin: 0,
    padding: 'var(--paddingTop) 24px 0 24px',
    position: 'relative',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      height: 472,
      width: 778,
      padding: '24px 40px',
    },
  }),
  infoContainer: css({
    alignItems: 'center',
    display: 'grid',
    gap: THEME.box.gaps.s,
    minHeight: 66,
    paddingBottom: 16,
  }),
  instructionContainer: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  }),
  readyToSignUpContainer: css({
    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 12,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 8,
    },
  }),
  readyToSignUpMobileContainer: css({
    alignContent: 'space-between',
    display: 'grid',
    height: 'calc(100% - 89px)',
    padding: '65px 0 24px 0',

    '& > div:nth-of-type(1)': {
      background: THEME.colors.white,
      display: 'grid',
      gap: THEME.box.gaps.s,
      padding: '24px 20px',
      textAlign: 'center',

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        backgroundImage: `url(${sunRays})`,
        backgroundSize: '92px 92px',
        display: 'flex',
        height: 92,
        justifyContent: 'center',
        justifySelf: 'center',
        width: 92,

        '& > div': {
          alignItems: 'center',
          background: '#ed6a65',
          borderRadius: '50%',
          display: 'flex',
          height: 64,
          justifyContent: 'center',
          width: 64,
        },
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
      },

      '& > div:nth-of-type(3)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  titleContainer: css({
    width: 'fill-available',

    '& > a': {
      margin: '22px 0 30px 9px',

      '& > svg': {
        '[dir="rtl"] &': {
          transform: 'rotate(270deg)',
        },
      },
    },

    '& > div:nth-of-type(1)': {
      marginBottom: 16,
    },

    '& > div:nth-of-type(2)': {
      marginBottom: 240,
      maxWidth: 328,
    },
  }),
  webContentContainer: css({
    alignItems: 'center',
    display: 'grid',
    height: '100%',
    width: 'fill-available',
  }),
};

export default Instructions;
