import React, { useEffect, useState, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { CheckBox, Icomoon } from '@src/components/atoms';
import { ViewportType } from '@src/libs/types';
import { Accordion, Drawer, ThemeButton } from '@src/components/molecules';
import { MultiTagsSearchSelector, TextForm } from '@src/components/shared';
import { FilterByDateRange } from '@src/__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { FanListFilterType } from '../types';
import DateOptionGroup from './DateOptionGroup';
import { FormValues } from './types';
import { getFanFilterCount } from './helper';

const dateGroupOptions = [
  { label: 'Last 7 days', value: FilterByDateRange.LAST_7_DAYS },
  { label: 'Last 30 days', value: FilterByDateRange.LAST_30_DAYS },
  { label: 'Last 90 days', value: FilterByDateRange.LAST_90_DAYS },
  { label: 'Last 12 months', value: FilterByDateRange.LAST_12_MONTHS },
];

interface MoreFiltersProps {
  filter: FanListFilterType;
  onSubmit: (information: FormValues) => void;
}

export const MoreFilters = ({ filter, onSubmit }: MoreFiltersProps) => {
  const [isDrawerMoreFilter, setIsDrawerMoreFilter] = useState<boolean>(false);
  const { t } = useTranslation();
  const { setGlobalLayout } = useGlobalLayout();

  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useFormContext<FormValues>();
  const [createdDate, checkedFields, lastOrderDate, tags] = watch([
    'createdDate',
    'checkedFields',
    'lastOrderDate',
    'tags',
  ]);

  useEffect(() => {
    setGlobalLayout({ isMenuOpen: isDrawerMoreFilter });
  }, [isDrawerMoreFilter]);

  const handleClearAllOrders = () => {
    setValue('minNumberOfOrders', '');
    setValue('maxNumberOfOrders', '');
    setValue('exactNumberOfOrders', '');
    setValue('checkedFields', {
      ...checkedFields,
      ...{ minNumberOfOrders: false, maxNumberOfOrders: false, exactNumberOfOrders: false },
    });
  };
  const handleClickClearCreatedDate = () => {
    setValue('createdDate', null);
  };

  const handleClickClearLastOrderDate = () => {
    setValue('lastOrderDate', null);
  };

  const handleClearAllAmount = () => {
    setValue('minAmountSpent', '');
    setValue('maxAmountSpent', '');
    setValue('exactAmountSpent', '');
    setValue('checkedFields', {
      ...checkedFields,
      ...{ minAmountSpent: false, maxAmountSpent: false, exactAmountSpent: false },
    });
  };
  const handleClickOk = () => {
    setIsDrawerMoreFilter(false);
    handleSubmit(onSubmit)();
  };

  const filterCount = getFanFilterCount(filter);

  return (
    <Fragment>
      <Drawer
        actionContent={
          <div css={styles.footer}>
            <ThemeButton text="Cancel" width="fit-content" onClick={() => setIsDrawerMoreFilter(false)} />
            <ThemeButton disabled={isSubmitting} text="OK" theme="blue" width="fit-content" onClick={handleClickOk} />
          </div>
        }
        height="90%"
        open={isDrawerMoreFilter}
        onClose={() => setIsDrawerMoreFilter(false)}
      >
        <div css={styles.container}>
          <div>
            <div css={styles.heading}>
              <p css={styles.headingText}>{t('More Filters')}</p>
            </div>
            <Accordion
              type="multiple"
              css={styles.summary}
              defaultValue={[
                ...(createdDate ? ['createdDate'] : []),
                ...(lastOrderDate ? ['lastOrderDate'] : []),
                ...(checkedFields.minAmountSpent || checkedFields.maxAmountSpent || checkedFields.exactAmountSpent
                  ? ['amount-spent']
                  : []),
                ...(checkedFields.minNumberOfOrders ||
                checkedFields.maxNumberOfOrders ||
                checkedFields.exactNumberOfOrders
                  ? ['number-of-orders']
                  : []),
                ...(tags.length ? ['tags'] : []),
              ]}
              items={[
                {
                  trigger: { children: <h2 css={styles.title}>{t('Created Date')}</h2> },
                  value: 'createdDate',
                  content: (
                    <DateOptionGroup
                      items={dateGroupOptions}
                      value={createdDate}
                      onClear={handleClickClearCreatedDate}
                      onChange={val => setValue('createdDate', val)}
                    />
                  ),
                },
                // Last Order Date
                {
                  trigger: { children: <h2 css={styles.title}>{t('Last Order Date')}</h2> },
                  value: 'lastOrderDate',
                  content: (
                    <DateOptionGroup
                      items={dateGroupOptions}
                      value={lastOrderDate}
                      onClear={handleClickClearLastOrderDate}
                      onChange={val => setValue('lastOrderDate', val)}
                    />
                  ),
                },
                //  Amount Spent
                {
                  trigger: { children: <h2 css={styles.title}>{t('Amount Spent')}</h2> },
                  value: 'amount-spent',
                  content: (
                    <div css={{ display: 'flex', flexDirection: 'column' }}>
                      {/* Min */}
                      <CheckBox
                        label="Amount.More than this number"
                        checked={checkedFields.minAmountSpent}
                        onChange={checked => {
                          setValue('checkedFields', {
                            ...checkedFields,
                            minAmountSpent: checked,
                          });
                        }}
                      />
                      {checkedFields.minAmountSpent && (
                        <TextForm css={styles.textForm} placeholder="¥ 0" {...register('minAmountSpent')} />
                      )}
                      {/* Max */}
                      <CheckBox
                        label="Amount.Less than this number"
                        checked={checkedFields.maxAmountSpent}
                        onChange={checked => {
                          setValue('checkedFields', {
                            ...checkedFields,
                            maxAmountSpent: checked,
                          });
                        }}
                      />
                      {checkedFields.maxAmountSpent && (
                        <TextForm css={styles.textForm} placeholder="¥ 0" {...register('maxAmountSpent')} />
                      )}
                      {/* Exact */}
                      <CheckBox
                        label="Amount.This exact amount"
                        checked={checkedFields.exactAmountSpent}
                        onChange={checked => {
                          setValue('checkedFields', {
                            ...checkedFields,
                            exactAmountSpent: checked,
                          });
                        }}
                      />
                      {checkedFields.exactAmountSpent && (
                        <TextForm css={styles.textForm} placeholder="¥ 0" {...register('exactAmountSpent')} />
                      )}
                      <p css={styles.clearBtn} onClick={handleClearAllAmount}>
                        {t('Clear')}
                      </p>
                    </div>
                  ),
                },
                // Number of Order
                {
                  trigger: { children: <h2 css={styles.title}>{t('Number of Order')}</h2> },
                  value: 'number-of-orders',
                  content: (
                    <div css={{ display: 'flex', flexDirection: 'column' }}>
                      {/* Min */}
                      <CheckBox
                        label="OrderCount.More than this number"
                        checked={checkedFields.minNumberOfOrders}
                        onChange={checked => {
                          setValue('checkedFields', {
                            ...checkedFields,
                            minNumberOfOrders: checked,
                          });
                        }}
                      />
                      {checkedFields.minNumberOfOrders && (
                        <TextForm css={styles.textForm} placeholder="0" {...register('minNumberOfOrders')} />
                      )}
                      {/* Max */}
                      <CheckBox
                        label="OrderCount.Less than this number"
                        checked={checkedFields.maxNumberOfOrders}
                        onChange={checked => {
                          setValue('checkedFields', {
                            ...checkedFields,
                            maxNumberOfOrders: checked,
                          });
                        }}
                      />
                      {checkedFields.maxNumberOfOrders && (
                        <div>
                          <TextForm css={styles.textForm} placeholder="0" {...register('maxNumberOfOrders')} />
                        </div>
                      )}
                      {/* Exact */}
                      <CheckBox
                        label="OrderCount.This exact amount"
                        checked={checkedFields.exactNumberOfOrders}
                        onChange={checked => {
                          setValue('checkedFields', {
                            ...checkedFields,
                            exactNumberOfOrders: checked,
                          });
                        }}
                      />
                      {checkedFields.exactNumberOfOrders && (
                        <TextForm css={styles.textForm} placeholder="0" {...register('exactNumberOfOrders')} />
                      )}
                      <p css={styles.clearBtn} onClick={handleClearAllOrders}>
                        {t('Clear')}
                      </p>
                    </div>
                  ),
                },
                // Tags
                {
                  trigger: { children: <h2 css={styles.title}>{t('Tags')}</h2> },
                  value: 'tags',
                  content: (
                    <div css={styles.tagSelector}>
                      <MultiTagsSearchSelector
                        placeholder="VIP,etc"
                        isCreatable={false}
                        // @ts-ignore type is TagsValue[]
                        value={tags}
                        setFieldValue={value => setValue('tags', value)}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Drawer>
      <ThemeButton
        text="More filters"
        width="max-content"
        css={[styles.button, filterCount ? styles.filterIcon : undefined]}
        prefixIcon={<Icomoon color={filterCount ? '#3992e5' : '#6e7c89'} icon="filter" size={18} />}
        postfixIcon={
          filterCount ? (
            <div css={styles.filterCount}>
              <span>{filterCount}</span>
            </div>
          ) : undefined
        }
        onClick={() => setIsDrawerMoreFilter(true)}
      />
    </Fragment>
  );
};
MoreFilters.displayName = 'MoreFilters';

const styles = {
  title: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  form: css({
    height: '100%',
  }),
  submitBtn: css({
    width: 'fit-content',
    lineHeight: '32px',
    borderRadius: 3,
    padding: '0 24px',
  }),
  summary: css({
    height: '48px !important',
    minHeight: '48px !important',
    padding: '0 !important',
  }),
  heading: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderBottom: '1px solid #dee5ec',
  }),
  headingText: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.heading,
    color: THEME.font.colors.black.main,
  }),
  label: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
  }),
  container: css({
    width: 336,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  }),
  button: css({
    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      display: 'flex',
      flex: 1,
    },
  }),
  footer: css({
    backgroundColor: '#f6f8fa',
    padding: '24px 24px 24px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: THEME.box.gaps.s,

    "[dir='rtl'] &": {
      padding: '24px 0 24px 24px',
    },
  }),
  expansion: css({
    backgroundColor: 'red',

    '&.MuiExpansionPanel-root': {
      position: 'unset',
    },

    '& .MuiExpansionPanelSummary-expandIcon': {
      marginRight: '-8px !important',
    },

    '&::before': {
      height: 0,
    },

    '&:last-of-type': {
      borderBottom: 'none',
    },
  }),
  textForm: css({
    width: 240,
    margin: '0 0 16px 26px',

    '& input': {
      borderRadius: 3,
      height: 32,
    },

    label: {
      paddingLeft: 0,
    },
  }),
  clearBtn: css({
    margin: '8px 0',
    color: THEME.font.colors.blue.main,
    fontSize: THEME.font.sizes.normal,
    cursor: 'pointer',
  }),
  filterCount: css({
    width: 24,
    lineHeight: '24px',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: THEME.font.sizes.subordinate,
    backgroundColor: THEME.colors.white,
    fontWeight: 600,
  }),
  filterIcon: css({
    borderColor: '#3892e5',
    backgroundColor: '#ecf3fb',

    '.btn-text': {
      padding: 0,
      color: THEME.font.colors.blue.main,
    },
  }),
  tagSelector: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 40,
  }),
};
