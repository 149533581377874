import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import RegionSelector from '@src/components/organisms/RegionSelector';
import { Label, Select, SentenceForm, TextForm, YearMonthDayPicker } from '@src/components/shared';
import { GENDERS } from '@src/libs/constant';
import {
  useAuthData,
  useAllAvailableCountries,
  useAllCountries,
  useInfluencerCategories,
  useTranslateOptions,
} from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { Genders } from '@src/__generated__/globalTypes';
import { FormSubmitSection } from '@src/components/shared';

export interface AccountInformation {
  brandName: string | null;
  categoryIds: number[];
  countryId: string;
  dateOfBirth: string;
  gender: Genders;
  introduce: string;
  name: string;
  regionId: string | null;
}

interface CreatorAccountProps {
  isLoading: boolean;
}

const CreatorAccount = ({ isLoading }: CreatorAccountProps) => {
  const { isAvailableCountry } = useAuthData();
  const { t } = useTranslation();
  const { influencerCategories } = useInfluencerCategories();
  const translatedCategories = useTranslateOptions(influencerCategories);
  const translatedGenders = useTranslateOptions(GENDERS);
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<AccountInformation>();
  const [
    // brandNameValue,
    categoryIdsValue,
    countryIdValue,
    dateOfBirthValue,
    genderValue,
    regionIdValue,
  ] = watch([
    // 'brandName',
    'categoryIds',
    'countryId',
    'dateOfBirth',
    'gender',
    'regionId',
  ]);
  const isRegionRequired = countryIdValue !== 'US' && isAvailableCountry;
  const { allTranslatedCountries: translatedCountries } = isRegionRequired
    ? useAllAvailableCountries()
    : useAllCountries();

  return (
    <div css={styles.formContainer}>
      <div css={{ maxWidth: 648, width: '100%' }}>
        {isLoading ? (
          <ListLoading />
        ) : (
          <>
            <div css={styles.formInputSection}>
              <div css={styles.formTitle}>{t('Title.Creator Account')}</div>
              <div css={styles.formDescription}>
                {t('Annotation.Edit your information and set notifications to receive')}
              </div>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.name}
                  isRequired
                  title="Creator Name"
                  placeholder="Creator Name"
                  {...register('name')}
                />
                <ErrorMessage message={errors.name?.message} />
              </InputContainer>

              {/* <InputContainer>
                <StyledTextForm
                  error={!!errors.brand}
                  isRequired
                  title="Brand Name"
                  placeholder="Brand Name"
                  {...register('brandName')}
                />
                <ErrorMessage message={errors.brandName?.message} />
              </InputContainer> */}

              <InputContainer>
                <Select
                  error={!!errors.categoryIds}
                  isRequired
                  multiple
                  options={translatedCategories}
                  title="Categories"
                  value={categoryIdsValue.map(id => id.toString()) || []}
                  onChange={value =>
                    setValue(
                      'categoryIds',
                      value.map(val => Number(val))
                    )
                  }
                />
                <ErrorMessage message={errors.categoryIds?.message} />
              </InputContainer>

              <div css={styles.dobpickerContainer}>
                <Label isRequired title="Selector.Date of Birth" />
                <YearMonthDayPicker
                  format="yyyy-MM-dd"
                  error={!!errors.dateOfBirth}
                  maxDate={new Date()}
                  value={dateOfBirthValue || ''}
                  onChange={value => setValue('dateOfBirth', value as string)}
                />
                <ErrorMessage message={errors.dateOfBirth?.message} />
              </div>

              <InputContainer>
                <Select
                  disabled
                  error={!!errors.countryId}
                  isRequired
                  options={translatedCountries}
                  title="Country"
                  value={countryIdValue || ''}
                  onChange={value => setValue('countryId', value)}
                />
                <ErrorMessage message={errors.countryId?.message} />
              </InputContainer>

              <InputContainer>
                <RegionSelector
                  countryId={countryIdValue}
                  error={!!errors.regionId}
                  isRequired={isRegionRequired}
                  title="Region"
                  value={regionIdValue || ''}
                  onChange={value => setValue('regionId', value)}
                />
                <ErrorMessage message={errors.regionId?.message} />
              </InputContainer>

              <InputContainer>
                <Select
                  disabled
                  error={!!errors.gender}
                  isRequired
                  options={translatedGenders}
                  title="Gender"
                  value={genderValue || ''}
                  onChange={value => setValue('gender', value as Genders)}
                />
                <ErrorMessage message={errors.gender?.message} />
              </InputContainer>

              <InputContainer>
                <StyledSentenceForm placeholder="Introduction" title="Introduction" {...register('introduce')} />
              </InputContainer>
            </div>

            <FormSubmitSection isSubmitting={isSubmitting} />
          </>
        )}
      </div>
    </div>
  );
};

const InputContainer = styled.div({
  marginBottom: 24,
});

const StyledSentenceForm = styled(SentenceForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& > textarea': {
    borderRadius: 2,
  },
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& input': {
    borderRadius: 2,
    height: 32,
  },
});

const styles = {
  container: css({
    margin: '32px 80px',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      margin: '32px 16px',
    },
  }),
  dobpickerContainer: css({
    marginBottom: 16,
    width: '60%',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      width: '100%',
    },

    '& > label': {
      fontSize: THEME.font.sizes.normal,
    },

    '& > div > div > div': {
      borderRadius: 0,
      minHeight: 32,

      '& > div > input': {
        minHeight: 32,
      },
    },
  }),
  formContainer: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  formDescription: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
  }),
  formInputSection: css({
    backgroundColor: THEME.colors.white,
    padding: 24,
  }),
  formTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 8,
  }),
  titleContainer: css({
    alignItems: 'center',
    display: 'flex',

    '& > a': {
      backgroundColor: THEME.colors.white,
      boxShadow: THEME.box.shadows.outer,
      display: 'flex',
      height: 32,
      justifyContent: 'center',
      width: 32,

      '& > i': {
        paddingTop: 2,
      },
    },

    '& > div': {
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginLeft: 16,
    },
  }),
};

export default CreatorAccount;
