import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icomoon, TextCutter } from '@src/components/atoms';
import Popover, { PopoverPrimitives } from '@src/components/atoms/Popover';
import { SearchForm } from '@src/components/shared';
import { css } from '@emotion/react';
import { CRMChatTypeFilter } from '@src/__generated__/globalTypes';
import useDimensions from '@src/libs/hooks/useDimensions';
import { checkmarkCode } from '@src/assets/htmlCodes';
import { useDirLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ChatFilterFormValues, LineChatCountsType } from '../types';

export interface ChatListFilterProps extends LineChatCountsType {
  onKeywordSearch: (keyword: string) => void;
  filter: ChatFilterFormValues;
  setFilter: React.Dispatch<React.SetStateAction<ChatFilterFormValues>>;
  disabled?: boolean;
}

const ChatListFilter = ({
  totalNum = 0,
  assignedToMeNum = 0,
  setFilter,
  onKeywordSearch,
  filter: { type, keyword },
  disabled,
}: ChatListFilterProps) => {
  const [showSearch, setShowSearch] = useState(false);
  const { t } = useTranslation();
  const filterRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDimensions(filterRef);
  const { isRtl } = useDirLayout();

  const options = [
    { value: CRMChatTypeFilter.ASSIGNED_TO_ME, label: 'Assigned to me', count: assignedToMeNum },
    { value: CRMChatTypeFilter.ACTIVATED, label: 'All', count: totalNum },
  ];

  const selectedOption = options.find(opt => opt.value === type);

  return (
    <div css={styles.wrapper} className={disabled ? 'disabled' : ''}>
      <div css={{ flex: 1 }} ref={filterRef}>
        {showSearch ? (
          <SearchForm
            placeholder={t('search')}
            autoFocus
            value={keyword}
            onChange={e => {
              const inputText = e.target.value;
              setFilter(prevState => ({ ...prevState, keyword: inputText }));
              onKeywordSearch(inputText);
            }}
          />
        ) : (
          <Popover
            renderTrigger={
              <div css={styles.popupTrigger} className={disabled ? 'disabled' : ''}>
                <Icomoon css={styles.filterDecs} icon="filter" size={18} />
                {selectedOption ? (
                  <TextCutter
                    lines={1}
                    text={`${t(selectedOption.label)} (${selectedOption.count})`}
                    css={styles.filterText}
                  />
                ) : (
                  '-'
                )}
                <Icomoon className="arrow-down" css={[styles.arrowDown]} icon="arrow-down" size={10} />
              </div>
            }
            side="bottom"
            align={isRtl ? 'end' : 'start'}
          >
            <div css={{ width: `${width}px` }} tabIndex={0}>
              {options.map(option => {
                const isSelected = option.value === type;

                return (
                  <PopoverPrimitives.Close
                    key={option.value}
                    css={[styles.popupItem, isSelected ? { backgroundColor: '#f6f8fa' } : '']}
                    onClick={() => {
                      setFilter(prevState => ({ ...prevState, type: option.value }));
                    }}
                  >
                    <div css={styles.checkmark}>{isSelected ? checkmarkCode : null}</div>
                    <div css={[styles.optionText]}>
                      {/* {option.status ? <span css={styles.optionStatus}>{t(option.status)}</span> : null} */}
                      <span
                        css={[
                          styles.optionLabel,
                          isSelected
                            ? {
                                color: THEME.font.colors.blue.main,
                                fontWeight: 600,
                              }
                            : {},
                        ]}
                      >
                        {`${t(option.label)} (${option.count})`}
                      </span>
                    </div>
                  </PopoverPrimitives.Close>
                );
              })}
            </div>
          </Popover>
        )}
      </div>
      <div css={styles.iconBox} className={disabled ? 'disabled' : ''}>
        {showSearch ? (
          <Icomoon
            color="#808080"
            icon="clear"
            size={8}
            onClick={() => {
              setShowSearch(false);
              setFilter(prevState => ({ ...prevState, keyword: '' }));
              onKeywordSearch('');
            }}
          />
        ) : (
          <Icomoon icon="search" onClick={() => setShowSearch(true)} />
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    padding: 8,
    columnGap: 8,
    backgroundColor: THEME.colors.white,

    '&.disabled': {
      opacity: 0.3,
      backgroundColor: '#c5d0da',
    },
  }),
  popupTrigger: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    border: THEME.box.borders.gray.main,
    boxSizing: 'border-box',
    borderRadius: 3,
    backgroundColor: THEME.colors.white,

    '&.disabled': {
      pointerEvents: 'none',
      backgroundColor: 'inherit',
    },

    '&[data-state="open"]': {
      '.arrow-down': {
        transform: 'rotate(180deg)',
      },
    },
  }),
  filterDecs: css({
    margin: '0 10px 0 6px',
  }),
  filterText: css({
    fontWeight: 'normal',
    fontSize: THEME.font.sizes.normal,
    lineHeight: '16px',
    color: THEME.font.colors.black.main,
  }),
  checkmark: css({
    color: THEME.font.colors.blue.main,
    width: 24,
    textAlign: 'center',
  }),
  optionText: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  optionLabel: css({
    fontSize: THEME.font.sizes.subordinate,
    lineHeight: '18px',
    fontWeight: 400,
  }),
  optionStatus: css({
    fontSize: THEME.font.sizes.hint,
    color: THEME.font.colors.gray.main,
  }),
  popupItem: css({
    display: 'flex',
    alignItems: 'center',
    height: 48,
    paddingRight: 16,
    cursor: 'pointer',
    width: '100%',

    '&:hover': {
      backgroundColor: '#dee5ec',
    },

    '[dir="rtl"] &': {
      padding: '0 0 0 16px',
    },
  }),
  iconBox: css({
    alignItems: 'center',
    display: 'flex',
    width: 32,
    height: 32,
    background: THEME.colors.white,
    border: THEME.box.borders.gray.main,
    boxSizing: 'border-box',
    borderRadius: 3,
    justifyContent: 'center',

    '&.disabled': {
      pointerEvents: 'none',
      backgroundColor: 'inherit',
    },

    '&:hover': {
      borderColor: 'lightgrey',
      cursor: 'pointer',
    },
  }),
  arrowDown: css({
    marginLeft: 'auto',
    marginRight: 8,
    transition: 'all 0.2s ease-in-out',

    '[dir="rtl"] &': {
      marginRight: 'auto',
      marginLeft: 8,
    },
  }),
};

export default ChatListFilter;
