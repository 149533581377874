import { useState } from 'react';
import { useGetLineChannelsQuery } from '@src/graphql/hooks';

export type LineConnectStatus = 'pending' | 'connectLine' | 'connected';

const useGetLineChannels = () => {
  const [lineConnectStatus, setLineConnectStatus] = useState<LineConnectStatus>('pending');

  const { data, loading } = useGetLineChannelsQuery({
    onCompleted: ({ getLineChannels }) => {
      if (!getLineChannels?.id) {
        setLineConnectStatus('connectLine');
      } else {
        setLineConnectStatus('connected');
      }
    },
  });

  return {
    data,
    loading,
    lineConnectStatus,
    hasNoLineConnected: lineConnectStatus !== 'connected',
  };
};

export default useGetLineChannels;
