import styled from '@emotion/styled';
import List from '@src/components/atoms/List';
import { THEME } from '@src/libs/theme';
import SliderComponents from './BaseComponents';

const StyledStickyWrapper = styled(SliderComponents.StickyWrapper)({
  width: 200,
  backgroundColor: '#f6f8fa',
});

const StickyTableRow = styled(SliderComponents.TitleWrapper)({
  height: 72,
  display: 'flex',
  padding: 0,
  flexDirection: 'row',
  justifyContent: 'unset',
});

const ButtonWrapper = styled.div({
  display: 'flex;',
  alignItems: 'center',
  minHeight: 35,
  margin: '0 23px 0',
  padding: '8px 15px',
  backgroundColor: THEME.colors.white,
  border: THEME.box.borders.gray.main,
  borderTop: 'none',
  borderBottom: 'none',
});

const StyledRow = styled(SliderComponents.Row)({
  height: 64,

  td: {
    borderTop: '1px solid #efefef',
    padding: '0 8px',
  },
});

const StyledRowNew = styled.tr<{ isRowChecked?: boolean }>(({ isRowChecked }) => ({
  height: 64,

  td: {
    backgroundColor: !!isRowChecked ? '#fffde7' : '#fff',
    borderTop: '1px solid #efefef',
    padding: '0 8px',
  },

  '&:hover': {
    backgroundColor: '#f6f8fa',

    td: {
      backgroundColor: '#f6f8fa',
    },
  },

  'a:hover': {
    color: '#179cd7',
    textDecoration: 'underline',
  },
}));

const NumberColumn = styled(List.TextColumn)({
  '& div': {
    justifyContent: 'flex-end',
  },
});

const DateColumn = styled(List.DateColumn)({});

const StyledHeaderColumn = styled(List.HeaderColumn)({
  padding: 0,
  verticalAlign: 'bottom',

  '& > div': {
    paddingLeft: 16,
    height: 44,
    borderTop: '1px solid #dee5ec',
  },
});

const AbsoluteTableHeaderRow = styled.th({
  position: 'absolute',
  top: 5,
  zIndex: 2,
  fontSize: 11,
  fontWeight: 600,
});

const AbsoluteTableHeaderDiv = styled.div({
  position: 'absolute',
  top: -12,
  left: 3,
  zIndex: 2,
  fontSize: 11,
  fontWeight: 600,
});

export default {
  StyledStickyWrapper,
  StickyTableRow,
  ButtonWrapper,
  StyledRow,
  NumberColumn,
  DateColumn,
  StyledRowNew,
  StyledHeaderColumn,
  AbsoluteTableHeaderRow,
  AbsoluteTableHeaderDiv,
};
