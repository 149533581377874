import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/molecules';
import { THEME } from '@src/libs/theme';

interface PromotionMethodConnectorProps {
  items: (string | null | undefined)[];
  title: string;
  to: string;
}

const PromotionMethodConnector = ({ items, title, to }: PromotionMethodConnectorProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      {items.length > 0 ? (
        <div>
          {items.map((item, index) => (
            <div css={styles.methodContainer} key={index}>
              <div>{t(title)}</div>
              <div>{item}</div>
            </div>
          ))}
          <div css={styles.addBtnContainer}>
            <ThemeButton to={to} skipTranslation text={t<string>(`Add Method`, { method: t(title) })} />
          </div>
        </div>
      ) : (
        <div css={styles.emptyMethodContainer}>
          <div>{t(title)}</div>
          <ThemeButton to={to} skipTranslation text={t<string>(`Add Method`, { method: t(title) })} />
        </div>
      )}
    </div>
  );
};

const styles = {
  addBtnContainer: css({
    padding: 16,

    '& > a': {
      minWidth: 160,
      padding: '0 8px',
      width: 'fit-content',

      '& > div > span': {
        fontSize: THEME.font.sizes.subordinate,
        padding: 0,
      },
    },
  }),
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    marginBottom: 16,
  }),
  emptyMethodContainer: css({
    alignItems: 'center',
    display: 'flex',
    padding: 16,

    '& > div': {
      color: THEME.font.colors.black.main,
      display: 'flex',
      flex: 1,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },

    '& > a': {
      minWidth: 116,
      padding: '0 8px',
      width: 'fit-content',

      '& > div > span': {
        fontSize: THEME.font.sizes.subordinate,
        padding: 0,
      },
    },
  }),
  methodContainer: css({
    borderBottom: '1px solid #eef3f7',
    padding: 16,

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      marginBottom: 6,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subordinate,
    },
  }),
};

export default PromotionMethodConnector;
