import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FanDetailsSchema } from '@src/libs/validation';
import { useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { Empty } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { useGetFanQuery, useUpdateFanMutation } from '@src/graphql/hooks';
import { ROUTES } from '@src/shared/routes';
import FanDetailsForm, { FormValues } from './FanDetailsForm';
import { getInitialFormValues, getUpdateFanInput } from './helper';

const FanDetails = () => {
  const { enqueueSnackbar, matchPath, params, pathname, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const fanId = Number(params.id);
  const { data, error, loading } = useGetFanQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        fanId,
      },
    },
  });
  const [updateFan] = useUpdateFanMutation({
    refetchQueries: ['GetFanHeader'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editFan, { fanId });
    },
  });

  const defaultValues = getInitialFormValues(data?.getFan);
  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(FanDetailsSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (payload: FormValues) => {
    const input = getUpdateFanInput(fanId, payload);
    await updateFan({
      variables: {
        input,
      },
    });
  };

  if (matchPath(ROUTES.MY_PAGE_FANS_EDIT, pathname) && isMobileView) {
    // Nothing to return. Only show fan navigation tabs from parent.
    return null;
  }

  if (loading) {
    return <ListLoading />;
  }

  if (error?.message.includes("fan doesn't exist") || !data?.getFan) {
    return <Empty />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FanDetailsForm editable={data.getFan.editable} />
      </form>
    </FormProvider>
  );
};

export default FanDetails;
