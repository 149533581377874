import TimePicker, { TimePickerProps } from 'rc-time-picker';
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import Icomoon from '@src/components/atoms/Icomoon';
import 'rc-time-picker/assets/index.css';

export const timeDefaultFormat = 'hh:mm A';

interface RCTimePickerProps extends TimePickerProps {
  format?: string;
  width?: number;
}

const RCTimePicker = ({
  format = timeDefaultFormat,
  showSecond = false,
  width = 150,
  ...restProps
}: RCTimePickerProps) => (
  <TimePickerContainer width={width}>
    <Icomoon icon="clock-outlined" size={24} />
    <TimePicker format={format} showSecond={showSecond} {...restProps} />
    <div css={styles.iconContainer}>
      <Icomoon icon="arrow-down" size={10} />
    </div>
  </TimePickerContainer>
);

const TimePickerContainer = styled.div<{ width: number }>(({ width }) => ({
  position: 'relative',

  '& > svg': {
    left: 8,
    position: 'absolute',
    top: 4,
    zIndex: 1,
  },

  '& .rc-time-picker': {
    '& > input': {
      borderColor: '#dee5ec',
      borderRadius: 3,
      color: THEME.font.colors.black.main,
      fontSize: 13,
      height: 32,
      paddingLeft: 40,
      width,
    },

    '& > a': {
      display: 'none',
    },
  },
}));

const styles = {
  iconContainer: css({
    position: 'absolute',
    right: 8,
    top: 8,
  }),
};

export default RCTimePicker;
