import React from 'react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';

interface ProgressBarProps {
  bgColor?: string;
  completed: number;
  completedColor?: string;
  height?: number;
}

const ProgressBar = ({
  bgColor = THEME.colors.gray.eef3f7,
  completed,
  completedColor = THEME.colors.blue.main,
  height = 10,
}: ProgressBarProps) => (
  <ProgressBarContainer bgColor={bgColor} height={height}>
    <Bar bgColor={completedColor} width={completed} />
  </ProgressBarContainer>
);

const Bar = styled.div<{ bgColor: string; width: number }>(({ bgColor, width }) => ({
  background: bgColor,
  borderRadius: width === 100 ? '30px' : '30px 0 0 30px',
  height: '100%',
  width: `${width}%`,
}));

const ProgressBarContainer = styled.div<{ bgColor: string; height: number }>(({ bgColor, height }) => ({
  background: bgColor,
  borderRadius: 30,
  height: `${height}px`,
  overflow: 'hidden',
  width: '100%',
}));

export default ProgressBar;
