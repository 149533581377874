import { format, formatDistanceToNow, isValid, getMonth, getYear, parseISO, subDays } from 'date-fns';
import { arSA, enUS, ja, ko } from 'date-fns/locale';
import { getI18n } from 'react-i18next';
import { DEFAULT_FNS_DATE_FORMAT } from './constant';
import { AppLanguage, APP_LANGUAGES } from './i18n/languageDetector/utils';

export const getNow = (dateFormat: string = 'yyyy-MM-DD HH:mm:ss') => format(new Date(), dateFormat);

export const getToday = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) => format(new Date(), dateFormat);
// note use 29 day, because today is not included
export const getThirtyDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 29), dateFormat);
export const getSevenDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 6), dateFormat);

export const getFourteenDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 13), dateFormat);

export const getNinetyDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 89), dateFormat);

export const getSixtyDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 59), dateFormat);
export const getLastDay = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) => {
  const y = getYear(new Date());
  const m = getMonth(new Date());
  const lastDay = new Date(y, m + 1, 0);

  return format(lastDay, dateFormat);
};

// TODO: Localize Weekdays
const WEEKDAYS_LONG: Record<AppLanguage, string[]> = {
  [APP_LANGUAGES.en]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [APP_LANGUAGES.ja]: ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'],
  [APP_LANGUAGES.th]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [APP_LANGUAGES.id]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [APP_LANGUAGES.vi]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [APP_LANGUAGES.tw]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [APP_LANGUAGES.ch]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [APP_LANGUAGES.km]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [APP_LANGUAGES.my]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  [APP_LANGUAGES.ar]: ['الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعه', 'السبت', 'الأحد'],
  [APP_LANGUAGES.ko]: ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'],
  [APP_LANGUAGES.pseudo]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
};

export const localizedWeekdays = (locale: AppLanguage = APP_LANGUAGES.en) => WEEKDAYS_LONG[locale];

export const convertDurationToHhMmSs = (seconds: number | null) =>
  seconds === null ? '-' : new Date(seconds * 1000).toISOString().substr(11, 8);

// Calendar library utils
const MONTHS = {
  [APP_LANGUAGES.en]: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  [APP_LANGUAGES.ja]: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  [APP_LANGUAGES.ar]: [
    'كَانُون ٱلثَّانِي',
    'شُبَاط',
    'آذَار',
    'نَيْسَان',
    'أَيَّار',
    'حَزِيرَان',
    'تَمُّوز',
    'آب',
    'أَيْلُول',
    'تِشْرِين ٱلْأَوَّل',
    'تِشْرِين ٱلثَّانِي',
    'كَانُون ٱلْأَوَّل',
  ],
  [APP_LANGUAGES.ko]: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
};
const CALENDAR_WEEKDAYS_LONG = {
  [APP_LANGUAGES.en]: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  [APP_LANGUAGES.ja]: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  [APP_LANGUAGES.ar]: ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعه', 'السبت'],
  [APP_LANGUAGES.ko]: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
};
const CALENDAR_WEEKDAYS_SHORT = {
  [APP_LANGUAGES.en]: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  [APP_LANGUAGES.ja]: ['日', '月', '火', '水', '木', '金', '土'],
  [APP_LANGUAGES.ar]: ['ح', 'ن', 'ث', 'ر', 'خ', 'ج', 'س'],
  [APP_LANGUAGES.ko]: ['일', '월', '화', '수', '목', '금', '토'],
};

const formatDay = (d: Date, locale: AppLanguage = APP_LANGUAGES.en) =>
  `${
    CALENDAR_WEEKDAYS_LONG[locale === APP_LANGUAGES.ja ? APP_LANGUAGES.ja : APP_LANGUAGES.en][d.getDay()]
  }, ${d.getDate()} ${
    MONTHS[locale === APP_LANGUAGES.ja ? APP_LANGUAGES.ja : APP_LANGUAGES.en][d.getMonth()]
  } ${d.getFullYear()}`;
const formatMonthTitle = (d: Date, locale: AppLanguage = APP_LANGUAGES.en) => {
  switch (locale) {
    case 'ja':
      return `${d.getFullYear()}年${MONTHS[locale][d.getMonth()]}`;
    case 'ar':
      return `${MONTHS.ar[d.getMonth()]} ${d.getFullYear()}`;
    default:
      return `${MONTHS.en[d.getMonth()]} ${d.getFullYear()}`;
  }
};
const formatWeekdayShort = (i: number, locale: AppLanguage = APP_LANGUAGES.en) =>
  CALENDAR_WEEKDAYS_SHORT[
    locale === APP_LANGUAGES.ja ? APP_LANGUAGES.ja : locale === APP_LANGUAGES.ar ? APP_LANGUAGES.ar : APP_LANGUAGES.en
  ][i];
const formatWeekdayLong = (i: number, locale: AppLanguage = APP_LANGUAGES.en) =>
  CALENDAR_WEEKDAYS_SHORT[
    locale === APP_LANGUAGES.ja ? APP_LANGUAGES.ja : locale === APP_LANGUAGES.ar ? APP_LANGUAGES.ar : APP_LANGUAGES.en
  ][i];

export const localeUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
};

export const hourIntervals = [
  '12 am',
  '1 am',
  '2 am',
  '3 am',
  '4 am',
  '5 am',
  '6 am',
  '7 am',
  '8 am',
  '9 am',
  '10 am',
  '11 am',
  '12 pm',
  '1 pm',
  '2 pm',
  '3 pm',
  '4 pm',
  '5 pm',
  '6 pm',
  '7 pm',
  '8 pm',
  '9 pm',
  '10 pm',
  '11 pm',
];

export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const minColor = '#dee5ec';
export const maxColor = '#ff8097';

export const getDayfromDateString = (dateString: string) => {
  const { language } = getI18n();

  return format(new Date(dateString), language === APP_LANGUAGES.ja ? 'do' : 'dd', {
    locale: getDateLocal(language as AppLanguage),
  });
};

export const getDateTimeInPickerFormat = (date: Date) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const isAfternoon = hour >= 12;

  return {
    date: format(date, DEFAULT_FNS_DATE_FORMAT),
    // if hour equal to 0 mean is 12 midnight and set it to 12
    time: `${String(isAfternoon && hour > 12 ? hour - 12 : hour || 12).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )} ${isAfternoon ? 'PM' : 'AM'}`,
  };
};

export const getFormatDistanceToNow = (date: string, isLocal: undefined | boolean = false): string =>
  formatDistanceToNow(new Date(isLocal ? date + 'Z' : date), { addSuffix: true });

export const getDateLocal = (locale?: AppLanguage) => {
  switch (locale) {
    case 'ja':
      return ja;
    case 'ar':
      return arSA;
    case 'ko':
      return ko;
    case 'en':
    default:
      return enUS;
  }
};

export const localizedDateFormatter = (
  date: Date | number | string,
  dateFormat = 'MMM do',
  local: AppLanguage = 'en'
): string => {
  const dateIsIso = typeof date === 'string' ? isValid(parseISO(date)) : false;
  if (!date || (!dateIsIso && !isValid(date))) {
    return '-';
  }

  // https://stackoverflow.com/questions/65699256/datefns-format-is-not-completely-formatted
  // We want Dec 23, 2020 in english (not full date), but 2020年2月15日 in japanese (full date)
  if (dateFormat === 'PPP' && local !== 'ja') {
    dateFormat = 'MMM dd, yyyy';
  }

  return format(new Date(date), dateFormat, { locale: getDateLocal(local) });
};
