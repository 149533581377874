import React, { useCallback, useState, useEffect } from 'react';
import {
  getToday,
  getThirtyDaysAgo,
  getSevenDaysAgo,
  getFourteenDaysAgo,
  getSixtyDaysAgo,
  getNinetyDaysAgo,
} from '@src/libs/date';
import { Option } from '@src/libs/functions';
import { useTranslation } from 'react-i18next';
import Icomoon from '@src/components/atoms/Icomoon';
import { Select } from '@src/components/shared/Select';
import { localizedDateFormatter } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import useUuumInfluencerView from '@src/libs/hooks/useUuumInfluencerView';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { Period } from '../DayPicker/types';

export enum AllowedDateRanges {
  LAST_ONE_WEEK = 'LAST_ONE_WEEK',
  LAST_TWO_WEEKS = 'LAST_TWO_WEEKS',
  LAST_ONE_MONTH = 'LAST_ONE_MONTH',
  LAST_TWO_MONTHS = 'LAST_TWO_MONTHS',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
}

export const periodForDateRange: Record<AllowedDateRanges, Period> = {
  [AllowedDateRanges.LAST_ONE_WEEK]: { startDate: getSevenDaysAgo(), endDate: getToday() },
  [AllowedDateRanges.LAST_TWO_WEEKS]: { startDate: getFourteenDaysAgo(), endDate: getToday() },
  [AllowedDateRanges.LAST_ONE_MONTH]: { startDate: getThirtyDaysAgo(), endDate: getToday() },
  [AllowedDateRanges.LAST_TWO_MONTHS]: { startDate: getSixtyDaysAgo(), endDate: getToday() },
  [AllowedDateRanges.LAST_THREE_MONTHS]: { startDate: getNinetyDaysAgo(), endDate: getToday() },
};

const getRangeFromPeriod = ({ startDate, endDate }: Period) => {
  switch (JSON.stringify({ startDate, endDate })) {
    case JSON.stringify({ startDate: getSevenDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_ONE_WEEK;
    case JSON.stringify({ startDate: getFourteenDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_TWO_WEEKS;
    case JSON.stringify({ startDate: getThirtyDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_ONE_MONTH;
    case JSON.stringify({ startDate: getSixtyDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_TWO_MONTHS;
    case JSON.stringify({ startDate: getNinetyDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_THREE_MONTHS;

    default:
      return null;
  }
};

const Icon = <Icomoon icon="calendar" size={19} />;
export const getDateRangeOptions = (t: TFunction): Option[] => [
  {
    value: AllowedDateRanges.LAST_ONE_WEEK,
    label: t('Last one week'),
    icon: Icon,
  },
  {
    value: AllowedDateRanges.LAST_TWO_WEEKS,
    label: t('Last two weeks'),
    icon: Icon,
  },
  {
    value: AllowedDateRanges.LAST_ONE_MONTH,
    label: t('Last one month'),
    icon: Icon,
  },
  {
    value: AllowedDateRanges.LAST_TWO_MONTHS,
    label: t('Last two months'),
    icon: Icon,
  },
  {
    value: AllowedDateRanges.LAST_THREE_MONTHS,
    label: t('Last three months'),
    icon: Icon,
  },
];

export interface MobileDaysRangeSelectorProps {
  onRangeChange: (period: Period) => void;
  initialPeriod: Period;
  className?: string;
}
const MobileDaysRangeSelector = ({ initialPeriod, onRangeChange, className }: MobileDaysRangeSelectorProps) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState<AllowedDateRanges | string>('');
  const { isUuumView } = useUuumInfluencerView();
  const isJPLanguage = i18n.language === 'ja';

  const options = useCallback(() => getDateRangeOptions(t), []);

  const { startDate, endDate } = Object.keys(AllowedDateRanges).includes(value)
    ? periodForDateRange[value as AllowedDateRanges]
    : initialPeriod;

  useEffect(() => {
    const selectedRange = getRangeFromPeriod(initialPeriod);
    if (selectedRange) {
      setValue(selectedRange);
    }
  }, [initialPeriod?.endDate, initialPeriod?.startDate]);

  const handleChange = (selectedRange: string) => {
    onRangeChange(periodForDateRange[selectedRange as AllowedDateRanges]);
  };

  return (
    <Container className={className} isUuumView={isUuumView}>
      <p>
        {value !== undefined
          ? `${localizedDateFormatter(
              startDate,
              isJPLanguage ? 'MMM do' : 'MMM dd',
              i18n.language as AppLanguage
            )} - ${localizedDateFormatter(endDate, isJPLanguage ? 'MMM do' : 'MMM dd', i18n.language as AppLanguage)} `
          : '-'}
      </p>
      <Select disableClear disableSearch options={options()} value={value} onChange={handleChange} />
    </Container>
  );
};

const Container = styled.div<{ isUuumView: boolean }>(({ isUuumView }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: THEME.box.gaps.xxl,

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    gap: THEME.box.gaps.s,
    ...(!isUuumView && { flexDirection: 'row-reverse' }),
  },

  '& > p': {
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    justifyContent: 'flex-end',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      flex: 1,
    },
  },

  '& > div': {
    width: 160,

    '& .select-input-container': {
      ...(!isUuumView && { borderRadius: 9, height: 40 }),
    },

    '& .select-menu-container': {
      ...(!isUuumView && { borderRadius: 9 }),
    },
  },
}));

export default MobileDaysRangeSelector;
