import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import { EmailEventStatus } from '@src/__generated__/globalTypes';

interface RecipientStatusIndicatorProps {
  status: EmailEventStatus;
}

const RecipientStatusIndicator = ({ status }: RecipientStatusIndicatorProps) => {
  const { t } = useTranslation();
  const color =
    status === EmailEventStatus.BOUNCE ? '#ffd7d7' : status === EmailEventStatus.CLICK ? '#cde4f9' : '#cfedde';

  return <Container color={color}>{t(status)}</Container>;
};

const Container = styled.div<{ color: string }>(({ color }) => ({
  alignItems: 'center',
  backgroundColor: color,
  borderRadius: 35,
  color: THEME.font.colors.black.main,
  display: 'flex',
  fontSize: THEME.font.sizes.subordinate,
  height: 24,
  justifyContent: 'center',
  padding: '0 8px',
  width: 'fit-content',
}));

export default RecipientStatusIndicator;
