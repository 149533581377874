import React, { useRef, useState, useEffect, PropsWithChildren } from 'react';
import Slider, { Settings } from 'react-slick';
import { SerializedStyles } from '@emotion/serialize/types';
import { css } from '@emotion/react';
import useDimensions from '@src/libs/hooks/useDimensions';
import SliderArrow from './SliderArrow';

const EACH_SLIDE_WIDTH = 176;

interface ImageSliderProps {
  data: any[];
  className?: string;
  slideWidth?: number;
  sliderArrowCss?: SerializedStyles;
  hasBgImage?: boolean;
  currentSlideIdx?: number;
  sliderLength?: number;
}

const ImageSlider = (props: PropsWithChildren<ImageSliderProps>) => {
  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);
  const { width } = useDimensions(sliderWrapperRef);

  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [maxNumberOfCardsToShow, setMaxNumberOfCardsToShow] = useState<number>(0);

  const {
    children,
    data,
    className,
    slideWidth = EACH_SLIDE_WIDTH,
    sliderArrowCss,
    hasBgImage,
    currentSlideIdx,
    sliderLength = 0,
  } = props;

  const settings: Settings = {
    className: 'slider variable-width',
    centerMode: false,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    arrows: false,
    afterChange: (currentSlide: number) => handleChangeSlide(currentSlide),
  };

  const handleChangeSlide = (currentSlide: number) => {
    const leftArrowVisible = currentSlide !== 0;
    const rightArrowVisible = currentSlide <= data.length - maxNumberOfCardsToShow;
    setShowLeftArrow(leftArrowVisible);
    setShowRightArrow(rightArrowVisible);
  };

  useEffect(() => {
    const maxNumberOfCards = Math.ceil(width / slideWidth);
    setMaxNumberOfCardsToShow(maxNumberOfCards);
    setShowRightArrow(width < data.length * (slideWidth + 16)); // 16 is slider margin
  }, [width]);

  useEffect(() => {
    if (sliderRef.current && currentSlideIdx !== undefined && sliderLength > 8) {
      sliderRef.current.slickGoTo(currentSlideIdx);
    }
  }, [currentSlideIdx]);

  return (
    <div css={{ position: 'relative' }} ref={sliderWrapperRef} className={className}>
      {showLeftArrow && (
        <SliderArrow
          type="prev"
          onClick={() => sliderRef.current?.slickPrev()}
          css={sliderArrowCss}
          hasBgImage={hasBgImage}
        />
      )}
      <Slider
        {...settings}
        ref={sliderRef}
        css={css({
          '& .slick-track': {
            width: 'calc(100vw - 300px) !important',
            display: 'flex',
          },
        })}
      >
        {children}
      </Slider>
      {showRightArrow && (
        <SliderArrow
          type="next"
          onClick={() => sliderRef.current?.slickNext()}
          css={sliderArrowCss}
          hasBgImage={hasBgImage}
        />
      )}
    </div>
  );
};

export default ImageSlider;
