import axios from 'axios';
import React, { ComponentProps } from 'react';
import SunEditor from 'suneditor-react';
import lang from 'suneditor-react/dist/types/lang';
import 'suneditor/src/assets/css/suneditor.css';
import { useApolloClient } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import QUERY from '@src/graphql/queries/GeneratePostFileSignedUrls.graphql';
import useQueryHelper from '@src/libs/hooks/useQueryHelper';
import { THEME } from '@src/libs/theme';
import {
  GeneratePostFileSignedUrlsQuery,
  GeneratePostFileSignedUrlsQueryVariables,
} from '@src/__generated__/globalTypes';
import Label from '../Label';

type SunEditorProps = ComponentProps<typeof SunEditor>;

interface RichEditorProps extends Omit<SunEditorProps, 'setContents'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  error?: boolean;
  help?: string;
  note?: string;
  value?: string;
}

const RichEditor = (props: RichEditorProps) => {
  const client = useApolloClient();
  const { enqueueSnackbar, i18n, t } = useQueryHelper();
  const { title, isRequired, help, value, error, setOptions } = props;
  const buttonList = [
    ['undo', 'redo'],
    ['bold', 'underline', 'italic', 'strike'],
    ['fontColor', 'hiliteColor'],
    ['removeFormat'],
    ['indent', 'outdent'],
    ['list'],
    ['link'],
    ['image'],
  ];
  // suneditor does not provide function to get language list, so we need to define a variable based on the document
  const editorLanguages = [
    'ckb',
    'da',
    'de',
    'en',
    'es',
    'fr',
    'he',
    'it',
    'lv',
    'ja',
    'ko',
    'pl',
    'pt_br',
    'se',
    'ro',
    'ru',
    'ua',
    'zh_cn',
  ];

  return (
    <div css={styles.content}>
      {title && <Label title={title} isRequired={isRequired} help={help} />}
      <EditorContainer error={error}>
        <SunEditor
          // suneditor import language file based on the name, it hit error if the file does not exist
          lang={editorLanguages.includes(i18n.language) ? (i18n.language as lang) : 'en'}
          setContents={value}
          setOptions={{ buttonList, ...setOptions }}
          onImageUploadBefore={(files, _info, uploadHandler) => {
            const uploadedFile = files[0];
            client
              .query<GeneratePostFileSignedUrlsQuery, GeneratePostFileSignedUrlsQueryVariables>({
                query: QUERY,
                variables: { fileNames: [uploadedFile.name] },
              })
              .then(({ data }) => {
                const generatePostFileSignedUrl = data?.generatePostFileSignedUrls.fileUploadUrls?.[0];
                if (generatePostFileSignedUrl) {
                  axios(generatePostFileSignedUrl.signedUrl, {
                    method: 'PUT',
                    data: uploadedFile,
                  })
                    .then(() => {
                      const response = {
                        result: [
                          {
                            url: generatePostFileSignedUrl?.signedUrl.split('?')[0] || '',
                            name: uploadedFile.name,
                            size: uploadedFile.size,
                          },
                        ],
                      };

                      uploadHandler(response);
                    })
                    .catch(err => {
                      enqueueSnackbar(t(err.message), { variant: 'error' });
                      uploadHandler(err.message);
                    });
                }
              })
              .catch(err => {
                enqueueSnackbar(t(err.message), { variant: 'error' });
                uploadHandler(err.message);
              });

            // uploadHandler already included image with storage url generated by API
            // return false will exclude initial base64 image
            return false;
          }}
          {...props}
        />
      </EditorContainer>
    </div>
  );
};

const EditorContainer = styled.div<{ error?: boolean }>(({ error }) => ({
  boxSizing: 'border-box',
  width: '100%',
  backgroundColor: THEME.colors.white,
  borderColor: error ? 'tomato' : '#e0e8ed',
  borderRadius: 2,
  fontSize: 13,
}));

const styles = {
  // For some reason, all our native css is reseted in our general css.. so need to add back those default styles
  content: css({
    h1: {
      display: 'block',
      fontSize: '2em',
      marginTop: '0.67em',
      marginBottom: '0.67em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold',
    },

    h2: {
      display: 'block',
      fontSize: '1.5em',
      marginTop: '0.83em',
      marginBottom: '0.83em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold',
    },

    h3: {
      display: 'block',
      fontSize: '1.17em',
      marginTop: '1em',
      marginBottom: '1em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold',
    },

    h4: {
      display: 'block',
      marginTop: '1.33em',
      marginBottom: '1.33em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold',
    },

    h5: {
      display: 'block',
      fontSize: '0.83em',
      marginTop: '1.67em',
      marginBottom: '1.67em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold',
    },

    h6: {
      display: 'block',
      fontSize: '0.67em',
      marginTop: '2.33em',
      marginBottom: '2.33em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold',
    },

    ul: {
      listStyleType: 'disc',
      listStylePosition: 'inside',
    },

    ol: {
      listStyleType: 'decimal',
      listStylePosition: 'inside',
    },

    'ul ul, ol ul': {
      listStyleType: 'circle',
      listStylePosition: 'inside',
      marginLeft: 15,
    },

    'ol ol, ul ol': {
      listStyleType: 'lower-latin',
      listStylePosition: 'inside',
      marginLeft: 15,
    },

    li: {
      listStyle: 'inherit',
      display: 'list-item',
    },

    pre: {
      background: '#e0e0e0',
      borderRadius: 10,
      color: 'rgba(0, 0, 0, 0.9)',
      fontFamily: 'Consolas, monospace',
      margin: 0,
    },

    blockquote: {
      borderLeft: '5px solid #eee',
      fontFamily: '"Hoefler Text, Georgia, serif"',
      color: '#666',
      fontStyle: 'italic',
    },

    strong: {
      fontWeight: 'bold',
    },

    a: {
      color: '#004cff',
      textDecoration: 'none',
    },

    'a:hover': {
      cursor: 'pointer',
      color: '#0093ff',
      textDecoration: 'underline',
    },

    '.sun-editor': {
      color: THEME.font.colors.black.main,
      fontFamily: '"Inter, sans-serif"',
      border: 'inherit',
    },

    '.se-dialog.sun-editor-common': {
      zIndex: 2,
    },

    '.se-wrapper': {
      position: 'unset !important' as any,
      zIndex: 'unset',
    },

    '.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable': {
      wordBreak: 'break-word',
      padding: '16px 16px 0 16px',
    },

    '.se-dialog-btn-radio': {
      appearance: 'auto',
    },
  }),
};

export default RichEditor;
