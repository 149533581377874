import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerAllInstagramStoryPostsQuery,
  InfluencerAllInstagramStoryPostsQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerAllInstagramStoryPosts.graphql';

export const useInfluencerAllInstagramStoryPostsQuery = (
  options?: QueryHookOptions<InfluencerAllInstagramStoryPostsQuery, InfluencerAllInstagramStoryPostsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerAllInstagramStoryPostsQuery, InfluencerAllInstagramStoryPostsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerAllInstagramStoryPostsLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerAllInstagramStoryPostsQuery, InfluencerAllInstagramStoryPostsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerAllInstagramStoryPostsQuery, InfluencerAllInstagramStoryPostsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
