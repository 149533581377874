import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { JAPANESE_LANG } from '@src/libs/constant';
import { THEME } from '@src/libs/theme';

const ShareLogins = () => {
  const { i18n, t } = useTranslation();

  return i18n.language === JAPANESE_LANG ? (
    <div css={styles.mobileContainer}>
      <div>
        <Icomoon color="#000" css={{ marginRight: -16 }} icon="user-circle-outlined" size={28} />
        <Icomoon icon="instagram-key-colors" size={72} />
        <Icomoon color="#000" css={{ marginLeft: -16 }} icon="user-circle-outlined" size={28} />
      </div>
      <div>{t('Title.Share logins using Accounts Center')}</div>
      <div>
        {t(
          'Annotation.Setting up your Accounts Center makes it easier to log in with Facebook or Instagram, share your stories and posts, and manage your accounts in one place'
        )}
      </div>
      <div>{t('Button.Next')}</div>
    </div>
  ) : (
    <div css={styles.container}>
      <div css={styles.shareLoginsContainer}>
        <div>
          <Icomoon color="#000" css={{ marginRight: -12 }} icon="user-circle-outlined" size={18} />
          <Icomoon icon="instagram-key-colors" size={48} />
          <Icomoon color="#000" css={{ marginLeft: -12 }} icon="user-circle-outlined" size={18} />
        </div>
        <div>{t('Title.Share logins using Accounts Center')}</div>
        <div>{t('Button.Continue')}</div>
      </div>

      <div css={styles.allowMetaToAccessYourAccContainer}>
        <div>
          <Icomoon color="#2962d8" icon="meta" />
          <div>Meta</div>
        </div>
        <div>{t('Annotation.Allow Instagram to access your account')}</div>
        <div>{t('Annotation.Instagram needs to access info from your Facebook account to continue')}</div>
        <div>{t('Button.Yes, continue')}</div>
      </div>

      <div css={styles.arrowNext} />
    </div>
  );
};

const styles = {
  allowMetaToAccessYourAccContainer: css({
    background: 'linear-gradient(140deg, #f7f8fa 15%, #f4f2fc 25%, #eef5fd 90%)',
    borderRadius: 32,
    height: 120,
    padding: 8,
    position: 'relative',
    width: 264,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      color: '#000',
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 500,
      gap: THEME.box.gaps.xs,
      marginBottom: 6,
    },

    '& > div:nth-of-type(2)': {
      color: '#000',
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      marginBottom: 6,
    },

    '& > div:nth-of-type(3)': {
      color: '#000',
      fontSize: THEME.font.sizes.hint,
    },

    '& > div:nth-of-type(4)': {
      alignItems: 'center',
      background: '#2962d8',
      borderRadius: 16,
      bottom: 0,
      color: THEME.font.colors.white,
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 500,
      height: 32,
      justifyContent: 'center',
      left: -14,
      position: 'absolute',
      width: 306,
    },
  }),
  arrowNext: css({
    alignItems: 'center',
    background: '#000',
    borderRadius: '50%',
    bottom: 0,
    content: '""',
    display: 'grid',
    height: 24,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    margin: 'auto',
    top: 0,
    width: 24,

    '&::before': {
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: '8px solid #fff',
      content: '""',
      display: 'block',
    },
  }),
  container: css({
    display: 'grid',
    gap: THEME.box.gaps.s,
    position: 'relative',
  }),
  mobileContainer: css({
    alignContent: 'flex-start',
    background: '#e5e5e5',
    borderRadius: 32,
    display: 'grid',
    gap: THEME.box.gaps.s,
    height: 248,
    position: 'relative',
    padding: 16,
    width: 248,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      justifySelf: 'center',
    },

    '& > div:nth-of-type(2)': {
      color: '#000',
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      textAlign: 'center',
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.gray.main,
      fontSize: 9.5,
      textAlign: 'center',
    },

    '& > div:nth-of-type(4)': {
      alignItems: 'center',
      background: '#0095f6',
      borderRadius: 4,
      bottom: 24,
      color: THEME.font.colors.white,
      display: 'grid',
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 500,
      height: 40,
      justifyContent: 'center',
      left: -12,
      position: 'absolute',
      width: 306,
    },
  }),
  shareLoginsContainer: css({
    alignContent: 'flex-start',
    background: THEME.colors.white,
    borderRadius: 32,
    display: 'grid',
    gap: 2,
    height: 120,
    padding: 8,
    position: 'relative',
    width: 264,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      justifySelf: 'center',
    },

    '& > div:nth-of-type(2)': {
      color: '#000',
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      justifySelf: 'center',
      textAlign: 'center',
      width: 138,
    },

    '& > div:nth-of-type(3)': {
      alignItems: 'center',
      background: '#0095f6',
      borderRadius: 3,
      bottom: 8,
      color: THEME.font.colors.white,
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 500,
      height: 32,
      justifyContent: 'center',
      left: -12,
      position: 'absolute',
      width: 306,
    },
  }),
};

export default ShareLogins;
