import React, { useState, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import peopleHoldingCreditCard from '@src/assets/img/myPage/peopleHoldingCreditCard.png';
import { Icomoon, Icon } from '@src/components/atoms';
import { Avatars, Dialog, ThemeButton, YoutubeCmsWidget } from '@src/components/molecules';
import { Toolbar } from '@src/components/shared';
import {
  useAnyXCheckConnectedSocialAccountsQuery,
  useCreatorAddressInformationQuery,
  useGetCreatorStaffsCountQuery,
  useGetInfluencerRateCardForInfluencerQuery,
  useInfluencerPaymentInformationQuery,
  useInfluencerPaymentRequestsQuery,
  useInfluencerProfileV2Query,
  usePaymentRequestQuery,
} from '@src/graphql/hooks';
import { removeToken } from '@src/libs/auth';
import { CURRENCY_SYMBOLS, COUNTRY_CODES, LIMIT } from '@src/libs/constant';
import { formatNumberWithCommas } from '@src/libs/format';
import { useHelpCenterUrl } from '@src/libs/help';
import { useAuthData, useGlobalLayout, usePageLayout, useQueryHelper, useUuumInfluencerView } from '@src/libs/hooks';
import { switchImage, switchSocialBackgroundColor, switchText } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import {
  CampaignSocialMediaType,
  InfluencerType,
  PaymentRequestStatusForInfluencer,
} from '@src/__generated__/globalTypes';

interface SocialAccounts {
  avatar?: string | null;
  name: string;
}

const MyPage = () => {
  const [isDialogBankAccount, setIsDialogBankAccount] = useState<boolean>(false);
  const { countryId, creatorType, isAvailableCountry, userId } = useAuthData();
  const { hasYoutubeCmsWidget } = useGlobalLayout();
  const { helpCenterUrl, howToGetPayment } = useHelpCenterUrl();
  const { isMobileView, isYoutubeCmsPath } = usePageLayout();
  const { navigate, t } = useQueryHelper();
  const { isUuumView } = useUuumInfluencerView();

  const { data: dataInfluencerProfile } = useInfluencerProfileV2Query({
    variables: {
      pk: Number(userId),
    },
  });
  const enabledCustomerSupport = [
    COUNTRY_CODES.AE,
    COUNTRY_CODES.HK,
    COUNTRY_CODES.IN,
    COUNTRY_CODES.MY,
    COUNTRY_CODES.SG,
    COUNTRY_CODES.TW,
  ].includes(dataInfluencerProfile?.influencerProfileV2?.country.id as string);

  const { data: dataInfluencerPaymentInformation } = useInfluencerPaymentInformationQuery({ skip: isYoutubeCmsPath });
  const { data: dataInfluencerPaymentRequests } = useInfluencerPaymentRequestsQuery({
    skip: !isAvailableCountry || isYoutubeCmsPath,
    variables: { offset: 0, limit: LIMIT },
  });
  const { data: dataPaymentRequest } = usePaymentRequestQuery({
    skip: countryId === 'US' || !isAvailableCountry || isYoutubeCmsPath,
  });
  const paymentRequest = dataPaymentRequest?.paymentRequest;
  const currency = paymentRequest?.currency;
  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] || '' : '';
  const grossRevenue = paymentRequest?.grossRevenue;
  const hasPaymentInformation = !!dataInfluencerPaymentInformation?.influencerPaymentInformation?.accountNumber;
  const incorrectBankAccount = !!dataInfluencerPaymentRequests?.influencerPaymentRequests.paymentRequests.find(
    payment => payment.status === PaymentRequestStatusForInfluencer.FAILED
  );
  const bankAccountWarning = !hasPaymentInformation || incorrectBankAccount;

  const { data: dataCreatorAddress } = useCreatorAddressInformationQuery();
  const creatorAddressInformation = dataCreatorAddress?.creatorAddressInformation;
  const isRegisteredAddress = !!(
    creatorAddressInformation?.address ||
    creatorAddressInformation?.address2 ||
    creatorAddressInformation?.city ||
    creatorAddressInformation?.fullName ||
    creatorAddressInformation?.postalCode ||
    creatorAddressInformation?.province
  );

  const { data: dataSnsBudget } = useGetInfluencerRateCardForInfluencerQuery({
    skip: !isAvailableCountry || isYoutubeCmsPath,
  });
  const snsBudgetInformation = dataSnsBudget?.getInfluencerRateCardForInfluencer;
  const isRegisteredSnsBudget = !!(
    snsBudgetInformation?.facebook ||
    snsBudgetInformation?.instagram ||
    snsBudgetInformation?.instagramStory ||
    snsBudgetInformation?.tiktok ||
    snsBudgetInformation?.twitter ||
    snsBudgetInformation?.youtube
  );

  const { data: dataUsersCount } = useGetCreatorStaffsCountQuery({ skip: isYoutubeCmsPath });
  const isRegisteredUsers = !!dataUsersCount?.getCreatorStaffsCount;

  const { data: dataSocialConnect } = useAnyXCheckConnectedSocialAccountsQuery();
  const connectedSocialAccounts = dataSocialConnect?.anyXCheckConnectedSocialAccounts;

  const formatSocialAccounts = (socialAccounts?: SocialAccounts[]) =>
    socialAccounts?.map(account => ({
      src: account.avatar || '',
      title: account.name,
    })) || [];
  const fbAccounts = [
    ...formatSocialAccounts(connectedSocialAccounts?.facebookPages),
    ...formatSocialAccounts(connectedSocialAccounts?.facebooks),
  ];
  const instagramAccounts = formatSocialAccounts(connectedSocialAccounts?.instagramAccounts);
  const twitterAccounts = formatSocialAccounts(connectedSocialAccounts?.twitterAccounts);
  const youtubeAccounts = formatSocialAccounts(connectedSocialAccounts?.youtubeAccounts);
  const tiktokAccounts = formatSocialAccounts(connectedSocialAccounts?.tiktokAccounts);

  const profileRegistration = [
    {
      icon: 'user-outlined',
      isRegistered: true,
      title: 'Account',
      to: isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE_ACCOUNT : ROUTES.MY_PAGE_ACCOUNT,
    },
    {
      icon: 'book-outlined',
      isRegistered: isRegisteredAddress,
      title: 'Address',
      to: isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE_ADDRESS : ROUTES.MY_PAGE_ADDRESS,
    },
    ...(creatorType === InfluencerType.PRO && !isYoutubeCmsPath
      ? [{ icon: 'users-outlined', isRegistered: isRegisteredUsers, title: 'Users', to: ROUTES.MY_PAGE_USERS }]
      : []),
    // we hide SNS budget for TA/PA influencer
    ...(!isUuumView && snsBudgetInformation && !isYoutubeCmsPath
      ? [
          {
            icon: 'banknotes-outlined',
            isRegistered: isRegisteredSnsBudget,
            title: 'SNS budget',
            to: ROUTES.MY_PAGE_SNS_BUDGET,
          },
        ]
      : []),
    ...(!isUuumView && creatorType === InfluencerType.PRO && !isYoutubeCmsPath
      ? [
          {
            icon: 'plug-connect',
            isRegistered: true,
            title: 'Channel Connect',
            to: ROUTES.MY_PAGE_CHANNEL_CONNECT,
          },
        ]
      : []),
  ];

  const fanManagement = [
    { icon: 'users', title: 'Fan', to: ROUTES.MY_PAGE_FANS },
    { icon: 'mail', title: 'Email', to: ROUTES.MY_PAGE_EMAILS },
    { icon: 'chat-white', title: 'Message', to: ROUTES.MY_PAGE_MESSAGE },
    { icon: 'clipboard-check', title: 'Forms', to: ROUTES.MY_PAGE_FORMS },
  ];

  const socialConnection = [
    { items: instagramAccounts, value: CampaignSocialMediaType.INSTAGRAM },
    { items: fbAccounts, value: CampaignSocialMediaType.FACEBOOK },
    { items: twitterAccounts, value: CampaignSocialMediaType.TWITTER },
    { items: tiktokAccounts, value: CampaignSocialMediaType.TIKTOK },
    { items: youtubeAccounts, value: CampaignSocialMediaType.YOUTUBE },
    { icon: 'mail-outlined', title: 'Other', value: CampaignSocialMediaType.OTHER },
  ];

  const collaborateWithBrand = [{ icon: 'search', title: 'Go to Find Jobs', to: ROUTES.FIND_JOBS }];

  const onClickSignOut = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.preventDefault();

    removeToken();
    sendAmplitudeEvent(eventTypes.signOut);
    navigate(ROUTES.ROOT);
  };

  const others = [
    { icon: 'help-outlined', target: '_blank', title: 'Help', to: helpCenterUrl },
    ...(enabledCustomerSupport
      ? [
          {
            icon: 'headphone-grey-light',
            title: 'Contact',
            to: isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE_CONTACT : ROUTES.MY_PAGE_CONTACT,
          },
        ]
      : []),
    { icon: 'exit', title: 'Sign Out', onClick: onClickSignOut },
  ];

  return (
    <div
      css={{
        display: 'grid',
        gap: THEME.box.gaps.l,
        ...(isMobileView && { paddingTop: 40 }),
        ...(hasYoutubeCmsWidget && { paddingBottom: 62 }),
      }}
    >
      <Dialog open={isDialogBankAccount} onClose={() => setIsDialogBankAccount(false)}>
        <div css={styles.dialogBankAccountUnavailableContainer}>
          <img alt="peopleHoldingCreditCard" height="150" src={peopleHoldingCreditCard} width="265" />
          <div>{t('Annotation.Bank account support unavailable')}</div>
          <div>{t('Annotation.Sorry, we do not offer support for bank accounts in your location currently')}</div>
        </div>
      </Dialog>

      <Toolbar hasNotification title="My Page" />

      <div css={{ [`@media (min-width: ${ViewportType.TABLET}px)`]: { justifySelf: 'center', width: 614 } }}>
        {!isYoutubeCmsPath && (
          <div css={styles.pendingRevenueContainer}>
            <div>
              <div>
                <Icomoon icon="coins" size={16} />
                <div>{t(`This Month's Revenue`)}</div>
              </div>
              <div>{`${currencySymbol}${formatNumberWithCommas(grossRevenue, undefined, currency)}`}</div>
              <div>
                <Icomoon color="#3892e5" icon="help-filled" />
                <Link target="_blank" to={howToGetPayment}>
                  {t('Annotation.How can I get payment')}
                </Link>
              </div>
              <div>
                <ThemeButton
                  prefixIcon={<Icomoon icon={!isMobileView ? 'file-text-outlined' : ''} size={16} />}
                  text="Payment History"
                  to={ROUTES.MY_PAGE_PAYMENT_HISTORY}
                />
                {!isUuumView && (
                  <ThemeButton
                    prefixIcon={
                      <Icomoon
                        color={bankAccountWarning ? '#f8b74a' : '#6e7c89'}
                        icon={bankAccountWarning ? 'warning' : !isMobileView ? 'gear' : ''}
                        size={16}
                      />
                    }
                    text="Bank Account"
                    {...(isAvailableCountry
                      ? { to: ROUTES.MY_PAGE_BANK_ACCOUNT }
                      : { onClick: () => setIsDialogBankAccount(true) })}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div css={[styles.contentContainer, isYoutubeCmsPath ? { padding: 16 } : { padding: '0 16px 16px 16px' }]}>
          <Section>
            <Header>{t('Title.Profile Registration')}</Header>
            <InfoContainer css={styles.profileRegistrationContainer}>
              {/* will include redirect path after implement the page */}
              {profileRegistration.map(({ icon, isRegistered, title, to }) => (
                <Link key={title} to={to || ''}>
                  <Icomoon icon={icon} size={16} />
                  <div>{t(`Title.${title}`)}</div>
                  {!isMobileView && !isRegistered && (
                    <div>
                      <Icomoon icon="warning-grey" size={14} />
                      <div>{t('Unregistered')}</div>
                    </div>
                  )}
                  <Icon>
                    <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={8} />
                  </Icon>
                </Link>
              ))}
            </InfoContainer>
          </Section>

          <Section>
            <Header>{t('Social Connection')}</Header>
            <InfoContainer css={styles.socialConnectionContainer}>
              {/* will include redirect path after implement the page */}
              {socialConnection.map(({ icon, items, title, value }) => (
                <Link
                  key={value}
                  to={isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT : ROUTES.MY_PAGE_SOCIAL_CONNECT}
                >
                  <SocialAccountIndicator background={icon ? '#6e7c89' : switchSocialBackgroundColor(value)}>
                    <Icomoon
                      color="#fff"
                      icon={icon ? icon : switchImage({ dynamicColor: true, socialMedia: value })}
                      size={14}
                    />
                  </SocialAccountIndicator>
                  <div>{title ? t(title) : switchText(value)}</div>
                  {items && <Avatars items={items} size={24} />}
                  <Icon>
                    <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={8} />
                  </Icon>
                </Link>
              ))}
            </InfoContainer>
          </Section>

          {isYoutubeCmsPath && (
            <Section>
              <Header>{t('Title.Collaborate with Brand')}</Header>
              <InfoContainer css={styles.commonListsContainer}>
                {collaborateWithBrand.map(({ icon, title, to }) => (
                  <Link key={title} to={to}>
                    <Icomoon icon={icon} size={16} />
                    <div>{t(`Title.${title}`)}</div>
                    <Icon>
                      <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={8} />
                    </Icon>
                  </Link>
                ))}
              </InfoContainer>
            </Section>
          )}

          {creatorType !== InfluencerType.NORMAL && !isYoutubeCmsPath && (
            <Section>
              <Header>{t('Title.Fan management')}</Header>
              <InfoContainer css={styles.fanManagementContainer}>
                {fanManagement.map(({ icon, title, to }) => (
                  <Link key={title} to={to}>
                    <div>
                      <Icomoon color="#fff" icon={icon} />
                    </div>
                    <div>{t(`Title.${title}`)}</div>
                    <Icon>
                      <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={8} />
                    </Icon>
                  </Link>
                ))}
              </InfoContainer>
            </Section>
          )}

          <Section>
            <Header>{t('Other')}</Header>
            <InfoContainer css={styles.commonListsContainer}>
              {others.map(({ icon, target, title, to, onClick }) => (
                <Link key={title} to={to || ''} onClick={onClick} {...(target && { target })}>
                  <Icomoon icon={icon} size={16} />
                  <div>{t(`Title.${title}`)}</div>
                  <Icon>
                    <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={8} />
                  </Icon>
                </Link>
              ))}
            </InfoContainer>
          </Section>
        </div>
      </div>

      {!isYoutubeCmsPath && <YoutubeCmsWidget />}
    </div>
  );
};

const InfoContainer = styled.div({
  background: '#eef3f7',
  borderRadius: 15,
  display: 'grid',
  gap: 1,
  overflow: 'hidden',
});

const Header = styled.div({
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.normal,
  textTransform: 'capitalize',
});

const Section = styled.div({
  display: 'grid',
  gap: THEME.box.gaps.s,

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    gap: THEME.box.gaps.l,
  },
});

const SocialAccountIndicator = styled.div<{ background: string }>(({ background }) => ({
  alignItems: 'center',
  background,
  borderRadius: '50%',
  display: 'flex',
  height: 24,
  justifyContent: 'center',
  minWidth: 24,
}));

const styles = {
  contentContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.xxl,
  }),
  dialogBankAccountUnavailableContainer: css({
    color: THEME.font.colors.black.main,
    display: 'grid',
    fontSize: THEME.font.sizes.normal,
    gap: THEME.box.gaps.m,
    justifyItems: 'center',
    padding: '48px 24px',
    textAlign: 'center',

    '& > div:nth-of-type(1)': {
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
    },
  }),
  fanManagementContainer: css({
    '& > a': {
      alignItems: 'center',
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: '20px 16px',

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        background: '#6e7c89',
        borderRadius: '50%',
        display: 'flex',
        height: 24,
        justifyContent: 'center',
        width: 24,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        width: 'fill-available',

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.normal,
        },
      },
    },
  }),
  commonListsContainer: css({
    '& > a': {
      alignItems: 'center',
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: '20px 16px',

      '& > div': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        width: 'fill-available',

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.normal,
        },
      },
    },
  }),
  pendingRevenueContainer: css({
    background: THEME.colors.white,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
    },

    '& > div': {
      display: 'grid',
      padding: 16,
      width: 'fill-available',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        padding: 20,
        width: 343,
      },

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        display: 'flex',
        gap: THEME.box.gaps.s,
        justifySelf: 'center',
        marginBottom: 8,

        '& > div': {
          color: THEME.font.colors.gray.main,
          fontSize: THEME.font.sizes.normal,
          fontWeight: 600,
        },

        [`@media (max-width: ${ViewportType.TABLET}px)`]: {
          justifySelf: 'flex-start',
        },
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: 36,
        fontWeight: 700,
        justifySelf: 'center',
        marginBottom: 8,

        [`@media (max-width: ${ViewportType.TABLET}px)`]: {
          justifySelf: 'flex-start',
        },
      },

      '& > div:nth-of-type(3)': {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        gap: THEME.box.gaps.xs,
        justifySelf: 'center',
        marginBottom: 18,

        '& > a': {
          color: THEME.font.colors.blue.main,
          fontSize: THEME.font.sizes.normal,
        },

        [`@media (max-width: ${ViewportType.TABLET}px)`]: {
          justifySelf: 'flex-start',
        },
      },

      '& > div:nth-of-type(4)': {
        display: 'flex',
        gap: THEME.box.gaps.s,

        '& > a, button': {
          borderRadius: 9,
          height: 48,
        },
      },
    },
  }),
  profileRegistrationContainer: css({
    '& > a': {
      alignItems: 'center',
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: '16px 12px',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
        width: 'fill-available',
      },

      '& > div:nth-of-type(2)': {
        alignItems: 'center',
        display: 'flex',
        gap: THEME.box.gaps.xs,
        justifyContent: 'flex-end',

        '& > div': {
          color: THEME.font.colors.gray.main,
          fontSize: THEME.font.sizes.subordinate,
        },
      },
    },
  }),
  socialConnectionContainer: css({
    '& > a': {
      alignItems: 'center',
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: '16px 12px',

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        width: 'fill-available',

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.normal,
        },
      },
    },
  }),
};

export default MyPage;
