import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '../functions';

export const useTranslateCountry = () => {
  const { t } = useTranslation();
  const tc = (value: string) => t(`Option.${value.includes('.') ? value.replace(/[.]/g, '') : value}`);

  return { tc };
};

const useTranslateOptions = (OPTIONS: Option[], key = 'Option'): Option[] => {
  const { t, i18n, ready } = useTranslation();

  return useMemo(
    () =>
      OPTIONS.map(option => ({
        ...option,
        label: t(`${key}.${option.label.includes('.') ? option.label.replace(/[.]/g, '') : option.label}`),
      })),
    [i18n.language, OPTIONS.length, ready]
  );
};

export default useTranslateOptions;
