import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';

export interface AvatarProps {
  className?: string;
  numberOfCharacter?: number;
  size?: number;
  src?: string;
  title?: string;
}

export const DEFAULT_SIZE = 50;

const Avatar = ({ className, numberOfCharacter = 1, size = DEFAULT_SIZE, src, title }: AvatarProps) => {
  const name = title ? title.slice(0, numberOfCharacter).toUpperCase() : '';
  const colors = [
    '#f6f8fa',
    '#ffb900',
    '#d83b01',
    '#b50e0e',
    '#e81123',
    '#b4009e',
    '#5c2d91',
    '#0078d7',
    '#00b4ff',
    '#008272',
    '#107c10',
  ];

  const calculateColor = (value: string) => {
    let sum = 0;
    for (let i = 0; i < value.length; i++) {
      sum += value.charCodeAt(i);
    }

    return colors[sum % colors.length];
  };

  return src ? (
    <img alt="avatar" className={className} css={styles.avatar} height={size} src={src} width={size} />
  ) : (
    <AvatarTitle className={className} color={calculateColor(title || '')} size={size}>
      {name}
    </AvatarTitle>
  );
};

const AvatarTitle = styled.div<{ color: string; size: number }>(({ color, size }) => ({
  alignItems: 'center',
  background: color,
  borderRadius: '50%',
  color: THEME.font.colors.white,
  display: 'flex',
  fontSize: Math.min(size - 15, 35),
  minHeight: size,
  minWidth: size,
  justifyContent: 'center',
}));

const styles = {
  avatar: css({
    border: '1px solid #ebebeb',
    borderRadius: '50%',
  }),
};

export default Avatar;
