import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { useSortable, CSS } from '@src/components/atoms/DragAndDropToolkit';
import { TextForm } from '@src/components/shared';
import { THEME } from '@src/libs/theme';

interface BlockCardProps {
  actionLabel: { genId: string; label: string; url: string };
  disabled?: boolean;
  error?: boolean;
  isDeletable: boolean;
  onChange: ({ label, url }: { label: string; url: string }) => void;
  onDelete: () => void;
}

const BlockCard = ({ actionLabel, disabled, error, isDeletable, onChange, onDelete }: BlockCardProps) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: actionLabel.genId,
  });
  const { t } = useTranslation();

  return (
    <Container
      css={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      disabled={disabled}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <Block isDragging={isDragging}>
        <Icomoon icon="six-dots-vertical" size={24} />
        <div>
          <div>
            <TextForm
              disabled={disabled}
              placeholder={t('See more')}
              title="Action Label"
              value={actionLabel.label}
              onChange={e => onChange({ label: e.target.value, url: actionLabel.url })}
            />
            {error && !actionLabel.label && <ErrorMessage message={'requiredFieldMessage'} />}
          </div>
          <div>
            <TextForm
              disabled={disabled}
              placeholder="http://anymindgroup.com"
              title="URL"
              value={actionLabel.url}
              onChange={e => onChange({ label: actionLabel.label, url: e.target.value })}
            />
            {error && !actionLabel.url && <ErrorMessage message={'requiredFieldMessage'} />}
          </div>
        </div>
      </Block>
      <Delete disabled={!isDeletable} onClick={() => isDeletable && onDelete()}>
        <Icomoon icon="trash-outlined" />
      </Delete>
    </Container>
  );
};

const Block = styled.div<{ isDragging: boolean }>(({ isDragging }) => ({
  alignItems: 'center',
  backgroundColor: THEME.colors.white,
  border: THEME.box.borders.gray.main,
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  gap: THEME.box.gaps.s,
  marginBottom: 8,
  opacity: isDragging ? 0.8 : 1,
  padding: '16px 8px',

  '& > svg': {
    cursor: 'grab',
  },

  '& > div': {
    display: 'grid',
    width: '100%',

    '& > div:nth-of-type(1)': {
      marginBottom: 16,
    },
  },
}));

const Container = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  gap: THEME.box.gaps.s,
  pointerEvents: disabled ? 'none' : 'auto',
}));

const Delete = styled.div<{ disabled: boolean }>(({ disabled }) => ({
  alignItems: 'center',
  backgroundColor: THEME.colors.white,
  borderRadius: 3,
  cursor: disabled ? 'default' : 'pointer',
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  opacity: disabled ? 0.5 : 1,
  width: 32,
}));

export default BlockCard;
