import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, List } from '@src/components/atoms';
import {
  ListLoading,
  Pager,
  SliderTableComponents,
  SliderTableStyledComponents,
  ThemeButton,
} from '@src/components/molecules';
import { getCurrentPage, getOffset, getPageInfo } from '@src/components/molecules/Pager/helpers';
import RecipientStatusIndicator from '@src/components/organisms/MyPage/Emails/RecipientStatusIndicator';
import { useGetEmailRecipientsCountQuery, useGetEmailRecipientsQuery } from '@src/graphql/hooks';
import { LIMIT } from '@src/libs/constant';
import { THEME } from '@src/libs/theme';
import { FilterItems } from './RecipientListFilter';
import useRecipient from './useRecipient';

interface RecipientListProps {
  emailId: string;
  filter: FilterItems;
}

const RecipientList = ({ emailId, filter }: RecipientListProps) => {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const { t } = useTranslation();
  const { exportEmailRecipientsCsv } = useRecipient();
  const currentPage = getCurrentPage();
  const { data, loading } = useGetEmailRecipientsQuery({
    variables: {
      input: {
        emailId,
        keyword: filter.keyword,
        limit: LIMIT,
        offset: getOffset(currentPage),
        ...(filter.status && { status: filter.status }),
      },
    },
  });
  const { data: dataEmailRecipientsCount } = useGetEmailRecipientsCountQuery({
    variables: {
      input: {
        emailId,
        keyword: filter.keyword,
        ...(filter.status && { status: filter.status }),
      },
    },
  });

  const onClickAllChecked = (status: boolean) => {
    const items = [...checkedIds];
    recipientsList.forEach(recipient => {
      const index = items.findIndex(checkedId => checkedId === recipient.fanId);
      if (status && index >= 0) {
        items.splice(index, 1);
      } else if (!status && index < 0) {
        items.push(recipient.fanId);
      }
    });
    setCheckedIds(items);
  };

  const onClickCheck = (id: string) => {
    const items = [...checkedIds];
    const index = items.findIndex(item => item === id);
    if (index > -1) {
      items.splice(index, 1);
    } else {
      items.push(id);
    }
    setCheckedIds(items);
  };

  const onClickExport = () => {
    exportEmailRecipientsCsv(emailId, checkedIds);
  };

  const recipientsList = data?.getEmailRecipients?.recipients || [];
  const getCheckedItemStatus = () => {
    let isAllCheckedStatus = checkedIds.length > 0;
    let isItemSelectedStatus = false;

    recipientsList.every(recipient => {
      if (!checkedIds.includes(recipient.fanId)) {
        isAllCheckedStatus = false;
      } else {
        isItemSelectedStatus = true;
      }

      if (!isAllCheckedStatus && isItemSelectedStatus) {
        return false;
      }

      return true;
    });

    return {
      isAllChecked: isAllCheckedStatus,
      isItemSelected: isItemSelectedStatus,
    };
  };
  const { isAllChecked, isItemSelected } = getCheckedItemStatus();
  const totalCount = dataEmailRecipientsCount?.getEmailRecipientsCount?.total || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  if (loading) {
    return <ListLoading />;
  }

  return (
    <div>
      <SliderTableComponents.Wrapper css={styles.container}>
        <SliderTableComponents.Container>
          <SliderTableComponents.SliderSection>
            <SliderTableComponents.SliderTable>
              <Thead isItemSelected={isItemSelected}>
                <tr>
                  <List.HeaderCheckboxColumn
                    checked={isAllChecked || isItemSelected}
                    css={{ padding: '0 12px' }}
                    indeterminate={!isAllChecked && isItemSelected}
                    onChange={checked => onClickAllChecked(!checked)}
                  />
                  {isItemSelected ? (
                    <th colSpan={3} css={{ height: 48 }}>
                      <div css={styles.actionContainer}>
                        <div>{t('Selected', { count: checkedIds.length })}</div>
                        <div>
                          <ThemeButton
                            text="Export CSV"
                            prefixIcon={<Icomoon icon="import" />}
                            onClick={onClickExport}
                          />
                        </div>
                      </div>
                    </th>
                  ) : (
                    <>
                      <List.HeaderColumn title="Name" />
                      <List.HeaderColumn title="Email" />
                      <List.HeaderColumn title="Status" />
                    </>
                  )}
                </tr>
              </Thead>

              <tbody>
                {recipientsList.map(recipient => {
                  const { email, fanId, name, statuses } = recipient;
                  const isChecked = checkedIds.includes(fanId);

                  return (
                    <SliderTableStyledComponents.StyledRowNew css={isChecked ? styles.activeTableRow : {}} key={fanId}>
                      <List.HeaderCheckboxColumn
                        checked={isChecked}
                        css={styles.columnCheckbox}
                        onChange={() => onClickCheck(fanId)}
                      />
                      <List.TextColumn data={<Link to={`/fans/${fanId}/details`}>{name}</Link>} />
                      <List.TextColumn data={email} />
                      <List.TextColumn
                        data={
                          !!statuses.length ? (
                            <div css={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                              {statuses.map((status, index) => (
                                <RecipientStatusIndicator key={index} status={status} />
                              ))}
                            </div>
                          ) : (
                            ''
                          )
                        }
                      />
                    </SliderTableStyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderTableComponents.SliderTable>
          </SliderTableComponents.SliderSection>
        </SliderTableComponents.Container>
      </SliderTableComponents.Wrapper>

      <Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </div>
  );
};

const Thead = styled.thead<{ isItemSelected: boolean }>(({ isItemSelected }) => ({
  backgroundColor: isItemSelected ? '#27313b' : '#f6f8fa',
  borderBottom: '1px solid #dfe7ec',
  borderTop: '1px solid #dfe7ec',
}));

const styles = {
  actionContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.white,
      display: 'flex',
      flex: 1,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },

    '& > div:nth-of-type(2)': {
      '& > button': {
        fontSize: THEME.font.sizes.subordinate,
        marginRight: 16,
        width: 124,
      },
    },
  }),
  activeTableRow: css({
    backgroundColor: '#eef3f7',

    '& > td': {
      backgroundColor: '#eef3f7',
    },
  }),
  columnCheckbox: css({
    borderTop: '1px solid #efefef',
    padding: '0 12px',
  }),
  container: css({
    border: '1px solid #eef3f7',
    margin: 0,
    padding: 0,
  }),
};

export default RecipientList;
