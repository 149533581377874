import React from 'react';
import { LiffProvider } from 'react-liff';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import LineIdTokenVerification from './LineIdTokenVerification';

const Index = () => {
  const { matchPath, params, pathname } = useQueryHelper();
  const { liffId } = params;
  const isIgSignIn = !!matchPath(ROUTES.LIFF_SIGN_IN_ID, pathname);

  return (
    <LiffProvider liffId={liffId as string}>
      <LineIdTokenVerification isIgSignIn={isIgSignIn} />
    </LiffProvider>
  );
};

export default Index;
