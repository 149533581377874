import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

interface SignInStateProps {
  lineIdToken?: string;
}

const { persistAtom } = recoilPersist();

export const signInState = atom<SignInStateProps>({
  key: 'signInState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
