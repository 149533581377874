import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  MarketplaceJoinedMethodsV2Query,
  MarketplaceJoinedMethodsV2QueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/MarketplaceJoinedMethodsV2.graphql';

export const useMarketplaceJoinedMethodsV2Query = (
  options?: QueryHookOptions<MarketplaceJoinedMethodsV2Query, MarketplaceJoinedMethodsV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<MarketplaceJoinedMethodsV2Query, MarketplaceJoinedMethodsV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useMarketplaceJoinedMethodsV2LazyQuery = (
  options?: LazyQueryHookOptions<MarketplaceJoinedMethodsV2Query, MarketplaceJoinedMethodsV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<MarketplaceJoinedMethodsV2Query, MarketplaceJoinedMethodsV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
