import React, { useState } from 'react';
import { Dialog } from '@src/components/molecules';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { getSocialMediaUrl } from '@src/libs/socialMedia';
import { useUpdateMarketplacePostTrackingUrlMutation } from '@src/graphql/hooks';
import { CampaignPromotionMethod, CampaignSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { mainWhite } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { MarketplaceCampaignAppliedStatus } from '@src/__generated__/globalTypes';
import { DialogAddPostsProps } from './types';
import { CopyHashtags, HelpYouCreateCaptions, InputPostUrl, HowToReportTweet } from './Steps';

interface CheckIsOnlyOneSM {
  isOnlyOneSM: boolean;
  socialMedia: CampaignPromotionMethod | undefined;
}

const DialogAddPosts = ({
  campaign,
  isFirstInit,
  joinedMethods,
  joinedMethodAccounts,
  open,
  onClickUseCaptionGenerator,
  onClose,
}: DialogAddPostsProps) => {
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, t } = useQueryHelper();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isSubmittingPostTrackingUrl, setIsSubmittingPostTrackingUrl] = useState<boolean>(false);
  const [postTrackingUrl, setPostTrackingUrl] = useState<string>(campaign?.postTrackingUrl || '');
  const [updatePostTrackingUrl] = useUpdateMarketplacePostTrackingUrlMutation({
    refetchQueries: ['MarketplaceForInfluencer'],
  });

  const isManualApprovalApplying = !!(
    campaign.isSelectionCampaign &&
    !campaign.isAutoInfluencerApproval &&
    [MarketplaceCampaignAppliedStatus.APPLYING, null].includes(
      campaign.appliedStatus as MarketplaceCampaignAppliedStatus | null
    )
  );
  // marketplace campaign will pass socialMedias prop
  const isCaptionGenerator =
    !isManualApprovalApplying &&
    campaign?.socialMedias?.find(s =>
      [
        CampaignSocialMediaType.FACEBOOK,
        CampaignSocialMediaType.INSTAGRAM,
        CampaignSocialMediaType.INSTAGRAM_STORY,
        CampaignSocialMediaType.TIKTOK,
        CampaignSocialMediaType.TWITTER,
        CampaignSocialMediaType.YOUTUBE,
      ].includes(s)
    );

  const isRequiredPostTrackingUrl = campaign.socialMedias?.includes(CampaignSocialMediaType.FACEBOOK);
  const isTwitterCampaign = campaign.socialMedias?.includes(CampaignSocialMediaType.TWITTER);

  const getSocialMedia = (socialMedia: CampaignPromotionMethod) =>
    [CampaignPromotionMethod.FACEBOOK, CampaignPromotionMethod.FACEBOOK_PAGE].includes(socialMedia)
      ? CampaignPromotionMethod.FACEBOOK
      : [CampaignPromotionMethod.INSTAGRAM, CampaignPromotionMethod.INSTAGRAM_STORY].includes(socialMedia)
      ? CampaignPromotionMethod.INSTAGRAM
      : socialMedia;

  const availableJoinedMethods: { type: CampaignPromotionMethod | SocialAccountType }[] =
    joinedMethodAccounts && joinedMethodAccounts.length
      ? joinedMethodAccounts.map(({ type }) => ({ type }))
      : joinedMethods && joinedMethods.length
      ? joinedMethods.map(({ type }) => ({ type }))
      : [];
  const { isOnlyOneSM, socialMedia } = availableJoinedMethods.reduce(
    (acc: CheckIsOnlyOneSM, currVal) => {
      const previousSM = acc.socialMedia ? getSocialMedia(acc.socialMedia) : undefined;
      const currSM = getSocialMedia(currVal.type as CampaignPromotionMethod);

      return {
        ...acc,
        isOnlyOneSM: !acc.socialMedia ? true : !acc.isOnlyOneSM ? false : previousSM === currSM,
        socialMedia: !acc.socialMedia ? (currVal.type as CampaignPromotionMethod) : acc.socialMedia,
      };
    },
    { isOnlyOneSM: false, socialMedia: undefined }
  ) || { isOnlyOneSM: false, socialMedia: undefined };
  const socialMediaUrl = isOnlyOneSM && socialMedia ? getSocialMediaUrl(socialMedia) : '';

  const onClickUpdatePostUrl = async () => {
    try {
      setIsSubmittingPostTrackingUrl(true);
      await updatePostTrackingUrl({
        variables: {
          input: {
            campaignId: Number(campaign.id),
            postTrackingUrl,
          },
        },
      });
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setIsSubmittingPostTrackingUrl(false);
    }
  };

  const onClickUseItNow = () => {
    onCloseDialog();
    onClickUseCaptionGenerator?.();
  };

  const onCloseDialog = () => {
    setCurrentStep(0);
    onClose();
  };

  const steps = [
    {
      content: CopyHashtags,
      footerProps: {
        ...((!isFirstInit && isRequiredPostTrackingUrl) || isTwitterCampaign || isCaptionGenerator
          ? {
              nextButtonProps: {
                onClick: () => setCurrentStep(currentStep + 1),
              },
            }
          : {
              cancelButtonProps: {
                text: 'Close',
                onClick: onCloseDialog,
              },
              ...(socialMediaUrl && {
                nextButtonProps: {
                  to: socialMediaUrl,
                  target: '_blank',
                },
              }),
            }),
      },
    },
    {
      content:
        !isFirstInit && isRequiredPostTrackingUrl
          ? InputPostUrl
          : isTwitterCampaign
          ? HowToReportTweet
          : isCaptionGenerator
          ? HelpYouCreateCaptions
          : 'div',
      footerProps: {
        cancelButtonProps: {
          text: 'Back',
          onClick: () => setCurrentStep(currentStep - 1),
        },
        ...(!isFirstInit && isRequiredPostTrackingUrl && !campaign.postTrackingUrl
          ? {
              nextButtonProps: {
                disabled: !postTrackingUrl,
                loading: isSubmittingPostTrackingUrl,
                onClick: onClickUpdatePostUrl,
              },
            }
          : isTwitterCampaign && isCaptionGenerator
          ? {
              nextButtonProps: {
                onClick: () => setCurrentStep(currentStep + 1),
              },
            }
          : isCaptionGenerator && socialMediaUrl
          ? {
              nextButtonProps: {
                to: socialMediaUrl,
                target: '_blank',
              },
            }
          : {
              nextButtonProps: {
                customPalette: mainWhite,
                text: 'Close',
                onClick: onCloseDialog,
              },
            }),
      },
    },
    {
      content: HelpYouCreateCaptions,
      footerProps: {
        cancelButtonProps: {
          text: 'Back',
          onClick: () => setCurrentStep(currentStep - 1),
        },
        ...(socialMediaUrl
          ? {
              nextButtonProps: {
                to: socialMediaUrl,
                target: '_blank',
              },
            }
          : {
              nextButtonProps: {
                customPalette: mainWhite,
                text: 'Close',
                onClick: onCloseDialog,
              },
            }),
      },
    },
  ];

  const { footerProps, content: Content } = steps[currentStep];

  return (
    <Dialog
      contentStyles={{
        backgroundColor: THEME.colors.white,
        borderRadius: 9,
        boxShadow: THEME.box.shadows.outer,
        height: isMobileView ? '74%' : 548,
        maxHeight: '90%',
        maxWidth: 648,
        width: '90%',
      }}
      open={open}
      onClose={onCloseDialog}
      {...footerProps}
    >
      <Content
        helpYouCreateCaptionsProps={{
          onClickUseItNow,
        }}
        postTrackingUrlProps={{
          readOnly: !!campaign.postTrackingUrl,
          value: postTrackingUrl,
          onChange: setPostTrackingUrl,
        }}
        {...campaign}
      />
    </Dialog>
  );
};

export default DialogAddPosts;
