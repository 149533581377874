import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { OnlyAvailablePlatformIndicator } from '@src/components/atoms';
import { useAddEmailTemplateMutation, useUpdateEmailTemplateMutation } from '@src/graphql/hooks';
import { useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import Form, { dateFormat, FormInformation } from './Form';

interface IndexProps extends FormInformation {
  backUrl?: string;
}

const Index = ({ backUrl, fans, id, isDraft, message, scheduleDate, sender, sendNow, subject, tags }: IndexProps) => {
  const { enqueueSnackbar, navigate, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const [addEmailTemplate] = useAddEmailTemplateMutation({
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.composeEmail);
      navigate(ROUTES.MY_PAGE_EMAILS);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });
  const [updateEmailTemplate] = useUpdateEmailTemplateMutation({
    refetchQueries: ['GetEmailTemplate'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editEmail, { emailId: id });
      navigate(ROUTES.MY_PAGE_EMAILS);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const defaultValues = {
    fans,
    id,
    isDraft,
    message,
    scheduleDate,
    sender,
    sendNow,
    subject,
    tags,
  };
  const validationSchema = yup.object().shape(
    {
      fans: yup.array().when('tags', {
        is: (items: string[]) => items && items.length <= 0,
        then: yup.array().required('requiredRecipient'),
      }),
      message: yup.string().required('requiredMessage'),
      scheduleDate: yup.string().when('sendNow', {
        is: false,
        then: yup.string().required('requiredScheduleDateMessage'),
      }),
      sender: yup.string().email().required('requiredEmailMessage'),
      subject: yup.string().required('requiredSubjectMessage'),
      tags: yup.array().when('fans', {
        is: (items: string[]) => items && items.length <= 0,
        then: yup.array().required('requiredRecipient'),
      }),
    },
    [['fans', 'tags']]
  );
  const methods = useForm<FormInformation>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (values: FormInformation) => {
    if (!values.id) {
      await addEmailTemplate({
        variables: { input: { ...values, scheduleDate: values.sendNow ? null : values.scheduleDate } },
      });
    } else {
      await updateEmailTemplate({
        variables: { input: { ...values, id: values.id, scheduleDate: values.sendNow ? null : values.scheduleDate } },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {isMobileView ? <OnlyAvailablePlatformIndicator to={backUrl || ''} /> : <Form onSubmit={onSubmit} />}
      </form>
    </FormProvider>
  );
};

export default Index;
export { dateFormat };
