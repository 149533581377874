import React from 'react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';

interface RadioIconProps {
  isChecked: boolean;
  onClick?: () => void;
  className?: string;
}
const RadioIcon = (props: RadioIconProps) => {
  const { className, ...iconProps } = props;

  return (
    <div className={className}>
      <Icon {...iconProps} />
    </div>
  );
};

const Icon = styled.div<RadioIconProps>(({ isChecked }) => ({
  width: 16,
  height: 16,
  background: THEME.colors.white,
  boxShadow: THEME.box.shadows.outer,
  borderRadius: '50%',
  border: isChecked ? 'solid 4px #3892e5' : 'solid 1px #c5d0da',
  boxSizing: 'border-box',
  cursor: 'pointer',
}));

export default RadioIcon;
