import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  JoinAutoManagedCampaignMutation,
  JoinAutoManagedCampaignMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/JoinAutoManagedCampaign.graphql';

export const useJoinAutoManagedCampaignMutation = (
  options?: MutationHookOptions<JoinAutoManagedCampaignMutation, JoinAutoManagedCampaignMutationVariables>
) => useMutation<JoinAutoManagedCampaignMutation, JoinAutoManagedCampaignMutationVariables>(MUTATION, options);
