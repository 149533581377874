import { format } from 'date-fns';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import {
  useYoutubeCmsConfirmedRevenueForInfluencerQuery,
  useYoutubeCmsRevenueForInfluencerQuery,
} from '@src/graphql/hooks';

interface UseYoutubeCmsRevenueProps {
  skip: boolean;
}

const useYoutubeCmsRevenue = (props?: UseYoutubeCmsRevenueProps) => {
  const { skip } = { ...props };
  const date = new Date();
  const firstDayOfTheMonth = format(new Date(date.getFullYear(), date.getMonth(), 1), DEFAULT_FNS_DATE_FORMAT);

  const { data: dataConfirmedRevenue, loading: loadingConfirmedRevenue } =
    useYoutubeCmsConfirmedRevenueForInfluencerQuery({
      skip,
      variables: {
        month: firstDayOfTheMonth,
      },
    });
  const { data: dataRevenue, loading: loadingRevenue } = useYoutubeCmsRevenueForInfluencerQuery({
    skip,
    variables: {
      month: firstDayOfTheMonth,
    },
  });

  return {
    isConfirmedRevenue: !!dataConfirmedRevenue?.youtubeCmsConfirmedRevenueForInfluencer?.revenue,
    isYoutubeCmsRevenue:
      !!dataConfirmedRevenue?.youtubeCmsConfirmedRevenueForInfluencer?.revenue ||
      !!dataRevenue?.youtubeCmsRevenueForInfluencer?.revenue,
    loading: loadingConfirmedRevenue || loadingRevenue,
  };
};

export default useYoutubeCmsRevenue;
