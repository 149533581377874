import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as React from 'react';
import { TermsTitle } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

const TermsContentJP = () => (
  <section css={styles.wrapper}>
    <TermsTitle title={`AnyCreator利用規約 `} />
    <RegularText>
      本利用規約（以下「本規約」といいます。）には、AnyMind Group
      Pte.Ltd.（以下「当社」といいます。）の提供する本サービス（第2条に定義）のご利用にあたり、ユーザーの皆様に遵守していただかなければならない事項及び当社とユーザーの皆様との間の権利義務関係が定められております。本サービスをご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
    </RegularText>

    {/* Article 1 */}
    <Article>
      第1条 <span>適　用</span>
    </Article>
    <OrderedList>
      <li>
        本規約は、本サービスの利用に関する当社と登録クリエイター（第2条に定義）との間の権利義務関係を定めることを目的とし、登録クリエイターと当社の間の本サービスの利用に関わる一切の関係に適用されます。
      </li>
      <li>
        当社が当社ウェブサイト（第2条に定義）又は当社アプリケーション（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
      </li>
    </OrderedList>

    {/* Article 2 */}
    <Article>
      第2条 <span>定　義</span>
    </Article>
    <RegularText>本規約において使用する以下の用語は各々以下に定める意味を有するものとします。</RegularText>
    <OrderedList css={styles.resetListStyle}>
      <li>(1) 「インフルエンサー業務」とは、第6条第1項で定義される「インフルエンサー業務」を意味します。</li>
      <li>
        (2) 「インフルエンサー業務委託契約」とは、第6条第5項で定義される「インフルエンサー業務委託契約」を意味します。
      </li>
      <li>
        (3)
        「SNS」とは、Facebook、その他の当社以外の事業者が提供している当社所定のソーシャル・ネットワーキング・サービスで、登録クリエイターの認証、友人関係の開示、当該外部ソーシャル・ネットワーク内へのコンテンツの公開などの機能を持ち、本サービスの実施に利用されるサービスを意味します。
      </li>
      <li>(4) 「SNSアカウント」とは、SNSにおいて特定の個人又は法人が管理するアカウントを意味します。</li>
      <li>(5) 「SNSアカウント分析サービス」とは、第5条第4項に定義される「SNSアカウント分析サービス」を意味します。</li>
      <li>(6) 「SNS事業者」とは、SNSのサービス提供者を意味します。</li>
      <li>(7) 「SNS利用規約」とは、登録クリエイターとSNS事業者との権利関係を定める規約を意味します。</li>
      <li>(8) 「SNS連携」とは、第5条第1項に定義される「SNS連携」を意味します。</li>
      <li>(9) 「広告主」とは、第6条第4項に定義される「広告主」を意味します。</li>
      <li>(10) 「成果物」とは、第7条第3項に定義される「成果物」を意味します。</li>
      <li>
        (11)
        「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
      </li>
      <li>
        (12)
        「当社ウェブサイト」とは、そのドメインが「any-creator.com」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
      </li>
      <li>(13) 「当社アプリケーション」とは、当社が本サービスに関連して提供するアプリケーションを意味します。</li>
      <li>(14) 「登録希望者」とは、第3条第1項において定義された「登録希望者」を意味します。</li>
      <li>(15) 「登録情報」とは、第3条第1項において定義された「登録情報」を意味します。</li>
      <li>
        (16) 「登録クリエイター」とは、第3条第4項に基づき本サービスの利用者としての登録がなされた個人を意味します。
      </li>
      <li>
        (17)
        「本サービス」とは、当社が提供するAnyCreatorという名称のクリエイターの業務を支援するサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
      </li>
      <li>(18) 「利用契約」とは、第3条第4項に定義される「利用契約」を意味します。</li>
    </OrderedList>

    {/* Article 3 */}
    <Article>
      第3条 <span>登　録</span>
    </Article>
    <OrderedList>
      <li>
        本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
      </li>
      <li>
        登録の申請は必ず本サービスを利用する個人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
      </li>
      <li>
        当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
        <OrderedList css={styles.resetListStyle}>
          <li>(1) 本規約に違反するおそれがあると当社が判断した場合</li>
          <li>(2) 当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</li>
          <li>(3) 過去に本サービスの利用の登録を取り消された者である場合</li>
          <li>
            (4)
            未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
          </li>
          <li>
            (5)
            反社会的勢力等（暴力団、暴力団員、暴力団準構成員、暴力団員又は暴力団準構成員でなくなった日から5年を経過しない者、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
          </li>
          <li>(6) その他、当社が登録を適当でないと合理的に判断した場合</li>
        </OrderedList>
      </li>
      <li>
        当社は、前項その他当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知します。かかる通知により登録希望者の登録クリエイターとしての登録は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）が登録クリエイターと当社の間に成立します。
      </li>
      <li>
        登録クリエイターは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
      </li>
      <li>
        当社は、登録情報のうち以下の事項を、当社が提供するインフルエンサーによる広告宣伝その他の業務を希望する企業等に提供するサービスであるAnyTag（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）において、AnyTagのユーザーに提供することができるものとし、登録クリエイターはあらかじめこれに同意するものとします。
        <OrderedList css={styles.resetListStyle}>
          <li>(1) 名前</li>
          <li>(2) カテゴリ</li>
          <li>(3) 性別</li>
          <li>(4) 国</li>
          <li>(5) 地域</li>
          <li>(6) メールアドレス</li>
          <li>(7) 電話番号</li>
          <li>(8) 誕生日</li>
          <li>(9) 自己紹介</li>
          <li>(10) 投稿希望価格</li>
          <li>
            (11)
            投稿情報（投稿数、投稿画像、投稿エンゲージメントなど）、フォロワーの属性（年齢性別、国地域）等のSNSの情報
          </li>
        </OrderedList>
      </li>
    </OrderedList>

    {/* Article 4 */}
    <Article>
      第4条 <span>本サービスの利用</span>
    </Article>
    <RegularText>
      登録クリエイターは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
    </RegularText>

    {/* Article 5 */}
    <Article>
      第5条 <span>SNSアカウント分析サービス</span>
    </Article>
    <OrderedList>
      <li>
        登録クリエイターは、本サービス内で、当社の定める方法に従い、自身が管理するSNSアカウントを本サービスと連携（以下「SNS連携」といいます。）することができます。
      </li>
      <li>
        登録クリエイターが、前項に基づきSNS連携をした場合、当社は、連携したSNSアカウントが登録されているSNSを運営するSNS事業者が提供する情報を取得することができるものとし、登録クリエイターはあらかじめこれに同意するものとします。
      </li>
      <li>
        登録クリエイターは、SNS連携に際し、登録クリエイター及び当社によるSNS連携及びそれに基づく情報の取得にSNS事業者の同意その他必要な手続がある場合、当該手続を行うものとします。
      </li>
      <li>
        当社は、SNS連携がなされたSNSアカウントに関する情報のうち当社が定める情報を、当社所定の方法により分析します。登録クリエイターは当該分析の結果を閲覧することができます（以下当該分析及びその結果の閲覧に関するサービスを「SNSアカウント分析サービス」といいます。）。
      </li>
      <li>
        当社は、SNSアカウント分析サービスの対象とする情報及び当該情報の分析方法を、自己の裁量で随時変更することができるものとし、登録クリエイターはこれに異議を述べないものとします。
      </li>
      <li>
        当社は、SNS事業者が提供する情報に基づいてSNSアカウント分析サービスを提供するものとし、SNS事業者が提供する情報の正確性及び真実性その他一切の事項について保証を行うものではありません。当社は、登録クリエイターがSNSアカウント分析サービスを利用したこと及び分析の結果に基づき行った行為により生じた損害につき、当社の責に帰すべき場合を除き、責任を負いません。
      </li>
      <li>
        当社は、SNS連携及びSNSアカウント分析サービスにより得られた情報を、AnyTagにおいて、AnyTagのユーザーに提供することができるものとし、登録クリエイターはあらかじめこれに同意するものとします。
      </li>
    </OrderedList>

    {/* Article 6 */}
    <Article>
      第6条 <span>インフルエンサー業務の受託</span>
    </Article>
    <OrderedList>
      <li>
        当社は、本サービス内で、登録クリエイターが管理するSNSアカウントへの所定の内容の投稿その他の業務（以下「インフルエンサー業務」といいます。）の委託先を募集することがあります。登録クリエイターは、本サービス内で、当社の定める方法に従い、当該インフルエンサー業務の受託を申込むことができます。
      </li>
      <li>
        前項のインフルエンサー業務の委託先の募集にあたり、当社は、当該インフルエンサー業務に関する応募条件及び契約条件を登録クリエイターに対して提示するものとし、登録クリエイターは、インフルエンサー業務の受託の申込を行う場合、当該条件を確認の上申込をするものとします。但し、登録クリエイターは、申込に先立ち、あらかじめSNS連携を行う必要があります。
      </li>
      <li>
        当社は、当社が定める基準に従って、インフルエンサー業務の委託先を選考するものとします。当社は、インフルエンサー業務の受託の申込を行った登録クリエイターに対して当社の裁量により資料の提出、面談その他当社が選考に必要であると合理的に判断する行為を求めることができ、当該登録クリエイターはこれに協力するものとします。
      </li>
      <li>
        当社は、前項に基づく選考に際し、インフルエンサー業務の受託の申込を行った登録クリエイターの登録情報及び前項に基づく選考の過程で当社が取得した情報その他の情報を、選考に必要な範囲で当社に商品の広告等を委託する者（以下「広告主」といいます。）その他の第三者に対して提供することができるものとし、登録クリエイターはあらかじめこれに同意するものとします。
      </li>
      <li>
        当社は、第3項の選考の結果、インフルエンサー業務を委託することを決定した登録クリエイターに対してその旨を連絡するものとします。当該連絡がなされた時点で、当社が第2項に基づき提示した条件で、連絡を受けた登録クリエイターと当社の間に、インフルエンサー業務の委託に関する契約（以下「インフルエンサー業務委託契約」といいます。）が成立するものとします。
      </li>
      <li>
        本規約の条項とインフルエンサー業務委託契約の条項が異なる場合には、インフルエンサー業務委託契約の条項が優先して効力を有するものとします。
      </li>
      <li>
        当社は、第5項に従ってインフルエンサー業務を委託することを決定した登録クリエイターに対してその旨の連絡を行う場合を除き、登録クリエイターに対して、選考の結果及びその理由を連絡及び開示する義務を負わず、登録クリエイターは本項に基づく当社の対応に異議を述べないものとします。
      </li>
      <li>
        登録クリエイターは、自身が締結したインフルエンサー業務に関する業務の履行状況その他当社が定める情報を本サービス内で閲覧することができるものとします。
      </li>
      <li>
        当社は、前項の情報につきその最新性その他一切の事項について保証を行うものではありません。当社は、登録クリエイターが当該情報を利用したこと及び当該情報に基づき行った行為により生じた損害につき、当社の責に帰すべき場合を除き、責任を負いません。
      </li>
    </OrderedList>

    {/* Article 7 */}
    <Article>
      第7条 <span>インフルエンサー業務の履行</span>
    </Article>
    <OrderedList>
      <li>
        登録クリエイターは、前条に基づき当社とインフルエンサー業務委託契約を締結した場合、当該契約に従って、自身が管理するSNSアカウントへの投稿その他当該契約に定めた業務を行い、当該業務の履行の完了を示す情報その他の当社が指定する情報を提供するものとします。
      </li>
      <li>
        登録クリエイターは、インフルエンサー業務委託契約に基づく義務を自ら履行するものとし、当社が書面により事前に承諾しない限り、当該義務の履行の全部又は一部を他の第三者に再委託することはできないものとします。
      </li>
      <li>
        登録クリエイターがインフルエンサー業務委託契約に基づき作成した画像、文章、動画を含む成果物（以下「成果物」といいます。）に関し、当社は、インフルエンサー業務委託契約及び当社所定の基準に基づき、成果物のチェックを行うものとします。成果物がインフルエンサー業務委託契約又は当社所定の基準に従っていないと当社が合理的に判断した場合、当社は、登録クリエイターに対し再度のSNSアカウントへの投稿その他の合理的な指示を行うことができるものとし、登録クリエイターはこれに従うものとします。
      </li>
      <li>
        登録クリエイターは、インフルエンサー業務委託契約に基づき行ったSNSへの投稿をインフルエンサー業務委託契約に定めた期間、削除しないものとします。
      </li>
      <li>
        前項の定めにかかわらず、当社は、次の各号のいずれかに該当したときは、登録クリエイターに対してインフルエンサー業務委託契約に基づく投稿の削除（登録クリエイター自身が削除を行うことができない場合には、SNS事業者等に対する削除申請を行うことを含みます。）を指示することができるものとし、登録クリエイターは、かかる指示に従うものとします。
        <OrderedList css={styles.resetListStyle}>
          <li>(1) 当該投稿の内容がインフルエンサー業務委託契約の定めと異なる場合</li>
          <li>(2) 当該投稿の内容が不適切であると当社が判断する場合</li>
          <li>
            (3) 風評被害など登録クリエイター又は広告主のいずれかに対する危害が発生する可能性が高いと当社が判断した場合
          </li>
        </OrderedList>
      </li>
      <li>
        当社は、インフルエンサー業務委託契約に基づき、登録クリエイターに対してサンプル品その他の物品を提供することがあります。登録クリエイターが成果物の提供その他インフルエンサー業務委託契約に定める業務を行わなかった場合、登録クリエイターは、当社の指示に基づき、当社に対して当該物品を速やかに返還するものとし、返還に係る費用は登録クリエイターが負担するものとします。当該物品が、提供を受けた当時の状態から損耗していると当社が判断する場合には、登録クリエイターは、当社が算定する弁償費用を当社に支払うものとします。
      </li>
      <li>
        前項に基づき提供されるサンプル品その他の物品は、現状有姿で提供されるものであり、当社は、当該物品の完全性その他について保証するものではありません。登録クリエイターが当該物品を利用したことにより登録クリエイターに生じた損害について、当社の責めに帰すべき事由がある場合を除き、当社は責任を負いません。
      </li>
    </OrderedList>

    <Article>
      第8条 <span>インフルエンサー業務委託契約に基づく対価の支払い</span>
    </Article>
    <OrderedList>
      <li>
        当社は、登録クリエイターに対し、インフルエンサー業務委託契約に定める対価を、当該インフルエンサー業務委託契約に定める成果物の提供その他の業務がすべて完了し、前条第3項に基づくチェック及びチェックに基づく措置が完了した日を基準として、毎月15日締翌月末日払いにて、登録クリエイターが別途当社所定の手続きにより口座登録することにより指定した口座に振り込むことにより支払うものとします。
      </li>
      <li>
        登録クリエイターは、1回の支払につき、330円（税込）を支払に係る手数料として負担するものとし、当社は当該手数料を振込金額から差し引くことができるものとします。
      </li>
      <li>
        第1項に基づく当社の対価の支払い義務は、登録クリエイターが登録した口座に当社が対価を支払うことにより履行されるものとし、口座番号の誤りその他の理由により登録クリエイターが支払いを受けられなかった場合でも、当社は、当社の責めに帰すべき事由がある場合を除き、登録クリエイターに生じた損害を賠償する責任を負わないものとします。
      </li>
      <li>
        第1項にかかわらず、インフルエンサー業務委託契約に定める業務が履行されなかった場合には、当社はインフルエンサー業務委託契約の対価を支払わないものとします。ただし、当社は、登録クリエイターによる前日までの報告があった場合、当日の不慮の事故があった場合その他当社が認める場合には本項を適用しないことができ、又はインフルエンサー業務委託契約の対価の総額の一部のみを支払うこととすることができるものとします。
      </li>
      <li>
        前項本文の場合において、業務が履行されなかったインフルエンサー業務委託契約の対価が既に登録クリエイターに対し支払われていたときは、当社は登録クリエイターに対し、前項に基づき支払われないこととなった対価の返金を請求することができるものとし、返金に係る費用は登録クリエイターの負担とします。
      </li>
      <li>
        当社は、登録クリエイターに対する金銭支払債務（本規約に定めるインフルエンサー業務委託契約の対価の支払債務を含みますが、これに限られないものとします。）を負う場合には、前項に定める返金請求権と対当額で相殺することができるものとします。
      </li>
      <li>
        第4項から前項までの規定は、当社が登録クリエイターに対して、損害賠償請求を行うことを妨げるものではありません。
      </li>
      <li>
        インフルエンサー業務委託契約に基づく業務の遂行のために必要となる登録クリエイターの人件費、資材費、旅費、宿泊費その他の諸費用は、インフルエンサー業務委託契約の対価に含まれるものとし、登録クリエイターは別途それらの諸費用を当社に請求することはできないものとします。
      </li>
      <li>
        前項にかかわらず、当社の負担費用がインフルエンサー業務委託契約において定められている場合は、登録クリエイターは、前項に定める諸費用のうち当社の負担費用に限り、当社に請求することができるものとします。
      </li>
    </OrderedList>

    {/* Article 9 */}
    <Article>
      第9条 <span>インフルエンサー業務委託契約に関する知的財産権</span>
    </Article>
    <OrderedList>
      <li>
        成果物の著作権は、インフルエンサー業務委託契約において別段の定めのない限り、登録クリエイターに帰属するものとします。
      </li>
      <li>
        当社及び広告主は、前項の成果物を、無償で利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとします。
      </li>
      <li>
        登録クリエイターは、インフルエンサー業務委託契約に基づく業務の履行の過程で作成された著作物について、著作者人格権を行使しないものとします。
      </li>
      <li>
        第1項にかかわらず、インフルエンサー業務委託契約において登録クリエイター以外の第三者（登録クリエイターが手配した第三者を除きます。）が成果物を制作する場合その他当社が定める場合、インフルエンサー業務委託契約に基づく業務の過程で登録クリエイターに生じた知的財産権は、発生と同時に当社に移転するものとします。
      </li>
      <li>
        登録クリエイターは、第三者が知的財産権を有するものを成果物その他のインフルエンサー業務委託契約に基づく業務に用いる場合には、当社の事前の承諾を得るものとします。登録クリエイターは、第三者が知的財産権を有するものを用いる場合には、当社が当該知的財産権を利用することにつき当該第三者の同意を得るなど権利処理について適切な措置を行うものとします。
      </li>
    </OrderedList>

    {/* Article 10 */}
    <Article>
      第10条 <span>インフルエンサー業務委託契約に関する登録クリエイターの保証</span>
    </Article>
    <OrderedList>
      <li>
        登録クリエイターは、インフルエンサー業務委託契約に基づく業務の実施に当たり第三者の権利を侵害しないように留意すると共に、当該業務の遂行又は成果物が、第三者のいかなる権利も侵害していないことを当社に保証するものとします。
      </li>
      <li>
        前項の定めにかかわらず、インフルエンサー業務委託契約に基づく業務又は成果物について、第三者から当社に対して何らかの訴え、異議、請求等がなされた場合において、当社から処理の要請がなされたときは、登録クリエイターは自己の責任と費用負担において、当社に代わって当該第三者との紛争を処理するとともに、当社がかかる訴え、異議、請求等により被った一切の損害（弁護士費用を含みます。）を賠償するものとします。
      </li>
    </OrderedList>

    {/* Article 11 */}
    <Article>
      第11条 <span>アカウント情報の管理</span>
    </Article>
    <OrderedList>
      <li>
        登録クリエイターは、自己の責任において、本サービスにかかるユーザーID及びパスワード（以下「アカウント情報」といいます。）を管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
      </li>
      <li>
        アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録クリエイターが負うものとし、当社は一切の責任を負いません。
      </li>
      <li>
        登録クリエイターは、アカウント情報が盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
      </li>
    </OrderedList>

    {/* Article 12 */}
    <Article>
      第12条 <span>禁止行為</span>
    </Article>
    <OrderedList>
      <li>
        登録クリエイターは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
        <OrderedList css={styles.resetListStyle}>
          <li>
            (1)
            当社、又は他の登録クリエイター、SNS事業者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
          </li>
          <li></li>
          <li>(2) 犯罪行為に関連する行為又は公序良俗に反する行為</li>
          <li>(3) 法令又は当社若しくは登録クリエイターが所属する業界団体の内部規則に違反する行為</li>
          <li>(4) コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為</li>
          <li>(5) 当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為</li>
          <li>
            (6)
            広告主との間で、本サービスを介さず直接インフルエンサー業務に関連する契約を締結し、又は締結しようとする行為
          </li>
          <li>(7) 当社による本サービスの運営を妨害するおそれがあると合理的に認められる行為</li>
          <li>(8) その他、当社が不適切と合理的に判断する行為</li>
        </OrderedList>
      </li>
      <li>
        当社は、本サービスにおける登録クリエイターによる情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が合理的に判断した場合には、登録クリエイターに事前に通知することなく、当該情報の全部又は一部について削除、送信停止その他の措置をとることができるものとします。当社は、本項に基づき当社が行った措置に基づき登録クリエイターに生じた損害について一切の責任を負いません。
      </li>
    </OrderedList>

    {/* Article 13 */}
    <Article>
      第13条 <span>本サービスの停止等</span>
    </Article>
    <OrderedList>
      <li>
        当社は、以下のいずれかに該当する場合には、登録クリエイターに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
        <OrderedList css={styles.resetListStyle}>
          <li>(1) 本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合</li>
          <li>(2) コンピューター、通信回線等が事故により停止した場合</li>
          <li>(3) 火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
          <li>
            (4) SNSサービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
          </li>
          <li>(5) その他、当社が停止又は中断を合理的に必要と判断した場合</li>
        </OrderedList>
      </li>
      <li>
        当社は、当社の合理的な判断により、本サービスの提供を終了することができます。この場合、当社は登録クリエイターに事前に通知するものとします。
      </li>
      <li>当社は、本条に基づき当社が行った措置に基づき登録クリエイターに生じた損害について一切の責任を負いません。</li>
    </OrderedList>

    {/* Article 14 */}
    <Article>
      第14条 <span>設備の負担等</span>
    </Article>
    <OrderedList>
      <li>
        本サービスの提供を受けるために必要な、コンピューター、スマートフォン、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、登録クリエイターの費用と責任において行うものとします。
      </li>
      <li>
        登録クリエイターは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
      </li>
      <li>
        当社は、登録クリエイターが送受信したメッセージその他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。
      </li>
      <li>
        登録クリエイターは、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法により当社アプリケーションを含むソフトウェア等を登録クリエイターのコンピューター、スマートフォン等にインストールする場合には、登録クリエイターが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとします。
      </li>
    </OrderedList>

    {/* Article 15 */}
    <Article>
      第15条 <span>権利帰属</span>
    </Article>
    <OrderedList>
      <li>
        当社ウェブサイト、当社アプリケーション及び本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本規約において明示されているものを除き、当社ウェブサイト、当社アプリケーション又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。登録クリエイターは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
      </li>
      <li>
        当社ウェブサイト、当社アプリケーション又は本サービスにおいて、登録クリエイターが投稿その他送信を行った文章、画像、動画その他のデータについては、当社において、無償で自由に利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとします。
      </li>
    </OrderedList>

    {/* Article 16 */}
    <Article>
      第16条 <span>登録取消等</span>
    </Article>
    <OrderedList>
      <li>
        当社は、登録クリエイターが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該登録クリエイターについて本サービスの利用を一時的に停止し、又は登録クリエイターとしての登録を取り消すことができます。
        <OrderedList css={styles.resetListStyle}>
          <li>(1) 本規約又はインフルエンサー業務委託契約のいずれかの条項に違反した場合</li>
          <li>(2) 登録情報に虚偽の事実があることが判明した場合</li>
          <li>
            (3)
            当社、他の登録クリエイター、SNS事業者その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
          </li>
          <li>
            (4)
            SNS利用規約に違反したことその他の理由によって、登録クリエイターがSNS事業者から、そのサービスの提供や連携を受けられなくなった場合
          </li>
          <li>(5) 手段の如何を問わず、本サービスの運営を妨害した場合</li>
          <li>
            (6)
            支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、若しくはこれらに類する手続の開始の申立てがあった場合
          </li>
          <li>
            (7)
            自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
          </li>
          <li>(8) 差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合</li>
          <li>(9) 租税公課の滞納処分を受けた場合</li>
          <li>(10) 死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合</li>
          <li>(11) 3ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合</li>
          <li>(12) 第3条第3項各号に該当する場合</li>
          <li>(13) その他、当社が登録クリエイターとしての登録の継続を適当でないと合理的に判断した場合</li>
        </OrderedList>
      </li>
      <li>
        前項各号のいずれかの事由に該当した場合、登録クリエイターは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      </li>
      <li>
        登録クリエイターは、7日前までに当社所定の方法で当社に通知することにより、登録クリエイターの登録を取り消すことができます。
      </li>
      <li>当社は、本条に基づき当社が行った行為により登録クリエイターに生じた損害について一切の責任を負いません。</li>
      <li>
        登録クリエイターの登録が取り消された場合、登録クリエイターは、当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他の物につき、返還、廃棄その他の処分を行うものとします。
      </li>
    </OrderedList>

    {/* Article 17 */}
    <Article>
      第17条 <span>保証の否認及び免責</span>
    </Article>
    <OrderedList>
      <li>
        当社は、登録クリエイターのインフルエンサー業務の受託その他本サービスを利用したことによる効果につき如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
      </li>
      <li>
        å登録クリエイターが当社から直接又は間接に、本サービス、当社ウェブサイト、当社アプリケーション、本サービスの他の登録クリエイターその他の事項に関する何らかの情報を得た場合であっても、当社は登録クリエイターに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
      </li>
      <li>
        本サービスは、SNSサービスとの連携を保証するものではなく、SNSサービスとの連携の支障等について、当社の責に帰すべき場合を除き、当社は一切の責任を負いません。
      </li>
      <li>
        本サービスがSNSサービスと連携している場合において、登録クリエイターはSNS利用規約を自己の費用と責任で遵守するものとし、その違反によって登録クリエイターと当該SNSサービスを運営するSNS事業者との間で紛争等が生じた場合でも、当社は当該紛争等について一切の責任を負いません。
      </li>
      <li>
        登録クリエイターは、本サービスを利用することが、登録クリエイターに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、登録クリエイターによる本サービスの利用が、登録クリエイターに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
      </li>
      <li>
        登録クリエイターと広告主の間において生じた紛争等については、登録クリエイターの責任において処理及び解決するものとし、当社の責に帰すべき場合を除き、当社はかかる事項について一切責任を負いません。
      </li>
      <li>
        当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、登録クリエイターのメッセージ又は情報の削除又は消失､登録クリエイターの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録クリエイターが被った損害につき、当社の責に帰すべき場合を除き、賠償する責任を一切負わないものとします。
      </li>
      <li>
        当社ウェブサイト若しくは当社アプリケーションから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して、当社の責に帰すべき場合を除き、一切の責任を負わないものとします。
      </li>
      <li>
        当社は、当社の合理的な支配の及ばない状況（火事、停電、ハッキング、コンピューター・ウィルスの侵入、地震、洪水、戦争、疫病、通商停止、ストライキ、暴動、物資及び輸送施設の確保不能、政府当局若しくは地方自治体による介入、指示若しくは要請、又は内外法令の制定若しくは改廃を含みますがこれらに限定されません。）により利用契約上の義務を履行できない場合、その状態が継続する期間中登録クリエイターに対し債務不履行責任を負わないものとします。
      </li>
      <li>
        登録クリエイターの登録及び利用契約の締結は、登録クリエイターと当社の間にマネジメント契約その他の、本規約に定める以外の関係を生じさせるものではないことを登録クリエイターは確認するものとします。
      </li>
      <li>
        当社の賠償責任は、直接かつ通常の損害に限り、逸失利益、事業機会の喪失等の間接的な損害は含まないものとし、当社が登録クリエイターに対して損害賠償責任を負う場合においても、当社の賠償責任は、当該賠償責任に関連する個別のインフルエンサー業務委託契約における対価の額を上限とします。
      </li>
    </OrderedList>

    {/* Article 18 */}
    <Article>
      第18条 <span>賠償等の責任</span>
    </Article>
    <OrderedList>
      <li>
        登録クリエイター及び当社は、本規約に違反することにより、又は本サービスの利用に関連して相手方に損害を与えた場合、相手方に対しその損害を賠償するものとします。
      </li>
      <li>
        登録クリエイターは、本サービスに関連して他の登録クリエイター、SNS事業者その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、登録クリエイターの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
      </li>
    </OrderedList>

    {/* Article 19 */}
    <Article>
      第19条 <span>秘密保持</span>
    </Article>
    <OrderedList>
      <li>
        本規約において「秘密情報」とは、利用契約又は本サービスに関連して、登録クリエイター又は当社が、相手方より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、相手方の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味するものとし、インフルエンサー業務（当社が現在又は過去に募集した全てのインフルエンサー業務を意味し、登録クリエイターが現に受託したものに限りません。）の存在及び内容（広告主に関する情報を含みます。）を含むものとします。但し、(1)相手方から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)相手方から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)相手方から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。
      </li>
      <li>
        登録クリエイター及び当社は、秘密情報を本サービスの利用の目的のみに利用するとともに、相手方の書面による承諾なしに第三者（当社の関係会社を除きます。）に秘密情報を提供、開示又は漏洩しないものとします。
      </li>
      <li>
        第2項の定めに拘わらず、登録クリエイター及び当社は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を相手方に通知するものとします。
      </li>
      <li>
        登録クリエイター及び当社は、相手方から求められた場合にはいつでも、遅滞なく、相手方の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄するものとします。
      </li>
    </OrderedList>

    {/* Article 20 */}
    <Article>
      第20条 <span>個人情報等の取扱い</span>
    </Article>
    <OrderedList>
      <li>
        当社による登録クリエイターの個人情報（個人情報の保護に関する法律第2条第1項に定める「個人情報」を意味します。）の取扱いについては、別途定める当社のプライバシーポリシーの定めによるものとし、登録クリエイターはこのプライバシーポリシーに従って当社が登録クリエイターの個人情報を取扱うことについて同意するものとします。
      </li>
      <li>
        当社は、登録クリエイターが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録クリエイターはこれに異議を述べないものとします。
      </li>
    </OrderedList>

    {/* Article 21 */}
    <Article>
      第21条 <span>有効期間</span>
    </Article>
    <OrderedList>
      <li>
        利用契約は、登録クリエイターについて第3条に基づく登録が完了した日に効力を生じ、当該登録クリエイターの登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社と登録クリエイターとの間で有効に存続するものとします。
      </li>
      <li>
        利用契約が終了した場合であっても、登録クリエイターと当社の間で締結された個別のインフルエンサー業務委託契約は、当該インフルエンサー業務委託契約所定の有効期間中有効に存続するものとし、本規約の定めは当該インフルエンサー業務委託契約に関する限りにおいて、当該インフルエンサー業務委託契約の有効期間中効力を有するものとします。
      </li>
    </OrderedList>

    {/* Article 22 */}
    <Article>
      第22条 <span>本規約等の変更</span>
    </Article>
    <OrderedList>
      <li>当社は、本サービスの内容を自由に変更できるものとします。</li>
      <li>
        当社は、本規約（当社ウェブサイト及び当社アプリケーションに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更する場合には、変更の内容及び変更の効力発生時期を、当該効力発生時期までに当社所定の方法で告知するものとします。告知された効力発生時期以降に登録クリエイターが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、登録クリエイターは、本規約の変更に同意したものとみなします。
      </li>
    </OrderedList>

    {/* Article 23 */}
    <Article>
      第23条 <span>連絡/通知</span>
    </Article>
    <RegularText>
      本サービスに関する問い合わせその他登録クリエイターから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から登録クリエイターに対する連絡又は通知は、当社の定める方法で行うものとします。
    </RegularText>

    {/* Article 24 */}
    <Article>
      第24条 <span>本規約の譲渡等</span>
    </Article>
    <OrderedList>
      <li>
        登録クリエイターは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      </li>
      <li>
        当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録クリエイターの登録情報その他の顧客情報を当該譲渡の譲受人に譲渡することができるものとし、登録クリエイターは、かかる譲渡につき本項において予め同意したものとします。
      </li>
    </OrderedList>

    {/* Article 25 */}
    <Article>
      第25条 <span>完全合意</span>
    </Article>
    <RegularText>
      本規約は、本規約に含まれる事項に関する当社と登録クリエイターとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社と登録クリエイターとの事前の合意、表明及び了解に優先します。
    </RegularText>

    {/* Article 26 */}
    <Article>
      第26条 <span>分離可能性</span>
    </Article>
    <RegularText>
      本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。また、当社及び登録クリエイターは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
    </RegularText>

    {/* Article 27 */}
    <Article>
      第27条 <span>存続規定</span>
    </Article>
    <RegularText>
      第5条第6項及び第7項、第6条第7項及び第9項、第7条第4項から第7項まで、第8条、第9条、第10条、第11条第2項、第12条第2項、第13条第3項、第14条、第15条、第16条第2項、第4項及び第5項、第17条から第20条まで、第21条第2項並びに第24条から第28条までの規定は利用契約の終了後も有効に存続するものとします。但し、第19条については、利用契約終了後5年間に限り存続するものとします。
    </RegularText>

    {/* Article 28 */}
    <Article>
      第28条 <span>準拠法及び管轄裁判所</span>
    </Article>
    <RegularText>
      本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
    </RegularText>

    {/* Article 29 */}
    <Article>
      第29条 <span>協議解決</span>
    </Article>
    <RegularText>
      当社及び登録クリエイターは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
    </RegularText>
  </section>
);

const styles = {
  resetListStyle: css({
    li: {
      listStyle: 'none',
    },
  }),
  wrapper: css({
    boxShadow: THEME.box.shadows.outer,
    width: '100%',
    padding: 48,
    backgroundColor: THEME.colors.white,
    borderRadius: 3,
    boxSizing: 'border-box',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 0,
      padding: '24px 24px 16px',
    },
  }),
};

const OrderedList = styled.ol({
  paddingLeft: 32,

  li: {
    listStyle: 'inherit',
    marginBottom: 8,
  },
});

const RegularText = styled.p({
  marginBottom: 8,
  fontSize: 13,
});

const Article = styled(RegularText)({
  paddingLeft: 32,
  fontWeight: 'bold',

  span: {
    textDecoration: 'underline',
  },
});

export default TermsContentJP;
