import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, Icon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

interface Breadcrumbs {
  title: string;
  onClick?: () => void;
}

interface BackNavigatorProps {
  breadcrumbs?: Breadcrumbs[];
  className?: string;
  title?: string | null;
  to?: string;
  interpolation?: Record<string, string>;
  skipTranslation?: boolean;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

const BackNavigator = (props: BackNavigatorProps) => {
  const { t } = useTranslation();
  const { breadcrumbs, title, to, interpolation, skipTranslation, className, onClick } = props;

  return (
    <div className={className} css={styles.container}>
      {to && (
        <Link to={to} onClick={onClick}>
          <Icon>
            <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
          </Icon>
        </Link>
      )}
      {!!breadcrumbs?.length ? (
        <div css={{ display: 'flex' }}>
          {breadcrumbs.map((breadcrumb, index) => {
            const { title: breadcrumbTitle, onClick: breadcrumbOnClick } = breadcrumb;
            const isActiveTitle = index === breadcrumbs.length - 1;

            return (
              <Breadcrumb
                isActiveTitle={isActiveTitle}
                isClickable={!!breadcrumbOnClick}
                key={index}
                onClick={breadcrumbOnClick}
              >
                <div>{t(`pageTitle.${breadcrumbTitle}`)}</div>
                {!isActiveTitle && (
                  <Icon css={styles.nextIcon}>
                    <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={10} />
                  </Icon>
                )}
              </Breadcrumb>
            );
          })}
        </div>
      ) : title ? (
        <div css={{ fontSize: THEME.font.sizes.heading, fontWeight: 600 }}>
          {skipTranslation ? title : t(`pageTitle.${title}`, { ...interpolation })}
        </div>
      ) : null}
    </div>
  );
};

const Breadcrumb = styled.div<{ isActiveTitle: boolean; isClickable: boolean }>(({ isActiveTitle, isClickable }) => ({
  display: 'flex',
  color: isActiveTitle ? '#27313b' : '#6e7C89',
  fontSize: THEME.font.sizes.heading,
  fontWeight: 600,
  justifyContent: 'center',

  '& > div:hover': {
    color: THEME.font.colors.black.main,
    cursor: isClickable ? 'pointer' : 'default',
    textDecoration: isClickable ? 'underline' : 'none',
  },
}));

const styles = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.l,

    '& > a': {
      alignItems: 'center',
      backgroundColor: THEME.colors.white,
      boxShadow: THEME.box.shadows.outer,
      display: 'flex',
      height: 32,
      justifyContent: 'center',
      width: 32,
    },
  }),
  nextIcon: css({
    cursor: 'default',
    margin: 'auto 8px',
  }),
};

export default BackNavigator;
