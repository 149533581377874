import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { LineMessageType } from '@src/__generated__/globalTypes';
import { LineContent, fontSizeMapping } from '../FormInput/Inputs/helpers';
import Slider from './Slider';

interface PreviewItemProps {
  error: boolean;
  lineContent: LineContent;
}

const PreviewItem = ({ error, lineContent }: PreviewItemProps) => {
  const { buttonMessage, imageMessage, plainTextMessage, richMessage, type } = lineContent;
  const { t } = useTranslation();

  switch (type) {
    case LineMessageType.BUTTON:
      return (
        <ContentContainer css={{ marginBottom: 40 }}>
          {error && <ErrorMessage message={'broadcastErrorMessage'} />}
          <Slider>
            {buttonMessage?.cards.map((item, index) => {
              const { actionLabels, description, imageUrl, title } = item;
              const isAction =
                !!actionLabels?.find(actionLabel => actionLabel.label || actionLabel.url) ||
                !!description.text ||
                !!title.text;

              return (
                <ButtonTypeContainer background={imageUrl} isAction={isAction} key={index}>
                  <div>{!imageUrl && <Icomoon icon="image-grey-light" size={40} />}</div>
                  <div>
                    <ButtonTypeFont
                      fontSize={fontSizeMapping[title.fontSize]}
                      isBold={title.weighted}
                      isEmpty={!!title.text}
                    >
                      {title.text || t('TextForm.Title Text')}
                    </ButtonTypeFont>
                    <ButtonTypeFont
                      fontSize={fontSizeMapping[description.fontSize]}
                      isBold={description.weighted}
                      isEmpty={!!description.text}
                    >
                      {description.text || t('Annotation.Button Type Description')}
                    </ButtonTypeFont>
                    {actionLabels.map((actionLabel, idx) => {
                      const { label, url } = actionLabel;

                      return validator.isURL(url) ? (
                        <Link
                          css={styles.actionLabel(label)}
                          key={idx}
                          rel="noopener noreferrer"
                          target="_blank"
                          to={url}
                        >
                          {label || t('TextForm.Action Label')}
                        </Link>
                      ) : (
                        <div css={styles.actionLabel(label)} key={idx}>
                          {label || t('TextForm.Action Label')}
                        </div>
                      );
                    })}
                  </div>
                </ButtonTypeContainer>
              );
            })}
          </Slider>
        </ContentContainer>
      );
    case LineMessageType.IMAGES:
      return (
        <ContentContainer css={{ marginBottom: 40 }}>
          {error && <ErrorMessage message={'broadcastErrorMessage'} />}
          <Slider>
            {imageMessage?.images.map((item, index) => {
              const { imageUrl, label, url } = item;

              return validator.isURL(url || '') ? (
                <Link
                  css={styles.imageTypeContainer(imageUrl)}
                  key={index}
                  rel="noopener noreferrer"
                  target="_blank"
                  to={url}
                >
                  {!imageUrl && <Icomoon icon="image-grey-light" size={60} />}
                  <div>{label || t('TextForm.Action Label')}</div>
                </Link>
              ) : (
                <div css={styles.imageTypeContainer(imageUrl)} key={index}>
                  {!imageUrl && <Icomoon icon="image-grey-light" size={60} />}
                  <div>{label || t('TextForm.Action Label')}</div>
                </div>
              );
            })}
          </Slider>
        </ContentContainer>
      );
    case LineMessageType.PLAIN_TEXT:
      return (
        <ItemContainer>
          {error && <Icomoon icon="warning-grey" />}
          <ContentContainer>
            {error && <ErrorMessage message={'broadcastErrorMessage'} />}
            <PlainTextContainer isEmpty={!plainTextMessage?.text}>
              {plainTextMessage?.text ||
                t('Placeholder.Use this text to share information about your store with your customers')}
            </PlainTextContainer>
          </ContentContainer>
        </ItemContainer>
      );
    default:
      return (
        <ItemContainer>
          {error && <Icomoon icon="warning-grey" />}
          <ContentContainer>
            {error && <ErrorMessage message={'broadcastErrorMessage'} />}
            <RichMessageContainer isEmpty={!richMessage?.imageUrl}>
              {richMessage?.imageUrl ? (
                validator.isURL(richMessage.url || '') ? (
                  <Link rel="noopener noreferrer" target="_blank" to={richMessage.url || ''}>
                    <RichMessageImage src={richMessage.imageUrl} />
                  </Link>
                ) : (
                  <RichMessageImage src={richMessage.imageUrl} />
                )
              ) : (
                <Icomoon icon="image-grey-light" size={46} />
              )}
            </RichMessageContainer>
          </ContentContainer>
        </ItemContainer>
      );
  }
};

const ButtonTypeContainer = styled.div<{ background: string; isAction: boolean }>(({ background, isAction }) => ({
  backgroundColor: isAction ? '#fff' : '#f6f8fa',
  border: '2px solid #eef3f7',
  borderRadius: 15,
  minHeight: 333,
  width: 262,

  '& > div:nth-of-type(1)': {
    alignItems: 'center',
    backgroundColor: '#eef3f7',
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '15px 15px 0 0',
    display: 'flex',
    height: 174,
    justifyContent: 'center',
    width: '100%',
  },

  '& > div:nth-of-type(2)': {
    borderRadius: '0 15px 15px 15px',
    padding: '24px 18px',
  },
}));

const ButtonTypeFont = styled.div<{ fontSize: string; isBold: boolean; isEmpty: boolean }>(
  ({ fontSize, isBold, isEmpty }) => ({
    color: isEmpty ? '#27313B' : '#dee5ec',
    fontSize,
    fontWeight: isBold ? 600 : 400,
    marginBottom: 12,
  })
);

const ContentContainer = styled.div({
  '& > p': {
    fontSize: THEME.font.sizes.hint,
    fontWeight: 700,
    marginBottom: 4,
  },
});

const ItemContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  gap: THEME.box.gaps.s,
  marginBottom: 24,
});

const PlainTextContainer = styled.div<{ isEmpty: boolean }>(({ isEmpty }) => ({
  backgroundColor: isEmpty ? '#eef3f7' : '#fff',
  borderRadius: 15,
  color: isEmpty ? '#c5d0da' : '#27313b',
  padding: 12,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
}));

const RichMessageContainer = styled.div<{ isEmpty: boolean }>(({ isEmpty }) => ({
  alignItems: 'center',
  backgroundColor: isEmpty ? '#eef3f7' : '#b3b7ba',
  borderRadius: 15,
  display: 'flex',
  height: 330,
  justifyContent: 'center',
  width: 330,
}));

const RichMessageImage = styled.img({
  height: 330,
  objectFit: 'contain',
  width: '100%',
});

const styles = {
  actionLabel: (label: string) =>
    css({
      alignItems: 'center',
      color: label ? '#80a0d3' : '#dee5ec',
      display: 'flex',
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      height: 48,
      justifyContent: 'center',
      width: '100%',
    }),
  imageTypeContainer: (background?: string) =>
    css({
      alignItems: 'center',
      backgroundColor: '#eef3f7',
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      borderRadius: 15,
      display: 'flex !important',
      height: 262,
      justifyContent: 'center',
      position: 'relative',
      width: 262,

      '& > div': {
        backgroundColor: background ? '#000' : '#dee5ec',
        borderRadius: 44,
        bottom: 24,
        color: background ? '#fff' : '#c5d0da',
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        opacity: background ? 0.7 : 1,
        overflow: 'hidden',
        padding: '10px 8px',
        position: 'absolute',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 147,
      },
    }),
};

export default PreviewItem;
