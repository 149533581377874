import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { SearchForm, Select } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { FormStatus } from '@src/__generated__/globalTypes';

export interface FilterItems {
  keyword: string;
  status: FormStatus;
}

interface FormListFilterProps {
  values: FilterItems;
  onChange: (values: FilterItems) => void;
}

const FormListFilter = ({ values, onChange }: FormListFilterProps) => {
  const [filter, setFilter] = useState<FilterItems>(values);
  const { t } = useTranslation();
  const statusOptions = [
    { label: t(FormStatus.DRAFT), value: FormStatus.DRAFT },
    { label: t(FormStatus.PRIVATE), value: FormStatus.PRIVATE },
    { label: t(FormStatus.PUBLIC), value: FormStatus.PUBLIC },
  ];

  useEffect(() => {
    setFilter(values);
  }, [values.keyword, values.status]);

  function onChangeSelect(value: FormStatus) {
    const items = { ...filter, status: value };
    setFilter(items);
    onChange(items);
  }

  return (
    <div css={styles.container}>
      <div>
        <SearchForm
          css={styles.searchForm}
          placeholder={t(`TextForm.Search by Form's Name`)}
          value={filter.keyword}
          onBlur={() => onChange(filter)}
          onChange={e => setFilter({ ...filter, keyword: e.target.value })}
          onEnterKeyPress={() => onChange(filter)}
        />
      </div>
      <div>
        <Select
          options={statusOptions}
          placeholder="Status"
          value={filter.status}
          onChange={value => onChangeSelect(value as FormStatus)}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: '5px 5px 0 0',
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    gap: THEME.box.gaps.s,

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      flexDirection: 'column',
    },

    '& > div:nth-of-type(1)': {
      flex: 4,

      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        flex: 1,
      },
    },

    '& > div:nth-of-type(2)': {
      flex: 1,
    },
  }),
  searchForm: css({
    '& input': {
      height: 32,
    },
  }),
};

export default FormListFilter;
