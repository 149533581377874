import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { Empty, Icomoon, Icon, List } from '@src/components/atoms';
import { Pager, SliderTableComponents, SliderTableStyledComponents } from '@src/components/molecules';
import { getCurrentPage, getOffset, getPageInfo } from '@src/components/molecules/Pager/helpers';
import * as SC from '@src/components/organisms/MyJobs/Marketplace/Report/styledComponents';
import Card from '@src/components/organisms/MyPage/Payment/Revenue/Card';
import {
  useAutoManagedReportForInfluencerQuery,
  useAutoManagedReportSummaryForInfluencerQuery,
} from '@src/graphql/hooks';
import { CURRENCY_SYMBOLS, LIMIT } from '@src/libs/constant';
import { formatNumberWithCommas } from '@src/libs/format';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';

const Report = () => {
  const { isMobileView } = usePageLayout();
  const { params, t } = useQueryHelper();
  const currentPage = getCurrentPage();
  const campaignId = Number(params.id);
  const { data: dataReport } = useAutoManagedReportForInfluencerQuery({
    variables: { campaignId, limit: LIMIT, offset: getOffset(currentPage) },
  });
  const { data: dataReportSummary } = useAutoManagedReportSummaryForInfluencerQuery({ variables: { campaignId } });

  const reportSummary = dataReportSummary?.autoManagedReportSummaryForInfluencer;
  const reportSummaryCurrency = reportSummary?.currency;
  const currencySymbol = reportSummaryCurrency ? CURRENCY_SYMBOLS[reportSummaryCurrency] : '';
  const reportList = dataReport?.autoManagedReportForInfluencer || [];
  const isEmptyData = !reportList.length;
  const totalCount = dataReport?.autoManagedReportCountForInfluencer?.totalNumber || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  const summaryItems = [
    { isAmount: true, title: 'Label.Commissions', value: reportSummary?.commission || 0 },
    { title: 'Label.Orders', value: reportSummary?.conversions || 0 },
    { title: 'Label.Clicks', value: reportSummary?.clicks || 0 },
  ];

  return (
    <SC.Container>
      {!isMobileView && (
        <SC.Header>
          <Link to={generatePath(ROUTES.MY_JOBS_AUTO_MANAGED_ID, { id: campaignId })}>
            <Icon>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Icon>
          </Link>
          <div>{t('pageTitle.CV Report')}</div>
        </SC.Header>
      )}

      <div css={{ padding: '24px 16px' }}>
        <Card
          currency={reportSummaryCurrency}
          isTransparentBackground={isMobileView}
          title="Title Commissions"
          revenue={reportSummary?.commission || 0}
        />
        <SC.Description>{t('Annotation.The report serves as an estimate of your daily conversions')}</SC.Description>

        <SC.TableContainer>
          <SC.SummaryContainer>
            {summaryItems.map(({ isAmount, title, value }) => (
              <div key={title}>
                <div>{t(title)}</div>
                <div>{`${isAmount ? currencySymbol : ''} ${formatNumberWithCommas(
                  value,
                  0,
                  isAmount ? reportSummaryCurrency : undefined
                )}`}</div>
              </div>
            ))}
          </SC.SummaryContainer>

          <SC.TableTitleContainer>{t('Title.Daily Report')}</SC.TableTitleContainer>
          <SC.TableWrapper isEmptyData={isEmptyData}>
            <SliderTableComponents.Container>
              <SliderTableComponents.SliderSection>
                <SliderTableComponents.SliderTable>
                  <thead css={{ backgroundColor: '#f6f8fa' }}>
                    <tr>
                      <List.HeaderColumn title="Date" />
                      <List.HeaderColumn css={{ padding: 0 }} title="Order" />
                      <List.HeaderColumn css={{ padding: 0 }} title="Click" />
                      <List.HeaderColumn css={{ padding: 0 }} title="Commission" />
                    </tr>
                  </thead>

                  <tbody>
                    {reportList.map(({ date, commission, clicks, conversions }, index) => (
                      <SliderTableStyledComponents.StyledRowNew key={index}>
                        <SC.StyledTextColumn data={date ? format(new Date(date), 'yyyy/MM/dd') : '-'} />
                        <SC.StyledTextColumn
                          css={{ textAlign: 'center' }}
                          data={formatNumberWithCommas(conversions, 0)}
                        />
                        <List.TextColumn css={{ textAlign: 'center' }} data={formatNumberWithCommas(clicks, 0)} />
                        <List.TextColumn
                          data={`${currencySymbol}${formatNumberWithCommas(commission, 0, reportSummaryCurrency)}`}
                        />
                      </SliderTableStyledComponents.StyledRowNew>
                    ))}
                  </tbody>
                </SliderTableComponents.SliderTable>
              </SliderTableComponents.SliderSection>
            </SliderTableComponents.Container>

            {isEmptyData && <Empty content="You can track performance on daily basis" title="There are no reports" />}
          </SC.TableWrapper>

          <Pager
            css={{ padding: '24px 16px' }}
            currentPage={currentPage}
            first={firstIndex}
            last={lastIndex}
            totalCount={totalCount}
            totalPages={totalPages}
          />
        </SC.TableContainer>
      </div>
    </SC.Container>
  );
};

export default Report;
