import { InfluencerType } from '@src/__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { useAuthData } from './hooks';

export const useHelpCenterUrl = () => {
  const { i18n } = useTranslation();
  const { creatorType } = useAuthData();
  const isProInfluencer = creatorType === InfluencerType.PRO;

  // help links for EN Normal & PRO influencer will be default fallback
  const helpLinks = {
    helpCenterUrl: isProInfluencer
      ? 'https://anymind-group.gitbook.io/anycreator-help-page-english/'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/',
    lineConnectHelp:
      'https://anymind-group.gitbook.io/anyx-help-page-english/faq/how-to-check-channel-id-and-channel-secret',
    switchBusinessAccUrl:
      'https://anymind-group.gitbook.io/anycreator-help-page/getting-started/change-to-instagram-business-account',
    anycreatorBenefits: isProInfluencer
      ? 'https://anymind-group.gitbook.io/anycreator-help-page-english/getting-started/anyxs-function'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/getting-started/anyxs-function',
    howToConnectIgToFb: isProInfluencer
      ? 'https://anymind-group.gitbook.io/anycreator-help-page-english/faq/fail-to-connect-instagram-to-facebook'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/faq/fail-to-connect-instagram-to-facebook',
    howToGetPayment: 'https://anymind-group.gitbook.io/anycreator-help-page/faq/payment/when-can-i-get-payment',
    howToGrantPermissions: isProInfluencer
      ? 'https://anymind-group.gitbook.io/anycreator-help-page-english/faq/how-to-grant-permissions'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/faq/how-to-grant-permissions',
    joinCampaignHelp: 'https://anymind-group.gitbook.io/anycreator-help-page',
    requestPaymentHelp:
      'https://anymind-group.gitbook.io/anycreator-help-page/faq/payment/withdrawal-transfer-fees-how-much-do-they-cost-per-withdrawal-request',
    learnAboutEstimatedRevenue:
      'https://anymind-group.gitbook.io/anycreator-help-page/bgm-dashboard/youtube-shorts-background-music-bgm-revenue-dashboard',
    learnAboutAIFeature:
      'https://anymind-group.gitbook.io/anycreator-help-page/ai-features/campaign-socialmedia-caption-generator',
  };

  switch (i18n.language) {
    case 'ja': {
      helpLinks.helpCenterUrl = 'https://anymind-group.gitbook.io/anycreator_help/';
      helpLinks.lineConnectHelp =
        'https://anymind-group.gitbook.io/anyx/hajimeni/channel-idtochannel-secretno#2-noakauntowoshiwokurikku';
      helpLinks.switchBusinessAccUrl =
        'https://anymind-group.gitbook.io/anycreatorhelp/hajimeni/instagramwobijinesuakauntoherieru';
      helpLinks.anycreatorBenefits = 'https://anymind-group.gitbook.io/anycreator-help/hajimeni/untitled';
      helpLinks.howToConnectIgToFb =
        'https://anymind-group.gitbook.io/anycreator-help/yokuaru/instagramtofacebooknogadekinai';
      helpLinks.howToGetPayment = 'https://anymind-group.gitbook.io/anycreator_help/oi/oinore';
      helpLinks.joinCampaignHelp = 'https://anymind-group.gitbook.io/anycreator_help';
      helpLinks.requestPaymentHelp = 'https://anymind-group.gitbook.io/anycreator_help/oi/untitled';
      helpLinks.learnAboutEstimatedRevenue =
        'https://anymind-group.gitbook.io/anycreator_help/bgm-dashboard/youtube-short-bgm-dasshubdo';
      break;
    }

    case 'th': {
      if (isProInfluencer) {
        // links are not implemented so we will fallback to default EN_PRO
        break;
      } else {
        helpLinks.helpCenterUrl = 'https://anymind-group.gitbook.io/th-anycreator-help-page/';
        helpLinks.anycreatorBenefits = 'https://anymind-group.gitbook.io/th-anycreator-help-page/master-1';
        helpLinks.howToConnectIgToFb =
          'https://anymind-group.gitbook.io/th-anycreator-help-page/HowToConnect/instagram';
        helpLinks.howToGrantPermissions =
          'https://anymind-group.gitbook.io/th-anycreator-help-page/FAQ/grant-permission';
        helpLinks.joinCampaignHelp = 'https://anymind-group.gitbook.io/th-anycreator-help-page';
        helpLinks.learnAboutEstimatedRevenue =
          'https://anymind-group.gitbook.io/th-anycreator-help-page/undefined-1/undefined';
      }

      break;
    }
  }

  return helpLinks;
};
