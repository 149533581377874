import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { Icomoon, RadioIcon } from '@src/components/atoms';
import Popover, { MenuItem } from '@src/components/atoms/Popover';
import { ThemeButton } from '@src/components/molecules';
import { SearchForm } from '@src/components/shared';
import { Order } from '@src/__generated__/globalTypes';
import { useApolloClient } from '@apollo/client';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import styled from '@emotion/styled';
import GET_CREATOR_TAGS_BY_ID from '@src/graphql/queries/GetCreatorTagsById.graphql';
import { GetCreatorTagsByIdQuery, GetCreatorTagsByIdQueryVariables } from '@src/__generated__/globalTypes';
import useFanList from '../useFanList';
import { FanListFilterType } from '../types';
import { FormValues } from './types';
import useStateHandler from './useStateHandler';
import ImportFansModal from './ImportFansModal';
import { getTagsIdFromURLString } from './helper';
import { MoreFilters } from './MoreFilters';

interface FanListFilterProps {
  filter: FanListFilterType;
  sortOrder: Order;
  onSort: () => void;
  selectedFansId: Set<number>;
  onSubmit: (information: FormValues) => void;
}

const FanListFilter = (props: FanListFilterProps) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { selectedFansId, filter, sortOrder, onSort, onSubmit } = props;
  const { exportFanCSV, uploadSelectedFile } = useFanList();
  const { handleSubmit, register, setValue } = useFormContext<FormValues>();

  const setSelectedTags = async (tagIds: number[]) => {
    const { data } = await client.query<GetCreatorTagsByIdQuery, GetCreatorTagsByIdQueryVariables>({
      query: GET_CREATOR_TAGS_BY_ID,
      variables: { input: { tagIds } },
    });

    setValue(
      'tags',
      data.getCreatorTagsById?.tags.map(item => ({
        label: item.tag,
        value: item.id.toString(),
      })) || []
    );
  };

  useEffect(() => {
    const appliedTagIds = getTagsIdFromURLString(filter.tags);
    if (appliedTagIds.length) {
      setSelectedTags(appliedTagIds);
    } else {
      setValue('tags', []);
    }
  }, [filter.tags]);

  const { showImportModal, closeImportModal, openImportModal } = useStateHandler();
  let onCloseMenu: () => void;

  const handleBlurAndSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const handleClickImport = async (file: File) => {
    await uploadSelectedFile(file);
    closeImportModal();
  };

  const onClickExport = () => {
    exportFanCSV(selectedFansId);
    onCloseMenu();
  };
  const onClickImport = () => {
    openImportModal();
    onCloseMenu();
  };

  return (
    <>
      <ImportFansModal open={showImportModal} onClose={closeImportModal} onImportFile={handleClickImport} />
      <div css={styles.btnWrapper}>
        <SearchForm
          placeholder={t(`TextForm.Search by Fan's Name or Email Address`)}
          onEnterKeyPress={handleBlurAndSubmit}
          css={styles.input}
          {...register('keyword')}
          onBlur={handleBlurAndSubmit}
        />

        <MoreFilters filter={filter} onSubmit={onSubmit} />

        <Popover
          renderTrigger={
            <ThemeButton
              text="Sort"
              width="max-content"
              prefixIcon={<Icomoon icon="sort" size={20} />}
              css={styles.button}
            />
          }
        >
          <div css={{ width: 'max-content' }}>
            <SortMenuItem onClick={onSort}>
              <RadioIcon isChecked={sortOrder === Order.DESC} />
              {t('Option.Latest Created')}
            </SortMenuItem>
            <SortMenuItem onClick={onSort}>
              <RadioIcon isChecked={sortOrder === Order.ASC} />
              {t('Option.Last Created')}
            </SortMenuItem>
          </div>
        </Popover>
        <Popover
          renderTrigger={<ThemeButton prefixIcon="&middot;&middot;&middot;" width="32px" css={styles.downloadBtn} />}
          emitEvents={({ onClose }) => {
            onCloseMenu = onClose;
          }}
        >
          <SortMenuItem onClick={onClickImport} css={{ gap: '8px' }}>
            <Icomoon icon="import" />
            {t('Import CSV')}
          </SortMenuItem>
          <SortMenuItem onClick={onClickExport} css={{ gap: '8px' }}>
            <Icomoon icon="export" />
            {t('Export CSV')}
          </SortMenuItem>
        </Popover>
      </div>
    </>
  );
};

const SortMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  gap: THEME.box.gaps.s,
});

const styles = {
  title: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
  }),
  panelDetails: css({
    padding: '0 !important',
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  form: css({
    height: '100%',
  }),
  submitBtn: css({
    width: 'fit-content',
    lineHeight: '32px',
    borderRadius: 3,
    padding: '0 24px',
  }),
  summary: css({
    height: '48px !important',
    minHeight: '48px !important',
    padding: '0 !important',
  }),
  heading: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderBottom: '1px solid #dee5ec',
  }),
  headingText: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.heading,
    color: THEME.font.colors.black.main,
  }),
  input: css({
    flex: 1,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      flexBasis: '100%',
    },

    i: {
      backgroundColor: 'transparent',
      height: 30,
    },

    input: {
      lineHeight: '30px',
    },
  }),
  label: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
  }),
  container: css({
    width: 336,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  }),
  btnWrapper: css({
    padding: 16,
    backgroundColor: THEME.colors.white,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.s,
  }),
  button: css({
    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      display: 'flex',
      flex: 1,
    },
  }),
  downloadBtn: css({
    '.prefix-icon': {
      fontWeight: 600,
      fontSize: 22,
    },
  }),
  footer: css({
    backgroundColor: '#f6f8fa',
    padding: '24px 24px 24px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: THEME.box.gaps.s,
  }),
  expansion: css({
    backgroundColor: 'red',

    '&.MuiExpansionPanel-root': {
      position: 'unset',
    },

    '& .MuiExpansionPanelSummary-expandIcon': {
      marginRight: '-8px !important',
    },

    '&::before': {
      height: 0,
    },

    '&:last-of-type': {
      borderBottom: 'none',
    },
  }),
  textForm: css({
    width: 240,
    margin: '0 0 16px 26px',

    '& input': {
      borderRadius: 3,
      height: 32,
    },

    label: {
      paddingLeft: 0,
    },
  }),
  checkbox: css({
    padding: '0 0 4px 0',

    label: {
      marginRight: 0,
      alignItems: 'flex-start',
    },
  }),
  clearBtn: css({
    margin: '8px 0',
    color: THEME.font.colors.blue.main,
    fontSize: THEME.font.sizes.normal,
    cursor: 'pointer',
  }),
  filterCount: css({
    width: 24,
    lineHeight: '24px',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: THEME.font.sizes.subordinate,
    backgroundColor: THEME.colors.white,
    fontWeight: 600,
  }),
  filterIcon: css({
    borderColor: '#3892e5',
    backgroundColor: '#ecf3fb',

    '.btn-text': {
      padding: 0,
      color: THEME.font.colors.blue.main,
    },
  }),
  tagSelector: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 40,
  }),
};

export default FanListFilter;
