import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeAnalyticsOverviewQuery,
  InfluencerYoutubeAnalyticsOverviewQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerYoutubeAnalyticsOverview.graphql';

export const useInfluencerYoutubeAnalyticsOverviewQuery = (
  options?: QueryHookOptions<InfluencerYoutubeAnalyticsOverviewQuery, InfluencerYoutubeAnalyticsOverviewQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerYoutubeAnalyticsOverviewQuery, InfluencerYoutubeAnalyticsOverviewQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerYoutubeAnalyticsOverviewLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerYoutubeAnalyticsOverviewQuery,
    InfluencerYoutubeAnalyticsOverviewQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerYoutubeAnalyticsOverviewQuery, InfluencerYoutubeAnalyticsOverviewQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
