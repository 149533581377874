import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdateAccountInformationMutation,
  UpdateAccountInformationMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdateAccountInformation.graphql';

export const useUpdateAccountInformationMutation = (
  options?: MutationHookOptions<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>
) => useMutation<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>(MUTATION, options);
