import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeAnalyticsCompareAccountCardsQuery,
  InfluencerYoutubeAnalyticsCompareAccountCardsQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerYoutubeAnalyticsCompareAccountCards.graphql';

export const useInfluencerYoutubeAnalyticsCompareAccountCardsQuery = (
  options?: QueryHookOptions<
    InfluencerYoutubeAnalyticsCompareAccountCardsQuery,
    InfluencerYoutubeAnalyticsCompareAccountCardsQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<
    InfluencerYoutubeAnalyticsCompareAccountCardsQuery,
    InfluencerYoutubeAnalyticsCompareAccountCardsQueryVariables
  >(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerYoutubeAnalyticsCompareAccountCardsLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerYoutubeAnalyticsCompareAccountCardsQuery,
    InfluencerYoutubeAnalyticsCompareAccountCardsQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<
    InfluencerYoutubeAnalyticsCompareAccountCardsQuery,
    InfluencerYoutubeAnalyticsCompareAccountCardsQueryVariables
  >(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
