import React, { useState } from 'react';
import ReactSlick, { Settings } from 'react-slick';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Icomoon from '@src/components/atoms/Icomoon';
import Icon from '@src/components/atoms/Icon';
import VideoPreview from '@src/components/atoms/VideoPreview';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface CarouselProps {
  height?: number;
  items: string[];
  width?: number;
}

const Carousel = ({ height = 230, items, width = 343 }: CarouselProps) => {
  const [currIndex, setCurrIndex] = useState<number>(0);
  const [enlarge, setEnlarge] = useState<boolean>(false);
  const extensions = /.jpeg|.jpg|.png|.gif|.zip|.pdf|.ppt|.pptx|.xls|.xlsx|.doc|.docx|.txt/;
  const settings: Settings = {
    beforeChange: (_prevIndex: number, nextIndex: number) => {
      setCurrIndex(nextIndex);
      if (enlarge) {
        sliderMethods?.slickGoTo(nextIndex, false);
      } else {
        overlaySliderMethods?.slickGoTo(nextIndex, false);
      }
    },
    nextArrow: (
      <Button css={{ right: 8 }}>
        <Icon>
          <Icomoon color="#fff" css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={10} />
        </Icon>
      </Button>
    ),
    prevArrow: (
      <Button css={{ left: 8 }}>
        <Icon>
          <Icomoon color="#fff" css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
        </Icon>
      </Button>
    ),
    slidesToShow: 1,
  };
  let overlaySliderMethods: ReactSlick | null;
  let sliderMethods: ReactSlick | null;

  return (
    <div>
      <Overlay open={enlarge}>
        <div css={styles.overlayContent}>
          <div css={styles.overlayCloseIcon} onClick={() => setEnlarge(false)} />
          <div css={styles.overlaySlickContainer}>
            <ReactSlick ref={slider => (overlaySliderMethods = slider)} {...settings}>
              {items.map((source, index) => {
                const isImage = extensions.test(source.toLowerCase());

                return (
                  <div key={index}>
                    {isImage ? <img src={source} /> : <VideoPreview css={{ width: '100% !important' }} url={source} />}
                  </div>
                );
              })}
            </ReactSlick>
          </div>
          <div css={styles.overlayIndexIndicator}>{`${currIndex + 1}/${items.length}`}</div>
        </div>
      </Overlay>

      <Container height={height} width={width}>
        <ReactSlick css={styles.reactSlick} ref={slider => (sliderMethods = slider)} {...settings}>
          {items.map((source, index) => {
            const isImage = extensions.test(source.toLowerCase());

            return (
              <div key={index}>
                {isImage ? (
                  <img height={height} src={source} width={width} onClick={() => setEnlarge(true)} />
                ) : (
                  <VideoPreview
                    css={{ background: THEME.colors.black.main, borderRadius: 15 }}
                    height={height}
                    width={width}
                    url={source}
                    onClick={() => setEnlarge(true)}
                  />
                )}
              </div>
            );
          })}
        </ReactSlick>
        <Icomoon color="#fff" css={styles.enlargeIcon} icon="enlarge" size={21} onClick={() => setEnlarge(true)} />
        <div css={styles.indexIndicator}>{`${currIndex + 1}/${items.length}`}</div>
      </Container>
    </div>
  );
};

const Button = styled.div({
  alignItems: 'center',
  background: THEME.colors.black.main,
  border: '1px solid #a9adb1',
  borderRadius: 45,
  boxShadow: THEME.box.shadows.outer,
  cursor: 'pointer',
  display: 'flex !important',
  height: 32,
  justifyContent: 'center',
  position: 'absolute',
  width: 32,
  zIndex: 1,

  '&::before': {
    display: 'none',
  },

  '&:hover': {
    background: THEME.colors.black.main,
  },
});

const Container = styled.div<{ height: number; width: number }>(({ height, width }) => ({
  background: THEME.colors.black.main,
  borderRadius: 15,
  cursor: 'pointer',
  height: `${height}px`,
  position: 'relative',
  maxWidth: `${width}px`,
  overflow: 'hidden',
  width: '100%',
}));

const Overlay = styled.div<{ open: boolean }>(({ open }) => ({
  alignItems: 'center',
  backdropFilter: 'blur(2px)',
  background: 'rgba(0, 0, 0, 0.6)',
  bottom: 0,
  display: open ? 'flex' : 'none',
  flexWrap: 'wrap',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 5,

  '& .slick-track': {
    alignItems: 'center',
    display: 'flex',
  },
}));

const styles = {
  enlargeIcon: css({
    cursor: 'pointer',
    position: 'absolute',
    right: 17,
    top: 17,
  }),
  indexIndicator: css({
    background: 'rgba(39, 49, 59, 0.6)',
    borderRadius: 32,
    bottom: 8,
    color: THEME.font.colors.white,
    fontSize: THEME.font.sizes.subordinate,
    left: 0,
    margin: '0 auto',
    padding: '4px 8px',
    position: 'absolute',
    right: 0,
    width: 'fit-content',
  }),
  overlayCloseIcon: css({
    '&::after': {
      color: THEME.font.colors.white,
      content: '"\\00d7"',
      cursor: 'pointer',
      fontSize: 32,
      position: 'absolute',
      right: 16,
      top: 16,
      zIndex: 1,
    },
  }),
  overlayContent: css({
    background: THEME.colors.black.main,
    display: 'grid',
    height: '100%',
    position: 'relative',
    width: '100%',

    '& img, video': {
      height: '100%',
      maxHeight: 588,
      objectFit: 'contain',
      width: '100%',
    },

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      alignItems: 'center',
      borderRadius: 15,
      maxHeight: 709,
      maxWidth: 648,

      '& img, video': {
        maxWidth: 648,
      },
    },
  }),
  overlayIndexIndicator: css({
    color: THEME.font.colors.white,
    fontSize: THEME.font.sizes.heading,
    textAlign: 'center',
    width: 'fill-available',
  }),
  overlaySlickContainer: css({
    height: '100%',
    maxHeight: 588,
    width: '100vw',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      maxWidth: 648,
    },

    '[dir="rtl"] &': {
      '.slick-next': {
        left: 8,
      },

      '.slick-prev': {
        right: 8,
      },
    },
  }),
  reactSlick: css({
    '[dir="rtl"] &': {
      '.slick-next': {
        left: 8,
      },

      '.slick-prev': {
        right: 8,
      },
    },

    '& img, video': {
      objectFit: 'contain',
    },
  }),
};

export default Carousel;
