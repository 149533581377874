import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthSignInOrSignUpMutation,
  AnyXSocialAuthSignInOrSignUpMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthSignInOrSignUp.graphql';

export const useAnyXSocialAuthSignInOrSignUpMutation = (
  options?: MutationHookOptions<AnyXSocialAuthSignInOrSignUpMutation, AnyXSocialAuthSignInOrSignUpMutationVariables>
) =>
  useMutation<AnyXSocialAuthSignInOrSignUpMutation, AnyXSocialAuthSignInOrSignUpMutationVariables>(MUTATION, options);
