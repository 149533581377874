import styled from '@emotion/styled';
import { ViewportType } from '@src/libs/types';
import { Icomoon, TextCutter } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

export const OverviewWrapper = styled.section({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0 0 3px 3px',
  backgroundColor: THEME.colors.white,
  color: THEME.font.colors.black.main,
  borderTop: 0,
});

export const EngagementGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(165px, auto))',

  [`@media (max-width: ${ViewportType.SMALL}px)`]: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, auto))',
  },

  overflow: 'hidden',
  backgroundColor: THEME.colors.white,
  borderTop: '1px solid #dee5ec',
  borderBottom: '1px solid #dee5ec',
  gap: 1,
});

export const EngagementGridCell = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  /* trick to collapse borders */
  outline: '1px solid #dee5ec',
});

export const EngagementTileIcon = styled(Icomoon)({
  backgroundColor: THEME.colors.white,
  height: '24px !important',
  margin: 2,
  position: 'absolute',
  right: 8,
  top: 8,
  width: '24px !important',

  [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
    width: '20px !important',
    height: '20px !important',
  },
});

export const EngagementTitle = styled(TextCutter)({
  fontWeight: 'normal',
  fontSize: 11,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '0.28px',
  padding: '16px 32px 0 16px',

  '[dir="rtl"] &': {
    /* for text-overflow ellipsis */
    padding: '16px 16px 0 32px',
  },
});

export const TotalNumber = styled.span({
  fontWeight: 600,
  fontSize: THEME.font.sizes.heading,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.2,
  letterSpacing: '-0.3px',
  paddingBottom: 4,
});

export const Growth = styled.div<{ isPositive?: boolean }>(({ isPositive }) => ({
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 400,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.38,
  letterSpacing: 'normal',
  color: isPositive === undefined ? 'inherit' : isPositive ? '#40b87c' : '#ff2b52',
  whiteSpace: 'pre-wrap',
}));

export const PartWithIcon = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const EngagementNumbers = styled.div({
  display: 'flex',
  flexFlow: 'column wrap',
  padding: 16,
  paddingTop: 4,
});

export const LegendBar = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '16px 12px',
  gap: THEME.box.gaps.s,
});

export const LegendTileColored = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: 4,
  borderRadius: 3,
  border: 'solid 1px #dee5ec',
  backgroundColor: '#f6f8fa',
  cursor: 'pointer',
  marginBottom: 8,
});

export const Text = styled.p({
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.17,
  letterSpacing: 'normal',
  color: THEME.font.colors.black.main,
  padding: '0 4px',
});

export const LegendTile = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: 4,
  marginRight: 8,
  cursor: 'pointer',
});
