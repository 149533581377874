import React from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/molecules';
import { removeToken } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import Instruction from '@src/pages/MyPage/SocialConnect/TiktokGrantPermission/Instruction';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const TiktokGrantPermission = () => {
  const { search } = useLocation();
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.MOBILE_TIKTOK_CONNECT);
  const searchParams = new URLSearchParams(search);
  const socialAccountId = searchParams.get('id');

  const onClickReconnect = () => {
    connectSocialAuth(SocialAccountType.TIKTOK, { socialAccountId: Number(socialAccountId) });
  };

  const onCloseSuccess = () => {
    removeToken();
    if (window.opener) {
      window.close();
    }
  };

  return (
    <div css={styles.container}>
      {socialAccountId ? (
        <Instruction onClickReconnect={onClickReconnect} />
      ) : (
        <>
          {/* pending for the design and temporary solution for now */}
          <div css={styles.successContainer}>
            <div>Success reconnect TikTok</div>
            <div>
              <ThemeButton text="Close" onClick={onCloseSuccess} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  container: css({
    margin: '16px 24px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: 16,
    },
  }),
  successContainer: css({
    textAlign: 'center',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 8,
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      justifyContent: 'center',

      '& > button': {
        height: 40,
        width: 'fit-content',
      },
    },
  }),
};

export default TiktokGrantPermission;
