import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ErrorMessage } from '@src/components/atoms';
import { BackNavigatorV2, ThemeButton } from '@src/components/molecules';
import { TextFormV2 } from '@src/components/shared';
import { usePageLayout } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';

export interface AddressInformation {
  address: string;
  address2: string;
  city: string;
  fullName: string;
  phoneNumber: string;
  postalCode: string;
  province: string;
}

const Address = () => {
  const { t } = useTranslation();
  const { isMobileView, isYoutubeCmsPath } = usePageLayout();
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<AddressInformation>();

  return (
    <div css={{ padding: '24px 16px' }}>
      {!isMobileView && (
        <BackNavigatorV2
          actionItem={
            <ThemeButton
              css={{ borderRadius: 9, height: 40, width: 226 }}
              customPalette={mainBlack}
              disabled={isSubmitting}
              text="Save"
              type="submit"
            />
          }
          backPath={isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE : ROUTES.MY_PAGE}
          title="Address"
        />
      )}

      <div css={styles.contentContainer}>
        <div css={styles.description}>{t('Annotation.View and edit the address information to ship products')}</div>
        <div css={styles.inputContainer}>
          <div>
            <TextFormV2
              error={!!errors.fullName}
              isRequired
              title="Settings Address Full Name"
              placeholder="Full Name"
              {...register('fullName')}
            />
            <ErrorMessage message={errors.fullName?.message} />
          </div>

          <TextFormV2 title="Phone Number" placeholder="+6563867368" type="number" {...register('phoneNumber')} />

          <div>
            <TextFormV2
              error={!!errors.postalCode}
              isRequired
              title="Postal Code"
              placeholder="Postal Code"
              {...register('postalCode')}
            />
            <ErrorMessage message={errors.postalCode?.message} />
          </div>

          <div>
            <TextFormV2
              error={!!errors.province}
              isRequired
              title="Province"
              placeholder="Province"
              {...register('province')}
            />
            <ErrorMessage message={errors.province?.message} />
          </div>

          <div>
            <TextFormV2 error={!!errors.city} isRequired title="City" placeholder="City" {...register('city')} />
            <ErrorMessage message={errors.city?.message} />
          </div>

          <div>
            <TextFormV2
              error={!!errors.address}
              isRequired
              title="Address"
              placeholder="Address"
              {...register('address')}
            />
            <ErrorMessage message={errors.address?.message} />
          </div>

          <div>
            <TextFormV2
              error={!!errors.address2}
              isRequired
              title="Apartment, suite, etc"
              placeholder="Apartment, Suite, etc"
              {...register('address2')}
            />
            <ErrorMessage message={errors.address2?.message} />
          </div>

          {isMobileView && (
            <ThemeButton
              css={{ borderRadius: 9, height: 48 }}
              disabled={isSubmitting}
              customPalette={mainBlack}
              text="Save"
              type="submit"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  contentContainer: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,
  }),
  header: css({
    alignItems: 'center',
    display: 'flex',
  }),
  inputContainer: css({
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    padding: '24px 16px',
  }),
};

export default Address;
