import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  InfluencerAnalyticsTwitterRefreshMutation,
  InfluencerAnalyticsTwitterRefreshMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/InfluencerAnalyticsTwitterRefresh.graphql';

export const useInfluencerAnalyticsTwitterRefreshMutation = (
  options?: MutationHookOptions<
    InfluencerAnalyticsTwitterRefreshMutation,
    InfluencerAnalyticsTwitterRefreshMutationVariables
  >
) =>
  useMutation<InfluencerAnalyticsTwitterRefreshMutation, InfluencerAnalyticsTwitterRefreshMutationVariables>(
    MUTATION,
    options
  );
