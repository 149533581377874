import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { useGlobalLayout, usePageLayout } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import IntercomInitializer from '../Intercom';
import LineWidget from '../LineWidget';
import Menu, { menuWidth } from './Menu';
import Toolbar, { ToolbarProps, DYNAMIC_TITLE } from './Toolbar';

export { DYNAMIC_TITLE };

export interface LayoutProps {
  backgroundColor?: string;
  hideToolbar?: boolean;
  toolbarProps?: ToolbarProps;
}

interface MainProps extends LayoutProps {
  children: ReactNode;
}

const Layout = ({ backgroundColor = '#f7f8fa', children, hideToolbar, toolbarProps }: MainProps) => {
  const { hasMobileMenu } = useGlobalLayout();
  const { isMobileView } = usePageLayout();
  const showToolbar = isMobileView && !hideToolbar;

  return (
    <div>
      {showToolbar && <Toolbar {...toolbarProps} />}
      <Menu />
      <Content backgroundColor={backgroundColor} marginTop={showToolbar} paddingBottom={hasMobileMenu}>
        {children}
      </Content>
      <LineWidget />
      <IntercomInitializer />
    </div>
  );
};

const Content = styled.div<{
  backgroundColor: string;
  marginTop: boolean;
  paddingBottom: boolean;
}>(({ backgroundColor, marginTop, paddingBottom }) => ({
  backgroundColor,
  width: '100%',
  ...(marginTop && { marginTop: 40 }),
  ...(paddingBottom && { paddingBottom: 64 }),

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    marginLeft: `${menuWidth}px`,
    width: `calc(100% - ${menuWidth}px)`,

    "[dir='rtl'] &": {
      marginLeft: 'unset',
      marginRight: `${menuWidth}px`,
    },
  },
}));

export default Layout;
