import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import reconnectFacebook from '@src/assets/img/findJobs/reconnectFacebook.png';
import { Icomoon } from '@src/components/atoms';
import { Dialog } from '@src/components/molecules';
import { mainWhite } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface DialogFBReconnectProps {
  isOpen: boolean;
  onClose: () => void;
  onClickReconnect: () => void;
}

const DialogFBReconnect = ({ isOpen, onClose, onClickReconnect }: DialogFBReconnectProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      cancelButtonProps={{
        text: 'Reconnect Facebook account',
        theme: 'black',
        onClick: onClickReconnect,
      }}
      contentStyles={{
        backgroundColor: THEME.colors.white,
        borderRadius: 9,
        boxShadow: THEME.box.shadows.outer,
        height: 600,
        maxHeight: '90%',
        maxWidth: 648,
        width: '90%',
      }}
      nextButtonProps={{
        customPalette: mainWhite,
        text: 'Maybe later',
        onClick: onClose,
      }}
      open={isOpen}
      onClose={onClose}
    >
      <div css={styles.dialogContent}>
        <img height="200" src={reconnectFacebook} width="268" />
        <div>{t('Title.Reconnect Facebook account')}</div>
        <div>
          <Icomoon color="#27313b" icon="warning" size={12} />
          &nbsp;
          {t('Annotation.Reconnect Facebook Account')}
        </div>
      </div>
    </Dialog>
  );
};

const styles = {
  actionContainer: css({
    borderTop: '1px solid #dee5ec',
    display: 'grid',
    gap: THEME.box.gaps.s,
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      display: 'flex',
    },

    '& > button': {
      borderRadius: 9,
      height: 48,
    },
  }),
  dialogContent: css({
    display: 'grid',
    padding: '48px 4px',

    '& > img': {
      justifySelf: 'center',
      marginBottom: 32,
    },

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subTitle,
      fontWeight: 700,
      marginBottom: 12,
      textAlign: 'center',
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      display: 'block',
      fontSize: THEME.font.sizes.normal,
    },
  }),
};

export default DialogFBReconnect;
