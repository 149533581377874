import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface ThumbnailColumnProps extends ThumbnailProps {
  className?: string;
  href?: LocationDescriptor;
  title: string;
  handleClick?: () => void;
  children?: ReactNode;
  role?: string;
}

const ThumbnailColumn = (props: ThumbnailColumnProps) => {
  const { className, href, src, title, handleClick, children, imageWidth, imageHeight, role } = props;
  const Wrapper = role === 'div' ? 'div' : 'td';
  const hasInteraction = !!href || !!handleClick;

  return (
    <Wrapper className={className}>
      <div css={styles.wrapper}>
        <Thumbnail
          src={src}
          onClick={handleClick}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          hasInteraction={hasInteraction}
        />
        <div css={styles.flexBox}>
          {href ? (
            <Title onClick={handleClick}>
              <Link css={!title && styles.noTitleLink} to={href} title={title}>
                {title || '-'}
              </Link>
            </Title>
          ) : (
            <Title onClick={handleClick} title={title}>
              {title}
            </Title>
          )}
          {children}
        </div>
      </div>
    </Wrapper>
  );
};

interface ThumbnailProps {
  src: string | null;
  imageWidth?: string;
  imageHeight?: string;
  onClick?: () => void;
  hasInteraction?: boolean;
}

const Thumbnail = styled.div<ThumbnailProps>(({ imageHeight, imageWidth, hasInteraction, src }) => ({
  flexShrink: 0,
  width: imageWidth || 98,
  height: imageHeight || 42,
  marginRight: 8,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundImage: !!src ? `url(${src})` : 'none',
  border: '1px solid #f5f5f5',
  borderRadius: 2,
  ...(hasInteraction && {
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  }),
}));

const Title = styled.p<{ onClick?: () => void }>(({ onClick }) => ({
  overflow: 'hidden',
  flex: 1,
  minWidth: 120,
  maxHeight: 46,
  fontSize: 13,
  lineHeight: '16px',
  wordBreak: 'break-word',
  ...(!!onClick && {
    '&:hover': {
      color: '#179cd7',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
}));

const styles = {
  flexBox: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  }),
  noTitleLink: css({
    textDecoration: 'none',
    textDecorationColor: 'transparent !important',

    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
      textDecorationColor: 'transparent !important',
    },
  }),
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  }),
};

export default ThumbnailColumn;
