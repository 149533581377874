import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  SendLineBroadcastMessageMutation,
  SendLineBroadcastMessageMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/SendLineBroadcastMessage.graphql';

export const useSendLineBroadcastMessageMutation = (
  options?: MutationHookOptions<SendLineBroadcastMessageMutation, SendLineBroadcastMessageMutationVariables>
) => useMutation<SendLineBroadcastMessageMutation, SendLineBroadcastMessageMutationVariables>(MUTATION, options);
