import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@src/libs/theme';

const SwitchToProAccount = () => {
  const { t } = useTranslation();
  const menus = ['Email from Instagram', 'Option.Help', 'Digital collectibles', 'switch to pro account'];

  return (
    <div css={styles.container}>
      <div css={styles.menuContainer}>
        {menus.map(key => (
          <div key={key}>{t(key)}</div>
        ))}
      </div>

      <div css={styles.rightContainer}>
        <div>{t('self-introduction')}</div>
        <div>{t('mail address')}</div>
        <div>{t('telephone number')}</div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    display: 'flex',
    gap: THEME.box.gaps.s,
    height: 234,
    width: 368,
  }),
  menuContainer: css({
    border: '1px solid #dbdbdc',
    borderBottom: 'none',
    borderTop: 'none',
    height: '100%',
    marginLeft: 40,
    minWidth: 190,

    '[dir="rtl"] &': {
      marginLeft: 'unset',
      marginRight: 40,
    },

    '& > div': {
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 500,
      padding: '12px 16px',
      width: 'fill-available',
    },

    '& > div:nth-child(n + 2)': {
      textTransform: 'lowercase',
    },

    '& > div:nth-of-type(4)': {
      color: '#3992e5',
      fontWeight: 600,
      position: 'relative',

      '&::before': {
        border: '2px dashed #3892e5',
        bottom: -2,
        content: '""',
        display: 'block',
        height: '90%',
        left: 6,
        position: 'absolute',
        width: '90%',

        '[dir="rtl"] &': {
          right: 6,
        },
      },
    },
  }),
  rightContainer: css({
    fontSize: 13,
    fontWeight: 600,
    position: 'relative',
    textAlign: 'right',
    width: 'fill-available',

    '[dir="rtl"] &': {
      textAlign: 'left',
    },

    '& > div:nth-of-type(1)': {
      marginTop: 12,
      padding: '0 12px',
    },

    '& > div:nth-of-type(2)': {
      bottom: 56,
      padding: '0 12px',
      position: 'absolute',
      right: 0,

      '[dir="rtl"] &': {
        left: 0,
        right: 'unset',
      },
    },

    '& > div:nth-of-type(3)': {
      bottom: 8,
      padding: '0 12px',
      position: 'absolute',
      width: 'fill-available',
    },
  }),
};

export default SwitchToProAccount;
