import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import DragAndDrop, { Preview as DragAndDropPreview } from '@src/components/molecules/DragAndDrop';
import { DayPicker, Label, Select, SentenceForm } from '@src/components/shared';
import { SOCIAL_MEDIAS } from '@src/libs/constant';
import { switchImage } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  EngagementPostSocialAccount,
  SocialAccountType,
} from '@src/__generated__/globalTypes';

export interface PostDetails {
  campaignId: number;
  content: string;
  id?: number;
  materialsUrl: string[];
  planedPostDate: string;
  socialAccountId?: number | null;
  socialAccountMedia?: SocialAccountType | null;
  socialMedia: CampaignSocialMediaType | null;
}

interface PostFormProps {
  isEdit?: boolean;
  socialAccounts: EngagementPostSocialAccount[];
  status: EngagementCampaignPostStatus;
}

const PostForm = ({ isEdit, socialAccounts, status }: PostFormProps) => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<SocialAccountType | null>(null);
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<PostDetails>();
  const [materialsUrl, planedPostDate, socialAccountId, socialMedia] = watch([
    'materialsUrl',
    'planedPostDate',
    'socialAccountId',
    'socialMedia',
  ]);
  const disabledFields = ![EngagementCampaignPostStatus.REJECT, EngagementCampaignPostStatus.REVIEWING].includes(
    status
  );

  const igSocialMedias = SOCIAL_MEDIAS.filter(sm =>
    [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(sm.value)
  );
  const socialAccountsDropdown =
    socialAccounts.map(socialAccount => ({
      icon: <Icomoon icon={switchImage({ socialMedia: socialAccount.socialMedia })} size={18} />,
      label: socialAccount.name,
      value: socialAccount.id.toString(),
    })) || [];

  useEffect(() => {
    if (socialAccounts.length > 0 && socialAccountId) {
      const accSocialMedia = socialAccounts.find(socialAcc => socialAcc.id === socialAccountId)?.socialMedia || null;
      setSelectedSocialMedia(accSocialMedia);
      setValue('socialAccountMedia', accSocialMedia);
      const sm =
        accSocialMedia === SocialAccountType.FACEBOOK_PAGE
          ? CampaignSocialMediaType.FACEBOOK
          : accSocialMedia === SocialAccountType.INSTAGRAM
          ? [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(
              socialMedia as CampaignSocialMediaType
            )
            ? socialMedia
            : null
          : accSocialMedia;
      setValue('socialMedia', sm as CampaignSocialMediaType);
    }
  }, [socialAccountId]);

  return (
    <div css={styles.container}>
      <div css={styles.infoContainer}>
        <InputContainer>
          <Select
            disabled={disabledFields}
            error={!!errors.socialAccountId}
            isRequired
            options={socialAccountsDropdown}
            title="Select Social Account"
            value={String(socialAccountId) || ''}
            onChange={value => setValue('socialAccountId', value ? Number(value) : null)}
          />
          <ErrorMessage message={errors.socialAccountId?.message} />
        </InputContainer>

        {selectedSocialMedia === SocialAccountType.INSTAGRAM && (
          <InputContainer>
            <Select
              disabled={disabledFields}
              error={!!errors.socialAccountMedia}
              isRequired
              options={igSocialMedias}
              title="Post Type (Instagram)"
              value={socialMedia || ''}
              onChange={value => setValue('socialMedia', value as CampaignSocialMediaType)}
            />
            <ErrorMessage message={errors.socialAccountMedia?.message} />
          </InputContainer>
        )}

        <InputContainer>
          <Label css={styles.label} title={t('Selector.Plan Date of Post')} isRequired={true} />
          <DayPicker
            disableClear
            disabled={disabledFields}
            disabledRange={{ before: new Date() }}
            error={!!errors.planedPostDate}
            numberOfMonths={2}
            value={planedPostDate}
            onChange={value => setValue('planedPostDate', value)}
          />
          <ErrorMessage message={errors.planedPostDate?.message} />
        </InputContainer>

        <InputContainer>
          <SentenceForm
            css={styles.sentenceForm}
            disabled={disabledFields}
            error={!!errors.content}
            isRequired={true}
            placeholder="Placeholder"
            title="Caption"
            {...register('content')}
          />
          <ErrorMessage message={errors.content?.message} />
        </InputContainer>

        <InputContainer>
          <Label title="Image/Video" help={t('Files Help')} />
          <DragAndDrop
            accept="image/gif, image/jpeg, image/png, video/avi, video/mp4, video/quicktime"
            css={styles.fileUploadContainer}
            disabled={disabledFields}
            contentNotes={[
              t('DragAndDrop.Info'),
              t('DragAndDrop.MaxSize', { maxSize: '10GB' }),
              t('DragAndDrop.FileType', {
                MIME: 'PNG, GIF, JPEG, AVI, MOV, MP4',
              }),
              t('DragAndDrop.ImageRatio'),
            ]}
            multiple
            value={materialsUrl}
            onChange={val => setValue('materialsUrl', val)}
          />
          <DragAndDropPreview
            css={styles.fileUploadPreviewCss}
            value={materialsUrl}
            onDelete={index =>
              setValue('materialsUrl', [...materialsUrl.slice(0, index), ...materialsUrl.slice(index + 1)])
            }
          />
        </InputContainer>

        <div css={styles.formActionSection}>
          <ThemeButton
            theme="blue"
            width="max-content"
            disabled={isSubmitting}
            type="submit"
            text={isEdit ? 'Upload Post' : 'Upload Draft Post'}
          />
        </div>
      </div>
    </div>
  );
};

const InputContainer = styled.div({
  marginBottom: 16,
});

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  fileUploadContainer: css({
    '& label': {
      fontSize: 13,
    },
  }),
  fileUploadPreviewCss: css({
    position: 'relative',

    '& > div': {
      counterIncrement: 'count',
      maxWidth: 292,
      width: '45%',

      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        width: '100%',
      },

      '& > div::after': {
        alignItems: 'center',
        backgroundColor: THEME.colors.white,
        borderRadius: 5,
        color: THEME.font.colors.blue.main,
        content: '"Image " counter(count)',
        display: 'flex',
        fontSize: 13,
        fontWeight: 600,
        height: 24,
        left: 16,
        justifyContent: 'center',
        position: 'absolute',
        top: 16,
        width: 62,
      },
    },
  }),
  formActionSection: css({
    backgroundColor: THEME.colors.white,
    borderTop: '1px solid #eef3f7',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 16,

    '& input': {
      borderRadius: 3,
      height: 32,
      lineHeight: 0,
      textTransform: 'capitalize',
      width: 160,

      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        width: '100%',
      },
    },

    '& > button': {
      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        height: 40,
        width: '100%',
      },
    },
  }),
  imagesContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 16,

    '& img': {
      backgroundColor: '#000',
      height: 219,
      maxWidth: 292,
      objectFit: 'contain',
      width: '100%',
    },
  }),
  infoContainer: css({
    backgroundColor: THEME.colors.white,
    maxWidth: 648,
    padding: 16,
    width: '100%',
  }),
  label: css({
    fontSize: 13,
  }),
  sentenceForm: css({
    '& > label': {
      fontSize: 13,
    },

    '& > textarea': {
      borderRadius: 2,

      '&:focus': {
        borderColor: '#e0e8ed',
      },
    },
  }),
};

export default PostForm;
