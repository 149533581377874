import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdateEngagementPostForInfluencerV2Mutation,
  UpdateEngagementPostForInfluencerV2MutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdateEngagementPostForInfluencerV2.graphql';

export const useUpdateEngagementPostForInfluencerV2Mutation = (
  options?: MutationHookOptions<
    UpdateEngagementPostForInfluencerV2Mutation,
    UpdateEngagementPostForInfluencerV2MutationVariables
  >
) =>
  useMutation<UpdateEngagementPostForInfluencerV2Mutation, UpdateEngagementPostForInfluencerV2MutationVariables>(
    MUTATION,
    options
  );
