import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Icomoon from '@src/components/atoms/Icomoon';
import NotificationBadge from '@src/components/molecules/NotificationBadge';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface ToolbarProps {
  backPath?: string;
  contentWidth?: number;
  customContent?: ReactNode;
  hasNotification?: boolean;
  title?: string;
}

const Toolbar = ({ backPath, contentWidth = 614, customContent, hasNotification, title }: ToolbarProps) => {
  const { t } = useTranslation();

  return (
    <Container contentWidth={contentWidth} hasBackButton={!!backPath}>
      <div className="content">
        {customContent || (
          <>
            {backPath && (
              <Link className="back-button" to={backPath}>
                <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
              </Link>
            )}
            <label className="title">{t(`Title.${title}`)}</label>
          </>
        )}
      </div>
      <div className="notification">{hasNotification && <NotificationBadge />}</div>
    </Container>
  );
};

const Container = styled.div<{ contentWidth: number; hasBackButton: boolean }>(({ contentWidth, hasBackButton }) => ({
  alignItems: 'center',
  background: THEME.colors.white,
  display: 'flex',
  padding: '8px 16px',
  position: 'fixed',
  top: 0,
  width: 'fill-available',
  zIndex: 5,

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    padding: '28px 40px',
    position: 'unset',
    top: 'unset',
    width: 'unset',
    zIndex: 'unset',

    '&::before': {
      content: '""',
      display: 'block',
      width: 'fill-available',
    },
  },

  '& > .content': {
    alignItems: 'center',
    display: 'flex',
    width: 'fill-available',
    ...(hasBackButton && { textAlign: 'center' }),

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      minWidth: contentWidth,
      width: 'unset',
    },

    '& > .back-button': {
      '[dir="rtl"] &': {
        transform: 'scaleX(-1)',
      },
    },

    '& > .title': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      width: 'fill-available',
    },
  },

  '& > .notification': {
    display: 'flex',
    justifyContent: 'flex-end',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      width: 'fill-available',
    },
  },
}));

export default Toolbar;
