import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  EngagementPostForInfluencerV2Query,
  EngagementPostForInfluencerV2QueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/EngagementPostForInfluencerV2.graphql';

export const useEngagementPostForInfluencerV2Query = (
  options?: QueryHookOptions<EngagementPostForInfluencerV2Query, EngagementPostForInfluencerV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<EngagementPostForInfluencerV2Query, EngagementPostForInfluencerV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useEngagementPostForInfluencerV2LazyQuery = (
  options?: LazyQueryHookOptions<EngagementPostForInfluencerV2Query, EngagementPostForInfluencerV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<EngagementPostForInfluencerV2Query, EngagementPostForInfluencerV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
