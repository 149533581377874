import React from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { arrowTopLeft, arrowTopRight } from '@src/assets/htmlCodes';
import anyCreatorLogo from '@src/assets/img/anyCreatorLogo.png';
import { Icomoon, Switch } from '@src/components/atoms';
import { useDirLayout } from '@src/libs/hooks';
import { switchImage } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';

export const defaultWidth = 247;

interface AccessPermissionProps {
  width?: number;
}

const AccessPermission = ({ width = defaultWidth }: AccessPermissionProps) => {
  const { t } = useTranslation();
  const { isRtl } = useDirLayout();
  const options = [
    { sample: 'sample@anymindgroup.com', title: 'Receive your email address' },
    {
      sample: 'sample_business_01',
      title: 'Access profile and posts from the Instagram account connected to your Page',
    },
    { sample: 'sample_business_01', title: 'Manage comments for the Instagram account connected to your Page' },
    { sample: 'sample_business_01', title: 'Access insights for the Instagram account connected to your Page' },
  ];
  const quotient = width / defaultWidth;
  const exchangeIconSize = quotient * 8;
  const iconSize = quotient * 12;

  return (
    <div css={styles.container(quotient)}>
      <div css={styles.accessPermissionContainer(quotient)}>
        <div css={styles.toolbar(quotient)}>
          <Icomoon icon={switchImage({ socialMedia: SocialAccountType.FACEBOOK })} size={iconSize} />
          <Icomoon color="#8f95a0" icon="exchange-round" size={exchangeIconSize} />
          <img height={iconSize} src={anyCreatorLogo} width={iconSize} />
        </div>

        <div css={styles.contentContainer(quotient)}>
          <div>{t('Facebook access permission page title')}</div>
          <div>
            <AiOutlineExclamationCircle color="#839ed8" size={quotient * 10} />
            <div>{t('Facebook access permission page hint')}</div>
          </div>

          <div>
            {options.map((option, optionIndex) => {
              const { sample, title } = option;

              return (
                <div css={styles.option(quotient)} key={optionIndex}>
                  <div>
                    <div>{t(title)}</div>
                    <div>{sample}</div>
                  </div>
                  <div>
                    <Switch checked color="#4379de" labelOn="" />
                  </div>
                </div>
              );
            })}
          </div>

          <div css={styles.actionContainer(quotient)}>
            <div>{t('Button.Back')}</div>
            <div>{t('Button.Done')}</div>
          </div>

          <div css={styles.scrollbar(quotient)} />
          <div css={styles.highlightedBox(quotient)} />
          <div css={styles.arrow(quotient)}>{isRtl ? arrowTopLeft : arrowTopRight}</div>
        </div>
      </div>

      <div css={styles.message(quotient)}>
        <div>{t('Turn all toggles')}</div>
        <Switch checked />
      </div>
    </div>
  );
};

const styles = {
  accessPermissionContainer: (quotient: number) =>
    css({
      border: THEME.box.borders.gray.main,
      borderRadius: 5,
      boxShadow: THEME.box.shadows.outer,
      height: quotient * 377,
      position: 'relative',
      width: quotient * 211,
    }),
  actionContainer: (quotient: number) =>
    css({
      backgroundColor: THEME.colors.white,
      borderTop: '1px solid #e0dfe0',
      bottom: 0,
      display: 'flex',
      gap: quotient * 4,
      left: 0,
      padding: quotient * 18,
      position: 'absolute',
      width: 'fill-available',

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        border: '1px solid #bcbcbf',
        borderRadius: 3,
        color: '#4e4f53',
        display: 'flex',
        flex: 1,
        fontSize: quotient * 8,
        fontWeight: 600,
        justifyContent: 'center',
        padding: `${quotient * 6}px 0`,
      },

      '& > div:nth-of-type(2)': {
        alignItems: 'center',
        backgroundColor: '#4f82f7',
        border: '1px solid #4f82f7',
        borderRadius: 3,
        color: THEME.font.colors.white,
        display: 'flex',
        flex: 1,
        fontSize: quotient * 8,
        fontWeight: 600,
        justifyContent: 'center',
        padding: `${quotient * 6}px 0`,
      },
    }),
  arrow: (quotient: number) =>
    css({
      bottom: quotient * 15,
      color: THEME.font.colors.error,
      fontFamily: '"Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSize: quotient * 45,
      position: 'absolute',
      right: quotient * 50,

      '[dir="rtl"] &': {
        left: quotient * 50,
        right: 'unset',
      },
    }),
  container: (quotient: number) =>
    css({
      width: quotient * 211,
    }),
  contentContainer: (quotient: number) =>
    css({
      padding: `${quotient * 8}px ${quotient * 19}px`,

      '& > div:nth-of-type(1)': {
        color: '#060508',
        fontSize: quotient * 10,
        fontWeight: 600,
        marginBottom: quotient * 24,
      },

      '& > div:nth-of-type(2)': {
        alignItems: 'center',
        border: '1px solid #e6e4e7',
        borderLeft: `${quotient * 2}px solid #4278e0`,
        display: 'flex',
        height: quotient * 18,
        marginBottom: quotient * 24,
        padding: quotient * 6,
        position: 'relative',

        '[dir="rtl"] &': {
          borderLeft: 'none',
          borderRight: `${quotient * 2}px solid #4278e0`,
        },

        '& > div': {
          backgroundColor: THEME.colors.white,
          color: '#89898b',
          fontSize: quotient * 8,
          fontWeight: 500,
          padding: `${quotient * 5}px ${quotient * 7}px`,
          position: 'absolute',
          right: 0,
          top: 0,
          width: '85%',

          '[dir="rtl"] &': {
            left: 0,
            right: 'unset',
          },
        },
      },
    }),
  highlightedBox: (quotient: number) =>
    css({
      border: `${quotient * 2}px solid #ff5f5f`,
      borderRadius: 3,
      height: quotient * 175,
      position: 'absolute',
      right: quotient * 20,
      top: quotient * 142,
      width: quotient * 25,

      '[dir="rtl"] &': {
        left: quotient * 20,
        right: 'unset',
      },
    }),
  message: (quotient: number) =>
    css({
      alignItems: 'center',
      color: THEME.font.colors.error,
      display: 'flex',
      fontSize: quotient * 16,
      fontWeight: 600,
      gap: THEME.box.gaps.s,
      marginTop: quotient * 21,
      justifyContent: 'center',

      '& > div:nth-of-type(2)': {
        height: quotient * 24,
        width: quotient * 48,

        '& > .slider:before': {
          height: quotient * 16,
          left: 0,
          top: 4,
          width: quotient * 16,
        },

        '& > .label-on': {
          fontSize: quotient * 12,
        },
      },
    }),
  option: (quotient: number) =>
    css({
      borderBottom: '1px solid #e6e4e7',
      display: 'flex',
      padding: `${quotient * 6}px 0`,

      '& > div:nth-of-type(1)': {
        width: '80%',

        '& > div:nth-of-type(1)': {
          color: '#606062',
          fontSize: quotient * 8,
          fontWeight: 600,
          marginBottom: quotient * 2,
        },

        '& > div:nth-of-type(2)': {
          color: '#9b9b9c',
          fontSize: quotient * 7,
        },
      },

      '& > div:nth-of-type(2)': {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',

        '& > div': {
          height: quotient * 8,
          width: quotient * 12,

          '& > .slider:before': {
            border: '1px solid #d8d6d8',
            height: quotient * 8,
            left: -24,
            top: -1,
            width: quotient * 8,
          },
        },
      },
    }),
  scrollbar: (quotient: number) =>
    css({
      backgroundColor: '#98a3af',
      borderRadius: 35,
      height: quotient * 90,
      position: 'absolute',
      right: quotient * 5,
      top: quotient * 150,
      width: quotient * 2,
    }),
  toolbar: (quotient: number) =>
    css({
      alignItems: 'center',
      background: 'linear-gradient(#e1e1e1 10%, #f8f7fb 35%)',
      display: 'flex',
      gap: quotient * 6,
      padding: quotient * 8,
      position: 'relative',

      '&::after': {
        background: 'linear-gradient(to right, #367bed 85%, #eceef1 15%)',
        borderTop: '1px solid #e2e4e6',
        bottom: 0,
        content: '""',
        left: 0,
        height: quotient * 3,
        position: 'absolute',
        width: '100%',

        '[dir="rtl"] &': {
          background: 'linear-gradient(to left, #367bed 85%, #eceef1 15%)',
        },
      },
    }),
};

export default AccessPermission;
