import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InitialLoading } from '@src/components/atoms';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const SocialAuth = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const socialMedia = (urlSearchParams.get('sm') as SocialAccountType) || SocialAccountType.TIKTOK;

  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.MOBILE_SOCIAL_AUTH);

  useEffect(() => {
    connectSocialAuth(socialMedia);
  }, []);

  return <InitialLoading />;
};

export default SocialAuth;
