import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  InfluencerDeleteYoutubeCompareAccountAnalyticsMutation,
  InfluencerDeleteYoutubeCompareAccountAnalyticsMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/InfluencerDeleteYoutubeCompareAccountAnalytics.graphql';

export const useInfluencerDeleteYoutubeCompareAccountAnalyticsMutation = (
  options?: MutationHookOptions<
    InfluencerDeleteYoutubeCompareAccountAnalyticsMutation,
    InfluencerDeleteYoutubeCompareAccountAnalyticsMutationVariables
  >
) =>
  useMutation<
    InfluencerDeleteYoutubeCompareAccountAnalyticsMutation,
    InfluencerDeleteYoutubeCompareAccountAnalyticsMutationVariables
  >(MUTATION, options);
