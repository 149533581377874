import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { AllRegionsQuery, AllRegionsQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/AllRegions.graphql';

export const useAllRegionsQuery = (options?: QueryHookOptions<AllRegionsQuery, AllRegionsQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<AllRegionsQuery, AllRegionsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useAllRegionsLazyQuery = (options?: LazyQueryHookOptions<AllRegionsQuery, AllRegionsQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<AllRegionsQuery, AllRegionsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
