import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import { FormStatus } from '@src/__generated__/globalTypes';

interface StatusIndicatorProps {
  status: FormStatus;
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const { t } = useTranslation();

  const color = status === FormStatus.PRIVATE ? '#6e7c89' : status === FormStatus.DRAFT ? '#ffb63d' : '#40b87c';

  return <Indicator color={color}>{t(status)}</Indicator>;
};

const Indicator = styled.div<{ color: string }>(({ color }) => ({
  alignItems: 'center',
  backgroundColor: color,
  borderRadius: 35,
  display: 'flex',
  color: THEME.font.colors.white,
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 600,
  height: 24,
  justifyContent: 'center',
  padding: '0 16px',
}));

export default StatusIndicator;
