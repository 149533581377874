import React, { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { InitialLoading } from '@src/components/atoms';
import { DIALOG_REQUIRED_ACTIONS } from '@src/components/shared/Campaign/helpers';
import { useAnyXSocialAuthReconnectTikTokMutation } from '@src/graphql/hooks';
import { getConnectSocialAuthState } from '@src/libs/auth';
import { getMessagesFromFetchResult, ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { switchText } from '@src/libs/socialMedia';
import { generatePath, ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useAuthProviderResponse } from '../hooks';

const TiktokReconnect = () => {
  const { enqueueSnackbar, navigate, t } = useQueryHelper();
  const { provider, response } = useAuthProviderResponse(ROUTES.SETTINGS_SOCIAL_CONNECT);
  const [anyXSocialAuthReconnectTikTok] = useAnyXSocialAuthReconnectTikTokMutation();
  const {
    connectState: { isNewUI, marketplaceId, socialAccountId },
    resetConnectState,
  } = getConnectSocialAuthState();

  const reconnectTiktok = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      navigate(ROUTES.SETTINGS_SOCIAL_CONNECT);

      return;
    }

    const variables = {
      input: {
        id: String(socialAccountId),
        response,
      },
    };

    const { ok, errors } = await anyXSocialAuthReconnectTikTok({ variables })
      .then(result => {
        if (result.data?.anyXSocialAuthReconnectTikTok?.ok) {
          return { ok: true, errors: [] };
        } else {
          return { ok: false, errors: getMessagesFromFetchResult(result) };
        }
      })
      .catch((e: ApolloError) => ({
        ok: false,
        errors: [e?.graphQLErrors[0].message],
      }));

    if (ok && errors.length === 0) {
      enqueueSnackbar('Success to Connect', { variant: 'success' });
      sendAmplitudeEvent(eventTypes.connectSocial, { socialMedia: switchText(SocialAccountType.TIKTOK) });
      navigate(
        marketplaceId
          ? generatePath(ROUTES.FIND_JOBS_MARKETPLACE_ID, { id: marketplaceId })
          : isNewUI
          ? ROUTES.MY_PAGE_SOCIAL_CONNECT
          : ROUTES.SETTINGS_SOCIAL_CONNECT
      );
    } else {
      let isTiktokGrantPermission = false;
      errors.forEach(error => {
        if (provider === SocialAccountType.TIKTOK && error === ErrorTypes.VIDEO_PERMISSION_MISSING) {
          isTiktokGrantPermission = true;
        } else {
          enqueueSnackbar(t(error), { variant: 'error' });
        }
      });

      navigate(
        isTiktokGrantPermission
          ? marketplaceId
            ? generatePath(
                ROUTES.FIND_JOBS_MARKETPLACE_ID,
                { id: marketplaceId },
                { [DIALOG_REQUIRED_ACTIONS.TIKTOK_GRANT_PERMISSION]: true }
              )
            : generatePath(
                isNewUI
                  ? ROUTES.MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION
                  : ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION,
                {},
                { id: socialAccountId }
              )
          : ROUTES.SETTINGS_SOCIAL_CONNECT
      );
    }
  };

  useEffect(() => {
    reconnectTiktok();

    return () => {
      resetConnectState();
    };
  }, []);

  return <InitialLoading />;
};

export default TiktokReconnect;
