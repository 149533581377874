import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  GetAutoManagedCampaignForInfluencerSearchJobQuery,
  GetAutoManagedCampaignForInfluencerSearchJobQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetAutoManagedCampaignForInfluencerSearchJob.graphql';

export const useGetAutoManagedCampaignForInfluencerSearchJobQuery = (
  options?: QueryHookOptions<
    GetAutoManagedCampaignForInfluencerSearchJobQuery,
    GetAutoManagedCampaignForInfluencerSearchJobQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<
    GetAutoManagedCampaignForInfluencerSearchJobQuery,
    GetAutoManagedCampaignForInfluencerSearchJobQueryVariables
  >(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetAutoManagedCampaignForInfluencerSearchJobLazyQuery = (
  options?: LazyQueryHookOptions<
    GetAutoManagedCampaignForInfluencerSearchJobQuery,
    GetAutoManagedCampaignForInfluencerSearchJobQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<
    GetAutoManagedCampaignForInfluencerSearchJobQuery,
    GetAutoManagedCampaignForInfluencerSearchJobQueryVariables
  >(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
