import range from 'lodash/range';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import emptyAlignCenter from '@src/assets/img/chat/emptyAlignCenter.png';
import emptyAlignStart from '@src/assets/img/chat/emptyAlignStart.png';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

const Empty = () => {
  const { t } = useTranslation();
  const cards = [
    {
      color: '#dcf3f6',
      description: `View your fan's information`,
      icon: 'address-book-tiffany-blue',
      title: 'Fan Detail',
    },
    {
      color: '#f0f3f9',
      description: `View your fan’s activities and you can leave comments`,
      icon: 'clock-deep-blue-light',
      title: 'Activity',
    },
    {
      color: '#f6f5f3',
      description: `View the fan’s orders or check the shipping or payment status at any time`,
      icon: 'clipboard-orange-light',
      title: 'Orders',
    },
  ];

  return (
    <div>
      <div css={styles.headerContainer}>
        <div css={styles.lineInfoContainer}>
          <div>
            <div />
          </div>
          <div>
            <img alt="emptyName" height="26" src={emptyAlignStart} />
          </div>
        </div>

        <div css={styles.customerInfoContainer}>
          {range(4).map(num => (
            <div key={num}>
              <img alt="emptyDetail" height="20" src={emptyAlignCenter} />
            </div>
          ))}
        </div>
      </div>

      <div css={styles.cardsContainer}>
        {cards.map(card => {
          const { color, description, icon, title } = card;

          return (
            <Card color={color} key={title}>
              <div>
                <Icomoon icon={icon} size={30} />
              </div>
              <div>
                <span>{t(`MenuTitle.${title}`)}</span>
                <span>{t(`Annotation.${description}`)}</span>
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

const Card = styled.div<{ color: string }>(({ color }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 32,
  gap: THEME.box.gaps.l,

  '& > div:nth-of-type(1)': {
    alignItems: 'center',
    backgroundColor: color,
    borderRadius: 7,
    display: 'flex',
    height: 56,
    justifyContent: 'center',
    width: 56,
  },

  '& > div:nth-of-type(2)': {
    display: 'grid',
    flex: 1,
    height: 'fit-content',

    '& > span:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      marginBottom: 4,
    },

    '& > span:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subordinate,
    },
  },
}));

const styles = {
  cardsContainer: css({
    padding: 30,
  }),
  customerInfoContainer: css({
    alignItems: 'center',
    border: '1px solid #eef3f7',
    borderRadius: 3,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 8,

    '& > div': {
      alignItems: 'center',
      boxShadow: THEME.box.shadows.outer,
      display: 'flex',
      flexBasis: '24.5%',
      height: 32,
      justifyContent: 'center',

      '& > img': {
        width: '80%',
      },
    },

    '& > div:nth-of-type(4)': {
      boxShadow: 'none',
    },
  }),
  headerContainer: css({
    backgroundColor: THEME.colors.white,
    borderBottom: '1px solid #dee5ec',
    display: 'grid',
    padding: 16,
  }),
  lineInfoContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 8,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      backgroundColor: THEME.colors.white,
      display: 'flex',
      height: 64,
      justifyContent: 'center',
      marginRight: 8,
      width: 64,

      '& > div': {
        backgroundColor: THEME.colors.blue.main,
        borderRadius: '50%',
        height: 53,
        opacity: 0.3,
        width: 53,
      },
    },

    '& > div:nth-of-type(2)': {
      alignItems: 'center',
      display: 'flex',
      flex: 1,

      '& > img': {
        width: '55%',
      },
    },
  }),
};

export default Empty;
