import React, { Fragment, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useDimensions from '@src/libs/hooks/useDimensions';
import Icomoon from '@src/components/atoms/Icomoon';
import TextCutter from '@src/components/atoms/TextCutter';

interface ReactFormStepperProps {
  className?: string;
  steps: { label: string }[];
  size?: string;
  activeStep?: number;
  activeColor?: string;
  baseColor?: string;
  isWarning?: boolean;
}

const FormStepper = (props: ReactFormStepperProps) => {
  const {
    activeColor = '#3892e5',
    steps,
    activeStep,
    size = '15px',
    baseColor = '#c5d0da',
    className,
    isWarning,
  } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDimensions(containerRef);
  // Math.ceil seems to work better than Math.floor, in some cases UI looks better then sum of all labels is even bigger container width
  const stepLabelWidth = `${Math.ceil(width / steps.length)}px`;

  return (
    <>
      <div className={className} css={styles.stepperContainer} ref={containerRef}>
        {steps?.map((step, i) => {
          const isActive = i === activeStep;
          const isComplete = i < (activeStep || 0);
          const bgColor = isActive ? activeColor : baseColor;
          const textColor = isActive ? activeColor : baseColor;

          return (
            <Fragment key={`${step.label}_${i}`}>
              {i > 0 ? (
                <hr
                  className={isComplete ? 'completeStepConnector' : isActive && !isWarning ? 'activeStepConnector' : ''}
                  css={styles.horizontalLine}
                  key={`${i}_delimeter`}
                />
              ) : null}
              {isActive && isWarning ? (
                <div css={styles.warningStep}>
                  <Icomoon color="#ff5f5f" icon="warning" />
                  <StepLabel
                    className={isComplete ? 'completeStepLabel' : isActive ? 'activeStepLabel' : ''}
                    lines={1}
                    text={step.label || ''}
                    css={{
                      width: stepLabelWidth,
                    }}
                  />
                </div>
              ) : (
                <Step
                  className={isComplete ? 'completeStepIndicator' : isActive ? 'activeStepIndicator' : ''}
                  key={`${step.label}_${i}`}
                  bgColor={bgColor}
                  textColor={textColor}
                  css={{
                    width: size,
                    height: size,
                  }}
                >
                  <StepLabel
                    className={isComplete ? 'completeStepLabel' : isActive ? 'activeStepLabel' : ''}
                    lines={1}
                    text={step.label || ''}
                    css={{
                      width: stepLabelWidth,
                    }}
                  />
                </Step>
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

const Step = styled.div<{ bgColor: string; textColor: string }>(({ bgColor, textColor }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  placeContent: 'center',
  borderRadius: '50%',
  boxSizing: 'border-box',
  background: bgColor,
  color: textColor,
}));

const StepLabel = styled(TextCutter)({
  position: 'absolute',
  bottom: -20,
  width: 'max-content',
  fontSize: 11,
  fontWeight: 600,
  textAlign: 'center',
});

const styles = {
  horizontalLine: css({
    flex: 'auto',
    borderTop: '1px dashed #c5d0da',
  }),
  stepperContainer: css({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 24px 24px 24px',
  }),
  warningStep: css({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',

    '& > svg': {
      position: 'absolute',
      top: -5,
    },

    '& > div': {
      color: '#ff5f5f !important',
    },
  }),
};

export { Step };
export default FormStepper;
