import { ToastContainer, toast } from 'react-toastify';
import * as React from 'react';
import { css } from '@emotion/react';
import 'react-toastify/dist/ReactToastify.css';
import { Icomoon, TextCutter } from '@src/components/atoms';
import { useDirLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';

const Notification = () => {
  const { isRtl } = useDirLayout();

  return (
    <ToastContainer
      enableMultiContainer
      position={isRtl ? 'top-left' : 'top-right'}
      hideProgressBar
      closeOnClick
      draggable
      closeButton={() => <Icomoon color="#fff" css={{ filter: 'contrast(0.5)', padding: 6 }} icon="clear" size={12} />}
      css={styles.root}
      limit={3}
    />
  );
};

export const customIcons = {
  [toast.TYPE.SUCCESS]: { icon: undefined, header: 'Success' },
  [toast.TYPE.ERROR]: { icon: <Icomoon color="#ff5f5f" icon="clear-filled" size={18} />, header: 'Error' },
  [toast.TYPE.INFO]: { icon: undefined, header: 'Information' },
  [toast.TYPE.WARNING]: {
    icon: <Icomoon color="#ffb63d" icon="warning" size={24} />,
    header: 'Warning',
  },
  [toast.TYPE.DEFAULT]: { icon: undefined, header: '' },
};

interface ToastWrapperProps {
  header: string;
  message: string;
}
export const ToastWrapper = ({ header, message }: ToastWrapperProps) => (
  <div>
    {header ? <h3 css={styles.toastHeader}>{header}</h3> : null}
    <TextCutter lines={2} text={message} css={styles.toastContent} />
  </div>
);

const styles = {
  root: css({
    '.Toastify__toast': {
      alignItems: 'center',
    },

    '.Toastify__toast-body': {
      padding: 0,
    },

    '.Toastify__toast--error': {
      borderLeft: '3px solid #ff5f5f',
    },

    '.Toastify__toast--warning': {
      borderLeft: '3px solid #ffb63d',
    },

    '.Toastify__toast--success': {
      borderLeft: '3px solid #40b87c',
    },

    '.Toastify__toast--info': {
      borderLeft: '3px solid #3892e5',
    },
  }),
  toastHeader: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
  }),
  toastContent: css({
    fontSize: THEME.font.sizes.subordinate,
    color: THEME.font.colors.black.main,
  }),
};

export default Notification;
