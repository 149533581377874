import React, { ReactElement } from 'react';

interface MultiTextProps {
  className?: string;
  data: string | ReactElement[] | ReactElement;
  title?: string;
}

const MultiTextColumn = (props: MultiTextProps) => {
  const { className, data } = props;
  const title = props.title || (typeof data === 'string' ? data : '');

  return (
    <td className={className} css={{ padding: '0 8px' }} title={title}>
      <div css={{ alignItems: 'center', display: 'flex' }}>
        <div css={{ display: 'inline-block', fontSize: 13, lineHeight: '16px' }}>{data}</div>
      </div>
    </td>
  );
};

export default MultiTextColumn;
