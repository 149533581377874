import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { useDeepCompareEffect, useDirLayout, useGlobalLayout, useQueryHelper } from '@src/libs/hooks';
import { closeIcon } from '@src/assets/htmlCodes';
import { THEME } from '@src/libs/theme';
import { checkIsActivePathname, uuumMenuItems, MenuItemProps } from './helpers';
import Menu from './Menu';

interface MobileMenuProps {
  open: boolean;
  onClose: () => void;
}

const MobileMenu = ({ open, onClose }: MobileMenuProps) => {
  const { pathname, t } = useQueryHelper();
  const { dir, isRtl } = useDirLayout();
  const { setGlobalLayout } = useGlobalLayout();
  const isSelectedMenu = uuumMenuItems
    .filter(menu => menu.submenus)
    .find(menu =>
      menu.submenus?.some(submenu => {
        // submenu may contain multiple pathname
        // to check has visible submenu
        if (typeof submenu.to === 'string') {
          return checkIsActivePathname(submenu.to, true);
        } else {
          return submenu.to.some(path => checkIsActivePathname(path, true));
        }
      })
    );

  const selectedMenuItems =
    isSelectedMenu && (uuumMenuItems.find(menu => menu.title === isSelectedMenu.title) as MenuItemProps);
  const isAnalytics = pathname.includes('analytics');

  useDeepCompareEffect(() => {
    setGlobalLayout({ hasAnalyticsTabs: !!selectedMenuItems });
  }, [selectedMenuItems]);

  return (
    <div css={styles.container}>
      <div css={[styles.overlay, open ? { opacity: 1, visibility: 'visible' } : {}]}>
        <div css={[styles.closeBtn, isRtl ? { left: '16px' } : { right: '16px' }]} onClick={onClose}>
          <span css={{ fontSize: '30px' }}>{closeIcon}</span>
        </div>

        <div css={[isRtl ? styles.menuContainerRtl : styles.menuContainer, open ? styles.menuContainerShow : {}]}>
          <Menu />
        </div>
      </div>

      {selectedMenuItems && (
        <div css={styles.submenu} dir={dir}>
          {selectedMenuItems.submenus?.map((submenu, index) => {
            const { activeIcon, activeSubmenuIcon, icon, to } = submenu;
            const isActive =
              typeof to === 'string' ? checkIsActivePathname(to) : to.some(path => checkIsActivePathname(path));
            // submenu may contain multiple valid pathname
            const redirect = typeof to === 'string' ? to : to[0];

            return (
              <SubmenuContainer key={index} to={redirect}>
                <div>
                  <Icomoon
                    color={isActive ? '#3992e5' : '#6e7c89'}
                    icon={isActive ? activeSubmenuIcon || activeIcon : icon}
                    size={24}
                  />
                </div>
                <SubmenuTitle isActive={isActive} isAnalytics={isAnalytics}>
                  {t(submenu.title)}
                </SubmenuTitle>
              </SubmenuContainer>
            );
          })}
        </div>
      )}
    </div>
  );
};

const SubmenuContainer = styled(Link)({
  padding: '0 8px',

  '& > div': {
    display: 'flex',
    justifyContent: 'center',
  },
});

const SubmenuTitle = styled.div<{ isActive: boolean; isAnalytics: boolean }>(({ isActive, isAnalytics }) => ({
  color: isActive ? (isAnalytics ? '#27313b' : '#3892e5') : '#97a4af',
  display: 'flex',
  fontWeight: 600,
  justifyContent: 'center',
  width: '100%',
}));

const styles = {
  closeBtn: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    position: 'absolute',
    top: 16,
    width: 40,

    '& i': {
      fontSize: 25,
      marginTop: 8,
    },
  }),
  container: css({
    position: 'relative',
  }),
  menuContainer: css({
    '& > div': {
      transform: 'translateX(-100%)',
      transition: 'transform 0.5s',
    },
  }),
  menuContainerRtl: css({
    '& > div': {
      transform: 'translateX(100%)',
      transition: 'transform 0.5s',
    },
  }),
  menuContainerShow: css({
    '& > div': {
      transform: 'translateX(0)',
    },
  }),
  overlay: css({
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    bottom: 0,
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
    visibility: 'hidden',
    width: '100%',
    zIndex: 10,
  }),
  submenu: css({
    backgroundColor: THEME.colors.white,
    borderBottom: '1px solid #e5ebf1',
    bottom: 0,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    height: 56,
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflowX: 'auto',
    position: 'fixed',
    width: '100%',
    zIndex: 5,
  }),
};

export default MobileMenu;
