import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Icomoon, TextCutter } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { AnyXMarketplaceCampaignStatus } from '@src/__generated__/globalTypes';

interface StatusIndicatorProps {
  status: AnyXMarketplaceCampaignStatus;
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const { t } = useTranslation();

  const { background, color, icon, message, title } = (() => {
    switch (status) {
      case AnyXMarketplaceCampaignStatus.APPROVED:
        return {
          background: '#d9f1e5',
          color: '#40b87c',
          icon: 'check',
          message: 'Your post has been approved',
          title: 'Approved',
        };
      case AnyXMarketplaceCampaignStatus.ASSESSING:
        return {
          background: '#fef1db',
          color: '#f8b74a',
          icon: 'clock-anti-clockwise',
          message: 'Please wait until the review is complete',
          title: 'Assessing',
        };
      case AnyXMarketplaceCampaignStatus.EFFECTIVE:
        return {
          background: '#d9f1e5',
          color: '#40b87c',
          icon: 'bar-chart-rise-outlined',
          message: 'Please check the report',
          title: 'Effective',
        };
      case AnyXMarketplaceCampaignStatus.INEFFECTIVE:
        return {
          background: '#ffe9e9',
          color: THEME.font.colors.error,
          icon: 'warning',
          message: 'Your post was not approved',
          title: 'Ineffective',
        };
      case AnyXMarketplaceCampaignStatus.POSTING:
        return {
          background: '#fef1db',
          color: '#f8b74a',
          icon: 'pencil-square',
          message: 'Please make a post',
          title: 'Posting',
        };
      case AnyXMarketplaceCampaignStatus.REVIEWING:
        return {
          background: '#d7e9fa',
          color: THEME.font.colors.blue.main,
          icon: 'clock-anti-clockwise',
          message: 'Please wait for your post to be approved',
          title: 'Reviewing',
        };
      case AnyXMarketplaceCampaignStatus.SHIPPED:
        return {
          background: '#d9f1e5',
          color: '#40b87c',
          icon: 'truck',
          message: 'Item has been shipped',
          title: 'Shipped',
        };
      case AnyXMarketplaceCampaignStatus.WAITING_FOR_SHIPMENT:
        return {
          background: '#fef1db',
          color: '#f8b74a',
          icon: 'truck',
          message: 'Please wait for the product to be shipped',
          title: 'Waiting',
        };
      case AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE:
        return {
          background: '#ffe9e9',
          color: THEME.font.colors.error,
          icon: 'warning',
          message: 'Please revise the post',
          title: 'Warning',
        };
      default:
        return {
          background: '#fef1db',
          color: '#f8b74a',
          icon: 'clock-anti-clockwise',
          message: 'Please wait till information updated',
          title: 'Preparing',
        };
    }
  })();

  return (
    <Container background={background} color={color}>
      <div>
        <Icomoon color={color} icon={icon} size={12} />
        <div>{t(title)}</div>
      </div>
      <TextCutter lines={1} text={t(message)} />
    </Container>
  );
};

const Container = styled.div<{ background: string; color: string }>(({ background, color }) => ({
  alignItems: 'center',
  border: `1px solid ${background}`,
  borderRadius: 10,
  display: 'flex',
  width: 'fill-available',

  '& > div:nth-of-type(1)': {
    alignItems: 'center',
    background,
    color,
    display: 'flex',
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    gap: THEME.box.gaps.xs,
    padding: '8px 12px',
  },

  '& > div:nth-of-type(2)': {
    color,
    fontSize: THEME.font.sizes.subordinate,
    padding: '0 12px',
  },
}));

export default StatusIndicator;
