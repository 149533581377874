import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/browser';
import App from './App';
import { config } from './libs/config';
import { ErrorBoundary, InitialLoading } from './components/atoms';
import { getAppRootElement } from './libs/utils';

// eslint-disable-next-line no-underscore-dangle,no-undef
const __DEV__ = process.env.NODE_ENV === 'development';
if (!__DEV__) {
  Sentry.init(config.sentry);
}

const container = getAppRootElement();
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <RecoilRoot>
      <React.Suspense fallback={<InitialLoading />}>
        <App />
      </React.Suspense>
    </RecoilRoot>
  </ErrorBoundary>
);
