import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFansQuery, GetFansQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFans.graphql';

export const useGetFansQuery = (options?: QueryHookOptions<GetFansQuery, GetFansQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFansQuery, GetFansQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFansLazyQuery = (options?: LazyQueryHookOptions<GetFansQuery, GetFansQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFansQuery, GetFansQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
