import { css } from '@emotion/react';
import * as React from 'react';
import notFoundGuy from '@src/assets/img/notFoundGuy.svg';
import { useDocumentTitle } from '@src/libs/hooks';
import { ThemeButton } from '@src/components/molecules';
import { ROUTES } from '@src/shared/routes';

const Template = ({ showLink = true }) => {
  useDocumentTitle({ title: 'pageTitle.Not Found' });

  return (
    <div css={styles.wrapper}>
      <img css={styles.image} src={notFoundGuy} alt="a man can't found and confused" />
      <p css={styles.notFoundText}>
        404
        <br />
        <span>Not found</span>
      </p>
      {showLink && <ThemeButton height="40px" text="Back to top" to={ROUTES.ROOT} width="max-content" />}
    </div>
  );
};

const styles = {
  image: css({
    width: 100,
    height: 100,
    objectFit: 'contain',
    marginBottom: 10,
  }),
  notFoundText: css({
    fontSize: 30,
    fontWeight: 800,
    letterSpacing: 7,
    lineHeight: '20px',
    marginBottom: 32,

    span: {
      fontSize: 13,
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: 'normal',
    },
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#f6f8fa',
    paddingTop: '30%',
  }),
};

export default Template;
