import styled from '@emotion/styled';
import * as React from 'react';
import { css } from '@emotion/react';
import emptyImage from '@src/assets/img/emptyImage.png';
import emptyVideo from '@src/assets/img/videoThumbnail.png';
import { Icomoon } from '@src/components/atoms';
import { getFileTypeFromUrl, getDecodedUrlFileName } from '@src/libs/requests';
import { THEME } from '@src/libs/theme';

export interface PreviewProps {
  className?: string;
  disabled?: boolean;
  previewWidth?: string;
  value: string[];
  onDelete: (index: number) => void;
}

const Preview = (props: PreviewProps) => {
  const { className, disabled, previewWidth, value, onDelete } = props;

  return (
    <Container className={className} previewWidth={previewWidth}>
      {value.map((url, index) => {
        const fileType = getFileTypeFromUrl(url);
        const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileType);
        // when we create preview from Blob fileType=[avi, mov, mp4], when we upload file fileType is normal MIME
        const isVideo = ['video/avi', 'avi', 'video/mp4', 'mp4', 'video/quicktime', 'mov'].includes(fileType);
        const isEmptyImage = !(isImage || isVideo);
        const imagePreview = url && isImage ? url : emptyImage;

        return (
          <Wrapper previewWidth={previewWidth} key={index}>
            {isImage && (
              <div css={styles.thumbnailWrapper}>
                <img css={styles.thumbnail} src={imagePreview} />
              </div>
            )}
            {isVideo && <img css={{ width: '100%' }} src={emptyVideo} />}
            {isEmptyImage && (
              <a download={url} href={url}>
                <img css={{ width: '100%' }} src={imagePreview} />
              </a>
            )}
            {!disabled && <Icomoon css={{ cursor: 'pointer' }} icon="clear" size={8} onClick={() => onDelete(index)} />}
            <p css={styles.title} title={getDecodedUrlFileName(url)}>
              {getDecodedUrlFileName(url)}
            </p>
          </Wrapper>
        );
      })}
    </Container>
  );
};

const Container = styled.div<{ previewWidth?: string }>(({ previewWidth }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  flexWrap: 'wrap',

  '&::after': {
    content: '""',
    display: 'block',
    width: previewWidth || '31.5%',
  },
}));

const Wrapper = styled.div<{ previewWidth?: string }>(({ previewWidth }) => ({
  overflow: 'hidden',
  width: previewWidth || '31.5%',
  marginTop: 12,
  position: 'relative',
}));

const styles = {
  title: css({
    marginTop: 2,
    fontSize: THEME.font.sizes.subordinate,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  thumbnail: css({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    objectFit: 'contain',
  }),
  thumbnailWrapper: css({
    position: 'relative',
    background: THEME.colors.black.main,

    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '56.25%',
    },
  }),
};

export default Preview;
