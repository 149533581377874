import { css } from '@emotion/react';
import * as React from 'react';
import logo from '@src/assets/img/anyCreatorWhite.png';
import { useUuumInfluencerView } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';

const InitialLoading = () => {
  const { isUuumView } = useUuumInfluencerView();

  return (
    <div css={styles.wrapper}>
      {!isUuumView ? <img src={logo} width="185px" alt="" /> : null}
      <span css={styles.text}>Loading...</span>
    </div>
  );
};

const styles = {
  text: css({
    marginTop: 16,
    fontSize: THEME.font.sizes.normal,
    lineHeight: 1.29,
    color: THEME.font.colors.white,
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    background: THEME.colors.black.main,
  }),
};

export default InitialLoading;
