import React from 'react';
import { css } from '@emotion/react';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { switchImage, switchSocialButtonColor, AuthenticationType } from '@src/libs/socialMedia';
import Icomoon from '@src/components/atoms/Icomoon';
import ThemeButton from '@src/components/molecules/ThemeButton';
import { THEME } from '@src/libs/theme';

interface SocialButtonProps {
  className?: string;
  title: string;
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  iconSrc?: string;
  onClick?: () => void;
  socialMedia?: SocialAccountType | AuthenticationType;
  disabled?: boolean;
  hasIcon?: boolean;
  comboButton?: boolean;
  href?: string;
  light?: boolean;
  width?: string;
}

const SocialButton = (props: SocialButtonProps) => {
  const {
    className,
    socialMedia,
    icon,
    iconColor,
    iconSize = '20',
    title,
    onClick,
    disabled,
    hasIcon = true,
    comboButton,
    href,
    light,
    width,
  } = props;
  const background = socialMedia ? switchSocialButtonColor(socialMedia, light) : 'initial';
  const isInstagram = socialMedia === SocialAccountType.INSTAGRAM;
  const bgImage = comboButton
    ? 'linear-gradient(100deg, #4460a0 26%, #ce3396 74%, #ff6641)'
    : isInstagram
    ? light
      ? 'linear-gradient(116.92deg, #F9BB67 22.14%, #DA337E 90.57%)'
      : 'linear-gradient(72deg, #ae3bb0, #ff6641)'
    : 'none';
  const borderColor = bgImage !== 'none' ? bgImage : background;

  return (
    <ThemeButton
      prefixIcon={
        hasIcon ? (
          <Icomoon
            color={iconColor || '#fff'}
            icon={
              icon ||
              switchImage({
                dynamicColor: true,
                socialMedia: socialMedia as SocialAccountType,
                tiktokWhite: socialMedia === SocialAccountType.TIKTOK,
              })
            }
            size={iconSize}
          />
        ) : undefined
      }
      text={title}
      onClick={onClick}
      to={href}
      disabled={disabled}
      width={width}
      css={styles.themeButton(background, bgImage, borderColor)}
      className={className}
    />
  );
};

const styles = {
  themeButton: (background: string, backgroundImage: string, borderColor: string) =>
    css({
      background,
      backgroundImage,
      borderColor,
      color: `${THEME.colors.white} !important`,

      '&:hover': {
        background,
        backgroundImage,
        opacity: 0.9,
      },

      '& > svg': {
        minWidth: 20,
      },

      '& > span': {
        width: 'fill-available',
      },

      '&::after': {
        content: '""',
        display: 'block',
        width: 20,
      },
    }),
};

export default SocialButton;
