import Highcharts, { ColorType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { css } from '@emotion/react';
import { useDirLayout } from '@src/libs/hooks';

interface PieChartProps {
  answerOptions: {
    count: number;
    optionTitle: string;
  }[];
  title: string;
}

interface AnswerOptions {
  data: {
    name: string;
    y: number;
  }[];
  pieColors: ColorType[];
}

const PieChart = ({ answerOptions, title }: PieChartProps) => {
  const { isRtl } = useDirLayout();
  const { data, pieColors } = answerOptions.reduce<AnswerOptions>(
    (prev, curr) => {
      const { count, optionTitle } = curr;
      const baseColor = '#3892e5';

      return {
        data: [...prev.data, { name: optionTitle, y: count }],
        pieColors: [
          ...prev.pieColors,
          Highcharts.color(baseColor)
            .brighten((count * -2 + count * 0.1) / 100)
            .get(),
        ],
      };
    },
    {
      data: [],
      pieColors: [],
    }
  );

  const options = {
    chart: {
      height: 250,
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      rtl: isRtl,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
      },
    },
    series: [
      {
        dataLabels: {
          distance: -50,
          enabled: true,
          format: '{point.percentage:.0f} %',
        },
        colors: pieColors,
        data,
        name: title,
        type: 'pie',
      },
    ],
    title: {
      text: '',
    },
  };

  return (
    <div css={styles.container}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const styles = {
  container: css({
    '& .highcharts-credits': {
      display: 'none',
    },

    '& .highcharts-legend': {
      transform: 'translate(230px, 25px)',
    },
  }),
};

export default PieChart;
