import styled from '@emotion/styled';
import * as React from 'react';
import Slider from 'react-slick';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import Icon from '@src/components/atoms/Icon';
import Image from '@src/components/atoms/Image';
import { THEME } from '@src/libs/theme';
import CarouselArrow from './CarouselArrow';

interface CarouselProps {
  className?: string;
  sources: string[];
  height?: string;
  width?: string;
  afterChangeHandler?: (currentSlide: number) => void;
  currSlideIdx?: number;
}

const SETTINGS = {
  speed: 500,
  infinite: true,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <CarouselArrow
      content={
        <Icon>
          <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={10} />
        </Icon>
      }
    />
  ),
  prevArrow: (
    <CarouselArrow
      content={
        <Icon>
          <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
        </Icon>
      }
    />
  ),
};

const Carousel = (props: CarouselProps) => {
  const { className, sources, height, width, afterChangeHandler, currSlideIdx } = props;
  let sliderMethods: Slider | null;

  React.useEffect(() => {
    if (sliderMethods && currSlideIdx !== undefined) {
      sliderMethods.slickGoTo(currSlideIdx);
    }
  }, [currSlideIdx]);

  return (
    <StyledSlider
      className={className}
      height={height}
      {...SETTINGS}
      afterChange={afterChangeHandler}
      ref={slider => (sliderMethods = slider)}
    >
      {sources.map(source => {
        const extensions = /.jpeg|.jpg|.png|.gif|.zip|.pdf|.ppt|.pptx|.xls|.xlsx|.doc|.docx|.txt/;
        const isImage = extensions.test(source.toLowerCase());

        return (
          <div css={styles.wrapper} key={source}>
            {isImage ? (
              <Image
                src={source}
                width={width || 'auto'}
                height={height || 'auto'}
                alt=""
                css={{ background: '#000', margin: 'auto' }}
              />
            ) : (
              <video
                css={{ margin: 'auto' }}
                src={source}
                loop={true}
                muted={true}
                autoPlay={true}
                playsInline={true}
                controls={true}
                width={width ? `${width}px` : 'auto'}
                height={height ? `${height}px` : 'auto'}
              />
            )}
          </div>
        );
      })}
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)<{ height?: string }>(({ height }) => ({
  width: '100%',

  '& div': {
    outline: 'none !important',
  },

  '& .slick-arrow': {
    width: 24,
    height: 48,
    zIndex: 100,
  },

  '& .slick-arrow.slick-next': {
    right: 0,
  },

  '& .slick-arrow.slick-prev': {
    left: 0,
  },

  '.slick-dots': {
    bottom: 0,
    display: 'block',
    background: THEME.colors.white,
    width: 'fit-content',
    borderRadius: 8,
    height: 16,
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  '& .slick-dots li': {
    margin: 0,
    top: -4,
  },

  '& .slick-arrow::before': {
    fontSize: THEME.font.sizes.title,
    textShadow: '0 0 10px rgba(0, 0, 0, 0.4)',
    opacity: 0.8,
  },

  '& .slick-dots li button::before': {
    color: '#ff2b52',
    fontSize: THEME.font.sizes.hint,
  },

  '& .slick-slide > div > div': {
    ...(height && {
      height,
    }),
  },
}));

const styles = {
  wrapper: css({
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#000',
  }),
};

export default Carousel;
