import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { arrowLeft, arrowRight } from '@src/assets/htmlCodes';
import anyCreatorBlack from '@src/assets/img/anyCreatorBlack.png';
import { CheckBox } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { useDirLayout, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import AccessPermission from './AccessPermission';

interface FacebookInstructionProps {
  backUrl?: string;
  isAuthPage?: boolean;
  onClickEditSettings: () => void;
}

const FacebookInstruction = ({ backUrl, isAuthPage, onClickEditSettings }: FacebookInstructionProps) => {
  const [confirmedNotification, setConfirmedNotification] = useState<boolean>(false);
  const { navigate, t } = useQueryHelper();
  const { isRtl } = useDirLayout();
  const { isMobileView } = usePageLayout();

  return (
    <Container isAuthPageMobileView={!!isAuthPage && isMobileView}>
      <ContentContainer isAuthPageMobileView={!!isAuthPage && isMobileView}>
        {isMobileView && isAuthPage && (
          <div css={styles.logoContainer}>
            <span onClick={() => navigate(backUrl || '')}>{arrowLeft}</span>
            <img alt="logo" src={anyCreatorBlack} width="165" />
          </div>
        )}
        <div css={styles.titleContainer}>
          {!isMobileView && isAuthPage && (
            <span onClick={() => navigate(backUrl || '')}>{isRtl ? arrowRight : arrowLeft}</span>
          )}
          <div>{t(isAuthPage ? 'Title.Please grant permission' : 'pageTitle.Grant permission')}</div>
        </div>
        <div css={styles.description}>
          {t(
            'Annotation.In order to fetch your post to the campaign, AnyCreator needs access to your public information'
          )}
        </div>
        <div css={styles.infoContainer}>
          <div>{t('Annotation.Please make sure that you will turn on all toggles on the access permission page')}</div>
          <div>{t('Annotation.Facebook’s access permission page')}</div>
          <div>
            <AccessPermission />
          </div>
        </div>

        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <CheckBox
            checked={confirmedNotification}
            label="I have confirmed this notification"
            onChange={checked => setConfirmedNotification(checked)}
          />
        </div>
      </ContentContainer>

      <ActionContainer isMobileView={isMobileView}>
        <div>
          <ThemeButton
            disabled={!confirmedNotification}
            text="Edit Settings"
            theme="blue"
            onClick={onClickEditSettings}
          />
          <div>*{t('Annotation.Enable button with checking a checkbox below')}</div>
        </div>
      </ActionContainer>
    </Container>
  );
};

const ActionContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  backgroundColor: THEME.colors.white,
  borderTop: '1px solid #dee5ec',
  display: 'flex',
  padding: 16,
  justifyContent: 'flex-end',

  '& > div': {
    ...(isMobileView && { width: '100%' }),

    '& > button': {
      marginBottom: 4,
      ...(isMobileView && { height: 40 }),
    },

    '& > div': {
      color: THEME.font.colors.gray.main,
      fontSize: 11,
    },
  },
}));

const Container = styled.div<{ isAuthPageMobileView: boolean }>(({ isAuthPageMobileView }) => ({
  backgroundColor: THEME.colors.white,
  borderRadius: 5,
  boxShadow: isAuthPageMobileView ? 'none' : THEME.box.shadows.outer,
}));

const ContentContainer = styled.div<{ isAuthPageMobileView: boolean }>(({ isAuthPageMobileView }) => ({
  padding: isAuthPageMobileView ? '16px 24px' : '16px',
}));

const styles = {
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
  }),
  infoContainer: css({
    border: THEME.box.borders.gray.main,
    borderRadius: 5,
    marginBottom: 32,
    padding: '24px 16px',

    '& > div:nth-of-type(1)': {
      borderBottom: '1px solid #eef3f7',
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 24,
      paddingBottom: 16,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 16,
      textAlign: 'center',
    },

    '& > div:nth-of-type(3)': {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  logoContainer: css({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 32,
    position: 'relative',

    '& > span': {
      color: THEME.font.colors.blue.main,
      fontSize: 22,
      left: 0,
      position: 'absolute',
    },
  }),
  titleContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginBottom: 16,

    '& > span': {
      color: THEME.font.colors.blue.main,
      cursor: 'pointer',
      fontSize: 22,
    },

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
    },
  }),
};

export default FacebookInstruction;
