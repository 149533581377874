import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import DragAndDropToolkit, { DragEndProps } from '@src/components/atoms/DragAndDropToolkit';
import { ThemeButton } from '@src/components/molecules';
import { Label, TextForm } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import BlockCard from './BlockCard';
import { FormInformation, FormType } from './helpers';
import { DragAndDrop } from './StyledComponents';

interface ImageTypeInputProps {
  isDisabled?: boolean;
}

const ImageTypeInput = ({ isDisabled }: ImageTypeInputProps) => {
  const [contentIndex, setContentIndex] = useState<number>(0);
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [formType, lineContent, selectedBlockIndex] = watch(['formType', 'lineContent', 'selectedBlockIndex']);

  const imageTypeContent = lineContent[selectedBlockIndex];
  const maxCards = 10;
  const selectedContent = imageTypeContent.imageMessage?.images[contentIndex];
  const imagesLength = imageTypeContent.imageMessage?.images.length || 0;

  const onChangeImageContent = (val: { genId: string; imageUrl: string; label?: string; url: string }) => {
    const images = [...(imageTypeContent.imageMessage?.images || [])];
    images[contentIndex] = val;

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...imageTypeContent,
      imageMessage: {
        images,
      },
    };
    setValue('lineContent', items);
  };

  const onClickAdd = () => {
    const images = [...(imageTypeContent.imageMessage?.images || [])];
    images.push({
      genId: uuidv4(),
      imageUrl: '',
      label: '',
      url: '',
    });

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...imageTypeContent,
      imageMessage: {
        images,
      },
    };
    setValue('lineContent', items);
  };

  const onClickDelete = (index: number) => {
    const images = [...(imageTypeContent.imageMessage?.images || [])];
    images.splice(index, 1);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...imageTypeContent,
      imageMessage: {
        images,
      },
    };

    setValue('lineContent', items);
  };

  const onDragEnd = ({ destinationIndex, sourceIndex }: DragEndProps) => {
    const images = [...(imageTypeContent.imageMessage?.images || [])];
    const dragCard = images[sourceIndex];
    images.splice(sourceIndex, 1);
    images.splice(destinationIndex, 0, dragCard);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...imageTypeContent,
      imageMessage: {
        images,
      },
    };
    setValue('lineContent', items);
  };

  return (
    <div css={{ padding: 24 }}>
      {formType === FormType.IMAGE_TYPE ? (
        <>
          <DragAndDropToolkit
            items={imageTypeContent.imageMessage?.images || []}
            primaryKey="genId"
            onDragEnd={onDragEnd}
          >
            {({ items }) =>
              items.map((item, index) => {
                const error = !!errors.lineContent && (!item.imageUrl || !item.url);

                return (
                  <BlockCard
                    error={error}
                    id={item.genId}
                    isDeletable={!isDisabled && imagesLength > 1}
                    key={item.genId}
                    onClick={() => setContentIndex(index)}
                    onDelete={() => onClickDelete(index)}
                  />
                );
              })
            }
          </DragAndDropToolkit>

          {!isDisabled && (
            <>
              <ThemeButton
                height="40px"
                text="Add Card"
                prefixIcon={<Icomoon icon="plus" size={12} />}
                width="max-content"
                disabled={imagesLength >= maxCards}
                onClick={onClickAdd}
              />
              <div css={styles.cardCount}>{`${imagesLength} / ${t('Card Maximum', {
                count: maxCards,
              })}`}</div>
            </>
          )}
        </>
      ) : selectedContent ? (
        <>
          <Label css={styles.label} isRequired title="Image" />
          <DragAndDrop
            disabled={isDisabled}
            error={!!errors.lineContent && !selectedContent.imageUrl}
            imageUrl={selectedContent.imageUrl}
            onChange={imageUrl => onChangeImageContent({ ...selectedContent, imageUrl })}
          />
          {errors.lineContent && !selectedContent.imageUrl && <ErrorMessage message={'requiredFieldMessage'} />}

          <div css={{ marginTop: 24 }}>
            <TextForm
              disabled={isDisabled}
              placeholder={t('Placeholder.See more')}
              title="Action Label"
              value={selectedContent.label}
              onChange={e => onChangeImageContent({ ...selectedContent, label: e.target.value })}
            />
          </div>

          <div css={{ marginTop: 16 }}>
            <TextForm
              disabled={isDisabled}
              error={!!errors.lineContent && !selectedContent.url}
              isRequired
              placeholder="http://anymindgroup.com"
              title="URL"
              value={selectedContent.url}
              onChange={e => onChangeImageContent({ ...selectedContent, url: e.target.value })}
            />
            {errors.lineContent && !selectedContent.url && <ErrorMessage message={'requiredFieldMessage'} />}
          </div>
        </>
      ) : null}
    </div>
  );
};

const styles = {
  addCardBtn: css({
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    marginBottom: 4,
    width: 112,
  }),
  cardCount: css({
    color: '#c5d0da',
    fontSize: THEME.font.sizes.subordinate,
    marginTop: 4,
  }),
  label: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  }),
};

export default ImageTypeInput;
