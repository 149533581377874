import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFormResponseByPageQuery, GetFormResponseByPageQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFormResponseByPage.graphql';

export const useGetFormResponseByPageQuery = (
  options?: QueryHookOptions<GetFormResponseByPageQuery, GetFormResponseByPageQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFormResponseByPageQuery, GetFormResponseByPageQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFormResponseByPageLazyQuery = (
  options?: LazyQueryHookOptions<GetFormResponseByPageQuery, GetFormResponseByPageQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFormResponseByPageQuery, GetFormResponseByPageQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
