import React, { forwardRef, Ref } from 'react';
import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

interface AudioPlayerProps {
  className?: string;
  preload?: 'auto' | 'metadata' | 'none';
  src: string;
  onEnded?: () => void;
  onPlay?: () => void;
}

const AudioPlayer = forwardRef((props: AudioPlayerProps, ref: Ref<any>) => <ReactH5AudioPlayer ref={ref} {...props} />);

AudioPlayer.displayName = 'AudioPlayer';

export default AudioPlayer;
