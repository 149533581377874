import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeComparePostListQuery,
  InfluencerYoutubeComparePostListQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerYoutubeComparePostList.graphql';

export const useInfluencerYoutubeComparePostListQuery = (
  options?: QueryHookOptions<InfluencerYoutubeComparePostListQuery, InfluencerYoutubeComparePostListQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerYoutubeComparePostListQuery, InfluencerYoutubeComparePostListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerYoutubeComparePostListLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerYoutubeComparePostListQuery, InfluencerYoutubeComparePostListQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerYoutubeComparePostListQuery, InfluencerYoutubeComparePostListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
