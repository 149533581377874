import React, { forwardRef, ReactElement, Ref } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import CircularProgress from '@src/components/atoms/CircularProgress';
import { mainBlack, mainBlue, mainLightRed, mainWhite, PaletteColorType } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';

export interface ThemeButtonProps extends Omit<LinkProps, 'to'> {
  className?: string;
  customPalette?: PaletteColorType;
  disabled?: boolean;
  height?: string;
  loading?: boolean;
  postfixIcon?: ReactElement | string;
  prefixIcon?: ReactElement | string;
  skipTranslation?: boolean;
  text?: string;
  theme?: 'black' | 'blue' | 'red';
  to?: string;
  type?: 'button' | 'submit';
  width?: string;
  onClick?: (event?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
}

const ThemeButton = forwardRef(
  (
    {
      className,
      customPalette = mainWhite,
      disabled,
      height = '32px',
      loading,
      postfixIcon,
      prefixIcon,
      skipTranslation,
      text,
      theme,
      target,
      to = '',
      type = 'button',
      width = 'fill-available',
      onClick,
    }: ThemeButtonProps,
    ref: Ref<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    const { t } = useTranslation();
    const Component = to ? Link : 'button';
    const isDisabled = disabled || loading;

    const selectedTheme = (() => {
      switch (theme) {
        case 'black':
          return mainBlack;
        case 'blue':
          return mainBlue;
        case 'red':
          return mainLightRed;
        default:
          return undefined;
      }
    })();

    return (
      <Component
        className={className}
        css={[
          styles.button({ disabled: isDisabled, height, width }),
          styles.customPaletteButton(selectedTheme || customPalette),
        ]}
        disabled={isDisabled}
        ref={ref as any}
        target={target}
        to={to}
        type={type}
        onClick={onClick}
      >
        {loading || prefixIcon ? loading ? <CircularProgress size="20px" thickness="2px" /> : prefixIcon : null}
        {text && <span>{skipTranslation ? text : t<string>(`Button.${text}`)}</span>}
        {postfixIcon}
      </Component>
    );
  }
);

const styles = {
  button: ({ disabled, height, width }: { disabled?: boolean; height: string; width?: string }) =>
    css({
      alignItems: 'center',
      borderRadius: THEME.box.borderRadius.xs,
      color: `${THEME.colors.white} ${disabled && '!important'}`,
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      gap: THEME.box.gaps.s,
      height,
      justifyContent: 'center',
      letterSpacing: '.03125rem',
      padding: '0 16px',
      width,
      ...(disabled
        ? {
            background: `${THEME.colors.disabled} !important`,
            pointerEvents: 'none',
          }
        : { cursor: 'pointer' }),
    }),
  customPaletteButton: ({ borderColor, click, normal, textColor }: PaletteColorType) =>
    css({
      '--border': borderColor ? `1px solid ${borderColor}` : 'none',

      border: 'var(--border)',
      background: normal,
      color: textColor,

      '&:hover': {
        border: 'var(--border)',
        background: normal,
        color: textColor,
        opacity: 0.9,
      },

      '&:active': {
        background: click,
        border: 'var(--border)',
        color: textColor,
      },

      '&:focus': {
        outline: `2px solid #3892e51a`,
        border: 'var(--border)',
        background: normal,
        color: textColor,
      },
    }),
};

ThemeButton.displayName = 'ThemeButton';

export default ThemeButton;
