import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { BackNavigator } from '@src/components/molecules';
import SubmitForm from '@src/components/organisms/MyPage/Forms/SubmitForm';
import StatusIndicator from '@src/components/organisms/MyPage/Forms/StatusIndicator';
import { THEME } from '@src/libs/theme';
import { submitFormState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { FormStatus } from '@src/__generated__/globalTypes';

enum Mode {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

const AddPreviewForm = () => {
  const [mode, setMode] = useState<Mode>(Mode.DESKTOP);
  const { navigate, t } = useQueryHelper();
  // if form is previewing before save, the draft information is store into atoms
  // use the draft information instead of DB information
  const { recoilState: submitFormInformation } = useRecoil(submitFormState);
  const { isMobileView } = usePageLayout();
  const isMobileMode = mode === Mode.MOBILE;
  const options = [{ value: Mode.DESKTOP }, { value: Mode.MOBILE }];

  if (!submitFormInformation) {
    navigate(ROUTES.MY_PAGE_FORMS_ADD);

    return null;
  }

  return (
    <div>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Live Preview" to={ROUTES.MY_PAGE_FORMS_ADD} />
            <StatusIndicator status={FormStatus.DRAFT} />
          </div>
          <div>
            <Link css={styles.editBtn} to={ROUTES.MY_PAGE_FORMS_ADD}>
              <Icomoon icon="pentagon-outlined" />
            </Link>
          </div>
        </div>
      )}

      <div css={styles.modeContainer}>
        {options.map(option => {
          const { value } = option;
          const isActive = mode === value;

          return (
            <Option isActive={isActive} key={value} onClick={() => setMode(value)}>
              {t(value)}
            </Option>
          );
        })}
      </div>

      <FormContainer isMobileMode={isMobileMode}>
        <div>
          <SubmitForm
            description={submitFormInformation?.description || ''}
            id={submitFormInformation?.id || ''}
            isMobileMode={isMobileMode}
            questions={submitFormInformation?.questions || []}
            thankDescription={submitFormInformation?.thankDescription || ''}
            thankTitle={submitFormInformation?.thankTitle || ''}
            title={submitFormInformation?.title || ''}
          />
        </div>
      </FormContainer>
    </div>
  );
};

const FormContainer = styled.div<{ isMobileMode: boolean }>(({ isMobileMode }) => ({
  display: 'flex',
  justifyContent: 'center',

  '& > div': {
    width: isMobileMode ? '375px' : '100%',
  },
}));

const Option = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  alignItems: 'center',
  backgroundColor: isActive ? '#27313b' : '#fff',
  boxShadow: THEME.box.shadows.outer,
  color: isActive ? '#fff' : '#27313b',
  cursor: 'pointer',
  display: 'flex',
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 600,
  height: 32,
  justifyContent: 'center',
  textTransform: 'capitalize',
  width: 81,
}));

const styles = {
  divider: css({
    borderLeft: '1px solid #dee5ec',
    height: 32,
    margin: '0 8px',
  }),
  editBtn: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    width: 32,
  }),
  modeContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '16px 0 24px 0',

    '& > div:nth-of-type(1)': {
      borderRadius: '5px 0 0 5px',
    },

    '& > div:nth-of-type(2)': {
      borderRadius: '0 5px 5px 0',
    },
  }),
  navigatorContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px 24px',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      flexBasis: '50%',
      flexWrap: 'wrap',

      '& > div:nth-of-type(2)': {
        marginLeft: 8,
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '50%',
      justifyContent: 'flex-end',
    },
  }),
};

export default AddPreviewForm;
