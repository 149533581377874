import React, { forwardRef, ChangeEvent, ReactElement, Ref } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import { THEME } from '@src/libs/theme';
import Label from '../Label';

export interface SearchFormProps {
  appendIcon?: ReactElement;
  autoFocus?: boolean;
  className?: string;
  help?: string;
  inputStyle?: SerializedStyles;
  isRequired?: boolean;
  placeholder?: string | null;
  title?: string | null;
  value?: string;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onEnterKeyPress?: () => void;
}

const SearchForm = forwardRef((props: SearchFormProps, ref: Ref<HTMLDivElement>) => {
  const { appendIcon, className, title, isRequired, help, placeholder, inputStyle, onEnterKeyPress, ...inputProps } =
    props;

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!onEnterKeyPress && e.charCode === 13) {
      onEnterKeyPress();
    }
  };

  return (
    <div className={className} ref={ref}>
      {title ? <Label title={title} isRequired={isRequired} help={help} /> : null}
      <div css={styles.input}>
        <Icomoon icon="search" size={15} />
        <input css={inputStyle} placeholder={placeholder || ''} type="text" onKeyPress={onKeyPress} {...inputProps} />
        {appendIcon}
      </div>
    </div>
  );
});

const styles = {
  input: css({
    alignItems: 'center',
    border: THEME.box.borders.gray.main,
    borderRadius: THEME.box.borderRadius.xs,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    gap: THEME.box.gaps.xs,
    height: 30,
    padding: '0 8px',
    width: 'fill-available',

    '& > input': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 400,
      width: 'fill-available',
    },
  }),
};

SearchForm.displayName = 'SearchForm';

export default SearchForm;
