import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation,
  InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/InfluencerSocialAuthReconnectInstagramAccountAnalytics.graphql';

export const useInfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation = (
  options?: MutationHookOptions<
    InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation,
    InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutationVariables
  >
) =>
  useMutation<
    InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation,
    InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutationVariables
  >(MUTATION, options);
