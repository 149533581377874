import { useQueryHelper } from '@src/libs/hooks';
import { useState, useEffect } from 'react';
import { POLL_INTERVAL } from '@src/libs/constant';
import { useGetChatEventsQuery, useGetPersonalUserDetailQuery, useSendMessageMutation } from '@src/graphql/hooks';
import { GetChatEventsQuery } from '@src/__generated__/globalTypes';

type GetChatEvents = NonNullable<GetChatEventsQuery['getChatEvents']>;

export const useChatMessages = () => {
  const { data: dataUserDetails } = useGetPersonalUserDetailQuery();
  const { enqueueSnackbar, t, params } = useQueryHelper();
  const chatId = params.activeChat;
  const [newestId, setNewestId] = useState<string | undefined>(undefined);
  const [oldestId, setOldestId] = useState<string | undefined | null>(undefined);
  const [textareaValue, setTextareaValue] = useState('');
  const [chatEvents, setChatEvents] = useState<GetChatEvents['chatEvents']>([]);
  const { data, loading, fetchMore, startPolling, stopPolling } = useGetChatEventsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: POLL_INTERVAL,
    skip: !chatId,
    variables: {
      input: {
        chatId: chatId as string,
        newerThan: newestId,
      },
    },
    onCompleted: ({ getChatEvents }) => {
      if (getChatEvents?.chatEvents.length) {
        setNewestId(getChatEvents.chatEvents[getChatEvents.chatEvents.length - 1].id);
        setOldestId(getChatEvents.chatEvents[0].id);
        setChatEvents(getChatEvents.chatEvents || []);
      }
    },
  });

  const [sendMessage, sendMessageCtx] = useSendMessageMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setTextareaValue('');
    },
  });

  const handleSendMessages = async () => {
    const { activeChat } = params;
    const trimText = textareaValue.trim();

    if (trimText === '' || !activeChat) {
      return;
    }

    stopPolling();
    try {
      await sendMessage({
        variables: { input: { message: trimText, chatId: activeChat } },
      }).then(() => {
        setChatEvents(prevState =>
          prevState.concat({
            id: '',
            authorName: dataUserDetails?.getPersonalUserDetail?.name || '',
            text: textareaValue,
            timestamp: (+new Date()).toString(),
            __typename: 'BotTextMessageEvent',
          })
        );
      });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      startPolling(POLL_INTERVAL);
    }
  };

  useEffect(() => {
    setNewestId(undefined);
    setOldestId(undefined);
  }, [params.activeChat]);

  const onClickViewMore = async () => {
    if (!oldestId || loading) {
      return;
    }

    const { data: fetchMoreData } = await fetchMore({
      variables: {
        input: {
          chatId: params.activeChat,
          olderThan: oldestId,
        },
      },
    });
    if (fetchMoreData.getChatEvents?.chatEvents.length === 0) {
      // means this is the last message
      setOldestId(null);
    } else {
      setOldestId(fetchMoreData.getChatEvents?.chatEvents[0]?.id);
    }
  };

  return {
    channelUserName: data?.getChatEvents?.userName || '',
    chatEvents,
    loading,
    fetchOlder: onClickViewMore,
    handleSendMessages,
    textareaValue,
    setTextareaValue,
    activeChatId: chatId,
    sendMessageLoading: sendMessageCtx.loading,
  };
};
