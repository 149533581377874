import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { InfluencerInterestsQuery, InfluencerInterestsQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerInterests.graphql';

export const useInfluencerInterestsQuery = (
  options?: QueryHookOptions<InfluencerInterestsQuery, InfluencerInterestsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerInterestsQuery, InfluencerInterestsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerInterestsLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerInterestsQuery, InfluencerInterestsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerInterestsQuery, InfluencerInterestsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
