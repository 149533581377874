import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import sunRays from '@src/assets/img/signup/sunRays.png';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { TextFormV2 } from '@src/components/shared';
import { useResetPasswordAndLoginMutation } from '@src/graphql/hooks';
import { removeToken, setToken } from '@src/libs/auth';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { PasswordResetSchema } from '@src/libs/validation';
import { ROUTES } from '@src/shared/routes';

interface FormValues {
  password: string;
  passwordConfirm: string;
}

const Form = () => {
  const [accessTokens, setAccessTokens] = useState<{
    refreshToken?: string | null;
    resetPasswordLoginToken: string;
  } | null>(null);
  const { setUp } = useAuthSetup();
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, navigate, params, t } = useQueryHelper();
  const { token } = params;
  const [resetPasswordAndLogin] = useResetPasswordAndLoginMutation();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { password: '', passwordConfirm: '' },
    resolver: yupResolver(PasswordResetSchema),
  });

  const onClickContinue = async () => {
    if (accessTokens) {
      const { refreshToken, resetPasswordLoginToken } = accessTokens;
      setToken(resetPasswordLoginToken, refreshToken);
      try {
        await setUp(resetPasswordLoginToken);
        navigate(ROUTES.ROOT);
      } catch (err) {
        removeToken();
        enqueueSnackbar(t(err.message), { variant: 'error' });
      }
    }
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const result = await resetPasswordAndLogin({
        variables: {
          input: {
            password: values.password,
            token,
          },
        },
      });
      if (result.data?.resetPasswordAndLogin) {
        const { refreshToken, token: resetPasswordLoginToken } = result.data.resetPasswordAndLogin;
        setAccessTokens({
          refreshToken,
          resetPasswordLoginToken,
        });
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  return (
    <form css={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {isMobileView ? (
        <div css={styles.mobileTitleContainer}>
          <Link to={ROUTES.ROOT}>
            <Icomoon icon="arrow-down" />
          </Link>
          <div>{t(accessTokens ? 'Title.Sign Up' : 'Title.Confirmation')}</div>
        </div>
      ) : (
        <Link css={styles.backButton} to={ROUTES.ROOT}>
          <div>&#8592;</div>
          <div>{t('Button.Back')}</div>
        </Link>
      )}

      <div css={styles.contentContainer}>
        <Icon isRegistered={!!accessTokens}>
          <div>
            <Icomoon color="#fff" icon={accessTokens ? 'check' : 'key'} size={30} />
          </div>
        </Icon>
        <div css={styles.title}>
          {t(accessTokens ? 'Title.Sign up Completed' : 'Heading.Set a password and sign in')}
        </div>
        <div css={[styles.description, { marginBottom: isMobileView ? 24 : accessTokens ? 32 : 16 }]}>
          {t(
            accessTokens
              ? `Annotation.Thank you for signing up AnyCreator! Now, let's setup specific methods you are using for your promote`
              : 'Annotation.Please set your password'
          )}
        </div>

        {!accessTokens && (
          <>
            <InputContainer css={{ marginBottom: 24 }}>
              <TextForm
                error={!!errors.password}
                isRequired
                title="New Password"
                type="password"
                {...register('password')}
              />
              <ErrorMessage message={errors.password?.message} />
            </InputContainer>
            <InputContainer css={{ marginBottom: 32 }}>
              <TextForm
                error={!!errors.passwordConfirm}
                isRequired
                title="Confirm Password"
                type="password"
                {...register('passwordConfirm')}
              />
              <ErrorMessage message={errors.passwordConfirm?.message} />
            </InputContainer>
          </>
        )}
      </div>

      <StyledThemeButton
        css={!isMobileView && { justifySelf: 'center', width: 456 }}
        customPalette={mainBlack}
        isRegistered={!!accessTokens}
        loading={isSubmitting}
        text={accessTokens ? 'Go and see jobs' : 'Continue'}
        type={accessTokens ? 'button' : 'submit'}
        onClick={accessTokens ? onClickContinue : undefined}
      />
    </form>
  );
};

const Icon = styled.div<{ isRegistered: boolean }>(({ isRegistered }) => ({
  alignItems: 'center',
  backgroundSize: '70px 70px',
  display: 'flex',
  height: 70,
  marginBottom: 8,
  justifyContent: 'center',
  width: 70,
  ...(isRegistered && { backgroundImage: `url(${sunRays})` }),

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    marginBottom: 12,
  },

  '& > div': {
    alignItems: 'center',
    backgroundColor: '#ed6a65',
    borderRadius: '50%',
    display: 'flex',
    height: 48,
    justifyContent: 'center',
    width: 48,
  },
}));

const InputContainer = styled.div({
  width: 'fill-available',
});

const StyledThemeButton = styled(ThemeButton)<{ isRegistered: boolean }>(({ isRegistered }) => ({
  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    borderRadius: 9,
    height: 48,
    marginTop: isRegistered ? 200 : 48,

    '& span': {
      fontSize: THEME.font.sizes.subHeading,
    },
  },
}));

const TextForm = styled(TextFormV2)({
  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    '& > div:nth-of-type(1)': {
      fontSize: THEME.font.sizes.normal,
    },

    '& > div:nth-of-type(2)': {
      borderRadius: 3,
      height: 32,

      '& input': {
        fontSize: THEME.font.sizes.normal,
      },
    },
  },
});

const styles = {
  backButton: css({
    alignItems: 'center',
    color: THEME.font.colors.gray.main,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    gap: THEME.box.gaps.s,

    '& > div:nth-of-type(1)': {
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,

      '[dir="rtl"] &': {
        transform: 'scaleX(-1)',
      },
    },
  }),
  container: css({
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      background: THEME.colors.white,
      borderRadius: 5,
      boxShadow: THEME.box.shadows.outer,
      display: 'grid',
      padding: '24px 24px 94px 24px',
      width: 808,
    },
  }),
  contentContainer: css({
    display: 'grid',
    justifyItems: 'center',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      background: THEME.colors.white,
      borderRadius: 14,
      boxShadow: THEME.box.shadows.outer,
      padding: 16,
    },

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      justifySelf: 'center',
      width: 456,
    },
  }),
  description: css({
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.normal,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  }),
  mobileTitleContainer: css({
    alignItems: 'center',
    color: '#000',
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    justifyContent: 'center',
    marginBottom: 24,
    position: 'relative',

    '& > a': {
      alignItems: 'center',
      display: 'flex',
      height: 24,
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      width: 24,

      '& > svg': {
        transform: 'rotate(90deg)',
      },

      '[dir="rtl"] &': {
        left: 'unset',
        right: 0,

        '& > svg': {
          transform: 'rotate(270deg)',
        },
      },
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 600,
    marginBottom: 8,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      marginBottom: 16,
    },
  }),
};

export default Form;
