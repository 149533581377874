import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdateMarketplacePostTrackingUrlMutation,
  UpdateMarketplacePostTrackingUrlMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdateMarketplacePostTrackingUrl.graphql';

export const useUpdateMarketplacePostTrackingUrlMutation = (
  options?: MutationHookOptions<
    UpdateMarketplacePostTrackingUrlMutation,
    UpdateMarketplacePostTrackingUrlMutationVariables
  >
) =>
  useMutation<UpdateMarketplacePostTrackingUrlMutation, UpdateMarketplacePostTrackingUrlMutationVariables>(
    MUTATION,
    options
  );
