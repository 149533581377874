import React, { useEffect, SyntheticEvent } from 'react';
import { css } from '@emotion/react';
import { BackNavigator } from '@src/components/molecules';
import PromotionMethodConnector from '@src/components/organisms/Settings/SocialConnect/PromotionMethodConnector';
import SocialAccountConnector from '@src/components/organisms/Settings/SocialConnect/SocialAccountConnector';
import { useAnyXCheckConnectedSocialAccountsQuery, useInfluencerPromotionMethodsQuery } from '@src/graphql/hooks';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { toolbarBackState, useRecoil } from '@src/recoilAtoms';
import { CampaignPromotionMethod, SocialAccountType } from '@src/__generated__/globalTypes';
import { generatePath, ROUTES } from '@src/shared/routes';

const SocialConnect = () => {
  const { recoilState: toolbarState, setRecoilState: setToolbarState } = useRecoil(toolbarBackState);
  const { isMobileView } = usePageLayout();
  const { navigate, t } = useQueryHelper();
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.CONNECT);
  const { connectSocialAuth: tiktokReconnectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.TIKTOK_RECONNECT);
  const { data: dataPromotionMethods } = useInfluencerPromotionMethodsQuery();
  const { data: dataSocialConnect } = useAnyXCheckConnectedSocialAccountsQuery();

  const backToPreviousPage = () => {
    const { previousPath } = toolbarState;
    if (previousPath) {
      navigate(previousPath);
      setToolbarState({ ...toolbarState, backMethod: null, previousPath: null });
    }
  };

  useEffect(() => {
    if (toolbarState.previousPath) {
      setToolbarState({
        ...toolbarState,
        backMethod: () => {
          backToPreviousPage();
        },
      });
    }
  }, [toolbarState.previousPath]);

  const onClickConnectSocialAccount = (socialMedia: SocialAccountType) => {
    connectSocialAuth(socialMedia);
  };

  const onClickTiktokReconnect = (socialAccountId: string) => {
    tiktokReconnectSocialAuth(SocialAccountType.TIKTOK, { socialAccountId: Number(socialAccountId) });
  };

  const connectedSocialAccounts = dataSocialConnect?.anyXCheckConnectedSocialAccounts;
  const fbAccounts = [...(connectedSocialAccounts?.facebookPages || []), ...(connectedSocialAccounts?.facebooks || [])];
  const instagramAccounts = connectedSocialAccounts?.instagramAccounts || [];
  const twitterAccounts = connectedSocialAccounts?.twitterAccounts || [];
  const youtubeAccounts = connectedSocialAccounts?.youtubeAccounts || [];
  const tiktokAccounts = connectedSocialAccounts?.tiktokAccounts || [];

  const promotionMethods = dataPromotionMethods?.influencerPromotionMethods;
  const emailNewsLetters = promotionMethods?.emailNewsLetters.map(item => item.description) || [];
  const mobileApps = promotionMethods?.mobileApps.map(item => item.url || '') || [];
  const offlines = promotionMethods?.offlines.map(item => item.description) || [];
  const podCasts = promotionMethods?.podCasts.map(item => item.description) || [];
  const websites = promotionMethods?.websites.map(item => item.description) || [];

  const socialAccountItems = [
    {
      accounts: fbAccounts,
      socialMedia: SocialAccountType.FACEBOOK,
      onClickConnect: () => onClickConnectSocialAccount(SocialAccountType.FACEBOOK),
      onClickReconnect: () => navigate(ROUTES.SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION),
    },
    {
      accounts: instagramAccounts,
      socialMedia: SocialAccountType.INSTAGRAM,
      onClickConnect: () => navigate(ROUTES.SETTINGS_SOCIAL_CONNECT_IG_CONNECT),
      onClickReconnect: () => navigate(ROUTES.SETTINGS_SOCIAL_CONNECT_IG_CONNECT),
    },
    {
      accounts: twitterAccounts,
      socialMedia: SocialAccountType.TWITTER,
      onClickConnect: () => onClickConnectSocialAccount(SocialAccountType.TWITTER),
    },
    {
      accounts: youtubeAccounts,
      socialMedia: SocialAccountType.YOUTUBE,
      onClickConnect: () => onClickConnectSocialAccount(SocialAccountType.YOUTUBE),
    },
    {
      accounts: tiktokAccounts,
      socialMedia: SocialAccountType.TIKTOK,
      onClickConnect: () => onClickConnectSocialAccount(SocialAccountType.TIKTOK),
      onClickReconnect: (socialAccountId: string) => onClickTiktokReconnect(socialAccountId),
    },
  ];

  const promotionMethodItems = [
    {
      items: websites,
      title: 'Website',
      to: generatePath(ROUTES.SETTINGS_SOCIAL_CONNECT_ADD_METHODS, undefined, {
        method: CampaignPromotionMethod.WEBSITE,
      }),
    },
    {
      items: emailNewsLetters,
      title: 'Email / Newsletter',
      to: generatePath(ROUTES.SETTINGS_SOCIAL_CONNECT_ADD_METHODS, undefined, {
        method: CampaignPromotionMethod.EMAIL_NEWSLETTER,
      }),
    },
    {
      items: mobileApps,
      title: 'Mobile App',
      to: generatePath(ROUTES.SETTINGS_SOCIAL_CONNECT_ADD_METHODS, undefined, {
        method: CampaignPromotionMethod.MOBILE_APP,
      }),
    },
    {
      items: podCasts,
      title: 'Podcast',
      to: generatePath(ROUTES.SETTINGS_SOCIAL_CONNECT_ADD_METHODS, undefined, {
        method: CampaignPromotionMethod.PODCAST,
      }),
    },
    {
      items: offlines,
      title: 'Offline / Others',
      to: generatePath(ROUTES.SETTINGS_SOCIAL_CONNECT_ADD_METHODS, undefined, {
        method: CampaignPromotionMethod.OFFLINE,
      }),
    },
  ];

  const onClickNavigate = (e: SyntheticEvent<HTMLAnchorElement>) => {
    if (toolbarState.previousPath) {
      e.preventDefault();
      backToPreviousPage();
    }
  };

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigator title="Social Connect" to={ROUTES.SETTINGS} onClick={onClickNavigate} />}

      <div css={styles.description}>{t('Annotation.Connect your social accounts, websites, etc')}</div>

      {connectedSocialAccounts && (
        <div css={styles.socialMediasContainer}>
          {socialAccountItems.map(({ accounts, socialMedia, onClickConnect, onClickReconnect }, index) => (
            <SocialAccountConnector
              accounts={accounts}
              key={index}
              socialMedia={socialMedia}
              onClickConnect={onClickConnect}
              {...(!!onClickReconnect && { onClickReconnect })}
            />
          ))}

          {promotionMethodItems.map(({ items, title, to }, index) => (
            <PromotionMethodConnector items={items} key={index} title={title} to={to} />
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    margin: '16px 24px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: 16,
    },
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
    marginLeft: 48,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      marginLeft: 0,
    },
  }),
  socialMediasContainer: css({
    justifySelf: 'center',
    maxWidth: 648,
    width: '100%',
  }),
};

export default SocialConnect;
