import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFormTemplateQuery, GetFormTemplateQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFormTemplate.graphql';

export const useGetFormTemplateQuery = (
  options?: QueryHookOptions<GetFormTemplateQuery, GetFormTemplateQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFormTemplateLazyQuery = (
  options?: LazyQueryHookOptions<GetFormTemplateQuery, GetFormTemplateQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
