import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import LanguageMenu, { LanguageMenuChildProps } from '@src/components/organisms/LanguageMenu';
import { LanguageSwitcher } from '@src/components/molecules';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import MenuLink from './MenuLink';

interface TopPageFooterProps {
  className?: string;
}

const TopPageFooter = ({ className }: TopPageFooterProps) => {
  const { t, i18n } = useTranslation();

  return (
    <footer className={className} css={styles.footer}>
      <div css={styles.footerRow}>
        <div css={styles.copyright}>Copyright © AnyMind Group. All right reserved.</div>
        <div css={styles.termsWrapper}>
          <div css={styles.termAnchor}>
            <MenuLinkStyled href={ROUTES.PRIVACY} title={t('Privacy Policy')} />
            <MenuLinkStyled href={ROUTES.TERMS} title={t('Terms Of Service')} />
            <a
              css={styles.anchor}
              href={`https://any-creator.com/${i18n.language}/contact/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Contact')}
            </a>
          </div>
          <LanguageMenu>
            {({ title, visible, handleButtonClick, handleSwitcherClick }: LanguageMenuChildProps) => (
              <>
                <button css={styles.languageButton} onClick={handleButtonClick}>
                  <span>{title}</span>
                  &nbsp;
                  <Icomoon icon="arrow-down" size={10} />
                </button>
                <div css={styles.switcherWrapper}>
                  <LanguageSwitcher visible={visible} onClick={handleSwitcherClick} />
                </div>
              </>
            )}
          </LanguageMenu>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  anchor: css({
    display: 'flex',
    boxSizing: 'border-box',
    padding: '0 14px',
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.subordinate,
    lineHeight: '16px',
    letterSpacing: '0.04em',

    '&:hover': {
      textDecoration: 'underline',
    },
  }),
  copyright: css({
    color: THEME.font.colors.gray.main,
    display: 'flex',
    flexBasis: '40%',
    fontSize: THEME.font.sizes.subordinate,
    lineHeight: '16px',
    letterSpacing: '0.04em',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      flexBasis: '100%',
      justifyContent: 'center',
    },
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 'auto',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      paddingTop: 16,
    },
  }),
  footerRow: css({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '24px 0',
    width: '100%',
  }),
  languageButton: css({
    alignItems: 'center',
    borderLeft: '1px solid #dee5ec',
    borderRadius: 3,
    color: THEME.font.colors.gray.main,
    cursor: 'pointer',
    display: 'flex',
    fontSize: THEME.font.sizes.subordinate,
    gap: 2,
    padding: '0 14px',

    '& i': {
      fontSize: THEME.font.sizes.subHeading,
      marginLeft: 5,
    },

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      alignItems: 'center',
      backgroundColor: THEME.colors.white,
      border: THEME.box.borders.gray.main,
      color: THEME.font.colors.black.main,
      flexBasis: '100%',
      height: 32,
      justifyContent: 'center',
      width: 'auto',
    },
  }),
  switcherWrapper: css({
    '& ul': {
      bottom: 28,
    },
  }),
  termAnchor: css({
    display: 'flex',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      flexBasis: '100%',
      justifyContent: 'center',
      margin: '16px 0',
    },
  }),
  termsWrapper: css({
    display: 'flex',
    flexBasis: '60%',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      flexBasis: '100%',
      justifyContent: 'center',
    },
  }),
};

const MenuLinkStyled = styled(MenuLink)({
  fontSize: THEME.font.sizes.subordinate,
});

export default TopPageFooter;
