import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerInstagramDashboardOverviewQuery,
  InfluencerInstagramDashboardOverviewQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerInstagramDashboardOverview.graphql';

export const useInfluencerInstagramDashboardOverviewQuery = (
  options?: QueryHookOptions<
    InfluencerInstagramDashboardOverviewQuery,
    InfluencerInstagramDashboardOverviewQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerInstagramDashboardOverviewQuery, InfluencerInstagramDashboardOverviewQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};

export const useInfluencerInstagramDashboardOverviewLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerInstagramDashboardOverviewQuery,
    InfluencerInstagramDashboardOverviewQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerInstagramDashboardOverviewQuery, InfluencerInstagramDashboardOverviewQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
