import { format, isValid } from 'date-fns';
import * as React from 'react';
import { CURRENCY_DECIMALS } from '@src/libs/constant';
import { Genders } from '../__generated__/globalTypes';

export const formatNumber = (n: number | null | undefined, digits: number = 2) =>
  n === null || n === undefined
    ? '-'
    : n.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });

export const formatIntNumber = (n: number | null | undefined) => formatNumber(n, 0);

export const formatPercent = (n: number | null | undefined, unit: boolean = true, digits: number = 2) => {
  const num =
    n === null || n === undefined
      ? '-'
      : `${Number(n * 100)
          .toFixed(digits)
          .toLocaleString()}`;
  if (unit && n !== null && n !== undefined) {
    // "+" helps to get rid of ".00"
    return `${+num}%`;
  }

  return num;
};

export const convertBreakLines = (text?: string) =>
  text
    ? text.split('\n').map((item, key) => (
        <React.Fragment key={key}>
          {item}
          <br />
        </React.Fragment>
      ))
    : '';

export const formatDate = (
  date: string | null,
  dateFormat: string | undefined = 'MMM d, y',
  ignoreClientUTC?: boolean
) => {
  if (!date || !isValid(new Date(date))) {
    return '-';
  }
  const parsedDate = ignoreClientUTC ? date.replace('Z', '') : date;

  return format(new Date(parsedDate), dateFormat);
};

export const formatGender = (gender: Genders) => {
  switch (gender) {
    case 'FEMALE':
      return 'Female';
    case 'MALE':
      return 'Male';
    default:
      return 'Others';
  }
};

export const bigIntFormatter = (
  value: number | null | undefined,
  toFixed: number | undefined = 2,
  { k, m }: { k: boolean; m: boolean } | undefined = { k: false, m: true }
): string => {
  if (value === null || value === undefined) {
    return '-';
  }

  const absValue = Math.abs(value);
  if (m && absValue >= 1000000) {
    return `${(value / 1000000).toFixed(2)}m`;
  } else if (k && absValue >= 10000) {
    return `${(value / 1000).toFixed(2)}k`;
  }

  return intlNumberFormat(+value.toFixed(toFixed));
};

export const intlNumberFormat = (num: number | null | undefined) =>
  num === null || num === undefined ? '-' : new Intl.NumberFormat('en-US').format(num);

interface RenderIfMoreThan10PercentType {
  value: number | null;
  unit?: boolean;
  digits?: number;
}
export const renderIfMoreThan20Percent = ({ value, unit, digits }: RenderIfMoreThan10PercentType) =>
  value && value > 0.2 ? formatPercent(value, unit, digits) : '';

export interface SeriesHistoryType {
  count?: number | null;
  date?: any | null;
}
export const formatDateCount = (followersHistory: SeriesHistoryType[], historyData: SeriesHistoryType[]) =>
  followersHistory.reduce<{
    count: number[];
  }>(
    (acc, curr) => ({
      count: [...acc.count, Number(historyData.find(el => el.date === curr.date)?.count || 0)],
    }),
    { count: [] }
  );

export const formatLineChartDateCount = (historyData: SeriesHistoryType[]) =>
  historyData.reduce<{
    count: number[];
    date: string[];
  }>(
    (acc, curr) => ({
      count: [...acc.count, Number(curr.count)],
      date: [...acc.date, curr.date],
    }),
    { count: [], date: [] }
  );

export const analyticsChartNumberFormatter = (value: number): string => {
  const absValue = Math.abs(value);
  if (absValue >= 1000000) {
    return `${(value / 1000000).toFixed(1)}m`;
  }

  return value.toLocaleString();
};

export const formatNumberWithCommas = (
  value: number | null | undefined,
  decimals: number | undefined = 2,
  currency?: string
): string => {
  if (value === null || value === undefined) {
    return '-';
  }

  const currencyDecimals = currency ? CURRENCY_DECIMALS[currency] : decimals;

  const parts = value.toFixed(currencyDecimals).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

export const formatDuration = (seconds?: number | null, isHourMinuteSecond?: boolean) => {
  const duration = new Date((seconds || 0) * 1000).toISOString();

  if (isHourMinuteSecond) {
    return duration.substring(11, 19);
  }

  return duration.substring(14, 19);
};
