import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import emailsEmptyListBg from '@src/assets/img/emails/emptyListBg.png';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';

const EmptyList = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <div>
          <div css={styles.addEmailWrapper}>
            <img src={emailsEmptyListBg} css={styles.emptyChat} />
            <div css={styles.addEmail}>
              <span className="plus">+</span>
              <span>{` ${t('Button.Add')}`}</span>
            </div>
          </div>
        </div>
        <div>{t(`Let's compose Emails`)}</div>
        <div>{t('Create your Emails and reach out to them in an effective way')}</div>
        <div>
          <Link to={ROUTES.MY_PAGE_EMAILS_COMPOSE}>{t('Button.Compose')}</Link>
        </div>
      </div>
    </div>
  );
};

const styles = {
  addEmail: css({
    position: 'absolute',
    padding: '0 9px',
    borderRadius: 16,
    backgroundColor: THEME.colors.blue.main,
    color: THEME.font.colors.white,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '140%',
    bottom: 35,
    left: '50%',
    transform: 'translate(-50%, -16px)',

    span: {
      verticalAlign: 'text-bottom',

      '&.plus': {
        fontSize: THEME.font.sizes.heading,
      },
    },
  }),
  addEmailWrapper: css({
    position: 'relative',
    alignSelf: 'center',
  }),
  container: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: '0 0 3px 3px',
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'center',
    padding: '126px 0',
    margin: '0 24px',

    '& > div': {
      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 24,
      },

      '& > div:nth-of-type(2)': {
        color: '#000',
        display: 'flex',
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        justifyContent: 'center',
        marginBottom: 24,
      },

      '& > div:nth-of-type(3)': {
        color: '#000',
        display: 'flex',
        fontSize: THEME.font.sizes.normal,
        justifyContent: 'center',
        marginBottom: 24,
      },

      '& > div:nth-of-type(4)': {
        display: 'flex',
        justifyContent: 'center',

        '& > a': {
          alignItems: 'center',
          backgroundColor: THEME.colors.blue.main,
          borderRadius: 3,
          color: THEME.font.colors.white,
          display: 'flex',
          fontSize: THEME.font.sizes.subordinate,
          fontWeight: 600,
          height: 32,
          justifyContent: 'center',
          width: 97,
        },
      },
    },
  }),
  emptyChat: css({
    margin: 'auto',
    width: 180,
    height: 180,
  }),
};

export default EmptyList;
