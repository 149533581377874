import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

const PressBusinessToProceed = () => {
  const { t } = useTranslation();
  const items = [
    {
      description: 'Annotation.Perfect for public figures, content producers, artists and influencers',
      icon: 'instagram-creator',
      title: 'Creator',
    },
    {
      description: 'Annotation.Perfect for retailers, local businesses, brands, organizations and service providers',
      icon: 'instagram-shop',
      title: 'Option.Business',
    },
  ];

  return (
    <div css={styles.container}>
      <div css={styles.contentContainer}>
        <div>{t('Annotation.Choose the account type that best suits you')}</div>
        <div>
          {items.map(({ description, icon, title }) => (
            <div key={title}>
              <div>
                <Icomoon color="#000" icon={icon} size={10} />
              </div>
              <div>{t(title)}</div>
              <div>{t(description)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    background: '#fafafa',
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    display: 'flex',
    height: 222,
    justifyContent: 'center',
    width: 368,
  }),
  contentContainer: css({
    background: THEME.colors.white,
    border: '1px solid #dadbdb',
    borderBottom: 'none',
    borderTop: 'none',
    padding: '8px 12px',
    width: 230,

    '& > div:nth-of-type(1)': {
      color: '#262626',
      fontSize: THEME.font.sizes.hint,
      fontWeight: 500,
      marginBottom: 7,
      textAlign: 'center',
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      gap: 7,

      '& > div': {
        border: '1px solid #dbdcdb',
        borderRadius: 5,
        display: 'grid',
        padding: '11px 8px',
        position: 'relative',

        '& > div:nth-of-type(1)': {
          alignItems: 'center',
          border: '1px solid #dfe0df',
          borderRadius: '50%',
          display: 'flex',
          height: 20,
          justifyContent: 'center',
          justifySelf: 'center',
          marginBottom: 5,
          width: 20,
        },

        '& > div:nth-of-type(2)': {
          color: '#4f4f50',
          display: 'flex',
          fontSize: THEME.font.sizes.hint,
          fontWeight: 500,
          justifySelf: 'center',
          marginBottom: 2,
        },

        '& > div:nth-of-type(3)': {
          color: '#acadac',
          display: 'flex',
          fontSize: 6,
          justifySelf: 'center',
          textAlign: 'center',
        },
      },

      '& > div:nth-of-type(1)': {
        '&::before': {
          border: '1px solid #dbdbdb',
          borderRadius: '50%',
          content: '""',
          display: 'block',
          height: 8,
          position: 'absolute',
          right: 8,
          top: 8,
          width: 8,

          '[dir="rtl"] &': {
            left: 8,
            right: 'unset',
          },
        },
      },

      '& > div:nth-of-type(2)': {
        '&::before': {
          border: '3px solid #3892e5',
          borderRadius: '50%',
          content: '""',
          display: 'block',
          height: 5,
          position: 'absolute',
          right: 8,
          top: 8,
          width: 5,

          '[dir="rtl"] &': {
            left: 8,
            right: 'unset',
          },
        },

        '&::after': {
          border: '2px dashed #3892e5',
          content: '""',
          display: 'block',
          height: '100%',
          left: -6,
          padding: 4,
          position: 'absolute',
          top: -6,
          width: 'calc(100%)',
        },
      },
    },
  }),
};

export default PressBusinessToProceed;
