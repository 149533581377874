import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { InfluencerPromotionMethodsQuery } from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerPromotionMethods.graphql';

export const useInfluencerPromotionMethodsQuery = (options?: QueryHookOptions<InfluencerPromotionMethodsQuery>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerPromotionMethodsQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerPromotionMethodsLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerPromotionMethodsQuery>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerPromotionMethodsQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
