import React, { useEffect } from 'react';
import { InitialLoading } from '@src/components/atoms';
import { useAnyXSocialAuthReconnectTtcmMutation } from '@src/graphql/hooks';
import { getConnectSocialAuthState } from '@src/libs/auth';
import { ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';
import { useAuthProviderResponse } from '../hooks';

const TtcmReconnect = () => {
  const { enqueueSnackbar, navigate, t } = useQueryHelper();
  const {
    connectState: { campaignId, isNewUI, socialAccountId },
    resetConnectState,
  } = getConnectSocialAuthState();
  const failedRedirectPath = campaignId
    ? generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID, { id: campaignId })
    : ROUTES.MY_JOBS;
  const { provider, response } = useAuthProviderResponse(failedRedirectPath);
  const [anyXSocialAuthReconnectTtcm] = useAnyXSocialAuthReconnectTtcmMutation();

  const socialAuthReconnect = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      navigate(failedRedirectPath);

      return;
    }

    const variables = {
      input: {
        id: String(socialAccountId),
        response,
      },
    };
    await anyXSocialAuthReconnectTtcm({
      variables,
    })
      .then(result => {
        if (result.data?.anyXSocialAuthReconnectTtcm?.ok) {
          navigate(
            campaignId
              ? `${generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID, { id: campaignId })}?uploadDialog=true`
              : ROUTES.MY_JOBS
          );
        }
      })
      .catch(err => {
        if (err.message === ErrorTypes.VIDEO_PERMISSION_MISSING) {
          navigate(
            generatePath(
              isNewUI
                ? ROUTES.MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION
                : ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION,
              undefined,
              { id: socialAccountId }
            )
          );
        } else {
          enqueueSnackbar(t(err.message), { variant: 'error' });
          navigate(failedRedirectPath);
        }
      });
  };

  useEffect(() => {
    socialAuthReconnect();

    return () => {
      resetConnectState();
    };
  }, []);

  return <InitialLoading />;
};

export default TtcmReconnect;
