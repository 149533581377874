import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { THEME } from '@src/libs/theme';

interface OnlyAvailablePlatformIndicatorProps {
  /* temporary use this same compoent for mobile until design is complete */
  availableForMobile?: boolean;
  to?: string;
}

const OnlyAvailablePlatformIndicator = ({ availableForMobile, to }: OnlyAvailablePlatformIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <span>
          {t(
            availableForMobile
              ? 'Annotation.This feature is only available on mobile'
              : 'Annotation.This feature is only available on PCs'
          )}
        </span>
        {to && <Link to={to}>{t('Anchor.Back')}</Link>}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    alignItems: 'center',
    background: THEME.colors.white,
    borderRadius: 24,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    height: 88,
    justifyContent: 'center',
    width: '100%',

    '& > div': {
      textAlign: 'center',

      '& > span': {
        color: '#000',
        fontSize: THEME.font.sizes.normal,
        marginBottom: 8,
      },

      '& > a': {
        color: THEME.font.colors.blue.main,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
};

export default OnlyAvailablePlatformIndicator;
