import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { removeToken } from '@src/libs/auth';
import { SocialAccountType, UserRoles } from '@src/__generated__/globalTypes';
import { ROUTES } from '@src/shared/routes';

export enum BadgeType {
  NOTIFICATION = 'NOTIFICATION',
}

export enum DynamicToType {
  HELP = 'HELP',
}

export enum HiddenType {
  CRM = 'CRM',
  INTERCOM = 'INTERCOM',
}

export enum MenuTitle {
  ANALYTICS = 'Analytics',
  CASTING = 'Casting',
  MESSAGE = 'Message',
  MY_JOBS = 'My Jobs',
  CONTACT = 'Contact',
  EMAILS = 'Emails',
  FANS = 'Fans',
  FIND_JOBS = 'Find Jobs',
  FORMS = 'Forms',
  HELP = 'Help',
  HOME = 'Home',
  MARKETPLACE = 'Marketplace',
  MY_LINK = 'My Link',
  NOTIFICATION = 'Notification',
  PAYMENT = 'Payment',
  SETTINGS = 'Settings',
  SIGN_OUT = 'Sign Out',
}

export enum SmDisconnectStatusType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
}

export interface Submenus {
  activeIcon: string;
  icon: string;
  disconnectStatusType?: SmDisconnectStatusType;
  hiddenType?: HiddenType[];
  title: string;
  to: string | string[];
  activeSubmenuIcon?: string;
}

export interface MenuItemProps {
  activeIcon?: string;
  badge?: BadgeType;
  dynamicTo?: DynamicToType;
  icon?: string;
  groupTitle?: string;
  hiddenType?: HiddenType[];
  isDivider?: boolean;
  isNewTab?: boolean;
  rolesToAccess?: UserRoles[];
  submenus?: Submenus[];
  title?: MenuTitle;
  to?: string;
  onClick?: () => void;
}

const onClickSignOut = () => {
  removeToken();
  sendAmplitudeEvent(eventTypes.signOut);
  window.location.replace(ROUTES.ROOT);
};

export const menuWidth = 240;

export const uuumMenuItems: MenuItemProps[] = [
  { groupTitle: 'Social media' },
  {
    activeIcon: 'thunder-move-blue-light',
    icon: 'thunder-move-grey-light',
    rolesToAccess: [UserRoles.INFLUENCER, UserRoles.CREATOR_STAFF],
    submenus: [
      {
        activeIcon: 'youtube',
        icon: 'youtube',
        activeSubmenuIcon: 'youtube-color',
        disconnectStatusType: SmDisconnectStatusType.YOUTUBE,
        title: 'Youtube',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.YOUTUBE}`,
      },
      {
        activeIcon: 'instagram',
        icon: 'instagram',
        activeSubmenuIcon: 'instagram-color-light',
        disconnectStatusType: SmDisconnectStatusType.INSTAGRAM,
        title: 'Instagram',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.INSTAGRAM}`,
      },
      {
        activeIcon: 'facebook-round-color',
        icon: 'facebook-round',
        activeSubmenuIcon: 'facebook-round-color',
        disconnectStatusType: SmDisconnectStatusType.FACEBOOK,
        title: 'Facebook',
        to: [
          `${ROUTES.ANALYTICS}?sm=${SocialAccountType.FACEBOOK}`,
          `${ROUTES.ANALYTICS}?sm=${SocialAccountType.FACEBOOK_PAGE}`,
        ],
      },
      {
        activeIcon: 'x-twitter',
        icon: 'x-twitter',
        activeSubmenuIcon: 'x-twitter-color',
        disconnectStatusType: SmDisconnectStatusType.TWITTER,
        title: 'Twitter',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.TWITTER}`,
      },
      {
        activeIcon: 'tiktok',
        icon: 'tiktok',
        activeSubmenuIcon: 'tiktok-color',
        disconnectStatusType: SmDisconnectStatusType.TIKTOK,
        title: 'TikTok',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.TIKTOK}`,
      },
    ],
    title: MenuTitle.ANALYTICS,
    to: ROUTES.ANALYTICS,
  },
  { groupTitle: 'Account' },
  {
    activeIcon: 'contact',
    icon: 'contact',
    isDivider: true,
    title: MenuTitle.SETTINGS,
    to: ROUTES.SETTINGS,
  },
  { icon: 'exit-grey-light', title: MenuTitle.SIGN_OUT, onClick: onClickSignOut },
];

export const checkIsActivePathname = (path: string, checkIsInitialPage?: boolean) => {
  // split url query string into array
  const params = window.location.search.split(/[?&]+/);

  // to split query string from path and form into array
  const pathParams = path.split('?')[1] ? path.split('?')[1].split(/[?&]+/) : [''];
  const pathWithoutParams = path.split('?')[0];
  // for YT compare URl will look like "analytics:compare?sm=YOUTUBE"
  const pathWithoutCompare = window.location.pathname.split(':')[0];

  return checkIsInitialPage
    ? pathWithoutParams === pathWithoutCompare
    : pathWithoutCompare.includes(pathWithoutParams) && pathParams.some(param => params.includes(param));
};
