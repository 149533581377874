import styled from '@emotion/styled';
import * as React from 'react';
import { THEME } from '@src/libs/theme';
import LanguageButton, { LanguageButtonProps } from './LanguageButton';

export interface LanguageSwitcherProps {
  className?: string;
  visible: boolean;
  languages: LanguageButtonProps[];
}

// Component
const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const { className, visible, languages } = props;

  return (
    <List className={className} visible={visible}>
      {languages.map(language => (
        <li key={language.title}>
          <LanguageButton title={language.title} onClick={language.onClick} />
        </li>
      ))}
    </List>
  );
};

const List = styled.ul<{ visible: boolean }>(({ visible }) => ({
  width: 140,
  position: 'absolute',
  zIndex: 999,
  background: THEME.colors.white,
  border: '1px solid #efefef',
  borderRadius: 3,
  boxShadow: THEME.box.shadows.outer,

  /* Fade In */
  display: visible ? 'block' : 'none',
  opacity: visible ? '1' : '0',
  animationName: 'fadeIn',
  animationDuration: '0.3s',
  animationTimingFunction: 'ease-out',
  fontSize: THEME.font.sizes.normal,

  '@keyframes fadeIn': {
    '0%': {
      display: 'none',
      opacity: 0,
    },

    '1%': {
      display: 'block',
      opacity: 0,
    },

    '100%': {
      display: 'block',
      opacity: 1,
    },
  },

  '@-webkit-keyframes fadeIn': {
    '0%': {
      display: 'none',
      opacity: 0,
    },

    '1%': {
      display: 'block',
      opacity: 0,
    },

    '100%': {
      display: 'block',
      opacity: 1,
    },
  },
}));

export default LanguageSwitcher;
