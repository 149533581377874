export interface PaletteColorType {
  normal: string;
  hover: string;
  click: string;
  disable: string;
  hoverBorder?: string;
  textColor?: string;
  hoverFilter?: string;
  borderColor?: string;
}

const baseColor = {
  disable: '#dee5ec',
  textColor: '#fff',
};

export const mainRed: PaletteColorType = {
  ...baseColor,
  normal: '#ff2b52',
  hover: '#ff5575',
  click: '#cc2241',
};

export const mainLightRed: PaletteColorType = {
  ...baseColor,
  normal: '#ff5f5f',
  hover: '#ff7875',
  click: '#ff4d4f',
};

export const mainMagenta: PaletteColorType = {
  ...baseColor,
  normal: '#ec4d74',
  hover: '#ef6081',
  click: '#ee3965',
};

export const mainWhite: PaletteColorType = {
  ...baseColor,
  normal: '#fff',
  hover: '#fff',
  hoverBorder: '#c5d0da',
  click: '#f6f8fa',
  textColor: '#6e7c89',
  borderColor: '#dee5ec',
};

export const mainOrange: PaletteColorType = {
  ...baseColor,
  normal: '#ffb63d',
  hover: '#ffc463',
  click: '#cc9130',
};

export const mainBlue: PaletteColorType = {
  ...baseColor,
  normal: '#3892e5',
  hover: '#5fa7ea',
  click: '#2c74b7',
};

export const mainDarkBlue: PaletteColorType = {
  ...baseColor,
  normal: '#4460a0',
  hover: '#5161a3',
  click: '#1d2d70',
};

export const linerGradientIG: PaletteColorType = {
  ...baseColor,
  normal: 'linear-gradient(160deg, #4460a0 21.6%, #ce3396 55.31%, #FF6641)',
  hover: 'linear-gradient(160deg, #4460a0 11.6%, #ce3396 45.31%, #FF6641 90%)',
  click: 'linear-gradient(160deg, #4460a0 1.6%, #ce3396 35.31%, #FF6641 80%)',
};

export const mainBlack: PaletteColorType = {
  ...baseColor,
  normal: '#27313B',
  hover: '#525A62',
  click: '#1F272F',
  disable: '#DEE5EC',
};
