import { memo, useEffect, useRef, useState, ReactNode } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ portalId, children }: { portalId: string; children: ReactNode }) => {
  const el = useRef(document.getElementById(portalId) || document.createElement('div'));
  const [needNewParentNode] = useState(!el.current.parentElement);

  useEffect(() => {
    // check if there is no current parent on portal element and if no -> create new DOM Node
    if (needNewParentNode) {
      el.current.id = portalId;
      document.body.appendChild(el.current);
    }

    return () => {
      if (needNewParentNode && el.current.parentElement) {
        el.current.parentElement.removeChild(el.current);
      }
    };
  }, [portalId]);

  return createPortal(children, el.current);
};

export default memo(Portal);
