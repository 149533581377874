import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Textarea } from '@src/components/atoms';
import { TextForm } from '@src/components/shared';
import { useGetFormReportSummaryQuery } from '@src/graphql/hooks';
import { useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { QuestionType } from '@src/__generated__/globalTypes';
import BarChart from './BarChart';
import PieChart from './PieChart';

const FormSummary = () => {
  const { params, t } = useQueryHelper();

  const { data } = useGetFormReportSummaryQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: Number(params.id),
      },
    },
  });

  const answers = data?.getFormReportSummary?.answers || [];

  return (
    <div css={styles.container}>
      {answers.map(answer => {
        const { answerOptions, answerTexts, numberResponses, questionId, questionTitle, questionType } = answer;

        return questionType === QuestionType.CHECKBOX ? (
          <QuestionContainer key={questionId}>
            <div>{questionTitle}</div>
            <div>{t('response', { count: numberResponses })}</div>
            <div css={styles.barChartContainer}>
              <BarChart answerOptions={answerOptions} title={questionTitle} />
            </div>
          </QuestionContainer>
        ) : questionType === QuestionType.DROPDOWN ? (
          <QuestionContainer key={questionId}>
            <div>{questionTitle}</div>
            <div>{t('response', { count: numberResponses })}</div>
            <div css={styles.pieChartContainer}>
              <div>
                <PieChart answerOptions={answerOptions} title={questionTitle} />
              </div>
            </div>
          </QuestionContainer>
        ) : questionType === QuestionType.SHORT_ANSWER ? (
          <QuestionContainer key={questionId}>
            <div>{questionTitle}</div>
            <div>{t('response', { count: numberResponses })}</div>
            {answerTexts.map((text, index) => {
              const isLongerText = text.length >= 85;

              return isLongerText ? (
                <Textarea css={{ height: 102 }} disabled key={index} placeholder="" value={text} />
              ) : (
                <TextForm css={styles.textForm} disabled key={index} placeholder="" value={text} />
              );
            })}
          </QuestionContainer>
        ) : null;
      })}
    </div>
  );
};

const QuestionContainer = styled.div({
  borderBottom: '1px solid #dde5eb',
  padding: '24px 0',
  width: '100%',

  '& > div:nth-of-type(1)': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
    marginBottom: 8,
  },

  '& > div:nth-of-type(2)': {
    color: THEME.font.colors.black.main,
    fontWeight: 12,
    marginBottom: 16,
  },

  '& > textarea:not(:last-child)': {
    marginBottom: 8,
  },

  [`@media (max-width: ${ViewportType.SMALL}px)`]: {
    maxWidth: '80vw',
  },
});

const styles = {
  barChartContainer: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  container: css({
    marginTop: -24,
  }),
  pieChartContainer: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    '& > div': {
      width: 350,
    },
  }),
  textForm: css({
    marginBottom: 8,

    '& > label': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },

    '& input': {
      borderRadius: 3,
      height: 32,
    },
  }),
};

export default FormSummary;
