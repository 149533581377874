import React, { useEffect } from 'react';
import { InitialLoading } from '@src/components/atoms';
import { ROUTES } from '@src/shared/routes';
import { useAuthProviderResponse } from '../hooks';
import { deepLinkUrls } from './helpers';

const MobileSocialAuth = () => {
  const { provider, response } = useAuthProviderResponse(ROUTES.SETTINGS_SOCIAL_CONNECT);

  useEffect(() => {
    const deepLinkUrl = deepLinkUrls[provider as keyof typeof deepLinkUrls];
    if (deepLinkUrl && response) {
      window.location.href = `${deepLinkUrl}?${response}`;
    }
  }, []);

  return <InitialLoading />;
};
export default MobileSocialAuth;
