import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeCompareTagRankingQuery,
  InfluencerYoutubeCompareTagRankingQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerYoutubeCompareTagRanking.graphql';

export const useInfluencerYoutubeCompareTagRankingQuery = (
  options?: QueryHookOptions<InfluencerYoutubeCompareTagRankingQuery, InfluencerYoutubeCompareTagRankingQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerYoutubeCompareTagRankingQuery, InfluencerYoutubeCompareTagRankingQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerYoutubeCompareTagRankingLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerYoutubeCompareTagRankingQuery,
    InfluencerYoutubeCompareTagRankingQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerYoutubeCompareTagRankingQuery, InfluencerYoutubeCompareTagRankingQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
