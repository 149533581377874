import React, { useEffect, useState, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import CheckBox from '@src/components/atoms/CheckBox';
import Icomoon from '@src/components/atoms/Icomoon';
import ThemeButton from '@src/components/molecules/ThemeButton';
import useClickOutside from '@src/libs/hooks/useClickOutside';
import { mainBlue } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import Label from '../Label';

export type ConditionalProps =
  | {
      multiple: boolean;
      value: string[];
      onChange?: (val: string[]) => void;
    }
  | {
      multiple?: never;
      value: string;
      onChange?: (val: string) => void;
    };

export interface SelectProps {
  className?: string;
  disableCheckAll?: boolean;
  disableClear?: boolean;
  disabled?: boolean;
  disableSearch?: boolean;
  error?: boolean;
  help?: string;
  isRequired?: boolean;
  options: {
    icon?: ReactNode;
    label: string;
    prefixIcon?: ReactNode;
    value: string;
  }[];
  placeholder?: string;
  title?: string;
}

const Select = ({
  className,
  disableCheckAll,
  disableClear,
  disabled,
  disableSearch,
  error,
  help,
  isRequired,
  multiple,
  options,
  placeholder,
  title,
  value,
  onChange,
  ...inputProps
}: ConditionalProps & SelectProps) => {
  const { t } = useTranslation();
  const selectedValue =
    multiple && value.length > 2
      ? t('Selected', { count: value.length })
      : options
          .filter(({ value: optionVal }) => (multiple ? value.includes(optionVal) : value === optionVal))
          .map(({ label }) => label)
          .join(', ');
  const [input, setInput] = useState<string>(!multiple ? selectedValue : '');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const { clickedOutside, ref } = useClickOutside();
  const emptyOptionsDisabled = disabled || !options.length;
  const enableClear = !disableClear && !isMenuOpen && !multiple && selectedValue;
  const isCheckedAll = multiple && value.length === options.length;
  const searchItems = options.filter(({ label }) =>
    isSearching ? label.toLowerCase().includes(input.toLowerCase()) : true
  );
  const selectedItem = !multiple ? options.find(option => option.value === value) : undefined;

  useEffect(() => {
    if (clickedOutside) {
      setIsMenuOpen(false);
      setIsSearching(false);
    }
  }, [clickedOutside]);

  useEffect(() => {
    setInput(currValue => (multiple ? (isMenuOpen ? currValue : '') : selectedValue));
  }, [isMenuOpen, multiple, selectedValue]);

  const onChangeInput = (val: string) => {
    setInput(val);
    setIsSearching(true);
  };

  const onClickCheckedAll = () => {
    if (multiple) {
      if (isCheckedAll) {
        onChange?.([]);
      } else {
        onChange?.(options.map(option => option.value));
      }
    }
  };

  const onClickCheckItem = (val: string) => {
    if (multiple) {
      const items = [...value];
      const index = items.findIndex(item => item === val);
      if (index >= 0) {
        items.splice(index, 1);
      } else {
        items.push(val);
      }
      onChange?.(items);
    }
  };

  const onClickClear = (e: MouseEvent<SVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange?.((multiple ? [] : '') as string & string[]);
  };

  const onClickItem = (val: string) => {
    setIsMenuOpen(false);
    onChange?.(val as string & string[]);
  };

  const onKeyDownInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && multiple && value.length && !input) {
      const items = [...value];
      onChange?.(items.slice(0, -1));
    }

    if (['Enter', 'Tab'].includes(e.key)) {
      if (e.key === 'Enter') {
        e.preventDefault();
      }

      const matched = options.find(({ label, value: optionVal }) =>
        multiple ? !value.includes(optionVal) && label === input : label === input
      );
      if (matched) {
        if (multiple) {
          const items = [...value];
          items.push(matched.value);
          onChange?.(items);
          setInput('');
        } else {
          onChange?.(matched.value as string & string[]);
          setIsMenuOpen(false);
        }
      } else {
        onChange?.((multiple ? [...value] : value) as string & string[]);
        setIsMenuOpen(false);
      }
    }
  };

  return (
    <div className={className} css={{ width: '100%' }}>
      {title && <Label help={help} isRequired={isRequired} title={`Selector.${title}`} />}
      <div css={{ position: 'relative', ...(emptyOptionsDisabled && { cursor: 'not-allowed' }) }} ref={ref}>
        <InputContainer
          className="select-input-container"
          disabled={!!emptyOptionsDisabled}
          isEmptyItems={!searchItems.length}
          isError={!!error}
          isMenuOpen={isMenuOpen}
          onClick={() => setIsMenuOpen(true)}
        >
          {multiple && <label css={styles.selectedValue}>{selectedValue}</label>}
          {selectedItem?.prefixIcon}
          {selectedItem?.icon}
          <input
            css={disableSearch && { pointerEvents: 'none' }}
            placeholder={multiple && selectedValue ? '' : (t(`Selector.${placeholder || 'Please Select'}`) as string)}
            value={input}
            onChange={e => onChangeInput(e.target.value)}
            onClick={() => setIsMenuOpen(true)}
            onKeyDown={onKeyDownInput}
            {...inputProps}
          />
          {!emptyOptionsDisabled && (
            <Icomoon
              icon={enableClear ? 'clear-filled' : 'arrow-down'}
              size={enableClear ? 15 : 10}
              {...(enableClear && { color: '#c5d0da', onClick: e => onClickClear(e) })}
            />
          )}
        </InputContainer>
        {isMenuOpen && (
          <>
            {!!searchItems.length && (
              <MenuContainer className="select-menu-container" isError={!!error}>
                {!disableCheckAll && multiple && (
                  <div className="select-checked-all-container" css={styles.checkedAllContainer}>
                    <CheckBox
                      checked={!!value.length}
                      indeterminate={!isCheckedAll && !!value.length}
                      label="Select all"
                      onChange={onClickCheckedAll}
                    />
                  </div>
                )}
                <div className="select-list-container" css={styles.listContainer}>
                  {searchItems.map(({ icon, label, value: optionVal }, index) => {
                    const checked = multiple && value.includes(optionVal);

                    return (
                      <div
                        css={multiple && { cursor: 'default !important' }}
                        key={index}
                        {...(!multiple && { onClick: () => onClickItem(optionVal) })}
                      >
                        {multiple && <CheckBox checked={!!checked} onChange={() => onClickCheckItem(optionVal)} />}
                        {icon}
                        <label>{label}</label>
                      </div>
                    );
                  })}
                </div>
                {multiple && (
                  <div className="select-action-container" css={styles.actionContainer}>
                    <ThemeButton customPalette={mainBlue} text="Apply" onClick={() => setIsMenuOpen(false)} />
                  </div>
                )}
              </MenuContainer>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const InputContainer = styled.div<{ disabled: boolean; isEmptyItems: boolean; isError: boolean; isMenuOpen: boolean }>(
  ({ disabled, isEmptyItems, isError, isMenuOpen }) => ({
    alignItems: 'center',
    background: THEME.colors.white,
    border: `1px solid ${isError ? 'tomato' : isMenuOpen ? '#179cd7' : '#dee5ec'}`,
    borderBottomColor: isEmptyItems
      ? isError
        ? 'tomato'
        : isMenuOpen
        ? '#179cd7'
        : '#dee5ec'
      : !isMenuOpen && isError
      ? 'tomato'
      : '#dee5ec',
    borderRadius: 3,
    display: 'flex',
    gap: THEME.box.gaps.s,
    height: 30,
    padding: '0 12px',
    ...(disabled && { background: '#f2f2f2', pointerEvents: 'none' }),

    '& input': {
      color: '#545454',
      flex: '1 1 0%',
      fontSize: THEME.font.sizes.normal,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '100%',
    },

    '& > svg': {
      cursor: 'pointer',
    },
  })
);

const MenuContainer = styled.div<{ isError: boolean }>(({ isError }) => ({
  background: THEME.colors.white,
  border: `1px solid ${isError ? 'tomato' : '#179cd7'}`,
  borderRadius: 3,
  borderTopColor: '#dee5ec',
  overflow: 'hidden',
  position: 'absolute',
  width: 'fill-available',
  zIndex: 4,
}));

const styles = {
  actionContainer: css({
    borderTop: '1px solid #dee5ec',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 10,

    '& > button': {
      width: 'fit-content',
    },
  }),
  checkedAllContainer: css({
    borderBottom: '1px solid #dee5ec',
    padding: 10,

    '& > div': {
      display: 'flex',
      gap: THEME.box.gaps.s,

      '& label': {
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  listContainer: css({
    maxHeight: 300,
    overflowY: 'auto',
    overflowX: 'hidden',

    '& > div': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: 10,
      wordBreak: 'break-word',

      '&:hover': {
        background: '#e1f0f9',
      },

      '& > label': {
        flex: 1,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  selectedValue: css({
    fontSize: THEME.font.sizes.normal,
    maxWidth: 'fill-available',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  }),
};

export default Select;
