import isEmpty from 'lodash/isEmpty';
import { useInfluencerProfileV2Query } from '@src/graphql/hooks';
import { useAuthData } from '@src/libs/hooks';

const useInfluencerProfile = () => {
  const { userId } = useAuthData();

  const { data, loading } = useInfluencerProfileV2Query({
    skip: !userId,
    variables: { pk: userId as number },
  });

  return {
    hasFBAccount:
      !isEmpty(data?.influencerProfileV2?.facebookAccount) || !isEmpty(data?.influencerProfileV2?.facebookPages),
    hasIGAccount: !isEmpty(data?.influencerProfileV2?.instagramAccounts),
    hasTWAccount: !isEmpty(data?.influencerProfileV2?.twitterAccounts),
    hasYTAccount: !isEmpty(data?.influencerProfileV2?.youtubeAccounts),
    hasTKAccount: !isEmpty(data?.influencerProfileV2?.tiktokAccounts),
    data,
    loading,
  };
};

export default useInfluencerProfile;
