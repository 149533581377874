import React, { useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate } from 'react-router-dom';
import { sendAmplitudeEvent } from '@src/amplitude';
import { CrowdinWidget } from '@src/components/molecules';
import Layout, { LayoutProps, DYNAMIC_TITLE } from '@src/components/organisms/LayoutV2';
import { useIsInfluencerUuumQuery } from '@src/graphql/hooks';
import { checkSignedIn } from '@src/libs/auth';
import { useDocumentTitle, useGlobalLayout } from '@src/libs/hooks';
import { useGaTracker } from '@src/libs/withTracker';
import { dynamicTitleState, redirectState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';

export { DYNAMIC_TITLE };

type AppRouteProps = LayoutProps & {
  amplitudeEvent?: string;
  element: ReactElement;
  documentTitle?: string;
};

const AppRoute = ({ amplitudeEvent, documentTitle, element, ...restProps }: AppRouteProps) => {
  const { toolbarProps } = restProps;
  const { setGlobalLayout } = useGlobalLayout();
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const isSignedIn = checkSignedIn();
  const {
    recoilState: { title: dynamicTitle, interpolation },
  } = useRecoil(dynamicTitleState);
  const { setRecoilState: setRedirectState } = useRecoil(redirectState);
  const isDynamicTitle = documentTitle ? documentTitle === DYNAMIC_TITLE : toolbarProps?.title === DYNAMIC_TITLE;
  const updatedTitle = isDynamicTitle
    ? t(dynamicTitle, { ...interpolation })
    : t(documentTitle || toolbarProps?.title ? `Title.${documentTitle || toolbarProps?.title}` : 'AnyCreator');

  useGaTracker(isSignedIn);
  useDocumentTitle({ title: updatedTitle });

  useIsInfluencerUuumQuery({
    skip: !isSignedIn,
    onCompleted: ({ isInfluencerUuum }) => {
      const isUuum = !!isInfluencerUuum?.isUuum;
      setGlobalLayout({ isUuum });
      if (isUuum) {
        i18n.changeLanguage('ja');
      }
    },
  });

  useEffect(() => {
    if (amplitudeEvent) {
      sendAmplitudeEvent(amplitudeEvent);
    }
  }, [amplitudeEvent]);

  if (!isSignedIn) {
    setRedirectState({ accessUrlBeforeSignIn: location.pathname });

    return <Navigate state={{ state: { from: location } }} to={ROUTES.ROOT} />;
  }

  return (
    <Layout {...restProps}>
      {element}
      <CrowdinWidget />
    </Layout>
  );
};

export default AppRoute;
