import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdatePersonalUserDetailMutation,
  UpdatePersonalUserDetailMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdatePersonalUserDetail.graphql';

export const useUpdatePersonalUserDetailMutation = (
  options?: MutationHookOptions<UpdatePersonalUserDetailMutation, UpdatePersonalUserDetailMutationVariables>
) => useMutation<UpdatePersonalUserDetailMutation, UpdatePersonalUserDetailMutationVariables>(MUTATION, options);
