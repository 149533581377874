import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const highchartOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
};

export enum MapConstructor {
  CHART = 'chart',
  MAP_CHART = 'mapChart',
}

interface HighchartsType {
  /* Chart configuration object */
  options: Highcharts.Options;
  /* Constructor method type. Default is `chart` */
  constructorType?: MapConstructor;
}

const HighChart = ({ options, constructorType = MapConstructor.CHART }: HighchartsType) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{ ...highchartOptions, ...options }}
    constructorType={constructorType}
    allowChartUpdate
    oneToOne
  />
);

export { Highcharts };
export default HighChart;
