import { css } from '@emotion/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface EmptyProps {
  className?: string;
  title?: string;
  content?: string;
}
const Empty = ({ className, content, title }: EmptyProps) => {
  const { t } = useTranslation();

  return (
    <div className={className} css={styles.wrapper}>
      <div>
        <h6 css={styles.title}>{t(title || 'General.Nothing has been found')}</h6>
        <p css={styles.content}>{t(content || 'General.Please try a different query')}</p>
      </div>
    </div>
  );
};

const styles = {
  content: css({
    marginTop: 8,
    color: '#757575',
    fontSize: THEME.font.sizes.normal,
  }),
  title: css({
    color: '#757575',
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 500,
    letterSpacing: '0.02em',
  }),
  wrapper: css({
    alignItems: 'center',
    textAlign: 'center',
    display: 'grid',
    flexDirection: 'column',
    flex: 1,
    height: 446,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 223,
      padding: 30,
    },
  }),
};

export default Empty;
