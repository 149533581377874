import React from 'react';
import styled from '@emotion/styled';
import Icomoon from '@src/components/atoms/Icomoon';
import { THEME } from '@src/libs/theme';

interface SliderArrowProps {
  type: 'next' | 'prev';
  onClick: () => void;
  hasBgImage?: boolean;
}

const SliderArrow = (props: SliderArrowProps) => {
  const { type, hasBgImage = true, ...rest } = props;

  return (
    <SliderNav type={type} {...rest}>
      <Shadow type={type} hasBgImage={hasBgImage} />
      <Icomoon css={{ transform: `rotate(${type === 'next' ? '270deg' : '90deg'})` }} icon="arrow-down" size={10} />
    </SliderNav>
  );
};

const Shadow = styled.div<{ type: SliderArrowProps['type']; hasBgImage?: boolean }>(({ hasBgImage, type }) => ({
  position: 'absolute',
  height: 'calc(100% + 2px)',
  width: 36,
  top: -1,
  ...(hasBgImage
    ? {
        left: 29,
        backgroundImage:
          type === 'next'
            ? 'linear-gradient(to right, rgba(255, 255, 255, 0), #f9fbfc)'
            : 'linear-gradient(to left, rgba(255, 255, 255, 0), #f9fbfc)',
      }
    : {}),
}));

const SliderNav = styled.div<SliderArrowProps>(({ type }) => ({
  zIndex: 1,
  width: 28,
  height: 'calc(100% - 2px)',
  position: 'absolute',
  top: 0,
  background: THEME.colors.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: THEME.font.sizes.subHeading,
  color: THEME.font.colors.gray.main,
  border: THEME.box.borders.gray.main,
  ...(type === 'next' ? { right: 0 } : type === 'prev' ? { left: 0 } : {}),

  '&:hover': {
    cursor: 'pointer',
  },
}));

export default SliderArrow;
