import { AccordionTrigger, Root, Item, Header, Content } from '@radix-ui/react-accordion';
import { ComponentProps, forwardRef, ReactNode, RefObject } from 'react';
import { css, keyframes } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import { THEME } from '@src/libs/theme';
import React from 'react';

export interface AccordionProps {
  type: ComponentProps<typeof Root>['type'];
  className?: string;
  defaultValue?: string | string[];
  items: {
    trigger: ComponentProps<typeof AccordionTrigger>;
    content: ReactNode;
    key?: string;
    value: string;
  }[];
}

export const Trigger = forwardRef(({ children, ...props }: ComponentProps<typeof AccordionTrigger>, forwardedRef) => (
  <Header css={styles.header}>
    <AccordionTrigger {...props} ref={forwardedRef as RefObject<HTMLButtonElement>} css={styles.trigger}>
      {children}
      <Icomoon className="toggle-icon" icon="arrow-down" size={10} />
    </AccordionTrigger>
  </Header>
));
Trigger.displayName = 'AccordionTrigger';

export const Accordion = ({ items, type, defaultValue = [], className }: AccordionProps) => (
  // @ts-ignore dynamic type can be ether "single" or "multiple", based on it defaultValue is string or string[]
  <Root type={type} defaultValue={defaultValue} css={styles.root} className={className}>
    {items.map(({ trigger, content, value }) => (
      <Item value={value} css={styles.item} key={value}>
        <Trigger {...trigger} />
        <Content css={styles.content}>{content}</Content>
      </Item>
    ))}
  </Root>
);

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const styles = {
  root: css({
    background: THEME.colors.white,
  }),
  header: css({
    all: 'unset',
    display: 'flex',
    padding: '0 16px',
  }),
  trigger: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 4px',
    background: THEME.colors.white,
    cursor: 'pointer',

    '&[data-state="open"]': {
      '.toggle-icon': {
        transition: 'transform 200ms',
        transform: 'rotate(180deg)',
      },
    },

    '&:hover': {
      opacity: 0.8,
    },
  }),
  item: css({
    overflow: 'hidden',
    marginTop: 1,
    borderBottom: '1px solid #dee5ec',

    '&:first-of-type': {
      marginTop: 0,
    },

    '&:focus-withi': {
      position: 'relative',
      zIndex: 1,
    },
  }),
  content: css({
    background: THEME.colors.white,
    overflow: 'hidden',
    padding: '0 16px',

    '&[data-state="open"]': {
      animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    },

    '&[data-state="closed"]': {
      animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    },
  }),
};

export default Accordion;
