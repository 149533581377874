import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { DragAndDrop as DragAndDropUpload, ThemeButton } from '@src/components/molecules';
import Select, { ConditionalProps, SelectProps } from '@src/components/shared/Select/Select';
import { THEME } from '@src/libs/theme';
import { FontSize } from '@src/__generated__/globalTypes';
import { fontSizeMapping } from './helpers';

interface DragAndDropProps {
  allowVideo?: boolean;
  disabled?: boolean;
  error?: boolean;
  imageUrl: string;
  onChange: (imageUrl: string) => void;
}

export const DragAndDrop = ({ allowVideo, disabled, error, imageUrl, onChange }: DragAndDropProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ pointerEvents: disabled ? 'none' : 'auto' }}>
      {imageUrl ? (
        <div css={styles.uploadedImageContainer}>
          <div>
            <div onClick={() => onChange('')}>
              <Icomoon color="#fff" icon="clear" size={8} />
            </div>
            <img src={imageUrl} />
          </div>
        </div>
      ) : (
        <DragAndDropUpload
          accept={`image/gif, image/jpeg, image/png ${allowVideo ? ', video/avi, video/mp4, video/quicktime' : ''}`}
          customContent={
            <div css={styles.dragAndDropContent}>
              <div>
                <ThemeButton text="Upload File" width="max-content" css={{ marginBottom: '16px' }} />
              </div>
              <div>{t('Annotation.drop the file and upload')}</div>
              <div>{t('DragAndDrop.MaxSize', { maxSize: '10GB' })}</div>
              <div>
                {t('DragAndDrop.FileType', {
                  MIME: `PNG, JPEG, GIF ${allowVideo ? ', AVI, MOV, MP4' : ''}`,
                })}
              </div>
              <div>{t('DragAndDrop.MaxImageSize1024')}</div>
            </div>
          }
          error={error}
          onChange={val => onChange(val[0])}
        />
      )}
    </div>
  );
};

interface FontSizePickerProps {
  disabled?: boolean;
  isBold?: boolean;
  value?: FontSize;
  onChange: ({ isBold, value }: { isBold: boolean; value: FontSize }) => void;
}

export const FontSizePicker = ({ disabled, isBold, value = FontSize.SM, onChange }: FontSizePickerProps) => {
  const fontSizeOptions = Object.keys(fontSizeMapping).map(key => ({
    label: fontSizeMapping[key as FontSize],
    value: key,
  }));

  return (
    <FontSizePickerContainer disabled={disabled} isBold={isBold}>
      <div onClick={() => onChange({ isBold: !isBold, value })}>
        <Icomoon icon="bold" />
      </div>
      <Select
        disabled={disabled}
        disableClear
        options={fontSizeOptions}
        value={value}
        onChange={val => onChange({ isBold: !!isBold, value: val as FontSize })}
      />
    </FontSizePickerContainer>
  );
};

const FontSizePickerContainer = styled.div<{ disabled?: boolean; isBold?: boolean }>(({ disabled, isBold }) => ({
  display: 'flex',
  height: 32,
  pointerEvents: disabled ? 'none' : 'auto',

  '& > div:nth-of-type(1)': {
    alignItems: 'center',
    backgroundColor: isBold ? '#f6f8fa' : '#fff',
    border: THEME.box.borders.gray.main,
    borderRadius: '3px 0 0 3px',
    borderRight: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    width: 32,
  },

  '& > div:nth-of-type(2)': {
    width: 64,
  },
}));

export const labelFormat = 'HH:mm';
export const valueFormat = 'hh:mm A';

interface TimePickerProps extends Omit<SelectProps, 'options'> {
  labelFormat?: string;
  valueFormat?: string;
}

export const TimePicker = ({
  labelFormat: lf,
  valueFormat: vf,
  ...selectProps
}: TimePickerProps & ConditionalProps) => {
  const timestamps = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2);
    const minutes = index % 2 === 0 ? 0 : 30;

    return {
      label: moment({ hour, minutes }).format(lf || labelFormat),
      value: moment({ hour, minutes }).format(vf || valueFormat),
    };
  });

  return <Select css={styles.timePickerSelector} options={timestamps} {...selectProps} />;
};

const styles = {
  dragAndDropContent: css({
    background: '#f6f8fa',
    border: '1px dashed #e0e8ed',
    borderRadius: 3,
    padding: 24,
    transition: 'border-color 0.3s ease-in-out',

    '&:hover': {
      borderColor: '#179cd7',
    },

    '& > div': {
      color: THEME.font.colors.gray.main,
      display: 'flex',
      fontSize: 11,
      justifyContent: 'center',

      '& > button': {
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
        marginBottom: 16,
      },
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 12,
    },
  }),
  timePickerSelector: css({
    '& > label': {
      fontSize: 13,
    },

    '& > div > div': {
      borderRadius: 2,
      minHeight: 30,

      '& > div > div': {
        height: 'inherit',
      },
    },

    '& input': {
      borderRadius: 2,
      minHeight: 30,
    },
  }),
  uploadedImageContainer: css({
    backgroundColor: '#f6f8fa',
    border: '2px dashed #dee5ec',
    borderRadius: 3,
    padding: 16,

    '& > div': {
      position: 'relative',
      width: 108,

      '& > div': {
        alignItems: 'center',
        backgroundColor: '#000',
        borderRadius: '50%',
        display: 'flex',
        height: 16,
        justifyContent: 'center',
        position: 'absolute',
        right: -8,
        top: -8,
        width: 16,

        '& > img': {
          width: 14,
        },
      },

      '& > img': {
        backgroundColor: '#b3b7ba',
        borderRadius: 2,
        height: 108,
        objectFit: 'contain',
        width: '100%',
      },
    },
  }),
};
