import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import yourJobBackground from '@src/assets/img/myJobs/yourJobBackground.png';
import { THEME } from '@src/libs/theme';

interface EmptyJobProps {
  redirect: string;
}

const EmptyJob = ({ redirect }: EmptyJobProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div css={styles.infoContainer}>
        <div>
          <img alt="backgroundImg" height="120" src={yourJobBackground} width="170" />
        </div>
        <div>{t('Annotation.Empty Your Job Page')}</div>
        <div>
          <Link css={styles.connectBtn} to={redirect}>
            {t('Button.Go to see jobs')}
          </Link>
        </div>
      </div>
    </div>
  );
};

const styles = {
  connectBtn: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    border: THEME.box.borders.gray.main,
    borderRadius: 5,
    color: THEME.font.colors.gray.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    height: 40,
    justifyContent: 'center',
    width: 156,
  }),
  container: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '70vh',
  }),
  infoContainer: css({
    textAlign: 'center',
    width: 300,

    '& > div:nth-of-type(1)': {
      display: 'grid',
      justifyContent: 'center',
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.normal,
      margin: '16px 0',
    },

    '& > div:nth-of-type(3)': {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
};

export default EmptyJob;
