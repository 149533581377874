import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  TrackYoutubeMusicDownloadMutation,
  TrackYoutubeMusicDownloadMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/TrackYoutubeMusicDownload.graphql';

export const useTrackYoutubeMusicDownloadMutation = (
  options?: MutationHookOptions<TrackYoutubeMusicDownloadMutation, TrackYoutubeMusicDownloadMutationVariables>
) => useMutation<TrackYoutubeMusicDownloadMutation, TrackYoutubeMusicDownloadMutationVariables>(MUTATION, options);
