import React, { useEffect, useState, PropsWithChildren } from 'react';
import { InitialLoading } from '@src/components/atoms';
import { getToken, removeToken } from '@src/libs/auth';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { useAuthSetup } from './useAuthSetup';

interface AuthSetUpProps {
  hideLoadingPaths?: string[];
  skipAuthPaths?: string[];
}

const AuthSetUp = (props: PropsWithChildren<AuthSetUpProps>) => {
  const { children, hideLoadingPaths, skipAuthPaths } = props;
  const { setUp } = useAuthSetup();
  const { t, enqueueSnackbar, matchPath, navigate, pathname } = useQueryHelper();
  const hideLoading = !!hideLoadingPaths?.find(path => matchPath(path, pathname));
  const skipAuth = !!skipAuthPaths?.find(path => matchPath(path, pathname));
  const [loading, setLoading] = useState<boolean>(!(hideLoading || skipAuth));

  const executeSetUp = async () => {
    const token = getToken();
    if (!token) {
      setLoading(false);

      return;
    }

    try {
      await setUp(token);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t(e.message), { variant: 'error' });
      removeToken();
      navigate(ROUTES.ROOT);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!skipAuth) {
      executeSetUp();
    }
  }, [skipAuth]);

  if (loading) {
    return hideLoading ? null : <InitialLoading />;
  }

  return <>{children}</>;
};

export default AuthSetUp;
