import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerAllInstagramMentionedPostsCountQuery,
  InfluencerAllInstagramMentionedPostsCountQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerAllInstagramMentionedPostsCount.graphql';

export const useInfluencerAllInstagramMentionedPostsCountQuery = (
  options?: QueryHookOptions<
    InfluencerAllInstagramMentionedPostsCountQuery,
    InfluencerAllInstagramMentionedPostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<
    InfluencerAllInstagramMentionedPostsCountQuery,
    InfluencerAllInstagramMentionedPostsCountQueryVariables
  >(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerAllInstagramMentionedPostsCountLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerAllInstagramMentionedPostsCountQuery,
    InfluencerAllInstagramMentionedPostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<
    InfluencerAllInstagramMentionedPostsCountQuery,
    InfluencerAllInstagramMentionedPostsCountQueryVariables
  >(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
