import { FanActivity, FanActivityType } from '@src/__generated__/globalTypes';
import { TFunction } from 'i18next';

export const getActivityList = (activities: FanActivity[], t: TFunction) =>
  activities.reduce<
    {
      id: number;
      activityType: FanActivityType;
      activity?: string | null;
      created: string;
      commenterId?: number | null;
    }[]
  >((prev, curr) => {
    switch (curr.activityType) {
      case FanActivityType.COMMENT_CREATE:
        return [
          ...prev,
          ...curr.changes.map(item => ({
            id: curr.id,
            activityType: curr.activityType,
            created: curr.created,
            activity: item.newValue,
            commenterId: curr.commenterId,
          })),
        ];

      case FanActivityType.FAN_CREATE:
        return [
          ...prev,
          { id: curr.id, activityType: curr.activityType, created: curr.created, activity: t('Fan was created') },
        ];

      case FanActivityType.FAN_UPDATE:
        return [
          ...prev,
          ...curr.changes.map(item => ({
            id: curr.id,
            activityType: curr.activityType,
            created: curr.created,
            activity: t('FAN_UPDATE', {
              key: item.key.toLowerCase().replace('_', ' '),
              oldValue: item.oldValue,
              newValue: item.newValue,
            }),
          })),
        ];

      case FanActivityType.ORDER_CREATE:
        return [
          ...prev,
          ...curr.changes.map(item => ({
            id: curr.id,
            activityType: curr.activityType,
            created: curr.created,
            activity: t('This fan placed order', { orderId: item.newValue }),
          })),
        ];

      case FanActivityType.ORDER_UPDATE:
        return [
          ...prev,
          ...curr.changes.map(item => ({
            id: curr.id,
            activityType: curr.activityType,
            created: curr.created,
            activity: t('This fan updated order', { orderId: item.newValue }),
          })),
        ];

      case FanActivityType.ORDER_CANCEL:
        return [
          ...prev,
          ...curr.changes.map(item => ({
            id: curr.id,
            activityType: curr.activityType,
            created: curr.created,
            activity: t('This fan cancelled order', { orderId: item.newValue }),
          })),
        ];
    }
  }, []);
