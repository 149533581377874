import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerAllInstagramFeedPostsQuery,
  InfluencerAllInstagramFeedPostsQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerAllInstagramFeedPosts.graphql';

export const useInfluencerAllInstagramFeedPostsQuery = (
  options?: QueryHookOptions<InfluencerAllInstagramFeedPostsQuery, InfluencerAllInstagramFeedPostsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerAllInstagramFeedPostsQuery, InfluencerAllInstagramFeedPostsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerAllInstagramFeedPostsLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerAllInstagramFeedPostsQuery, InfluencerAllInstagramFeedPostsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerAllInstagramFeedPostsQuery, InfluencerAllInstagramFeedPostsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
