import React, { useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckBox } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { OptionType } from '@src/components/shared/MultiSearchSelector';
import { MultiTagsSearchSelector, Select } from '@src/components/shared';
import { useQueryHelper } from '@src/libs/hooks';
import { useAddFanTagsMutation, useDeleteFanTagsMutation } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { SelectedTagsInfo } from './types';
import { getInitialTagsSelection } from './helper';

interface SelectionNoticeProps {
  selectedFansId: Set<number>;
  allSelectedTags: SelectedTagsInfo;
  totalFanCount: number;
  onConfirm: () => void;
  onSelectAll: () => void;
}

enum AllowedOperations {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

const SelectionNotice = ({
  selectedFansId,
  allSelectedTags,
  totalFanCount,
  onConfirm,
  onSelectAll,
}: SelectionNoticeProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedOption, setSelectedOption] = useState<AllowedOperations | undefined>(undefined);
  const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
  const [addFanTags] = useAddFanTagsMutation({
    refetchQueries: ['GetFans'],
  });
  const [executeDeleteFanTags] = useDeleteFanTagsMutation({
    refetchQueries: ['GetFans'],
  });

  const handleOnExec = () => {
    setShowDeleteConfirmation(false);
    onConfirm();
  };

  const handleChange = (value: string) => {
    setSelectedOption(value as AllowedOperations);
  };

  const options = useMemo(
    () => [
      { label: t('Add Tags'), value: AllowedOperations.ADD },
      { label: t('Delete Tags'), value: AllowedOperations.DELETE },
    ],
    []
  );

  const handleExec = async () => {
    if (selectedOption === AllowedOperations.ADD) {
      try {
        await addFanTags({
          variables: {
            input: {
              fanIds: Array.from(selectedFansId),
              tags: selectedTags.map(item => item.label),
            },
          },
        });
        enqueueSnackbar(t('succeededInAddingTags'), { variant: 'success' });
      } catch (err) {
        const message = err?.message || 'failedToAdd';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        setSelectedOption(undefined);
      }
    } else {
      try {
        await executeDeleteFanTags({
          variables: {
            input: {
              fanIds: Array.from(selectedFansId),
              tagIds: selectedTags.map(item => Number(item.value)).filter(item => item),
            },
          },
        });
        enqueueSnackbar(t('succeededInDeletingTags'), { variant: 'success' });
      } catch (err) {
        const message = err?.message || 'failedToDelete';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        setSelectedOption(undefined);
      }
    }
  };

  useEffect(() => {
    if (selectedOption === AllowedOperations.DELETE || selectedOption === AllowedOperations.ADD) {
      setSelectedTags(getInitialTagsSelection(allSelectedTags));
    } else {
      setSelectedTags([]);
    }
  }, [selectedOption]);

  return (
    <>
      <div css={styles.container}>
        <div css={styles.headerLeft}>
          <CheckBox
            label="Selected"
            checked={selectedFansId.size !== 0}
            indeterminate={![totalFanCount, 0].includes(selectedFansId.size)}
            interpolation={{ count: selectedFansId.size.toString() }}
            onChange={onSelectAll}
          />
          <p css={styles.selectLink} onClick={onSelectAll}>
            {t('Select all items')}
          </p>
          <Select
            css={styles.selector}
            options={options}
            placeholder="Other operations"
            value={selectedOption as string}
            onChange={value => handleChange(value)}
          />
        </div>
        <ThemeButton text="Delete" theme="red" onClick={() => setShowDeleteConfirmation(true)} css={styles.button} />
      </div>

      <Dialog
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Delete',
          theme: 'red',
          onClick: handleOnExec,
        }}
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
      >
        <Heading>{t('Are you sure you want to permanently delete this?')}</Heading>
        <RegularText>{t('You might lost fans data forever Before continuing, export fans')}</RegularText>
        <RegularText css={styles.warning}>
          {t('Do you want to delete this fan and lost information of the fans?')}
        </RegularText>
      </Dialog>

      <Dialog
        nextButtonProps={{
          disabled: !selectedTags.length,
          styles: smallButtonStyles,
          text: selectedOption === AllowedOperations.ADD ? 'Add Tag' : 'Delete Tag',
          theme: selectedOption === AllowedOperations.DELETE ? 'red' : 'blue',
          onClick: handleExec,
        }}
        open={selectedOption === AllowedOperations.ADD || selectedOption === AllowedOperations.DELETE}
        onClose={() => setSelectedOption(undefined)}
      >
        {selectedOption === AllowedOperations.ADD && (
          <>
            <Heading>{t('Add tags to selected files')}</Heading>
            <RegularText>{t('Search for tags or create new ones and tag the selected files')}</RegularText>
          </>
        )}
        {selectedOption === AllowedOperations.DELETE && (
          <>
            <Heading>{t('Remove tags from selected files')}</Heading>
            <RegularText>{t('Deletes the specified tags')}</RegularText>
          </>
        )}
        <MultiTagsSearchSelector
          isCreatable={selectedOption === AllowedOperations.ADD}
          placeholder="VIP,etc"
          value={selectedTags}
          // @ts-ignore type is OptionType[]
          setFieldValue={val => setSelectedTags(val)}
        />
      </Dialog>
    </>
  );
};

const styles = {
  container: css({
    height: 48,
    backgroundColor: '#29323a',
    color: THEME.font.colors.white,
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  headerLeft: css({
    display: 'flex',
    alignItems: 'center',
    gap: THEME.box.gaps.l,
  }),
  button: css({
    borderRadius: THEME.box.borderRadius.s,
    padding: '0 8px',
    width: 106,
  }),
  warning: css({
    color: THEME.font.colors.error,
    marginBottom: 0,
  }),
  selector: css({
    color: THEME.font.colors.gray.main,
    width: 182,

    '& > label': {
      fontSize: THEME.font.sizes.normal,
    },

    '& > div > div': {
      borderRadius: 2,
      minHeight: 32,

      '& > div > div': {
        height: 'auto',
      },
    },

    '& p': {
      display: 'none',
    },

    '& input': {
      borderRadius: 2,
      minHeight: 32,

      '::placeholder': {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: THEME.font.colors.black.main,
        opacity: 1 /* Firefox */,
      },

      '::-ms-input-placeholder': {
        /* Microsoft Edge */
        color: THEME.font.colors.black.main,
      },

      ':-ms-input-placeholder': {
        /* Internet Explorer 10-11 */
        color: THEME.font.colors.black.main,
      },
    },
  }),
  selectLink: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.white,
    textDecoration: 'underline',
    cursor: 'pointer',
  }),
};

const Heading = styled.h1({
  marginBottom: 24,
  fontWeight: 600,
  fontSize: THEME.font.sizes.heading,
  lineHeight: '22px',
  color: THEME.font.colors.black.main,
});

const RegularText = styled.p({
  fontSize: THEME.font.sizes.normal,
  color: THEME.font.colors.black.main,
  marginBottom: 16,
});

export default SelectionNotice;
