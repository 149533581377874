import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { ErrorMessage, List } from '@src/components/atoms';
import {
  BackNavigator,
  EmptyTable,
  Pager,
  SliderTableComponents,
  SliderTableStyledComponents,
  ThemeButton,
} from '@src/components/molecules';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { TextForm } from '@src/components/shared';
import { LIMIT } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { getCurrentPage, getOffset, getPageInfo } from '@src/components/molecules/Pager/helpers';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  useCreateCreatorStaffMutation,
  useGetCreatorStaffsCountQuery,
  useGetCreatorStaffsQuery,
  useRemoveCreatorStaffMutation,
} from '@src/graphql/hooks';
import { ROUTES, generatePath } from '@src/shared/routes';

const Users = () => {
  const [dialogAdd, setDialogAdd] = useState<boolean>(false);
  const [dialogDelete, setDialogDelete] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmmiting] = useState(false);
  const [name, setName] = useState<string>('');
  const [selectedStaffId, setSelectedStaffId] = useState<number | null>(null);
  const { enqueueSnackbar, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const currentPage = getCurrentPage();
  const { data } = useGetCreatorStaffsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        limit: LIMIT,
        offset: getOffset(currentPage),
      },
    },
  });
  const { data: dataCreatorStaffsCount } = useGetCreatorStaffsCountQuery();
  const [createCreatorStaff] = useCreateCreatorStaffMutation({
    refetchQueries: ['GetCreatorStaffs'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.addUser);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });
  const [executeRemoveCreatorStaff] = useRemoveCreatorStaffMutation({
    refetchQueries: ['GetCreatorStaffs'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const cancelDialogAdd = () => {
    setDialogAdd(false);
    setEmail('');
    setEmailError(false);
    setName('');
  };

  const onClickDelete = (id: number) => {
    setSelectedStaffId(id);
    setDialogDelete(true);
  };

  const onClickDeleteStaff = () => {
    if (selectedStaffId) {
      setIsSubmmiting(true);
      executeRemoveCreatorStaff({
        variables: {
          input: {
            creatorStaffId: selectedStaffId,
          },
        },
      })
        .then(() => setDialogDelete(false))
        .finally(() => setIsSubmmiting(false));
    }
  };

  // @ts-ignore: Not all code paths return a value
  const onClickSendInvite = () => {
    // eslint-disable-next-line no-useless-escape
    const isValidEmail = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,}$/.test(email);
    if (!isValidEmail) {
      setEmailError(true);

      return false;
    }

    setEmailError(false);
    setIsSubmmiting(true);
    createCreatorStaff({
      variables: {
        input: {
          name,
          email,
        },
      },
    })
      .then(() => cancelDialogAdd())
      .finally(() => setIsSubmmiting(false));
  };

  const avatar = data?.getCreatorStaffs?.avatar || '';
  const creatorName = data?.getCreatorStaffs?.name || '';
  const staffs = data?.getCreatorStaffs?.staffs || [];
  const selectedStaffName = staffs.find(staff => staff?.id === selectedStaffId)?.name || '';
  const staffCount = staffs.length;
  const totalCount = dataCreatorStaffsCount?.getCreatorStaffsCount?.total || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  return (
    <div css={styles.container}>
      <Dialog
        actionContentStyles={styles.dialogActionContainer}
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: cancelDialogAdd,
        }}
        nextButtonProps={{
          disabled: !name || !email || isSubmitting,
          styles: smallButtonStyles,
          text: 'Send invite',
          theme: 'blue',
          onClick: onClickSendInvite,
        }}
        open={dialogAdd}
        onClose={() => setDialogAdd(false)}
      >
        <DialogContent>
          <DialogTitle>{t('Add Staff')}</DialogTitle>
          <TextFormContainer>
            <StyledTextForm
              isRequired
              placeholder="Name"
              title="Name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </TextFormContainer>
          <TextFormContainer>
            <StyledTextForm
              isRequired
              placeholder="Email"
              title="Email address"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            {emailError && <ErrorMessage message={'invalidEmailMessage'} />}
          </TextFormContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        actionContentStyles={styles.dialogActionContainer}
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: () => setDialogDelete(false),
        }}
        nextButtonProps={{
          disabled: isSubmitting,
          styles: smallButtonStyles,
          text: 'Delete',
          theme: 'red',
          onClick: onClickDeleteStaff,
        }}
        open={dialogDelete}
        onClose={() => setDialogDelete(false)}
      >
        <DialogContent>
          <DialogTitle>{t('Dialog.Delete Staff')}</DialogTitle>
          <div css={{ fontSize: THEME.font.sizes.subordinate }}>{`${t('Delete staff')} ${selectedStaffName}?`}</div>
        </DialogContent>
      </Dialog>

      {isDesktopView && <BackNavigator title="Users" to={ROUTES.SETTINGS} />}

      <div css={styles.creatorContainer}>
        <div>{t('Creator')}</div>
        <div>
          <img alt="avatar" css={styles.avatar} height="64" src={defaultInfluencerAvatar(avatar)} width="64" />
          <span>{creatorName}</span>
        </div>
      </div>

      <div css={styles.addStaffContainer}>
        <div>{t('User Count', { staffCount: staffCount * currentPage, totalCount })}</div>
        <ThemeButton theme="blue" text="Add Staff" onClick={() => setDialogAdd(true)} />
      </div>

      <div css={styles.tableContainer}>
        {staffCount > 0 ? (
          !isDesktopView ? (
            staffs.map(staff => (
              <div css={styles.staffsContainer} key={staff!.id}>
                <Link to={generatePath(ROUTES.SETTINGS_USERS_USER_ID, { userId: staff.id })}>{staff!.name}</Link>
                <p>{staff?.email}</p>
              </div>
            ))
          ) : (
            <SliderTableComponents.SliderSection css={styles.sliderTableContainer}>
              <SliderTableComponents.SliderTable>
                <thead>
                  <tr>
                    <StyledHeaderColumn title="Name" />
                    <StyledHeaderColumn title="Mail Address" />
                  </tr>
                </thead>

                <tbody>
                  {staffs.map(staff => (
                    <SliderTableStyledComponents.StyledRowNew css={styles.tableBodyRow} key={staff!.id}>
                      <td>
                        <Link to={`/settings/users/${staff!.id}`}>{staff!.name}</Link>
                      </td>
                      <td>
                        <div css={styles.emailContainer}>
                          <div>{staff!.email}</div>
                          <div>
                            <ThemeButton
                              css={styles.deleteBtn}
                              text="Delete"
                              onClick={() => onClickDelete(staff!.id)}
                            />
                          </div>
                        </div>
                      </td>
                    </SliderTableStyledComponents.StyledRowNew>
                  ))}
                </tbody>
              </SliderTableComponents.SliderTable>
            </SliderTableComponents.SliderSection>
          )
        ) : (
          <EmptyTable css={styles.emptyTable} />
        )}
      </div>

      <Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </div>
  );
};

const DialogContent = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: 8,
});

const DialogTitle = styled.div({
  color: THEME.font.colors.black.main,
  display: 'grid',
  flexBasis: '100%',
  fontSize: THEME.font.sizes.heading,
  fontWeight: 600,
  marginBottom: 24,
});

const StyledHeaderColumn = styled(List.HeaderColumn)({
  color: THEME.font.colors.black.main,
  borderBottom: '1px solid #dee5ec',
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 600,
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& input': {
    borderRadius: 0,
    height: 32,
  },
});

const TextFormContainer = styled.div({
  display: 'grid',
  flexBasis: '100%',
  marginBottom: 16,
});

const styles = {
  addStaffContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderBottom: '1px solid #dee5ec',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 64,
    padding: '8px 16px',

    '& > div': {
      color: THEME.font.colors.black.main,
      display: 'flex',
      flex: 1,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
    },

    '& > button': {
      width: 'max-content',

      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        borderRadius: 5,
      },
    },
  }),
  avatar: css({
    borderRadius: '50%',
  }),
  container: css({
    margin: '16px 24px',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      margin: 16,
    },
  }),
  creatorContainer: css({
    backgroundColor: THEME.colors.white,
    margin: '24px 0',
    padding: '24px 16px',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
    },

    '& > div:nth-of-type(2)': {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 8,

      '& > span': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        marginLeft: 8,
      },
    },
  }),
  deleteBtn: css({
    width: 'max-content',
  }),
  dialogActionContainer: css({
    background: '#f6f8fa',
    borderRadius: '0 0 15px 15px',
  }),
  emailContainer: css({
    alignItems: 'center',
    display: 'flex',

    '& > div': {
      display: 'grid',
      flexBasis: '50%',
    },

    '& > div:nth-of-type(2)': {
      justifyContent: 'flex-end',
    },
  }),
  emptyTable: css({
    border: 'none',
    margin: 0,
  }),
  sliderTableContainer: css({
    width: '100%',
  }),
  staffsContainer: css({
    backgroundColor: THEME.colors.white,
    borderBottom: '1px solid #dee5ec',
    padding: 8,

    '& > a': {
      color: THEME.font.colors.blue.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 8,
    },

    '& > p': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
    },
  }),
  tableBodyRow: css({
    '& > td > a': {
      color: THEME.font.colors.blue.main,
      fontSize: THEME.font.sizes.normal,
    },

    '& > td:nth-of-type(1)': {
      color: THEME.font.colors.blue.main,
      fontSize: THEME.font.sizes.normal,
    },

    '&:hover': {
      '& > td': {
        backgroundColor: THEME.colors.white,
      },
    },
  }),
  tableContainer: css({
    boxShadow: THEME.box.shadows.outer,
  }),
};

export default Users;
