import { atom } from 'recoil';

export interface SelectedChatStateType {
  pic: string | null;
  resolved: boolean;
  userName: string;
  isChartStateMenuOpen: boolean;
  fanId: number | null;
  hasNoChats: boolean | null;
}
export const selectedChatState = atom<SelectedChatStateType>({
  key: 'selectedChatState',
  default: { pic: null, resolved: false, userName: '', isChartStateMenuOpen: false, fanId: null, hasNoChats: null },
});

interface ChatMessageTemplatesStateType {
  name: string;
  templateId: string;
  text: string;
}
export const chatMessageTemplatesState = atom<ChatMessageTemplatesStateType[]>({
  key: 'chatMessageTemplatesState',
  default: [],
});
