import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerAllInstagramFeedPostsCountQuery,
  InfluencerAllInstagramFeedPostsCountQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerAllInstagramFeedPostsCount.graphql';

export const useInfluencerAllInstagramFeedPostsCountQuery = (
  options?: QueryHookOptions<
    InfluencerAllInstagramFeedPostsCountQuery,
    InfluencerAllInstagramFeedPostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerAllInstagramFeedPostsCountQuery, InfluencerAllInstagramFeedPostsCountQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};

export const useInfluencerAllInstagramFeedPostsCountLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerAllInstagramFeedPostsCountQuery,
    InfluencerAllInstagramFeedPostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerAllInstagramFeedPostsCountQuery, InfluencerAllInstagramFeedPostsCountQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
