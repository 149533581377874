import { useQueryHelper } from '@src/libs/hooks';
import { useState } from 'react';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useAddCommentMutation, useDeleteCommentMutation, useUpdateCommentMutation } from '@src/graphql/hooks';
import { FormValues } from './CommentForm';

export default (fanId: number) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [activity, setActivity] = useState<{
    id: number | null;
    showCommentInsideForm: boolean;
    showDeleteConfirmation: boolean;
  }>({
    id: null,
    showCommentInsideForm: false,
    showDeleteConfirmation: false,
  });
  const [addComment] = useAddCommentMutation({
    refetchQueries: ['GetFanActivities'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInPosting'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.addFanActivityComment, { fanId });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });
  const [deleteComment] = useDeleteCommentMutation({
    refetchQueries: ['GetFanActivities'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.deleteFanActivityComment, { activityId: activity.id });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });
  const [updateComment] = useUpdateCommentMutation({
    refetchQueries: ['GetFanActivities'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editFanActivityComment, { activityId: activity.id });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const handleClickMore = (activityId: number) => {
    setActivity({ id: activityId, showCommentInsideForm: false, showDeleteConfirmation: false });
  };

  const handleClose = () => {
    setActivity({ id: null, showCommentInsideForm: false, showDeleteConfirmation: false });
  };

  const handleAddComment = async (payload: FormValues) => {
    await addComment({
      variables: {
        input: {
          fanId,
          comment: payload.comment,
        },
      },
    });
  };

  const handleClickEdit = () => {
    setActivity(prevState => ({ ...prevState, showCommentInsideForm: true, el: null }));
  };

  const handleUpdateComment = async (comment: string) => {
    await updateComment({
      variables: {
        input: {
          activityId: activity.id as number,
          comment,
        },
      },
    });

    setActivity(prevState => ({ ...prevState, showCommentInsideForm: false, id: null }));
  };

  const handleClickDelete = () => {
    setActivity(prevState => ({ ...prevState, showDeleteConfirmation: true, el: null }));
  };

  const handleDeleteConfirm = async () => {
    await deleteComment({
      variables: {
        input: {
          id: activity.id as number,
        },
      },
    });

    setActivity(prevState => ({ ...prevState, el: null, id: null, showDeleteConfirmation: false }));
  };

  return {
    activity,
    handleClickMore,
    handleClose,
    handleClickEdit,
    handleClickDelete,
    handleAddComment,
    handleUpdateComment,
    handleDeleteConfirm,
  };
};
