import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { formatNumber } from '@src/libs/format';
import { BackNavigator } from '@src/components/molecules';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { getDateLocal } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { CircularProgress, Icomoon } from '@src/components/atoms';
import { useGetFanHeaderQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';

interface FanDetailsHeaderProps {
  id: string;
}

const FanDetailsHeader = ({ id }: FanDetailsHeaderProps) => {
  const { i18n, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { data, loading } = useGetFanHeaderQuery({
    variables: {
      input: {
        fanId: Number(id),
      },
    },
  });

  const headerInfo = data?.getFanHeader;

  const fullName = `${data?.getFanHeader?.firstName} ${data?.getFanHeader?.lastName}`;
  const isLineConnected = !!headerInfo?.lineAccount;
  const isShopifyConnected = !!headerInfo?.shopifyAccount;

  return (
    <>
      {!isMobileView && (
        <BackNavigator
          css={{ marginBottom: 24 }}
          to={ROUTES.MY_PAGE_FANS}
          title="Details of"
          interpolation={{ translationKey: fullName }}
        />
      )}
      <div css={[styles.wrapper, isMobileView ? styles.mobileWrapper : undefined]}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div css={styles.col}>
              <img css={styles.avatar} src={headerInfo?.avatar} />
              <div>
                <MainText css={styles.marginBottom6}>
                  {headerInfo?.firstName} {headerInfo?.lastName}
                </MainText>

                {headerInfo?.created && (
                  <SubText css={styles.clearMarginBottom}>
                    {`${t('Customer for')} ${
                      headerInfo.created
                        ? formatDistanceToNowStrict(new Date(headerInfo.created + 'Z'), {
                            locale: getDateLocal(i18n.language as AppLanguage),
                          })
                        : '-'
                    }`}
                  </SubText>
                )}
              </div>
            </div>
            <div css={[styles.col, isMobileView && styles.mobileCol]}>
              <InfoWrapper isMobile={isMobileView}>
                <SubText>{t('Channels')}</SubText>
                {isLineConnected || isShopifyConnected ? (
                  <div css={styles.connectedChannelsContainer}>
                    {isLineConnected && <Icomoon icon="line-color" size={24} />}
                    {isShopifyConnected && <Icomoon icon="shopify-color" size={24} />}
                  </div>
                ) : (
                  <MainText>-</MainText>
                )}
              </InfoWrapper>
              <InfoWrapper isMobile={isMobileView}>
                <SubText>{t('Last order')}</SubText>
                <MainText>
                  {headerInfo?.lastOrder
                    ? formatDistanceToNow(new Date(headerInfo.lastOrder + 'Z'), {
                        addSuffix: true,
                        locale: getDateLocal(i18n.language as AppLanguage),
                      })
                    : '-'}
                </MainText>
              </InfoWrapper>
              <InfoWrapper isMobile={isMobileView}>
                <SubText>{t('Order')}</SubText>
                <MainText>
                  {headerInfo?.totalOrders} {t('orders')}
                </MainText>
              </InfoWrapper>
              <InfoWrapper isMobile={isMobileView}>
                <SubText>{t('Total spent')}</SubText>
                <MainText>
                  {headerInfo?.currencyId} {formatNumber(headerInfo?.totalSpent || null)}
                </MainText>
              </InfoWrapper>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const styles = {
  connectedChannelsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: THEME.box.gaps.s,
  }),
  mobileWrapper: css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 16,
    height: 'auto',
  }),
  clearMarginBottom: css({
    marginBottom: 0,
  }),
  marginBottom6: css({
    marginBottom: 6,
  }),
  wrapper: css({
    height: 112,
    display: 'flex',
    backgroundColor: THEME.colors.white,
    boxShadow: THEME.box.shadows.outer,
    borderRadius: 5,
    marginBottom: 24,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
  }),
  col: css({
    display: 'flex',
    alignItems: 'center',
    gap: THEME.box.gaps.l,
  }),
  mobileCol: css({
    marginTop: 16,
    width: '100%',
  }),
  avatar: css({
    width: 64,
    height: 64,
    borderRadius: '50%',
  }),
};

const MainText = styled.p({
  fontWeight: 600,
  fontSize: THEME.font.sizes.subHeading,
  color: THEME.font.colors.black.main,
});

const SubText = styled.p({
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.subordinate,
  marginBottom: 4,
});

const InfoWrapper = styled.div<{ isMobile: boolean }>(({ isMobile }) => ({
  padding: isMobile ? '0' : '0 40px',
  display: 'flex',
  flex: 'auto',
  flexDirection: 'column',
  alignItems: 'center',

  '&:not(:last-of-type)': {
    borderRight: '1px solid #eef3f7',
  },
}));

export default FanDetailsHeader;
