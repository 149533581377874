import styled from '@emotion/styled';
import { List } from '@src/components/atoms';
import { SliderTableComponents } from '@src/components/molecules';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

export const Container = styled.div({
  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    padding: '24px 16px',
  },
});

export const Description = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subordinate,
  marginBottom: 24,
});

export const Header = styled.div({
  alignItems: 'center',
  backgroundColor: THEME.colors.white,
  borderRadius: '23px 23px 0 0',
  display: 'flex',
  gap: THEME.box.gaps.xxl,
  padding: 32,

  '& > div': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
  },
});

export const StyledTextColumn = styled(List.TextColumn)({
  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    width: '12%',
  },
});

export const SummaryContainer = styled.div({
  display: 'flex',
  padding: 16,

  '& > div': {
    border: THEME.box.borders.gray.main,
    borderLeft: 'none',
    display: 'grid',
    gap: THEME.box.gaps.s,
    padding: '12px 0',
    textAlign: 'center',
    width: 'fill-available',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
    },
  },

  '& > div:nth-of-type(1)': {
    borderLeft: '1px solid #dee5ec',
    borderRadius: '10px 0 0 10px',
  },

  '& > div:last-child': {
    borderRadius: '0 10px 10px 0',
  },
});

export const TableContainer = styled.div({
  background: THEME.colors.white,
  borderRadius: 15,
  boxShadow: THEME.box.shadows.outer,
  overflow: 'hidden',
});

export const TableTitleContainer = styled.div({
  borderTop: '1px solid #eef3f7',
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
  padding: 16,
});

export const TableWrapper = styled(SliderTableComponents.Wrapper)<{ isEmptyData: boolean }>(({ isEmptyData }) => ({
  borderLeft: 'none',
  borderRight: 'none',
  margin: 0,
  padding: 0,
  ...(isEmptyData && { borderRadius: '0 0 15px 15px' }),
}));
