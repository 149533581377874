import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerProfileFollowerGrowthQuery,
  InfluencerProfileFollowerGrowthQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerProfileFollowerGrowth.graphql';

export const useInfluencerProfileFollowerGrowthQuery = (
  options?: QueryHookOptions<InfluencerProfileFollowerGrowthQuery, InfluencerProfileFollowerGrowthQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerProfileFollowerGrowthQuery, InfluencerProfileFollowerGrowthQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerProfileFollowerGrowthLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerProfileFollowerGrowthQuery, InfluencerProfileFollowerGrowthQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerProfileFollowerGrowthQuery, InfluencerProfileFollowerGrowthQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
