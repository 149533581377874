import React, { useEffect } from 'react';
import { secondsToMilliseconds } from 'date-fns';
import { css } from '@emotion/react';
import { TextCutter } from '@src/components/atoms';
import { NavLink } from 'react-router-dom';
import { dynamicTitleState, selectedChatState, useRecoil } from '@src/recoilAtoms';
import { useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';
import ChatAvatar from '../ChatAvatar';
import useFormatChatTime from '../hooks/useFormatChatTime';

export const enum ChatType {
  Line = 'LINE',
}
export interface ChatInfoCardProps {
  id: string;
  fanId: number | null;
  userName: string;
  avatar?: string | null;
  type?: ChatType;
  lastMessage: string;
  lastMessageAt: number;
  pic: string | null;
  checked: boolean;
  resolved: boolean;
  hasMobileLayout?: boolean;
}

function ChatInfoCard({
  id,
  fanId,
  userName,
  avatar,
  type = ChatType.Line, // currently type is always LINE
  lastMessage,
  lastMessageAt,
  pic,
  checked,
  resolved,
  hasMobileLayout,
}: ChatInfoCardProps) {
  const { t, params } = useQueryHelper();
  const pathOptions = id && fanId ? { activeChat: id, fanId } : undefined;
  const { setRecoilState: setSelectedChatState } = useRecoil(selectedChatState);
  const { setRecoilState: setDynamicTitleState } = useRecoil(dynamicTitleState);
  const { formattedChatTime } = useFormatChatTime({
    time: lastMessageAt,
    interval: secondsToMilliseconds(30),
  });

  useEffect(() => {
    if (id === params.activeChat) {
      setSelectedChatState(prevState => ({ ...prevState, pic, resolved, userName, fanId }));
      setDynamicTitleState({ title: userName });
    }
  }, [params.activeChat, pic, resolved, userName]);

  return (
    <NavLink
      to={
        fanId && !hasMobileLayout
          ? generatePath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID, pathOptions)
          : generatePath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID, id ? { activeChat: id } : undefined)
      }
      css={styles.container}
    >
      <div css={styles.mainContent}>
        <div css={styles.avatarRow}>
          <ChatAvatar avatar={avatar} type={type} />
          <div css={styles.textSection}>
            <div css={styles.justifiedRow}>
              <TextCutter text={userName} lines={1} css={[{ fontsize: '16px' }, !checked ? styles.unreadText : '']} />
              <span css={{ fontsize: '12px' }}>{formattedChatTime}</span>
            </div>
            <div css={styles.justifiedRow}>
              <TextCutter lines={1} text={lastMessage} css={[!checked ? styles.unreadText : '', { width: '100%' }]} />
              {!checked && <div css={styles.unreadBadge} />}
            </div>
          </div>
        </div>

        <div css={styles.statusBar}>
          <p css={[styles.status, resolved ? { background: '#dee5ec' } : '']}>{t(resolved ? 'Resolved' : 'Ongoing')}</p>
          {pic && <TextCutter lines={1} text={`${t('PIC')}: ${pic}`} css={styles.pic} />}
        </div>
      </div>
    </NavLink>
  );
}

const styles = {
  container: css({
    padding: '12px 10px',
    borderBottom: '1px solid #eef3f7',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(56, 146, 229, 0.2)',
    },

    '&.active': {
      backgroundColor: 'rgba(56, 146, 229, 0.1)',
    },
  }),
  mainContent: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
  }),
  avatarRow: css({
    display: 'flex',
    alignItems: 'end',
    columnGap: 12,
  }),
  statusBar: css({
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 8,
    alignItems: 'center',
    whiteSpace: 'nowrap',
  }),
  status: css({
    background: '#ffb63d',
    borderRadius: 40,
    fontWeight: 600,
    fontSize: 11,
    padding: '2px 6px',
    color: THEME.font.colors.white,
    minWidth: 60,
    textAlign: 'center',
  }),
  textSection: css({
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    color: THEME.font.colors.black.main,
  }),
  justifiedRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  unreadBadge: css({
    background: THEME.colors.blue.main,
    width: 8,
    height: 8,
    borderRadius: '50%',
    flexShrink: 0,
  }),
  pic: css({
    color: THEME.font.colors.blue.main,
    fontSize: THEME.font.sizes.subordinate,
  }),
  unreadText: css({
    fontWeight: 'bold',
  }),
};

export default ChatInfoCard;
