import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import {
  useCreateEngagementPostForInfluencerV2Mutation,
  useUpdateEngagementPostForInfluencerV2Mutation,
  useEngagementPostForInfluencerV2Query,
  useEngagementPostSocialAccountsForInfluencerQuery,
} from '@src/graphql/hooks';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
// import useCampaign from '@src/pages/Casting/YourJob/Details/useCampaign';
import { generatePath, ROUTES } from '@src/shared/routes';
import {
  CreateEngagementPostInputForInfluencerV2,
  EngagementCampaignPostStatus,
  UpdateEngagementPostInputForInfluencerV2,
  // SocialAccountType,
} from '@src/__generated__/globalTypes';
import PostForm, { PostDetails } from './PostForm';

interface IndexProps {
  engagementId: number;
  postId?: number;
}

const Index = ({ engagementId, postId }: IndexProps) => {
  const [postDetails, setPostDetails] = useState<PostDetails>({
    campaignId: engagementId,
    content: '',
    materialsUrl: [],
    planedPostDate: '',
    socialAccountId: null,
    socialAccountMedia: null,
    socialMedia: null,
  });
  const [status, setStatus] = useState<EngagementCampaignPostStatus>(EngagementCampaignPostStatus.REVIEWING);
  const { enqueueSnackbar, navigate, t } = useQueryHelper();
  // const { campaignDetails } = useCampaign(engagementId);
  const { data } = useEngagementPostSocialAccountsForInfluencerQuery({
    variables: {
      pk: engagementId,
    },
  });
  const [createCampaignPost] = useCreateEngagementPostForInfluencerV2Mutation({
    refetchQueries: ['EngagementCampaignPosts'],
  });
  const [updateCampaignPost] = useUpdateEngagementPostForInfluencerV2Mutation();
  // TODO: to enable new flow when marketplace implement TTCM
  // const availableSocialAccounts = socialAccounts.filter(
  //   account => !(campaignDetails?.isTtcmCampaign && account.socialMedia === SocialAccountType.TIKTOK)
  // );

  const validationSchema = yup.object().shape({
    content: yup.string().required('requiredEngagementPostContent'),
    planedPostDate: yup.string().required('requiredEngagementPlannedPostDate'),
    socialAccountId: yup.number().nullable().required('requiredSocialAccountId'),
    socialMedia: yup.string().nullable().required('requiredIgSocialMedia'),
  });
  const methods = useForm<PostDetails>({
    defaultValues: postDetails,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(postDetails);
  }, [postDetails]);

  useEngagementPostForInfluencerV2Query({
    skip: !postId,
    ...(postId && {
      variables: {
        pk: postId,
      },
    }),
    onCompleted: ({ engagementPostForInfluencerV2 }) => {
      if (engagementPostForInfluencerV2) {
        const { content, id, materials, planedPostDate, postStatus, socialAccountId, socialAccountMedia, socialMedia } =
          engagementPostForInfluencerV2;
        const postInfo = {
          campaignId: engagementId,
          content,
          id,
          materialsUrl: materials?.map(material => material.url) || [],
          planedPostDate,
          socialAccountId,
          socialAccountMedia,
          socialMedia,
        };
        setPostDetails(postInfo);
        setStatus(postStatus);
      }
    },
  });

  const onSubmit = async (values: PostDetails) => {
    if (values.id) {
      updateCampaignPost({
        variables: {
          input: values as UpdateEngagementPostInputForInfluencerV2,
        },
      })
        .then(() => {
          enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
          sendAmplitudeEvent(eventTypes.editEngagementPost, { postId: postDetails.id });
        })
        .catch(err => {
          enqueueSnackbar(t(err.message), { variant: 'error' });
        });
    } else {
      createCampaignPost({
        variables: {
          input: values as CreateEngagementPostInputForInfluencerV2,
        },
      })
        .then(() => {
          enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
          sendAmplitudeEvent(eventTypes.addEngagementPost);
          navigate(generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST, { id: engagementId }));
        })
        .catch(err => {
          enqueueSnackbar(t(err.message), { variant: 'error' });
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PostForm
          isEdit={!!postId}
          socialAccounts={data?.engagementPostSocialAccountsForInfluencer || []}
          status={status}
        />
      </form>
    </FormProvider>
  );
};

export default Index;
