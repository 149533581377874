import * as React from 'react';
import { APP_NAME } from '@src/libs/constant';
import * as StyledComponents from './StyledComponents';

const PrivacyContentEN = () => (
  <StyledComponents.Wrapper>
    <StyledComponents.PrivacyTitle title={`Privacy Policy of ${APP_NAME}`} />
    <StyledComponents.SubTitle>Last Updated: 19 November 2020</StyledComponents.SubTitle>
    <StyledComponents.PrivacyText>
      {`AnyMind Group Pte. Ltd. (“AnyMind”, "us", "we", or "our") operates "${APP_NAME}"`}&nbsp;
      <StyledComponents.Anchor href="https://app.anytag.jp/" target="_blank">
        https://app.anytag.jp/
      </StyledComponents.Anchor>
      We care about your rights and we acknowledge that your data is yours. Please take your time to have a look through
      our privacy policy. This Privacy Policy of AnyCreator will help you understand what personal data we collect, why
      we collect it, how we protect your personal data, how long do we keep your personal data and what are your rights.
      If you have any questions please do not hesitate to contact us by sending an email to{' '}
      <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
        info@anymindgroup.com
      </StyledComponents.Anchor>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText>
      First, please have a look and be familiar with our specific cookie types used for the AnyCreator Service here
      below.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="Specific cookie types used">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b> Performance Analytics tracking :</b> This type of cookie provides aggregated information about where a
          user may go on the Website.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Google Analytics (persistent) :</b> AnyMind Group uses Google Analytics to help analyze how visitors use
          the Website. Among other things, Google Analytics uses cookies to collect information about the number of
          visits to the Website, the webpage that referred visitors to the Website, language, device, browser and
          operating system, the pages visitors view within the Website and other similar details. We do not share this
          information with third parties. When used this way the Google Analytics cookie is a persistent cookie, and
          will remain on your device until the cookie expires or you delete it. These cookies may appear as _utm, _utma,
          _utmb, _utmc, _utmz, _utmv, _utmt, _gid.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Google Analytics (session) :</b> This cookie may appear as has_js. We do not retain this data as it is
          deleted upon closing of your browser, and therefore we cannot share it with any third parties.{' '}
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Social Media :</b> These are cookies that help with the website’s functionality. These are third party
          cookies and they allow you to share comments, pages, bookmarks and likes and help to provide access to social
          networking tools easily.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Social Sharing :</b> Social media widgets used on this Website include LinkedIn, Twitter and YouTube.
          These social media widgets collect information about your visit to the Website and your use of the social
          platform services (for example, buttons or likes) on the Website. We do not share information with these
          social platforms, however please keep in mind the social networks collect the data of your site visit and
          interaction with the social widget directly.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Third-Party Services :</b> We may use cookies and pixels from third party providers – such as Google,
          Facebook and others – on our website to help provide more relevant experiences for you as a user. Such cookie
          or pixel may be implemented on a AnyMind Group company website which tags a visitor of the site, so that the
          visitor may be identified and advertisements can be served on that visitor via the relevant platforms.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="1. What personal data do we collect and how do we collect it?">
      Personal data means any information that can be used to identify directly or indirectly a specific individual. We
      collect your personal data for the purpose of using our service or join our AnyCreator Networks, AnyCreator as a
      data controller here to inform you all information you may need to know about using our services or joining our
      AnyCreator Networks.
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      You might refuse to provide us your personal data, but if you choose not to do so, we will not be able to provide
      you the AnyCreator Service, but you may at anytime choose to opt-out by simply sending us an email to {` `}
      <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
        info@anymindgroup.com
      </StyledComponents.Anchor>
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      We may collect personal data from a variety of sources. This includes:
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Personal data you give us directly. </b> We collect data about how you use our services and products, such
          as the types of content you view or engage with, or the frequency and duration of your activities. We also
          collect personal data you provide us when you sign up for a marketing newsletter, complete a survey or
          register for an account to buy our products or to join our AnyCreator Networks, and for any communication
          between you and us whether by e-mail, phone or other communication channels including our chatbot. In so
          doing, we may ask for personal data, which is your name, gender, date of birth, address, email address,
          telephone number, photographic images and your bank account’s detail;
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Personal data we collect automatically. </b> We also receive and store certain types of personal data
          whenever you interact with us online. This shall be included but not limited to cookies we used to obtain
          personal data when your web browser accesses our websites or advertisements and other content served by or on
          behalf of us on other websites. We also collect your data when you search, post, participate in a contest or
          questionnaire or communicate with our customer service teams. Your personal data that we collect automatically
          include IP address, device ID, location data, computer and connection information such as browser type and
          version, time zone setting, browser plug-in types and versions, operating system, and purchase history. We
          also use our software tools to measure and collect information which include social listening tools to analyze
          sentiment about normal comments on your social media. The software tools may collect page response times,
          length of visits to certain pages, etc.; and
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Personal data we collect from third parties. </b> We may use cookies and pixels from third party providers
          – such as Google, Facebook, Instagram, Twitter, YouTube and others via their official API – on our website to
          help provide more relevant experiences for you as a user. Such a cookie or pixel may be implemented on a
          AnyMind Group company website which tags a visitor of the site, so that the visitor may be identified and
          advertisements can be served on that visitor via the relevant platforms.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="2. Why do we collect your data?">
      We collect your data to analyze your access behavior for media buying, response to your inquiry, handle any
      complaints, to develop and improve our services, communication methods and the functionality of our websites, to
      provide personalised services, communications and targeted advertising, to provide you with requested marketing
      materials, newsletters and also use it for applications enhancement.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="3. Who do we share your personal data and non personal data with?">
      We know that your personal data is confidential therefore, except as set forth in the Privacy Policy of
      AnyCreator, your personal data will never be shared with third parties without your consent. We may pass your
      personal data to our subsidiaries and affiliated companies so that they may contact you in connection with the
      objective of your inquiry or propose you the run-on campaigns. Where we pass your personal data to our subsidiary
      or affiliated companies in another country, we will make sure that they apply the same level of protection to your
      information as we do. We may also disclose your information to third parties in the event of suspected fraud,
      legal requirement or in connection with our vendors and contractors, all as described below.{' '}
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      Generally, we do not collect your personal data via our client's advertisements, and therefore, we do not sell,
      rent, or share your personal data collected on our or other websites to non-affiliated third parties.{' '}
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      We may disclose your personal data to third parties when we reasonably believe we are obligated to do so by law,
      and in order to investigate, prevent, or take action regarding suspected or actual prohibited activities,
      including, but not limited to, fraud and situations involving potential threats to the physical safety of any
      person. In addition and if permitted by applicable law, we may also disclose and provide access to your personal
      data and non personal data to our vendors and contractors who are performing services on our behalf. However, such
      vendors and contractors are not permitted to use such user information for any purpose other than providing
      services to us.{' '}
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="4. How do we protect your personal data?">
      We follow generally accepted industry standards to protect against the unauthorized access to, retention of, and
      disclosure of information. This includes undertaking necessary physical, electronic, and management activities
      required to protect information integrity, access, and use. Any information that is stored on our server is
      treated as confidential information. Please keep in mind, however, that despite these reasonable efforts to
      protect information on our servers, no method of transmission over the Internet is guaranteed to be secure.
      Therefore, while we strive to protect your information at all times, we cannot guarantee its absolute security.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="5. Where do we keep your personal data?">
      We keep your personal data in our database which is held by Google Cloud, only assigned software developers which
      are our employees shall have the permission to edit/check the production environment, such permission is managed
      by Google Cloud Platform and Amazon Web Services. We, every time of updating tested our system to ensure the
      security of personal data protection{' '}
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="6. How long do we keep your personal data?">
      We may use our anonymous cookie information for advertising purposes for up to 14 months. For your personal data,
      we will keep your personal data until you require to opt- out or delete your personal data from our website.{' '}
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="7. What are your rights?">
      We follow generally accepted industry standards to protect against the unauthorized access to, retention of, and
      disclosure of information. This includes undertaking necessary physical, electronic, and management activities
      required to protect information integrity, access, and use. Any information that is stored on our server is
      treated as confidential information. Please keep in mind, however, that despite these reasonable efforts to
      protect information on our servers, no method of transmission over the Internet is guaranteed to be secure.
      Therefore, while we strive to protect your information at all times, we cannot guarantee its absolute security.
      <StyledComponents.PrivacyText>Your rights for your personal data are as follows:</StyledComponents.PrivacyText>
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Right to be informed;</b> You have the right to be provided with clear, transparent and easily
          understandable information about how we use your personal data and your rights. Therefore, we’re providing you
          with the information in this Privacy Policy of AnyCreator. We also will inform you if we collect your personal
          data for other purposes rather than what we described above.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Right to access/ right to rectify;</b> You always have the right to access, correct or update your personal
          data at any time. We understand the importance of this and should you want to exercise your rights, please
          contact us at{' '}
          <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
            info@anymindgroup.com
          </StyledComponents.Anchor>
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Right to data portability; </b> The personal data you have provided us with may be portable. This means it
          can be moved, copied or transmitted electronically under certain circumstances.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Right to object; </b> you have the right to object to certain types of processing, including processing for
          direct marketing (i.e., receiving emails from us notifying you or being contacted with varying potential
          opportunities).
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Right to erasure / right to be forgotten; </b>You have the right to request that we delete your data. If
          you wish to delete the personal data we hold about you, please let us know and we will take reasonable steps
          to respond to your request in accordance with legal requirements. If the personal data we collect is no longer
          needed for any purposes and we are not required by law to retain it, we will do what we can to delete, destroy
          or permanently de-identify it.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Right to restrict of processing; </b> you have the right to restrict the processing of your personal data.
        </StyledComponents.ListItem>

        <StyledComponents.ListItem>
          <b>Right to withdraw the consent; </b> you have the right to withdraw your consent that you gave to us anytime
          by contact us at{` `}
          <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
            info@anymindgroup.com
          </StyledComponents.Anchor>
          . Or{` `}
          <StyledComponents.Anchor href="mailto:dpo@anymindgroup.com" target="_blank">
            dpo@anymindgroup.com
          </StyledComponents.Anchor>
          , we will take reasonable steps to respond to your request in accordance with legal requirements.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="8. Children's Privacy">
      We are sensitive to the issue of children's privacy. Therefore, our websites are neither developed for, nor
      directed at, children. If you believe your child has provided us with PII, and you would like to have the
      information removed, please contact us at{` `}.
      <StyledComponents.Anchor href="mailto:dpo@anymindgroup.com" target="_blank">
        dpo@anymindgroup.com
      </StyledComponents.Anchor>
      .
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="9. Changes To This Privacy Policy">
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
      Policy on this page.You are advised to review this Privacy Policy periodically for any changes. Changes to this
      Privacy Policy are effective when they are posted on this page.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="10. How to contact us?">
      If you have any questions about this Privacy Policy, please contact us at 3 Anson Road, #20-03 Springleaf Tower,
      Singapore 079909 or via email at{` `}
      <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
        info@anymindgroup.com
      </StyledComponents.Anchor>
      , or{` `}
      <StyledComponents.Anchor href="mailto:dpo@anymindgroup.com" target="_blank">
        dpo@anymindgroup.com
      </StyledComponents.Anchor>
      .
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="Cookies Notice">
      By using our websites, you are consenting to our use of cookies in accordance with this Cookie Notice and our
      Privacy Policy of AnyCreator. If you do not agree to our use of cookies in this way, you should set your browser
      settings accordingly, disable the cookies that we use or not use our websites at all. If you disable the cookies
      we use, this may impact your user experience while on the websites.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="1. What is Cookies">
      Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to
      your browser from a web site and stored on your computer's hard drive.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="2. What purpose do we use cookies for?">
      We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a
      cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of
      AnyCreator Service.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="3. Can I delete cookies?">
      Yes you can delete cookies by refuse your consent, disable it by use of your browser setting or use our cookie
      management to disable cookies. However, by delete cookies, it may impact your user experience while on the
      websites.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="4. What Cookies do we use?">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b> Performance Analytics tracking :</b> This type of cookie provides aggregated information about where a
          user may go on the Website.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Google Analytics (persistent) :</b> AnyMind Group uses Google Analytics to help analyze how visitors use
          the Website. Among other things, Google Analytics uses cookies to collect information about the number of
          visits to the Website, the webpage that referred visitors to the Website, language, device, browser and
          operating system, the pages visitors view within the Website and other similar details. We do not share this
          information with third parties. When used this way the Google Analytics cookie is a persistent cookie, and
          will remain on your device until the cookie expires or you delete it. These cookies may appear as _utm, _utma,
          _utmb, _utmc, _utmz, _utmv, _utmt, _gid.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Google Analytics (session) :</b> This cookie may appear as has_js. We do not retain this data as it is
          deleted upon closing of your browser, and therefore we cannot share it with any third parties.{' '}
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Social Media :</b> These are cookies that help with the website’s functionality. These are third party
          cookies and they allow you to share comments, pages, bookmarks and likes and help to provide access to social
          networking tools easily.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Social Sharing :</b> Social media widgets used on this Website include LinkedIn, Twitter and YouTube.
          These social media widgets collect information about your visit to the Website and your use of the social
          platform services (for example, buttons or likes) on the Website. We do not share information with these
          social platforms, however please keep in mind the social networks collect the data of your site visit and
          interaction with the social widget directly.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b> Third-Party Services :</b> We may use cookies and pixels from third party providers – such as Google,
          Facebook and others – on our website to help provide more relevant experiences for you as a user. Such cookie
          or pixel may be implemented on a AnyMind Group company website which tags a visitor of the site, so that the
          visitor may be identified and advertisements can be served on that visitor via the relevant platforms.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>
  </StyledComponents.Wrapper>
);

export default PrivacyContentEN;
