import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { Select, SentenceForm, TextForm, YearMonthDayPicker } from '@src/components/shared';
import { css } from '@emotion/react';
import { useGetFanCountriesQuery, useGetFanProvincesQuery } from '@src/graphql/hooks';
import { getOptions } from '@src/libs/functions';
import { FanGender } from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  birthday: string;
  gender: string;
  countryId: string;
  province: string;
  city: string;
  postalCode: string;
  address1: string;
  address2: string;
  tags: string[];
  tagEntry: string;
}

interface FanDetaisFormProps {
  editable: boolean;
}

const FanDetailsForm = ({ editable }: FanDetaisFormProps) => {
  const { t } = useTranslation();
  const { data } = useGetFanCountriesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { isMobileView } = usePageLayout();
  const fanCountries = data?.getFanCountries?.countries || [];

  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<FormValues>();
  const [countryId, birthday, gender, province, tags, tagEntry] = watch([
    'countryId',
    'birthday',
    'gender',
    'province',
    'tags',
    'tagEntry',
  ]);

  const { data: dataFanProvinces, refetch } = useGetFanProvincesQuery({
    variables: {
      input: {
        countryId,
      },
    },
  });
  const provinceOptions =
    dataFanProvinces?.getFanProvinces?.provinces.map(item => ({ label: item.name, value: item.name })) || [];

  const countryOptions = useMemo(() => getOptions(fanCountries), [fanCountries.length]);

  const genderOptions = [
    { value: FanGender.FEMALE, label: 'Female' },
    { value: FanGender.MALE, label: 'Male' },
    { value: FanGender.OTHER, label: 'Other' },
  ];

  useEffect(() => {
    refetch({
      input: {
        countryId,
      },
    });
  }, [countryId]);

  const handleChangeCountry = (value: string) => {
    setValue('countryId', value);
    // Reset province after changing country
    if (countryId !== value) {
      setValue('province', '');
    }
  };

  const handleClickAddTag = () => {
    setValue('tags', [...tags, ...tagEntry.split(',').filter(item => !!item)]);
    setValue('tagEntry', '');
  };

  const handleClickDelete = (tag: string) => {
    if (editable) {
      setValue(
        'tags',
        tags.filter(item => item !== tag)
      );
    }
  };

  return (
    <div css={{ display: 'grid', justifyContent: 'center' }}>
      <div css={styles.container}>
        <FieldRow>
          <Label>{t('Name')}:</Label>
          <Col>
            <TextForm
              css={styles.textForm}
              isRequired={true}
              error={!!errors.firstName}
              placeholder=""
              disabled={!editable}
              {...register('firstName')}
            />
            <ErrorMessage message={errors.firstName?.message} />
          </Col>
          <Col>
            <TextForm
              css={styles.textForm}
              isRequired={true}
              error={!!errors.lastName}
              placeholder=""
              disabled={!editable}
              {...register('lastName')}
            />
            <ErrorMessage message={errors.lastName?.message} />
          </Col>
        </FieldRow>
        <FieldRow>
          <Label>{t('Email')}:</Label>
          <Col>
            <TextForm
              css={styles.textForm}
              error={!!errors.email}
              placeholder="anychat@anymindgroup.com"
              disabled={!editable}
              {...register('email')}
            />
            <ErrorMessage message={errors.email?.message} />
          </Col>
        </FieldRow>
        <FieldRow>
          <Label>{t('Phone Number')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!errors.contactNumber}
            placeholder="0123456789"
            disabled={!editable}
            {...register('contactNumber')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Birth day')}:</Label>
          <YearMonthDayPicker
            css={{ width: '50%' }}
            format="yyyy-MM-dd"
            error={!!errors.birthday}
            maxDate={new Date()}
            value={birthday || ''}
            onChange={value => setValue('birthday', value as string)}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Gender')}:</Label>
          <Select
            error={!!errors.gender}
            options={genderOptions}
            placeholder="Select Gender"
            value={gender || ''}
            onChange={value => setValue('gender', value)}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Country')}:</Label>
          <Select
            disabled={!editable}
            error={!!errors.countryId}
            options={countryOptions}
            placeholder="Select Country"
            value={countryId || ''}
            onChange={value => handleChangeCountry(value)}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Province')}:</Label>
          {/* Show TextFrom from for Shopify tagged fan */}
          {editable ? (
            <Select
              error={!!errors.province}
              options={provinceOptions}
              placeholder="Select Province"
              value={province || ''}
              onChange={value => setValue('province', value)}
            />
          ) : (
            <TextForm css={styles.textForm} placeholder="" disabled {...register('province')} />
          )}
        </FieldRow>
        <FieldRow>
          <Label>{t('City')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!errors.city}
            placeholder={t('City')}
            disabled={!editable}
            {...register('city')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Postal Code')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!errors.postalCode}
            placeholder="〒106-6131"
            disabled={!editable}
            {...register('postalCode')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Address 1')}:</Label>
          <SentenceForm
            css={styles.sentenceForm}
            error={!!errors.address1}
            placeholder="Address 1"
            disabled={!editable}
            {...register('address1')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Address 2')}:</Label>
          <SentenceForm
            css={styles.sentenceForm}
            error={!!errors.address2}
            placeholder="Address 2"
            disabled={!editable}
            {...register('address2')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Tags')}:</Label>
          <div css={styles.tagField}>
            <div css={styles.tagTextBoxWrapper}>
              <Col>
                <TextForm
                  css={styles.textForm}
                  error={!!errors.tagEntry}
                  placeholder={t('Tags')}
                  disabled={!editable}
                  {...register('tagEntry')}
                />
              </Col>
              <ThemeButton
                disabled={!tagEntry}
                customPalette={mainBlack}
                text="Add Tag"
                width="max-content"
                onClick={handleClickAddTag}
              />
            </div>
            <div css={styles.tagsContainer}>
              {tags
                .filter(item => item)
                .map((tag, index) => (
                  <p key={index + tag} css={styles.tag}>
                    {tag}
                    <Icomoon
                      css={[styles.icon, !editable && { cursor: 'default' }]}
                      icon="clear"
                      size={8}
                      onClick={() => handleClickDelete(tag)}
                    />
                  </p>
                ))}
            </div>
          </div>
        </FieldRow>

        {!editable && (
          <div css={styles.disableMessage}>
            ※{t('Annotation.this account is linked with Shopify, so you cannot edit information')}
          </div>
        )}

        {isMobileView && (
          <div css={[styles.submitBtnWrapper, styles.mobileSubmitWrapper]}>
            <ThemeButton disabled={isSubmitting} text="Save" theme="blue" type="submit" width="100%" />
          </div>
        )}
      </div>
      {!isMobileView && (
        <div css={styles.submitBtnWrapper}>
          <ThemeButton disabled={isSubmitting} text="Save" theme="blue" type="submit" width="max-content" />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css({
    maxWidth: 600,
    padding: 24,
    backgroundColor: THEME.colors.white,
  }),
  disableMessage: css({
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.normal,
  }),
  mobileSubmitWrapper: css({
    paddingRight: 0,

    input: {
      width: '100%',
    },
  }),
  tagBtn: css({
    width: 'fit-content',
    padding: '0 32px',
    lineHeight: '32px',
    borderRadius: 3,
    backgroundColor: THEME.colors.black.main,
  }),
  sentenceForm: css({
    flex: 1,

    textarea: {
      borderRadius: 3,
      height: 56,
      padding: '8px 12px',
    },
  }),
  submitBtnWrapper: css({
    height: 64,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid #eef3f7',
    paddingRight: 24,
  }),
  textForm: css({
    flex: 1,

    '& input': {
      borderRadius: 3,
      height: 32,
    },
  }),
  icon: css({
    color: THEME.font.colors.white,
    width: 16,
    cursor: 'pointer',
    filter: 'brightness(0.5)',

    '&:hover': {
      filter: 'brightness(0.7)',
    },
  }),
  marginRight8: css({
    marginRight: 8,
  }),
  tag: css({
    display: 'inline-flex',
    alignItems: 'center',
    width: 'max-content',
    backgroundColor: '#eef3f7',
    borderRadius: 3,
    height: 24,
    padding: '0 8px',
    color: THEME.font.colors.black.main,
    gap: THEME.box.gaps.xs,
  }),
  tagTextBoxWrapper: css({
    display: 'flex',
    flex: 1,
    gap: THEME.box.gaps.s,
  }),
  tagField: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  }),
  tagsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.s,
    marginTop: 12,
  }),
};

const FieldRow = styled.div({
  display: 'flex',
  marginBottom: 16,
  flex: 1,
  gap: THEME.box.gaps.s,
});

const Label = styled.p({
  fontSize: THEME.font.sizes.normal,
  color: THEME.font.colors.black.main,
  lineHeight: '32px',
  minWidth: 116,
});

const Col = styled.div({
  flex: 1,
});

export default FanDetailsForm;
