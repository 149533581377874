import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFanCountriesQuery } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFanCountries.graphql';

export const useGetFanCountriesQuery = (options?: QueryHookOptions<GetFanCountriesQuery>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFanCountriesQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFanCountriesLazyQuery = (options?: LazyQueryHookOptions<GetFanCountriesQuery>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFanCountriesQuery>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
