import { useApolloClient } from '@apollo/client';
import GENERATE_IMPORT_FANS_UPLOAD_URL from '@src/graphql/queries/GenerateImportFansUploadURL.graphql';
import { useImportFansMutation, useRemoveFansMutation } from '@src/graphql/hooks';
import { downloadFile } from '@src/libs/requests/downloadFile';
import { CRM_URL } from '@src/libs/requests';
import { useQueryHelper } from '@src/libs/hooks';
import { GenerateImportFansUploadURLQuery } from '@src/__generated__/globalTypes';
import axios from 'axios';

export default () => {
  const client = useApolloClient();
  const { t, enqueueSnackbar } = useQueryHelper();
  const [importFans] = useImportFansMutation();
  const [removeFans] = useRemoveFansMutation();

  const downloadSampleFanCSV = async () => {
    const url = new URL(`${CRM_URL}fans/template`);

    const { error } = await downloadFile({
      url,
      filenamePrefix: `manual-upload-template fans`,
    });

    if (error) {
      enqueueSnackbar(t('failedToDownload'), { variant: 'error' });
    }
  };

  const exportFanCSV = async (ids: Set<number>) => {
    const param = ids.size > 0 ? Array.from(ids).join(',') : '*';
    const url = new URL(`${CRM_URL}fans/export/?ids=${param}`);

    await downloadFile({ url, filenamePrefix: 'fans', extension: '.csv' });
  };

  const fetchFileUrl = async () => {
    const { data } = await client.query<GenerateImportFansUploadURLQuery>({
      query: GENERATE_IMPORT_FANS_UPLOAD_URL,
    });

    return data?.generateImportFansUploadURL || null;
  };

  const uploadSelectedFile = async (acceptedFile: File) => {
    const signedUrlResp = await fetchFileUrl();

    if (signedUrlResp) {
      uploadFileToGCSAndOurBackend(signedUrlResp.signedUrl, acceptedFile);
    }
  };

  const uploadFileToGCSAndOurBackend = async (signedUrl: string, accepted: File) => {
    try {
      await axios(signedUrl, {
        method: 'PUT',
        data: accepted,
      });
      const urlObjFromSignedUrl = new URL(signedUrl);
      // full path url without search
      const uploadFileUrl = urlObjFromSignedUrl.origin + urlObjFromSignedUrl.pathname;
      await importFans({
        variables: { input: { uploadFileUrl } },
      });
      enqueueSnackbar(t('Your CSV file was successfully uploaded'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t(error?.message || 'fileUploadFail'), { variant: 'error' });
    }
  };

  return {
    removeFans,
    downloadSampleFanCSV,
    exportFanCSV,
    uploadSelectedFile,
  };
};
