import { useState } from 'react';
import useUrl from './useUrl';

const useFilter = <T>(initialFilter?: T) => {
  const [filter, setCurrentFilter] = useState<Partial<T>>(initialFilter || {});
  const { updateSearchParams } = useUrl();

  const setFilter = (values: Partial<T>) => {
    setCurrentFilter(values);
    updateSearchParams({ ...values });
  };

  return {
    filter,
    setFilter,
  };
};

export default useFilter;
