import React from 'react';
import { useLocation, Navigate } from 'react-router';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { AuthSocialAccountType } from '@src/__generated__/globalTypes';
import { getConnectSocialAuthState, getRedirectPath } from '@src/libs/auth';
import { useQueryHelper } from '@src/libs/hooks';
import { InitialLoading } from '@src/components/atoms';
import { ROUTES } from '@src/shared/routes';

const RedirectTwitter = () => {
  const { search } = useLocation();
  const { t, enqueueSnackbar } = useQueryHelper();
  const response = search.substr(1);
  const {
    connectState: { redirectType },
  } = getConnectSocialAuthState();

  if (!redirectType) {
    enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
    if (window.opener) {
      window.close();
    }

    return <Navigate to={ROUTES.ROOT} />;
  }

  const redirectPath = getRedirectPath(AuthSocialAccountType.TWITTER, redirectType);
  const redirectUrl = `/${redirectPath}?${response}`;

  if (window.opener) {
    window.close();
    window.opener.postMessage({ redirectPath: redirectUrl }, '*');

    return <InitialLoading />;
  }

  return <Navigate to={redirectUrl} />;
};

export default RedirectTwitter;
