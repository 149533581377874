interface DoubleClickProps {
  singleClickCb?: () => void;
  doubleClickCb?: () => void;
}
// this is pretty naive realisation of double click handler, if any issues we can improve it based on useRef
const useDoubleClick = ({ singleClickCb, doubleClickCb }: DoubleClickProps) => {
  const delay: number = 200;
  const longDelay: number = 400;
  let timer: NodeJS.Timeout;
  let prevent = false;

  const onSingleClick = () => {
    timer = setTimeout(() => {
      if (!prevent) {
        // due to https://github.com/typescript-eslint/typescript-eslint/issues/1051
        // eslint-disable-next-line no-unused-expressions
        singleClickCb?.();
      }
    }, delay);
  };
  const onDoubleClick = () => {
    clearTimeout(timer);
    prevent = true;
    // eslint-disable-next-line no-unused-expressions
    doubleClickCb?.();

    setTimeout(() => {
      prevent = false;
    }, longDelay);
  };

  return { onSingleClick, onDoubleClick };
};

export default useDoubleClick;
