import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subWeeks,
  subYears,
} from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { DayModifiers } from 'react-day-picker';
import { SELECTED_RANGE } from './helpers';
import { DayPickerDate, DayPickerRange, Period } from './types';

interface UseRangeProps {
  setState?: Dispatch<SetStateAction<DayPickerRange>>;
  initialPeriod?: Period;
}
export const useRange = ({
  setState,
}: UseRangeProps): Record<string, () => { from: Date | number; to: Date | number }> => {
  const today = new Date();
  const lastDayOfPrevMonth = subDays(startOfMonth(today), 1);

  const handleClickToday = () => {
    setState?.({
      from: today,
      to: today,
      hoverTo: today,
      hoverFrom: today,
    });

    return {
      from: today,
      to: today,
    };
  };

  const handleClickYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);

    setState?.({
      from: date,
      hoverFrom: date,
      to: date,
      hoverTo: date,
    });

    return {
      from: date,
      to: date,
    };
  };

  const handleClickThisWeek = () => {
    const fistDayOdWeek = startOfWeek(today);

    setState?.({
      from: fistDayOdWeek,
      hoverFrom: fistDayOdWeek,
      to: today,
      hoverTo: today,
    });

    return {
      from: fistDayOdWeek,
      to: today,
    };
  };

  const handleClickLastSevenDays = () => {
    const sevenDaysAgo = subDays(today, 6);

    setState?.({
      from: sevenDaysAgo,
      hoverFrom: sevenDaysAgo,
      to: today,
      hoverTo: today,
    });

    return {
      from: sevenDaysAgo,
      to: today,
    };
  };

  const handleClickLastWeek = () => {
    const oneWeekAgo = subWeeks(today, 1);
    const startLastWeek = startOfWeek(oneWeekAgo);
    const endLastWeek = endOfWeek(oneWeekAgo);

    setState?.({
      from: startLastWeek,
      hoverFrom: startLastWeek,
      to: endLastWeek,
      hoverTo: endLastWeek,
    });

    return {
      from: startLastWeek,
      to: endLastWeek,
    };
  };

  const handleClickThisMonth = () => {
    const startThisMonth = startOfMonth(today);
    const endThisMonth = endOfMonth(today);

    setState?.({
      from: startThisMonth,
      hoverTo: endThisMonth,
      to: endThisMonth,
      hoverFrom: startThisMonth,
    });

    return {
      from: startThisMonth,
      to: endThisMonth,
    };
  };

  const handleClickLastMonth = () => {
    const from = startOfMonth(lastDayOfPrevMonth);
    const to = lastDayOfPrevMonth;

    setState?.({
      from,
      hoverTo: to,
      to,
      hoverFrom: from,
    });

    return {
      from,
      to,
    };
  };

  const handleClickLastTwoWeeks = () => {
    const from = subWeeks(today, 2);

    setState?.({
      from,
      hoverTo: today,
      to: today,
      hoverFrom: from,
    });

    return {
      from,
      to: today,
    };
  };

  const handleClickOneMonthAgo = () => {
    const from = subDays(today, 29);

    setState?.({
      from,
      hoverTo: today,
      to: today,
      hoverFrom: from,
    });

    return {
      from,
      to: today,
    };
  };

  const handleClickLastNinetyDays = () => {
    const from = subDays(today, 89);

    setState?.({
      from,
      hoverTo: today,
      to: today,
      hoverFrom: from,
    });

    return {
      from,
      to: today,
    };
  };

  const handleClickLastYearToRange = () => {
    const lastYearFromToday = subYears(today, 1);
    const from = startOfYear(lastYearFromToday);
    const to = endOfYear(lastYearFromToday);

    setState?.({ from, to, hoverFrom: from, hoverTo: to });

    return { from, to };
  };

  const handleClickStartWeekToRange = () => {
    const from = startOfWeek(today, {
      weekStartsOn: 1,
    });

    setState?.({ from, to: today, hoverFrom: from, hoverTo: today });

    return { from, to: today };
  };

  const handleClickStartMonthToRange = () => {
    const from = startOfMonth(today);

    setState?.({ from, to: today, hoverFrom: from, hoverTo: today });

    return { from, to: today };
  };

  const handleClickStartYearToRange = () => {
    const from = startOfYear(today);

    setState?.({ from, to: today, hoverFrom: from, hoverTo: today });

    return { from, to: today };
  };

  const handleClickCustom = () => {
    setState?.({ from: undefined, to: undefined, hoverFrom: undefined, hoverTo: undefined });

    return { from: 0, to: 0 };
  };

  return {
    [SELECTED_RANGE.TODAY]: handleClickToday,
    [SELECTED_RANGE.YESTERDAY]: handleClickYesterday,
    [SELECTED_RANGE.LAST_MONTH]: handleClickLastMonth,
    [SELECTED_RANGE.LAST_TWO_WEEKS]: handleClickLastTwoWeeks,
    [SELECTED_RANGE.THIS_WEEK]: handleClickThisWeek,
    [SELECTED_RANGE.LAST_SEVEN_DAYS]: handleClickLastSevenDays,
    [SELECTED_RANGE.LAST_WEEK]: handleClickLastWeek,
    [SELECTED_RANGE.TODAY_TO_MONTH_AGO]: handleClickOneMonthAgo,
    [SELECTED_RANGE.LAST_NINETY_DAYS]: handleClickLastNinetyDays,
    [SELECTED_RANGE.THIS_MONTH]: handleClickThisMonth,
    [SELECTED_RANGE.LAST_YEAR]: handleClickLastYearToRange,
    [SELECTED_RANGE.WEEK_TO_DATE]: handleClickStartWeekToRange,
    [SELECTED_RANGE.MONTH_TO_DATE]: handleClickStartMonthToRange,
    [SELECTED_RANGE.YEAR_TO_DATE]: handleClickStartYearToRange,
    [SELECTED_RANGE.CUSTOM]: handleClickCustom,
  };
};

export const useHandlers = (
  state: DayPickerRange,
  defaultInputState: DayPickerRange,
  setState: (range: DayPickerRange) => void
) => {
  const { from, to, hoverTo, hoverFrom } = state;

  const isSelectingFirstDay = () => {
    const isRangeSelected = Boolean(from && to && hoverFrom && hoverTo);

    return !from || isRangeSelected;
  };

  const handleResetClick = () => {
    setState(defaultInputState);
  };

  const handleDayClick = (day: DayPickerDate, modifiers: DayModifiers) => {
    const clickedDayProp = Object.keys(modifiers);
    const isDayDisabled = clickedDayProp.some(prop => prop.includes('disabled'));

    if (isDayDisabled) {
      return;
    }

    if (!!from && !!to) {
      handleResetClick();
    }

    const isFirstDay = isSelectingFirstDay();
    if (isFirstDay) {
      setState({
        from: day,
        to: undefined,
        hoverTo: undefined,
        hoverFrom: day,
      });
    } else {
      setState({ ...state, to: day, hoverTo: day });
    }
  };

  const handleDayMouseEnter = (day: DayPickerDate, modifiers: DayModifiers) => {
    const clickedDayProp = Object.keys(modifiers);
    const isDayDisabled = clickedDayProp.some(prop => prop.includes('disabled'));

    if (isDayDisabled) {
      return;
    }

    const isSelectFistDay = isSelectingFirstDay();
    // return if both are selected/unselected
    if ((!from && !to) || (!!from && !!to)) {
      return;
    }

    if (!isSelectFistDay) {
      setState({ ...state, to: undefined, hoverTo: day });
    } else {
      // if hovered_day is in the past
      setState({ from: undefined, hoverFrom: day, to: to || state.hoverFrom, hoverTo: to || state.hoverFrom });
    }
  };

  return {
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter,
  };
};
