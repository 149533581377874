import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface ListProps {
  title: string;
  href: string;
  icon?: ReactNode;
  className?: string;
}

const LinkWithIcon = (props: ListProps) => {
  const { title, href, icon, className } = props;

  return (
    <Link className={className} css={styles.listItem} to={href}>
      <span>{title}</span>
      <div css={styles.icon}>{icon}</div>
    </Link>
  );
};

const styles = {
  icon: css({
    color: '#ff2b52',
    fontSize: THEME.font.sizes.heading,
    marginLeft: 10,

    '&:hover': {
      textDecoration: 'none !important',
    },
  }),
  listItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px 15px 16px',
    borderTop: '1px solid #eef3f7',
    alignItems: 'center',

    '&:hover': {
      color: '#179cd7',
      textDecoration: 'underline',

      [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
        color: '#3b4146',
        textDecoration: 'none',
      },

      i: {
        opacity: 0.7,
      },
    },
  }),
};

export default LinkWithIcon;
