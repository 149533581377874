import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFormRespondentsCountQuery, GetFormRespondentsCountQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFormRespondentsCount.graphql';

export const useGetFormRespondentsCountQuery = (
  options?: QueryHookOptions<GetFormRespondentsCountQuery, GetFormRespondentsCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFormRespondentsCountQuery, GetFormRespondentsCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFormRespondentsCountLazyQuery = (
  options?: LazyQueryHookOptions<GetFormRespondentsCountQuery, GetFormRespondentsCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFormRespondentsCountQuery, GetFormRespondentsCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
