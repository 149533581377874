import { AnyXAuthSocialAccountType, SocialAccountType } from '../../../__generated__/globalTypes';

export const getSocialAccountType = (provider: string): SocialAccountType | null => {
  switch (provider) {
    case 'twitter':
      return SocialAccountType.TWITTER;
    case 'youtube':
      return SocialAccountType.YOUTUBE;
    case 'facebook':
      return SocialAccountType.FACEBOOK;
    case 'instagram':
      return SocialAccountType.INSTAGRAM;
    case 'tiktok':
      return SocialAccountType.TIKTOK;
    default:
      return null;
  }
};

/* currently provider for connect is incompatible with signUp/signIn, 
will change to AnyXAuthSocialAccountType when merge to anyX */
export const getAuthSocialAccountType = (provider: string): any => {
  switch (provider) {
    case 'twitter':
      return AnyXAuthSocialAccountType.TWITTER;
    case 'youtube':
      return AnyXAuthSocialAccountType.YOUTUBE;
    case 'facebook':
      return AnyXAuthSocialAccountType.FACEBOOK;
    case 'instagram':
      return AnyXAuthSocialAccountType.INSTAGRAM;
    case 'tiktok':
      return AnyXAuthSocialAccountType.TIKTOK;
    default:
      return null;
  }
};
