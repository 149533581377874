import React, { forwardRef, Ref } from 'react';
import { css } from '@emotion/react';
import Text, { TextProps } from '@src/components/atoms/Text';
import { THEME } from '@src/libs/theme';
import Label from '../Label';

interface TextFormProps extends TextProps {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  autoFocus?: boolean;
}

const TextForm = forwardRef((props: TextFormProps, ref: Ref<HTMLDivElement>) => {
  const { className, title, isRequired, help, placeholder, error, autoFocus, ...field } = props;

  return (
    <div className={className} ref={ref}>
      {title && (
        <Label css={styles.label} title={title ? `TextForm.${title}` : ''} isRequired={isRequired} help={help} />
      )}
      <Text placeholder={placeholder} error={error} {...field} autoFocus={autoFocus} />
    </div>
  );
});

const styles = {
  label: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  }),
};

TextForm.displayName = 'TextForm';

export default TextForm;
