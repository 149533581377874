import styled from '@emotion/styled';
import List from '@src/components/atoms/List';
import { THEME } from '@src/libs/theme';
import EmptyTable from './EmptyTable';

export const Wrapper = styled.section({
  margin: '4px 16px 0',
  padding: '0 12px',
  background: THEME.colors.white,
  border: THEME.box.borders.gray.main,
  borderRadius: 2,
  position: 'relative',
});

export const HeaderRow = styled.tr({
  borderBottom: '1px solid #efefef',
});

export const Row = styled.tr({
  borderTop: '1px solid #efefef',

  '&:hover': {
    background: '#f9f9f9',
  },
});

export const TextLinkCell = styled(List.TextLinkColumn)({
  [`${Row}:hover & > div:first-of-type a`]: {
    color: '#179cd7',
    textDecoration: 'underline',
  },
});

export const ThumbnailCell = styled(List.ThumbnailColumn)({
  padding: 8,

  [`${Row}:hover & p`]: {
    color: '#179cd7',
    textDecoration: 'underline',
  },
});

export default EmptyTable;
