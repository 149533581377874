import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as React from 'react';
import { TermsText, TermsTitle } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

const TermsContentEN = () => (
  <section css={styles.wrapper}>
    <TermsTitle title={`Terms and Conditions for using the AnyCreator Platform`} />
    <TermsText>
      AnyCreator Platform (hereinafter referred to as “Platform”) is a social influencer marketing service provided by
      AnyMind Group Pte. Ltd. and its subsidiaries (hereinafter referred to as “AnyMind”), which connects influencers
      and the clients of AnyMind (hereinafter referred to as “AnyCreator Service”). AnyMind has made every attempt to
      ensure the reliability and accuracy of information provided on this Platform but as such, this information is
      provided without warranty of any kind. AnyMind does not and will not accept liability or responsibility of any
      manner for the completeness, accuracy, content, legality, or reliability for the information on this Platform. The
      services on Platform as well as all information, products, content and other services included are made available
      to Influencer and Client on an “as is” basis, unless specified in writing. Influencer or Client agree that
      Influencer or Client uses the AnyCreator Service at Influencer’s or Client’s sole risk.
    </TermsText>
    <TermsText>
      By accessing, using and browsing this Platform, Influencer or Client acknowledge that Influencer or Client have
      read, understood and agreed to be bound by these Terms and Conditions for using the AnyCreator Platform
      (hereinafter referred to “Terms and Conditions, T&C, Terms”) set forth below, and to comply with all applicable
      laws, rules and regulations.
    </TermsText>
    <BreakTermsText subTitle={`1. TERMS AND DEFINITIONS`}>
      Client (s): the legal or natural person(s), or entity that is using AnyMind’s services.
    </BreakTermsText>
    <BreakTermsText>
      Influencer: the legal or natural person(s), or entity that is providing services to AnyMind and it’s Client
      through Platform.
    </BreakTermsText>
    <BreakTermsText>
      Platform: an online platform under the ownership of AnyMind which can be utilized by brands and entities to
      connect with social influencers to market the former’s product.
    </BreakTermsText>
    <BreakTermsText>
      AnyCreator Service: A social influencer marketing service Provided by AnyMind to connect influencers on the
      Platform and the Client.
    </BreakTermsText>
    <BreakTermsText>
      Live Campaign: a project uploaded by a Client of AnyMind which requires bidding by different influencers, who
      shall then propose a method of rolling out the campaign to be agreed upon by the Client. The Live Campaign will
      specify certain aspects in detail in addition to the Terms and Conditions which may include scope of services
      required, fees, payment details, and timeline for a certain Live Campaign.
    </BreakTermsText>

    <TermsText subTitle={`2. TERMS AND CONDITIONS`}>
      In consideration of the terms outlined in this Terms and Conditions, Influencer or Client and AnyMind agree to
      enter into Terms and Conditions whereby AnyMind shall provide access to Platform where Influencer can upload your
      workmanship and personal style to be on display to AnyMind’ Clients as well as bid for Clients’ Live Campaign.
      Clients of AnyMind will periodically upload Campaign onto the Platform to tender possibilities in finding an
      Influencer who would best fit the Live Campaign. Once the Client accepts Influencer, Influencer would be deemed to
      have accepted the terms and conditions of the Live Campaign and is therefore contracted for that Live Campaign.
    </TermsText>
    <TermsText>
      AnyMind provides access to AnyCreator Service subject to the general terms and conditions that are given below.
      Influencer or Client completely and unequivocally agree to be bound by the terms and conditions of the Terms and
      Conditions without modification. If Influencer or Client does not intend to be legally bound to the Terms and
      Conditions, Influencer or Client may not access or otherwise use the AnyCreator Service or Platform. AnyMind
      reserves the right, at any time, to update and change any or all of these Terms and Conditions, at its sole
      discretion.
    </TermsText>
    <TermsText>
      By using Platform, Influencer or Client acknowledges that Influencer or Client has read and understood all
      chapters, sub-chapters, provisions of the Terms and Conditions, and agrees to work under the Terms and Conditions
      and any terms and conditions of the Live Campaign as a case maybe.
    </TermsText>
    <TermsText>
      By using AnyCreator Service/Platform, Influencer or Client represents and warrants that Influencer or Client has
      the right, authority and capacity to enter into the Terms and Conditions. If Influencer or Client does not meet
      all of these requirements, Influencer or Client must not access or use AnyCreator Service.
    </TermsText>
    <TermsText>
      The Influencer or Client accepts these Terms and Conditions by executing these Terms and Conditions by
      electronically accepting these Terms and Conditions, and by accessing Platform thereafter.
    </TermsText>
    <TermsText>
      AnyMind makes no claims that AnyCreator Service/Platform or any of its contents is accessible or appropriate based
      on the domicile of Influencer. Access to AnyCreator Service/Platform may not be legal for certain persons or in
      certain countries. If Influencer accesses Platform, Influencer does so on its own initiative and as such, shall be
      responsible for compliance with local laws.
    </TermsText>

    <TermsText subTitle={`3. TRADEMARKS`}>
      AnyMind’s name, logo and all related names, logos, product and service names, designs and slogans including but
      not limited to AnyCreator are trademarks of AnyMind or its Affiliates. Influencer or Client must not use such
      marks without the prior written permission from AnyMind. All other names, logos, product and service names,
      designs and slogans on Platform are the trademarks of their respective owners.
    </TermsText>

    <TermsText subTitle={`4. SCOPE DEFINITION`}>
      The Services associated with these Terms and Conditions will be defined with a detailed statement of what is
      required from the Influencer in a particular Live Campaign. This will be uploaded on the Platform for Influencer
      to view and choose from. If Client accepts Influencer, it would be deemed that Influencer is now under a
      contractual obligation to finish the Live Campaign for that Client under the terms and conditions contained herein
      and subsequent parameters for a particular Live Campaign. Any such Live Campaign remains valid for the terms and
      conditions of that Live Campaign unless superseded, extended, or altered by mutual terms and conditions of both
      parties as a case maybe. Any such extension or alteration must be agreed in writing through email exchange for
      consent.
    </TermsText>

    <BreakTermsText subTitle={`5. FEES AND PAYMENT TERMS`}>
      Influencer will accumulate amounts from Influencer’s performance for Live Campaign which shall be redeemable after
      surpassing a threshold of amounts as defined by AnyMind for natural persons and amounts for juristic persons,
      respectively. The remuneration for Live Campaign to Influencer shall be specified in that Live Campaign. The
      Influencer must provide AnyMind with Influencer’s bank account information or other type of account information.
      The payment method may vary from jurisdiction to jurisdiction; please follow instructions on AnyMind’s local site
      for payment. Influencer’s performance will always be tracked but there may be situations where the budget of a
      certain live Campaign has been reached, in these cases Influencer will not be compensated for performance over the
      budget threshold.
    </BreakTermsText>
    <TermsText>
      Influencer agrees and acknowledges that the payable amount is at AnyMind’s sole discretion. Once the payment
      amounts are redeemable, it will be deposited into the Influencer’s bank account or other types of accounts which
      shall be specified by Influencer. Influencer will be paid according to AnyMind’s billing cycle. Deductions for
      withholding tax, any other levies, or other service charge (including bank charges) can be made to the final
      amount as may be required according to the taxation laws for natural and juristic persons of the jurisdiction the
      Influencer is domiciled in. For clarification, the transaction fee for Influencer situated in Japan shall be fixed
      at the amount of Japanese Yen 330 (including service fee) per transaction. For influencers who domicile in other
      countries, the transaction fee may vary depending on the transaction amount. The service charge must be whatever
      the vendor for such transfers charge in its regular business. Influencer is responsible for any personal income
      tax, any other taxes, tariffs or service charge that might be levied upon the Influencer’s income, or similar as
      per the applicable countries. AnyMind holds no responsibility in the non-performance of Influencer.
    </TermsText>

    <BreakTermsText subTitle={`6. ANYMIND RESPONSIBILITIES`}>
      6.1 AnyMind reserves the right to provide access, withdraw or amend the access to AnyCreator Service or Platform,
      and any service or material that AnyMind provides for AnyCreator Service or on Platform, at its sole discretion
      and without notice. AnyMind will not be liable if for any reason all or any part of AnyCreator Service is
      unavailable at any time or for any period. From time to time, AnyMind may restrict access to some parts of
      AnyCreator Service or Platform, or the entire AnyCreator Service or Platform.
    </BreakTermsText>
    <BreakTermsText>
      6.2 AnyMind may provide Live Campaign from Client on the Platform which shall include the following but not
      limited to, the scope of work required by the Client, the detailed statement of work to be performed by the
      Influencer, the fees associated with such statement of work, and/or any additional conditions agreed upon between
      the parties.
    </BreakTermsText>
    <TermsText>
      6.3 At its sole discretion, AnyMind has the right to:
      <ListWrapper>
        <ListItem>
          Remove or refuse to post any Influencer content or Client’s Live Campaign for any or no reason;
        </ListItem>
        <ListItem>
          AnyMind has the right to disable any user name, password or other identifier, whether chosen by Influencer,
          Client or provided by AnyMind, at any time at its sole discretion for any or no reason, including if, in
          AnyMind’s opinion, Influencer or Client has violated any clauses of the Terms and Conditions;
        </ListItem>
        <ListItem>
          Block, ban, deactivate, refrain or take any action to temporarily/permanently suspend Influencer’s account or
          Client’s account on Platform without further notice if AnyMind believes that Influencer and Client are
          directly engaging and/or are in contact in connection with the AnyCreator Service and purpose hereof without
          using Platform, or whereby Influencer’s content, Client’s Live Campaign or the use of Platform violates all or
          part thereof of the Terms and Conditions. AnyMind reserves the right to claim for a compensation from
          Influencer and Client for any damages resulting from such direct or indirect of the action that Influencer and
          Client direct engage;
        </ListItem>
        <ListItem>
          Take any action with respect to any Influencer’s content, Client’s Live Campaign that AnyMind deems necessary
          or appropriate, including if AnyMind believes that such Influencer’s content, Client’s Live Campaign violates
          the Terms and Conditions or violates any applicable laws, including the content standards, infringes any
          intellectual property right or other right of any person or entity, threatens the personal safety of users of
          Platform or the public or could create liability for AnyMind; and
        </ListItem>
        <ListItem>
          Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or
          unauthorized use of AnyCreator Service or Platform.
        </ListItem>
      </ListWrapper>
    </TermsText>
    <BreakTermsText>
      6.4 Without limiting the foregoing, AnyMind has the right to fully cooperate with any law enforcement authorities
      or court order requesting or directing the disclosure of the identity or other information of anyone posting any
      materials on or through Platform. Influencer or Client waives and indemnifies AnyMind and its affiliates,
      licensees and service providers from any claims resulting from any action taken by AnyMind any of the foregoing
      parties during or as a result of its investigations and from any actions taken as a consequence of investigations
      by either AnyMind or law enforcement authorities. However, AnyMind does not undertake to review material before it
      is posted on Platform, any social media channels or the internet, and therefore cannot ensure prompt removal of
      objectionable material after it has been posted. Accordingly, AnyMind assumes no liability for any action or
      inaction regarding transmissions, communications or contents provided by any user, Influencer, Client or third
      party. AnyMind has no liability or responsibility to anyone for performance or nonperformance of the activities
      described in this section either by AnyMind, Influencer or Client.
    </BreakTermsText>
    <BreakTermsText>
      6.5 AnyMind only agrees to provide AnyCreator Service to Influencer or Client who accept these Terms and
      Conditions and abide by all the terms and conditions provided herein.
    </BreakTermsText>
    <BreakTermsText subTitle={`7. INFLUENCER RESPONSIBILITIES`}>
      7.1 Influencer must provide certain registration details or other information as required by AnyMind to use
      certain features of AnyCreator Service or Platform. Influencer guarantees that the information of Influencer
      provided to AnyMind for the use of AnyCreator Service or Platform is correct, current, complete and up to date.
    </BreakTermsText>
    <BreakTermsText>
      7.2 Influencer must provide detailed services displaying personal style and original ideas to AnyMind for
      AnyCreator Service.
    </BreakTermsText>
    <BreakTermsText>
      7.3 If and when Influencer chooses to select a particular Live Campaign of Client on Platform, it shall be deemed
      that Influencer intends to take up said Live Campaign as an offer. When and if the Client accepts Influencer, it
      shall be deemed that the Influencer accepts all the terms and conditions of that particular Live Campaign without
      conditions, and Influencer shall be held responsible for completing the scope of work required within the timeline
      specified under such Live Campaign.
    </BreakTermsText>
    <BreakTermsText>
      7.4 Influencer shall adhere to the conditions of participating in AnyCreator Service or using Platform and the
      content standards set out by AnyMind in this Terms and Conditions.
    </BreakTermsText>
    <BreakTermsText>
      7.5 Influencer shall ensure that all persons who have access to Influencer’s account on Platform and access either
      through the Influencer’s internet or any network connection are aware of these Terms and Conditions and comply
      with them. Influencer shall be wholly responsible for access to Influencer’s own account. AnyMind shall not be
      held liable for any damages used by or on Influencer’s account.
    </BreakTermsText>
    <BreakTermsText>
      7.6 Influencer shall ensure that the material to be posted on Platform, any social media channels or the internet
      is reviewed and complies with all local laws and regulations.
    </BreakTermsText>
    <BreakTermsText>
      7.7 Influencer must comply with the timeline prescribed in the Live Campaign. There may be a penalty for late
      service imposed to Influencer, if services are not rendered as agreed in the details for a particular Live
      Campaign.
    </BreakTermsText>
    <TermsText>
      7.8 Influencer agrees to provide personal information to AnyMind for registration on Platform. This personal
      information is used in accordance with Privacy Policy, used to match Influencers with Client as well as for the
      management of such Live Campaign.
    </TermsText>

    <BreakTermsText subTitle={`8. CONFIDENTIAL INFORMATION`}>
      8.1 Influencer acknowledges that the Influencer’s account is personal to Influencer and agrees not to provide any
      other person with access to Platform or portions of it using Influencer’s username, password or other security
      information.
    </BreakTermsText>
    <BreakTermsText>
      8.2 Influencer also acknowledges that Influencer must treat all private information gathered from AnyCreator
      Service as confidential and Influencer must not disclose it to any other person or entity or third-party without
      the prior written consent from AnyMind.
    </BreakTermsText>
    <BreakTermsText>
      8.3 Influencer agrees to notify AnyMind immediately of any unauthorized access to or use of its username or
      password or any other breach of security. Influencer also agrees to ensure that the Influencer shall exit or sign
      out from the member account at the end of each session. Influencer should use particular caution when accessing
      the member account from a public or shared computer so that others are not able to view or record the password or
      other personal information.
    </BreakTermsText>
    <BreakTermsText>
      8.4 AnyMind shall not be liable for any loss or damage arising from the Influencer’s failure to comply with the
      above requirements.
    </BreakTermsText>
    <TermsText>
      8.5 AnyMind shall not be liable for any data privacy law violation arising from Influencer’s failure to comply
      with the above requirements.
    </TermsText>
    <TermsText>
      8.6 Client agrees and acknowledges that Client shall use confidential information of Influencer and AnyMind for
      specific purpose of Live Campaign only and shall not use any confidential information of Influencer or AnyMind for
      other purposes without the prior written of Influencer or AnyMind.
    </TermsText>
    <TermsText>
      8.7 AnyMind shall not be liable for any data privacy law violation arising from Client’s failure to comply with
      the above requirements.
    </TermsText>
    <TermsText subTitle={`9. INDEMNIFICATION`}>
      9.1 Influencer agrees to defend, indemnify and hold harmless AnyMind, its Affiliates, licensors and service
      providers, and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
      successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs,
      expenses or fees (including reasonable attorneys' fees) arising out of or relating to the Influencer’s violation
      of the Terms and Conditions or Influencer’s use of Platform, including, but not limited to Influencer’s content,
      any use of AnyCreator Service’s content, services and products other than as expressly authorized in the Terms and
      Conditions or Influencer’s use of any information obtained from AnyMind.
    </TermsText>
    <BreakTermsText>
      9.2 Client agrees to defend, indemnify and hold harmless AnyMind, its Affiliates, licensors and service providers,
      and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and
      assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees
      (including reasonable attorneys' fees) arising out of or relating to the Client’s violation of the Terms and
      Conditions or Client’s use of Platform, including, but not limited to Client’s Live Campaign’s scope, any use of
      AnyCreator Service’s content, services and products other than as expressly authorized in the Terms and Conditions
      or Client’s use of any information obtained from AnyMind.
    </BreakTermsText>
    <TermsText subTitle={`10. LIMITATION OF LIABILITY`}>
      AnyMind will not be liable for any damage incurred by Influencer, Client or third parties from the use of Platform
      or AnyCreator Service, unless such damage is attributable to the intentional or grossly negligent acts of AnyMind;
      provided, however, that in the case that AnyMind commits intentional or grossly negligent acts, AnyMind
      responsibility for the damages shall be limited to the fee paid to Influencer or the fee received from Client (as
      the case may be)
    </TermsText>

    <BreakTermsText subTitle={`11. TERMINATION`}>
      AnyMind has the right to terminate or suspend Influencer’s or Client’s access to all or part of AnyCreator Service
      or Platform for any or no reason, including without limitation, any violation of the Terms and Conditions. AnyMind
      will not have any liability whatsoever to Influencer or Client for any termination of the access, including for
      termination of the Influencer’s or Client’s member account or deletion of Influencer’s content or Client’s Live
      Campaign. AnyMind will not be liable to Influencer, Client or any third-party for termination of any Creator
      Service.
    </BreakTermsText>

    <BreakTermsText subTitle={`12. WAIVER AND SEVERABILITY`}>
      12.1 No omission or delay on AnyMind's part in exercising any or part of its rights under these Terms and
      Conditions shall operate as a waiver thereof.
    </BreakTermsText>
    <BreakTermsText>
      12.2 If any provision of these Terms and Conditions is held by a court or other tribunal of competent jurisdiction
      to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the
      minimum extent such that the remaining provisions of the Terms and Conditions will continue in full force and
      effect.
    </BreakTermsText>

    <BreakTermsText subTitle={`13. FORMAL COMMUNICATION`}>
      All formal communications between AnyMind and Influencers or AnyMind and Client shall be executed through the
      following electronic mail and/or app notification.
    </BreakTermsText>
    <BreakTermsText>
      Influencer and/or Client shall provide Influencer and/or Client electronic mail address or app inbox on
      Influencer’s and/or Client’s account on the Platform. This address is the only address where correspondence shall
      be sent from and received with regards to the contractual relationship between Influencer and AnyMind or Client
      and AnyMind.
    </BreakTermsText>
    <BreakTermsText>
      Electronic mail sent and received in the above address or app inbox shall be deemed to have been formally and
      properly received and posted. Influencer and/or Client cannot claim to not have received or not acknowledged a
      certain email or notification that has been sent or received in the address or inbox to be provided on the
      Influencer’s and/or Client’s account.
    </BreakTermsText>

    <BreakTermsText subTitle={`14. EXCLUSION OF ANTI-SOCIAL FORCES`}>
      Influencer, Client and AnyMind represent to each other that neither he/she/it/they nor his/her/its/their
      representatives, officers or other persons substantially involved in his/her/its/their management currently fall
      under an organized crime group, an organized crime group member, a quasi-member of an organized crime group, an
      enterprise associated with an organized crime group, a corporate racketeer (sokaiya), a social campaign advocate
      racketeer (shakai-undo-hyobo-goro), a political campaign racketeer (seiji-katsudo-hyobo-goro), an organized crime
      group with special intelligence, or a person having a close relationship with such entities or persons equivalent
      thereto, and affirm that he/she/it/they will not fall under any of the foregoing in the future.
    </BreakTermsText>
    <BreakTermsText>
      If Influencer, Client or AnyMind breaches the above representations, the other party may immediately cancel use of
      the Platform and Terms and Conditions relating to AnyCreator Service without warning.
    </BreakTermsText>

    <BreakTermsText subTitle={`15. FORCE MAJEURE`}>
      Except for payment obligations, neither Influencer nor AnyMind shall be liable for any failure to perform its
      obligations hereunder where such failure results from any performance beyond either Influencer’s or AnyMind’s
      reasonable control, including the elements; fire; flood; severe weather; earthquake; vandalism; accidents;
      sabotage; power failure; denial of service attacks or similar attacks; Internet failure; acts of God and the
      public enemy; acts of war; acts of terrorism; riots; civil or public disturbances; strikes, lock-outs, or labor
      disruptions; and any laws, orders, rules, regulations, acts, or restraints of any government or governmental body
      or authority, civil or military, including the orders and judgments of courts.
    </BreakTermsText>

    <BreakTermsText subTitle={`16. GOVERNING LAW AND JURISDICTION`}>
      These Terms and Conditions shall be governed by the laws and regulations of whichever jurisdiction is applicable
      based on the registered domicile of Influencer at the time of account registration.
    </BreakTermsText>

    <BreakTermsText subTitle={`17. SERVICES MAY USE YOUTUBE’S APPLICATION INTERFACE PROGRAM (API)`}>
      By using our Services, Influencer and/or Client is agreeing to be bound by (1) the&nbsp;
      <Anchor href="https://www.youtube.com/t/terms" target="_blank">
        YouTube Terms of Service
      </Anchor>
      &nbsp;and (2) the&nbsp;
      <Anchor href="http://www.google.com/policies/privacy" target="_blank">
        privacy policy
      </Anchor>
      . AnyMind collects influencer’s information such as subscribers and viewers and engagements by using YouTube API.
    </BreakTermsText>
    <TermsText>
      In addition to the AnyMind’s normal procedure for deleting stored data, Influencers can revoke that AnyMind’s
      access to their data at anytime via the Google security settings page at&nbsp;
      <Anchor href="https://security.google.com/settings/security/permissions" target="_blank">
        https://security.google.com/settings/security/permissions
      </Anchor>
      .
    </TermsText>

    <BreakTermsText subTitle={`18. Restricted Matters`}>
      AnyMind prohibits Influencer and Client from engaging in any of the following acts when using Platform or
      AnyCreator Service:
      <ListWrapper>
        <ListItem>
          Acts that violate the laws and regulations, court verdicts, resolutions or orders, or administrative measures
          that are legally binding;
        </ListItem>
        <ListItem>Acts that may be in violation of public order, morals or customs;</ListItem>
        <ListItem>
          Acts that infringe intellectual property rights, such as copyrights, trademarks and patent rights, rights to
          fame, privacy, and all other rights granted by law or by a contract with AnyMind and/or a third party;
        </ListItem>
        <ListItem>
          Acts of posting or transmitting excessively violent or explicit sexual expressions; expressions that amount to
          child pornography or child abuse; expressions that lead to discrimination by race, national origin, creed,
          gender, social status, family origin, etc.; expressions that induce or encourage suicide, self-injurious
          behavior or drug abuse; or expressions that include anti-social content and lead to the discomfort of others;
        </ListItem>
        <ListItem>
          Acts that lead to the misrepresentation of AnyMind and/or a third party or that intentionally spread false
          information;
        </ListItem>
        <ListItem>
          Acts of using Platform for sales, marketing, advertising, solicitation or other commercial purposes (except
          for those approved by AnyMind); using Platform for the purpose of sexual conduct or obscene acts; using
          Platform for the purpose of meeting or engaging in sexual encounters with an unknown third party; using
          Platform for the purpose of harassment or libelous attacks against others; or otherwise using Platform for
          purposes other than as intended by Platform;
        </ListItem>
        <ListItem>Acts that benefit or involve collaboration with anti-social groups;</ListItem>
        <ListItem>Acts that are related to religious activities or invitations to certain religious groups;</ListItem>
        <ListItem>
          Acts of unauthorized or improper collection, disclosure, or provision of any other person's personal
          information, registered information, user history, or the like;
        </ListItem>
        <ListItem>
          Acts of interfering with the servers and/or network systems of Platform; fraudulently manipulating Platform by
          means of bots, cheat tools, or other technical measures; deliberately using defects of Platform; making
          unreasonable inquires and/or undue claims such as repeatedly asking the same questions beyond what is
          necessary, and other acts of interfering with or hindering AnyMind's operation of Platform or others use of
          Platform;
        </ListItem>
        <ListItem>
          Acts of decoding the source code of Platform, such as by way of reverse engineering, disassembling or the
          like, for unreasonable purposes or in an unfair manner;
        </ListItem>
        <ListItem>Acts that aid or encourage any acts stated above; and</ListItem>
        <ListItem>Acts other than those set forth above that AnyMind reasonably deems to be inappropriate.</ListItem>
      </ListWrapper>
    </BreakTermsText>

    <BreakTermsText subTitle={`19. Legal Disclaimer`}>
      AnyCreator Service includes content provided by third parties, including materials provided by Influencer or
      Client, other users, bloggers and third party licensors, syndicators, aggregators and/or reporting services. All
      statements and/or opinions expressed in these materials, and all articles and responses to questions and other
      content, other than the content provided by AnyMind, are solely the opinions and the responsibility of the person
      or entity providing those materials. These materials do not necessarily reflect the opinion of AnyMind. AnyMind is
      not responsible, or liable to Influencer, Client or any third party, for the content or accuracy of any materials
      provided by any third parties.
    </BreakTermsText>
    <BreakTermsText>
      AnyMind shall provide no warranty, either expressly or impliedly, with respect to AnyCreator Service or Platform,
      that there are no defects (including, without limitation, faults with respect to security, etc., errors or bugs,
      or violations of rights) or as to the safety, reliability, accuracy, completeness, effectiveness, and fitness for
      a particular purpose. AnyMind will in no way be responsible for providing Influencer or Client with AnyCreator
      Service after deleting such defects.
    </BreakTermsText>
    <BreakTermsText>
      No warranties, promises, representations of any kind, whether expressed or implied, are given as to the accuracy
      or standard of the information provided. AnyMind cannot and will not guarantee that this platform is bereft of
      viruses, malware, or anything else that might have destructive qualities. Any relianceInfluencer places on
      information from the platform is strictly at the Influencer’s own risk. AnyMind disclaims all liability and
      responsibility arising from any reliance placed on such materials by the Influencer or any other visitor(s) to
      AnyCreator Service, or by anyone who may be informed of any of its contents.
    </BreakTermsText>
    <BreakTermsText>
      In no event will AnyMind, its affiliates or their licensors, service providers, employees, agents, officers or
      directors be liable for damages of any kind, arising out of or in connection with the use of, or inability to use,
      AnyCreator Service, Platform, any websites or social network service linked to it, any content on AnyCreator
      Service, Platform, or such other websites, or any services or items obtained through AnyCreator Service, including
      any indirect, special, incidental, consequential or punitive damages, including but not limited to, personal
      injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated
      savings, loss of use, loss of goodwill, loss of data, and whether AnyMindused by tort (including negligence),
      breach of contract or otherwise, even if foreseeable.
    </BreakTermsText>
    <BreakTermsText>
      Any misuse of action or claim the Influencer may have arising out of or relating to the Terms and Conditions or
      AnyMind must be commenced within one (1) year after the misuse of action accrues, otherwise, such misuse of action
      or claim is permanently barred.
    </BreakTermsText>
    <BreakTermsText>
      The foregoing does not affect any liability which cannot be excluded or limited under applicable law. If any
      provision of these Terms and Conditions are held to be illegal, inapplicable or unenforceable in any jurisdiction,
      only that clause shall be voided. The remainder of the contract shall still be appliAnyMindble and enforceable in
      the court of law.
    </BreakTermsText>
    <BreakTermsText>
      Certain countries’ laws do not allow limitations on implied warranties or the exclusion or limitation of certain
      kinds of damages. If these laws apply to you, some or all of the above disclaimer, limitations, or exclusions may
      not apply to you, and you might have additional rights.
    </BreakTermsText>
    <BreakTermsText>
      By visiting Platform, Influencer agrees that the laws of whichever jurisdiction is applicable based on the
      domicile of the Influencer, without regard to the principles of conflict of laws, will govern these Terms and
      Conditions and any dispute of any sort that might arise between Influencer and AnyMind.
    </BreakTermsText>

    <InSentenceTermsTitle title={`CONTENT STANDARDS AND PROHIBITED USES`} />
    <BreakTermsText>
      Any Influencer content the influencer posts will be considered non-confidential and non-proprietary. By providing
      any Influencer content on AnyCreator Service, the Influencer grants AnyMind and its Affiliates the right to use,
      reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material.
    </BreakTermsText>
    <BreakTermsText>Influencer represents and warrants that:</BreakTermsText>
    <BreakTermsText>
      1. Influencer owns or controls all rights in and to Influencer’s contents and has the right to grant permission
      for usage by AnyMind, Affiliates and respective licensees, successors and assignees.
    </BreakTermsText>
    <BreakTermsText>
      2. All Influencer’s contents does and will comply with the Terms and Conditions and appliAnyMindble laws.
    </BreakTermsText>
    <BreakTermsText>
      3. Influencer’s contents is, at all times, correct, current and complete, and up to date.
    </BreakTermsText>
    <BreakTermsText>
      4. Influencer shall have one account per person. Influencer can not hold multiple accounts.
    </BreakTermsText>
    <BreakTermsText>
      5. Influencer understands and acknowledges that Influencer has full responsibility of Influencer’s contents,
      including its legality, reliability, accuracy and appropriateness.
    </BreakTermsText>
    <BreakTermsText>
      6. AnyMind is not responsible, or liable to any third party, for the content or accuracy of any Influencer content
      posted by tInfluencer or any other user of AnyCreator Service.
    </BreakTermsText>
    <BreakTermsText>
      These content standards apply to any and all Influencer’s contents. The content must in its entirety comply with
      all laws and regulations. Without limiting the foregoing, Influencer content must not:
      <ListWrapper>
        <ListItem>
          Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful,
          inflammatory or otherwise objectionable;
        </ListItem>
        <ListItem>
          Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion,
          nationality, political leanings, disability, sexual orientation or age;
        </ListItem>
        <ListItem>
          Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any
          other person;
        </ListItem>
        <ListItem>
          Violate the legal rights (including the rights of publicity and privacy) of others or contain any material
          that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise
          may be in conflict with the Terms and Conditions; and
        </ListItem>
        <ListItem>Promote any illegal activity, or advocate, promote or assist any unlawful act.</ListItem>
      </ListWrapper>
    </BreakTermsText>
    <BreakTermsText>
      Influencer also acknowledges and accepts that:
      <ListWrapper>
        <ListItem>
          AnyMind may take measures that AnyMind considers necessary and appropriate, if AnyMind notices that the
          Influencer is using AnyCreator Service in a way which violates the Terms and Conditions;
        </ListItem>
        <ListItem>
          In the case where AnyMind has suffered loss/damage or has been charged an expense directly or indirectly due
          to the Influencer violating applicable laws or the Terms and Conditions while using AnyCreator Service,
          Influencer shall immediately compensate AnyMind upon its and/or others’ request without limitation.
        </ListItem>
      </ListWrapper>
    </BreakTermsText>
    <InSentenceTermsTitle title={`PROHIBITED USES OF PLATFORM`} />
    <BreakTermsText>
      Influencer and Client may use Platform only for lawful purposes and in accordance with the Terms and Conditions.
      Influencer and/or Client agrees not to use AnyCreator Service and Platform:
      <ListWrapper>
        <ListItem>In any way that violates any law or regulation.</ListItem>
        <ListItem>To directly solicit AnyMind’s Clients from AnyCreator Service;</ListItem>
        <ListItem>
          To send, knowingly receive, upload, download, use or re-use any material which does not comply with the
          content standards set out in the Terms and Conditions;
        </ListItem>
        <ListItem>
          To distribute, transmit, or procure the sending of, any advertising or promotional material, including any
          "junk mail", "chain letter" or "spam" or any other similar solicitation;
        </ListItem>
        <ListItem>
          To impersonate or attempt to impersonate AnyMind, a Company employee, another user or any other person or
          entity (including, without limitation, by using e-mail addresses or screen names, social media accounts,
          associated with any of the foregoing);
        </ListItem>
        <ListItem>
          To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of AnyMind, or which, as
          determined by us, may harm AnyMind or users of Platform or expose them to liability.
        </ListItem>
      </ListWrapper>
    </BreakTermsText>
    <BreakTermsText>
      Additionally, Influencer and/or Client agrees not to:
      <ListWrapper>
        <ListItem>
          Use Platform in any manner that could disable, overburden, damage, or impair the site or interfere with any
          other party's use of Platform, including their ability to engage in real time activities through Platform;
        </ListItem>
        <ListItem>
          Use any robots or devices, processes or means to access AnyCreator Service for any purpose, including
          monitoring or copying any of the material on AnyCreator Service;
        </ListItem>
        <ListItem>
          Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or
          technologically harmful;
        </ListItem>
        <ListItem>
          Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of AnyMind, the server on
          which Platform is stored, or any server, computer or database connected to Platform;
        </ListItem>
        <ListItem>Otherwise attempt to interfere with the proper working of Platform;</ListItem>
        <ListItem>
          Comply with all laws and regulations of the countryInfluencer is domiciled in, as well as the laws and
          regulations of where the Campaign is to go live. This responsibility rests with the influencer but the
          influencer may ask AnyMind for assistance when absolutely necessary. This, by no means, gives rise to
          AnyMind’s liability. On the contrary, AnyMind may choose to assist or not assist the Influencer. The
          responsibility rests solely with the Influencer.
        </ListItem>
      </ListWrapper>
    </BreakTermsText>
    <InSentenceTermsTitle title={`INTELLECTUAL PROPERTY RIGHTS`} />
    <BreakTermsText>
      AnyMind and its entire contents, features and functionality (including but not limited to all information,
      software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned
      by AnyMind, its licensors or other providers of such material and are protected by copyright, trademark, patent,
      trade secret and other intellectual property or proprietary rights laws.
    </BreakTermsText>
    <BreakTermsText>
      The Terms and Conditions permits Influencer or Client to use AnyCreator Service and Platform only for the purpose
      prescribed by AnyMind. Influencer must not reproduce, distribute, modify, copy, create derivative works of,
      publicly display, publicly perform, republish, download, store or transmit any of the material on Platform
      including, but not limited to:
    </BreakTermsText>
    <BreakTermsText>* Modifying copies of any materials from this site.</BreakTermsText>
    <BreakTermsText>
      * Using any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying
      text.
    </BreakTermsText>
    <BreakTermsText>
      * Deleting or alter any copyright, trademark or other proprietary rights notices from copies of materials from
      this site.
    </BreakTermsText>
    <BreakTermsText>
      If Influencer or Client prints, copies, modifies, downloads or otherwise uses or provides any other person with
      access to any part of Platform in breach of the Terms and Conditions, Influencer’s or Client’s right to use
      Platform will cease immediately and Influencer or Client must, at AnyMind’s option, return or destroy any copies
      of the materials that Influencer or Client has made.
    </BreakTermsText>
    <BreakTermsText>
      No right, title or interest in or to AnyMind or any content on Platform is transferred to Influencer or Client,
      and all rights not expressly granted are reserved by AnyMind.
    </BreakTermsText>
    <BreakTermsText>
      Any use of Platform not expressly permitted by Terms and Conditions is a breach of the Terms and Conditions and
      may violate copyright, trademark and other laws.
    </BreakTermsText>
  </section>
);

const Anchor = styled.a({
  display: 'inline',
  color: '#179cd7',

  '&:hover': {
    textDecoration: 'underline',
  },
});

const styles = {
  wrapper: css({
    boxShadow: THEME.box.shadows.outer,
    width: '100%',
    padding: 48,
    backgroundColor: THEME.colors.white,
    borderRadius: 3,
    boxSizing: 'border-box',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 0,
      padding: '24px 24px 16px',
    },
  }),
};

const BreakTermsText = styled(TermsText)({
  paddingBottom: 8,
});

const InSentenceTermsTitle = styled(TermsTitle)({
  padding: '40px 0 32px',
});

const ListWrapper = styled.ul({
  paddingLeft: 16,
});

const ListItem = styled.li({
  listStyle: 'outside',
});

export default TermsContentEN;
