import { format, isSameDay, Locale } from 'date-fns';
import { ja } from 'date-fns/locale';
import { RefObject } from 'react';
import { localizedDateFormatter } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { DayPickerDate, Entries, Period, RangeListOption, ValuesOf } from './types';
import { useRange } from './useDatepickerState';

export const dateSeparator = '\u00A0\u00A0\u2013\u00A0\u00A0';
export const placeholderRange = 'Select date';
export const placeholderSingle = 'Jan 01, 2020';
export const DAYPICKER_INPUT_FORMAT = 'MMM dd, yyyy';

export const SELECTED_RANGE = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This week',
  LAST_WEEK: 'Last week',
  LAST_SEVEN_DAYS: 'Last 7 days',
  LAST_TWO_WEEKS: 'Last two weeks',
  LAST_MONTH: 'Last month',
  THIS_MONTH: 'This month',
  TODAY_TO_MONTH_AGO: 'Last 30 days',
  LAST_NINETY_DAYS: 'Last 90 days',
  LAST_YEAR: 'Last year',
  WEEK_TO_DATE: 'Week to date',
  MONTH_TO_DATE: 'Month to date',
  YEAR_TO_DATE: 'Year to date',
  CUSTOM: 'Custom time',
} as const;

export const getDefaultDate = (date: string) => (date ? new Date(date) : undefined);

export const CloseOverlayAfterFocus = (inputRef: RefObject<any>) => {
  const activeElement = document.activeElement;
  const overlayContainer = document.getElementsByClassName('DayPickerInput-Overlay');

  if (overlayContainer && !overlayContainer[0].contains(activeElement)) {
    inputRef.current.hideAfterDayClick();
  }
};

interface GetSelectedRange {
  from: Date;
  to: Date;
  initialPeriod?: Period;
  rangeList?: RangeListOption[];
}
export const getSelectedRangeValue = ({ from, to, initialPeriod, rangeList }: GetSelectedRange) => {
  const fromFormat = from === null ? 0 : from;
  const toFormat = to === null ? 0 : to;

  const customValue = rangeList?.find(({ value }) => value === SELECTED_RANGE.CUSTOM);

  let resultValue: { value: ValuesOf<typeof SELECTED_RANGE>; label: string } = {
    value: SELECTED_RANGE.CUSTOM,
    label: customValue?.label || 'Custom time',
  };
  const rangesCallbacks = useRange({ initialPeriod });

  (Object.entries(rangesCallbacks) as Entries<typeof rangesCallbacks>).forEach(([rangeName, callback]) => {
    const selectedRange = callback();

    if (isSameDay(selectedRange.from || 0, fromFormat) && isSameDay(selectedRange.to || 0, toFormat)) {
      const rangeValue = rangeList?.find(({ value }) => value === rangeName);
      if (rangeValue) {
        resultValue = { value: rangeValue.value, label: rangeValue.label || rangeValue.value };
      }
    }
  });

  return resultValue;
};

export const formatCaption = (date: Date, options: { locale?: Locale } | undefined) => {
  const isJPLanguage = options?.locale === ja;

  return format(date, isJPLanguage ? 'yoMMM' : 'MMMM yyyy', { locale: options?.locale });
};

export const getInputValue = (startDate: DayPickerDate, endDate: DayPickerDate, language: AppLanguage) =>
  (startDate ? localizedDateFormatter(startDate, 'PPP', language) : '') +
  (endDate && endDate.getTime() !== startDate?.getTime()
    ? `${dateSeparator}${localizedDateFormatter(endDate, 'PPP', language)}`
    : '');
