import React, { ComponentProps, ElementType } from 'react';
import { css } from '@emotion/react';

const defaultElement = 'div';
interface CutterOwnProps<T extends ElementType = ElementType> {
  text: string | null;
  lines?: number;
  className?: string;
  onClick?: () => void;
  as?: T;
}
type TextCutterProps<T extends ElementType> = CutterOwnProps<T> & Omit<ComponentProps<T>, keyof CutterOwnProps>;

/**
 * @description Polymorphic component for text cutter, use `as` prop to change the element type
 */
const TextCutter = <T extends ElementType = typeof defaultElement>({ className, as, ...rest }: TextCutterProps<T>) => {
  const Component = (as || defaultElement) as ElementType;

  return (
    <Component {...rest} className={className} css={[styles.root, { WebkitLineClamp: rest?.lines || 2 }]}>
      {rest?.text || ''}
    </Component>
  );
};

const styles = {
  root: css({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    height: 'fit-content',
  }),
};

export default TextCutter;
