import i18next from 'i18next';
import { APP_LANGUAGES } from '../i18n/languageDetector/utils';

export default () => {
  const jiptLanguageName = localStorage.getItem('jipt_language_name_anytag'); // getting app language from injected script (Crowdin in-context translation), we need this to properly support RTL for this case
  const isRtl =
    [APP_LANGUAGES.ar as string].includes(i18next.language) ||
    (jiptLanguageName && jiptLanguageName === 'Arabic') ||
    false;

  return {
    dir: (isRtl ? 'rtl' : 'ltr') as 'rtl' | 'ltr',
    isRtl,
  };
};
