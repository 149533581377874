import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  CreateMarketplaceDraftPostMutation,
  CreateMarketplaceDraftPostMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/CreateMarketplaceDraftPost.graphql';

export const useCreateMarketplaceDraftPostMutation = (
  options?: MutationHookOptions<CreateMarketplaceDraftPostMutation, CreateMarketplaceDraftPostMutationVariables>
) => useMutation<CreateMarketplaceDraftPostMutation, CreateMarketplaceDraftPostMutationVariables>(MUTATION, options);
