import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, TypeOptions } from 'react-toastify';
import { customIcons, ToastWrapper } from '@src/components/organisms/Notification';

interface ToastProps {
  variant: TypeOptions;
}

const useQueryHelper = <T extends Record<string, string | undefined>>() => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<T>();
  const { i18n, t } = useTranslation();

  const enqueueSnackbar = useCallback((message: string, props: ToastProps) => {
    const { icon, header } = customIcons[props.variant];

    return toast(() => ToastWrapper({ header: header ? t(header) : '', message }), {
      theme: 'light',
      type: props.variant,
      icon,
    });
  }, []);

  return {
    ...location,
    i18n,
    params,
    enqueueSnackbar,
    matchPath,
    navigate,
    t,
  };
};

export default useQueryHelper;
