import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { formatIntNumber } from '@src/libs/format';
import { THEME } from '@src/libs/theme';

interface PageCountProps {
  className?: string;
  first?: number;
  last?: number;
  total: number;
  countLabel: string;
}

const PageCount = (props: PageCountProps) => {
  const { className, total, countLabel } = props;
  const { t } = useTranslation();

  if (total === 0) {
    return null;
  }

  return (
    <p className={className} css={styles.stats}>
      <span css={styles.value}>{formatIntNumber(total)}</span> {t(`Label.${countLabel} found`)}
    </p>
  );
};

const styles = {
  stats: css({
    color: THEME.font.colors.black.main,
    fontSize: 13,
    alignItems: 'baseline',
  }),
  value: css({
    lineHeight: '18px',
    fontWeight: 600,
    marginRight: 4,
  }),
};

export default PageCount;
