import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetEmailListCountQuery, GetEmailListCountQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetEmailListCount.graphql';

export const useGetEmailListCountQuery = (
  options?: QueryHookOptions<GetEmailListCountQuery, GetEmailListCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetEmailListCountQuery, GetEmailListCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetEmailListCountLazyQuery = (
  options?: LazyQueryHookOptions<GetEmailListCountQuery, GetEmailListCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetEmailListCountQuery, GetEmailListCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
