import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFanHeaderQuery, GetFanHeaderQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFanHeader.graphql';

export const useGetFanHeaderQuery = (options?: QueryHookOptions<GetFanHeaderQuery, GetFanHeaderQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFanHeaderQuery, GetFanHeaderQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFanHeaderLazyQuery = (
  options?: LazyQueryHookOptions<GetFanHeaderQuery, GetFanHeaderQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFanHeaderQuery, GetFanHeaderQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
