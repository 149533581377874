import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFanProvincesQuery, GetFanProvincesQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFanProvinces.graphql';

export const useGetFanProvincesQuery = (
  options?: QueryHookOptions<GetFanProvincesQuery, GetFanProvincesQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFanProvincesQuery, GetFanProvincesQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFanProvincesLazyQuery = (
  options?: LazyQueryHookOptions<GetFanProvincesQuery, GetFanProvincesQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFanProvincesQuery, GetFanProvincesQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
