import React from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { ErrorMessage } from '@src/components/atoms';
import { Label, TextForm } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { FormInformation } from './helpers';
import { DragAndDrop } from './StyledComponents';

interface RichMessageInputProps {
  isDisabled?: boolean;
}

const RichMessageInput = ({ isDisabled }: RichMessageInputProps) => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [lineContent, selectedBlockIndex] = watch(['lineContent', 'selectedBlockIndex']);

  const richMessageContent = lineContent[selectedBlockIndex];
  const isError = !!errors.lineContent && !richMessageContent.richMessage?.imageUrl;

  const onChangeRichMessage = (richMessage: { imageUrl: string; url?: string }) => {
    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...richMessageContent,
      richMessage,
    };
    setValue('lineContent', items);
  };

  return (
    <div css={styles.container}>
      <Label css={styles.label} isRequired title="Title.Image" />
      <DragAndDrop
        disabled={isDisabled}
        error={isError}
        imageUrl={richMessageContent.richMessage?.imageUrl || ''}
        onChange={imageUrl => onChangeRichMessage({ ...richMessageContent.richMessage, imageUrl })}
      />
      {isError && <ErrorMessage message={'requiredFieldMessage'} />}

      <div css={{ marginTop: 24 }}>
        <TextForm
          disabled={isDisabled}
          placeholder="http://anymindgroup@gmail.com"
          title="URL"
          value={richMessageContent.richMessage?.url || ''}
          onChange={e => onChangeRichMessage({ ...richMessageContent.richMessage!, url: e.target.value })}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    padding: 24,
  }),
  label: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  }),
};

export default RichMessageInput;
