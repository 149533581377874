import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { InfluencerProfileV2Query, InfluencerProfileV2QueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerProfileV2.graphql';

export const useInfluencerProfileV2Query = (
  options?: QueryHookOptions<InfluencerProfileV2Query, InfluencerProfileV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerProfileV2Query, InfluencerProfileV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerProfileV2LazyQuery = (
  options?: LazyQueryHookOptions<InfluencerProfileV2Query, InfluencerProfileV2QueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerProfileV2Query, InfluencerProfileV2QueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
