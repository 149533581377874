import { format } from 'date-fns';
import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import iphoneSimulator from '@src/assets/img/iphoneSimulator.png';
import Icomoon from '@src/components/atoms/Icomoon';
import { THEME } from '@src/libs/theme';

interface MobilePreviewerProps {
  children: ReactNode;
}

const MobilePreviewer = ({ children }: MobilePreviewerProps) => {
  const currDate = new Date();
  const time = format(currDate, 'HH:mm');

  return (
    <div css={styles.container}>
      <div>
        <div>{time}</div>
        <div>
          {['line-signal', 'wifi-signal', 'battery'].map(icon => (
            <Icomoon color="#27313b" icon={icon} key={icon} />
          ))}
        </div>
      </div>
      <div>{children}</div>
      <div>
        {['arrow-down', 'arrow-down', 'safari-share', 'safari-bookmark', 'copy-outlined'].map((icon, index) => {
          const isForward = index === 1;

          return (
            <div key={index}>
              <Icomoon
                color={isForward ? '#bababb' : '#27313b'}
                css={icon === 'arrow-down' && { transform: isForward ? 'rotate(270deg)' : 'rotate(90deg)' }}
                icon={icon}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    '--quotient': 'calc(min(95vh, 844px) / 844)',
    '--height': 'calc(844 * var(--quotient))',
    '--paddingX': 'calc(28 * var(--quotient))',
    '--paddingY': 'calc(56 * var(--quotient))',
    '--width': 'calc(390 * var(--quotient))',

    background: THEME.colors.white,
    backgroundImage: `url(${iphoneSimulator})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'var(--width) var(--height)',
    borderRadius: 50,
    height: 'var(--height)',
    overflow: 'hidden',
    padding: '0 var(--paddingX)',
    position: 'relative',
    width: 'calc(var(--width) - calc(56 * var(--quotient)))',

    '& > div:nth-of-type(1)': {
      alignItems: 'flex-end',
      display: 'flex',
      height: 'calc(var(--paddingY) - 6px)',
      padding: '3px 0',

      '[dir="rtl"] &': {
        direction: 'ltr',
      },

      '& > div:nth-of-type(1)': {
        fontSize: 'calc(14 * var(--quotient))',
        fontWeight: 600,
        padding: '0 calc(16 * var(--quotient))',
        width: 'fill-available',
      },

      '& > div:nth-of-type(2)': {
        alignItems: 'center',
        display: 'flex',
        gap: THEME.box.gaps.xs,
        padding: '0 calc(16 * var(--quotient))',

        '& > svg': {
          height: 'calc(18 * var(--quotient)) !important',
          width: 'calc(18 * var(--quotient)) !important',
        },
      },
    },

    '& > div:nth-of-type(2)': {
      background: '#f7f8fa',
      height: 'calc(var(--height) / 0.905)',
      overflowY: 'auto',
      zoom: 0.75,
    },

    '& > div:nth-of-type(3)': {
      background: '#f5f5f5',
      borderRadius: '0 0 34px 34px',
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto auto',
      height: 'calc(var(--paddingY) * 1.2)',

      '[dir="rtl"] &': {
        direction: 'ltr',
      },

      '& > div': {
        alignItems: 'center',
        display: 'grid',
        justifyContent: 'center',

        '& > svg': {
          height: 'calc(18 * var(--quotient)) !important',
          width: 'calc(18 * var(--quotient)) !important',
        },
      },
    },
  }),
};

export default MobilePreviewer;
