import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFormQuery, GetFormQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetForm.graphql';

export const useGetFormQuery = (options?: QueryHookOptions<GetFormQuery, GetFormQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFormQuery, GetFormQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFormLazyQuery = (options?: LazyQueryHookOptions<GetFormQuery, GetFormQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFormQuery, GetFormQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
