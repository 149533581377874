import React, { useEffect, useRef, useCallback, useState } from 'react';
import ScrollToBottom, { useAtTop, useAtBottom, useScrollToBottom, useSticky } from 'react-scroll-to-bottom';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { GetChatEventsQuery } from '@src/__generated__/globalTypes';
import { ChatBubbles } from './ChatBubbles';
import { formatTimestampToDisplayDatetime } from './helpers';

type GetChatEvents = NonNullable<GetChatEventsQuery['getChatEvents']>;

interface ConversationAreaProps {
  channelUserName?: string;
  scrollHeight?: number;
  chatEvents: GetChatEvents['chatEvents'];
  fetchOlder: () => Promise<void>;
}

const ConversationArea = ({ channelUserName, chatEvents, fetchOlder, scrollHeight }: ConversationAreaProps) => (
  <ScrollToBottom
    followButtonClassName="scrollToBottomButton"
    scrollViewClassName="scrollViewContainer"
    initialScrollBehavior="auto"
    css={styles.scollToBottomContainer}
  >
    <Content
      channelUserName={channelUserName}
      chatEvents={chatEvents}
      fetchOlder={fetchOlder}
      scrollHeight={scrollHeight}
    />
  </ScrollToBottom>
);

const Content = ({ scrollHeight, chatEvents, channelUserName, fetchOlder }: ConversationAreaProps) => {
  const scrollViewContainerRef = useRef<HTMLElement | null>(null);
  const prevScrollHeightRef = useRef(0);
  const [hasInit, setHasInit] = useState(false);

  const [isAtTop] = useAtTop();
  const [isAtBottom] = useAtBottom();
  const scrollToBottom = useScrollToBottom();
  const [sticky] = useSticky();

  // Init
  useEffect(() => {
    const scrollViewContainer: HTMLElement | null = document.querySelector('.scrollViewContainer');

    if (scrollViewContainer) {
      scrollViewContainerRef.current = scrollViewContainer;

      // reset height
      scrollViewContainer.style.height = `${scrollHeight}px`;
      // means we are at top
      if (hasInit) {
        prevScrollHeightRef.current = scrollViewContainer.scrollHeight;
      }
    }
  });

  useEffect(() => {
    if (scrollHeight !== 0 && !isAtTop && isAtBottom) {
      setHasInit(true);
    }
  }, [scrollHeight, isAtBottom, isAtTop]);

  return (
    <div>
      {!sticky && (
        <div css={styles.scrollToBottomIcon} onClick={scrollToBottom}>
          <Icomoon icon="arrow-down" size={10} />
        </div>
      )}
      <Template hasInit={hasInit} chatEvents={chatEvents} channelUserName={channelUserName} fetchOlder={fetchOlder} />
    </div>
  );
};

const Template = ({
  channelUserName,
  chatEvents,
  fetchOlder,
  hasInit,
}: ConversationAreaProps & { hasInit: boolean }) => {
  const scrollViewContainerRef = useRef<HTMLElement | null>(null);
  const prevScrollHeightRef = useRef(0);
  const triggerObserverRef = useRef<IntersectionObserver | null>(null);
  const dateStrRef = useRef('');
  const isFetchingOlderRef = useRef(false);

  const triggerFetchOlderItemRef = useCallback(
    (node: HTMLDivElement) => {
      // if scroll reached the top (triggerRef got intersected)
      if (triggerObserverRef.current) {
        isFetchingOlderRef.current = false;
        // eslint-disable-next-line no-unused-expressions
        triggerObserverRef.current?.disconnect();
        // get next scroll position after get more items (otherwise it will trigger intersection and fetch again)
        if (scrollViewContainerRef.current && scrollViewContainerRef.current.scrollTop < 100) {
          const nextPosition = scrollViewContainerRef.current.scrollHeight - prevScrollHeightRef.current;

          scrollViewContainerRef.current.scrollTop = nextPosition;
        }
      }

      triggerObserverRef.current = new IntersectionObserver(
        entries => {
          if (entries[0].intersectionRatio >= 0.8 && hasInit) {
            if (!isFetchingOlderRef.current) {
              fetchOlder();
            }
            isFetchingOlderRef.current = true;
          }
        },
        {
          threshold: 0.8,
        }
      );

      if (node) {
        triggerObserverRef.current.observe(node);
      }
    },
    [fetchOlder, hasInit]
  );

  useEffect(() => {
    const scrollViewContainer: HTMLElement | null = document.querySelector('.scrollViewContainer');

    if (!scrollViewContainer) {
      return;
    }
    // means we are at the top of message container
    if (!hasInit) {
      scrollViewContainerRef.current = scrollViewContainer;
      prevScrollHeightRef.current = scrollViewContainer.scrollHeight;
    }
  }, [hasInit]);

  useEffect(() => {
    if (!scrollViewContainerRef.current) {
      return;
    }
    // save prev position scroll to use in nextPosition calculation
    prevScrollHeightRef.current = scrollViewContainerRef.current.scrollHeight;
  });

  return (
    <>
      {chatEvents.map(({ __typename, timestamp, ...rest }, i) => {
        const datetimeArr = formatTimestampToDisplayDatetime(Number(timestamp) * 1000).split(' '); // unix timestamp is a string due to schema issue with "Long" type
        const dateStr = datetimeArr[0];
        const timeStr = datetimeArr[1];

        const shouldRenderDateStr = dateStr !== dateStrRef.current || i === 0;
        dateStrRef.current = dateStr;

        return (
          <div key={`${timestamp}_${i}`} ref={i === 0 ? triggerFetchOlderItemRef : undefined}>
            {__typename && (
              <div css={styles.messageSection}>
                {shouldRenderDateStr && (
                  <div css={styles.dateSeparator} key={dateStr}>
                    <hr css={styles.dateHr} />
                    <span css={styles.date}>{dateStr}</span>
                    <hr css={styles.dateHr} />
                  </div>
                )}
                <ChatBubbles channelUserName={channelUserName} timeStr={timeStr} typename={__typename} {...rest} />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ConversationArea;

const styles = {
  messageSection: css({
    margin: '0 24px',
  }),
  dateSeparator: css({
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    paddingTop: 52,
    alignItems: 'center',
  }),
  date: css({
    padding: '0 4px',
    fontWeight: 600,
    fontSize: THEME.font.sizes.hint,
    color: THEME.font.colors.gray.main,
  }),
  dateHr: css({
    width: '100%',
    height: 1,
    background: '#dee5ec',
  }),
  scollToBottomContainer: css({
    '& .scrollToBottomButton': {
      display: 'none',
    },
  }),
  scrollToBottomIcon: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: '50%',
    bottom: 12,
    cursor: 'pointer',
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    position: 'absolute',
    right: 12,
    width: 32,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 40,
      width: 40,
    },

    '& > img': {
      width: 16,
      height: 16,
    },
  }),
};
