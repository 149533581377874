import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useDoubleClick from '@src/libs/hooks/useDoubleClick';
import { arrowDropDown } from '@src/assets/htmlCodes';
import { THEME } from '@src/libs/theme';
import TextCutter from '../TextCutter';
// beware of cyclic dependencies
import Tooltip from '../../molecules/Tooltip';

type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';

interface HeaderColumnProps {
  className?: string;
  title: string;
  colSpan?: number;
  activeAsc?: boolean;
  activeDesc?: boolean;
  sortAsc?: () => void;
  sortDesc?: () => void;
  textAlign?: TextAlign;
  help?: string;
  isTable?: boolean;
  handleSingleClick?: () => void;
  handleDoubleClick?: () => void;
  children?: React.ReactNode;
  justifyContent?: 'flex-end' | 'flex-start' | 'center';
}

const HeaderColumn = (props: HeaderColumnProps) => {
  const { t } = useTranslation();
  const {
    className,
    title,
    colSpan,
    activeAsc,
    activeDesc,
    sortAsc,
    sortDesc,
    textAlign,
    help,
    isTable = true,
    handleDoubleClick,
    handleSingleClick,
    children,
  } = props;
  const { onSingleClick, onDoubleClick } = useDoubleClick({
    singleClickCb: handleSingleClick,
    doubleClickCb: handleDoubleClick,
  });

  const translatedTitle = title ? t(`HeaderColumn.${title}`) : '';
  const Wrapper = isTable ? 'th' : 'div';
  const hasClickSorting = !!(handleSingleClick && handleDoubleClick);

  return (
    <Wrapper className={className} colSpan={colSpan} css={styles.wrapper(textAlign)}>
      {!!sortAsc && !!sortDesc ? (
        <FlexWrapper>
          {help && <Tooltip help={help} placement="top" />}
          <span
            onClick={onSingleClick}
            onDoubleClick={onDoubleClick}
            css={{ cursor: hasClickSorting ? 'pointer' : 'initial' }}
          >
            {translatedTitle}
          </span>
          <div css={styles.sortWrapper}>
            <span css={{ color: activeAsc ? '#3892e5' : '#9b9b9b', transform: 'rotate(180deg);' }} onClick={sortAsc}>
              {arrowDropDown}
            </span>
            <span css={[{ color: activeDesc ? '#3892e5' : '#9b9b9b' }]} onClick={sortDesc}>
              {arrowDropDown}
            </span>
          </div>
        </FlexWrapper>
      ) : (
        <FlexWrapper>
          {help ? (
            <FullSizeAligned textAlign={textAlign}>
              <Tooltip help={help} placement="top" />
              {translatedTitle}
            </FullSizeAligned>
          ) : (
            <TextCutter css={{ width: '100%' }} text={translatedTitle} lines={1} />
          )}
        </FlexWrapper>
      )}
      {children}
    </Wrapper>
  );
};

const FlexWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const FullSizeAligned = styled.div<{ textAlign?: string }>(({ textAlign }) => ({
  display: 'inline-flex',
  justifyContent: textAlign
    ? textAlign === 'left'
      ? 'flex-start'
      : textAlign === 'right'
      ? 'flex-end'
      : 'center'
    : 'flex-start',
  alignItems: 'center',
  width: '100%',
}));

const styles = {
  sortWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 2,
    margin: 'auto 5px',
    lineHeight: 'initial',

    '& > span': {
      height: 16,
      width: 16,
      textAlign: 'center',
      fontSize: 13,
      cursor: 'pointer',

      '&:hover': {
        filter: 'brightness(0.8)',
      },
    },
  }),
  wrapper: (textAlign?: TextAlign) =>
    css({
      padding: 0,
      paddingLeft: 16,
      textAlign: textAlign || 'left',
      color: THEME.font.colors.black.main,
      fontSize: 11,
      fontWeight: 600,
      lineHeight: '48px',
      whiteSpace: 'nowrap',

      '[dir="rtl"] &': {
        paddingRight: 16,
        textAlign: textAlign || 'right',
      },
    }),
};

export default HeaderColumn;
