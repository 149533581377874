import { useAllCountriesQuery } from '@src/graphql/hooks';
import useTranslateOptions from './useTranslateOptions';

const useAllCountries = () => {
  const { data } = useAllCountriesQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    allCountries: data?.allCountries || [],
    allTranslatedCountries: useTranslateOptions(
      data?.allCountries.map(c => ({
        label: c.name,
        value: c.id,
      })) || []
    ),
  };
};

export default useAllCountries;
