import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import SearchForm from './SearchForm';

const SearchFormV2 = styled(SearchForm)({
  '& > div': {
    borderRadius: THEME.box.borderRadius.m,
    gap: THEME.box.gaps.s,
    height: 38,
    padding: '0 12px',

    '& > svg': {
      height: 16,
      width: 16,
    },

    '& > input': {
      fontSize: THEME.font.sizes.subHeading,
    },
  },
});

export default SearchFormV2;
