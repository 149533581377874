import { css } from '@emotion/react';
import React, { FC, PropsWithChildren } from 'react';
import { THEME } from '@src/libs/theme';

interface WrapperProps {
  footerPadding?: number;
  isDesktop?: boolean;
}

const IframeLayout: FC<PropsWithChildren<WrapperProps>> = props => {
  const { children } = props;

  return (
    <div css={styles.container}>
      <div css={styles.wrapper}>
        <div css={styles.pageContent}>
          <div css={styles.mainContent}>
            <div css={{ width: '100%' }}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    minHeight: '100%',
    backgroundColor: THEME.colors.black.main,
    flexDirection: 'column',
  }),
  mainContent: css({
    display: 'flex',
    flex: 1,
    backgroundColor: '#f6f8fa',
  }),
  pageContent: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    flex: 1,
  }),
};

export default IframeLayout;
