import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  MarketplaceJoinableAccountsQuery,
  MarketplaceJoinableAccountsQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/MarketplaceJoinableAccounts.graphql';

export const useMarketplaceJoinableAccountsQuery = (
  options?: QueryHookOptions<MarketplaceJoinableAccountsQuery, MarketplaceJoinableAccountsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<MarketplaceJoinableAccountsQuery, MarketplaceJoinableAccountsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useMarketplaceJoinableAccountsLazyQuery = (
  options?: LazyQueryHookOptions<MarketplaceJoinableAccountsQuery, MarketplaceJoinableAccountsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<MarketplaceJoinableAccountsQuery, MarketplaceJoinableAccountsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
