import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckBox, Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

interface MultiCheckBoxSelectProps {
  checkedAllLabel?: string;
  options: { label: string; value: any }[];
  values: any[];
  onChange?: (values: any[]) => void;
}

const MultiCheckBoxSelect = ({ checkedAllLabel, options, values, onChange }: MultiCheckBoxSelectProps) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const { t } = useTranslation();
  const selectedItems = values
    .map(item => t(`Option.${options.find(option => option.value === item)?.label}`))
    .join(', ');
  const isAllChecked = !!checkedAllLabel && options.every(option => values.includes(option.value));

  const onCheckedChangeAll = (status: boolean) => {
    let items = [...options.map(option => option.value)];
    if (!status) {
      items = [];
    }
    if (onChange) {
      onChange(items);
    }
  };

  const onCheckedChangeItem = (status: boolean, val: any) => {
    const items = [...values];
    if (status) {
      items.push(val);
    } else {
      const index = items.findIndex(item => item === val);
      if (index > -1) {
        items.splice(index, 1);
      }
    }
    if (onChange) {
      onChange(items);
    }
  };

  return (
    <div>
      <Dropdown isExpand={isExpand} isPlaceholder={!values.length} onClick={() => setIsExpand(status => !status)}>
        <div>
          {checkedAllLabel && isAllChecked
            ? t(`Option.${checkedAllLabel}`)
            : selectedItems || t('Selector.Please Select')}
        </div>
        <Icomoon icon="arrow-down" size={16} />
      </Dropdown>

      {isExpand && (
        <div>
          {checkedAllLabel && (
            <div css={styles.option}>
              <CheckBox checked={isAllChecked} onChange={status => onCheckedChangeAll(status)} />
              <div>{t(`Option.${checkedAllLabel}`)}</div>
            </div>
          )}
          {options.map(option => {
            const { label, value } = option;
            const isChecked = values.includes(value);

            return (
              <div css={styles.option} key={value}>
                <CheckBox checked={isChecked} onChange={status => onCheckedChangeItem(status, value)} />
                <div>{t(`Option.${label}`)}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Dropdown = styled.div<{ isExpand: boolean; isPlaceholder: boolean }>(({ isExpand, isPlaceholder }) => ({
  alignItems: 'center',
  backgroundColor: isExpand ? '#f6f8fa' : '#fff',
  borderRadius: 9,
  cursor: 'pointer',
  display: 'flex',
  gap: THEME.box.gaps.xxl,
  height: 40,
  padding: '0 8px',

  '& > div': {
    color: isPlaceholder ? '#d8d9d9' : '#27313b',
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'fill-available',
  },
}));

const styles = {
  option: css({
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    gap: THEME.box.gaps.m,
    padding: 12,
  }),
};

export default MultiCheckBoxSelect;
