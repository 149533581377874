import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@src/components/atoms';
import { TextForm } from '@src/components/shared';
import { useTranslation } from 'react-i18next';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { FormSubmitSection } from '@src/components/shared';
import { FormValues } from './types';

const AddFanForm = () => {
  const { t } = useTranslation();
  const { isVerticalView } = usePageLayout();

  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();

  return (
    <div css={styles.responsiveWrapper}>
      <div css={styles.fixedWidthContainer}>
        <div css={{ padding: '24px 24px 0' }}>
          {!isVerticalView ? (
            <InputWrapper css={styles.flexRow}>
              <div css={styles.flex1}>
                <StyledTextForm
                  title="First Name"
                  isRequired={true}
                  error={!!errors.firstName}
                  placeholder={t('First Name')}
                  css={{ marginRight: 16 }}
                  {...register('firstName')}
                />
                <ErrorMessage message={errors.firstName?.message} />
              </div>

              <div css={styles.flex1}>
                <StyledTextForm
                  title="Last Name"
                  isRequired={true}
                  error={!!errors.lastName}
                  placeholder={t('Last Name')}
                  {...register('lastName')}
                />
                <ErrorMessage message={errors.lastName?.message} />
              </div>
            </InputWrapper>
          ) : (
            <>
              <InputWrapper>
                <StyledTextForm
                  title="First Name"
                  isRequired={true}
                  error={!!errors.firstName}
                  placeholder="First Name"
                  {...register('firstName')}
                />
                <ErrorMessage message={errors.firstName?.message} />
              </InputWrapper>
              <InputWrapper>
                <StyledTextForm
                  title="Last Name"
                  isRequired={true}
                  error={!!errors.lastName}
                  placeholder="Last Name"
                  {...register('lastName')}
                />
                <ErrorMessage message={errors.lastName?.message} />
              </InputWrapper>
            </>
          )}
          <InputWrapper>
            <StyledTextForm
              title="Email"
              isRequired={true}
              error={!!errors.email}
              placeholder={t('Your Email Address')}
              {...register('email')}
            />
            <ErrorMessage message={errors.email?.message} />
          </InputWrapper>
          <InputWrapper>
            <StyledTextForm
              title="Phone Number"
              error={!!errors.contactNumber}
              placeholder={t('Phone Number')}
              {...register('contactNumber')}
            />
            <ErrorMessage message={errors.contactNumber?.message} />
          </InputWrapper>
        </div>
        <FormSubmitSection isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};

const styles = {
  fixedWidthContainer: css({
    width: '100%',
    backgroundColor: THEME.colors.white,

    [`@media (min-width: ${ViewportType.MEDIUM}px)`]: {
      maxWidth: 640,
    },
  }),
  flex1: css({
    flex: 1,
  }),
  flexRow: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  responsiveWrapper: css({
    display: 'flex',
    justifyContent: 'center',
  }),
};

const InputWrapper = styled.div({
  marginBottom: 24,
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& input': {
    borderRadius: 2,
    height: 32,
  },
});

export default AddFanForm;
