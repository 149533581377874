import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon, Icon, TextCutter, VideoPreview } from '@src/components/atoms';
import StatusStepper from '@src/components/organisms/CastingMarketplace/YourJob/StatusStepper';
import { formatNumberWithCommas } from '@src/libs/format';
import { usePageLayout } from '@src/libs/hooks';
import { defaultEmptyImage, isVideoThumbnail } from '@src/libs/image';
import { switchImage } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignSocialMediaType,
  CampaignType,
  EngagementCampaignPostStatus,
  MarketplaceCampaignPostStatus,
} from '@src/__generated__/globalTypes';
import ReasonWidget from './ReasonWidget';

interface PostReportCardProps {
  campaignType: CampaignType;
  post: {
    anyXStatus?: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
    click?: number | null;
    comment: number;
    conversion?: number | null;
    id: number;
    inappropriateDetailedReason?: string | null;
    inappropriateReason?: string | null;
    like: number;
    postCaption: string;
    postSocialMedia: CampaignSocialMediaType;
    postUrl: string;
    share: number;
    status: EngagementCampaignPostStatus | MarketplaceCampaignPostStatus;
    thumbNail?: string | null;
    view: number;
  };
}

const PostReportCard = ({ campaignType, post }: PostReportCardProps) => {
  const {
    anyXStatus,
    click,
    comment,
    conversion,
    inappropriateDetailedReason,
    inappropriateReason,
    like,
    postCaption,
    postSocialMedia,
    postUrl,
    share,
    thumbNail,
    view,
  } = post;
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const engagementItems = [
    { title: postSocialMedia === CampaignSocialMediaType.FACEBOOK ? 'Reactions' : 'Like', value: like },
    { title: 'Comment', value: comment },
    { title: 'Share', value: share },
    { title: 'Click', value: click },
    { title: 'Conversion', value: conversion },
    { title: 'View', value: view },
  ];

  return (
    <div css={styles.container}>
      <div css={styles.title}>{t('Post Status')}</div>

      {anyXStatus && (
        <div css={styles.stepperContainer}>
          <StatusStepper activeColor={THEME.colors.black.main} status={anyXStatus} type={campaignType} />
        </div>
      )}

      {inappropriateDetailedReason && inappropriateReason && (
        <ReasonWidget content={inappropriateDetailedReason} css={{ marginBottom: 24 }} title={inappropriateReason} />
      )}

      <div css={styles.imageContainer}>
        <div>
          {isVideoThumbnail(thumbNail) ? (
            <VideoPreview url={thumbNail || ''} />
          ) : (
            <img src={defaultEmptyImage(thumbNail)} />
          )}
          {postSocialMedia === CampaignSocialMediaType.UNSELECT ? (
            <div css={styles.anyMethodsIndicator}>{t('Any Methods')}</div>
          ) : (
            <div css={styles.socialMediaIndicator}>
              <Icomoon icon={switchImage({ socialMedia: postSocialMedia })} />
            </div>
          )}
        </div>
        <div>
          <TextCutter lines={isMobileView ? 3 : 2} text={postCaption} />
          <Link target="_blank" to={postUrl}>
            <div>{t('Button.View Promotion')}</div>
            <Icon>
              <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={8} />
            </Icon>
          </Link>
        </div>
      </div>

      <div css={styles.engagementInfoContainer}>
        {engagementItems.map(({ title, value }) => (
          <div key={title}>
            <div>{t(title)}</div>
            <div>{value !== null ? formatNumberWithCommas(value, 0) : '-'}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  anyMethodsIndicator: css({
    background: THEME.colors.white,
    borderRadius: 15,
    bottom: 8,
    boxShadow: THEME.box.shadows.outer,
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.hint,
    fontWeight: 600,
    left: 8,
    padding: '8px 12px',
    position: 'absolute',
  }),
  container: css({
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    padding: 16,
  }),
  engagementInfoContainer: css({
    background: '#dee5ec',
    border: THEME.box.borders.gray.main,
    borderRadius: 13,
    display: 'flex',
    gridGap: 1,
    overflow: 'hidden',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    '& > div': {
      background: THEME.colors.white,
      display: 'grid',
      gap: THEME.box.gaps.s,
      padding: '12px 0',
      textAlign: 'center',
      width: 'fill-available',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  imageContainer: css({
    display: 'flex',
    gap: THEME.box.gaps.l,
    justifySelf: 'center',
    marginBottom: 24,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      width: '70%',
    },

    '& > div:nth-of-type(1)': {
      background: THEME.colors.black.main,
      borderRadius: 10,
      position: 'relative',
      width: 'fill-available',

      '& > img, video': {
        borderRadius: 10,
        height: 93,
        objectFit: 'contain',
        width: '100%',

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          height: 208,
        },
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      width: 'fill-available',

      '& > div': {
        color: '#596570',
        fontSize: THEME.font.sizes.normal,
      },

      '& > a': {
        alignItems: 'center',
        alignSelf: 'flex-end',
        color: THEME.font.colors.black.main,
        display: 'flex',
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
        gap: THEME.box.gaps.s,
        justifySelf: 'flex-end',
      },
    },
  }),
  socialMediaIndicator: css({
    alignItems: 'center',
    background: THEME.colors.white,
    borderRadius: '50%',
    bottom: 8,
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    left: 8,
    position: 'absolute',
    width: 24,
  }),
  stepperContainer: css({
    justifySelf: 'center',
    marginBottom: 24,
    width: '90%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      width: '70%',
    },

    '& > div': {
      margin: '0 0 16px 0',
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
    marginBottom: 24,
  }),
};

export default PostReportCard;
