import { css } from '@emotion/react';
import * as React from 'react';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

// Types
export interface LanguageButtonProps {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LanguageButton = (props: LanguageButtonProps) => {
  const { title, onClick } = props;

  return (
    <button css={styles.button} onClick={onClick}>
      {title}
    </button>
  );
};

const styles = {
  button: css({
    boxSizing: 'border-box',
    width: '100%',
    padding: '0 16px',
    textAlign: 'left',
    lineHeight: '40px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-out',

    '&:hover': {
      backgroundColor: '#efefef',

      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        backgroundColor: THEME.colors.white,
      },
    },
  }),
};

export default LanguageButton;
