import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  InfluencerAddYoutubeCompareAccountAnalyticsMutation,
  InfluencerAddYoutubeCompareAccountAnalyticsMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/InfluencerAddYoutubeCompareAccountAnalytics.graphql';

export const useInfluencerAddYoutubeCompareAccountAnalyticsMutation = (
  options?: MutationHookOptions<
    InfluencerAddYoutubeCompareAccountAnalyticsMutation,
    InfluencerAddYoutubeCompareAccountAnalyticsMutationVariables
  >
) =>
  useMutation<
    InfluencerAddYoutubeCompareAccountAnalyticsMutation,
    InfluencerAddYoutubeCompareAccountAnalyticsMutationVariables
  >(MUTATION, options);
