import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import CampaignPostReports from '@src/components/organisms/CastingMarketplace/YourJob/CampaignPostReports';
import { BackNavigator, ListLoading } from '@src/components/molecules';
import { useEngagementCampaignPostReportForInfluencerQuery } from '@src/graphql/hooks';
import { usePageLayout } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';
import { CampaignType } from '@src/__generated__/globalTypes';

const Reports = () => {
  const params = useParams<Record<any, string>>();
  const { isMobileView } = usePageLayout();
  const campaignId = Number(params.id);

  const { data, loading } = useEngagementCampaignPostReportForInfluencerQuery({
    variables: {
      campaignId,
    },
  });

  const campaignPostReports = data?.engagementCampaignPostReportForInfluencer;

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator
          title="Campaign's Report"
          to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID, { id: campaignId })}
        />
      )}

      <div css={{ padding: '16px 0 16px' }}>
        {loading ? (
          <ListLoading />
        ) : (
          campaignPostReports && (
            <CampaignPostReports campaignPostReports={campaignPostReports} campaignType={CampaignType.ENGAGEMENT} />
          )
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    margin: 16,
  }),
};

export default Reports;
