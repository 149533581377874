import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeAnalyticsPostsCountQuery,
  InfluencerYoutubeAnalyticsPostsCountQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerYoutubeAnalyticsPostsCount.graphql';

export const useInfluencerYoutubeAnalyticsPostsCountQuery = (
  options?: QueryHookOptions<
    InfluencerYoutubeAnalyticsPostsCountQuery,
    InfluencerYoutubeAnalyticsPostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerYoutubeAnalyticsPostsCountQuery, InfluencerYoutubeAnalyticsPostsCountQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};

export const useInfluencerYoutubeAnalyticsPostsCountLazyQuery = (
  options?: LazyQueryHookOptions<
    InfluencerYoutubeAnalyticsPostsCountQuery,
    InfluencerYoutubeAnalyticsPostsCountQueryVariables
  >
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerYoutubeAnalyticsPostsCountQuery, InfluencerYoutubeAnalyticsPostsCountQueryVariables>(
    QUERY,
    {
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      ...options,
    }
  );
};
