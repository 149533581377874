import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useCreatorAddressInformationQuery, useUpdateAddressInformationMutation } from '@src/graphql/hooks';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import Address, { AddressInformation } from './Address';

const Index = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const { data } = useCreatorAddressInformationQuery({ fetchPolicy: 'no-cache' });
  const [updateAddressInformation] = useUpdateAddressInformationMutation();
  const validationSchema = yup.object().shape({
    address: yup.string().required('requiredAddressMessage'),
    address2: yup.string().required('requiredApartmentMessage'),
    city: yup.string().required('requiredCityMessage'),
    fullName: yup.string().required('requiredFullNameMessage'),
    postalCode: yup.string().required('requiredPostalCodeMessage'),
    province: yup.string().required('requiredProvinceMessage'),
  });
  const creatorAddressInformation = data?.creatorAddressInformation;

  const addressInfo = {
    address: creatorAddressInformation?.address || '',
    address2: creatorAddressInformation?.address2 || '',
    city: creatorAddressInformation?.city || '',
    fullName: creatorAddressInformation?.fullName || '',
    phoneNumber: creatorAddressInformation?.phoneNumber || '',
    postalCode: creatorAddressInformation?.postalCode || '',
    province: creatorAddressInformation?.province || '',
  };

  const methods = useForm<AddressInformation>({
    defaultValues: addressInfo,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(addressInfo);
  }, [addressInfo]);

  const onSubmit = async (values: AddressInformation) => {
    try {
      await updateAddressInformation({
        variables: {
          input: values,
        },
      });
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editSettingsAddress);
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Address />
      </form>
    </FormProvider>
  );
};

export default Index;
