import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { usePageLayout } from '@src/libs/hooks';
import PostForm from '@src/components/organisms/CastingMarketplace/YourJob/PostForm';
import { BackNavigator } from '@src/components/molecules';
import { generatePath, ROUTES } from '@src/shared/routes';

const EditPost = () => {
  const params = useParams<Record<any, string>>();
  const { isMobileView } = usePageLayout();
  const engagementId = Number(params.id);
  const postId = Number(params.postId);

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator
          title="Edit Post"
          to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
        />
      )}

      <div css={styles.formContainer}>
        <PostForm engagementId={engagementId} postId={postId} />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    padding: 24,
  }),
  formContainer: css({
    marginTop: 16,
  }),
};

export default EditPost;
