import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Order } from '@src/__generated__/globalTypes';
import { FanListFilterType } from '../types';
import BasePostListFilter from './FanListFilter';
import { FormValues } from './types';
import { getInitialFormValues, getFilterData } from './helper';

interface FanListFilterComponentProps {
  filter: FanListFilterType;
  selectedFansId: Set<number>;
  sortOrder: Order;
  setFilter: (filter: FanListFilterType) => void;
  onSort: () => void;
}

const FanListFilterComponent = ({
  filter,
  selectedFansId,
  sortOrder,
  setFilter,
  onSort,
}: FanListFilterComponentProps) => {
  const methods = useForm<FormValues>({
    defaultValues: getInitialFormValues(filter),
  });

  const onSubmit = (payload: FormValues) => {
    setFilter(getFilterData(payload));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <BasePostListFilter
          filter={filter}
          sortOrder={sortOrder}
          onSort={onSort}
          selectedFansId={selectedFansId}
          onSubmit={onSubmit}
        />
      </form>
    </FormProvider>
  );
};

export default FanListFilterComponent;
