import { useQueryHelper } from '@src/libs/hooks';
import { useState } from 'react';
import { useRemoveFormResponseMutation } from '@src/graphql/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';

export default () => {
  const { t, enqueueSnackbar, navigate, params } = useQueryHelper();
  const { id } = params as { id: string; page: string };
  const [selectedSubmission, setSelectedSubmission] = useState<string | undefined>(undefined);

  const [removeFormResponse] = useRemoveFormResponseMutation();

  const onClickDelete = (submissionId: string | undefined) => {
    setSelectedSubmission(submissionId);
  };

  const handleDelete = async () => {
    if (!!selectedSubmission) {
      try {
        await removeFormResponse({
          variables: {
            input: {
              submissionId: Number(selectedSubmission),
            },
          },
        });
        navigate(generatePath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT_RESPONDENT, { id }));
        enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      } catch (err) {
        const message = err?.message || 'failedToDelete';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        closeDialog();
      }
    }
  };

  const closeDialog = () => {
    setSelectedSubmission(undefined);
  };

  return {
    selectedSubmission,
    closeDialog,
    onClickDelete,
    handleDelete,
  };
};
