import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthReconnectTikTokMutation,
  AnyXSocialAuthReconnectTikTokMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthReconnectTikTok.graphql';

export const useAnyXSocialAuthReconnectTikTokMutation = (
  options?: MutationHookOptions<AnyXSocialAuthReconnectTikTokMutation, AnyXSocialAuthReconnectTikTokMutationVariables>
) =>
  useMutation<AnyXSocialAuthReconnectTikTokMutation, AnyXSocialAuthReconnectTikTokMutationVariables>(MUTATION, options);
