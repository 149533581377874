import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import emptyVideo from '@src/assets/img/videoThumbnail.png';

interface VideoPreviewProps {
  className?: string;
  height?: string | number;
  width?: string | number;
  url: string | null;
  onClick?: () => void;
}

const VideoPreview = ({
  className,
  height = '100%',
  url,
  width = '100%',
  onClick,
  ...restProps
}: VideoPreviewProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  return url ? (
    <Container className={className} height={height} width={width} onClick={onClick}>
      <ReactPlayer
        height={height}
        playing={isPlaying}
        muted
        url={url}
        width={width}
        onEnded={() => setIsPlaying(false)}
        {...restProps}
      />
      <div css={isPlaying ? styles.pauseIcon : styles.playIcon} onClick={() => setIsPlaying(state => !state)} />
    </Container>
  ) : (
    <img
      alt="emptyVideo"
      className={className}
      css={{ objectFit: 'contain' }}
      height={height}
      src={emptyVideo}
      width={width}
    />
  );
};

const Container = styled.div<{ height: string | number; width: string | number }>(({ height, width }) => ({
  alignContent: 'center',
  background: '#dee5ec',
  display: 'flex',
  height,
  justifyContent: 'center',
  position: 'relative',
  width,
}));

const styles = {
  pauseIcon: css({
    borderLeft: '10px solid #fff',
    borderRight: '10px solid #fff',
    bottom: 0,
    cursor: 'pointer',
    height: 28,
    left: 0,
    margin: 'auto',
    opacity: 0.2,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 4,

    '&:hover': {
      opacity: 1,
    },
  }),
  playIcon: css({
    borderBottom: '16px solid transparent',
    borderLeft: '24px solid #fff',
    borderTop: '16px solid transparent',
    bottom: 0,
    cursor: 'pointer',
    height: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 0,
  }),
};

export default VideoPreview;
