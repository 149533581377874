import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import emptyImage from '@src/assets/img/emptyImage.png';
import { Avatar, Icomoon, Textarea } from '@src/components/atoms';
import { Carousel, ThemeButton } from '@src/components/molecules';
import { switchImage } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { useCopy, usePageLayout } from '@src/libs/hooks';
import { EngagementPostDetailForInfluencer } from '@src/__generated__/globalTypes';

interface PostDetailsProps {
  postDetails: EngagementPostDetailForInfluencer;
}

const PostDetails = ({ postDetails }: PostDetailsProps) => {
  const { t } = useTranslation();
  const { handleCopyHasText, isCopying } = useCopy();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {isMobileView && (
        <div css={styles.infoHeader}>
          <div>
            <Icomoon icon={switchImage({ socialMedia: postDetails.socialMedia })} size={24} />
          </div>
          <div>
            <Avatar
              size={35}
              src={postDetails.engagementPostDetailInfluencer.avatar}
              title={postDetails.engagementPostDetailInfluencer.name}
            />
          </div>
          <div>
            <span>{postDetails.engagementPostDetailInfluencer.name}</span>
            <span>{`${postDetails.engagementPostDetailInfluencer.followersCount} Followers`}</span>
          </div>
        </div>
      )}

      <div css={styles.imagesContainer}>
        <div>
          <Carousel height="276px" sources={postDetails.images || [emptyImage]} width="100%" />
        </div>
      </div>
      <div css={styles.infoContainer}>
        {!isMobileView && (
          <div css={styles.infoHeader}>
            <div>
              <Icomoon icon={switchImage({ socialMedia: postDetails.socialMedia })} size={24} />
            </div>
            <div>
              <Avatar
                size={35}
                src={postDetails.engagementPostDetailInfluencer.avatar}
                title={postDetails.engagementPostDetailInfluencer.name}
              />
            </div>
            <div>
              <span>{postDetails.engagementPostDetailInfluencer.name}</span>
              <span>{`${postDetails.engagementPostDetailInfluencer.followersCount} Followers`}</span>
            </div>
          </div>
        )}

        <div css={styles.infoContent}>
          <div>
            <div>{t('Post Caption')}</div>
            <div>
              <ThemeButton
                css={styles.copyBtn}
                disabled={isCopying}
                text="Copy"
                width="fit-content"
                onClick={() => handleCopyHasText(postDetails.caption)}
              />
            </div>
          </div>
          <div>
            <Textarea value={postDetails.caption} disabled placeholder="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
  }),
  copyBtn: css({
    borderRadius: 5,
  }),
  imagesContainer: css({
    width: '50%',

    '& > div': {
      paddingRight: 24,

      [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
        paddingRight: 0,
      },
    },

    '& .slick-arrow': {
      borderRadius: '50%',
      boxShadow: THEME.box.shadows.outer,
      height: '32px !important',
      position: 'absolute',
      width: '32px !important',

      '& > i': {
        marginTop: 1,
      },

      '&.slick-prev': {
        left: '-15px !important',
      },

      '&.slick-next': {
        right: '-15px !important',
      },
    },

    '& .slick-dots li button::before': {
      color: '#3892e5 !important',
      fontSize: '11px !important',
    },

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      width: '100%',
    },
  }),
  infoContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    width: '50%',

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      marginTop: 16,
      width: '100%',
    },
  }),
  infoContent: css({
    display: 'flex',
    flexBasis: '100%',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      flexBasis: '100%',
      flexWrap: 'wrap',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        display: 'flex',
        flexBasis: '50%',
        fontSize: 13,
        fontWeight: 600,
      },

      '& > div:nth-of-type(2)': {
        display: 'flex',
        flexBasis: '50%',
        justifyContent: 'flex-end',
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '100%',

      '& > textarea': {
        backgroundColor: THEME.colors.white,
        border: 'none',
        height: 200,
      },
    },
  }),
  infoHeader: css({
    alignItems: 'center',
    display: 'flex',
    flexBasis: '100%',
    flexWrap: 'wrap',
    marginBottom: 16,

    '& > div': {
      display: 'flex',
      marginRight: 8,
    },

    '& > div:nth-of-type(3)': {
      display: 'grid',

      '& > span:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
      },

      '& > span:nth-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.subordinate,
      },
    },
  }),
};

export default PostDetails;
