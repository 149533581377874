import styled from '@emotion/styled';
import { PageCount as BasePageCount } from '@src/components/atoms';
import List from '@src/components/atoms/List';
import { THEME } from '@src/libs/theme';
import * as Table from '../EmptyTable';

const Wrapper = Table.Wrapper;

const Container = styled.section({
  overflowX: 'auto',
  display: 'flex',
});

const Sticky = styled.section({
  position: 'sticky',
  left: 0,
  zIndex: 3,
});

const StickyWrapper = styled.table({
  flexShrink: 0,
  width: 320,
  height: '100%',
  position: 'relative',
  boxShadow: THEME.box.shadows.outer,
  backgroundColor: THEME.colors.white,
});

const StickyHeader = styled.tr({
  display: 'flex',
  alignItems: 'center',
  height: 47.8,
  position: 'sticky',
  top: 0,
  zIndex: 4,
});

const StickyTitle = styled.p({
  marginBottom: 5,
  whiteSpace: 'nowrap',
  width: 200,

  '&:hover': {
    color: '#179cd7',
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  a: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const TitleWrapper = styled.tr<{ isRowHovered: boolean; isRowChecked?: boolean }>(({ isRowChecked, isRowHovered }) => ({
  padding: '0 8px',
  backgroundColor: isRowHovered ? '#f6f8fa' : !!isRowChecked ? '#fffde7' : '#fff',
  borderTop: '1px solid #efefef',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  'p > a': {
    ...(isRowHovered && {
      color: '#179cd7',
      textDecoration: 'underline',
    }),
  },
}));

const SliderSection = styled.section({
  flex: 1,
  width: 1,
  position: 'relative',
});

const SliderTable = styled.table({
  tableLayout: 'fixed',
  width: 'auto',
  position: 'relative',
  minWidth: '100%',
});

const Row = styled.tr<{ isRowHovered: boolean; isRowChecked?: boolean }>(({ isRowChecked, isRowHovered }) => ({
  td: {
    backgroundColor: isRowHovered ? '#f6f8fa' : !!isRowChecked ? '#fffde7' : '#fff',
  },

  a: {
    ...(isRowHovered && {
      color: '#179cd7',
      textDecoration: 'underline',
    }),
  },
}));

const ThumbnailCell = styled(List.ThumbnailColumn)<{ titleWidth?: string }>(({ titleWidth }) => ({
  padding: '0 8px',
  paddingLeft: 16,

  p: {
    overflow: 'hidden',
    width: titleWidth || '120px',
    maxHeight: 33,
    textOverflow: 'ellipsis',
    minWidth: 'unset',
  },
}));

const PageCount = styled(BasePageCount)({
  display: 'flex',
  justifyContent: 'flex-start',
  flex: 1,
  paddingRight: 8,
});

const BlankColumn = styled.td<{ width?: string; height?: string }>(({ height, width }) => ({
  width: width || 22,
  height: height || 56,
}));

const StickyHeaderRowWrapper = styled.th({
  position: 'sticky',
  left: 0,
  zIndex: 3,
  display: 'table-cell',
  alignItems: 'center',
  boxShadow: THEME.box.shadows.outer,
  paddingLeft: 16,
  backgroundColor: '#f6f8fa',

  '& > :first-of-type': {
    boxShadow: THEME.box.shadows.outer,
  },
});

const StickyHeaderTdWrapper = styled.td({
  position: 'sticky',
  left: 0,
  zIndex: 3,
  display: 'flex',
  alignItems: 'center',
  boxShadow: THEME.box.shadows.outer,
  paddingLeft: 16,
  backgroundColor: '#f6f8fa',
});

const StickyBodyRowWrapper = styled.td({
  position: 'sticky',
  left: 0,
  zIndex: 3,
  filter: 'drop-shadow(3px 0 0 #efefef)',
});

export default {
  PageCount,
  ThumbnailCell,
  Row,
  SliderTable,
  SliderSection,
  TitleWrapper,
  StickyTitle,
  StickyHeader,
  StickyWrapper,
  Sticky,
  Container,
  BlankColumn,
  StickyHeaderRowWrapper,
  StickyBodyRowWrapper,
  StickyHeaderTdWrapper,
  Wrapper,
};
