import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import formsEmptyListBg from '@src/assets/img/forms/emptyListBg.png';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';

const EmptyList = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <div>
          <img alt="bgImg" height="180" src={formsEmptyListBg} width="180" />
        </div>
        <div>{t(`Let's add Form`)}</div>
        <div>{t('Create a form and get to know your fans better')}</div>
        <div>
          <Link to={ROUTES.MY_PAGE_FORMS_ADD}>{t('Button.Add Form')}</Link>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: '0 0 3px 3px',
    display: 'flex',
    justifyContent: 'center',
    padding: '126px 0',

    '& > div': {
      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 24,
      },

      '& > div:nth-of-type(2)': {
        color: '#000',
        display: 'flex',
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        justifyContent: 'center',
        marginBottom: 24,
      },

      '& > div:nth-of-type(3)': {
        color: '#000',
        display: 'flex',
        fontSize: THEME.font.sizes.normal,
        justifyContent: 'center',
        marginBottom: 24,
      },

      '& > div:nth-of-type(4)': {
        display: 'flex',
        justifyContent: 'center',

        '& > a': {
          alignItems: 'center',
          backgroundColor: THEME.colors.blue.main,
          borderRadius: 3,
          color: THEME.font.colors.white,
          display: 'flex',
          fontSize: THEME.font.sizes.subordinate,
          fontWeight: 600,
          height: 32,
          justifyContent: 'center',
          width: 97,
        },
      },
    },
  }),
};

export default EmptyList;
