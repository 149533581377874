import { css } from '@emotion/react';
import { THEME } from '@src/libs/theme';
import * as React from 'react';

interface TermsTextProps {
  className?: string;
  subTitle?: string;
  children: React.ReactNode;
}

const TermsText = (props: TermsTextProps) => {
  const { className, subTitle, children } = props;

  return (
    <div className={className} css={{ paddingBottom: 16 }}>
      {subTitle && <h3 css={styles.subTitle}>{subTitle}</h3>}
      <p css={styles.paragraph}>{children}</p>
    </div>
  );
};

const styles = {
  paragraph: css({
    lineHeight: 1.38,
    letterSpacing: 'normal',
    fontSize: 13,
    color: THEME.font.colors.black.main,
  }),
  subTitle: css({
    padding: '24px 0 8px',
    fontSize: THEME.font.sizes.heading,
    textTransform: 'uppercase',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.22,
    color: THEME.font.colors.black.main,
  }),
};

export default TermsText;
