import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import anyCreatorLogo from '@src/assets/img/anyCreatorBlack.png';
import privateForm from '@src/assets/img/forms/privateForm.png';
import { ViewportType } from '@src/libs/types';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';

const PrivateForm = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      <div css={styles.contentContainer}>
        <div>
          <div>
            <img
              alt="bgImg"
              height={isMobileView ? '94' : '126'}
              src={privateForm}
              width={isMobileView ? '134' : '180'}
            />
          </div>
          <div>{t(`Annotation.Private Form`)}</div>
        </div>
      </div>
      <div css={styles.footer}>
        <div>{t('Powered by')}</div>
        <Link to="https://any-creator.com/">
          <img alt="logo" height="32" src={anyCreatorLogo} width="151" />
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    paddingBottom: 40,
  }),
  contentContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    display: 'flex',
    height: 765,
    justifyContent: 'center',
    marginBottom: 62,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 540,
    },

    '& > div': {
      display: 'grid',
      justifyContent: 'center',
      padding: '0 40px',
      width: '100%',

      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 24,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  footer: css({
    display: 'grid',
    justifyContent: 'center',
    textAlign: 'center',

    '& > div:nth-of-type(1)': {
      color: '#000',
      fontSize: THEME.font.sizes.normal,
      marginBottom: 8,
    },
  }),
};

export default PrivateForm;
