import React, { SyntheticEvent } from 'react';
import { css } from '@emotion/react';
import useDirLayout from '@src/libs/hooks/useDirLayout';
import Icomoon from '@src/components/atoms/Icomoon';

interface MenuControlIconProps {
  color?: string;
  hasRemoveIcon?: boolean;
  onClose?: (e: SyntheticEvent<any>) => void;
}

const MenuControlIcon = ({ color = '#c5d0da', hasRemoveIcon, onClose }: MenuControlIconProps) => {
  const { dir } = useDirLayout();
  const rtlCss = dir === 'rtl' ? [{ right: 'unset', left: '2px' }] : [];

  return (
    <div css={[styles.container, ...rtlCss]}>
      <Icomoon
        icon={hasRemoveIcon ? 'clear-filled' : 'arrow-down'}
        size={hasRemoveIcon ? 16 : 10}
        {...(hasRemoveIcon && { color, onClick: onClose })}
      />
    </div>
  );
};

const styles = {
  container: css({
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 35,
  }),
};

export default MenuControlIcon;
