import styled from '@emotion/styled';
import React, { forwardRef, Ref } from 'react';
import useDirLayout from '@src/libs/hooks/useDirLayout';
import { THEME } from '@src/libs/theme';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

export interface TextareaProps extends FormProps, FieldType {
  placeholder: string;
}

const Textarea = forwardRef((props: TextareaProps, ref: Ref<HTMLTextAreaElement>) => {
  const { placeholder, error, disabled, readOnly, ...field } = props;
  const { dir } = useDirLayout();

  return (
    <Form
      placeholder={!!disabled ? '' : placeholder}
      error={error}
      disabled={disabled}
      ref={ref}
      {...field}
      readOnly={readOnly}
      dir={dir}
    />
  );
});

interface FormProps {
  error?: boolean;
  disabled?: boolean;
}

const Form = styled.textarea<FormProps>(({ disabled, error }) => ({
  boxSizing: 'border-box',
  width: '100%',
  height: 140,
  padding: 12,
  background: disabled ? '#f2f2f2' : '#fff',
  border: THEME.box.borders.gray.main,
  borderColor: error ? 'tomato' : '#e0e8ed',
  borderRadius: 3,
  ...(error && {
    '&:focus': {
      borderColor: '#3892e5',
    },
  }),
}));

Textarea.displayName = 'Textarea';

export default Textarea;
