export const getGrowthArrow = (value: number | null) => {
  if (Number(value) === 0) {
    return '';
  }

  return Number(value) > 0 ? '\u2191' : '\u2193';
};

export const getGrowthPrefix = (value: number | null) => {
  if (Number(value) === 0) {
    return undefined;
  }

  return Number(value) > 0 ? '+' : '';
};
