import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFormReportSummaryQuery, GetFormReportSummaryQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFormReportSummary.graphql';

export const useGetFormReportSummaryQuery = (
  options?: QueryHookOptions<GetFormReportSummaryQuery, GetFormReportSummaryQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFormReportSummaryQuery, GetFormReportSummaryQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFormReportSummaryLazyQuery = (
  options?: LazyQueryHookOptions<GetFormReportSummaryQuery, GetFormReportSummaryQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFormReportSummaryQuery, GetFormReportSummaryQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
