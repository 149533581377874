import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Icomoon, TextCutter } from '@src/components/atoms';
import { Dialog, ThemeButton } from '@src/components/molecules';
import { useDisconnectPromotionMethodsMutation } from '@src/graphql/hooks';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import { CampaignPromotionMethod } from '@src/__generated__/globalTypes';

interface Method {
  id: string;
  title: string | null | undefined;
}

interface PromotionMethodConnectorProps {
  icon: string;
  isEnableDisconnect: boolean;
  methods: Method[];
  title: string;
  type: CampaignPromotionMethod;
}

const PromotionMethodConnector = ({
  icon,
  isEnableDisconnect,
  methods,
  title,
  type,
}: PromotionMethodConnectorProps) => {
  const [disconnectAccount, setDisconnectAccount] = useState<Method | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isMobileView, isYoutubeCmsPath } = usePageLayout();
  const { enqueueSnackbar, t } = useQueryHelper();
  const [disconnectPromotionMethod] = useDisconnectPromotionMethodsMutation({
    refetchQueries: ['InfluencerPromotionMethods'],
  });

  const onClickRemove = async () => {
    try {
      if (disconnectAccount) {
        setIsLoading(true);
        await disconnectPromotionMethod({
          variables: {
            input: {
              promotionMethodId: disconnectAccount.id,
            },
          },
        });
        setDisconnectAccount(null);
        enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  return (
    <div css={styles.container}>
      <Dialog
        cancelButtonProps={{
          text: 'Close',
          onClick: () => setDisconnectAccount(null),
          ...(!isMobileView && { width: 112 }),
        }}
        open={!!disconnectAccount}
        nextButtonProps={{
          loading: isLoading,
          text: 'Remove',
          theme: 'red',
          onClick: onClickRemove,
          ...(!isMobileView && { width: 112 }),
        }}
        removeContentPadding
        onClose={() => setDisconnectAccount(null)}
      >
        <div css={styles.dialogDisconnect}>
          <div className="title">
            <Icomoon color="#ffb63d" icon="warning" size={24} />
            <label>{t('Title.Are you sure you want to disconnect')}</label>
          </div>
          <label className="description">
            <div>{t('Annotation.This process cannot be undone')}</div>
          </label>
          <div className="promotion-method-account">
            <div>
              <Icomoon icon={icon} size={18} />
              <label>{t(title)}</label>
            </div>
            <TextCutter lines={1} text={disconnectAccount?.title || ''} />
          </div>
        </div>
      </Dialog>
      <div css={styles.header}>
        <Icomoon icon={icon} size={18} />
        <label>{t(title)}</label>
      </div>
      {methods.map(method => (
        <div css={styles.methodContainer} key={method.id}>
          <div className="method">
            <label>{t(title)}</label>
            <TextCutter lines={1} text={method.title || ''} />
          </div>
          {isEnableDisconnect && (
            <ThemeButton
              css={{ minWidth: 'fit-content' }}
              text="Remove"
              width="fit-content"
              onClick={() => setDisconnectAccount(method)}
            />
          )}
        </div>
      ))}
      <div css={styles.addMethodContainer}>
        <ThemeButton
          height={isMobileView ? '56px' : '64px'}
          prefixIcon={<Icomoon icon="plus" />}
          text="Add Account"
          to={generatePath(
            isYoutubeCmsPath
              ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_ADD_METHODS
              : ROUTES.MY_PAGE_SOCIAL_CONNECT_ADD_METHODS,
            undefined,
            { method: type }
          )}
        />
      </div>
    </div>
  );
};

const styles = {
  addMethodContainer: css({
    borderTop: THEME.box.borders.gray.main,

    '& > a': {
      border: 'none',
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,

      '&:hover': {
        border: 'none',
      },
    },
  }),
  container: css({
    background: THEME.colors.white,
    borderShadow: THEME.box.shadows.outer,
    overflow: 'hidden',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
    },
  }),
  dialogDisconnect: css({
    color: THEME.colors.black.main,
    padding: '40px 16px 32px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '40px 24px 32px 24px',
    },

    '& > .title': {
      alignItems: 'flex-start',
      display: 'flex',
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      gap: THEME.box.gaps.xs,
      marginBottom: 12,
    },

    '& > .description': {
      fontSize: THEME.font.sizes.normal,
      marginBottom: 24,
    },

    '& > .promotion-method-account': {
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.m,
      color: THEME.font.colors.black.main,
      padding: '14px 16px',

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        display: 'flex',
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
        gap: THEME.box.gaps.xs,
      },

      '& > div:nth-of-type(2)': {
        fontSize: THEME.font.sizes.subordinate,
      },
    },
  }),
  header: css({
    alignItems: 'center',
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
    gap: THEME.box.gaps.m,
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '16px 24px',
    },
  }),
  methodContainer: css({
    borderTop: THEME.box.borders.gray.main,
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subordinate,
    gap: THEME.box.gaps.s,
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '16px 24px',
    },

    '& > .method': {
      display: 'grid',
      width: 'fill-available',

      '& > label': {
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
      },

      '& > div': {
        maxWidth: 224,
      },
    },

    '& > button': {
      borderRadius: THEME.box.borderRadius.m,
    },
  }),
};

export default PromotionMethodConnector;
