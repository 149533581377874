import React from 'react';
import { css } from '@emotion/react';
import { TextCutter } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { chatMessageTemplatesState, useRecoil } from '@src/recoilAtoms';
import ReactPlayer from 'react-player';

type ChatEventsTypenames =
  | 'BotTextMessageEvent'
  | 'InquiryEvent'
  | 'InquiryFinishEvent'
  | 'MessageTemplateEvent'
  | 'StateUpdateEvent'
  | 'UserAudioMessageEvent'
  | 'UserImageMessageEvent'
  | 'UserPostbackEvent'
  | 'UserTextMessageEvent'
  | 'UserVideoMessageEvent';

interface ChatBubblesProps {
  inquiry?: string;
  timeStr?: string;
  channelUserName?: string;
  text?: string;
  authorName?: string | null;
  templateId?: string;
  typename: ChatEventsTypenames;
  imageUrl?: string;
  contentUrl?: string;
  duration?: number;
}
export const ChatBubbles = ({ typename, ...restProps }: ChatBubblesProps) => {
  const { timeStr, channelUserName, text, authorName, templateId, contentUrl } = restProps;
  const { recoilState: chatTemplates } = useRecoil(chatMessageTemplatesState);

  switch (typename) {
    case 'UserTextMessageEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={[styles.bubble, styles.flexStart]}>
            <div css={styles.textSecondary}>
              <TextCutter lines={1} text={channelUserName || ''} css={styles.authorName} />
              <span>{timeStr}</span>
            </div>

            <p css={[styles.text, styles.userText]}>{text}</p>
          </div>
        </div>
      );
    case 'BotTextMessageEvent':
      return (
        <div css={styles.wrapper}>
          <div css={styles.bubble}>
            <div css={styles.textSecondary}>
              <span css={styles.timeStr}>{timeStr}</span>
              <TextCutter lines={1} text={authorName || ''} css={styles.authorName} />
            </div>
            <p css={styles.text}>{text}</p>
          </div>
        </div>
      );

    case 'UserImageMessageEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={[styles.bubble, styles.flexStart]}>
            <div css={styles.textSecondary}>
              <TextCutter lines={1} text={channelUserName || ''} css={styles.authorName} />
              <span css={styles.timeStr}>{timeStr}</span>
            </div>
            <img src={restProps.imageUrl} css={styles.userMediaUrl} alt="user's media file" />
          </div>
        </div>
      );

    case 'UserAudioMessageEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={[styles.bubble, styles.flexStart, styles.maxWidthUnset]}>
            <div css={styles.textSecondary}>
              <TextCutter lines={1} text={channelUserName || ''} css={styles.authorName} />
              <span css={styles.timeStr}>{timeStr}</span>
            </div>
            {/* TODO: apply custom design when ready */}
            <audio controls>
              <source src={contentUrl} type="audio/mp4" />
            </audio>
          </div>
        </div>
      );

    case 'UserVideoMessageEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={[styles.bubble, styles.flexStart]}>
            <div css={styles.textSecondary}>
              <TextCutter lines={1} text={channelUserName || ''} css={styles.authorName} />
              <span css={styles.timeStr}>{timeStr}</span>
            </div>
            {/* TODO: apply custom design when ready */}
            <ReactPlayer url={contentUrl} controls width="100%" css={{ marginTop: '10px' }} />
          </div>
        </div>
      );

    // currently we will show them same, may individually later on demand
    case 'UserPostbackEvent':
    case 'InquiryFinishEvent':
    case 'InquiryEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={styles.bubble}>
            <div css={styles.textSecondary}>
              <span css={styles.authorName}>{channelUserName}</span>
              <span css={styles.timeStr}>{timeStr}</span>
            </div>
            <p css={styles.text}>{text}</p>
          </div>
        </div>
      );

    case 'MessageTemplateEvent': {
      const templateText = chatTemplates.find(template => template.templateId === templateId)?.text;

      return templateText ? (
        <article css={styles.templateWrapper}>
          <div css={styles.templateBubble}>
            <p css={styles.templateText}>
              {`${templateText} `}
              <span css={styles.timeStr}>{timeStr}</span>
            </p>
          </div>
        </article>
      ) : null;
    }

    case 'StateUpdateEvent':
    default:
      return null;
  }
};

const styles = {
  authorName: css({
    fontWeight: 600,
  }),
  timeStr: css({
    fontWeight: 400,
  }),
  textSecondary: css({
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 4,
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.subordinate,
    lineHeight: '16px',
  }),
  wrapper: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 0',
  }),
  justifyStart: css({
    justifyContent: 'flex-start',
  }),
  flexStart: css({
    alignItems: 'flex-start',
  }),
  bubble: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    maxWidth: '75%',
    overflow: 'hidden',
  }),
  userText: css({
    backgroundColor: THEME.colors.white,
    color: THEME.font.colors.black.main,
  }),
  text: css({
    padding: 12,
    margin: 2 /* for box-shadow */,
    marginTop: 8,
    color: THEME.font.colors.white,
    whiteSpace: 'pre-line',
    background: '#6e7c89',
    boxShadow: THEME.box.shadows.outer,
    borderRadius: 15,
    lineBreak: 'anywhere',
  }),
  templateText: css({
    fontWeight: 400,
    textAlign: 'center',
  }),
  templateWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 0',
  }),
  templateBubble: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
    fontSize: THEME.font.sizes.subordinate,
    lineHeight: '16px',
  }),
  userMediaUrl: css({
    maxWidth: 320,
    width: '100%',
    borderRadius: 5,
    marginTop: 8,
  }),
  maxWidthUnset: css({
    maxWidth: 'unset',
  }),
};
