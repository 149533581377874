import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFormsCountQuery, GetFormsCountQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFormsCount.graphql';

export const useGetFormsCountQuery = (options?: QueryHookOptions<GetFormsCountQuery, GetFormsCountQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFormsCountQuery, GetFormsCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFormsCountLazyQuery = (
  options?: LazyQueryHookOptions<GetFormsCountQuery, GetFormsCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFormsCountQuery, GetFormsCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
