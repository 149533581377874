import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { BackNavigatorV2, Dialog, ThemeButton } from '@src/components/molecules';
import {
  useGetLineChannelsQuery,
  useGetShopifyAccountQuery,
  useRemoveShopifyAccountMutation,
} from '@src/graphql/hooks';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';

enum ChannelTypes {
  LINE = 'LINE',
  SHOPIFY = 'SHOPIFY',
}

const ChannelConnect = () => {
  const [dialog, setDialog] = useState<ChannelTypes | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { data: dataLine } = useGetLineChannelsQuery();
  const { data: dataShopify } = useGetShopifyAccountQuery();
  const lineAccount = dataLine?.getLineChannels;
  const shopifyAccount = dataShopify?.getShopifyAccount;

  const [removeShopifyAccount] = useRemoveShopifyAccountMutation({
    refetchQueries: ['GetShopifyAccount'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      setDialog(null);
    },
  });

  const onClickDelete = async () => {
    setIsSubmitting(true);
    switch (dialog) {
      case ChannelTypes.SHOPIFY:
        await removeShopifyAccount({
          variables: {
            input: {
              id: shopifyAccount?.id,
            },
          },
        });
        break;
      default:
    }
    setIsSubmitting(false);
  };

  const channels = [
    {
      icon: 'shopify-color',
      isConnected: !!shopifyAccount,
      label: 'Shopify',
      to: ROUTES.MY_PAGE_CHANNEL_CONNECT_ADD_SHOPIFY,
      type: ChannelTypes.SHOPIFY,
    },
    {
      icon: 'line-color',
      isConnected: !!lineAccount,
      label: 'LINE',
      to: ROUTES.MY_PAGE_CHANNEL_CONNECT_ADD_LINE,
      type: ChannelTypes.LINE,
    },
  ];

  return (
    <div css={styles.container}>
      <Dialog
        cancelButtonProps={{
          onClick: () => setDialog(null),
        }}
        contentStyles={{
          background: THEME.colors.white,
          borderRadius: 15,
          height: isMobileView ? 324 : 186,
          maxWidth: 648,
          width: '90%',
        }}
        nextButtonProps={{
          disabled: isSubmitting,
          text: 'Delete',
          theme: 'red',
          onClick: onClickDelete,
        }}
        open={!!dialog}
        onClose={() => setDialog(null)}
      >
        <div css={styles.dialogContent}>
          <div>
            <Icomoon color="#ff5f5f" icon="warning" size={isMobileView ? 56 : 24} />
            <div>{t('Title.Delete Channel')}</div>
          </div>
          <div>
            {t('Annotation.Are you sure you want to delete Channel', {
              name: shopifyAccount ? shopifyAccount.shopName : '',
            })}
          </div>
        </div>
      </Dialog>

      {!isMobileView && <BackNavigatorV2 backPath={ROUTES.MY_PAGE} title="Channel Connect" />}

      <div css={styles.contentContainer}>
        <div css={styles.title}>{t('Title.Channel Connection')}</div>
        <div css={styles.channelsContainer}>
          {channels.map(({ icon, isConnected, label, to }) => (
            <div key={label}>
              <Icomoon icon={icon} size={24} />
              <div>{label}</div>
              {isConnected ? (
                <>
                  <ThemeButton prefixIcon={<Icomoon color="#3892e5" icon="check" size={12} />} text="Connected" />
                  {/* temporary hide delete option as AnyChat does not support delete function for now */}
                  {/* <div onClick={() => setDialog(type)}>
                    <Icomoon icon="trash-outlined" size={16} />
                  </div> */}
                </>
              ) : (
                <ThemeButton text="Connect" theme="black" to={to} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  channelsContainer: css({
    background: '#eef3f7',
    borderRadius: 15,
    boxShadown: '0 0 1px 0 rgba(110, 124, 137, 0.20), 0 0 24px 0 rgba(110, 124, 137, 0.05)',
    display: 'grid',
    gap: 1,
    overflow: 'hidden',

    '& > div': {
      alignItems: 'center',
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: 16,

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        width: 'fill-available',
      },

      '& > a, button': {
        borderRadius: 3,
        width: 'fit-content',
      },

      '& > div:nth-of-type(2)': {
        alignItems: 'center',
        border: THEME.box.borders.gray.main,
        borderRadius: 3,
        cursor: 'pointer',
        display: 'flex',
        height: 32,
        justifyContent: 'center',
        minWidth: 32,
      },
    },
  }),
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '24px 16px',
    },
  }),
  contentContainer: css({
    padding: '24px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  dialogContent: css({
    color: THEME.font.colors.black.main,
    display: 'grid',
    gap: THEME.box.gaps.l,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: '0 8px',
    },

    '& > div:nth-of-type(1)': {
      display: 'grid',
      gap: THEME.box.gaps.l,
      justifyItems: 'center',
      justifySelf: 'center',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        alignItems: 'center',
        display: 'flex',
        gap: THEME.box.gaps.s,
        justifySelf: 'flex-start',
      },

      '& > div': {
        fontSize: THEME.font.sizes.subTitle,
        fontWeight: 600,

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.subHeading,
        },
      },
    },

    '& > div:nth-of-type(2)': {
      fontSize: THEME.font.sizes.normal,
      textAlign: 'center',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        textAlign: 'unset',
      },
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
  }),
};

export default ChannelConnect;
