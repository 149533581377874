import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useDirLayout from '@src/libs/hooks/useDirLayout';
import { THEME } from '@src/libs/theme';
import * as React from 'react';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: any;
  name?: string;
}

interface InputProps {
  autoComplete?: string;
  error?: boolean;
  disabled?: boolean;
}

export interface TextProps extends FieldType, InputProps {
  className?: string;
  placeholder: string;
  type?: string;
  unit?: string;
  autoFocus?: boolean;
}

const Text = (props: TextProps) => {
  const { className, placeholder, unit, type, autoFocus, ...field } = props;
  const inputType = !!type ? type : 'text';
  const { dir } = useDirLayout();

  return (
    <div className={className} css={{ position: 'relative' }}>
      <Input
        dir={dir}
        type={inputType}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...(type === 'number' && {
          onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          },
        })}
        {...field}
      />
      {unit && <p css={styles.unit}>{unit}</p>}
    </div>
  );
};

const styles = {
  unit: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    padding: '0 12px',
    position: 'absolute',
    right: 1,
    top: 1,
    background: THEME.colors.white,
    color: '#828282',
    fontSize: THEME.font.sizes.hint,
    letterSpacing: '0.03em',
    pointerEvents: 'none',
  }),
};

const Input = styled.input<InputProps>(({ disabled, error }) => ({
  boxSizing: 'border-box',
  width: '100%',
  padding: '0 12px',
  background: disabled ? '#f2f2f2' : '#fff',
  border: THEME.box.borders.gray.main,
  borderColor: error ? 'tomato' : '#e0e8ed',
  borderRadius: 3,
  fontSize: 13,
  lineHeight: '32px',
  letterSpacing: '0.02em',
  height: 32,

  '&:focus': {
    ...(!error && { borderColor: '#3892E5' }),
  },
}));

export default Text;
