import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { DateRange, DayPicker, Matcher, SelectRangeEventHandler } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import Icomoon from '@src/components/atoms/Icomoon';
import Popover from '@src/components/atoms/Popover';
import MenuControlIcon from '@src/components/molecules/MenuControlIcon';
import ThemeButton from '@src/components/molecules/ThemeButton';
import { Select } from '@src/components/shared/Select';
import { TextForm } from '@src/components/shared/TextForm';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import { localizedDateFormatter, getDateLocal } from '@src/libs/date';
import usePageLayout from '@src/libs/hooks/usePageLayout';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import 'react-day-picker/dist/style.css';
import {
  formatCaption,
  getDefaultDate,
  getInputValue,
  getSelectedRangeValue,
  placeholderRange,
  placeholderSingle,
} from './helpers';
import { DayPickerDate, DayPickerRange, Period, RangeListOption } from './types';
import { useRange } from './useDatepickerState';

type ConditionalProps =
  | {
      range: boolean;
      rangeList?: RangeListOption[];
      showDateTextField?: boolean;
      value: Period;
      onChange?: (value: Period) => void;
    }
  | {
      range?: never;
      rangeList?: never;
      showDateTextField?: never;
      value: string;
      onChange?: (value: string) => void;
    };

interface DayPickerProps {
  className?: string;
  dateFormat?: string;
  disableClear?: boolean;
  disabled?: boolean;
  disabledRange?: Matcher | Matcher[];
  error?: boolean;
  inputFormat?: string;
  numberOfMonths?: number;
  placeholder?: string;
}

const CustomDayPicker = ({
  className,
  dateFormat = DEFAULT_FNS_DATE_FORMAT,
  disableClear,
  disabled,
  disabledRange,
  error,
  inputFormat = 'MMM dd, yyyy',
  numberOfMonths,
  placeholder,
  range,
  rangeList,
  showDateTextField,
  value,
  onChange,
}: DayPickerProps & ConditionalProps) => {
  const from = getDefaultDate(range ? (value as Period).startDate : (value as string));
  const to = range ? getDefaultDate((value as Period).endDate) : undefined;
  const [rangeValue, setRangeValue] = useState<DayPickerRange>({
    from,
    to,
    hoverFrom: range ? from : undefined,
    hoverTo: range ? to : undefined,
  });
  const [singleValue, setSingleValue] = useState<DayPickerDate>(from);
  const { isMobileView } = usePageLayout();
  const { i18n, t } = useTranslation();
  const calendarNumberOfMonths = isMobileView ? 1 : numberOfMonths || (range ? 2 : 1);
  const cellSize = isMobileView ? 40 : 32;
  const hoverValue = getInputValue(rangeValue.hoverFrom, rangeValue.hoverTo, i18n.language as AppLanguage);
  const inputValue = range
    ? value.startDate && value.endDate
      ? getInputValue(new Date(value.startDate), new Date(value.endDate), i18n.language as AppLanguage)
      : ''
    : singleValue
    ? localizedDateFormatter(singleValue, inputFormat, i18n.language as AppLanguage)
    : '';
  const selectValue = getSelectedRangeValue({
    rangeList,
    from: rangeValue.hoverFrom as Date,
    to: rangeValue.hoverTo as Date,
  });
  const selectCallback = useRange({ setState: setRangeValue });
  let onClosePopup: () => void;

  useEffect(() => {
    setRangeValue({
      from,
      to,
      hoverFrom: range ? from : undefined,
      hoverTo: range ? to : undefined,
    });
    setSingleValue(from);
  }, [value]);

  const onClear = () => {
    setRangeValue({ from: undefined, to: undefined, hoverFrom: undefined, hoverTo: undefined });
    setSingleValue(undefined);
    onChange?.((range ? { endDate: '', startDate: '' } : '') as Period & string);
  };

  const onClickApply = () => {
    onChange?.({
      startDate: rangeValue.from ? format(rangeValue.from, dateFormat) : '',
      endDate: rangeValue.to ? format(rangeValue.to, dateFormat) : '',
    } as Period & string);
    onClosePopup();
  };

  const onDayMouseEnter = (date: Date) => {
    if (rangeValue.from && !rangeValue.to) {
      if (date > rangeValue.from) {
        setRangeValue({ ...rangeValue, hoverFrom: rangeValue.from, hoverTo: date });
      } else {
        setRangeValue({ ...rangeValue, hoverFrom: date, hoverTo: rangeValue.from });
      }
    }
  };

  const onSelectRange = (dateRange: DateRange | undefined) => {
    // if from && to is selected, start new selection
    if (rangeValue.from && rangeValue.to && rangeValue.to && rangeValue.from) {
      const startDate = dateRange?.from === rangeValue.from ? dateRange?.to : dateRange?.from;
      setRangeValue({
        from: startDate,
        to: undefined,
        hoverFrom: startDate,
        hoverTo: undefined,
      });
    } else {
      setRangeValue({ from: dateRange?.from, to: dateRange?.to, hoverFrom: dateRange?.from, hoverTo: dateRange?.to });
    }
  };

  const onSelectSingle = (date: DayPickerDate) => {
    setSingleValue(date);
    onChange?.((date ? format(date, dateFormat) : '') as Period & string);
    onClosePopup();
  };

  return (
    <div className={className}>
      <Popover
        align="center"
        css={{ borderRadius: 9 }}
        renderClose={({ onClose }) => {
          onClosePopup = onClose;

          return range ? (
            <div css={styles.actionContainer}>
              {!isMobileView && <div>{hoverValue}</div>}
              <ThemeButton text="Cancel" width="max-content" onClick={onClose} />
              <ThemeButton
                disabled={!hoverValue}
                text="Apply"
                theme="blue"
                width="max-content"
                onClick={onClickApply}
              />
            </div>
          ) : null;
        }}
        renderTrigger={
          <DayPickerInput disabled={disabled} error={error}>
            <Icomoon icon="calendar" size={19} />
            <input
              css={{ flex: 1, fontSize: THEME.font.sizes.normal }}
              placeholder={placeholder || ((range ? t(placeholderRange) : placeholderSingle) as string)}
              readOnly
              value={inputValue}
            />
            <MenuControlIcon hasRemoveIcon={!disableClear && !disabled && !!inputValue} onClose={onClear} />
          </DayPickerInput>
        }
        side="bottom"
      >
        <div css={{ display: 'grid', gap: THEME.box.gaps.xxl, padding: 24 }}>
          {rangeList && rangeList.length > 0 && (
            <div css={styles.selectContainer}>
              <label
                aria-label="date-picker-label"
                css={{ fontWeight: 400, fontSize: '14px', color: THEME.font.colors.black.main, lineHeight: '20px' }}
              >
                {t('Date range')}
              </label>
              <Select
                options={rangeList?.map(el => ({ value: el.value, label: el.label || el.value, range: el.range }))}
                value={selectValue.value}
                onChange={val => {
                  if (val) {
                    selectCallback[val]();
                  }
                }}
              />
            </div>
          )}
          {showDateTextField && (
            <div css={styles.textFieldsContainer}>
              <TextForm
                disabled
                placeholder={t(placeholderRange)}
                title="Starting"
                value={rangeValue.from ? format(rangeValue.from, dateFormat) : ''}
              />
              <TextForm
                disabled
                placeholder={t(placeholderRange)}
                title="Ending"
                value={rangeValue.to ? format(rangeValue.to, dateFormat) : ''}
              />
            </div>
          )}

          <DayPicker
            // using styled component will causing typescript so use css instead
            css={styles.dayPicker(range)}
            defaultMonth={from}
            disabled={disabledRange}
            formatters={{ formatCaption }}
            locale={getDateLocal(i18n.language as AppLanguage)}
            mode={(range ? 'range' : 'single') as any}
            modifiersStyles={{
              hover: { background: '#eff8ff', color: '#139cd7' },
              main: { background: THEME.colors.blue.main, color: THEME.font.colors.white },
              today: { color: THEME.font.colors.error },
              ...(!range && {
                selected: { background: THEME.colors.blue.main, borderRadius: 32, color: THEME.font.colors.white },
              }),
            }}
            numberOfMonths={calendarNumberOfMonths}
            selected={(range ? rangeValue : singleValue) as Matcher}
            styles={{
              caption: { position: 'relative' },
              caption_label: {
                color: THEME.font.colors.black.main,
                fontSize: THEME.font.sizes.subHeading,
                fontWeight: '600',
                zIndex: 0,
                ...(calendarNumberOfMonths === 1 && {
                  fontSize: THEME.font.sizes.heading,
                  left: 0,
                  margin: '0 auto',
                  position: 'absolute',
                  right: 0,
                  width: 'fit-content',
                }),
              },
              cell: { height: cellSize, width: cellSize },
              day: {
                borderRadius: 'unset',
                fontSize: isMobileView ? 16 : 14,
                fontWeight: 400,
                height: cellSize,
                width: cellSize,
              },
              head_cell: {
                color: '#8b9898',
                fontSize: THEME.font.sizes.subordinate,
                fontWeight: 400,
                textTransform: 'capitalize',
              },
              nav: {
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              },
              nav_icon: { height: 10, width: 10 },
            }}
            onSelect={(range ? onSelectRange : onSelectSingle) as SelectRangeEventHandler}
            {...(range && {
              modifiers: {
                hover: { from: rangeValue.hoverFrom, to: rangeValue.hoverTo },
                main: [rangeValue.hoverFrom as Date, rangeValue.hoverTo as Date],
              },
              onDayMouseEnter,
            })}
          />
        </div>
      </Popover>
      <Global
        styles={css({
          /* to made portal over FocusedBlock, it is not easy to pass this into Popover itself */
          '[data-radix-popper-content-wrapper]': {
            zIndex: '100 !important',
          },
        })}
      />
    </div>
  );
};

const DayPickerInput = styled.div<{ disabled?: boolean; error?: boolean }>(({ disabled, error }) => ({
  alignItems: 'center',
  background: THEME.colors.white,
  border: '1px solid #dfe8ed',
  borderRadius: 3,
  display: 'flex',
  fontSize: 13,
  gap: THEME.box.gaps.s,
  height: 30,
  minWidth: 270,
  padding: '0 12px',
  position: 'relative',
  ...(disabled && {
    background: '#f7f7f7',
    cursor: 'default',
    pointerEvents: 'none',
  }),
  ...(error && { borderColor: THEME.colors.error }),

  '& > div:nth-of-type(2), svg:nth-of-type(2)': {
    position: 'relative',
    right: 'unset',
    top: 'unset',
    width: 'unset',
  },
}));

const styles = {
  actionContainer: css({
    alignItems: 'center',
    borderTop: '1px solid #e0e8ed',
    display: 'flex',
    gap: THEME.box.gaps.s,
    justifyContent: 'flex-end',
    padding: '16px 24px',

    '& > div': {
      color: THEME.font.colors.gray.main,
      display: 'flex',
      flex: 1,
      fontSize: THEME.font.sizes.subordinate,
    },
  }),
  dayPicker: (range?: boolean) =>
    css({
      justifySelf: 'center',
      margin: 0,

      '& .rdp-nav_button': {
        '&:hover, :focus-visible': {
          background: 'unset !important',
          border: 'unset',
        },
      },

      '& .rdp-cell': {
        '& > .rdp-button:hover': {
          borderRadius: '32px !important',
        },

        '& .rdp-day_selected.rdp-day_today': {
          color: `${THEME.colors.white} !important`,
        },

        '&:has(button):has(.rdp-day_range_start), &:has(button):has(.rdp-day_range_end)': {
          '& > .rdp-day_range_start': {
            borderBottomLeftRadius: '32px !important',
            borderTopLeftRadius: '32px !important',
          },

          '& > .rdp-day_range_end': {
            borderBottomRightRadius: '32px !important',
            borderTopRightRadius: '32px !important',
          },
        },

        ...(range && {
          '& > .rdp-day_selected:hover': {
            borderRadius: 'unset !important',
          },
        }),
      },
    }),
  selectContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    span: {
      margin: '0 10px',
    },
  }),
  textFieldsContainer: css({
    display: 'flex',
    gap: THEME.box.gaps.s,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      gap: THEME.box.gaps.l,
    },

    '& > div': {
      width: 'fill-available',

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        width: 136,
      },

      '& input': {
        background: THEME.colors.white,
      },
    },
  }),
};

export default CustomDayPicker;
