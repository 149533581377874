// Diff / Omit taken from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// -> in-depth explanation https://fettblog.eu/typescript-union-to-intersection/
export type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;

export interface DropdownOption {
  label: string;
  value: string;
}

export enum ViewportType {
  SMALL = 556,
  MEDIUM = 761,
  TABLET = 940,
  LARGE = 1271,
  EXTRA_LARGE = 1920,
}
