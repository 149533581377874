import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

interface PostRejectedMessagesProps {
  className?: string;
}

const PostRejectedMessages = ({ className }: PostRejectedMessagesProps) => {
  const { t } = useTranslation();

  return (
    <div className={className} css={styles.container}>
      <div>
        <Icomoon color="#f8b74a" icon="warning" size={12} />
        <div>{t('Annotation.Your content needs to be improved')}</div>
      </div>
      <div>{t('Annotation.Please check the Report and improve the draft post')}</div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    gap: THEME.box.gaps.xs,
    width: 'fill-available',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.s,
      width: '100%',

      '& > div': {
        color: '#f8b74a',
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '90%',
      },
    },

    '& > div:nth-of-type(2)': {
      color: '#f8b74a',
      fontSize: 13,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
};

export default PostRejectedMessages;
