import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Icomoon, VideoPreview } from '@src/components/atoms';
import { ImageSlider } from '@src/components/molecules';
import { formatIntNumber } from '@src/libs/format';
import { defaultEmptyImage, isVideoThumbnail } from '@src/libs/image';
import { THEME } from '@src/libs/theme';
import { CarouselPost } from './model';

interface PostCarouselProps {
  isReel?: boolean;
  posts: CarouselPost[];
  className?: string;
  onPostClick?: (id: number) => void;
  type?: 'reach' | 'likes';
}
const PostCarousel = (props: PostCarouselProps) => {
  const { t } = useTranslation();
  const { isReel, posts, className, onPostClick, type = 'likes' } = props;

  return (
    <>
      <div className={className} css={{ paddingLeft: 16 }}>
        {!!posts?.length && (
          <>
            <ImageSlider data={posts}>
              {posts.map(post => {
                const items = [
                  { title: type === 'reach' ? 'Reach' : 'Like', value: post[type] },
                  { title: 'Comments', value: post.comments },
                ];

                return (
                  <div css={styles.postImageWrapper} key={post.id}>
                    {isVideoThumbnail(post.thumbNail) ? (
                      <div css={{ cursor: 'pointer' }} onClick={() => onPostClick?.(post.id)}>
                        <VideoPreview css={{ marginRight: 16 }} url={post.thumbNail || null} width="160" height="160" />
                      </div>
                    ) : (
                      <>
                        {isReel && (
                          <Icomoon
                            color="#fff"
                            css={{ position: 'absolute', right: 22, top: 8 }}
                            icon="movie-clapboard"
                            size={18}
                          />
                        )}
                        <img
                          css={styles.postImage}
                          src={defaultEmptyImage(post.thumbNail)}
                          onClick={() => onPostClick?.(post.id)}
                        />
                      </>
                    )}
                    <div css={styles.engagementInfo}>
                      {items.map((item, index) => (
                        <div css={{ padding: '0 8px 8px' }} key={index}>
                          <p css={{ marginBottom: 3 }}>{t(item.title)}</p>
                          <p>{formatIntNumber(item.value)}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </ImageSlider>
          </>
        )}
      </div>
    </>
  );
};

const styles = {
  engagementInfo: css({
    color: THEME.font.colors.white,
    fontSize: 11,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))',
    position: 'absolute',
    width: 'calc(100% - 16px)',
    bottom: 0,
    left: 0,
    cursor: 'pointer',
  }),
  postImage: css({
    width: 160,
    height: 160,
    marginRight: 16,
    objectFit: 'contain',
    cursor: 'pointer',
  }),
  postImageWrapper: css({
    position: 'relative',

    '&:focus': {
      outline: 'none',
    },
  }),
};

export default PostCarousel;
