import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

interface RedirectStateProps {
  accessUrlBeforeSignIn?: string;
}

const { persistAtom } = recoilPersist();

export const redirectState = atom<RedirectStateProps>({
  key: 'redirectState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
