import { css } from '@emotion/react';
import { ViewportType } from '@src/libs/types';
import { useTranslation, Trans } from 'react-i18next';
import inputPostUrl from '@src/assets/img/findJobs/inputPostUrl.png';
import joinCampaignCode from '@src/assets/img/findJobs/joinCampaignCode.png';
import joinCampaignCodeHashtags from '@src/assets/img/findJobs/joinCampaignCodeHashtags.png';
import joinCampaignHashtags from '@src/assets/img/findJobs/joinCampaignHashtags.png';
import joinCampaignHttps from '@src/assets/img/findJobs/joinCampaignHttps.png';
import joinCampaignHttpsHashtags from '@src/assets/img/findJobs/joinCampaignHttpsHashtags.png';
import { Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { TextFormV2 } from '@src/components/shared';
import { useCopy } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import styled from '@emotion/styled';
import { CampaignProps, HelpYouCreateCaptionsProps, PostTrackingUrlProps } from './types';

export const CopyHashtags = ({
  couponCode,
  hashtags,
  isCouponCampaign,
  isPostTracking,
  trackingUrl,
}: CampaignProps) => {
  const { t } = useTranslation();
  const { handleCopyHasHashtag, handleCopyHasText } = useCopy();

  const { image, message } = isCouponCampaign
    ? isPostTracking
      ? { image: joinCampaignCodeHashtags, message: 'Please include "Coupon code" & "Hashtags" in your post' }
      : { image: joinCampaignCode, message: 'Please include "Coupon code" in your post' }
    : trackingUrl
    ? isPostTracking
      ? { image: joinCampaignHttpsHashtags, message: 'Please include tracking URL in your post' }
      : { image: joinCampaignHttps, message: 'Please include tracking URL in your post' }
    : { image: joinCampaignHashtags, message: 'Paste “Hashtags” in your Post' };

  return (
    <div css={styles.dialogContent}>
      <div css={styles.dialogTitle}>{t('Dialog.Next Step')}</div>
      <div css={styles.dialogInfo}>
        <div css={styles.dialogImageContainer}>
          <img height="115" src={image} width="164" />
        </div>
        <div css={styles.dialogSubTitle}>{t(`Title.${message}`)}</div>
        <div css={styles.dialogDescription}>
          <Trans i18nKey={`Annotation.${message}`}>
            text<b>text</b>
          </Trans>
        </div>

        <div css={{ display: 'grid', gap: THEME.box.gaps.l }}>
          {isCouponCampaign && (
            <CopyInfoContainer>
              <div>{t('Label.Copy Code')}</div>
              <div>{couponCode}</div>
              <ThemeButton text="Copy Code" onClick={() => handleCopyHasText(couponCode || '')} />
            </CopyInfoContainer>
          )}

          {trackingUrl && (
            <CopyInfoContainer>
              <div>{t('Tracking URL')}</div>
              <div>{trackingUrl}</div>
              <ThemeButton text="Copy URL" onClick={() => handleCopyHasText(trackingUrl)} />
            </CopyInfoContainer>
          )}

          {isPostTracking && !!hashtags?.length && (
            <div css={styles.dialogHashtagsContainer}>
              <div>{t('Hashtags')}</div>
              <div>
                {hashtags.map((hashtag, hashtagIdx) => (
                  <div key={hashtagIdx}>{`#${hashtag}`}</div>
                ))}
              </div>
              <ThemeButton text="Copy Hashtags" onClick={() => handleCopyHasHashtag(hashtags)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const HelpYouCreateCaptions = ({
  helpYouCreateCaptionsProps: { onClickUseItNow },
}: HelpYouCreateCaptionsProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.dialogContent}>
      <div css={styles.dialogTitle}>{t('Dialog.Next Step')}</div>
      <div css={styles.dialogImageContainer}>
        <div css={styles.captionGeneratorImage}>
          <div className="content-container">
            <div className="content">
              <div className="title">
                <label>{t('Label.Caption Generator by AI')}</label>
                <div>{t('Beta')}</div>
              </div>
              <label className="description">{t('Annotation.Just click Generate and let AI create a post')}</label>
            </div>

            <div className="tabs">
              <div>{t('Tab.Generate Now')}</div>
              <div>{t('Tab.Customize')}</div>
            </div>
          </div>

          <div className="generate-button">
            <Icomoon icon="sun-rays-top-left" size={20} />
            <div>
              <Icomoon icon="generate-caption-stars" size={11} />
              <label>{t('Button.Generate')}</label>
            </div>
            <Icomoon icon="sun-rays-bottom-right" size={20} />
          </div>
        </div>
      </div>

      <label css={styles.generateCaptionTitle}>{t('Help you create captions')}</label>
      <div css={styles.generateCaptionDescriptions}>
        <label>{t('Annotation.AI will create captions that include the content of this campaign')}</label>
        <label>{t('Annotation.The suggestions from the AI may not always be accurate')}</label>
        <label>
          {t(
            'Annotation.Please make sure to check the content yourself and make any necessary corrections before using it'
          )}
        </label>
      </div>

      <ThemeButton css={styles.useItNowButton} text="Use it now" onClick={onClickUseItNow} />
    </div>
  );
};

export const InputPostUrl = ({ postTrackingUrlProps: { readOnly, value, onChange } }: PostTrackingUrlProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.dialogContent}>
      <div css={styles.dialogTitle}>{t('Dialog.Next Step')}</div>
      <div css={styles.inputPostUrlContent}>
        <div>
          <img height={118} src={inputPostUrl} width={127} />
        </div>
        <div>{t('Annotation.Report after you made a post on Social Media')}</div>
        <div>
          {t(`Annotation.If you don't tap the button, we cannot track your post and cannot pay commission to you`)}
        </div>
        <TextFormV2
          readOnly={readOnly}
          title="Input the Facebook post URL here"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export const HowToReportTweet = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.dialogContent}>
      <div css={styles.dialogTitle}>{t('Dialog.Next Step')}</div>
      <div css={styles.dialogInfo}>
        <div css={styles.dialogSubTitle}>{t('Report after you made a post on Social Media')}</div>
        <div css={styles.dialogDescription}>
          {t(`If you don't tap the button, we cannot track your post and cannot pay a commission to you`)}
        </div>
        <div css={styles.dialogImageContainer}>
          <div css={styles.howToReportTweet}>
            <div>
              <ThemeButton text="Copy Hashtags" />

              <div>
                <Icomoon color="#3892e5" icon="clock-anti-clockwise" size={11} />
                <div>
                  <div>{t('Post reflection time')}</div>
                  <div>{t('HashTag4')}</div>
                </div>
              </div>

              <div>{t('After you have made a post on social media')}</div>
              <div>{t('Annotation.Please report that you made a post to us by clicking a button below')}</div>
              <ThemeButton text="I made a tweet" />

              <div>
                <ThemeButton prefixIcon={<Icomoon icon="file-text-outlined" size={12} />} text="Report" />
                <ThemeButton customPalette={mainBlack} text="Add Posts" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const styles = {
  captionGeneratorImage: css({
    display: 'grid',
    justifyContent: 'center',
    position: 'relative',
    width: 247,

    '& > .content-container': {
      borderRadius: THEME.box.borderRadius.s,
      boxShadow: THEME.box.shadows.outer,
      height: 166,
      overflow: 'hidden',
      width: 198,

      '&::before': {
        background: 'linear-gradient(101deg, #5C5BFF 29.39%, #15D4FF 43.46%, #FFD235 66.45%, #FF942E 82.33%)',
        content: '""',
        display: 'block',
        height: 3.5,
      },

      '& > .content': {
        background:
          'conic-gradient(from 136deg at 54.7% 99.4%, rgba(92, 91, 255, 0.08) 0deg, rgba(21, 212, 255, 0.08) 144.0000021457672deg, rgba(255, 210, 53, 0.08) 251.99999570846558deg, rgba(255, 148, 46, 0.08) 360deg), #FFF',
        display: 'grid',
        gap: 9.3,
        padding: '14px 9.5px 9.5px 9.5px',

        '& > .title': {
          alignItems: 'center',
          color: THEME.font.colors.black.main,
          display: 'flex',
          fontSize: 9.3,
          fontWeight: 600,
          gap: THEME.box.gaps.xs,

          '& > div': {
            border: '0.58px solid #acacac',
            borderRadius: 1.7,
            color: '#acacac',
            fontSize: 7,
            fontWeight: 600,
            padding: '0 3.5px',
          },
        },

        '& > .description': {
          color: THEME.font.colors.gray.main,
          fontSize: 9.3,
        },
      },

      '& > .tabs': {
        borderBottom: THEME.box.borders.gray.main,
        display: 'flex',
        height: 27.4,

        '& > div': {
          alignItems: 'center',
          color: '#a8b4bf',
          display: 'grid',
          fontSize: 9,
          fontWeight: 600,
          justifyContent: 'center',
          margin: '0 9px',
          width: 'fill-available',
        },

        '& > div:nth-of-type(1)': {
          color: THEME.font.colors.black.main,
          borderBottom: '1px solid #27313b',
        },
      },
    },

    '& > .generate-button': {
      background: 'conic-gradient(#ffd235, #ff942e, #5c5bff, #15d4ff)',
      borderRadius: 6.5,
      bottom: 7,
      gap: THEME.box.gaps.s,
      height: 38,
      position: 'absolute',
      width: 247,

      '& > svg:nth-of-type(1)': {
        left: -10,
        top: -10,
        position: 'absolute',
      },

      '& > div': {
        alignItems: 'center',
        background: THEME.colors.white,
        borderRadius: 6.5,
        color: THEME.font.colors.gray.main,
        content: '""',
        display: 'flex',
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
        gap: 6,
        height: 36,
        justifyContent: 'center',
        left: 1,
        position: 'absolute',
        top: 1,
        width: 245,
      },

      '& > svg:nth-of-type(2)': {
        bottom: -10,
        position: 'absolute',
        right: -10,
      },
    },
  }),
  dialogContent: css({
    display: 'grid',
    overflow: 'auto',
    padding: '32px 8px 24px 8px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: '48px 16px 16px 16px',
    },
  }),
  dialogDescription: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    justifySelf: 'center',
    marginBottom: 16,
    maxWidth: 400,
    textAlign: 'left',
    whiteSpace: 'pre-line',

    '& > b': {
      color: '#e3544a',
      fontWeight: 700,
    },
  }),
  dialogHashtagsContainer: css({
    display: 'grid',
    width: '100%',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 8,
      textAlign: 'start',
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: THEME.box.gaps.s,
      marginBottom: 8,

      '& > div': {
        background: '#eef3f7',
        borderRadius: 5,
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        maxWidth: '100%',
        padding: 8,
        wordBreak: 'break-word',
      },
    },

    '& > button': {
      borderRadius: 9,
      height: 48,
      justifySelf: 'center',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        width: 226,
      },
    },
  }),
  dialogImageContainer: css({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  }),
  dialogInfo: css({
    display: 'grid',
    height: 'fit-content',
    justifySelf: 'center',
    maxWidth: 480,
    textAlign: 'center',
    width: '100%',
  }),
  dialogSubTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
    justifySelf: 'center',
    marginBottom: 8,
    textAlign: 'left',
  }),
  dialogTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 600,
    marginBottom: 16,
  }),
  generateCaptionDescriptions: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      textAlign: 'center',
    },
  }),
  generateCaptionTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
    marginBottom: 8,
    textAlign: 'center',
  }),
  howToReportTweet: css({
    background: '#f6f8fa',
    borderRadius: 9,
    display: 'flex',
    height: 308,
    justifyContent: 'center',
    maxWidth: 311,
    textAlign: 'left',
    width: '100%',

    '& > div': {
      background: THEME.colors.white,
      borderRadius: '0 0 15px 15px',
      boxShadow: THEME.box.shadows.outer,
      height: 273,
      padding: '0 10px 10px 10px',
      position: 'relative',
      width: 221,

      '& > button': {
        borderRadius: 5,
        cursor: 'pointer',
        marginBottom: 16,
        pointerEvents: 'none',

        '& span': {
          fontSize: 11,
        },
      },

      '& > div:nth-of-type(1)': {
        background: '#e7f2fc',
        border: '1px solid #3892e5',
        borderRadius: 9,
        display: 'flex',
        gap: 6,
        marginBottom: 16,
        padding: 8,
        width: 'fill-available',

        '& > div': {
          display: 'grid',
          gap: THEME.box.gaps.xs,
          width: 'fill-available',

          '& > div:nth-of-type(1)': {
            color: THEME.font.colors.blue.main,
            fontSize: 9,
            fontWeight: 700,
          },

          '& > div:nth-of-type(2)': {
            color: '#5aa4ea',
            fontSize: 8,
          },
        },
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.hint,
        fontWeight: 600,
        marginBottom: 10,
      },

      '& > div:nth-of-type(3)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.hint,
        marginBottom: 10,
      },

      '& > button:nth-of-type(2)': {
        position: 'relative',

        '&::before': {
          border: '2px dashed #27313b',
          borderRadius: 9,
          content: '""',
          display: 'block',
          height: 'calc(100% + 12px)',
          left: -16,
          position: 'absolute',
          top: -8,
          width: 'calc(100% + 28px)',
        },
      },

      '& > div:nth-of-type(4)': {
        borderTop: '1px solid #e6e8e7',
        bottom: 0,
        display: 'flex',
        gap: THEME.box.gaps.s,
        left: 0,
        padding: '12px 8px',
        position: 'absolute',
        width: 'fill-available',

        '& > button': {
          borderRadius: 5,
          cursor: 'pointer',
          height: 35,
          pointerEvents: 'none',

          '& span': {
            fontSize: THEME.font.sizes.hint,
          },
        },
      },
    },
  }),
  inputPostUrlContent: css({
    color: THEME.font.colors.black.main,
    display: 'grid',
    justifyItems: 'center',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      background: '#f6f8fa',
      borderRadius: 9,
      display: 'flex',
      height: 140,
      justifyContent: 'center',
      marginBottom: 8,
      maxWidth: 311,
      width: '100%',
    },

    '& > div:nth-of-type(2)': {
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 700,
      justifySelf: 'flex-start',
      marginBottom: 16,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        justifySelf: 'center',
      },
    },

    '& > div:nth-of-type(3)': {
      fontSize: THEME.font.sizes.normal,
      justifySelf: 'flex-start',
      marginBottom: 24,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        justifySelf: 'center',
      },
    },
  }),
  useItNowButton: css({
    borderRadius: THEME.box.borderRadius.m,
    fontSize: THEME.font.sizes.subHeading,
    height: 48,
    justifySelf: 'center',
    maxWidth: 298,
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.normal,
    },
  }),
};

const CopyInfoContainer = styled.div({
  display: 'grid',

  '& > div:nth-of-type(1)': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 4,
    textAlign: 'start',
  },

  '& > div:nth-of-type(2)': {
    border: THEME.box.borders.gray.main,
    borderRadius: 9,
    boxShadow: THEME.box.shadows.outer,
    color: THEME.font.colors.gray.main,
    fontSize: '16',
    marginBottom: 8,
    maxWidth: '100%',
    minWidth: 0,
    overflow: 'hidden',
    padding: '14px 12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& > button': {
    borderRadius: 9,
    height: 48,
    justifySelf: 'center',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      width: 226,
    },
  },
});
