import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import {
  EngagementCampaignPostStatus,
  EngagementDraftPostHistoryRecordForInfluencer,
} from '@src/__generated__/globalTypes';

interface PostHistoryProps {
  postHistory: EngagementDraftPostHistoryRecordForInfluencer[];
}

const PostHistory = ({ postHistory }: PostHistoryProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div css={styles.title}>{t('History')}</div>
      {postHistory.length > 0 ? (
        postHistory.map(history => {
          const showMessageTitle = history.postStatus === EngagementCampaignPostStatus.READY_TO_POST || !history.reason;
          const { color, message } = (() => {
            switch (history.postStatus) {
              case EngagementCampaignPostStatus.APPROVED:
              case EngagementCampaignPostStatus.READY_TO_POST:
                return { color: styles.darkGreenText, message: 'Approved the post' };
              case EngagementCampaignPostStatus.REJECT:
                return { color: styles.darkYellowText, message: 'Rejected to Post' };
              default:
                return { color: undefined, message: '' };
            }
          })();

          return (
            <div css={styles.historyContainer} key={history.id}>
              <div css={styles.dateTitle}>{format(new Date(history.createdDate), 'MMM dd, yyyy')}</div>
              {showMessageTitle ? (
                <HistoryTitle textColor={color}>{t(message)}</HistoryTitle>
              ) : (
                <div css={styles.reasonContainer}>
                  <HistoryTitle>{history.reason}</HistoryTitle>
                  <div>{history.detailedReason}</div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div css={styles.noHistory}>{t('No History')}</div>
      )}
    </div>
  );
};

const HistoryTitle = styled.div<{ textColor?: SerializedStyles }>(({ textColor }) => ({
  fontSize: 13,
  fontWeight: 600,
  ...(textColor ? textColor : { color: THEME.font.colors.black.main }),
}));

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    padding: '8px 24px',

    '& > div': {
      borderBottom: '1px solid #dee5ec',
    },

    '& > div:last-child': {
      border: 'none',
    },
  }),
  darkGreenText: css({
    color: 'rgb(64, 184, 124)',
  }),
  darkYellowText: css({
    color: 'rgb(255, 182, 61)',
  }),
  dateTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: 13,
  }),
  historyContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px 0',

    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexBasis: '20%',
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '80%',
    },
  }),
  noHistory: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: 200,
    width: '100%',
  }),
  reasonContainer: css({
    display: 'flex',
    flexWrap: 'wrap',

    '& > div': {
      display: 'flex',
      flexBasis: '100%',
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subordinate,
      marginTop: 8,
    },
  }),
  title: css({
    borderBottom: '1px solid #dee5ec',
    color: THEME.font.colors.black.main,
    fontSize: 13,
    fontWeight: 600,
    padding: '16px 0',
  }),
};

export default PostHistory;
