import { useMediaQuery } from 'react-responsive';
import { matchPath } from 'react-router-dom';
import { ROUTES } from '@src/shared/routes';
import { ViewportType } from '../types';

export default () => {
  const isMobileView = useMediaQuery({ maxWidth: ViewportType.TABLET });
  const isSmallView = useMediaQuery({ maxWidth: ViewportType.SMALL });
  const isVerticalView = useMediaQuery({ maxWidth: ViewportType.MEDIUM });
  // useLocation may be used only in the context of a <Router> component so used location.pathname instead.
  const isYoutubeCmsPath = !![ROUTES.YOUTUBE_CMS_MUSIC, ROUTES.YOUTUBE_CMS_MY_PAGE, ROUTES.YOUTUBE_CMS_REVENUE].find(
    route => !!matchPath(route, `/${location.pathname.split('/')[1]}`)
  );

  return {
    isSmallView,
    isMobileView,
    isVerticalView,
    isYoutubeCmsPath,
  };
};
