import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { THEME } from '@src/libs/theme';

interface MenuItemProps {
  children: ReactNode;
  onClick: () => void;
  className?: string;
}
export const MenuItem = ({ children, ...restProps }: MenuItemProps) => (
  <div css={styles.menuItem} {...restProps}>
    {children}
  </div>
);

const styles = {
  menuItem: css({
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    fontWeight: 400,
    fontSize: THEME.font.sizes.normal,
    lineHeight: '18px',
    cursor: 'pointer',

    '&:hover': {
      background: '#f6f8fa',
    },
  }),
};
