import { format } from 'date-fns';

export const formatTimestampToDisplayDatetime = (timeStamp: number, formatStr: string = 'yyyy/MM/dd HH:mm') => {
  if (!timeStamp) {
    return '';
  }

  const dateObj = new Date(timeStamp);

  return format(dateObj, formatStr);
};
