import { css } from '@emotion/react';
import { THEME } from '@src/libs/theme';
import { ReactNode } from 'react';

interface IconProps {
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}

const Icon = (props: IconProps) => {
  const { children, onClick, className } = props;

  return (
    <span className={className} css={styles.span} onClick={onClick}>
      {children}
    </span>
  );
};

const styles = {
  span: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontSize: THEME.font.sizes.subTitle,
    opacity: 0.9,
    cursor: 'pointer',
    width: 'fit-content',

    '&:hover': {
      opacity: 0.7,
    },

    '[dir="rtl"] &': {
      transform: 'scaleX(-1)',
    },
  }),
};

export default Icon;
