import { ShopifyOrderPaymentStatus, ShopifyOrderShippingStatus } from '@src/__generated__/globalTypes';

export const getPaymentStatusText = (status: ShopifyOrderPaymentStatus) => {
  switch (status) {
    case ShopifyOrderPaymentStatus.PAID:
      return 'Paid';
    case ShopifyOrderPaymentStatus.PARTIALLY_REFUNDED:
      return 'Partially refunded';
    case ShopifyOrderPaymentStatus.PENDING:
      return 'Payment pending';
    case ShopifyOrderPaymentStatus.REFUNDED:
      return 'Refunded';
    default:
      return 'Other';
  }
};

export const getShippingStatusText = (status?: ShopifyOrderShippingStatus | null) => {
  switch (status) {
    case ShopifyOrderShippingStatus.FULFILLED:
      return 'Shipped';
    case null:
      return 'Unshipped';
    default:
      return 'Other';
  }
};
