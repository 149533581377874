import { atom } from 'recoil';

type LineWidgetStateProps =
  | {
      extendedBottom: number;
      isHidden?: boolean;
    }
  | {
      extendedBottom?: number;
      isHidden: boolean;
    };

export const lineWidgetState = atom<Partial<LineWidgetStateProps>>({
  key: 'lineWidgetState',
  default: {},
});
