import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import yup, {
  InfluencerEmailSchema,
  LanguageSchema,
  NameSchema,
  PasswordConfirmSchema,
  PasswordSchema,
} from '@src/libs/validation';
import { useGetPersonalUserDetailQuery, useUpdatePersonalUserDetailMutation } from '@src/graphql/hooks';
import YourAccount, { LanguageMapping, PersonalInformation } from './YourAccount';

const Index = () => {
  const { enqueueSnackbar, i18n, t } = useQueryHelper();
  const { data } = useGetPersonalUserDetailQuery({ fetchPolicy: 'no-cache' });
  const [updatePersonalUserDetail] = useUpdatePersonalUserDetailMutation();
  const validationSchema = yup.object().shape(
    {
      displayLanguage: LanguageSchema,
      email: InfluencerEmailSchema,
      name: NameSchema,
      password: yup.string().when('password', {
        is: (password: string) => password && password.length > 0,
        then: PasswordSchema,
      }),
      passwordConfirm: yup.string().when('password', {
        is: (password: string) => password && password.length > 0,
        then: PasswordConfirmSchema,
      }),
    },
    [['password', 'password']]
  );

  const personalInfo = data?.getPersonalUserDetail;
  const personalInformation = {
    displayLanguage: personalInfo?.displayLanguage || LanguageMapping[i18n.language],
    email: personalInfo?.email || '',
    name: personalInfo?.name || '',
    password: '',
    passwordConfirm: '',
    notificationSetting: personalInfo?.notificationSetting || false,
  };

  const methods = useForm<PersonalInformation>({
    defaultValues: personalInformation,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(personalInformation);
  }, [personalInformation]);

  const onSubmit = async (values: PersonalInformation) => {
    try {
      const results = await updatePersonalUserDetail({
        variables: {
          input: {
            notificationSetting: values.notificationSetting,
            displayLanguage: values.displayLanguage,
            email: values.email,
            name: values.name,
            password: values.password,
          },
        },
      });
      if (results?.data?.updatePersonalUserDetail?.ok) {
        const language = Object.keys(LanguageMapping).find(key => LanguageMapping[key] === values.displayLanguage);

        if (language !== i18n.language) {
          i18n.changeLanguage(language);
        }
        enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.editMyPageAccount);
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <YourAccount />
      </form>
    </FormProvider>
  );
};

export default Index;
