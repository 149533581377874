import { useRecoilState, useResetRecoilState, RecoilState } from 'recoil';

export const useRecoil = <T extends Record<string, any> | null>(state: RecoilState<T>) => {
  const [recoilState, setRecoilState] = useRecoilState(state);
  const resetRecoilState = useResetRecoilState(state);

  return {
    recoilState,
    resetRecoilState,
    setRecoilState,
  };
};
