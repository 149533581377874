import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { BackNavigatorV2, ThemeButton } from '@src/components/molecules';
import { usePageLayout } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';

const CampaignFinished = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      <div css={{ maxWidth: 648, width: '100%' }}>
        {!isMobileView && <BackNavigatorV2 backPath={ROUTES.FIND_JOBS} title="Campaign has finished" />}
        <div css={styles.contentContainer}>
          <div>
            <Icomoon icon="ufo" size={48} />
          </div>
          <div>{t('Title.This campaign has finished')}</div>
          <div>{t('Annotation.Please join next time')}</div>
        </div>
        <ThemeButton
          css={{ borderRadius: 9, height: 48 }}
          customPalette={mainBlack}
          text="Back to Home"
          to={ROUTES.FIND_JOBS}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    justifyItems: 'center',
    padding: '48px 16px',
  }),
  contentContainer: css({
    background: THEME.colors.white,
    borderRadius: 14,
    display: 'grid',
    marginBottom: 32,
    padding: '24px 20px',
    textAlign: 'center',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      background: '#ed6a65',
      borderRadius: '50%',
      display: 'flex',
      height: 64,
      justifyContent: 'center',
      justifySelf: 'center',
      marginBottom: 24,
      width: 64,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      marginBottom: 8,
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subordinate,
    },
  }),
};

export default CampaignFinished;
