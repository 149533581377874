import React from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { css, Global } from '@emotion/react';
import { Icomoon, Icon } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { CampaignDetails, CampaignDetailsStyledComponents as SC, JoinButton } from '@src/components/shared';
import { useGetAutoManagedCampaignForInfluencerSearchJobQuery } from '@src/graphql/hooks';
import { usePageLayout } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';

const Details = () => {
  const { isMobileView } = usePageLayout();
  const params = useParams<Record<any, string>>();
  const searchId = Number(params.id);
  const { data, loading } = useGetAutoManagedCampaignForInfluencerSearchJobQuery({
    variables: { input: { id: searchId } },
  });

  const campaign = data?.getAutoManagedCampaignForInfluencerSearchJob;

  if (loading) {
    return (
      <SC.Container>
        <ListLoading />
      </SC.Container>
    );
  }

  if (!searchId || !campaign) {
    return <Navigate to={ROUTES.FIND_JOBS} />;
  }

  const { isJoined } = campaign;
  const hasJoinButton = !isJoined;
  const hasMobileActions = isMobileView && hasJoinButton;

  return (
    <SC.Container hasMobileActions={hasMobileActions}>
      {!isMobileView && (
        <SC.Header>
          <Link to={ROUTES.FIND_JOBS}>
            <Icon>
              <Icomoon css={{ cursor: 'pointer', transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Icon>
          </Link>
          {hasJoinButton && (
            <div>
              <JoinButton campaign={campaign} isAutoManagedCampaign searchId={searchId} />
            </div>
          )}
        </SC.Header>
      )}

      <CampaignDetails campaign={campaign} isAutoManaged searchId={searchId} />

      {hasMobileActions && (
        <SC.ActionContainer>
          <JoinButton campaign={campaign} isAutoManagedCampaign searchId={searchId} />
        </SC.ActionContainer>
      )}

      <Global
        styles={
          hasMobileActions &&
          css({
            '.contact-line-widget': {
              bottom: 96,
            },
          })
        }
      />
    </SC.Container>
  );
};

export default Details;
