import React from 'react';
import { generatePath, useParams, Navigate, NavigateProps } from 'react-router-dom';

const NavigateWithParams = (props: NavigateProps) => {
  const params = useParams();
  const redirectWithParams = generatePath(props.to as string, params);

  return <Navigate {...props} to={redirectWithParams} />;
};

export default NavigateWithParams;
