import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import anyCreatorBlack from '@src/assets/img/anyCreatorBlack.png';
import { APP_NAME } from '@src/libs/constant';

interface HeaderProps {
  className?: string;
  href?: LocationDescriptor;
  width?: string;
}

const Logo = (props: HeaderProps) => {
  const { className, href, width } = props;

  return (
    <div className={className} css={{ width: width || '100%' }}>
      {href ? (
        <Link to={href}>
          <img css={{ width: '100%' }} src={anyCreatorBlack} alt={`${APP_NAME} Influencer Marketing Platform`} />
        </Link>
      ) : (
        <img css={{ width: '100%' }} src={anyCreatorBlack} alt={`${APP_NAME} Influencer Marketing Platform`} />
      )}
    </div>
  );
};

export default Logo;
