import styled from '@emotion/styled';
import React, { ReactElement, CSSProperties, forwardRef, SVGAttributes } from 'react';
import { css } from '@emotion/react';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { usePageLayout } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { useDirLayout } from '@src/libs/hooks';

export interface ModalProps {
  open: boolean;
  title: string;
  cancel?: string;
  execute?: string;
  disabled?: boolean;
  handleExecute?: () => void;
  onClose: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  siblingPostId?: { prevId: number | null; nextId: number | null };
  children: ReactElement<any> | null;
  bgColor?: string;
  arrowsTop?: string;
  closeOnDocumentClick?: boolean;
  overridePopurStyles?: CSSProperties;
}

const CarouselModal = (props: ModalProps) => {
  const { open, onClose, children, onClickNext, onClickPrev, siblingPostId, overridePopurStyles } = props;
  const { isMobileView, isSmallView, isVerticalView } = usePageLayout();
  const { dir } = useDirLayout();

  const popupStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: !isMobileView ? '900px' : !isSmallView ? '450px' : '90%',
    height: isVerticalView ? '700px' : '500px',
    maxHeight: '90vh',
    padding: '0',
    border: 'none',
    borderRadius: isVerticalView ? '15px' : '5px',
    maxWidth: 'unset',
  };

  return (
    <Dialog
      contentStyles={{ ...popupStyles, ...overridePopurStyles }}
      open={open}
      removeContentPadding
      onClose={onClose}
      {...(isVerticalView && {
        cancelButtonProps: {
          styles: [smallButtonStyles, css({ borderRadius: '5px !important', width: 'fill-available !important' })],
          text: 'Close',
          onClick: onClose,
        },
      })}
    >
      <ModalWrapper dir={dir}>
        {!!siblingPostId?.prevId && (
          <IconWrapper onClick={onClickPrev}>
            <ModalArrow css={styles.leftArrow} />
          </IconWrapper>
        )}
        <ContentWrapper>{children}</ContentWrapper>
        {!!siblingPostId?.nextId && (
          <IconWrapper onClick={onClickNext}>
            <ModalArrow css={styles.rightArrow} />
          </IconWrapper>
        )}
      </ModalWrapper>
    </Dialog>
  );
};

export interface IconProps extends SVGAttributes<SVGElement> {
  children?: never;
  color?: string;
}
export const ModalArrow = forwardRef<SVGSVGElement, IconProps>(
  (
    { width = '24px', height = '24px', viewBox = '0 0 24 24', strokeWidth = '2.5', color = 'white', ...props },
    forwardedRef
  ) => (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M9 17.9993L15 11.9993L9 5.99927"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
);
ModalArrow.displayName = 'ModalArrow';

const styles = {
  rightArrow: css({
    position: 'absolute',
    right: -30,
    top: '45%',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      right: -22,
    },
  }),
  leftArrow: css({
    position: 'absolute',
    left: -30,
    top: '45%',
    transform: 'rotate(180deg)',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      left: -22,
    },
  }),
  closeWrapper: css({
    padding: 16,
    borderTop: '1px solid #dee5ec',
  }),
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  font-size: 12px;
`;
const ContentWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
  display: flex;
`;
const IconWrapper = styled.button`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export default CarouselModal;
