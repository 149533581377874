import React, { ReactNode, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDirLayout, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import IntercomInitializer from '../Intercom';
import LineWidget from '../LineWidget';
import Footer from '../Footer';
import Menu from './Menu';
import { menuWidth } from './helpers';
import MobileMenu from './MobileMenu';
import MobileToolbar from './MobileToolbar';

export interface LayoutProps {
  backPath?: string;
  hideFooter?: boolean;
  toolbarTitle?: string;
  skipBottomPadding?: boolean;
}

interface MainProps extends LayoutProps {
  children: ReactNode;
}

const Layout = ({ children, backPath, hideFooter, toolbarTitle, skipBottomPadding }: MainProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const { pathname, search } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { dir, isRtl } = useDirLayout();

  const menuRequiredWidth = !isMobileView ? menuWidth : 0;

  useEffect(() => {
    if (isMobileView) {
      setMobileMenuOpen(false);
    }
  }, [pathname, search]);

  useEffect(() => {
    if (!isMobileView) {
      setMobileMenuOpen(false);
    }
  }, [isMobileView]);

  return (
    <div dir={dir} css={{ fontSize: '14px' }}>
      {!isMobileView ? (
        <Menu isDesktopView />
      ) : (
        <>
          <MobileToolbar backPath={backPath} toolbarTitle={toolbarTitle} onClickMenu={() => setMobileMenuOpen(true)} />
          <MobileMenu open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} />
        </>
      )}

      <PageContainer
        isMobileView={isMobileView}
        skipBottomPadding={skipBottomPadding}
        skipPosition={isMobileView}
        css={isRtl ? { marginRight: menuRequiredWidth } : { marginLeft: menuRequiredWidth }}
      >
        <div>
          {children}
          {!hideFooter && !isMobileView && (
            <div css={styles.footerContainer}>
              <Footer />
            </div>
          )}
        </div>
        <IntercomInitializer />
        <LineWidget />
      </PageContainer>
    </div>
  );
};

const PageContainer = styled.div<{ isMobileView: boolean; skipBottomPadding?: boolean; skipPosition: boolean }>(
  ({ isMobileView, skipBottomPadding, skipPosition }) => ({
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'column',
    marginBottom: 56,
    minHeight: '80vh',
    overflow: 'hidden',
    paddingBottom: skipBottomPadding ? 0 : '10%',
    ...(isMobileView && { paddingTop: 56 }),
    ...(!skipPosition && { position: 'relative' }),

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },
  })
);

const styles = {
  footerContainer: css({
    bottom: 16,
    display: 'flex',
    justifyContent: 'center',
    height: 24,
    position: 'absolute',
    width: '100%',

    '& > div': {
      width: '85%',
    },
  }),
};

export default Layout;
