import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Item } from '@src/components/organisms/LinkInBio/EditBio/BioLinks/BioCard';
import { FlowTypes } from '@src/pages/LinkInBio/helpers';
import { SocialAccountType } from '@src/__generated__/globalTypes';

interface LinkInBioState {
  currentFlow: FlowTypes;
  sections?: Item[];
  socialAccountId?: string;
  socialAccountType?: SocialAccountType;
}

const { persistAtom } = recoilPersist();

export const linkInBioState = atom<LinkInBioState>({
  key: 'linkInBioState',
  default: { currentFlow: FlowTypes.CREATE_MY_FIRST_LINK },
  effects_UNSTABLE: [persistAtom],
});
