import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/molecules';
import { SocialButton } from '@src/components/shared';
import Footer from '@src/components/organisms/TopPage/Footer';
import Header from '@src/components/organisms/TopPage/Header';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useHelpCenterUrl } from '@src/libs/help';
import useSignInInstagram from './useSignInInstagramLine';

interface IgLoginFormProps {
  lineIdToken: string;
}

const InstagramLoginForm = ({ lineIdToken }: IgLoginFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.LINE_INSTAGRAM_SIGNIN);
  const { t } = useTranslation();
  const { anyXSocialAuthSignInInstagramLine, setLineIdToken } = useSignInInstagram();
  const { switchBusinessAccUrl } = useHelpCenterUrl();

  const onClickConnectWithInstagram = () => {
    setIsLoading(true);
    setLineIdToken(lineIdToken);
    connectSocialAuth(SocialAccountType.INSTAGRAM);
    setIsLoading(false);
  };

  const onClickSkip = () => {
    if (lineIdToken) {
      anyXSocialAuthSignInInstagramLine({
        variables: {
          input: {
            fbOauth: null,
            lineIDToken: lineIdToken,
          },
        },
      });
    }
  };

  return (
    <div css={styles.container}>
      <Header />
      <div css={styles.contentContainer}>
        <div css={{ width: 348 }}>
          <div css={styles.contentTitle}>{t('Let’s connect with Instagram')}</div>
          <div css={styles.contentDescription}>
            {t('Annotation.You will receive more campaigns when you connect with Instagram')}
          </div>
          <SocialButton
            css={styles.instagramBtn}
            disabled={isLoading}
            hasIcon
            socialMedia={SocialAccountType.INSTAGRAM}
            title="Connect with Instagram"
            onClick={onClickConnectWithInstagram}
          />
          <ThemeButton
            css={styles.skipBtn}
            disabled={isLoading}
            width="max-content"
            text="Skip it for now"
            onClick={onClickSkip}
          />
          <div>
            <Hint>*{t('Annotation.Switching to a business account (free) is required to link Instagram')}&nbsp;</Hint>
            <Link
              css={styles.learnSwitchBusinessAcc}
              rel="noopener noreferrer"
              target="_blank"
              to={{ pathname: switchBusinessAccUrl }}
            >
              {t('Click here to learn how to switch to a business account')}
            </Link>
          </div>
          <Hint>*{t('Annotation.We will not post to your account')}</Hint>
          <br />
          <Hint>*{t('Annotation.Data will not be used for any purpose other than campaigns')}</Hint>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Hint = styled.span({
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.subordinate,
});

const styles = {
  container: css({
    alignItems: 'center',
    display: 'grid',
    justifyContent: 'center',
  }),
  contentContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    height: 520,
    justifyContent: 'center',
    width: 856,
  }),
  contentDescription: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,
    textAlign: 'center',
  }),
  contentTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 600,
    marginBottom: 24,
    textAlign: 'center',
  }),
  instagramBtn: css({
    backgroundImage: 'linear-gradient(89.37deg, #ebb752 0.07%, #ff6641 31.78%, #e83262 65.06%, #ce3396 99.89%)',
    borderRadius: 3,
    height: 32,
    marginBottom: 16,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 5,
      height: 40,
    },
  }),
  learnSwitchBusinessAcc: css({
    color: THEME.font.colors.blue.main,
    fontSize: THEME.font.sizes.subordinate,
  }),
  skipBtn: css({
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    color: THEME.font.colors.gray.main,
    filter: 'brightness(1)',
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    height: 32,
    marginBottom: 16,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 5,
      height: 40,
    },
  }),
};

export default InstagramLoginForm;
