import React from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { THEME } from '@src/libs/theme';
import { LineMessageType } from '@src/__generated__/globalTypes';
import { LineContent } from '../FormInput/Inputs/helpers';
import PreviewItem from './PreviewItem';

const FormPreview = () => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<{ lineContent: LineContent[] }>();
  const lineContent = watch('lineContent');

  return (
    <div css={styles.container}>
      <div />
      <div>
        {lineContent.map((item, index) => {
          const { buttonMessage, imageMessage, plainTextMessage, richMessage, type } = item;
          const buttonMessageError =
            type === LineMessageType.BUTTON &&
            !!buttonMessage?.cards.find(
              card =>
                card.actionLabels.find(actionLabel => !actionLabel.label || !actionLabel.url) ||
                !card.description.text ||
                !card.title.text
            );
          const imageMessageError =
            type === LineMessageType.IMAGES && !!imageMessage?.images.find(image => !image.imageUrl || !image.url);
          const plainTextError = type === LineMessageType.PLAIN_TEXT && !plainTextMessage?.text;
          const richMessageError = type === LineMessageType.RICH && !richMessage?.imageUrl;
          const error =
            !!errors.lineContent && (buttonMessageError || imageMessageError || plainTextError || richMessageError);

          return <PreviewItem error={error} lineContent={item} key={index} />;
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 20,
    display: 'flex',
    height: 788,
    justifyContent: 'center',
    padding: 12,
    position: 'relative',
    width: 400,

    '& > div:nth-of-type(1)': {
      backgroundColor: THEME.colors.white,
      borderRadius: 24,
      height: 26,
      position: 'absolute',
      top: 4,
      width: 186,
    },

    '& > div:nth-of-type(2)': {
      backgroundColor: '#f6f8fa',
      borderRadius: 14,
      height: 708,
      overflow: 'auto',
      padding: '40px 18px',
      width: 376,
    },
  }),
};

export default FormPreview;
