import { Pager, SliderTableComponents, SliderTableStyledComponents } from '@src/components/molecules';
import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icomoon, List } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { getOffset, getPageInfo } from '@src/components/molecules/Pager/helpers';
import { getFormatDistanceToNow } from '@src/libs/date';
import { LIMIT } from '@src/libs/constant';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { FanListSortableField, Order } from '@src/__generated__/globalTypes';
import { TextCutter } from '@src/components/atoms';
import { useGetFansQuery, useGetFansCountQuery, useGetFanCountriesQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';
import { FanTag } from '@src/__generated__/globalTypes';
import { FanListFilterType } from './types';
import { getFansVariables } from './helper';
import EmptyList from './EmptyList';
import { getTagsIdFromURLString } from './FanListFilter/helper';

const emptyUsernameKey = 'UnknownUsername';

interface FansListProps {
  currentPage: number;
  filter: FanListFilterType;
  sortOrder: Order;
  selectedFansId: Set<number>;
  isAllSelectedFromCurrentPage: boolean;
  onChangeFanSelection: (id: number, tags: FanTag[]) => void;
  setFansIdFromCurrentPage: React.Dispatch<React.SetStateAction<number[]>>;
  setTotalFanCount: React.Dispatch<React.SetStateAction<number>>;
  onCheckAll: () => void;
}

const FansList = (props: FansListProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { data: dataFanCountries } = useGetFanCountriesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const fanCountriesHash =
    dataFanCountries?.getFanCountries?.countries.reduce<{ [id: string]: string }>(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {}
    ) || {};

  const {
    currentPage,
    filter,
    sortOrder,
    selectedFansId,
    onChangeFanSelection,
    setFansIdFromCurrentPage,
    setTotalFanCount,
    isAllSelectedFromCurrentPage,
    onCheckAll,
  } = props;

  const { data, loading } = useGetFansQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        keyword: filter.keyword,
        limit: LIMIT,
        offset: getOffset(currentPage),
        orderBy: { field: FanListSortableField.CREATED, order: sortOrder },
        tagIds: getTagsIdFromURLString(filter.tags),
        ...getFansVariables(filter),
      },
    },
    onCompleted: ({ getFans }) => {
      setFansIdFromCurrentPage(getFans?.fans.map(item => item.id) || []);
    },
    onError: err => {
      enqueueSnackbar(t(err.message || 'Cannot get fans data'), { variant: 'error' });
    },
  });
  const { data: dataFansCount } = useGetFansCountQuery({
    variables: {
      input: {
        keyword: filter.keyword,
        tagIds: getTagsIdFromURLString(filter.tags),
        ...getFansVariables(filter),
      },
    },
    onCompleted: ({ getFansCount }) => {
      setTotalFanCount(getFansCount?.total || 0);
    },
  });

  if (loading) {
    return <ListLoading />;
  }

  const fans = data?.getFans?.fans || [];

  const isEmptyFans = !fans.length;

  const pageInfo = getPageInfo(currentPage, dataFansCount?.getFansCount?.total || 0);

  return (
    <>
      <SliderTableComponents.Wrapper css={styles.tableContainer}>
        {isMobileView ? (
          fans.map(item => (
            <div key={item.id} css={styles.mobileWrapper}>
              <Link to={generatePath(ROUTES.MY_PAGE_FANS_EDIT, { id: item.id })} css={styles.linkStyle}>
                {item.name || t(emptyUsernameKey)}
              </Link>
              <p css={styles.regularText}>{item.email}</p>
              <p css={styles.regularText}>{item.contactNumber}</p>
              <p css={styles.regularText}>{item.countryId ? fanCountriesHash[item.countryId] || '' : ''}</p>
            </div>
          ))
        ) : (
          <SliderTableComponents.Container>
            <SliderTableComponents.SliderSection>
              <SliderTableComponents.SliderTable>
                {selectedFansId.size === 0 && (
                  <thead>
                    <tr>
                      <List.HeaderCheckboxColumn
                        css={[styles.headerCheckboxColumn, styles.checkboxGap]}
                        onChange={onCheckAll}
                        checked={isAllSelectedFromCurrentPage}
                      />
                      <List.HeaderColumn title="Name" css={[styles.headerColumn, { minWidth: '120px' }]} />
                      <List.HeaderColumn title="Tag" css={styles.headerColumn} />
                      <List.HeaderColumn title="Mail Address" css={styles.headerColumn} />
                      <List.HeaderColumn title="Phone number" css={styles.headerColumn} />
                      <List.HeaderColumn title="Connect" css={styles.headerColumn} />
                      <List.HeaderColumn title="Location" css={styles.headerColumn} />
                      <List.HeaderColumn title="Created Date" css={styles.headerColumn} />
                    </tr>
                  </thead>
                )}
                {!isEmptyFans && (
                  <tbody>
                    {fans.map(item => (
                      <SliderTableStyledComponents.StyledRowNew
                        key={item.id}
                        css={[styles.rowHeight, selectedFansId.has(item.id) ? styles.activeRow : undefined]}
                      >
                        <List.HeaderCheckboxColumn
                          css={[styles.checkboxTopBorder, styles.checkboxGap]}
                          checked={selectedFansId.has(item.id)}
                          onChange={() => onChangeFanSelection(item.id, item.tags)}
                        />
                        <List.TextLinkColumn
                          data={<TextCutter text={item.name || t(emptyUsernameKey)} />}
                          href={generatePath(ROUTES.MY_PAGE_FANS_EDIT_DETAILS, { id: item.id })}
                          css={{ minWidth: '120px' }}
                        />
                        <List.TagColumn tags={item.tags.map(el => el.tag)} />
                        <List.TextColumn data={item.email || ''} />
                        <List.TextColumn data={item.contactNumber || ''} />
                        <List.TextColumn
                          data={
                            <div css={{ display: 'flex', gap: '8px', minWidth: '40px' }}>
                              {item.lineAccount && <Icomoon icon="line-color" size={22} />}
                              {item.shopifyAccount && <Icomoon icon="shopify-color" size={22} />}
                            </div>
                          }
                        />
                        <List.TextColumn data={item.countryId ? fanCountriesHash[item.countryId] || '' : ''} />
                        <List.TextColumn data={getFormatDistanceToNow(item.created, true)} />
                      </SliderTableStyledComponents.StyledRowNew>
                    ))}
                  </tbody>
                )}
              </SliderTableComponents.SliderTable>
            </SliderTableComponents.SliderSection>
          </SliderTableComponents.Container>
        )}
      </SliderTableComponents.Wrapper>
      {isEmptyFans && <EmptyList />}
      <Pager
        currentPage={currentPage}
        totalPages={pageInfo.totalPages}
        totalCount={pageInfo.totalCount}
        first={pageInfo.firstIndex}
        last={pageInfo.lastIndex}
      />
    </>
  );
};

const styles = {
  activeRow: css({
    '& > th': {
      backgroundColor: '#fffde7',
    },

    '& > td': {
      backgroundColor: '#fffde7 !important',
    },
  }),
  linkStyle: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.blue.main,
    marginBottom: 16,
  }),
  regularText: css({
    fontSize: THEME.font.sizes.subordinate,
    color: THEME.font.colors.black.main,
    lineHeight: '100%',

    '&:not(:last-of-type)': {
      marginBottom: 8,
    },
  }),
  headerColumn: css({
    paddingLeft: 8,
    backgroundColor: '#f6f8fa',
  }),
  headerCheckboxColumn: css({
    backgroundColor: '#f6f8fa',
  }),
  paddingLeft16: css({
    paddingLeft: 16,
  }),
  checkboxTopBorder: css({
    borderTop: '1px solid #efefef',
  }),
  mobileWrapper: css({
    padding: 16,

    '&:not(:last-of-type)': {
      borderBottom: '1px solid #eef3f7',
    },
  }),
  rowHeight: css({
    height: 56,
  }),
  checkboxGap: css({
    padding: '0 0 0 16px',

    '[dir="rtl"] &': {
      padding: '0 16px 0 0',
    },
  }),
  tableContainer: css({
    margin: 0,
    padding: 0,
  }),
};

export default FansList;
