import React from 'react';
import Picker, { SKIN_TONE_LIGHT } from 'emoji-picker-react';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import Popover from '@src/components/atoms/Popover';
import { THEME } from '@src/libs/theme';

interface EmojiPickerProps {
  onEmojiClick: (emoji: string) => void;
}

const EmojiPicker = ({ onEmojiClick }: EmojiPickerProps) => {
  const onClickEmoji = (emoji: string) => {
    onEmojiClick(emoji);
    onCloseMenu();
  };

  let onCloseMenu: () => void;

  return (
    <Popover
      renderTrigger={
        <div css={styles.iconContainer}>
          <Icomoon icon="smile-grey-light" size={20} />
        </div>
      }
      emitEvents={({ onClose }) => {
        onCloseMenu = onClose;
      }}
      side="bottom"
      align="start"
    >
      <Picker
        disableAutoFocus={true}
        groupNames={{ smileys_people: 'PEOPLE' }}
        skinTone={SKIN_TONE_LIGHT}
        onEmojiClick={(_e, emojiObj) => onClickEmoji(emojiObj.emoji)}
      />
    </Popover>
  );
};

const styles = {
  iconContainer: css({
    alignItems: 'center',
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    cursor: 'pointer',
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    width: 32,
  }),
};

export default EmojiPicker;
