import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthConnectForLinkBioMutation,
  AnyXSocialAuthConnectForLinkBioMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthConnectForLinkBio.graphql';

export const useAnyXSocialAuthConnectForLinkBioMutation = (
  options?: MutationHookOptions<
    AnyXSocialAuthConnectForLinkBioMutation,
    AnyXSocialAuthConnectForLinkBioMutationVariables
  >
) =>
  useMutation<AnyXSocialAuthConnectForLinkBioMutation, AnyXSocialAuthConnectForLinkBioMutationVariables>(
    MUTATION,
    options
  );
