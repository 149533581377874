import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import DragAndDropToolkit, { DragEndProps } from '@src/components/atoms/DragAndDropToolkit';
import { ThemeButton } from '@src/components/molecules';
import { Label, TextForm } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { FontSize } from '@src/__generated__/globalTypes';
import BlockCard from '../BlockCard';
import { FormInformation, FormType, LineContentButtonCard } from '../helpers';
import { DragAndDrop, FontSizePicker } from '../StyledComponents';
import ActionLabelCard from './ActionLabelCard';

interface ButtonTypeInputProps {
  isDisabled?: boolean;
}

const ButtonTypeInput = ({ isDisabled }: ButtonTypeInputProps) => {
  const [contentIndex, setContentIndex] = useState<number>(0);
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [formType, lineContent, selectedBlockIndex] = watch(['formType', 'lineContent', 'selectedBlockIndex']);

  const buttonTypeContent = lineContent[selectedBlockIndex];
  const buttonTypeContentLength = buttonTypeContent.buttonMessage?.cards.length || 0;
  const maxActionCards = 3;
  const maxCards = 12;
  const selectedContent = buttonTypeContent.buttonMessage?.cards[contentIndex];
  const actionLabelsLength = selectedContent?.actionLabels?.length || 0;

  const onChangeCard = (lineContentButtonCard: LineContentButtonCard) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards[contentIndex] = lineContentButtonCard;

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onChangeCardActionLabel = (index: number, val: { label: string; url: string }) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards[contentIndex].actionLabels[index] = { ...cards[contentIndex].actionLabels[index], ...val };

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onClickAdd = () => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards.push({
      actionLabels: [{ genId: uuidv4(), label: '', url: '' }],
      description: {
        fontSize: FontSize.LG,
        text: '',
        weighted: false,
      },
      genId: uuidv4(),
      imageUrl: '',
      title: {
        fontSize: FontSize.SM,
        text: '',
        weighted: false,
      },
    });

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onClickAddAction = () => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards[contentIndex].actionLabels.push({
      genId: uuidv4(),
      label: '',
      url: '',
    });

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onClickDelete = (index: number) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards.splice(index, 1);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onClickDeleteActionLabel = (index: number) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards[contentIndex].actionLabels.splice(index, 1);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onDragEndBlock = ({ destinationIndex, sourceIndex }: DragEndProps) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    const dragCard = cards[sourceIndex];
    cards.splice(sourceIndex, 1);
    cards.splice(destinationIndex, 0, dragCard);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onDragEndLabel = ({ destinationIndex, sourceIndex }: DragEndProps) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    const actionLabels = cards[contentIndex].actionLabels;
    const dragCard = actionLabels[sourceIndex];
    actionLabels.splice(sourceIndex, 1);
    actionLabels.splice(destinationIndex, 0, dragCard);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  return (
    <div css={{ margin: 24 }}>
      {formType === FormType.BUTTON_TYPE ? (
        <>
          <DragAndDropToolkit
            items={buttonTypeContent.buttonMessage?.cards || []}
            primaryKey="genId"
            onDragEnd={onDragEndBlock}
          >
            {({ items }) =>
              items.map((item, index) => {
                const error = !!errors.lineContent && !!item.actionLabels.find(action => !action.label || !action.url);

                return (
                  <BlockCard
                    error={error}
                    id={item.genId}
                    isDeletable={!isDisabled && buttonTypeContentLength > 1}
                    key={item.genId}
                    onClick={() => setContentIndex(index)}
                    onDelete={() => onClickDelete(index)}
                  />
                );
              })
            }
          </DragAndDropToolkit>

          {!isDisabled && (
            <>
              <ThemeButton
                height="40px"
                text="Add Card"
                width="max-content"
                prefixIcon={<Icomoon icon="plus" size={12} />}
                onClick={onClickAdd}
                disabled={buttonTypeContentLength >= maxCards}
              />
              <div css={styles.cardCount}>{`${buttonTypeContentLength} / ${t('Card Maximum', {
                count: maxCards,
              })}`}</div>
            </>
          )}
        </>
      ) : selectedContent ? (
        <div>
          <Label css={styles.label} title="Image" />
          <DragAndDrop
            disabled={isDisabled}
            imageUrl={selectedContent.imageUrl}
            onChange={val => onChangeCard({ ...selectedContent, imageUrl: val })}
          />

          <TextFieldContainer css={{ marginTop: 24 }}>
            <div>
              <TextForm
                disabled={isDisabled}
                placeholder={t('TextForm.Title Text')}
                title="Title Text"
                value={selectedContent.title.text}
                onChange={e =>
                  onChangeCard({ ...selectedContent, title: { ...selectedContent.title, text: e.target.value } })
                }
              />
            </div>
            <div>
              <FontSizePicker
                disabled={isDisabled}
                isBold={selectedContent.title.weighted}
                value={selectedContent.title.fontSize}
                onChange={val =>
                  onChangeCard({
                    ...selectedContent,
                    title: { ...selectedContent.title, fontSize: val.value, weighted: val.isBold },
                  })
                }
              />
            </div>
          </TextFieldContainer>

          <TextFieldContainer css={{ marginTop: 16 }}>
            <div>
              <TextForm
                disabled={isDisabled}
                placeholder={t('TextForm.Description Text')}
                title="Description Text"
                value={selectedContent.description.text}
                onChange={e =>
                  onChangeCard({
                    ...selectedContent,
                    description: { ...selectedContent.description, text: e.target.value },
                  })
                }
              />
            </div>
            <div>
              <FontSizePicker
                disabled={isDisabled}
                isBold={selectedContent.description.weighted}
                value={selectedContent.description.fontSize}
                onChange={val =>
                  onChangeCard({
                    ...selectedContent,
                    description: { ...selectedContent.description, fontSize: val.value, weighted: val.isBold },
                  })
                }
              />
            </div>
          </TextFieldContainer>

          <DragAndDropToolkit items={selectedContent?.actionLabels || []} primaryKey="genId" onDragEnd={onDragEndLabel}>
            {({ items }) => (
              <div css={{ marginTop: 46 }}>
                {items.map((item, index) => (
                  <ActionLabelCard
                    actionLabel={item}
                    disabled={isDisabled}
                    error={!!errors.lineContent}
                    isDeletable={!isDisabled && actionLabelsLength > 1}
                    key={item.genId}
                    onChange={val => onChangeCardActionLabel(index, val)}
                    onDelete={() => onClickDeleteActionLabel(index)}
                  />
                ))}
              </div>
            )}
          </DragAndDropToolkit>

          {!isDisabled && (
            <>
              <ThemeButton
                disabled={actionLabelsLength >= maxActionCards}
                height="40px"
                skipTranslation
                prefixIcon={<Icomoon icon="plus" size={12} />}
                text="Add Action Button"
                width="max-content"
                onClick={onClickAddAction}
              />
              <div css={styles.cardCount}>{`${actionLabelsLength} / ${t('Card Maximum', {
                count: maxActionCards,
              })}`}</div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

const TextFieldContainer = styled.div({
  alignItems: 'flex-end',
  display: 'flex',
  gap: THEME.box.gaps.xs,

  '& > div:nth-of-type(1)': {
    display: 'flex',
    flex: 1,

    '& > div': {
      width: '100%',
    },
  },
});

const styles = {
  cardCount: css({
    color: '#c5d0da',
    fontSize: THEME.font.sizes.subordinate,
    marginTop: 4,
  }),
  label: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  }),
};

export default ButtonTypeInput;
