import { css } from '@emotion/react';
import { THEME } from '@src/libs/theme';
import * as React from 'react';

export interface HashTagProps {
  hashTag: string;
}

const HashTag = (props: HashTagProps) => {
  const { hashTag } = props;

  return <p css={styles.paragraph}>#{hashTag}</p>;
};

const styles = {
  paragraph: css({
    margin: '0 9px 9px 0',
    padding: '12px 20px',
    background: '#d7e9fa',
    borderRadius: 3,
    color: THEME.font.colors.blue.main,
    fontSize: 13,
    letterSpacing: '0.025em',
  }),
};

export default HashTag;
