// Request URLs
declare const API_URL: string;
declare const CRM_REST_API_URL: string;

export const GRAPHQL_URL = `${API_URL}graphql/`;
export const CRM_URL = `${CRM_REST_API_URL}api/`;

const getFilenameFromUrl = (url: string) => url.substring(url.lastIndexOf('/') + 1);
export const getFileTypeFromUrl = (url: string) => url.substring(url.lastIndexOf('.') + 1);

// Get File Object from material urls
export interface Material {
  id?: number;
  url: string;
  modified?: number;
}

export const getDecodedUrlFileName = (url: string) => decodeURI(getFilenameFromUrl(url));
