import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetLineBroadcastsCountQuery, GetLineBroadcastsCountQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetLineBroadcastsCount.graphql';

export const useGetLineBroadcastsCountQuery = (
  options?: QueryHookOptions<GetLineBroadcastsCountQuery, GetLineBroadcastsCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetLineBroadcastsCountQuery, GetLineBroadcastsCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetLineBroadcastsCountLazyQuery = (
  options?: LazyQueryHookOptions<GetLineBroadcastsCountQuery, GetLineBroadcastsCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetLineBroadcastsCountQuery, GetLineBroadcastsCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
