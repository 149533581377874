import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/browser';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { InitialLoading } from '@src/components/atoms';
import VERIFY_TOKEN from '@src/graphql/mutations/VerifyToken.graphql';
import { getCallbackUrl, removeToken, setToken } from '@src/libs/auth';
import { getMessagesFromApolloError, getMessagesFromFetchResult, UNEXPECTED_ERROR } from '@src/libs/error';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import { signUpState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { VerifyTokenMutation, VerifyTokenMutationVariables } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';
import { useSignup } from './useSignup';

const SignUpRedirect = () => {
  const { recoilState, resetRecoilState } = useRecoil(signUpState);
  const [verifyToken] = useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VERIFY_TOKEN);
  const { requestSignUp } = useSignup();

  const { t, enqueueSnackbar, navigate } = useQueryHelper();

  const { provider, response } = useAuthProviderResponse();
  const { setAuthData } = useAuthData();

  const signUpRedirectCall = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.ROOT, errorMsg: UNEXPECTED_ERROR }, '*');
        window.close();

        return;
      }
      navigate(ROUTES.ROOT);

      return;
    }

    const { agencyHash, agencyType, profile } = recoilState;

    if (!profile) {
      const failureMessage = 'failedToCreate';
      enqueueSnackbar(t(failureMessage), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP, errorMsg: failureMessage }, '*');
        window.close();

        return;
      }
      navigate(ROUTES.SIGN_UP);

      return;
    }

    const { token, refreshToken, errors } = await requestSignUp({
      agencyType,
      agencyHash,
      payload: {
        ...profile,
        provider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.TALENT_SIGNUP),
      },
    });

    // Error Handling
    if (!token || errors.length > 0) {
      errors.forEach((error: string) => {
        console.error(error);
        enqueueSnackbar(t(error), { variant: 'error' });
      });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP }, '*');
        window.close();

        return;
      }
      navigate(ROUTES.SIGN_UP);

      return;
    }

    // setToken
    setToken(token, refreshToken);

    // VerifyToken
    await verifyToken({ variables: { input: { token } } })
      .then(props => {
        if (props?.data?.verifyToken?.ok) {
          const { creatorType, role, userId } = props.data.verifyToken;
          setAuthData({ creatorType, role, userId });

          Sentry.setUser({
            id: String(userId),
          });
          ReactGA.set({ userId });
          navigate(ROUTES.ROOT);
        } else {
          removeToken();
          getMessagesFromFetchResult(props).forEach(error => {
            console.error(error);
            enqueueSnackbar(t(error), { variant: 'error' });
            if (window.opener) {
              window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP, errorMsg: error }, '*');
              window.close();

              return;
            }
          });
          if (window.opener) {
            window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP }, '*');
            window.close();

            return;
          }
          navigate(ROUTES.ROOT);
        }
      })
      .catch((e: ApolloError) => {
        removeToken();
        getMessagesFromApolloError(e).forEach(error => {
          console.error(error);
          enqueueSnackbar(t(error), { variant: 'error' });
          if (window.opener) {
            window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP, errorMsg: error }, '*');
            window.close();

            return;
          }
        });
        if (window.opener) {
          window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP }, '*');
          window.close();

          return;
        }
        navigate(ROUTES.ROOT);
      });
  };

  useEffect(() => {
    signUpRedirectCall();

    return () => {
      resetRecoilState();
    };
  }, []);

  return <InitialLoading />;
};

export default SignUpRedirect;
