import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { THEME } from '@src/libs/theme';

export interface CardProps {
  className?: string;
  children: ReactNode;
  title?: string;
  icon?: string;
  header?: ReactNode;
}

const DataCard = (props: CardProps) => {
  const { className, children, header, title, icon } = props;
  const { t } = useTranslation();

  return (
    <section className={className} css={styles.container}>
      {header ? (
        header
      ) : (
        <div css={styles.header}>
          <img css={styles.img} src={icon} /> <h1 css={styles.title}>{t(title || '')}</h1>
        </div>
      )}
      {children}
    </section>
  );
};

const styles = {
  container: css({
    position: 'relative',
    borderRadius: 3,
    boxShadow: THEME.box.shadows.outer,
    padding: 16,
    boxSizing: 'border-box',
    background: THEME.colors.white,
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 16,
  }),
  img: css({
    width: 24,
    marginRight: 8,
  }),
  title: css({
    fontSize: 13,
    fontWeight: 600,
    color: THEME.font.colors.black.main,
  }),
};

export default DataCard;
