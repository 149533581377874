import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFormsListQuery, GetFormsListQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFormsList.graphql';

export const useGetFormsListQuery = (options?: QueryHookOptions<GetFormsListQuery, GetFormsListQueryVariables>) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFormsListQuery, GetFormsListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFormsListLazyQuery = (
  options?: LazyQueryHookOptions<GetFormsListQuery, GetFormsListQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFormsListQuery, GetFormsListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
