import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

interface ReasonWidgetProps {
  content: string;
  title: string;
}

const ReasonWidget = ({ content, title }: ReasonWidgetProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <Icomoon color="#ff5f5f" icon="warning" size={18} />
      </div>
      <div>
        <div>{t(title)}</div>
        {/* the content is enter by user so it is dynamic and no need for translation */}
        <div>{content}</div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    borderLeft: '3px solid #ff5f5f',
    borderRadius: 3,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    width: '100%',

    '& > div:nth-of-type(2)': {
      display: 'grid',
      flexBasis: '88%',
      marginLeft: 8,

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
        marginBottom: 8,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: 13,
      },
    },
  }),
};

export default ReasonWidget;
