import { useRef } from 'react';
import useDimensions from './useDimensions';

interface ContainerQueryProps {
  hasWidthSmallerThan: number;
}
export default ({ hasWidthSmallerThan }: ContainerQueryProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useDimensions(containerRef);

  const isSmaller = width ? width < hasWidthSmallerThan : false;

  return {
    containerRef,
    width,
    isSmaller,
  };
};
