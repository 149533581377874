import React, { useEffect, createRef, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageMenu, { ChildProps } from './LanguageMenu';

export type LanguageMenuChildProps = ChildProps;
interface State {
  onRef: React.RefObject<HTMLDivElement>;
  visible: boolean;
}
interface LanguageMenuProps {
  className?: string;
  children: (props: ChildProps) => ReactNode;
}
const LanguageMenuComponent = ({ children, className }: LanguageMenuProps) => {
  const { t } = useTranslation();
  const [{ visible, onRef }, setState] = useState<State>({
    onRef: createRef(),
    visible: false,
  });

  const toggleVisible = () => {
    setState(prevState => ({
      ...prevState,
      visible: !prevState.visible,
    }));
  };
  const setInvisible = () => {
    setState(prevState => ({
      ...prevState,
      visible: false,
    }));
  };
  const handleClickOutside = (event: Event) => {
    const isOutside = !onRef.current?.contains(event.target as Node);
    if (isOutside && visible) {
      setState(prevState => ({
        ...prevState,
        visible: false,
      }));
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [visible]);

  const title = t('languageTitle');

  return (
    <LanguageMenu
      visible={visible}
      title={title}
      setInvisible={setInvisible}
      toggleVisible={toggleVisible}
      onRef={onRef}
      className={className}
    >
      {children}
    </LanguageMenu>
  );
};

export default LanguageMenuComponent;
