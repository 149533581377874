import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFormRespondentsListQuery, GetFormRespondentsListQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFormRespondentsList.graphql';

export const useGetFormRespondentsListQuery = (
  options?: QueryHookOptions<GetFormRespondentsListQuery, GetFormRespondentsListQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFormRespondentsListQuery, GetFormRespondentsListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFormRespondentsListLazyQuery = (
  options?: LazyQueryHookOptions<GetFormRespondentsListQuery, GetFormRespondentsListQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFormRespondentsListQuery, GetFormRespondentsListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
