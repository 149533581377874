import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  GetLinkBioFacebookPagePostsQuery,
  GetLinkBioFacebookPagePostsQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetLinkBioFacebookPagePosts.graphql';

export const useGetLinkBioFacebookPagePostsQuery = (
  options?: QueryHookOptions<GetLinkBioFacebookPagePostsQuery, GetLinkBioFacebookPagePostsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetLinkBioFacebookPagePostsQuery, GetLinkBioFacebookPagePostsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetLinkBioFacebookPagePostsLazyQuery = (
  options?: LazyQueryHookOptions<GetLinkBioFacebookPagePostsQuery, GetLinkBioFacebookPagePostsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetLinkBioFacebookPagePostsQuery, GetLinkBioFacebookPagePostsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
