import React, { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import Textarea, { TextareaProps } from '@src/components/atoms/Textarea';
import Label from '../Label';

interface SentenceFormProps extends TextareaProps {
  className?: string;
  title?: string | null;
  isRequired?: boolean;
  help?: string;
}

const SentenceForm = forwardRef((props: SentenceFormProps, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { className, title, isRequired, help, placeholder, error, ...field } = props;
  const translatedPlaceholder = placeholder ? t(`Sentence.${placeholder}`) : '';

  return (
    <div className={className} ref={ref}>
      {title && <Label title={title ? `Sentence.${title}` : ''} isRequired={isRequired} help={help} />}
      <Textarea placeholder={translatedPlaceholder} error={error} {...field} />
    </div>
  );
});

SentenceForm.displayName = 'SentenceForm';

export default SentenceForm;
