export const DIALOG_REQUIRED_ACTIONS = {
  ADD_POSTS: 'addPosts',
  FACEBOOK_GRANT_PERMISSION: 'facebookGrantPermission',
  JOINED_SELECTION: 'joinedSelection',
  TIKTOK_GRANT_PERMISSION: 'tiktokGrantPermission',
};

export const isDateWithinTheRange = (date: string, days: number) =>
  (new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24) <= days;

export const isNumber = (value: undefined | null | number) => value !== undefined && value !== null;
