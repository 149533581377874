import * as React from 'react';
import { css } from '@emotion/react';
import useUrl from '@src/libs/hooks/useUrl';
import { formatIntNumber } from '@src/libs/format';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import PageArrow from './PageArrow';
import PageLink from './PageLink';
import { getPageNumbers, getPagingParams } from './helpers';

interface PagerProps {
  className?: string;
  currentPage: number;
  totalPages: number;
  totalCount: number;
  first: number;
  last: number;
}

const Pager = (props: PagerProps) => {
  const { className, currentPage, totalPages, totalCount, first, last } = props;
  const { updateSearchParams } = useUrl();

  if (totalPages <= 1) {
    return null;
  }

  // Pages
  const pageNumbers = getPageNumbers(currentPage, totalPages);
  const lastPage = pageNumbers[pageNumbers.length - 1];
  const hasFirstPage = pageNumbers[0] > 2;

  return (
    <nav className={className} css={styles.wrapper}>
      <div css={styles.pageCounter}>
        {formatIntNumber(first)}-{formatIntNumber(last)} / {formatIntNumber(totalCount)}
      </div>
      <ul css={styles.list}>
        <PageArrow
          disabled={currentPage <= 1}
          isBack
          onClick={() => updateSearchParams(getPagingParams(currentPage - 1))}
        />
        {/* nav to first page */}
        {hasFirstPage && (
          <>
            <PageLink page={1} disabled={false} onClick={() => updateSearchParams(getPagingParams(1))} />
            <li css={{ width: 24 }}>...</li>
          </>
        )}
        {pageNumbers.map(n => (
          <PageLink
            key={n}
            page={n}
            disabled={n === currentPage}
            onClick={() => updateSearchParams(getPagingParams(n))}
          />
        ))}
        {/* nav to last page */}
        {totalPages > 1 && (
          <>
            {lastPage < totalPages - 1 && <li css={{ width: 24 }}>...</li>}
            <PageLink
              page={totalPages}
              disabled={totalPages === currentPage}
              onClick={() => updateSearchParams(getPagingParams(totalPages))}
            />
          </>
        )}
        <PageArrow
          disabled={currentPage >= totalPages}
          onClick={() => updateSearchParams(getPagingParams(currentPage + 1))}
        />
      </ul>
    </nav>
  );
};

const styles = {
  list: css({
    display: 'flex',
    alignItems: 'center',
    columnGap: 12,

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      height: 80,
    },
  }),
  pageCounter: css({
    fontSize: 13,
    color: THEME.font.colors.gray.main,

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      marginBottom: 8,
    },
  }),
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    justifyContent: 'space-between',

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      flexDirection: 'column',
    },
  }),
};

export default Pager;
