import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { ListLoading, NotificationBadge, Pager } from '@src/components/molecules';
import { getPageInfo } from '@src/components/molecules/Pager/helpers';
import { useYoutubeMusicCategoriesQuery, useYoutubeMusicListQuery } from '@src/graphql/hooks';
import { LIMIT_10 } from '@src/libs/constant';
import { usePageLayout, useQueryHelper, useUrl } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { dynamicTitleState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import MusicPlayer from '../MusicPlayer';

const CategoryId = () => {
  const [selectedMusicId, setSelectedMusicId] = useState<number | null>(null);
  const { isMobileView } = usePageLayout();
  const { params, t } = useQueryHelper();
  const { setRecoilState } = useRecoil(dynamicTitleState);
  const { searchParams } = useUrl();
  const categoryId = Number(params.categoryId);
  const page = Number(searchParams.get('p')) || 1;

  const { data, loading } = useYoutubeMusicCategoriesQuery({
    skip: !categoryId,
  });
  const { data: dataList, loading: loadingDataList } = useYoutubeMusicListQuery({
    skip: !categoryId,
    variables: {
      categoryId,
    },
  });
  const categories = data?.youtubeMusicCategories || [];
  const musicList = dataList?.youtubeMusicList || [];
  const pageInfo = getPageInfo(page || 1, musicList.length, LIMIT_10);
  const title = categories.find(category => category.id === categoryId)?.category;

  useEffect(() => {
    setSelectedMusicId(null);
  }, [page]);

  useEffect(() => {
    if (title) {
      setRecoilState({ title: title ? `Option.${title}` : '' });
    }
  }, [title]);

  if (!loading && !data?.youtubeMusicCategories.length) {
    return <Navigate to={ROUTES.YOUTUBE_CMS_MUSIC} />;
  }

  return (
    <div css={{ display: 'grid', gap: THEME.box.gaps.l }}>
      {!isMobileView && (
        <div css={styles.toolbar}>
          <div className="title-container">
            <Link to={ROUTES.YOUTUBE_CMS_MUSIC}>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
            </Link>
            <label>{t(`Option.${title}`)}</label>
          </div>
          <div className="badge-container">
            <NotificationBadge />
          </div>
        </div>
      )}

      {loadingDataList ? (
        <ListLoading isTransparentBg />
      ) : (
        <div css={styles.contentContainer}>
          {musicList
            .slice(isMobileView ? 0 : (page - 1) * LIMIT_10, isMobileView ? musicList.length : LIMIT_10 * page)
            .map(music => {
              const isSelected = music.id === selectedMusicId;

              return (
                <MusicPlayer
                  {...music}
                  isSelected={isSelected}
                  key={music.id}
                  onClickSelectPlayback={() => setSelectedMusicId(music.id)}
                />
              );
            })}
        </div>
      )}

      {!isMobileView && (
        <Pager
          css={styles.pager}
          currentPage={page || 1}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
          totalCount={pageInfo.totalCount}
          totalPages={pageInfo.totalPages}
        />
      )}
    </div>
  );
};

const styles = {
  contentContainer: css({
    borderTop: THEME.box.borders.gray.eef3f7,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      background: THEME.colors.gray.eef3f7,
      border: THEME.box.borders.gray.eef3f7,
      borderRadius: THEME.box.borderRadius.m,
      display: 'grid',
      gap: 1,
      justifySelf: 'center',
      overflow: 'hidden',
      maxWidth: 614,
      width: '100%',
    },
  }),
  pager: css({
    justifySelf: 'center',
    marginBottom: 24,

    '& > div:nth-of-type(1)': {
      display: 'none',
    },

    '& p': {
      borderRadius: THEME.box.borderRadius.xs,
      width: 88,
    },

    '& li': {
      borderRadius: THEME.box.borderRadius.xs,
    },
  }),
  toolbar: css({
    alignItems: 'center',
    background: THEME.colors.white,
    display: 'flex',
    padding: '28px 40px',

    '&::before': {
      content: '""',
      display: 'block',
      width: 'fill-available',
    },

    '& > .title-container': {
      alignItems: 'center',
      display: 'flex',
      minWidth: 614,

      '& > a': {
        cursor: 'pointer',

        '[dir="rtl"] &': {
          transform: 'scaleX(-1)',
        },
      },

      '& > label': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.heading,
        fontWeight: 600,
        textAlign: 'center',
        width: 'fill-available',
      },
    },

    '& .badge-container': {
      display: 'grid',
      justifyItems: 'flex-end',
      width: 'fill-available',
    },
  }),
};

export default CategoryId;
