import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import igDefaultAvatar from '@src/assets/img/instagramSignUp/igDefaultAvatar.png';
import { Avatar, Icomoon } from '@src/components/atoms';
import { switchImage } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const Completion = () => {
  const { t } = useTranslation();
  const items = [
    {
      description: `Annotation.Visit tokky_ig's mobile app to see insights about tokky_ig's followers, view and edit contact us buttons, and reach customers with promotions`,
      icon: 'mobile-outlined',
    },
    {
      description:
        'Annotation.Leverage Business Suite and Creator Studio to manage your new business account on your desktop',
      icon: 'laptop-outlined',
    },
  ];

  return (
    <div css={styles.container}>
      <div css={styles.contentContainer}>
        <div>
          <div>
            <Avatar src={igDefaultAvatar} size={26} />
            <div>
              <Icomoon
                color="#fff"
                icon={switchImage({ dynamicColor: true, socialMedia: SocialAccountType.INSTAGRAM })}
                size={5}
              />
            </div>
          </div>
          <div>{t('Instagram Business Account Now Available')}</div>
          <div>{t('Annotation.You now have a variety of tools to connect with your audience on Instagram')}</div>

          {items.map(({ description, icon }) => (
            <div css={styles.item} key={icon}>
              <div>
                <Icomoon color="#27313b" icon={icon} size={10} />
              </div>
              <div>{t(description)}</div>
            </div>
          ))}
        </div>

        <div css={styles.actionContainer}>
          <div>{t('Button.completion')}</div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  actionContainer: css({
    borderTop: '1px solid #dfdfe0',
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    left: -2,
    padding: 8,
    position: 'absolute',
    width: 'fill-available',

    '& > div': {
      background: '#3f93ee',
      borderRadius: 3,
      color: THEME.font.colors.white,
      fontSize: 6,
      fontWeight: 600,
      padding: '6px 8px',
      position: 'relative',

      '&::before': {
        border: '2px dashed #3892e5',
        bottom: -4,
        content: '""',
        display: 'block',
        height: 'calc(100% + 4px)',
        left: -4,
        position: 'absolute',
        width: 'calc(100% + 4px)',
      },
    },
  }),
  container: css({
    background: '#fafafa',
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    display: 'flex',
    height: 222,
    justifyContent: 'center',
    width: 368,
  }),
  contentContainer: css({
    background: THEME.colors.white,
    border: '1px solid #dadbdb',
    borderBottom: 'none',
    borderTop: 'none',
    padding: 12,
    position: 'relative',
    width: 180,

    '& > div:nth-of-type(1)': {
      display: 'grid',

      '& > div:nth-of-type(1)': {
        height: 'fit-content',
        justifySelf: 'center',
        marginBottom: 8,
        position: 'relative',

        '& > div': {
          alignItems: 'center',
          background: 'radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)',
          borderRadius: '50%',
          bottom: 0,
          display: 'flex',
          height: 10,
          justifyContent: 'center',
          position: 'absolute',
          right: -2,
          width: 10,

          '[dir="rtl"] &': {
            left: -2,
            right: 'unset',
          },
        },
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: 8,
        fontWeight: 500,
        marginBottom: 2,
        textAlign: 'center',
      },

      '& > div:nth-of-type(3)': {
        borderBottom: '1px solid #dbdbdc',
        color: '#8f8e8e',
        fontSize: 5,
        marginBottom: 8,
        paddingBottom: 8,
      },
    },
  }),
  item: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.l,
    marginBottom: 4,

    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'center',
      width: 14,
    },

    '& > div:nth-of-type(2)': {
      color: '#8e8f8e',
      fontSize: 5,
    },
  }),
};

export default Completion;
