import { useState } from 'react';
import { useRemoveAllFormResponsesMutation } from '@src/graphql/hooks';
import { CRM_URL } from '@src/libs/requests';
import { useQueryHelper } from '@src/libs/hooks';
import { downloadFile } from '@src/libs/requests/downloadFile';

export default () => {
  const { t, params, enqueueSnackbar } = useQueryHelper();
  const { id } = params as { id: string };

  const [downloadMenu, setDownloadMenu] = useState<null | HTMLElement>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [removeAllFormResponses] = useRemoveAllFormResponsesMutation({
    refetchQueries: ['GetFormRespondentsList'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const openDownloadMenu = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      setDownloadMenu(e.currentTarget);
    }
  };

  const closeDownloadMenu = () => {
    setDownloadMenu(null);
  };

  const showDeleteConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const hideDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const handleClickDownloadResponses = async (cb: () => void) => {
    const url = new URL(`${CRM_URL}forms/responses/${id}`);
    await downloadFile({ url, filenamePrefix: 'responses', extension: '.csv' });

    cb();
  };

  const handleClickDeleteAllResponses = (cb: () => void) => {
    cb();

    showDeleteConfirmation();
  };

  const handleDeleteConfirm = async () => {
    await removeAllFormResponses({
      variables: {
        input: {
          formId: Number(id),
        },
      },
    });

    hideDeleteConfirmation();
  };

  return {
    downloadMenu,
    deleteConfirmation,
    openDownloadMenu,
    closeDownloadMenu,
    showDeleteConfirmation,
    hideDeleteConfirmation,
    handleDeleteConfirm,
    handleClickDeleteAllResponses,
    handleClickDownloadResponses,
  };
};
