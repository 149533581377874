import { CampaignPromotionMethod, CampaignSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { FormattedJoinedMethodAccounts, JoinedMethodAccounts } from '../types';

export const formatJoinedMethods = (methods: JoinedMethodAccounts) => {
  const joinedMethods = (() => [
    ...(methods?.facebookAccount
      ? [{ ...methods.facebookAccount, isSocialAccount: true, type: SocialAccountType.FACEBOOK }]
      : []),
    ...methods?.facebookPages.map(page => ({
      ...page,
      isSocialAccount: true,
      type: SocialAccountType.FACEBOOK_PAGE,
    })),
    ...methods?.instagramAccounts.map(account => ({
      ...account,
      isSocialAccount: true,
      type: SocialAccountType.INSTAGRAM,
    })),
    ...methods?.tiktokAccounts.map(account => ({
      ...account,
      isSocialAccount: true,
      type: SocialAccountType.TIKTOK,
    })),
    ...methods?.twitterAccounts.map(account => ({
      ...account,
      isSocialAccount: true,
      type: SocialAccountType.TWITTER,
    })),
    ...methods?.youtubeAccounts.map(account => ({
      ...account,
      isSocialAccount: true,
      type: SocialAccountType.YOUTUBE,
    })),
    ...methods?.emailNewsLetters.map(emailNewsLetter => ({
      ...emailNewsLetter,
      icon: 'mail-outlined',
      title: 'Email / Newsletter',
      type: CampaignPromotionMethod.EMAIL_NEWSLETTER,
    })),
    ...methods?.mobileApps.map(mobileApp => ({
      ...mobileApp,
      icon: 'smartphone',
      title: 'Mobile App',
      type: CampaignPromotionMethod.MOBILE_APP,
    })),
    ...methods?.offlines.map(offline => ({
      ...offline,
      icon: 'ellipsis',
      title: 'Offline / Others',
      type: CampaignPromotionMethod.OFFLINE,
    })),
    ...methods?.podCasts.map(podcast => ({
      ...podcast,
      icon: 'podcast',
      title: 'Podcast',
      type: CampaignPromotionMethod.PODCAST,
    })),
    ...methods?.websites.map(website => ({
      ...website,
      icon: 'website',
      title: 'Website',
      type: CampaignPromotionMethod.WEBSITE,
    })),
  ])() as FormattedJoinedMethodAccounts[];

  return joinedMethods;
};

export const getPromotionMethods = (campaignPromotionMethod?: CampaignSocialMediaType) => [
  ...(!campaignPromotionMethod ? [{ value: CampaignPromotionMethod.FACEBOOK }] : []),
  ...(!campaignPromotionMethod ? [{ value: CampaignPromotionMethod.INSTAGRAM }] : []),
  ...(!campaignPromotionMethod ? [{ value: CampaignPromotionMethod.INSTAGRAM_STORY }] : []),
  ...(!campaignPromotionMethod ? [{ value: CampaignPromotionMethod.TIKTOK }] : []),
  ...(!campaignPromotionMethod ? [{ value: CampaignPromotionMethod.TWITTER }] : []),
  ...(!campaignPromotionMethod ? [{ value: CampaignPromotionMethod.YOUTUBE }] : []),
  { icon: 'mail-outlined', title: 'Email / Newsletter', value: CampaignPromotionMethod.EMAIL_NEWSLETTER },
  { icon: 'smartphone', title: 'Mobile App', value: CampaignPromotionMethod.MOBILE_APP },
  { icon: 'ellipsis', title: 'Offline / Others', value: CampaignPromotionMethod.OFFLINE },
  { icon: 'podcast', title: 'Podcast', value: CampaignPromotionMethod.PODCAST },
  { icon: 'website', title: 'Website', value: CampaignPromotionMethod.WEBSITE },
];

export const MOBILE_MAX_WIDTH = 'max-width: calc(100vw - 32px);';

export const RICH_EDITOR_STYLES = 'color: #6e7c89; font-size: 16px; min-height: unset; padding: 0;';

export const SELECTION_ACTIONS = [
  'Apply to this campaign if you are interested in this campaign',
  'You will get notified if you are approved by the brand',
  'Check the “Request Details” you can see below after you approved and make a post',
];
