import { css } from '@emotion/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface AmountColumnProps {
  className?: string;
  data: string | number;
  currency?: string;
}

const AmountColumn = (props: AmountColumnProps) => {
  const { className, data, currency } = props;
  const { t } = useTranslation();

  return (
    <td className={className}>
      <div css={styles.tableData}>
        <h6 css={styles.cost}>{data}</h6>
        {currency && <p css={styles.currency}>{t(currency)}</p>}
      </div>
    </td>
  );
};

const styles = {
  cost: css({
    marginRight: 6,
    fontSize: 13,
  }),
  currency: css({
    color: '#8694a2',
    fontSize: 8,
  }),
  tableData: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
  }),
};

export default AmountColumn;
