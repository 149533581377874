import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface DocumentTitleProps {
  interpolation?: Record<string, string>;
  skip?: boolean;
  title: string;
}

const useDocumentTitle = ({ interpolation, skip, title }: DocumentTitleProps) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (!skip) {
      document.title = t(title, { ...interpolation });
    }
  }, [i18n.isInitialized, i18n.language, skip, title]);

  const setDocumentTitle = (params: DocumentTitleProps) => {
    document.title = t(params.title, { ...params.interpolation });
  };

  return {
    setDocumentTitle,
  };
};

export default useDocumentTitle;
