import { Fragment, ReactNode } from 'react';
import ReactModernDrawer from 'react-modern-drawer';
import { css, Global } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import useDirLayout from '@src/libs/hooks/useDirLayout';
import { THEME } from '@src/libs/theme';
import 'react-modern-drawer/dist/index.css';

export interface DrawerProps {
  actionContent?: ReactNode;
  children?: ReactNode;
  className?: string;
  direction?: 'bottom' | 'left' | 'right' | 'top';
  height?: string;
  open: boolean;
  width?: string;
  onClose?: () => void;
}

const Drawer = ({
  actionContent,
  children,
  className,
  direction = 'right',
  height = '100%',
  open,
  width = '335px',
  onClose,
}: DrawerProps) => {
  const { dir } = useDirLayout();

  return (
    <Fragment>
      <ReactModernDrawer
        className={className}
        style={{ width }}
        direction={
          dir === 'rtl' ? (direction === 'left' ? 'right' : direction === 'right' ? 'left' : direction) : direction
        }
        open={open}
        onClose={onClose}
      >
        <div css={{ height, overflow: 'auto' }}>
          <div css={styles.closeIcon} onClick={onClose}>
            <Icomoon color={THEME.colors.disabled} icon="clear" />
          </div>
          {children}
          {actionContent && <div css={styles.actionContainer}>{actionContent}</div>}
        </div>
      </ReactModernDrawer>
      <Global
        styles={css({
          '.EZDrawer': {
            display: 'contents',
          },
        })}
      />
    </Fragment>
  );
};

const styles = {
  actionContainer: css({
    bottom: 0,
    position: 'absolute',
    width: 'fill-available',
  }),
  closeIcon: css({
    cursor: 'pointer',
    position: 'fixed',
    right: 16,
    top: 16,

    "[dir='rtl'] &": {
      left: 16,
      right: 'unset',
    },
  }),
};

export default Drawer;
