import * as React from 'react';
import { css } from '@emotion/react';
import { formatIntNumber } from '@src/libs/format';
import { THEME } from '@src/libs/theme';

interface PageLinkProps {
  page: number;
  disabled?: boolean;
  onClick?: () => void;
}

const PageLink = (props: PageLinkProps) => {
  const { page, disabled, onClick } = props;
  const pageNumber = formatIntNumber(page);

  return (
    <li css={disabled ? styles.disabledPage : styles.page} {...(!disabled && { onClick })}>
      {pageNumber}
    </li>
  );
};

const styles = {
  page: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    borderRadius: 7,
    border: 'solid 1px #dee5ec',
    backgroundColor: THEME.colors.white,
    color: '#8694a2',
    transition: 'background-color 0.3s ease-in-out',
    cursor: 'pointer',
    width: 40,

    '&:hover': {
      borderColor: '#c5d0da',
      color: '#3b4146',
    },

    '&:active': {
      backgroundColor: '#f6f8fa',
    },
  }),
  disabledPage: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    borderRadius: 7,
    background: THEME.colors.black.main,
    color: THEME.font.colors.white,
    width: 40,
  }),
};

export default PageLink;
