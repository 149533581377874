import React, { forwardRef, Ref } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import Popover from '@src/components/atoms/Popover';
import { TextForm } from '../TextForm';

export interface ColorPickerProps {
  className?: string;
  value: string;
  onChange: (val: string) => void;
}

const ColorPicker = forwardRef((props: ColorPickerProps, ref: Ref<HTMLDivElement>) => {
  const { className, value, onChange } = props;
  const colors = [
    '#FCBEBE',
    '#FFC6A6',
    '#FFE297',
    '#B9E998',
    '#98C2E9',
    '#BC98E9',
    '#FF5F5F',
    '#FF995F',
    '#FFBF5F',
    '#5CE189',
    '#5F9FFF',
    '#A158EA',
    '#D12257',
    '#DD8737',
    '#E7D42C',
    '#1EC575',
    '#1E94C5',
    '#7D3AD2',
    '#C63C55',
    '#D76845',
    '#CDAB53',
    '#5D998E',
    '#659CBB',
    '#845D99',
    '#27313B',
    '#6E7C89',
    '#C5D0DA',
    '#DEE5EC',
    '#EEF3F7',
    '#FFFFFF',
  ];

  return (
    <div css={styles.container}>
      <Popover
        align="center"
        css={{ borderRadius: '3px' }}
        renderTrigger={
          <div className={className} css={styles.input} ref={ref}>
            {value}
          </div>
        }
        side="bottom"
      >
        <div css={styles.menuItems}>
          <div>
            <ColorIndicator color={value} />
            <TextForm placeholder="" value={value} onChange={e => onChange(e.target.value)} />
          </div>
          <div>
            {colors.map(color => (
              <Color color={color} key={color} onClick={() => onChange(color)} />
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
});

ColorPicker.displayName = 'ColorPicker';

const Color = styled.div<{ color: string }>(({ color }) => ({
  background: color,
  border: '1px solid #eef3f7',
  borderRadius: 3,
  cursor: 'pointer',
  height: 34,
  width: 34,
}));

const ColorIndicator = styled.div<{ color: string }>(({ color }) => ({
  background: color,
  border: '1px solid #eef3f7',
  borderRadius: '50%',
  height: 32,
  marginRight: 4,
  width: 32,
}));

const styles = {
  container: css({
    display: 'flex',
    flex: 1,
  }),
  input: css({
    alignItems: 'center',
    background: THEME.colors.white,
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    boxShadow: THEME.box.shadows.outer,
    color: THEME.font.colors.black.main,
    display: 'flex',
    flex: 1,
    fontSize: THEME.font.sizes.normal,
    height: 32,
    padding: '0 12px',
  }),
  menuItems: css({
    padding: 8,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 8,

      '& > div:nth-of-type(2)': {
        display: 'flex',
        flex: 1,

        '& > div': {
          width: '100%',
        },
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      gap: '8px 4px',
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
  }),
};

export default ColorPicker;
