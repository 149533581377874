import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Icomoon, Icon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { useQueryHelper } from '@src/libs/hooks';
import { SearchForm } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import { ROUTES, generatePath } from '@src/shared/routes';

interface FormIndividualNavigationProps {
  total: number;
}

export interface FormValues {
  page: string;
}

const FormIndividualNavigation = ({ total }: FormIndividualNavigationProps) => {
  const { params, t, navigate } = useQueryHelper();
  const { id, page = '1' } = params as { id: string; page: string };
  const [pageInput, setPageInput] = useState(page);

  const handlePressEnter = () => {
    navigate(generatePath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL_PAGE, { id, page: pageInput }));
  };

  useEffect(() => {
    setPageInput(page);
  }, [page]);

  return (
    <div css={styles.navigationWrapper}>
      <ThemeButton
        css={[styles.navButton]}
        to={generatePath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL_PAGE, {
          id,
          page: +page - 1,
        })}
        prefixIcon={
          <Icon>
            <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
          </Icon>
        }
        disabled={Number(page) === 1}
      />

      <SearchForm
        css={styles.search}
        onChange={e => setPageInput(e.target.value)}
        onEnterKeyPress={handlePressEnter}
        value={pageInput}
      />

      <span css={styles.count}>
        {t('of')} {total}
      </span>
      <ThemeButton
        css={styles.navButton}
        to={generatePath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL_PAGE, {
          id,
          page: +page + 1,
        })}
        prefixIcon={
          <Icon>
            <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={10} />
          </Icon>
        }
        disabled={Number(page) === total}
      />
    </div>
  );
};

const styles = {
  navButton: css({
    padding: 0,
    width: 32,
  }),
  navigationWrapper: css({
    display: 'flex',
    alignItems: 'center',
    gap: THEME.box.gaps.s,
  }),
  count: css({
    color: THEME.font.colors.gray.main,
    fontWeight: 600,
    fontSize: THEME.font.sizes.subordinate,
    margin: '0 8px',
  }),
  textForm: css({
    flex: 1,

    '& input': {
      borderRadius: 3,
      height: 32,
      border: 'none',
    },
  }),
  search: css({
    width: 32,

    '& > svg': {
      display: 'none',
    },
  }),
};

export default FormIndividualNavigation;
