import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  ConnectPromotionMethodsMutation,
  ConnectPromotionMethodsMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/ConnectPromotionMethods.graphql';

export const useConnectPromotionMethodsMutation = (
  options?: MutationHookOptions<ConnectPromotionMethodsMutation, ConnectPromotionMethodsMutationVariables>
) => useMutation<ConnectPromotionMethodsMutation, ConnectPromotionMethodsMutationVariables>(MUTATION, options);
