import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { InitialLoading } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { getCallbackUrl, getConnectSocialAuthState, removeToken, setToken } from '@src/libs/auth';
import { getMessagesFromApolloError, getMessagesFromFetchResult, ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { useAnyXSocialAuthSignInOrSignUpMutation } from '@src/graphql/hooks';
import { switchText } from '@src/libs/socialMedia';
import { ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';

export interface SocialSignUpState {
  email: string;
  name: string;
  socialMedia: SocialAccountType;
  uuid: string;
}

const SignUpEnabledComponent = () => {
  const [anyXSocialAuthSignInOrSignUp] = useAnyXSocialAuthSignInOrSignUpMutation();
  const { setUp } = useAuthSetup();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { provider, response } = useAuthProviderResponse(ROUTES.SIGN_UP);
  const {
    connectState: { socialMedia },
    resetConnectState,
  } = getConnectSocialAuthState();

  const signUpEnabledCall = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      if (window.opener) {
        window.close();
      }
      navigate(ROUTES.SIGN_UP);

      return;
    }

    // Redirect with state for connecting FB pages and an IG account.
    let socialMediaProvider = provider;
    if (socialMedia === SocialAccountType.INSTAGRAM) {
      socialMediaProvider = SocialAccountType.INSTAGRAM;
    }

    const variables = {
      input: {
        provider: socialMediaProvider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.SIGNUP_ENABLED),
      },
    };

    const { uuid, name, email, hasIgAccount, token, refreshToken, errors } = await anyXSocialAuthSignInOrSignUp({
      variables,
    })
      .then(result => {
        if (result && result.data && result.data.anyXSocialAuthSignInOrSignUp) {
          const payload = result.data.anyXSocialAuthSignInOrSignUp;

          return {
            uuid: payload.signUp?.uuid,
            name: payload.signUp?.name,
            email: payload.signUp?.email,
            hasIgAccount: payload.hasIgAccount,
            token: payload.signIn?.token,
            refreshToken: null,
            errors: [],
          };
        } else {
          return {
            uuid: null,
            name: null,
            email: null,
            hasIgAccount: false,
            token: null,
            refreshToken: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => {
        if (window.opener) {
          window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP, errorMsg: e.message }, '*');
          window.close();
        }

        return {
          uuid: null,
          name: null,
          email: null,
          hasIgAccount: false,
          token: null,
          refreshToken: null,
          errors: getMessagesFromApolloError(e),
        };
      });

    // User has already an account.
    if (errors.length === 0 && token) {
      // Sign In with token
      setToken(token, refreshToken);

      try {
        await setUp(token);

        if (window.opener) {
          // send redirect url to main app listener, from where we opened this child window
          window.opener.postMessage(
            {
              // chrome version 113 and above with new feature storage partition
              // this cause popup window cannot share localStorage with parent iframe

              // pass token into state and handling reset into localStorage on public route
              redirectPath: ROUTES.ROOT,
              state: {
                refreshToken,
                token,
              },
            },
            '*'
          );
          window.close();

          return;
        }
        sendAmplitudeEvent(eventTypes.signUp, { signUpMethod: switchText(socialMediaProvider) });
        navigate(ROUTES.ROOT);
      } catch (e) {
        removeToken();
        console.error(e);
        enqueueSnackbar(t(e.message), { variant: 'error' });
        if (window.opener) {
          window.close();
          window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP, errorMsg: e.message }, '*');

          return;
        }
        navigate(ROUTES.SIGN_UP);
      }

      return;
    }

    // Redirect to SignUp form
    if (errors.length === 0) {
      if (socialMediaProvider === SocialAccountType.INSTAGRAM && !hasIgAccount) {
        enqueueSnackbar(t('Instagram account was not connected to this Facebook account'), { variant: 'error' });
        navigate(ROUTES.SIGN_UP_IG_UNABLE_CONNECT);

        return;
      }

      if (window.opener) {
        window.opener.postMessage(
          {
            redirectPath: ROUTES.SIGN_UP_PROFILE,
            state: {
              socialMedia: provider,
              uuid: uuid || '',
              name: name || '',
              email: email || '',
            },
          },
          '*'
        );
        window.close();

        return;
      }

      navigate(ROUTES.SIGN_UP, {
        state: {
          socialMedia: socialMediaProvider,
          uuid: uuid || '',
          name: name || '',
          email: email || '',
        },
      });

      return;
    }

    errors.forEach(error => {
      console.error(error);
      // put if condition on top of enqueueSnackbar to avoid showing alert message
      if (socialMediaProvider === SocialAccountType.TIKTOK && error === ErrorTypes.VIDEO_PERMISSION_MISSING) {
        navigate(ROUTES.SIGN_UP_TIKTOK_GRANT_PERMISSION);

        return;
      }

      if (socialMediaProvider === SocialAccountType.FACEBOOK && error === ErrorTypes.FB_REQUIRED_PERMISSION_MISSING) {
        navigate(ROUTES.SIGN_UP_FACEBOOK_GRANT_PERMISSION);

        return;
      }

      if (socialMediaProvider === SocialAccountType.INSTAGRAM) {
        navigate(ROUTES.SIGN_UP_IG_UNABLE_CONNECT);

        return;
      }

      enqueueSnackbar(t(error), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP, errorMsg: error }, '*');
        window.close();

        return;
      }
      navigate(ROUTES.SIGN_UP);
    });
  };

  useEffect(() => {
    signUpEnabledCall();

    return () => {
      resetConnectState();
    };
  }, []);

  return window.opener ? (
    <ListLoading height="100vh" text="Authentication is in progress, please wait" />
  ) : (
    <InitialLoading />
  );
};

export default SignUpEnabledComponent;
