import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Omit } from '@src/libs/types';
import * as GET_CREATOR_TAGS from '@src/graphql/queries/GetCreatorTags.graphql';
import { GetCreatorTagsQuery, GetCreatorTagsQueryVariables } from '@src/__generated__/globalTypes';
import MultiSearchSelector, { OptionType, MultiSearchSelectorProps } from '../MultiSearchSelector';

export type { OptionType };
export type MultiTagsSearchSelectorProps = Omit<MultiSearchSelectorProps, 'loadOptions'>;

const MultiTagsSearchSelector = (props: MultiTagsSearchSelectorProps) => {
  const client = useApolloClient();

  const promiseOptions = async (inputValue: string) =>
    new Promise(resolve => {
      setTimeout(async () => {
        const { data } = await client.query<GetCreatorTagsQuery, GetCreatorTagsQueryVariables>({
          query: GET_CREATOR_TAGS,
          variables: {
            input: {
              keyword: inputValue,
            },
          },
        });
        const formattedData = data?.getCreatorTags?.tags.map(el => ({ value: el.id, label: el.tag })) || [];

        resolve(formattedData);
      }, 500);
    });

  return <MultiSearchSelector {...props} loadOptions={promiseOptions} />;
};

export default MultiTagsSearchSelector;
