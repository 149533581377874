import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import React, { ReactElement } from 'react';
import { css } from '@emotion/react';
import { List } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';

interface EmptyTableProps {
  className?: string;
  link?: {
    href: LocationDescriptor;
    title: string;
  };
  colSpan?: number;
  children?: ReactElement<any>;
  hasChildren?: boolean;
  hasBorderTop?: boolean;
  title?: string;
  linkDescription?: string;
  noLinkDescription?: string;
}

const EmptyTable = (props: EmptyTableProps) => {
  const {
    link,
    colSpan = 11,
    className,
    hasChildren = false,
    children,
    hasBorderTop = false,
    title,
    linkDescription,
    noLinkDescription,
  } = props;

  return (
    <StyledWrapper hasBorderTop={hasBorderTop} className={className}>
      <table>
        <tbody>
          <tr>
            {!hasChildren && (
              <List.EmptyColumn
                colSpan={colSpan}
                link={link}
                title={title}
                linkDescription={linkDescription}
                noLinkDescription={noLinkDescription}
              />
            )}
            {hasChildren && (
              <td css={styles.childrenWrapper} colSpan={colSpan}>
                {children}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.section<{ hasBorderTop: boolean }>(({ hasBorderTop }) => ({
  margin: '0 23px 0',
  background: THEME.colors.white,
  border: THEME.box.borders.gray.main,
  borderBottomRightRadius: 3,
  borderBottomLeftRadius: 3,
  ...(!hasBorderTop && {
    borderTop: 'none',
  }),
}));

const styles = {
  childrenWrapper: css({
    height: 360,
    textAlign: 'center',

    h3: {
      color: '#757575',
      fontSize: THEME.font.sizes.subTitle,
      fontWeight: 500,
    },

    p: {
      marginTop: 8,
      color: '#757575',
      fontSize: THEME.font.sizes.normal,
    },
  }),
};

export default EmptyTable;
