import { lazy } from 'react';

export const Analytics = lazy(() => import('../Analytics'));
export const InstagramStoryReelPostFeed = lazy(() => import('../InstagramStoryReelFeedPosts'));
export const MyLinkAnalytics = lazy(() => import('../MyLinkAnalytics'));
export const FacebookPostFeed = lazy(() => import('../FacebookPostFeed'));
export const YoutubeVideoList = lazy(() => import('../YoutubeVideoList'));
export const TagRanking = lazy(() => import('../TagRanking'));
export const YoutubeCompareFeedVideos = lazy(() => import('../YoutubeCompareFeedVideos'));
export const UserAnalysisList = lazy(() => import('../UserAnalysisList'));
export const IgTaggedOrMentionedPostsList = lazy(() => import('../IgTaggedOrMentionedPostsList'));
