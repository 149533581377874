import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { JAPANESE_LANG } from '@src/libs/constant';
import { THEME } from '@src/libs/theme';

const ConnectYourFacebookPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>{t('Title.Connect your Facebook Page')}</div>
      <div>
        {t(
          `Annotation.You'll use your Facebook Page to connect a product catalog to Instagram If you don't see your Page here, make sure you're an admin of the Page`
        )}
      </div>
      <div>
        <div>{t('Create a new Facebook Page')}</div>
        <Icomoon icon="arrow-down" />
      </div>
      <ActionContainer isJPLanguage={i18n.language === JAPANESE_LANG}>
        <div>{t('Button.Next')}</div>
        <div>{t(`Button.Don't Connect to Facebook Now`)}</div>
      </ActionContainer>
    </div>
  );
};

const ActionContainer = styled.div<{ isJPLanguage: boolean }>(({ isJPLanguage }) => ({
  display: 'grid',
  height: 60,
  left: 0,
  position: 'absolute',
  width: '100%',
  ...(isJPLanguage ? { bottom: 48 } : { borderTop: '1px solid #d2d1d2', bottom: 16 }),

  '& > div:nth-of-type(1)': {
    alignItems: 'center',
    background: '#0095f6',
    borderRadius: 5,
    color: THEME.font.colors.white,
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'center',
    ...(isJPLanguage
      ? { fontSize: THEME.font.sizes.hint, height: 28, marginBottom: 4, justifySelf: 'center', width: '90;' }
      : { fontSize: THEME.font.sizes.subordinate, height: 32, left: -12, position: 'absolute', top: 8, width: 306 }),
  },

  '& > div:nth-of-type(2)': {
    color: '#91cae5',
    fontSize: THEME.font.sizes.hint,
    fontWeight: 600,
    justifySelf: 'center',
    textAlign: 'center',
    ...(!isJPLanguage && { alignSelf: 'flex-end' }),
  },
}));

const styles = {
  container: css({
    background: '#e5e5e5',
    borderRadius: 32,
    height: 232,
    padding: '24px 16px',
    position: 'relative',
    width: 248,

    '& > div:nth-of-type(1)': {
      color: '#000',
      fontSize: 14.5,
      fontWeight: 700,
      marginBottom: 4,
      textAlign: 'center',
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: 9,
      marginBottom: 24,
      textAlign: 'center',
    },

    '& > div:nth-of-type(3)': {
      alignItems: 'center',
      background: THEME.colors.white,
      borderRadius: 8,
      boxShadow: THEME.box.shadows.outer,
      display: 'flex',
      height: 37,
      justifyContent: 'center',
      left: -20,
      padding: '0 16px',
      position: 'absolute',
      width: 290,

      '& > div': {
        color: THEME.font.colors.black.main,
        display: 'flex',
        flex: 1,
        fontSize: THEME.font.sizes.normal,
      },

      '& > svg': {
        transform: 'rotate(270deg)',

        '[dir="rtl"] &': {
          transform: 'rotate(90deg)',
        },
      },
    },
  }),
};

export default ConnectYourFacebookPage;
