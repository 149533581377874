import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { TextForm } from '@src/components/shared';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';

export interface FormValues {
  comment: string;
}

const AddCommentInput = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const {
    formState: { errors, isSubmitting },
    register,
    watch,
  } = useFormContext<FormValues>();
  const comment = watch('comment');

  return (
    <Container isMobileView={isMobileView}>
      <TextForm error={!!errors.comment} placeholder={t('Comment')} {...register('comment')} />
      <ThemeButton
        theme="blue"
        disabled={!comment || isSubmitting}
        prefixIcon={
          isMobileView ? (
            <Icomoon css={{ height: '18px !important', width: '24px !important' }} icon="send-white" />
          ) : (
            <div />
          )
        }
        text={!isMobileView ? 'Post' : ''}
        type="submit"
      />
    </Container>
  );
};

const Container = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  backgroundColor: THEME.colors.white,
  border: THEME.box.borders.gray.main,
  borderRadius: 5,
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 8,
  padding: 8,
  zIndex: 10,
  gap: THEME.box.gaps.s,
  ...(isMobileView && { bottom: -8, left: -16, position: 'absolute', width: 'calc(100% + 16px)' }),

  '& > div': {
    display: 'flex',
    flex: 1,

    '& > div': {
      width: '100%',
    },
  },

  '& > button': {
    borderRadius: 3,
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    height: 32,
    width: isMobileView ? 56 : 59,
  },
}));

export default AddCommentInput;
