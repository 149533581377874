import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { YoutubeMusicListQuery, YoutubeMusicListQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/YoutubeMusicList.graphql';

export const useYoutubeMusicListQuery = (
  options?: QueryHookOptions<YoutubeMusicListQuery, YoutubeMusicListQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<YoutubeMusicListQuery, YoutubeMusicListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useYoutubeMusicListLazyQuery = (
  options?: LazyQueryHookOptions<YoutubeMusicListQuery, YoutubeMusicListQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<YoutubeMusicListQuery, YoutubeMusicListQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
