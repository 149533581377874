import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ListLoading } from '@src/components/molecules';
import {
  useEngagementDraftPostHistoryForInfluencerQuery,
  useEngagementPostDetailForInfluencerQuery,
} from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import PostDetails from './PostDetails';
import PostHistory from './PostHistory';

interface DialogContentProps {
  postId: number;
  onClose: () => void;
}

const DialogContent = ({ postId }: DialogContentProps) => {
  const [currTab, setCurrTab] = useState<string>('DETAILS');
  const { t } = useTranslation();
  const tabs = [
    { title: 'Post Detail', value: 'DETAILS' },
    { title: 'History', value: 'HISTORY' },
  ];

  const { data } = useEngagementDraftPostHistoryForInfluencerQuery({
    fetchPolicy: 'no-cache',
    variables: {
      pk: postId,
    },
  });
  const { data: dataPostDetail } = useEngagementPostDetailForInfluencerQuery({
    fetchPolicy: 'no-cache',
    variables: {
      pk: postId,
    },
  });

  const postDetails = dataPostDetail?.engagementPostDetailForInfluencer;
  const postHistory = data?.engagementDraftPostHistoryForInfluencer?.historyRecords || [];

  return (
    <div>
      <div css={styles.tabsContainer}>
        {tabs.map(tab => (
          <Tab active={tab.value === currTab} key={tab.value} onClick={() => setCurrTab(tab.value)}>
            {t(tab.title)}
          </Tab>
        ))}
      </div>

      {postDetails && postHistory ? (
        currTab === 'DETAILS' ? (
          <PostDetails postDetails={postDetails} />
        ) : (
          <PostHistory postHistory={postHistory} />
        )
      ) : (
        <ListLoading />
      )}
    </div>
  );
};

const Tab = styled.div<{ active: boolean }>(({ active }) => ({
  alignItems: 'center',
  backgroundColor: active ? '#fff' : '#eef3f7',
  color: active ? '#27313b' : '#6e7c89',
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 600,
  fontSize: THEME.font.sizes.subordinate,
  height: 32,
  justifyContent: 'center',
  padding: '0 16px',
}));

const styles = {
  closeBtn: css({
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: '50%',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    position: 'absolute',
    right: -12,
    top: 17,
    width: 40,
  }),
  tabsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      borderTopLeftRadius: 5,
    },

    '& > div:nth-of-type(2)': {
      borderTopRightRadius: 5,
    },
  }),
};

export default DialogContent;
