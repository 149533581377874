import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdateAddressInformationMutation,
  UpdateAddressInformationMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdateAddressInformation.graphql';

export const useUpdateAddressInformationMutation = (
  options?: MutationHookOptions<UpdateAddressInformationMutation, UpdateAddressInformationMutationVariables>
) => useMutation<UpdateAddressInformationMutation, UpdateAddressInformationMutationVariables>(MUTATION, options);
