import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetLineChatCountQuery, GetLineChatCountQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetLineChatCount.graphql';

export const useGetLineChatCountQuery = (
  options?: QueryHookOptions<GetLineChatCountQuery, GetLineChatCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetLineChatCountQuery, GetLineChatCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetLineChatCountLazyQuery = (
  options?: LazyQueryHookOptions<GetLineChatCountQuery, GetLineChatCountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetLineChatCountQuery, GetLineChatCountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
