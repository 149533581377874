import { getToken } from '../auth';

export const getAuthHeaders = () => {
  const token = getToken();

  const authHeaders = new Headers();
  authHeaders.append('Authorization', `${token}`);
  authHeaders.append('timezone', timeZoneHeader);

  return authHeaders;
};

export const timeZoneHeader = Intl.DateTimeFormat().resolvedOptions().timeZone;
