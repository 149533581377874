import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  AnyXSocialAuthReconnectForLinkBioMutation,
  AnyXSocialAuthReconnectForLinkBioMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/AnyXSocialAuthReconnectForLinkBio.graphql';

export const useAnyXSocialAuthReconnectForLinkBioMutation = (
  options?: MutationHookOptions<
    AnyXSocialAuthReconnectForLinkBioMutation,
    AnyXSocialAuthReconnectForLinkBioMutationVariables
  >
) =>
  useMutation<AnyXSocialAuthReconnectForLinkBioMutation, AnyXSocialAuthReconnectForLinkBioMutationVariables>(
    MUTATION,
    options
  );
