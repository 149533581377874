import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import TimePicker, { timeDefaultFormat } from '@src/components/molecules/TimePicker';
import { DayPicker, Label, RichEditor, TextForm } from '@src/components/shared';
import { useGetEmailOverviewQuery } from '@src/graphql/hooks';
import { getDateTimeInPickerFormat } from '@src/libs/date';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface OverviewProps {
  emailId: string;
}

const Overview = ({ emailId }: OverviewProps) => {
  const { t } = useTranslation();
  const { data } = useGetEmailOverviewQuery({
    variables: {
      input: { emailId },
    },
  });

  const emailInfo = data?.getEmailOverview?.info;
  const statictics = data?.getEmailOverview?.stats;
  const recipientsCount = statictics?.recipientsCount || 0;
  const staticticsItems = [
    { count: recipientsCount, isTotal: true, title: 'Recipients' },
    { count: statictics?.openCount || 0, showRate: true, title: 'Open Rate' },
    { count: statictics?.clickCount || 0, showRate: true, title: 'Click Rate' },
    { count: statictics?.bounceCount || 0, showRate: false, title: 'Bounce Rate' },
    { count: statictics?.unsubscribeCount || 0, showRate: false, title: 'Unsubscribe Rate' },
  ];

  const getDateTime = () => {
    if (emailInfo?.sentAt) {
      const selectedDate = new Date(emailInfo.sentAt);
      const { date: dateString, time: timeString } = getDateTimeInPickerFormat(selectedDate);

      return {
        sentDate: dateString,
        sentTime: timeString,
      };
    }

    return {
      sentDate: '',
      sentTime: '',
    };
  };
  const { sentDate, sentTime } = getDateTime();

  return (
    <div css={styles.container}>
      <div css={styles.statisticsContainer}>
        {staticticsItems.map((item, index) => {
          const { count, isTotal, showRate, title } = item;
          const percentage = recipientsCount ? +((count / recipientsCount) * 100).toFixed(2) : 0;

          return (
            <div key={index}>
              <div>{t(`HeaderColumn.${title}`)}</div>
              {showRate ? (
                <div>
                  <div>
                    {percentage}
                    <span>%</span>
                  </div>
                  <div>{t('Recipients Count', { count })}</div>
                </div>
              ) : (
                <div>
                  <div>{count}</div>
                  {!isTotal && <div>{t('Recipients Count in Percentage', { count: percentage })}</div>}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div>
        <InputContainer>
          <StyledLabel title="Sent Date" />
          <div css={styles.dateContainer}>
            <DayPicker disabled css={styles.datePicker} value={sentDate} />
            <div css={styles.timePickerContainer}>
              <TimePicker disabled use12Hours value={moment(sentTime, timeDefaultFormat)} />
            </div>
          </div>
        </InputContainer>

        <InputContainer>
          <StyledTextForm
            disabled
            placeholder=""
            title="To"
            value={emailInfo?.toEmails?.concat(emailInfo?.tags || []).join(', ') || ''}
          />
        </InputContainer>

        <InputContainer>
          <StyledTextForm disabled placeholder="" title="From" value={emailInfo?.fromEmail || ''} />
        </InputContainer>

        <InputContainer>
          <StyledTextForm disabled placeholder="" title="Subject" value={emailInfo?.subject || ''} />
        </InputContainer>

        <StyledLabel title="Message" />
        <div css={styles.richEditorContainer}>
          <RichEditor disable={true} height="auto" hideToolbar value={emailInfo?.message || ''} />
        </div>
      </div>
    </div>
  );
};

const InputContainer = styled.div({
  marginBottom: 16,
});

const StyledLabel = styled(Label)({
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  },

  '& input': {
    backgroundColor: THEME.colors.white,
    border: 'none',
    borderBottom: '1px solid #dee5ec',
  },
});

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: '0 0 3px 3px',
    boxShadow: THEME.box.shadows.outer,
    padding: 24,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: 16,
    },
  }),
  dateContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  }),
  datePicker: css({
    cursor: 'no-drop',
    marginRight: 8,
    width: '30%',

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      width: '49%',
    },

    '& > div': {
      backgroundColor: THEME.colors.white,
      border: 'none',
      borderBottom: '1px solid #dee5ec',
    },

    '& input': {
      fontSize: 13,
    },
  }),
  richEditorContainer: css({
    '& > div > div > div > div > div:nth-of-type(3)': {
      maxWidth: '50vw',
      width: '100%',

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        maxWidth: '85vw',
      },
    },

    '& .se-resizing-bar': {
      backgroundColor: THEME.colors.white,
      minHeight: 0,

      '& > div': {
        display: 'none',
      },
    },
  }),
  statisticsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,

    '& > div': {
      alignItems: 'flex-start',
      boxShadow: THEME.box.shadows.outer,
      display: 'grid',
      flexBasis: '20%',
      justifyContent: 'center',
      height: 'fit-content',

      [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
        alignItems: 'center',
        boxShadow: THEME.box.shadows.outer,
        display: 'flex',
        flexBasis: '100%',
        padding: 16,
      },

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
        marginBottom: 16,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
          display: 'flex',
          flex: '65%',
          marginBottom: 0,
        },
      },

      '& > div:nth-of-type(2)': {
        [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
          display: 'grid',
          flex: '35%',
        },

        '& > div:nth-of-type(1)': {
          alignItems: 'center',
          color: THEME.font.colors.black.main,
          display: 'flex',
          fontSize: THEME.font.sizes.subHeading,
          fontWeight: 600,
          justifyContent: 'center',
          marginBottom: 8,

          [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
            marginBottom: 4,
          },

          '& > span': {
            color: THEME.font.colors.gray.main,
            fontSize: THEME.font.sizes.subordinate,
          },
        },

        '& > div:nth-of-type(2)': {
          color: THEME.font.colors.gray.main,
          display: 'flex',
          justifyContent: 'center',
          fontSize: THEME.font.sizes.subordinate,
        },
      },
    },

    '& > div:nth-of-type(1)': {
      boxShadow: '-1px 0 0 #eef3f7, 1px 0 0 #eef3f7',

      [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
        boxShadow: '0 -1px 0 #eef3f7, 0 1px 0 #eef3f7',
      },
    },
  }),
  timePickerContainer: css({
    width: '30%',

    [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
      width: '49%',
    },

    '& > div': {
      '& > div': {
        display: 'none',
      },

      '& > span': {
        width: '100%',

        '& > input': {
          backgroundColor: '#fff !important',
          border: 'none',
          borderBottom: '1px solid #dee5ec',
          width: '100% !important',
        },
      },
    },
  }),
};

export default Overview;
