import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { GetFanFormResponseQuery, GetFanFormResponseQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/GetFanFormResponse.graphql';

export const useGetFanFormResponseQuery = (
  options?: QueryHookOptions<GetFanFormResponseQuery, GetFanFormResponseQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<GetFanFormResponseQuery, GetFanFormResponseQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useGetFanFormResponseLazyQuery = (
  options?: LazyQueryHookOptions<GetFanFormResponseQuery, GetFanFormResponseQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<GetFanFormResponseQuery, GetFanFormResponseQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
