import { useLocation, useNavigate, useParams } from 'react-router';
import { getAuthSocialAccountType, getSocialAccountType } from '../utils/utils';

export const useProviderResponse = (redirectTo: string) => {
  const { search } = useLocation();
  const { provider = '' } = useParams<{ provider: string }>();
  const navigate = useNavigate();

  const response = search.substr(1);

  if (!response) {
    navigate(redirectTo);
  }

  return { provider: getSocialAccountType(provider), response };
};

export const useAuthProviderResponse = (redirectTo?: string) => {
  const { search } = useLocation();
  const { provider = '' } = useParams<{ provider: string }>();
  const navigate = useNavigate();

  const response = search.substr(1);

  if (redirectTo && !response) {
    navigate(redirectTo);
  }

  return { provider: getAuthSocialAccountType(provider), response };
};
