import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage } from '@src/components/atoms';
import { BackNavigator } from '@src/components/molecules';
import { TextForm } from '@src/components/shared';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { FormSubmitSection } from '@src/components/shared';
import { ROUTES } from '@src/shared/routes';

export interface AddressInformation {
  address: string;
  address2: string;
  city: string;
  fullName: string;
  phoneNumber: string;
  postalCode: string;
  province: string;
}

const Address = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<AddressInformation>();

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigator to={ROUTES.SETTINGS} title="Address" />}

      <div css={styles.description}>{t('Annotation.View and edit the address information to ship products')}</div>

      <div css={styles.formContainer}>
        <div css={{ maxWidth: 648, width: '100%' }}>
          <div css={styles.formInputSection}>
            <InputContainer>
              <StyledTextForm
                error={!!errors.fullName}
                isRequired
                title="Settings Address Full Name"
                placeholder="Full Name"
                {...register('fullName')}
              />
              <ErrorMessage message={errors.fullName?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                title="Phone Number"
                placeholder="+6563867368"
                type="number"
                {...register('phoneNumber')}
              />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                error={!!errors.postalCode}
                isRequired
                title="Postal Code"
                placeholder="Postal Code"
                {...register('postalCode')}
              />
              <ErrorMessage message={errors.postalCode?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                error={!!errors.province}
                isRequired
                title="Province"
                placeholder="Province"
                {...register('province')}
              />
              <ErrorMessage message={errors.province?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm error={!!errors.city} isRequired title="City" placeholder="City" {...register('city')} />
              <ErrorMessage message={errors.city?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                error={!!errors.address}
                isRequired
                title="Address"
                placeholder="Address"
                {...register('address')}
              />
              <ErrorMessage message={errors.address?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                error={!!errors.address2}
                isRequired
                title="Apartment, suite, etc"
                placeholder="Apartment, Suite, etc"
                {...register('address2')}
              />
              <ErrorMessage message={errors.address2?.message} />
            </InputContainer>
          </div>

          <FormSubmitSection isSubmitting={isSubmitting} />
        </div>
      </div>
    </div>
  );
};

const InputContainer = styled.div({
  marginBottom: 24,
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    fontSize: THEME.font.sizes.normal,
  },

  '& input': {
    borderRadius: 2,
    height: 32,
  },
});

const styles = {
  container: css({
    margin: '16px 24px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: 16,
    },
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
    marginLeft: 48,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      marginLeft: 0,
    },
  }),
  formContainer: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  formInputSection: css({
    backgroundColor: THEME.colors.white,
    padding: 16,
  }),
};

export default Address;
