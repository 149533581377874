import { ApolloError } from '@apollo/client';
import {
  useAnyXSocialAuthSignUpMutation,
  useAnyXSocialAuthSignUpPartnerMutation,
  useAnyXSocialAuthSignUpTalentMutation,
  useUuumSocialAuthSignUpMutation,
} from '@src/graphql/hooks';
import { getMessagesFromApolloError, getMessagesFromFetchResult } from '@src/libs/error';
import { useUuumInfluencerView } from '@src/libs/hooks';
import { AnyXAuthSocialAccountType, AuthSocialAccountType, Genders, Mutation } from '@src/__generated__/globalTypes';

interface SignupRequestPayload {
  agencyType?: string | null;
  agencyHash?: string | null;
  payload: {
    callbackUrl?: string;
    categoryIds: number[];
    countryId: string;
    dateOfBirth: string;
    email: string;
    gender: Genders;
    name: string;
    phoneNumber: string;
    provider: AnyXAuthSocialAccountType | AuthSocialAccountType;
    regionId?: number | null;
    response: string;
    uuid: string;
  };
}

export const useSignup = () => {
  const { isUuumView } = useUuumInfluencerView();
  const [anyXSocialAuthSignUp] = useAnyXSocialAuthSignUpMutation();
  const [anyXSocialAuthSignUpPartner] = useAnyXSocialAuthSignUpPartnerMutation();
  const [anyXSocialAuthSignUpTalent] = useAnyXSocialAuthSignUpTalentMutation();
  const [uuumSocialAuthSignUp] = useUuumSocialAuthSignUpMutation();

  const signup = async ({ agencyType, agencyHash, payload }: SignupRequestPayload) => {
    if (isUuumView) {
      return await uuumSocialAuthSignUp({
        variables: {
          input: {
            uuid: payload.uuid,
            name: payload.name,
            countryId: payload.countryId,
            regionId: payload.regionId as number,
            email: payload.email,
            dateOfBirth: payload.dateOfBirth,
            gender: payload.gender,
            categoryIds: payload.categoryIds,
            phoneNumber: payload.phoneNumber,
          },
        },
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { callbackUrl, regionId, response, ...anyXSocialSignUpPayload } = payload;

    switch (agencyType) {
      case 'partner':
        return await anyXSocialAuthSignUpPartner({
          variables: {
            input: {
              ...anyXSocialSignUpPayload,
              regionId: regionId as number,
              provider: payload.provider as AnyXAuthSocialAccountType,
              partnerUserOwnerHash: agencyHash as string,
            },
          },
        });

      case 'talentAgency':
        return await anyXSocialAuthSignUpTalent({
          variables: {
            input: {
              ...anyXSocialSignUpPayload,
              regionId: regionId as number,
              provider: payload.provider as AnyXAuthSocialAccountType,
              talentAgencyOwnerHash: agencyHash as string,
            },
          },
        });

      default:
        return await anyXSocialAuthSignUp({
          variables: {
            input: {
              ...anyXSocialSignUpPayload,
              provider: payload.provider as AnyXAuthSocialAccountType,
              ...(!!regionId && { regionId }),
            },
          },
        });
    }
  };

  const requestSignUp = async (props: SignupRequestPayload) =>
    await signup(props)
      .then(result => {
        if (result?.data) {
          let token = null;
          if ((result.data as Mutation).anyXSocialAuthSignUp) {
            token = (result.data as Mutation).anyXSocialAuthSignUp?.token || null;
          } else if ((result.data as Mutation).anyXSocialAuthSignUpPartner) {
            token = (result.data as Mutation).anyXSocialAuthSignUpPartner?.token || null;
          } else if ((result.data as Mutation).anyXSocialAuthSignUpTalent) {
            token = (result.data as Mutation).anyXSocialAuthSignUpTalent?.token || null;
            // for UUUM iframe users
          } else if ((result.data as Mutation).uuumSocialAuthSignUp) {
            token = (result.data as Mutation).uuumSocialAuthSignUp?.token || null;
          }

          return {
            token,
            refreshToken: null,
            errors: [] as string[],
          };
        } else {
          return {
            token: null,
            refreshToken: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => {
        if (window.opener) {
          window.opener.postMessage({ redirectPath: '/', errorMsg: e.message, needLocationReload: false }, '*');
        }

        return { token: null, refreshToken: null, errors: getMessagesFromApolloError(e) };
      });

  return {
    requestSignUp,
  };
};
