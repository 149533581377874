import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  AllEngagementPostsForInfluencerQuery,
  AllEngagementPostsForInfluencerQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/AllEngagementPostsForInfluencer.graphql';

export const useAllEngagementPostsForInfluencerQuery = (
  options?: QueryHookOptions<AllEngagementPostsForInfluencerQuery, AllEngagementPostsForInfluencerQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<AllEngagementPostsForInfluencerQuery, AllEngagementPostsForInfluencerQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useAllEngagementPostsForInfluencerLazyQuery = (
  options?: LazyQueryHookOptions<AllEngagementPostsForInfluencerQuery, AllEngagementPostsForInfluencerQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<AllEngagementPostsForInfluencerQuery, AllEngagementPostsForInfluencerQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
