import React, { ReactNode } from 'react';
import ReactSlick, { Settings } from 'react-slick';
import styled from '@emotion/styled';

interface SliderProps {
  children: ReactNode | ReactNode[];
}

const Slider = ({ children }: SliderProps) => {
  const settings: Settings = {
    dots: true,
    centerMode: true,
  };

  return (
    <StyledReactSlick length={Array.isArray(children) ? children?.length : 1} {...settings}>
      {children}
    </StyledReactSlick>
  );
};

const StyledReactSlick = styled(ReactSlick)<{ length: number }>(({ length }) => ({
  '& .slick-arrow': {
    display: 'none !important',
  },

  '& .slick-dots > li': {
    margin: 0,

    '& > button::before': {
      color: '#c5d0da',
      fontSize: 8,
    },
  },

  '& .slick-track': {
    ...(length && { width: `calc(270px * (${length} * 2 + 2)) !important` }),
  },

  '& .slick-slide.slick-active': {
    margin: '0 8px !important',
  },
}));

export default Slider;
