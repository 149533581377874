import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, Icon } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import {
  CampaignDetails,
  CampaignDetailsStyledComponents as SC,
  DialogFBGrantPermission,
  DialogTikTokGrantPermission,
  JoinButton,
} from '@src/components/shared';
import { DIALOG_REQUIRED_ACTIONS } from '@src/components/shared/Campaign/helpers';
import { useGetMarketplaceCampaignForInfluencerQuery, useMarketplaceForInfluencerQuery } from '@src/graphql/hooks';
import { ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import {
  CampaignStatusForInfluencer,
  GetMarketplaceCampaignForInfluencerQuery,
  MarketplaceCampaignAppliedStatus,
  MarketplaceForInfluencerQuery,
} from '@src/__generated__/globalTypes';

const Details = () => {
  const [isJoinableMethods, setIsJoinableMethods] = useState<boolean>(false);
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, matchPath, navigate, params, pathname, search, t } = useQueryHelper();
  const isWaitingCampaign = !!matchPath(ROUTES.MY_JOBS_WAITING_ID, pathname);
  const searchParams = new URLSearchParams(search);
  const isFacebookGrantPermission = !!searchParams.get(DIALOG_REQUIRED_ACTIONS.FACEBOOK_GRANT_PERMISSION);
  const isTikTokGrantPermission = !!searchParams.get(DIALOG_REQUIRED_ACTIONS.TIKTOK_GRANT_PERMISSION);
  const [isFacebookGrantPermissionDialog, setIsFacebookGrantPermissionDialog] =
    useState<boolean>(isFacebookGrantPermission);
  const [isTikTokGrantPermissionDialog, setIsTikTokGrantPermissionDialog] = useState<boolean>(isTikTokGrantPermission);
  const searchId = Number(params.id);
  const query = isWaitingCampaign ? useMarketplaceForInfluencerQuery : useGetMarketplaceCampaignForInfluencerQuery;
  const { data, error, loading } = query({
    fetchPolicy: 'no-cache',
    variables: { pk: searchId },
    // use error response instead of onError
    onError: undefined,
  });

  const campaign = isWaitingCampaign
    ? (data as MarketplaceForInfluencerQuery)?.marketplaceForInfluencer
    : (data as GetMarketplaceCampaignForInfluencerQuery)?.getMarketplaceCampaignForInfluencer;

  useEffect(() => {
    if (isFacebookGrantPermission || isTikTokGrantPermission) {
      searchParams.delete(
        isFacebookGrantPermission
          ? DIALOG_REQUIRED_ACTIONS.FACEBOOK_GRANT_PERMISSION
          : DIALOG_REQUIRED_ACTIONS.TIKTOK_GRANT_PERMISSION
      );
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [isFacebookGrantPermission, isTikTokGrantPermission]);

  if (loading) {
    return (
      <SC.Container>
        <ListLoading />
      </SC.Container>
    );
  }

  if (error || !searchId || !campaign) {
    if (error?.message === ErrorTypes.CAMPAIGN_FINISHED) {
      return <Navigate to={ROUTES.FIND_JOBS_CAMPAIGN_FINISHED} />;
    } else {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });

      return <Navigate to={isWaitingCampaign ? ROUTES.MY_JOBS : ROUTES.FIND_JOBS} />;
    }
  }

  const { ableToJoin, appliedStatus, isApplied, isAutoInfluencerApproval, isJoined, isSelectionCampaign, status } =
    campaign;
  const hasJoinButton = !isApplied && !isJoined;
  const isManualApprovalApplying = !!(
    isSelectionCampaign &&
    !isAutoInfluencerApproval &&
    [MarketplaceCampaignAppliedStatus.APPLYING, null].includes(appliedStatus as MarketplaceCampaignAppliedStatus | null)
  );
  const isOverBudget = status === CampaignStatusForInfluencer.SUSPENDED;
  const hidePostRequirements = isSelectionCampaign && !appliedStatus && !isJoinableMethods;
  const hasActions = hasJoinButton && !isManualApprovalApplying && !isOverBudget && !hidePostRequirements;
  const hasMobileActions = isMobileView && hasActions;

  return (
    <SC.Container hasMobileActions={hasMobileActions}>
      <DialogFBGrantPermission
        open={isFacebookGrantPermissionDialog}
        onClose={() => setIsFacebookGrantPermissionDialog(false)}
      />
      <DialogTikTokGrantPermission
        open={isTikTokGrantPermissionDialog}
        onClose={() => setIsTikTokGrantPermissionDialog(false)}
      />
      {!isMobileView && (
        <SC.Header>
          <Link to={isWaitingCampaign ? ROUTES.MY_JOBS : ROUTES.FIND_JOBS}>
            <Icon>
              <Icomoon css={{ cursor: 'pointer', transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Icon>
          </Link>
          {hasActions && (
            <div>
              <JoinButton
                campaign={campaign}
                disabled={!ableToJoin}
                searchId={searchId}
                setIsJoinableMethods={setIsJoinableMethods}
              />
            </div>
          )}
        </SC.Header>
      )}

      <CampaignDetails campaign={campaign} isJoinableMethods={isJoinableMethods} searchId={searchId} />

      {(isManualApprovalApplying || hidePostRequirements) && (
        <>
          {/* hide the component instead of unmount because we need the component to update joinable methods state */}
          <ApplyButtonContainer isHidden={!isJoinableMethods || isOverBudget}>
            <JoinButton
              campaign={campaign}
              disabled={!ableToJoin}
              searchId={searchId}
              setIsJoinableMethods={setIsJoinableMethods}
            />
          </ApplyButtonContainer>
        </>
      )}

      {hasMobileActions && (
        <SC.ActionContainer>
          <JoinButton
            campaign={campaign}
            disabled={!ableToJoin}
            searchId={searchId}
            setIsJoinableMethods={setIsJoinableMethods}
          />
        </SC.ActionContainer>
      )}

      <Global
        styles={
          hasMobileActions &&
          css({
            '.contact-line-widget': {
              bottom: 96,
            },
          })
        }
      />
    </SC.Container>
  );
};

const ApplyButtonContainer = styled.div<{ isHidden: boolean }>(({ isHidden }) => ({
  bottom: 40,
  display: isHidden ? 'none' : 'grid',
  justifyItems: 'center',
  padding: '0 24px',
  position: 'absolute',
  width: 'fill-available',
  zIndex: 2,

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    bottom: 64,
  },
}));

export default Details;
