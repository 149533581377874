import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerPaymentRequestsQuery,
  InfluencerPaymentRequestsQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/InfluencerPaymentRequests.graphql';

export const useInfluencerPaymentRequestsQuery = (
  options?: QueryHookOptions<InfluencerPaymentRequestsQuery, InfluencerPaymentRequestsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<InfluencerPaymentRequestsQuery, InfluencerPaymentRequestsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useInfluencerPaymentRequestsLazyQuery = (
  options?: LazyQueryHookOptions<InfluencerPaymentRequestsQuery, InfluencerPaymentRequestsQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<InfluencerPaymentRequestsQuery, InfluencerPaymentRequestsQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
