import { layoutState, useRecoil, LayoutStateProps } from '@src/recoilAtoms';

const useGlobalLayout = () => {
  const { recoilState, setRecoilState } = useRecoil(layoutState);

  const updateGlobalLayout = (state: Partial<LayoutStateProps>) => {
    setRecoilState({
      ...recoilState,
      ...state,
    });
  };

  return {
    ...recoilState,
    setGlobalLayout: updateGlobalLayout,
  };
};

export default useGlobalLayout;
