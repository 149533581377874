import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { RadioIcon } from '@src/components/atoms';
import { THEME } from '@src/libs/theme';
import { FilterByDateRange } from '@src/__generated__/globalTypes';

interface DateOptionGroupProps {
  items: { label: string; value: FilterByDateRange }[];
  value: FilterByDateRange | null;
  onClear: () => void;
  onChange: (val: FilterByDateRange) => void;
}

const DateOptionGroup = (props: DateOptionGroupProps) => {
  const { t } = useTranslation();
  const { items, value, onClear, onChange } = props;

  return (
    <div css={styles.wrapper}>
      {items.map(item => (
        <div key={item.value} css={styles.row} onClick={() => onChange(item.value)}>
          <RadioIcon css={{ marginRight: 8 }} isChecked={value === item.value} />
          <span>{t(item.label)}</span>
        </div>
      ))}
      <p css={styles.clearBtn} onClick={onClear}>
        {t('Clear')}
      </p>
    </div>
  );
};

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  row: css({
    display: 'flex',
    alignItems: 'center',

    '&:not(:last-of-type)': {
      marginBottom: 16,
    },

    '&:hover': {
      cursor: 'pointer',
    },
  }),
  clearBtn: css({
    color: THEME.font.colors.blue.main,
    fontSize: THEME.font.sizes.normal,
    margin: '16px 0',

    '&:hover': {
      cursor: 'pointer',
    },
  }),
};

export default DateOptionGroup;
